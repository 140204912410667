import React, { useEffect, useState } from "react";
import "../../../../../static/css/deleteModal.css";
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteMerchant,
  getAllMerchantListAfterDelete,
} from "../../../../../redux/actions/merchant-center.action";
import { useDispatch } from "react-redux";
import { showToast, startLoader, stopLoader } from "../../../../../lib/global";
import Image from "../../../../../components/Images/Image";
import InputField from "../../../../../components/formControl/input/InputField";
import { changeLiveStreamProductVisibility } from "../../../../../redux/actions/marketing.action";

const GoLiveShowProductDialog = (props) => {
  let liveStreamId = props.liveStreamId;
  console.log(liveStreamId)
  const [allProductData, setallProductData] = useState([...props.product]);

  const dispatch = useDispatch();
  const STATUS_SHOW = 1;
  const STATUS_REMOVE = 0;

  function updateVisibilityProduct(isVisible,liveStreamProductId,productId){
    let visibleUntil = 60 // seconds
    console.log(isVisible,productId,visibleUntil,"Debug")
    dispatch(changeLiveStreamProductVisibility(liveStreamId,productId,isVisible,visibleUntil))
    .then((res) => {
      showToast(res.data.message, "success");
      changeStatus(isVisible,liveStreamProductId)
    })
    .catch((err) => {
      console.log("err", err);
    })

  }
  
  function statusButton(status,liveStreamProductId,productId) {
    if (status == STATUS_REMOVE) {
      return <button className='badge badge-outline  bg-white text-black-50 text-sm-center' onClick={()=>updateVisibilityProduct(STATUS_SHOW,liveStreamProductId,productId)}>show</button>;
    } else {
      return <button className='badge badge-outline  bg-white text-black-50 text-sm-center' onClick={()=>updateVisibilityProduct(STATUS_REMOVE,liveStreamProductId,productId)}>remove</button>;
    }
  }
  function changeStatus(status,id){
    let data = [...allProductData];
    data = data.map((item)=>{return {...item, isVisible:false}})
    let index = data.findIndex((item)=>item.id == id);
    data[index].isVisible = status;
    setallProductData(data);
    props.setProductData(data)
  }

  const filterProductData = (e) => {
    let val = e.target.value
    let temp = [...props.product]
    if(val!=""){
      temp = temp.filter((item)=>item.title.toLowerCase().includes(val.toLowerCase()))
    }
    setallProductData(temp)
  }

  return (
    <React.Fragment>
      <div>
        <div className="d-flex flex-row align-items-center justify-content-between px-3">
          <p className="mb-0">Show Product</p>
          <button
            onClick={props.onClose}
            className="btn fntSz24 text-secondary"
          >
            x
          </button>
        </div>
        <div className="border-top border-bottom py-1">
          <div className="d-flex flex-row justify-content-between m-2">
            <div className="row ml-2">
              {/* <button className="btn btn-danger py-1 mx-1 btn-sm">
                Add Releated Product
              </button> */}
              <InputField
                className=""
                formClass="col-12"
                type="text"
                id="filter"
                placeholder="Filter"
                name="filter"
                onChange={(e) => {
                  filterProductData(e)
                }}
                // value={basicInformation.title}
                // onChange={onChangeBasicInfo}
              />
            </div>
          </div>
          <div
            style={{ maxHeight: "35vh", overflow: "auto" }}
            className="fntSz14 position-relative px-2"
          >
            <table className="table border-0">
              <thead className="position-sticky" style={{ top: "0" }}>
                <tr className="bg-light rounded-lg text-secondary">
                  <th className="border-0" rowspan="2">
                    Product
                  </th>
                  <th className="border-0 text-center" colspan="3">
                    Discounted Price
                  </th>
                  <th className="border-0" rowspan="2">
                    Action
                  </th>
                </tr>
                <tr className="bg-light rounded-lg text-secondary">
                  <th className="border-0">
                    Variation
                  </th>
                  <th className="border-0">
                    Individual
                  </th>
                  <th className="border-0">
                    Team
                  </th>
                </tr>
              </thead>
              <tbody>
                {allProductData &&
                  allProductData.length > 0 &&
                  allProductData.map((item, index) => (
                    <>
                    <tr key={item.id}>
                      <td className=" align-middle border-0" rowspan={item.variationList.length}>
                        <div className="d-flex flex-row align-items-center">
                          <Image
                            src={item?.productImages[0]}
                            alt={"photo"}
                            className="flash_sale_product_image mr-1"
                          />
                          <div>{item.title}</div>
                        </div>
                      </td>
                      {item.variationList &&
                        item.variationList.length > 0 &&
                        item.variationList.map((row, indexVariation) => {
                          if (indexVariation == 0) {
                            return (
                              <>
                                <td>{item.variation && item.variation.length > 0 ? row[item.variation[0].name] : (row.Type ? row.Type : "N/A")}</td>
                                <td>RM. {row?.discountPrice || "0"}</td>
                                <td>RM. {row?.teamDiscountPrice || "0"}</td>
                              </>
                            );
                          }
                          return null;
                        })}
                      <td rowspan={item.variationList.length}>
                        {statusButton(item.isVisible,item.id,item.productId)}
                      </td>
                    </tr>
                    {item.variationList &&
                      item.variationList.length > 0 &&
                      item.variationList.map((row, indexVariation) => {
                        if (indexVariation > 0) {
                          return (
                            <tr>
                              <td>{item.variation && item.variation.length > 0 ? row[item.variation[0].name] : (row.Type ? row.Type : "N/A")}</td>
                              <td>RM. {row?.discountPrice || "0"}</td>
                              <td>RM. {row?.teamDiscountPrice || "0"}</td>
                            </tr>
                          );
                        }
                        return null;
                      })}
                    </>
                  ))}
              </tbody>
            </table>
          </div>
        </div>

        <style jsx="true">{`
          .card-body {
            min-height: 50vh;
          }
          .submit_btn {
            position: absolute;
            bottom: 15px;
            width: 100%;
            justify-content: center;
            display: flex;
          }
          .close_icon_btn {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
          .flash_sale_product_image {
            width: 45px;
            height: 40px;
            border-radius: 3px;
          }
          input {
            cursor: pointer;
          }
          .mu-dialog .MuiDialog-container > div {
            max-width: 1000px;
          }
          .badge-outline {
              border: 1px solid #6c757d; /* Gray border color */
              color: #6c757d; /* Gray text color */
              background-color: transparent; /* Transparent background */
              padding: 0.25rem 0.5rem; /* Adjust padding as needed */
          }
        `}</style>
      </div>
    </React.Fragment>
  );
};

export default GoLiveShowProductDialog;
