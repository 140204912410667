import {
  GetWithToken,
  GetWithTokenFile,
  PatchWithToken,
  PostExcelWithToken,
} from "../../lib/request";

export const exportOrderToExcel = (query) => {
  const { startDate, endDate } = query;
  const url = `/api/excel/download/order-reciept?startDate=${startDate}&endDate=${endDate}`;
  return async function (dispatch) {
    const result = await GetWithTokenFile(url);
    return result;
  };
};

export const exportMyIncomeToExcel = (id, query) => {
  const { startDate, endDate } = query;
  const url = `/api/excel/download/finance/my-income/store/${id}?startDate=${startDate}&endDate=${endDate}&reportName=${query.reportName}&settlementStatus=${query.settlementStatus}`;
  return async function (dispatch) {
    const result = await GetWithTokenFile(url);
    return result;
  };
};

export const getAllRecieptOrder = (query) => {
  return async function (dispatch) {
    // console.log("enter");
    // console.log(query);
    const result = await GetWithToken(
      `/order-reciept?purchaseType=${query.purchaseType || ""}&orderId=${
        query.orderId || ""
      }&date=${query.date || ""}&item=${query.item || ""}&order=${
        query.order || ""
      }&refNo=${query.refNo || ""}&orderStatus=${
        query.orderStatus || "COMPLETED"
      }&settlementStatus=${query.settlementStatus || ""}&offset=${
        query.offset || 0
      }&limit=${query.limit || 0}&transactionsType=${
        query.transactionsType || ""
      }&startDate=${query.startDate || ""}&endDate=${query.endDate || ""}`
    );
    // console.log(result, "result for all reciept ");
    return result;
  };
};
export const getOrderRecieptById = (id) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/v2/admin/finance/order-receipt/${id || ""}`);
    return result;
  };
};

export const getMerchantOrderRecieptById = (id) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/v2/merchant/finance/order-receipt/${id || ""}`);
    return result;
  };
};

export const getOrderReturnById = (id, status) => {
  return async function (dispatch) {
    console.log("enter");
    const result = await GetWithToken(`/return/${id || ""}`);
    console.log(result, "result for all reciept ");
    return result;
  };
};

export const getOrderReturnBystoreId = (id, query) => {
  return async function (dispatch) {
    // console.log("enter");
    // const result = await GetWithToken(`/return/merchant/${id || ''}`)
    const result = await GetWithToken(
      `/return/merchant/${id || ""}?consumerName=${
        query.buyerName || ""
      }&merchantName=${query.merchantName || ""}&agentId=${
        query.agentId || ""
      }&refundMethod=${query.refundMethod || ""}&refundId=${
        query.refundId || ""
      }&item=${query.item || ""}&order=${query.order || ""}&offset=${
        query.offset || 0
      }&status=${query.status || ""}&startDate=${
        query.startDate || ""
      }&endDate=${query.endDate || ""}&limit=${query.limit || 10}`
    );
    // console.log(result, "result for all reciept ");
    return result;
  };
};

export const patchOrderReturnById = (id, status) => {
  return async function (dispatch) {
    return await PatchWithToken(`/return/order/${id || ""}`, {
      status,
    });
  };
};

export const getAllReturnOrder = (query) => {
  return async function (dispatch) {
    console.log("enter", query, "dffffffffffffffffffffff");
    const result = await GetWithToken(
      `/return?orderId=${query.orderId || ""}&consumerName=${
        query.buyerName || ""
      }&merchantName=${query.merchantName || ""}&agentId=${
        query.agentId || ""
      }&refundMethod=${query.refundMethod || ""}&refundId=${
        query.refundId || ""
      }&item=${query.item || ""}&order=${query.order || ""}&offset=${
        query.offset || 0
      }&status=${query.status || ""}&startDate=${
        query.startDate || ""
      }&endDate=${query.endDate || ""}&limit=${query.limit || 10}`
    );
    // console.log(result, "result for all cancel order ");
    return result;
  };
};

export const getRecieptOrderWithhTeam = (query) => {
  // console.log(query, "adsfasd");
  return async function (dispatch) {
    console.log("enter", query, "dffffffffffffffffffffff");
    const result = await GetWithToken(
      `/order-reciept/get/team?orderId=${query.orderId || ""}&date=${
        query.date || ""
      }&item=${query.item || ""}&order=${query.order || ""}&offset=${
        query.offset || 0
      }&teamStatus=${query.status || ""}&orderStatus=${
        query.orderStatus || ""
      }&refNo=${query.refNo || ""}&startDate=${query.startDate || ""}&endDate=${
        query.endDate || ""
      }&limit=${query.limit || 10}`
    );
    // console.log(result, "result for all cancel order ");
    return result;
  };
};

export const getRecieptOrderByTeamId = (id) => {
  return async function (dispatch) {
    console.log("enter", id, "dffffffffffffffffffffff");
    const result = await GetWithToken(`/website/team-purchase/${id || ""}`);
    console.log(result, "result for all cancel order ");
    return result;
  };
};

export const getIncomeOverviewData = (id) => {
  return async function (dispatch) {
    // console.log("enter", id, "dffffffffffffffffffffff");
    const result = await GetWithToken(`/merchants/finance/${id}`);
    // console.log(result, "result for all cancel order ");
    return result;
  };
};

export const reportGenerate = (query) => {
  return async function (dispatch) {
    const result = await GetWithTokenFile(
      `/api/excel/download?status=${query.reportType || ""}&startDate=${
        query.startDate || ""
      }&endDate=${query.endDate || ""}&storeId=${query.storeId || ""}`
    );
    // console.log(result, "result for all cancel order ");
    return result;
  };
};

export const reportGenerateTaxInvoice = (query) => {
  return async function (dispatch) {
    const result = await GetWithTokenFile(
      `/api/tax/invoice/download?startDate=${query.startDate}&endDate=${query.endDate}`
    );

    return result;
  };
};

export const uploadCashbooks = (data) => {
  return async function (dispatch) {
    const result = await PostExcelWithToken("/api/excel/upload", data);

    console.log(result, "resultttttttttttttttt");
    
    return result;
  };
};
