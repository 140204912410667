import React from "react";

import "../../static/css/deleteModal.css"
import CloseIcon from "@mui/icons-material/Close";
import { deleteMerchant, getAllMerchantListAfterDelete } from "../../redux/actions/merchant-center.action";
import { useDispatch } from "react-redux";
import { showToast, startLoader, stopLoader } from "../../lib/global";
const AccessDeniedModal
 = (props) => {
    // console.log(props, "kkkkkkkkkkkkkkkkkkkkkkkk");
    const dispatch = useDispatch();
    const deleteFun = () => {
        const data = {
            status: "DELETED",

        }
        startLoader()
        const response = dispatch(deleteMerchant(data, props.id))
        response
            .then((data) => {
                if (data.data.status == 200) {
                    const response = dispatch(getAllMerchantListAfterDelete());
                    response
                        .then((data) => {
                            if (data && data.data && data.data.status === 200) {
                                setTimeout(() => {
                                    stopLoader()
                                    showToast(data.data.message, "success")
                                    props.onSubmit([...data.data.data]);
                                    props.onClose();
                                }, 1000)
                            } else {
                                stopLoader()
                                showToast("Internal Server Error", "error")
                                props.onClose();
                            }
                        })
                        .catch(error => {
                            console.log({ error })
                        })
                }
                else {
                    stopLoader()
                    showToast("Internal Server Error", "error")
                }
            })
    }
    return (

        <React.Fragment>
            <div className="delete_modal_body z-index-3">


                <div className="close_icon" style={{ top: "0px" }}>
                    <CloseIcon
                        className="close_icon_btn m-2"
                        color="primary2"
                        fontSize="large"
                        title="Close dialog"
                        onClick={props.onClose}
                    />
                </div>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="confirmation_dialogbox__content">
                        <div className="dialogbox_body__content">
                            <h1 className="d-flex flex-direction-row justify-content-center align-items-center"><span className="dialogbox_header__icon bg-danger">X</span></h1>
                            <h4>Acces Denied ..!</h4>
                            
                            <p>You don't have permission to access</p>
                        </div>
                    </div>
                </div>
              
            </div>
            <style jsx="true">{`
          .card-body {
            min-height: 50vh;
          }
          .submit_btn {
            position: absolute;
            bottom: 15px;
            width: 100%;
            justify-content: center;
            display: flex;
          }
          .close_icon_btn {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
        `}</style>
        </React.Fragment>

    )
}

export default AccessDeniedModal
