import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './static/css/bootstrap.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import Snackbar from './hoc/Snackbar';
// import { stopReportingRuntimeErrors } from "react-error-overlay";

// stopReportingRuntimeErrors(); // disables error overlays

ReactDOM.render(
  <React.Fragment>
    <Provider store={configureStore}>
      <App />
    </Provider>
    <Snackbar></Snackbar>     
  </React.Fragment>,
  document.getElementById('root')
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
