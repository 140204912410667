import React from "react";

import "../../../static/css/deleteModal.css"
import CloseIcon from "@mui/icons-material/Close";
import { merchantDeleteProduct, getStoreProducts } from "../../../redux/actions/merchant/product.action";
import { useDispatch } from "react-redux";
import { showToast, startLoader, stopLoader } from "../../../lib/global";

const DeleteProductByMerchantModal = (props) => {
    const dispatch = useDispatch();
    const deleteFun = () => {
        startLoader()
        const response = dispatch(merchantDeleteProduct(props.id))
        response.then((data) => {
                if (data.data.status == 200) {
                    const responseGet = dispatch(getStoreProducts({}));
                    responseGet.then((data) => {
                            if (data && data.data && data.data.status === 200) {
                                setTimeout(() => {
                                    stopLoader()
                                    showToast(data.data.message, "success")
                                    props.onSubmit(data.data);
                                    props.onClose();
                                }, 1000)
                            }else if (data.status==204){
                                setTimeout(() => {
                                    stopLoader()
                                    showToast(data.data.message, "success")
                                    props.onSubmit([]);
                                    props.onClose();
                                }, 1000)
                            }
                            else {
                                stopLoader()
                                showToast("Internal Server Error", "error")
                                props.onClose();
                            }
                        })
                        .catch(error => {
                            stopLoader()
                        })
                }
                else {
                    stopLoader()
                    showToast(data.data.message, "error")
                }
        })
        .catch((err) => {
          stopLoader()
          showToast(err.response.data.message, "error")
        });
    }
    return (
        <React.Fragment>
            <div className="delete_modal_body">
                <div className="close_icon" style={{ top: "0px" }}>
                    <CloseIcon
                        className="close_icon_btn m-2"
                        color="primary2"
                        fontSize="large"
                        title="Close dialog"
                        onClick={props.onClose}
                    />
                </div>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="confirmation_dialogbox__content">
                        <div className="dialogbox_body__content">
                            <h1 className="d-flex flex-direction-row justify-content-center align-items-center"><span className="dialogbox_header__icon">!</span></h1>
                            <h4>Are you sure you want to delete this product?</h4>
                            <p>Delete this will delete the entire product's data and login credential.<br />You are unable to restore back again.</p>
                        </div>
                    </div>
                </div>
                <div className="dialogbox_footer__content">
                    <button type="button" className="dialogbox_delete__btn" onClick={() => deleteFun()}>Delete</button>
                    <button type="button" className="dialogbox_cancel__btn" onClick={props.onClose}>Cancel</button>
                </div>
            </div>
            <style jsx="true">{`
                .card-body {
                  min-height: 50vh;
                }
                .submit_btn {
                  position: absolute;
                  bottom: 15px;
                  width: 100%;
                  justify-content: center;
                  display: flex;
                }
                .close_icon_btn {
                  position: absolute;
                  right: 0;
                  cursor: pointer;
                }
            `}</style>
        </React.Fragment>
    )
}

export default DeleteProductByMerchantModal