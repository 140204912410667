import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SvgIcon from "../../../../components/Images/SvgIcon";
import SelectInput from "../../../../components/select";
import InputField from "../../../../components/formControl/input/InputField";
import { getAllPromoSlot } from "../../../../redux/actions/marketing.action";
import { stopLoader, startLoader, AllowAccess } from "../../../../lib/global";
import { v2GetAllPromoSlotByMerchant } from "../../../../redux/actions/merchant/marketing.merchant.action";

const MerchantFlashDealsListing = () => {
  AllowAccess(["MERCHANT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [allPromoSlot, setAllPromoSlot] = useState([]);
  let filterPayload = {};

  useEffect(() => {
    apiData();
  }, []);

  const apiData = () => {
    startLoader();
    dispatch(v2GetAllPromoSlotByMerchant())
      .then((data) => {
        setAllPromoSlot(
          data &&
            data.data &&
            data.data.data.map((row, index) => {
              return {
                id: row?.id || "N/A",
                title: row?.title || "N/A",
                startTime: row?.startTime || "N/A",
                endTime: row?.endTime || "N/A",
              };
            })
        );

        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log({ err });
      });
  };

  const viewAction = (promoSlotId) => {
    // history.push(`/merchant/marketing/promo/flash-deals/product-listing/${promoSlotId}`);
    history.push(`flash-deals/time-slot-listing?promoslotid=${promoSlotId}`);
  };

  return (
    <React.Fragment>
      <div className=" d-flex flex-column justify-content-between container_body vh-100">
        <div className="container-fluid">
          <div className="card p-3">
            <h5>FLASH DEALS LISTING</h5>
            <div className="card p-3 m-1 border rounded">
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>Title</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              // onClick={() => getApiData({ item: "title", order: "ASC" })}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              //onClick={() => getApiData({ item: "title", order: "DESC" })}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>Time</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              //onClick={() => getApiData({ item: "title", order: "ASC" })}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              // onClick={() => getApiData({ item: "title", order: "DESC" })}
                            />
                          </div>
                        </div>
                      </TableCell>

                      <TableCell
                        className="table_action_head text-secondary"
                        component="th"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allPromoSlot &&
                      allPromoSlot.length > 0 &&
                      allPromoSlot.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="left" className="td_cell">
                            {row?.title}
                          </TableCell>
                          <TableCell align="left" className="td_cell">
                            {moment(row.startTime, "hh:mm A").format("LT")} -{" "}
                            {moment(row.endTime, "hh:mm A").format("LT")}
                          </TableCell>

                          <TableCell className="d-flex  table_action_item">
                            <IconButton
                              // onClick={() => history.push("/marketing/all-promo/flash-sale/participate-overview")}
                              className="pointer"
                              onClick={() => viewAction(row.id)}
                            >
                              <SvgIcon
                                src="/icons/view_icon.svg"
                                alt="view icon"
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>

        <footer className="bg-white p-3 ">
          <div className="d-flex justify-content-end">
            <button
              onClick={() => history.push("/merchant/marketing/promo")}
              type="button"
              className="btn btn-outline-dark mx-2"
            >
              Back
            </button>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default MerchantFlashDealsListing;
