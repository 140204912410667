import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { useEffect } from "react";
import { searchCategoryList } from "../../../redux/actions/merchant-center.action";
import { useDispatch } from "react-redux";

const mockCategories = [
  {
    title: "category1",
    id: 1,
    SubCategory: [
      {
        title: "subcategory2",
        id: 2,
        SubCategory: [
          {
            title: "subSubCategory3",
            id: 3,
            SubCategory: [{ title: "Sub sub sub cateory1", id: 4 }],
          },
          {
            title: "subSubCategory4",
            id: 14,
            SubCategory: [{ title: "Sub sub sub cateory5", id: 5 }],
          },
        ],
      },
    ],
  },
  {
    title: "category2",
    id: 6,
  },
  {
    title: "category3",
    id: 8,
    SubCategory: [{ title: "Subcategory 7", id: 7 }],
  },
  {
    title: "category4",
    id: 9,
  },
];
const CategorySelectionDialog = (props) => {
  // console.log(props);
  console.log(props, "jhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
  const dispatch = useDispatch();
  const [categories, setCategories] = useState(
    mockCategories || props.parentCategoryData || []
  );
  const [activeCategory, setActiveCategory] = useState(props.activeCategory);
  const [level2, setLevel2] = useState();
  const [level3, setLevel3] = useState();
  const [level4, setLevel4] = useState();

  const  [selectCategory,setSelectCategory]=useState([])
  console.log(selectCategory,'ojnoooooooooooooooo');
  useEffect(() => {
    getAllCategories()
    props.activeCategory && handleInitiateCateory();
  }, []);
  const getAllCategories = () => {
    dispatch(searchCategoryList())
      .then((response) => {
        console.log(response);
        if (response?.data?.data) {
          const data = response.data.data;
          // 
          // const data = [shoes, shirt, bat]
          console.log(data, "datab data ");
          setCategories(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  const handleSubmit = () => {
    props.onSubmit(activeCategory,selectCategory);
    props.onClose();
  };

  const handleInitiateCateory = () => {
    for (let level1 of categories) {
      if (level1?.SubCategory) {
        for (let level2 of level1.SubCategory) {
          if (level2.id == activeCategory?.id) {
            setLevel2(level1);
            break;
          }
          if (level2?.SubCategory) {
            for (let level3 of level2.SubCategory) {
              if (level3.id == activeCategory?.id) {
                setLevel2(level1);
                setLevel3(level2);
                break;
              }
              if (level3?.SubCategory) {
                for (let level4 of level3.SubCategory) {
                  if (level4.id == activeCategory?.id) {
                    setLevel2(level1);
                    setLevel3(level2);
                    setLevel4(level3);
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const handleClick = (level, item) => {

    const  categoryFilter=selectCategory && selectCategory.filter((data,index)=>index<level-1)
    setSelectCategory([...categoryFilter,item.title])

    switch (level) {
      case 1:
        setLevel3(null);
        setLevel4(null);
        setLevel2(item);
        setActiveCategory(item);
        break;
      case 2:
        setLevel4(null);
        setLevel3(item);
        setActiveCategory(item);
        break;
      case 3:
        setLevel4(item);
        setActiveCategory(item);
        break;
      case 4:
        setActiveCategory(item);
        break;
      default:
        break;
    }
  };
  return (
    <React.Fragment>
      <div className="col-12 p-0 m-0 card position-relative">
        <div className="close_icon">
          <CloseIcon
            className="close_icon_btn m-2"
            color="primary2"
            fontSize="large"
            title="Close dialog"
            onClick={props.onClose}
          />
        </div>
        <div className="card-header d-flex justify-content-center">
          <h4>SELECT CATEGORY</h4>
        </div>
        <div
          style={{ height: "75vh" }}
          className="card-body add-authoried-card-body "
        >
          <div className="category-tree d-flex justify-content-center ">
            <div className="col-12 row">
              <div className="col-3 border-right level-1">
                {categories.map((item, index) => (
                  <List key={index} component="div" disablePadding>
                    <ListItem
                      button
                      onClick={(e) => handleClick(1, item)}
                      className={`category_list_item ${level2?.id == item.id ? "active" : ""
                        }`}
                    >
                      <span className="d-flex sidebar nav__link w-100">
                        <ListItemText
                          className="nav_item_text"
                          style={{
                            color:
                              activeCategory?.id == item.id ? "tomato" : "#000",
                          }}
                        >
                          {item.title}

                        </ListItemText>
                        {item.SubCategory?.length && (
                          <ChevronRightIcon
                            style={{
                              margin: "auto",
                              color:
                                activeCategory?.id == item.id
                                  ? "tomato"
                                  : "#000",
                            }}
                          />
                        )}
                      </span>
                    </ListItem>
                  </List>
                ))}
              </div>
              <div className="col-3 border-right level-2">
                {level2?.SubCategory?.map((item, index) => (
                  <List key={index} component="div" disablePadding>
                    <ListItem
                      button
                      onClick={(e) => handleClick(2, item)}
                      className={`category_list_item ${level3?.id == item.id ? "active" : ""
                        }`}
                      active={activeCategory?.id == item.id}
                    >
                      <span className="d-flex sidebar nav__link w-100">
                        <ListItemText
                          className="nav_item_text"
                          style={{
                            color:
                              activeCategory?.id == item.id ? "tomato" : "#000",
                          }}
                        >
                          {item.title}
                        </ListItemText>
                        {item.SubCategory?.length && (
                          <ChevronRightIcon
                            style={{
                              margin: "auto",
                              color:
                                activeCategory?.id == item.id
                                  ? "tomato"
                                  : "#000",
                            }}
                          />
                        )}
                      </span>
                    </ListItem>
                  </List>
                ))}
              </div>
              <div className="col-3 border-right level-3">
                {level3?.SubCategory?.map((item, index) => (
                  <List key={index} component="div" disablePadding>
                    <ListItem
                      button
                      onClick={(e) => handleClick(3, item)}
                      className={`category_list_item ${level4?.id == item.id ? "active" : ""
                        }`}
                      active={activeCategory?.id == item.id}
                    >
                      <span className="d-flex sidebar nav__link w-100">
                        <ListItemText
                          className="nav_item_text"
                          style={{
                            color:
                              activeCategory?.id == item.id ? "tomato" : "#000",
                          }}
                        >
                          {item.title}
                        </ListItemText>
                        {item.SubCategory?.length && (
                          <ChevronRightIcon
                            style={{
                              margin: "auto",
                              color:
                                activeCategory?.id == item.id
                                  ? "tomato"
                                  : "#000",
                            }}
                          />
                        )}
                      </span>
                    </ListItem>
                  </List>
                ))}
              </div>
              <div className="col-3 level-4">
                {level4?.SubCategory?.map((item, index) => (
                  <List key={index} component="div" disablePadding>
                    <ListItem
                      button
                      onClick={(e) => props.disableLevel4 ? null : handleClick(4, item)}
                      className={`category_list_item ${props.disableLevel4 ? "cursor-none" : ""}`}
                      active={activeCategory?.id == item.id}
                    >
                      <span className="d-flex sidebar nav__link w-100">
                        <ListItemText
                          className="nav_item_text"
                          style={{
                            color:
                              activeCategory?.id == item.id ? "tomato" : "#000",
                          }}
                        >
                          {item.title}
                        </ListItemText>
                        {item.SubCategory?.length && (
                          <ChevronRightIcon
                            style={{
                              margin: "auto",
                              color:
                                activeCategory?.id == item.id
                                  ? "tomato"
                                  : "#000",
                            }}
                          />
                        )}
                      </span>
                    </ListItem>
                  </List>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="submit_btn col-md-12 ">
          <button
            disabled={!activeCategory}
            onClick={handleSubmit}
            className="btn btn-primary"
          >
            Submit
          </button>
        </div>
      </div>
      <style jsx="true">{`
        .card-body {
          min-height: 60vh;
        }
        .submit_btn {
          position: absolute;
          bottom: 15px;
          width: 12%;
          right: 30rem;
          justify-content: center;
          display: flex;
        }
        .close_icon_btn {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      `}</style>
    </React.Fragment>
  );
};

export default CategorySelectionDialog;
