import { combineReducers } from "redux";
import { configReducer } from "./config";
import { counterReducer } from "./counter";
import globalReducer from "./globalReducer";
import { productReducer } from "./productReducer";
import { merchantProductReducer } from "./merchant/productReducer.merchant";

const allReducers = combineReducers({
  store: globalReducer,
  counter: counterReducer,
  config: configReducer,
  product: productReducer,
  merchantProduct: merchantProductReducer,
});

export default allReducers;
