import { IconButton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InputField from "../../../../components/formControl/input/InputField";
import { stopLoader, showToast, AllowAccess } from "../../../../lib/global";
import { breadcrumbAction } from "../../../../redux/actions/config.action";

import "../../../../static/css/consumerCenterCss/consumerCenterOverview.css";
import { searchMerchantList } from "../../../../redux/actions/merchant-center.action";
import Select2 from "react-select";
import { formatDate } from "../../../../lib/helper";
import moment from "moment";
import RadioButtonsGroup from "../../../../components/radio-button/RadioButtonsGroup";
import { getCommisionRateById, addCommisionRate, updateCommisionRateById } from "../../../../services/commissionRate.service";

function CommisionRateCreate() {
  AllowAccess(["SUPERADMIN", "MARKETING & E-COMMERCE", "FINANCIAL", "SALES OPERATION", "DEVELOPER"]);
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [merchantList, setMerchantList] = useState([]);
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [commisionRateData, setCommisionRateData] = useState({
    merchant: null,
    storeId: null,
    merchantName: null,
    merchantType: null,
    commisionRateType: null,
    physicalPercentage: null,
    physicalDescription: null,
    physicalStartPeriodDate: null,
    physicalEndPeriodDate: null,
    nonPhysicalPercentage: null,
    nonPhysicalDescription: null,
    nonPhysicalStartPeriodDate: null,
    nonPhysicalEndPeriodDate: null,
  });

  const commisionRateType = [
    { label: "Normal Rate", value: "normal-rate" },
    { label: "Promo Rate", value: "promo-rate" },
  ];

  useEffect(() => {
    // startLoader();
    const breadcrumb = [
      { label: "Commision Rate", url: "/marketing/all-promo/commision-rate" },
      { label: id ? "Edit Commision Rate" : "Create Commision Rate", url: null }
    ];
    dispatch(breadcrumbAction(breadcrumb));
    getMerchantList()
    if (id) {
      dispatch(getCommisionRateById(id))
        .then((response) => {
          console.log(response.data.data)
          setCommisionRateData(response.data.data);
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  }, []);

  const onChangeCommisionRateData = (e, opt, val) => {
    const key = opt || e.target.name;
    let value = val || e.target.value;
    if (key == "type" && value && value.value != "PERCENTAGE_DISCOUNT") {
      setCommisionRateData((pre) => ({ ...pre, maxCappedValue: null }));
    }
    if (key == "merchant") {
      if (value) {
        setCommisionRateData((pre) => ({ ...pre, storeId: value.value.storeId }));
        setCommisionRateData((pre) => ({ ...pre, merchantName: value.value.preferredMerchantName }));
        setCommisionRateData((pre) => ({ ...pre, merchantType: value.value.merchantType }));
      }
    }
    console.log(key, value, "Change")
    setCommisionRateData((pre) => ({ ...pre, [key]: value }));
  };

  const getMerchantList = (search) => {
    dispatch(searchMerchantList(search, 9999))
      .then((response) => {
        if (response?.data?.data) {
          let data = []
          if (role.toLowerCase() === "merchant") {
            response.data.data.map((item) => {
              let isPush = false;
              if (role.toLowerCase() === "merchant") {
                if (item.storeId === storeId) {
                  isPush = true
                  setCommisionRateData((pre) => ({
                    ...pre, merchant: {
                      title: item.preferredMerchantName,
                      label: `${item.preferredMerchantName} - (${item.storeId})`,
                      value: item,
                    }
                  }));
                }
              } else {
                isPush = true;
              }

              if (isPush) {
                data.push({
                  title: item.preferredMerchantName,
                  label: `${item.preferredMerchantName} - (${item.storeId})`,
                  value: item,
                })
              }
            });
          } else {
            data = response.data.data.map((item) => ({
              title: item.preferredMerchantName,
              label: `${item.preferredMerchantName} - (${item.storeId})`,
              value: item,
            }));
          }

          setMerchantList(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const onSubmit = async () => {
    let payload = {
      ...commisionRateData
    }
    let isValidate = validateForm(payload)
    console.log(isValidate, commisionRateData, 'isValidate')
    if (!isValidate) {
      return isValidate
    }
    delete payload.merchant
    if (!payload.id) {
      dispatch(addCommisionRate(payload))
        .then((res) => {
          setTimeout(() => {
            stopLoader();
            showToast("Commision Rate added successfully", "success");
            history.push("/marketing/all-promo/commision-rate");
          }, 1000);
        })
        .catch((err) => {
          stopLoader();
          const message = JSON.parse(err.request.responseText).message
          showToast("Fail Add Commision Rate [" + message + "]", "error");
          // history.push("/banner");
        });
    } else {
      delete payload.id
      delete payload.physicalStatus
      delete payload.nonPhysicalStatus
      delete payload.createdAt
      delete payload.createdUserId
      delete payload.updatedAt
      delete payload.updatedUserId
      dispatch(updateCommisionRateById(id, payload))
        .then((res) => {
          setTimeout(() => {
            stopLoader();
            showToast("Commision Rate updated successfully", "success");
            history.push("/marketing/all-promo/commision-rate");
          }, 1000);
        })
        .catch((err) => {
          stopLoader();
          const message = JSON.parse(err.request.responseText).message
          showToast("Fail Update Commision Rate [" + message + "]", "error");
          console.log({ err });
          // history.push("/banner");
        });
    }
  }

  const validateForm = (payload) => {
    if (!id && !payload.merchant) {
      showToast("Merchant is required", "error");
      return false;
    }
    if (!payload.commisionRateType) {
      showToast("Commision Rate Type is required", "error");
      return false;
    }
    if (payload.physicalPercentage) {
      if (!payload.physicalStartPeriodDate && !payload.physicalEndPeriodDate) {
        showToast("Physical Period is required", "error");
        return false;
      }
      if (!payload.physicalStartPeriodDate && !payload.physicalEndPeriodDate) {
        showToast("Physical Period is required", "error");
        return false;
      }
      if (payload.physicalEndPeriodDate && payload.physicalEndPeriodDate < moment().format('YYYY-MM-DD')) {
        showToast("End Period Date must be greater than now (" + moment().format('DD/MM/YYYY') + ")", "error");
        return false;
      }
    }

    return true
  }

  const allowNumberOnly = (e) => {
    let keyCode = e.keyCode ? e.keyCode : e.which;
    // keyCode is number or backspace or delete or .
    if (keyCode != 46 && (keyCode < 48 || keyCode > 57)) {
      e.preventDefault();
    }
  };

  return (
    <div className="main_consumer_account_div">
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="row">
          <div className="col-12 col-md-12">
            {id && (
              <div className="col-12 row my-3">
                <label className="col-4 text-right m-0 pt-2 imp">
                  Merchant Name
                </label>
                <div className="form-group col-8">
                  <InputField
                    type="text"
                    className="form-control"
                    placeholder="Merchant Name"
                    id="merchantName"
                    name="merchantName"
                    value={commisionRateData.merchantName}
                    onChange={onChangeCommisionRateData}
                    disabled
                  />
                </div>
              </div>
            )}
            {!id && (
              <div className="col-12 row my-3">
                <label className="col-4 text-right m-0 pt-0 imp">
                  Merchant
                </label>
                <div className="form-group col-8">
                  <Select2
                    options={merchantList}
                    // disabled={commisionRateData.isEnableForAllMerchant}
                    name="merchant"
                    id="merchant"
                    value={commisionRateData.merchant}
                    onChange={(e) => onChangeCommisionRateData(e, "merchant", e)}
                    required={true}
                    isMulti={false}
                    placeholder="Select Merchant"
                  />
                </div>
              </div>
            )}
            <div className="col-12 row my-3">
              <label className="col-4 text-right m-0 pt-2 imp">
                Merchant ID
              </label>
              <div className="form-group col-8">
                <InputField
                  type="text"
                  className="form-control"
                  placeholder="Merchant ID"
                  id="storeId"
                  name="storeId"
                  value={commisionRateData.storeId}
                  onChange={onChangeCommisionRateData}
                  disabled
                />
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-4 text-right m-0 pt-2 imp">
                Merchant Type
              </label>
              <div className="form-group col-8">
                <InputField
                  type="text"
                  className="form-control"
                  placeholder="Merchant Type"
                  name="merchantType"
                  id="merchantType"
                  value={commisionRateData.merchantType}
                  onChange={onChangeCommisionRateData}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="card p-3">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="col-12 row my-3">
                <label className="col-4 text-right m-0 pt-0 mt-2 imp">
                  Commision Rate
                </label>
                <div className="form-group col-8">
                  <RadioButtonsGroup
                    options={commisionRateType}
                    name="commisionRateType"
                    className="col-9 px-3"
                    value={commisionRateData.commisionRateType}
                    onChange={(e) => onChangeCommisionRateData(e, "commisionRateType")}
                  />
                </div>
              </div>

              <div className="col-12 row my-3">
                <label className="col-4 text-right m-0 pt-2">
                  Physical Product
                </label>
                <div className="form-group col-8">
                  <InputField
                    type="number"
                    onKeyPress={allowNumberOnly}
                    className="form-control"
                    placeholder="Percentage of Amount"
                    name="physicalPercentage"
                    id="physicalPercentage"
                    value={commisionRateData.physicalPercentage}
                    maxLength={2}
                    onChange={onChangeCommisionRateData}
                  />
                  <textarea
                    id="textarea"
                    name="physicalDescription"
                    className="form-control p-2"
                    style={{ minHeight: "20vh" }}
                    value={commisionRateData.physicalDescription}
                    onChange={onChangeCommisionRateData}
                    placeholder="Pre-launched Promo. Approved by"
                  />
                </div>
              </div>

              <div className="col-12 row my-3">
                <label className="col-4 text-right m-0 pt-3">
                  Physical Period
                </label>
                <div className="form-group col-4">
                  <InputField
                    type="date"
                    className="form-control"
                    id="physicalStartPeriodDate"
                    name="physicalStartPeriodDate"
                    value={formatDate(commisionRateData.physicalStartPeriodDate, 'YYYY-MM-DD')}
                    onChange={onChangeCommisionRateData}
                  />
                </div>
                <div className="form-group col-4">
                  <InputField
                    type="date"
                    className="form-control"
                    id="physicalEndPeriodDate"
                    name="physicalEndPeriodDate"
                    value={formatDate(commisionRateData.physicalEndPeriodDate, 'YYYY-MM-DD')}
                    onChange={onChangeCommisionRateData}
                  />
                </div>
              </div>

              <div className="col-12 row my-3">
                <label className="col-4 text-right m-0 pt-2">
                  Non-Physical Product
                </label>
                <div className="form-group col-8">
                  <InputField
                    type="number"
                    onKeyPress={allowNumberOnly}
                    className="form-control"
                    placeholder="Percentage of Amount"
                    name="nonPhysicalPercentage"
                    id="nonPhysicalPercentage"
                    value={commisionRateData.nonPhysicalPercentage}
                    maxLength={2}
                    onChange={onChangeCommisionRateData}
                  />
                  <textarea
                    id="textarea"
                    className="form-control p-2"
                    name="nonPhysicalDescription"
                    style={{ minHeight: "20vh" }}
                    value={commisionRateData.nonPhysicalDescription}
                    onChange={onChangeCommisionRateData}
                    placeholder="Pre-launched Promo. Approved by"
                  />
                </div>
              </div>

              <div className="col-12 row my-3">
                <label className="col-4 text-right m-0 pt-3">
                  Non-Physical Period
                </label>
                <div className="form-group col-4">
                  <InputField
                    type="date"
                    className="form-control"
                    id="nonPhysicalStartPeriodDate"
                    name="nonPhysicalStartPeriodDate"
                    value={formatDate(commisionRateData.nonPhysicalStartPeriodDate, 'YYYY-MM-DD')}
                    onChange={onChangeCommisionRateData}
                  />
                </div>
                <div className="form-group col-4">
                  <InputField
                    type="date"
                    className="form-control"
                    id="nonPhysicalEndPeriodDate"
                    name="nonPhysicalEndPeriodDate"
                    value={formatDate(commisionRateData.nonPhysicalEndPeriodDate, 'YYYY-MM-DD')}
                    onChange={onChangeCommisionRateData}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="row float-right">
              <button className="btn btn-outline-dark m-2" onClick={history.goBack}>Cancel</button>
              <button className="btn btn-outline-dark m-2" onClick={() => onSubmit()}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommisionRateCreate;
