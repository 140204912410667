// library import
import React from 'react';
import ReactTable from 'react-table';
import './reactTable.css';
import './reactTable.scss';

class reactTable extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    return (
      <div>
        <div className="react-table">
          <div className="table-lable-div">
            <div className="table-filters">
              <div className="filter-div">
              </div>
            </div>
          </div>
          <ReactTable
            resizable={false}
            data={this.props.data}
            pageSize={this.props.data && this.props.data.length}
            showPagination={false}
            // showPaginationBottom={this.props.data.length != 0 ? true : false}
            className="-striped -highlight"
            columns={this.props.columns}
            minRows={this.props.minRows || 6}
            sortable={false}
            // multiSort={false}
            noDataText={this.props.noDataText || 'No rows found'}
            // onPageChange={
            //   this.props.onPageChange ? this.props.onPageChange : () => { }
            // }
            // PaginationComponent={Pagination}
          />

        </div>
        <style>
          {
              `
              .ReactTable .rt-tr{
                  background: #fff !important;
              }
              `
          }
        </style>
      </div>
    );
  }
}
export default reactTable;
