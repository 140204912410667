import { useState } from "react";
import { TabPanel } from "../../../../components/Tabs/TabPannel";
import MerchantBalance from "./MerchantBalance/merchantBalance";
import MerchantIncome from "./MerchantIncome/merchantIncome";

const FinanceInfoIndex = (props) => {

    const [activeTab, setActiveTab] = useState("basicSetting");

    return (
        <div >
            <div className="d-flex mx-3 mt-3">
                <button
                      onClick={() => setActiveTab("basicSetting")}
                    className={`btn btn-${activeTab == "basicSetting" ? "dark" : "outline-dark"
                } btn-sm mr-3`}
                >
                    Merchant Income
                </button>
                <button
                      onClick={() => setActiveTab("chatSetting")}
                    className={`btn btn-${activeTab == "chatSetting" ? "dark" : "outline-dark"
                } btn-sm mr-3`}
                >
                    Merchant Balance
                </button>
            </div>
            <TabPanel
            style={{display: activeTab == "basicSetting" ? "block" : "none"}}
            value={0} index={0}>
                <MerchantIncome />
            </TabPanel>
            <TabPanel
            style={{display: activeTab == "chatSetting" ? "block" : "none"}}
            >
                <MerchantBalance />
            </TabPanel>
        </div>
    )
}

export default FinanceInfoIndex;