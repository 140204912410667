import {
  DeleteWithToken,
  GetWithToken,
  GetWithTokenParams,
  PatchWithToken,
  PostWithToken,
  PutWithToken,
} from "../../lib/request";

export const getFlashDealsSetting = () => {
  return async function (dispatch) {
    const result = await GetWithToken(`/marketing/flash-deals/setting`);
    return result;
  };
};

export const updateFlashDealSetting = (data) => {
  return async function (dispatch) {
    const result = await PatchWithToken(`/marketing/flash-deals/setting`, data);
    return result;
  };
};

export const getAllTimeSlotByDate = (data) => {
  return async function (dispatch) {
    const result = await GetWithTokenParams(
      `/marketing/flash-deals/time-slot/date`,
      data
    );
    return result;
  };
};

export const getAllMerchantParticipateBySlotId = (id, data) => {
  return async function (dispatch) {
    const result = await GetWithTokenParams(
      `/marketing/flash-deals/merchant-participate/slotid/${id}`,
      data
    );
    return result;
  };
};

export const getAllFlashSaleProductByMerchantId = (id, data) => {
  return async function (dispatch) {
    const result = await GetWithTokenParams(
      `/marketing/flash-deals/flash-sale-product/merchantid/${id}`,
      data
    );
    return result;
  };
};

export const getAllFlashSaleProductByTimeSlotId = (id) => {
  return async function (dispatch) {
    const result = await GetWithTokenParams(
      `/marketing/flash-deals/flash-sale-product/timeslot/${id}`
    );
    return result;
  };
};

export const getAllProductByMerchantId = (id, data) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      // /v2/merchant/flashdeal/product/merchant
      `/marketing/flash-deals/product/merchantid/${id}`
    );
    return result;
  };
};

export const getAllPromoSlot = () => {
  return async function (dispatch) {
    const result = await GetWithToken(`/marketing/flash-deals/promo-slot`);
    return result;
  };
};

export const getTimeSLotBySlotId = (id, data) => {
  return async function (dispatch) {
    const result = await GetWithTokenParams(
      `/marketing/flash-deals/time-slot/slotid/${id}`,
      data
    );
    // console.log(result);
    return result;
  };
};

export const createFlashSaleProduct = (data) => {
  return async function (dispatch) {
    const result = await PostWithToken(
      `/marketing/flash-deals/flash-sale-product`,
      data
    );
    console.log(result, "result from action");
    return result;
  };
};

export const updateFlashSaleProductByTimeSlotID = (timeslotID, data) => {
  return async function (dispatch) {
    // return await PatchWithToken(`/marketing/flash-deals/flash-sale-product/timeslot/${timeslotID}`, data);
    return await PutWithToken(
      `/marketing/flash-deals/flash-sale-product/timeslot/${timeslotID}`,
      data
    );
  };
};

export const deleteTimeSlot = (id) => {
  return async function (dispatch) {
    const result = await DeleteWithToken(
      `/marketing/flash-deals/time-slot/${id}`
    );
    return result;
  };
};

export const getLiveStream = (filter) => {
  return async function (dispatch) {
    return await GetWithToken(`/marketing/livestream?liveStreamName=${encodeURIComponent(filter.liveStreamName || '')}&liveStreamDate=${filter.liveStreamDate || ''}&merchantId=${filter.merchantId || ''}&merchantName=${filter.merchantName || ''}&limit=${filter.limit || 10}&offset=${filter.offset || 0}&sortBy=${filter.order?.item || ''}&sortOrder=${filter.order?.order || ''}`);
  };
};

export const getLiveStreamById = (id) => {
  return async function (dispatch) {
    return await GetWithToken(`/marketing/livestream/${id}`);
  };
};

export const createLiveStream = (data) => {
  return async function (dispatch) {
    return await PostWithToken(`/marketing/livestream`, data);
  };
};

export const updateLiveStream = (data) => {
  return async function (dispatch) {
    return await PutWithToken(`/marketing/livestream`, data);
  };
};

export const updateLiveStreamVoucher = (data) => {
  return async function (dispatch) {
    return await PutWithToken(`/marketing/livestream/voucher-update`, data);
  };
};

export const changeLiveStreamStatus = (data) => {
  return async function (dispatch) {
    return await PutWithToken(`/marketing/livestream/status`, data);
  };
}

export const deleteLiveStream = (id) => {
  return async function (dispatch) {
    return await DeleteWithToken(`/marketing/livestream/${id}`);
  };
};

export const getVoucher = (params) => {
  return async function (dispatch) {
    return await GetWithTokenParams(`/marketing/voucher`, params);
  };
};

export const getVoucherByStoreId = (storeId) => {
  return async function (dispatch) {
    return await GetWithToken(`/marketing/voucher/store/${storeId}`);
  };
}

export const getVoucherById = (id) => {
  return async function (dispatch) {
    return await GetWithToken(`/marketing/voucher/${id}`);
  };
};

export const createVoucher = (data) => {
  return async function (dispatch) {
    return await PostWithToken(`/marketing/voucher`, data);
  };
};

export const updateVoucher = (data) => {
  return async function (dispatch) {
    return await PutWithToken(`/marketing/voucher`, data);
  };
};

export const updateStatusVoucher = (id, data) => {
  return async function (dispatch) {
    return await PutWithToken(`/marketing/voucher/status/${id}`, data);
  };
};

export const deleteVoucher = (id) => {
  return async function (dispatch) {
    return await DeleteWithToken(`/marketing/voucher/${id}`);
  };
};

export const getAllGroupDeals = () => {
  return async function (dispatch) {
    const result = await GetWithToken(`/v2/merchant/groupdeal`);
    return result;
  };
};

export const createGroupDeals = (payload) => {
  return async function (dispatch) {
    const result = await PostWithToken(`/marketing/groupdeals`, payload);
    return result;
  };
};

export const deleteGroupDeals = (id) => {
  return async function (dispatch) {
    return await DeleteWithToken(`/marketing/groupdeals/${id}`);
  };
};

export const getAllMerchantGroupDeals = (id) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/marketing/groupdeals/merchant/groupdealsid/${id}`
    );
    return result;
  };
};

export const getAllGroupDealsProduct = (data) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/marketing/groupdeals/product/all?groupDealsId=${data.groupDealsId}&merchantId=${data.merchantId}`
    );
    return result;
  };
};

export const getGroupDealsProductById = (data) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/marketing/groupdeals/product/${data}`);
    return result;
  };
};

export const updateGroupDealsProduct = (data) => {
  return async function (dispatch) {
    const result = await PatchWithToken(
      `/marketing/groupdeals/product/update`,
      data
    );
    return result;
  };
};

export const deleteGroupDealsProduct = (id) => {
  return async function (dispatch) {
    return await DeleteWithToken(`/marketing/groupdeals/product/${id}`);
  };
};

export const createGroupDealsProduct = (data) => {
  return async function (dispatch) {
    const result = await PostWithToken(`/marketing/groupdeals/product`, data);
    return result;
  };
};

export const getAllProductMasterGroupDealsByMerchantId = (id, groupDealsId) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/marketing/groupdeals/product/master?merchantId=${id}&groupDealsId=${groupDealsId}`
    );
    return result;
  };
};

export const changeLiveStreamProductVisibility = (liveStreamId,productId,isVisible,visibleUntil) => {
  return async function (dispatch) {
    const result = await PutWithToken(
      `/marketing/livestream/products/visibility/${liveStreamId}`,
      {productId,isVisible:isVisible==1?true:false,visibleUntil}
    );
    return result;
  };
};
