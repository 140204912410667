import React, { useRef } from "react";
import Select from "react-select";

/**
 * @description Use this component for select options
 * @note Wrap it in class: form-group
 * @author jagannath
 * @date 30/12/2021
 * @param options: { label: string; value: number | string }[];
 * @param onChange: function;
 * @param value: string;
 * @param isClearable?: boolean;
 * @param label: string;
 * @param classNamePrefix?: string;
 * @param formSubmitted: boolean;
 * @param name?: string;
 * @param required?: string;
 */
const SelectInput = (props) => {
  const selectLabel = useRef(null);
  const onClickLabel = () => {
    selectLabel.current.focus();
  };
  return (
    <React.Fragment>
      <Select
        options={props.options}
        onChange={props.onChange}
        isDisabled={props.disabled}
        value={props.value}
        isClearable={!!props.isClearable && true}
        onFocus={(e) =>
          e.target
            .closest(".react-select-parent")
            .classList.add("focused-react-select")
        }
        onBlur={(e) =>
          !props.value &&
          e.target
            .closest(".react-select-parent")
            .classList.remove("focused-react-select")
        }
        id="react-select"
        className="react-select react-select-parent"
        classNamePrefix={`${props.classNamePrefix || ""} react_select_filter`}
        placeholder={props.placeholder || ""}
        ref={selectLabel}
        name={props.name || "Select"}

      />
      {!props.placeholder && (
        <label
          onClick={onClickLabel}
          htmlFor="react-select"
          className="react-select-label"
        >
          {props.label || "Select"}
        </label>
      )}
      {props.required &&
        props.formSubmitted &&
        (!props.value || !props.value.label) && (
          <label className="error">Mandatory Field!</label>
        )}
    </React.Fragment>
  );
};

export default SelectInput;
