import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import InputField from "../../../components/formControl/input/InputField";

const EditPartnerModal = (props) => {
  const [partnerName, setPartnerName] = useState(props.partnerName);
  const [passportNumber, setPassportNumber] = useState(props.passportNumber);
  const [phone, setPhone] = useState(props.phone);

  const handleSubmit = () => {
    const payload = {
      partnerName,
      passportNumber,
      phone,
      index: props.index,
    };
    console.log(payload, ";jjjjjjjjjjjj");
    props.onSubmit(payload);
    props.onClose();
  };
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };
  return (
    <React.Fragment>
      <div className="col-12 p-0 m-0 card">
        <div className="close_icon">
          <CloseIcon
            className="close_icon_btn m-2"
            color="primary2"
            fontSize="large"
            title="Close dialog"
            onClick={props.onClose}
          />
        </div>
        <div className="card-header d-flex justify-content-center">
          <h4>Edit Director/Partner</h4>
        </div>
        <div
          style={{ height: "75vh" }}
          className="card-body add-authoried-card-body"
        >
          <form className="col-12 row">
            <div className="col-12 mb-2">
              <label className="col-12 m-0 p-0">Director/Partner Name</label>
              <InputField
                className="w-100"
                type="text"
                placeholder="Director/Partner Name"
                value={partnerName}
                onChange={(e) => setPartnerName(e.target.value)}
              />
            </div>
            <div className="col-12 my-2">
              <label className="col-12 m-0 p-0">NRIC/Passport No.</label>
              <InputField
                className="w-100"
                type="text"
                placeholder="NRIC/Passport No"
                value={passportNumber}
                onChange={(e) => setPassportNumber(e.target.value)}
              />
            </div>

            <div className="col-12 my-2">
              <label className="col-12 m-0 p-0">Phone Number</label>

              <InputField
                className="w-100"
                type="text"
                onKeyPress={(e) => handleKeyPress(e)}
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="submit_btn col-md-12">
          <button
            disabled={!partnerName || !passportNumber || !phone}
            onClick={handleSubmit}
            className="btn btn-primary"
          >
            Submit
          </button>
        </div>
      </div>
      <style jsx="true">{`
        .card-body {
          min-height: 60vh;
        }
        .submit_btn {
          position: absolute;
          bottom: 15px;
          width: 100%;
          justify-content: center;
          display: flex;
        }
        .close_icon_btn {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      `}</style>
    </React.Fragment>
  );
};

export default EditPartnerModal;
