import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MERCHANT_ADD_BANK_ACCOUNT, EDIT_BANK_ACCOUNT_DIALOG } from '../../../lib/constant';
import { open_dialog, startLoader, stopLoader, showToast, AllowAccess } from '../../../lib/global';
import { getLocalStorage } from '../../../lib/session';
import { breadcrumbAction } from '../../../redux/actions/config.action';
import { getMerchantAccountDetails, updateMerchantAccount } from '../../../redux/actions/merchant-account.action';
import { updateMerchantByMercnahtId } from '../../../redux/actions/merchant-center.action';


const initialBankInfo = [];


const MyBankAccount = () => {
    AllowAccess(["MERCHANT"]);
    const [error, setError] = useState("");
    const [merchantType, setMerchantType] = useState("Business");
    const [bankInfo, setBankInfo] = useState(initialBankInfo);

     
    const dispatch=useDispatch()
    useEffect(() => {
       const breadcrumb = [{ label: "My Bank Account", url: null }];
       dispatch(breadcrumbAction(breadcrumb));
   }, []);

    useEffect(() => {
        startLoader()
        dispatch(getMerchantAccountDetails())
            .then((response) => {
                const result = response?.data?.data || {};
                console.log(result, 'hjhhhhhhhhhhhhhh');
                setBankInfo(result.bankDetails);
                setTimeout(
                    stopLoader()
                    , 1000)
            })
            .catch((error) => {
                console.log({ error });
                setTimeout(
                    () => stopLoader()
                    , 1000)
            });
    }, [])

    const updateFun = (payload) => {
        startLoader()
        dispatch(updateMerchantAccount(payload))
            .then(resData => {
                console.log('data', resData);
                setTimeout(
                    () => stopLoader()
                    , 1000)
                showToast('Update successfully', 'success')

            }).catch(err => {
                console.log({ err });
                setTimeout(
                    () => stopLoader()
                    , 1000)
            })
    }

    const handleAddAccount = () => {
        open_dialog(MERCHANT_ADD_BANK_ACCOUNT, {
            onSubmit: onSubmitBankAccountModal,
        });
    };

    const onSubmitBankAccountModal = (data) => {
        console.log("dggata", data);
        const payload = { bankDetails: [...bankInfo, data] }
        setBankInfo([...bankInfo, data]);
        updateFun(payload)


    };

    const onSubmitEditBankAccount = (data) => {
        const items = [...bankInfo];
        items[data.index] = data;
        setBankInfo(items);
        const payload = { bankDetails: items }
        updateFun(payload)


    };

    const editBankAccount = (bankName, bankAccountNo, beneciaryName, index) => {
        open_dialog(EDIT_BANK_ACCOUNT_DIALOG, {
            onSubmit: onSubmitEditBankAccount,
            bankName,
            bankAccountNo,
            beneciaryName,
            index,
        });
    };

    const deleteBankAccount = (index1) => {
        console.log(index1);

        const payload = { bankDetails: bankInfo.filter((item, index) => index !== index1) }
        updateFun(payload)
        setBankInfo(bankInfo.filter((item, index) => index !== index1));

        // dispatch(updateMerchantAccount(payload))
        // .then(resData=>{
        //     // setBankInfo((pre) => [...pre, resData]);
        //     setBankInfo(bankInfo.filter((item, index) => index !== index1));
        //     console.log('data',resData);
        // }).catch(err=>{
        //     console.log({err});
        // })
    };


    return (
        <div>
            <div
                visible={String(merchantType === "Business")}
                className="card p-3 mx-4"
            >
                <div className="d-flex justify-content-between">
                    <div className="">
                        <h6 className="m-0">BANK ACCOUNT INFORMATION</h6>
                        <label className="text-muted font-size-account">
                            Manage merchant’s bank account
                        </label>
                    </div>
                    <div className="">
                        <button onClick={handleAddAccount} className="btn btn-danger px-2">
                            Add Bank Account
                        </button>
                    </div>
                </div>
                {bankInfo?.map((item, index) => (
                    <div key={index} className="col-12 row">
                        <hr className="w-100" />
                        <div className="col-10">
                            <div>
                                <label className="col-3 my-auto small text-right text-muted">
                                    Bank Name
                                </label>
                                <label className="col-9 my-auto small">{item.bankName}</label>
                            </div>
                            <div>
                                <label className="col-3 my-auto small text-right text-muted">
                                    Account No.
                                </label>
                                <label className="col-9 my-auto small">
                                    {item.bankAccountNo}
                                </label>
                            </div>
                            <div>
                                <label className="col-3 my-auto small text-right text-muted">
                                    Beneficiary Name
                                </label>
                                <label className="col-9 my-auto small">
                                    {item.beneciaryName}
                                </label>
                            </div>
                        </div>
                        <div className="col-2 d-flex">
                            <div
                                className="text-info"
                                disabled
                                onClick={() =>
                                    editBankAccount(
                                        item.bankName,
                                        item.bankAccountNo,
                                        item.beneciaryName,
                                        index
                                    )
                                }
                            >
                                Edit
                            </div>
                            <div className="text-info mx-2">|</div>
                            <div
                                className="text-info"
                                onClick={() => deleteBankAccount(index)}
                                disabled
                            >
                                Delete
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="d-flex justify-content-end">
                {error && <label className="text-danger">{error}</label>}
            </div>
            {/* <footer className="card p-3">
                <div className="d-flex justify-content-end">
                    <button
                        type="button"
                        className="btn btn-dark"
                        onClick={() => history.goBack()}
                    >
                        Back
                    </button>
                </div>
            </footer> */}
        </div>
    )
}

export default MyBankAccount;