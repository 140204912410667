import React, { useState } from "react"


import CloseIcon from "@mui/icons-material/Close";
import { AllowAccess, open_dialog, startLoader, stopLoader } from "../../../lib/global";
import { VIEW_GENERATE_REPORT_MODAL } from "../../../lib/constant";
import InputField from "../../../components/formControl/input/InputField";
import DateRangePicker from "../../../components/datepicker/dateRangePicker";
import { useDispatch } from "react-redux";
import { reportGenerate } from "../../../redux/actions/finance.action";

import download from 'downloadjs';
import ablity from "../../../helpers/abilityGuard";


// import { DateRangePicker } from 'rsuite';



const FinanceGenerateReportModal = (props) => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const [reportType, setReportType] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  // const [reportType,setReportType]=useState('')
  const dispatch = useDispatch()

  const handleReportSelect = (value) => {
    setReportType(value)
  }

  const generateReport = async () => {
    startLoader()
    const payload = {
      reportType,
      startDate,
      endDate
    }
    dispatch(reportGenerate(payload))
      //  const blob = await
      // res.blob()
      //  download(blob, "test.xlx");

      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        // Append to html link element page
        document.body.appendChild(link);
        setTimeout(() => {
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          stopLoader()
        }, 1000)
      }).catch(err => {
        console.log({ err });
        alert('error .somthing is wrong')
        stopLoader()
      })

  }

  return (
    <React.Fragment>
      <div className="card m-3">
        <div className="d-flex border-bottom w-100 justify-content-between">
          <div className="my-3" style={{ fontSize: "20px", fontWeight: "700" }}>Generate Report</div>
          <div className="close_icon" style={{ top: "0px" }}>
            <CloseIcon
              className="close_icon_btn m-2"
              color="primary2"
              fontSize="large"
              title="Close dialog"
              role="button"
              onClick={props.onClose}
            />
          </div>
        </div>

        <div className="my-3">
          <label htmlFor="">Select Report</label>
          <div className="d-flex">
            <button
              className={`btn  px-3 text-light mr-3 ${reportType == "merchantReport" ? "btn-dark" : "btn-danger"}`}
              onClick={() => handleReportSelect("merchantReport")}
            >
              Merchant Report
            </button>
            <button
              className={`btn  px-3 text-light mr-3 ${reportType == "settlementReport" ? "btn-dark" : "btn-danger"}`}
              onClick={() => handleReportSelect("settlementReport")}

            >
              Settlement Report
            </button>
            <button
              className={`btn  px-3 text-light mr-3 ${reportType == "ReturnRefundReport" ? "btn-dark" : "btn-danger"}`}
              onClick={() => handleReportSelect("ReturnRefundReport")}

            >
              Return/Refund Report
            </button>
          </div>
        </div>

        <div className="">
          <div className="my-2"> Report Date</div>


          {/* <DateRangePicker /> */}
          <input
            type='date'
            name='startingDate'
            className=""

            onChange={(e) => setStartDate(e.target.value)}
          />
          <spam> -- </spam>
          <input
            type='date'
            name="endingDate"
            className=""
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <br />
        <br />
        <hr />
        <div className="d-flex justify-content-end">
          <button
            className="btn border"
            onClick={props.onClose}
          >
            Cancel
          </button>
          {ablity({model:'Finance', action:'create'}) == 1 && (
            <button
              className="btn btn-danger border mx-3"
              onClick={generateReport}
              disabled={startDate ? endDate ? reportType ? false : true : true : true}
            >
              Generate
            </button>
          )}
        </div>



      </div>
      <style jsx="true">{`
          .w-0{
            width:0 !important;
          }
          .text-head-black{
            color:#000000;
          }
          .text-data{
            font-size:15px ;
           }
          .text-total{
            font-size:20px;
            font-weight:800;
          }
        `}</style>
    </React.Fragment>
  )
}

export default FinanceGenerateReportModal;