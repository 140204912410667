import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ListItemText } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import isMobile from "../../hooks/isMobile";
import useTheme from "../../hooks/useTheme";
import { SideNavbarToggleSubject } from "../../lib/rxSubject";
import sidebarList from "../sidebar";
import { spacing } from '@mui/system';
import merchantSidebarList from "../sidebar/MerchantSidebar";
import { open_dialog } from "../../lib/global";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
  },
  nested: {
    paddingLeft: spacing(4),
  },
}));

const Sidebar = () => {
  const [theme] = useTheme();
  const [mobileView] = isMobile();
  const classes = useStyles();
  const [open, setOpen] = React.useState({});
  const [minimize, setMinimize] = useState(false);
  const Route = useHistory();
  const [activeRoute, setActiveRoute] = useState("/");
  const { pathname = "" } = Route.location;
  const userRole = localStorage.getItem("role");
  const createdBy = localStorage.getItem("createdBy");
  useEffect(() => {
    SideNavbarToggleSubject.subscribe((flag) => setMinimize(flag || false));
  }, []);
  useEffect(() => {
    setActiveRoute(pathname);
  }, [pathname]);

  const handleClick = (title, item) => {
    if (mobileView) return;
    if (minimize) {
      SideNavbarToggleSubject.next();
    }
    setOpen((prev) => {
      return {
        ...prev,
        [title]: !prev[title],
      };
    });
    if (item.path) {
      Route.push(item.path);
    }
  };

  let itemList = [];
  if(userRole === "MERCHANT" && createdBy != "SUPERADMIN"){
    itemList = merchantSidebarList(theme.iconColor)
  }
  else{
    itemList = sidebarList(theme.iconColor);
  }

  // if (userRole === "SUPERADMIN" || createdBy == "SUPERADMIN") {
  //   itemList = sidebarList(theme.iconColor);
  // } else {
  //   itemList = merchantSidebarList(theme.iconColor)
  // }

  const openModal = (constant) => {
    open_dialog(constant)
  }

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        style={{
          color: theme.textColor,
          backgroundColor: theme.drawerBgColor,
          boxShadow: theme.drawerShadow,
          height: "100%",
          overflowY: "auto",
        }}
        className={classes.root}
      >
        {itemList.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <ListItem
                className={`d-flex justify-content-between side_nav_item ${activeRoute === item.path ? "active" : ""
                  }`}
                button
                onClick={() => handleClick(item.title, item)}
              >
                <span
                  className="d-flex sidebar nav__link"
                  style={{ color: activeRoute === item.path ? "#000" : theme.sidebarTextColor }}
                >
                  <ListItemIcon
                    style={{
                      color:
                        activeRoute === item.path ? "#fff" : theme.iconColor,
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  {!minimize && (
                    <ListItemText
                      className="nav_item_text"
                      primary={item.title}
                    />
                  )}
                </span>
                {!minimize && item.children && item.children.length ? (
                  open[item.title] ? (
                    <ExpandLess style={{ color: theme.sidebarTextColor }} />
                  ) : (
                    <ExpandMore style={{ color: theme.sidebarTextColor }} />
                  )
                ) : null}
              </ListItem>
              {item.children &&
                item.children.map((childItem, childIndex) => {
                  return (
                    <Collapse
                      key={childItem.title + childIndex}
                      in={open[item.title]}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        {
                          childItem.modal ?
                            <div style={{
                              color: theme.sidebarTextColor,
                            }}
                              className="d-flex sidebar nav__link pl-3"
                            >
                              <ListItem
                                button
                                onClick={() => openModal(childItem.modal)}
                                className={`${classes.nested} side_nav_item ${activeRoute === childItem.path ? "active" : ""
                                  }`}
                              >
                                <ListItemIcon
                                  style={{
                                    color:
                                      activeRoute === childItem.path
                                        ? "#fff"
                                        : theme.iconColor,
                                  }}
                                >
                                  {childItem.icon}
                                </ListItemIcon>
                                {!minimize && (
                                  <ListItemText
                                    className="nav_item_text"
                                    primary={childItem.title}
                                  />
                                )}
                              </ListItem>

                            </div>
                            :
                            <NavLink
                              style={{
                                color: theme.sidebarTextColor,
                              }}
                              className="d-flex sidebar nav__link pl-3"
                              to={{
                                pathname: childItem.path,
                              }}
                            >
                              <ListItem
                                button
                                onClick={() => setActiveRoute(childItem.path)}
                                className={`${classes.nested} side_nav_item ${activeRoute === childItem.path ? "active" : ""
                                  }`}
                              >
                                <ListItemIcon
                                  style={{
                                    color:
                                      activeRoute === childItem.path
                                        ? "#fff"
                                        : theme.iconColor,
                                  }}
                                >
                                  {childItem.icon}
                                </ListItemIcon>
                                {!minimize && (
                                  <ListItemText
                                    className="nav_item_text"
                                    primary={childItem.title}
                                  />
                                )}
                              </ListItem>
                            </NavLink>
                        }
                      </List>
                    </Collapse>
                  );
                })}
            </React.Fragment>
          );
        })}
      </List>
      <style jsx="true">{`
        :global(.MuiListItemIcon-root) {
          min-width: 30px !important;
          align-items: center !important;
        }
      `}</style>
    </>
  );
};

export default Sidebar;
