import { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";

import format from "date-fns/format";
import { addDays } from "date-fns";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import InputField from "../formControl/input/InputField";

const initalDateRange = [
  {
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  },
];
const DateRangePickerComp = (props) => {
  // date state
  const [range, setRange] = useState("");

  // open close
  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef(null);
  useEffect(() => {

    if (props.reset) {
      setRange("");
      props.setReset(false)
    }
  }, [props.reset]);

  const onchangeDate = (data) => {
    setRange(data);
    props.handleDateChange({
      // startDate: format(data[0].startDate, "dd/MM/yyyy"),
      startDate: moment(data[0].startDate).format(),
      // endDate: format(data[0].endDate, "dd/MM/yyyy"),
      endDate: moment(data[0].endDate).format(),

    });
  };

  useEffect(() => {
    // event listeners
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  // hide dropdown on ESC press
  const hideOnEscape = (e) => {
    // console.log(e.key)
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  // Hide dropdown on outside click
  const hideOnClickOutside = (e) => {
    // console.log(refOne.current)
    if (refOne.current && !refOne?.current?.contains(e.target)) {
      setOpen(false);
    }
  };



  return (
    <div className="">
      <InputField
        value={
          range
            ? `${format(range[0].startDate, "dd/MM/yyyy")} to ${format(
              range[0].endDate,
              "dd/MM/yyyy"
            )}`
            : ""
        }
        readOnly
        className={"inputBox p-1 "+props.className}
        placeholder="start Date - end Date"
        onClick={() => setOpen((open) => !open)}
      />

      <div ref={refOne} >
        {open && (
          <DateRange
            onChange={(item) => onchangeDate([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range ? range : initalDateRange}
            months={1}
            direction="horizontal"
            className="calendarElement"

          />
        )}
      </div>
    </div>
  );
};




export default DateRangePickerComp;
