import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
// import SvgIcon from "../../../components/Images/SvgIcon";
import SvgIcon from "../../../../components/Images/SvgIcon";
import {
  getAllProductList,
  getProductsBymerchantId,
  getSortedProducts,
} from "../../../../redux/actions/product.action";
import moment from "moment";

import {
  startLoader,
  stopLoader,
  showToast,
  open_dialog,
  AllowAccess,
} from "../../../../lib/global";
import { ACCESS_DENIED, PRODUCT_DELETE_DIALOG } from "../../../../lib/constant";
import { getLocalStorage } from "../../../../lib/session";
import Image from "../../../../components/Images/Image";
// import { getAllSettlementData } from "../../../../redux/actions/order.action";
import {getAllSettlementData} from "../../../../redux/actions/merchant/order.action";
import DateRangePickerComp from "../../../../components/datepicker/MUIDateRange";
// import { exportMyIncomeToExcel } from "../../../../redux/actions/finance.action";
import { exportMyIncomeToExcel } from "../../../../redux/actions/merchant/finance.action";

const initDateRange = {
  startDate: "",
  endDate: "",
};
const initialSearchData = {
  search: "",
  offset: 0,
};

function ToSettle(props) {
  AllowAccess(["MERCHANT"]);
  const [permissions, setPermissions] = useState(
    getLocalStorage("assignMethod")
  );
  const history = useHistory();
  // const [title, setTitle] = useState()
  const dispatch = useDispatch();
  const [allProductData, setAllProductData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [searchData, setSearchData] = useState(initialSearchData);
  const [totolCatetory, setTotalCategory] = useState(0);
  const [offset, setOffset] = useState(0);
  const [dateRange, setDateRange] = useState(initDateRange);

  const viewAction = (orderId, storeId) => {
    if (props.status === "toSettle") {
      history.push(
        `/merchant/finance/my-income/view-to-settle/${orderId}/${storeId}`
      );
    } else {
      history.push(
        `/merchant/finance/my-income/view-order/${orderId}/${storeId}`
      );
    }
  };

  const handleDateChange = (data) => {
    setDateRange(data);
    // console.log(dateRange)
    getApiData();
  };

  const id = getLocalStorage("storeId");
  useEffect(() => {
    getApiData();
  }, [searchData, props.search, props.status]);

  const getApiData = () => {
    startLoader();
    const payload = {
      ...props.search,
      ...searchData,
      status: props.status,
      ...dateRange,
    };
    console.log(payload);
    // const response = dispatch(getAllSettlementData(id, payload));
    const response = dispatch(getAllSettlementData(payload));
    response
      .then((data) => {
        console.log(data.data.data, "data data ");
        setAllProductData(data && data.data && data.data.data);
        setTotalCount(data.data.total);
        stopLoader();
      })
      .catch((error) => {
        stopLoader();
        console.log({ error }, "error");
      });
  };

  const handleExport = () => {
    // exportMyIncomeToExcel
    startLoader();
    let filter = {
      ...dateRange,
      reportName:
        props.status == "toSettle" ? "To Settle Report" : "Settle Report",
      settlementStatus: props.status,
    };
    // dispatch(exportMyIncomeToExcel(id, filter))
    dispatch(exportMyIncomeToExcel(filter))
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${Date.now()}.xlsx`);
        document.body.appendChild(link);
        setTimeout(() => {
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        console.log({ err });
        alert("error .somthing is wrong");
        stopLoader();
      });
  };

  return (
    <React.Fragment>
      <div className="d-flex mb-3 justify-content-between align-items-center">
        <div className="min-width-200 col-md-3">
          <label className=""> Select Date </label>

          <DateRangePickerComp
            // reset={reset}
            // setReset={setReset}
            handleDateChange={handleDateChange}
          />
        </div>
        <div className="">
          <button
            type="button"
            className="btn-lg btn-danger border  text-nowrap mx-3"
            onClick={() => handleExport()}
          >
            Export
          </button>
        </div>
      </div>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Order</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      // onClick={() => getApiData({ item: "title", order: "ASC" })}
                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      // onClick={() => getApiData({ item: "title", order: "DESC" })}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell " component="th" align="center">
                <div className="d-flex  ">
                  <div>
                    {props.status == "toSettle"
                      ? "Estimated Released Date"
                      : "Released Date"}
                  </div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      // onClick={() => getApiData({ item: "Rating", order: "ASC" })}
                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      // onClick={() => getApiData({ item: "Rating", order: "DESC" })}
                    />
                  </div>
                </div>
              </TableCell>

              <TableCell className="th_cell " component="th" align="center">
                <div className="d-flex  ">
                  <div>Status</div>

                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      // onClick={() => getApiData({ item: "categoryName", order: "ASC" })}
                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      // onClick={() => getApiData({ item: "categoryName", order: "DESC" })}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Payout Amount</div>

                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      // onClick={() => getApiData({ item: "createdAt", order: "ASC" })}
                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      // onClick={() => getApiData({ item: "createdAt", order: "DESC" })}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="table_action_head" component="th">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!allProductData?.length && (
              <div className="d-flex justify-content-center">
                <h3 className="text-muted m-auto">No data found!</h3>
              </div>
            )}

            {allProductData &&
              allProductData.length > 0 &&
              allProductData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="start" className="td_cell">
                    <div className="d-flex">
                      <Image
                        src={row?.productImages || "N/A"}
                        alt={"photo"}
                        className="shopRating_page_product_image"
                      />
                      <div className=" mx-2">
                        <div className="shopRating_Page_ProductBox_ProductName">
                          {row.productName || "0"}
                        </div>
                        <div className="shopRating_Page_ProductBox_Product_varient">
                          Buyer: {row.consumerName}
                        </div>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="start" className="td_cell">
                    {row.updatedAt
                      ? moment(row.updatedAt).format("DD/MM/YY")
                      : "N/A"}
                  </TableCell>
                  <TableCell align="start" className="td_cell">
                    {row?.status || "N/A"}
                  </TableCell>
                  <TableCell align="start" className="td_cell">
                    RM{row?.price * row?.quantity || "N/A"}.00
                  </TableCell>
                  <TableCell>
                    <IconButton
                      className="pointer"
                      onClick={() => viewAction(row.orderId, id)}
                    >
                      <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <div className="float-right">
        <Pagination
          count={Math.ceil(totalCount / 10)}
          shape="rounded"
          size="small"
          onChange={(e, value) => {
            setSearchData({ ...searchData, offset: value - 1 });
          }}
        />
      </div>
      <br />
    </React.Fragment>
  );
}

export default ToSettle;
