import { Tab, TablePagination, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { TabPanel } from "../../../../../components/Tabs/TabPannel";
import { getIncomeOverviewData } from "../../../../../redux/actions/finance.action";
import IncomeReleased from "./IncomeReleased";
import IncomeToRelease from "./IncomeToRelease";


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MerchantIncome = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [incomeData,setIncomeData]=useState(null)
  
  const dispatch = useDispatch()

  const {mid}=useParams()
  // console.log(a,'dsfghjklfdgkjl');



  useEffect(() => {
    dispatch(getIncomeOverviewData(mid))
      .then(data => {
        console.log(data.data.data.PendingAmonut[0]?.total  , 'data');
        setIncomeData(data.data.data)
      }).catch(err => {
        console.log({ err }); 
      })
  }, [])

  return (
    <div>
      <h2>Income Overview</h2>

      <div className="d-flex flex-direction-row justify-content-space-between align-items-end">
        <div className="min-width-200">
          <div>To Release</div>
          <div className="text-secondary">Total</div>
          <div>RM{incomeData?.PendingAmonut[0]?.total || "0.000"}</div>
        </div>
        <div className="min-width-200">
          <div>Released</div>
          <div className="text-secondary">This Week</div>
          <div>RM{incomeData?.week[0].total || "0.000"}</div>
        </div>
        <div className="min-width-200">
          <div className="text-secondary">This Month</div>
          <div>RM{incomeData?.month[0].total || "0.000"}</div>
        </div>
        <div className="min-width-200">
          <div className="text-secondary">Total</div>
          <div>RM{incomeData?.totalAmount[0].total || "0.000"}</div>
        </div>
      </div>
      <br />
      <div className="row mr-1">
        <div className="col-md-6">
          <div className="merchant_income_statement py-3">
            <h6 className="px-3">Income Statement</h6>
            <div className="marchant_income_statement_section px-3">
              <div>
                <table className="w-100">
                  <tbody>
                    <tr className="">
                      <td className="text-secondary">Date</td>
                      <td
                        style={{ cursor: "pointer" }}
                        className="text-primary float-right"
                      >
                        Download
                      </td>
                    </tr>
                    <tr className="">
                      <td className="text-secondary">Date</td>
                      <td
                        style={{ cursor: "pointer" }}
                        className="text-primary float-right"
                      >
                        Download
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 pr-0">
          <div className="merchant_income_statement py-3">
            <h6 className="px-3">Tax Invoice</h6>
            <div className="marchant_income_statement_section px-3">
              <div>
                <table className="w-100">
                  <tbody>
                    <tr>
                      <td className="text-secondary">Date</td>
                      <td
                        style={{ cursor: "pointer" }}
                        className="text-primary float-right"
                      >
                        Download
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="container_body">
        <div className="card">
          <span>PRODUCT LISTING</span>
          <div className=" border card  my-3">
            <Tabs
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              variant="scrollable"
              orientation="horizontal"
              scrollButtons={false}
              visibleScrollbar={false}
              className="px-3"
              aria-label="basic tabs example"
            >
              <Tab label="To Release" {...a11yProps(0)} />
              <Tab label=" Released" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <IncomeToRelease />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <IncomeReleased />
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantIncome;
