import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import InputField from "../../../components/formControl/input/InputField";

import { postRedemption } from "../../../redux/actions/order.action";
import ablity from "../../../helpers/abilityGuard";
import { getLocalStorage } from "../../../lib/session";
import { postRedemptionByMerchant } from "../../../redux/actions/merchant/order.action";
import { AllowAccess, showToast } from "../../../lib/global";
import SelectDropdown from '../../../components/select';
import DateRangePickerComp from "../../../components/datepicker/MUIDateRange";
import RedemptionList from "./RedemptionList";
const initOrderRangeDate = {
  startDate: "",
  endDate: "",
};


const OrderListing = () => {
  AllowAccess(["SUPERADMIN","MERCHANT","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState("");
  const [redemptionCode, setRedemptionCode] = useState("");

  // const [search, setSearch] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [reset, setReset] = useState(false);
  const [message, setMessage] = useState("");
  const [productName, setProductName] = useState("");
  const [orderDateRange, setOrderDateRange] = useState(initOrderRangeDate);
  const [merchantName, setMerchantName] = useState("");
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState({
    merchantName: "",
    status: "",
    productName: "",
    orderDate: "",
  });

  const listStatus = [
    {
      label: "All",
      value: "",
    },
    {
      label: "New",
      value: "ACTIVE",
    },
    {
      label: "Redeemed",
      value: "REDEEMED",
    },
    {
      label: "Expired",
      value: "EXPIRED",
    }
  ]

  const handleDateChange = (data) => {
    setOrderDateRange(data);
  };

  useEffect(() => {
    // startLoader();
    const breadcrumb = [{ label: "Redemption", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
  }, [tabIndex]);

  const handleRedemption = () => {
    console.log(orderId, "+++++++++++++++++++++++++++");
    // console.log(redemptionCode, "+++++++++++++++++++++++++++");
    const response =
      getLocalStorage("role") == "SUPERADMIN"
        ? dispatch(postRedemption(orderId, redemptionCode))
        : dispatch(postRedemptionByMerchant(orderId, redemptionCode));
    response
      .then(({ data }) => {
        // console.log(data, "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
        setMessage(data.message);
        setReset(true);

        setSearch({
          merchantName: "",
          status: "",
          productName: "",
          orderDate: "",
        });

        showToast(data.message, "success",  3000, "bottom", "center");

      })
      .catch((err) => {
        setMessage(
          err.response.data != null ? err.response.data.message : err.message
        );
        setReset(true);

        setSearch({
          merchantName: "",
          status: "",
          productName: "",
          orderDate: "",
        });

        showToast(err.response.data.message, "error", 3000, "bottom", "center");

      });
  };
  const handleReset = () => {
    setOrderId("");
    setRedemptionCode("");
    setReset(false);
  };

  const handleResetSearch = () => {
    setMerchantName("");
    setStatus("");
    setProductName("");
    setOrderDateRange(initOrderRangeDate);
    setReset(true);
  }

  const searchData = () => {
    console.log(status,"STATUS")
    setSearch({
      merchantName,
      redemptionStatus:status.value,
      productName,
      ...orderDateRange,
    });
  }

  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-4 rounded bg-white mb-3">
        <div className="col-11 row flex-wrap">
          <div className="min-width-200 col-md-4">
            <label>Redemption Code</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="redemptionCode"
              value={redemptionCode}
              onChange={(e) => setRedemptionCode(e.target.value)}
            />
          </div>
        </div>
        {reset ? <span className="px-3">{message}</span> : null}

        <div className="d-flex justify-content-end mt-5 mr-5">
          {ablity({ model: "Order", action: "create" }) == 1 && (
            <button
              type="button"
              className="btn input-inside-text mr-1 btn-white border px-4"
              onClick={() => handleReset()}
            >
              Reset
            </button>
          )}
          {ablity({ model: "Order", action: "create" }) == 1 && (
            <button
              type="button"
              className="btn input-inside-text mx-1 btn-danger px-4"
              onClick={handleRedemption}
            >
              Redeem
            </button>
          )}
        </div>
      </div>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-11 row flex-wrap">
          {!(getLocalStorage("role") === "MERCHANT" )&& (
            <div className="min-width-200 col-md-3">
              <label>Merchant Name</label>
              <InputField
                type="text"
                className="form-control"
                placeholder="Input"
                id="merchantName"
                value={merchantName}
                onChange={(e) => setMerchantName(e.target.value)}
              />
            </div>
          )}
          <div className="min-width-200 col-md-3">
            <label class="mb-3">Status</label>
            <SelectDropdown
                className="form-control"
                options={listStatus}
                placeholder="Select Status"
                value={status}
                onChange={setStatus}
            />
          </div>
          <div className="min-width-200 col-md-3">
            <label>Product Name</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="productName"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-3">
            <label className=""> Expired Date</label>

            <DateRangePickerComp
              reset={reset}
              setReset={setReset}
              handleDateChange={handleDateChange}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn input-inside-text mr-1 btn-white border px-4"
            onClick={() => handleResetSearch()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn input-inside-text mx-1 btn-secondary px-3"
            onClick={() => searchData()}
          >
            Search
          </button>
        </div>
      </div>
      <div className=" container_body container-fluid mt-3">
        <div className="card p-3">
          <span>REDEMPTION LISTING</span>
          <RedemptionList
              search={search}
              reset={handleResetSearch}
            />
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderListing;
