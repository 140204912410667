import {
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,

} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../../components/Images/SvgIcon";
import { getAllProductList, getProductsBymerchantId, getSortedProducts } from "../../../redux/actions/product.action";
import moment from "moment";
// import { getAllCategoryApi } from "../../../services/category.service";
// import {
//     startLoader,
//     stopLoader,
//     showToast    
// } from "../../../lib/global";

import { startLoader, stopLoader, showToast, open_dialog, AllowAccess } from "../../../lib/global";
import { ACCESS_DENIED, PRODUCT_DELETE_DIALOG } from "../../../lib/constant";
import { getLocalStorage } from "../../../lib/session";
import ablity from "../../../helpers/abilityGuard";

const initialData = [
    {
        date: "12-02-2022",
        name: "amenda sentia",
        desc: "mentitor amendar cartor",
    },
    {
        date: "12-02-2022",
        name: "amenda sentia",
        desc: "mentitor amendar cartor",
    },
]

const BroadcastManagementCreate = () => {
    AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
    const [createBroadcastData, setCreateBroadcastData] = useState(initialData)
 

    return (
        <React.Fragment >

            <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell
                                className="th_cell"
                                component="th"
                                align="center"
                            >
                                <div className="d-flex  ">
                                    <div>Date</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            onClick={() => getApiData({ item: "title", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            onClick={() => getApiData({ item: "title", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                className="th_cell"
                                component="th"
                                align="center"
                            >
                                <div className="d-flex  ">
                                    <div>Broadcast Name</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            onClick={() => getApiData({ item: "title", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            onClick={() => getApiData({ item: "title", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                className="th_cell "
                                component="th"
                                align="center"
                            >
                                <div className="d-flex  ">
                                    <div>Broadcast Description</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>

                            <TableCell className="table_action_head" component="th">
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {!allProductData?.length && (
                            <div className="d-flex justify-content-center">
                                <h3 className="text-muted m-auto">No data found!</h3>
                            </div>
                        )} */}
                        {createBroadcastData &&
                            createBroadcastData.length > 0 &&
                            createBroadcastData.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                    }}

                                >

                                    <TableCell align="left" className="td_cell">
                                        {row.date || "0"}
                                    </TableCell>
                                    <TableCell align="left" className="td_cell">
                                        {row.name || "N/A"}
                                    </TableCell>
                                    <TableCell align="left" className="td_cell">
                                        {row.desc || "N/A"}
                                    </TableCell>

                                    <TableCell className="d-flex justify-content-between table_action_item">
                                        {ablity({model:'Merchant', action:'read'}) == 1 && (
                                                <IconButton
                                                    // onClick={() => history.push(`edit?id=${row.productId}`)}
                                                    className="pointer"
                                                // onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}
                                                >
                                                    <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                                                </IconButton>
                                        )}

                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={10}
                component="div"
                // count={allProductData.length}
                count={2}
                rowsPerPage={10}
                page={0}
                onPageChange={(e) => {
                    console.log("page change", e);
                }}
            // onRowsPerPageChange={(e)=>{console.log('page change', e)}}
            />

        </React.Fragment >
    );
}

export default BroadcastManagementCreate;