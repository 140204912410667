import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Link, useHistory } from "react-router-dom";
import Image from "../../../components/Images/Image";
import SvgIcon from "../../../components/Images/SvgIcon";
import { getAllSubCategoriesList } from "../../../redux/actions/category.action";
import { useDispatch } from "react-redux";
import {
  parseQuery,
  parseParam,
  startLoader,
  stopLoader,
  open_dialog,
  AllowAccess,
} from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { DELETE_CATEGORY } from "../../../lib/constant";
function ProductSubCategory() {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const history = useHistory();
  const dispatch = useDispatch();
  // const [title, setTitle] = useState()
  const [categoryData, setCategoryData] = useState([]);
  const [totalCategory, setTotalCategory] = useState(0);
  const [totalDisableCategory, setTotalDisableCategory] = useState(0);
  const [mainCategory, setMainCategory] = useState(0);
  const [totalActiveCategory, setTotalActiveCategory] = useState(0);
  const [Id, setId] = useState();
  const [searchData, setSearchData] = useState("");

  const addCategory = () => {
    history.push("/products/add-category");
  };
  useEffect(() => {
    const { p = "" } = parseQuery(history.location.search) || {};
    const paramList = parseParam(history.location.pathname);
    console.log(paramList[2], "llllllllllllllllllllllllllllllllllllllllll");
    const categoryId = paramList[2];
    const breadcrumb = [
      { label: "Product Categories", url: "/products/categories" },
      {
        label: p,
        url: null,
      },
    ];
    dispatch(breadcrumbAction(breadcrumb));
    startLoader();
    console.log(Id, "lllllllllllllllll");
    const dataForSearch = { search: searchData };

    if (!Id) {
      const response = dispatch(
        getAllSubCategoriesList(categoryId, dataForSearch)
      );
      response
        .then((data) => {
          if (data && data.data && data.data.status === 200) {
            // console.log("datafffffffffffffffffffffffffffffff", data);
            stopLoader();
            setCategoryData(data && data.data && data.data.data);
            setTotalCategory(data && data.data.TotelCategory);
            setTotalDisableCategory(data && data.data.disableCategory);
            setTotalActiveCategory(data && data.data.activeCategory);
            setMainCategory(data && data.data.mainCategory);
          } else {
            stopLoader();
          }
        })
        .catch((error) => console.log("----------error----", error));
    } else {
      const response = dispatch(getAllSubCategoriesList(Id));
      response.then((data) => {
        // console.log(data && data.data && data.data.data, "categoryId    categoryId");
        setCategoryData(data && data.data && data.data.data);
        setTotalCategory(data && data.data.TotelCategory);
        setTotalDisableCategory(data && data.data.disableCategory);
        setMainCategory(data && data.data.mainCategory);
        setTotalActiveCategory(data && data.data.activeCategory);
        stopLoader();
      });
    }
  }, [searchData, Id]);

  const getProductAfterDelete = (data) => {
    console.log(data, "jjjkkkjkkk");
    setCategoryData(data && data.data && data.data.data);
    setTotalCategory(data && data.data.TotelCategory);
    setTotalDisableCategory(data && data.data.disableCategory);
    setTotalActiveCategory(data && data.data.activeCategory);
  };

  const deleteCategory = (id) => {
    console.log(id, "fghj");
    open_dialog(DELETE_CATEGORY, {
      onSubmit: getProductAfterDelete,
      id,
    });
  };

  const subCategory = (Id) => {
    // console.log(Id, "categoryId    categoryId");
    setId(Id);
    startLoader();
    const response = dispatch(getAllSubCategoriesList(Id));
    response.then((data) => {
      // console.log(data && data.data && data.data.data, "categoryId    categoryId");
      setCategoryData(data && data.data && data.data.data);
      setTotalCategory(data && data.data.TotelCategory);
      setTotalDisableCategory(data && data.data.disableCategory);
      setTotalActiveCategory(data && data.data.activeCategory);
      stopLoader();
    });
  };
  const editCategory = (id) => {
    history.push(`/products/edit-category/${id}`);
  };
  const onChangeSeachData = (data) => {
    setSearchData(data);
  };

  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof categoryData[0][item] === "number") {
        let sortedData = categoryData.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setCategoryData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof categoryData[0][item] === "string" && !isDate) {
        let sortedData = categoryData.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
        console.log(sortedData, "llllllllll");
        setTimeout(() => {
          setCategoryData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof categoryData[0][item] === "string" && isDate) {
        let sortedData = categoryData.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          console.log(da, db);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setCategoryData([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      console.log({ error });
      stopLoader();
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="px-2 py-3 mb-3 card">
          <div className=" px-4 py-3 bg-body bg-white rounded shadow-sm">
            <span>OVERVIEW</span>
            <div className="py-3 row">
              <div className="col-3">
                <div className="card p-4 border overview-card">
                  <p className="m-0 text-muted p-0 input-inside-text">
                    Total Category
                  </p>
                  <h4>{totalCategory}</h4>
                </div>
              </div>
              <div className="col-3">
                <div className="card p-4 rounded overview-card border">
                  <p className="m-0 text-muted p-0 input-inside-text ">
                    Main Category
                  </p>
                  <h4>{mainCategory}</h4>
                </div>
              </div>
              <div className="col-3">
                <div className="card p-4 rounded overview-card border">
                  <p className="m-0 text-muted p-0 input-inside-text ">
                    Active Category
                  </p>
                  <h4>{totalActiveCategory}</h4>
                </div>
              </div>
              <div className="col-3">
                <div className="card p-4 overview-card rounded border">
                  <p className="m-0 text-muted p-0 input-inside-text">
                    Disable Category
                  </p>
                  <h4>{totalDisableCategory}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-3">
          <div className=" px-4 py-3 bg-body bg-white rounded shadow-sm">
            <div className="d-flex">
              <IconButton onClick={history.goBack}>
                <KeyboardBackspaceIcon />
              </IconButton>
              <span className="my-auto">CATEGORY LISTING</span>
            </div>
            <div className="border card p-3 my-3 ">
              <div className="d-flex justify-content-between">
                <div>
                  <span>All Categories</span>
                </div>
                <div className="d-flex my-3">
                  <input
                    type="search"
                    placeholder="search..."
                    className="form-control mx-3"
                    onChange={(e) => onChangeSeachData(e.target.value)}
                  />
                  <button
                    onClick={() => addCategory()}
                    type="button"
                    className="btn btn-dark btn-sm text-nowrap mx-3"
                  >
                    {" "}
                    + Add Category
                  </button>
                </div>
              </div>
              <div className="card-body">
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell className="th_cell" component="th">
                          No
                        </TableCell>
                        <TableCell
                          className="th_cell"
                          component="th"
                          align="center"
                        >
                          <div className="d-flex  ">
                            <div>Name</div>
                            <div className="d-flex flex-column ">
                              <ExpandLessIcon
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "0px 3px",
                                }}
                                onClick={() => handleSorting("title", "asc")}
                              />
                              <ExpandMoreIcon
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "-5px 3px",
                                }}
                                onClick={() => handleSorting("title", "desc")}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell"
                          component="th"
                          align="right"
                        >
                          <div className="d-flex  ">
                            <div>Products</div>
                            <div className="d-flex flex-column ">
                              <ExpandLessIcon
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "0px 3px",
                                }}
                                onClick={() => handleSorting("product", "asc")}
                              />
                              <ExpandMoreIcon
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "-5px 3px",
                                }}
                                onClick={() => handleSorting("product", "desc")}
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell"
                          component="th"
                          align="center"
                        >
                          <div className="d-flex  ">
                            <div> Sub Categories</div>
                            <div className="d-flex flex-column ">
                              <ExpandLessIcon
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "0px 3px",
                                }}
                                onClick={() =>
                                  handleSorting("SubCategory", "asc")
                                }
                              />
                              <ExpandMoreIcon
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "-5px 3px",
                                }}
                                onClick={() =>
                                  handleSorting("SubCategory", "desc")
                                }
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell"
                          component="th"
                          align="center"
                        >
                          <div className="d-flex  ">
                            <div> Relevent Merchant</div>
                            <div className="d-flex flex-column ">
                              <ExpandLessIcon
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "0px 3px",
                                }}
                                onClick={() => handleSorting("merchant", "asc")}
                              />
                              <ExpandMoreIcon
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "-5px 3px",
                                }}
                                onClick={() =>
                                  handleSorting("merchant", "desc")
                                }
                              />
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell"
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div> Status</div>
                            <div className="d-flex flex-column ">
                              <ExpandLessIcon
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "0px 3px",
                                }}
                                onClick={() => handleSorting("status", "asc")}
                              />
                              <ExpandMoreIcon
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  margin: "-5px 3px",
                                }}
                                onClick={() => handleSorting("status", "desc")}
                              />
                            </div>
                          </div>
                        </TableCell>

                        <TableCell className="table_action_head" component="th">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!categoryData?.length && (
                        // <div className="d-flex justify-content-center">
                        <h3 className="text-muted m-auto">No data found!</h3>
                        // </div>
                      )}
                      {categoryData?.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell align="center" className="td_cell">
                            {index + 1}
                          </TableCell>
                          <TableCell align="left" className="td_cell">
                            {row.title || "N/A"}
                          </TableCell>
                          <TableCell align="center" className="td_cell">
                            {row.product || "0"}
                          </TableCell>
                          <TableCell
                            align="center"
                            style={{
                              color: "blue",
                              textDecorationLine: "underline",
                            }}
                            className="td_cell"
                            onClick={() => subCategory(row.id)}
                          >
                            {row.SubCategory || "0"}
                          </TableCell>
                          <TableCell align="center" className="td_cell">
                            {row.merchant || "0"}
                          </TableCell>
                          <TableCell align="center" className="td_cell">
                            {row.status || "N/A"}
                          </TableCell>

                          <TableCell className="d-flex justify-content-between table_action_item">
                            <IconButton
                              className="pointer"
                              onClick={() => editCategory(row.id)}
                            >
                              <SvgIcon
                                src="/icons/edit_icon.svg"
                                alt="view icon"
                              />
                            </IconButton>
                            <IconButton
                              className="pointer"
                              onClick={() => deleteCategory(row.id)}
                            >
                              <SvgIcon
                                src="/icons/delete_icon.svg"
                                alt="delete icon"
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <hr />
              <TablePagination
                rowsPerPageOptions={10}
                component="div"
                count={categoryData?.length || 0}
                rowsPerPage={10}
                page={0}
                onPageChange={(e) => {
                  console.log("page change", e);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductSubCategory;
