import React, { useState } from 'react'
import "../../static/css/productCss/allProduct.css"
import Image from "../../components/Images/Image"
import { useHistory } from "react-router-dom"
import { AllowAccess } from '../../lib/global'

const ProductSalesInformation = () => {
    AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
    const history = useHistory()

    // const [addvariation, setAddvariation] = useState(false)
    const Addvariation = () => {
        history.push("/product-add-variation")

    }
    const backproduct = () => {
        history.push("/all-products")
    }
    const backAddproduct = () => {
        history.push("/products/add-product")

    }
    const prodcutShippingInformation = () => {
        history.push("/shipping-information")
    }
    return (
        <React.Fragment>
            <div>
                <div>
                    <div className="d-flex justify-content-center  p-3">
                        <small >Basic Information</small>
                        <hr className="process-line mx-2" />
                        <div className="d-flex flex-column align-items-center">
                            <small className="m-0 p-0 h-0">Sales Information</small>
                            <p className="process-indicate m-0"></p>
                        </div>
                        <hr className="process-line mx-2" />
                        <small className="text-muted">Shipping information</small>
                    </div>
                </div>

                 <div className="mr-3 ml-4 rounded  px-3 pt-4 pb-2 bg-white">
                    <h6 className="my-0">SALES INFORMATION</h6>
                    <form className="mt-3  p-0">
                        <div className="form-group d-flex align-items-center">
                            <label className="col-xl-2 p-0 d-flex justify-content-end"><p className="my-0 input-inside-text mr-4">Variation</p></label>
                            <div className="input-group col-xl-6 border p-0 mb-2 rounded">
                                <button type="button" className=" input-inside-text form-control border-0 justify-content-center d-flex align-items-center " onClick={() => Addvariation()}>+ Add variation</button>
                            </div>
                        </div>
                        <div className="form-group d-flex align-items-center ">
                            <label htmlFor="product--price" className="col-xl-2 p-0 d-flex justify-content-end"><p className="my-0 input-inside-text mr-4">Price</p></label>
                            <div className="input-group col-xl-6 p-0  rounded">
                                <div className="input-group mb-2 ">
                                    <span className="input-group-text border-right-0 rounded-start input-inside-text rounded-right-0 pl-2 pr-0 py-0 bg-white" id="basic-addon1">RM</span><span className="input-group-text input-inside-text px-1 m-0 bg-white border-left-0 border-right-0 rounded-0">|</span>
                                    <input type="text" className="form-control product-sale-price-input  input-inside-text border-left-0 p-0" id="product--price" placeholder=" Input" aria-describedby="basic-addon1" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group d-flex align-items-center  ">
                            <label htmlFor="product-stock" className="col-xl-2 d-flex justify-content-end p-0"><p className="my-0 input-inside-text mr-4">Stock</p></label>
                            <div className="input-group col-xl-6 border p-0  rounded">
                                <input type="text" className=" form-control input-inside-text" id="product-stock" placeholder="  Input" />
                            </div>
                        </div>
                    </form>
                </div> 

                <div className="mr-3 ml-4 mt-3 rounded  px-3 pt-4 pb-2 bg-white">
                    <h6>TEAM BUY INFORMATION</h6>
                    <form className="mt-3  p-0">
                        <div className="form-group d-flex  align-items-center">
                            <label htmlFor="product--price" className="col-xl-2 d-flex justify-content-end p-0"><p className="my-0 input-inside-text mr-4">Price</p></label>
                            <div className="input-group col-xl-6 p-0  rounded">
                                <div className="input-group mb-2 ">
                                    <span className="input-group-text border-right-0 rounded-start input-inside-text rounded-right-0 pl-2 pr-0 py-0 bg-white" id="basic-addon1">RM</span><span className="input-group-text input-inside-text px-1 m-0 bg-white border-left-0 border-right-0 rounded-0">|</span>
                                    <input type="text" className="form-control product-sale-price-input  input-inside-text border-left-0 p-0" id="product--price" placeholder=" Input" aria-describedby="basic-addon1" />
                                </div>
                            </div>
                        </div>
                        <div className="form-group d-flex align-items-center  ">
                            <label htmlFor="product-name" className="col-xl-2 d-flex justify-content-end p-0"><p className="my-0 input-inside-text mr-4">Member</p></label>
                            <div className="input-group col-xl-6  p-0  align-items-center rounded">
                                <button type="button" className="btn py-1 px-2 btn-light">-</button>
                                <span className="p-1 mx-2">2</span>
                                <button type="button" className="btn py-1 px-2 btn-light">+</button>
                            </div>
                        </div>
                        <div className="form-group d-flex align-items-center  ">
                            <label  className="col-xl-2 d-flex justify-content-end p-0"><p className="my-0 input-inside-text mr-4">Unit Capped</p></label>
                            <div className="input-group col-xl-6  p-0  align-items-center rounded">
                                <button type="button" className="btn py-1 px-2 btn-light">-</button>
                                <span className="p-1 mx-2">2</span>
                                <button type="button" className="btn py-1 px-2 btn-light">+</button>
                            </div>
                        </div>
                        <div className="form-group d-flex align-items-center   ">
                            <label htmlFor="product--stock " className="col-xl-2 d-flex justify-content-end p-0"><p className="my-0 input-inside-text mr-4">Stock</p></label>
                            <div className="input-group col-xl-6 border p-0  rounded">
                                <input type="text" className=" form-control input-inside-text" id="product--stock" placeholder="  Input" />
                            </div>
                        </div>
                       
                    </form>
                </div>
                <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
                    <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
                        <button type="button" className="btn border input-inside-text mr-2" onClick={() => backproduct()}>Cancel</button>
                        <button type="button" className="btn border input-inside-text mr-2" onClick={() => backAddproduct()}>Prev</button>
                        <button type="button" className="btn border input-inside-text mr-2 text-nowrap" >Save & Deactivate</button>
                        <button type="button" className="btn input-inside-text btn-dark" onClick={() => prodcutShippingInformation()}>Next</button>
                    </div>
                </footer>
            </div>
        </React.Fragment>
    )
}

export default ProductSalesInformation
