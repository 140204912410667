export const APP_NAME = "UU2 Admin";
export const THEME = "dark";

export const APP_LOGO = "/icons/logo.png";
export const LOGIN_POSTER = "/images/broken-image.png";
export const BROKEN_IMAGE = "/images/login_poster.jpeg";
export const DASHBOARD_ICON = "/icons/dashboard_icon.png";

export const API_HOST = process.env.REACT_APP_API_HOST;

export const PY_API_HOST = process.env.REACT_APP_PY_API_HOST;
export const SENDBIRD_API_KEY = process.env.REACT_APP_SENDBIRD_API_KEY
export const ONESIGNAL_API_ID = process.env.REACT_APP_ONESIGNAL_API_ID

export const BASIC_AUTH = "Basic bWVldC5hcHBzY3JpcEBnbWFpbC5jb206M0VtYmVkMDA3";
export const DRAWER_CLOSE = "/cross.png";
export const DRAWER_TOASTER_TIME = 3000;

export const MAX_IMAGE_SIZE = 3000000; // 3MB
// cloudinary creds
export const CLOUDINARY_UPLOAD_PRESET = "ecruocymedu";
export const CLOUDINARY_CLOUD_NAME = "jagannath";
export const CLOUDINARY_API_BASE = "https://api.cloudinary.com/v1_1/";

export const SENDBIRD_ADMIN_ID = process.env.SENDBIRD_ADMIN_ID || "UU2M0F0";
