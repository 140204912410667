import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { App as SendBirdApp } from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import { getLocalStorage } from "../../../lib/session";
import { SENDBIRD_API_KEY } from "../../../lib/config";
import { AllowAccess } from "../../../lib/global";

function MerchantChat() {
  AllowAccess(["SUPERADMIN","MERCHANT","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const dispatch = useDispatch()
  const breadcrumb = [
    { label: "Dashboard", url: "/dashboard" },
    { label: "Chat", url: null }
  ];

  const userId   = getLocalStorage('storeId')?getLocalStorage('storeId'):getLocalStorage('uid');
  const appId    = SENDBIRD_API_KEY;

  dispatch(breadcrumbAction(breadcrumb));

  return (
        <SendBirdApp
          appId={appId}
          userId={userId}
        />
  )
}

export default MerchantChat
