import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import React, { useEffect, useState } from "react";
import Image from "../../../components/Images/Image";

const RefundChatLog = () => {
  const [assignChat, setAssignChat] = useState(" ")
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const breadcrumb = [
      { label: "Return/Refund", url: "/finance/return-refund" },
      { label: `Order ID `, url: "/finance/return-refund/refund-request" },
      { label: `Chat Log `, url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
  },[]);
  return (
    <React.Fragment>
      <div className="container-fluid position-relative row mx-0">
        <div className="col-8">
          <div className="d-flex">
            <p role="button" onClick={history.goBack}>
              &lt; Back
            </p>
          </div>
          <div>REQUEST REFUND INFORMATION</div>
          <div className="card d-flex flex-column justify-content-between my-3 vh-100">
            <div className="p-3">
              <div className="fntSz13">
                <div>Waiting for seller to respond</div>
                <div className="text-secondary fntSz12">
                  Seller needs to respond by 11/12/2022, otherwise it will be
                  automatically accepted and refund to buyer.
                </div>
                <div className="text-secondary fntSz12">
                  Seller is able to discuss with buyer for other possible
                  solutions.
                </div>
              </div>
              <br />
              <div style={{ background: "#E2E2E8" }} className="p-3 rounded-lg">
                <div className="fntSz13 mb-1">
                  Buyer has proposed Refund of RM34.00
                </div>
                <div className="fntSz12">
                  Please refund as I order keyboard not this.
                </div>
                <div className="fntSz11 text-secondary">11/12/2022 14:00pm</div>
              </div>
              <br />
              <div className="row">
                <div className="col-1 pt-1">
                  <Image
                    src="/mouse-img.png"
                    alt={"photo"}
                    width="40px"
                    height="40px"
                    className="overflow-hidden rounded-circle"
                  />
                </div>
                <div className="col-10  fntSz12">
                  <div>Cristo Mentren</div>
                  <div>Hellooooo!!! Please respond</div>
                  <div className="fntSz11 text-secondary">
                    11/12/2022 11:00pm
                  </div>
                </div>
              </div>
            </div>
            <div>
              <hr />
              <div className={`row align-items-center pl-4 pb-2 d-${assignChat == !"AssignToMe" ? "" : "none"}`}>
                <div className="col-9 text-secondary fntSz14">
                  Assign to me to start the chat.
                </div>
                <div className="col-3">
                  <button onClick={() => setAssignChat("AssignToMe")} className="btn btn-outline-danger text-nowrap fntSz13">
                    Assign To Me
                  </button>
                </div>
              </div>
              <div className={`row align-items-center pl-3 pr-2 pb-2 d-${assignChat == "AssignToMe" ? "" : "none"}`}>
                <div className="col-1 pr-1">
                  <Image
                    src="/imageIcon.png"
                    alt={"photo"}
                    // width="40px"
                    // height="40px"
                  />
                </div>
                <div className="col-9 pl-0">
                  <input type="text" className="form-control" placeholder="Type a message"/>
                </div>
                <div className="col-2 pl-0">
                  <button className="btn btn-secondary fntSz13 px-4" disabled>
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 p-0">
          <div
            style={{ top: "0", right: "0" }}
            className=" position-sticky  p-3 card my-5 fntSz13"
          >
            <div className="mb-3 ">
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">status</div>
                <div className="font-weight-bold" style={{ fontSize: "1rem" }}>
                  Merchant To Respond
                </div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Case ID</div>
                <div className="">
                  #390174
                  {/* {"#" + "012201" + (fullData?.orderId?.slice(-2) || "N/A")} */}
                  {/* {fullData?.orderId || "N/A"} */}
                </div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Order ID</div>
                <div className="">
                  #892345
                  {/* {"#" + "012201" + (fullData?.orderId?.slice(-2) || "N/A")} */}
                  {/* {fullData?.orderId || "N/A"} */}
                </div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Transaction Type</div>
                <div className="">Buy Now</div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Agent Name</div>
                <div className={`text-${assignChat == "AssignToMe" ? "" : "danger"}`}>Yet to assign</div>
              </div>
            </div>

            <div className="font-weight-bolder py-1 fntSz15">Payment</div>
            <div className="mb-3 ">
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Payment Method</div>
                <div className="">
                  Online
                  {/* {fullData?.paymentMethods || "N/A"} */}
                  {/* Creadit/Debit Card */}
                </div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Payment Date</div>
                <div className="">11/12/2022</div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Ref ID</div>
                <div className="">120-48792</div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Settlement Status</div>
                <div className="">settled</div>
              </div>
            </div>

            <div className="font-weight-bolder py-1 fntSz15 ">
              Shipping Details
            </div>
            <div className="mb-3">
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Merchant Name</div>
                <div className="">
                  {/* {fullData?.merchantName || "N/A"} */}
                  Nike eStore
                </div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Merchant ID</div>
                <div className="">
                  {/* {fullData?.merchantName || "N/A"} */}
                  Nike eStore
                </div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Shipping</div>
                <div className="">
                  {/* {fullData?.shippingDetails?.shippingType || "N/A"} */}
                  dshth
                </div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Courier</div>
                <div className="">
                  {/* {fullData?.shippingDetails?.courierName || "N/A"} */}
                  dfhgjnbv
                </div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Tracking Number</div>
                <div className="">
                  {/* {fullData?.shippingDetails?.trackingNo || "N/A"} */}
                  35674334
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RefundChatLog;
