import { Divider } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { breadcrumbAction } from "../../../../../redux/actions/config.action";
import { createGroupDeals } from "../../../../../redux/actions/marketing.action"
import { AllowAccess, showToast, stopLoader } from "../../../../../lib/global";

const GroupDealsCreate = () => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const history = useHistory()
  const dispatch = useDispatch()
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [merchantLimit, setMerchantLimit] = useState()
  const [productLimit, setProductLimit] = useState()
  const [consumerLimit, setConsumerLimit] = useState()
  const [merchantLimitQuota, setMerchantLimitQuota] = useState()
  const [productLimitQuota, setProductLimitQuota] = useState()
  const [consumerLimitQuota, setConsumerLimitQuota] = useState()

  useEffect(() => {
    const breadcrumb = [
      { label: "All Promo", url: "/marketing/all-promo" },
      { label: "Group Deals", url: "/marketing/all-promo/group-deals" },
      { label: "Create", url: null }
    ];

    dispatch(breadcrumbAction(breadcrumb));
  }, []);

  const handleInput = async (e, type) => {
    if (type === 'merchantLimitFalse'){
      setMerchantLimit(false)
      setMerchantLimitQuota(0)
    }
    else if (type === 'productLimitFalse'){
      setProductLimit(false)
      setProductLimitQuota(0)
    }
    else if (type === 'consumerLimitFalse'){
      setConsumerLimit(false)
      setConsumerLimitQuota(0)
    }
  }

  const onSubmit = async () => {
    let payload = {
      startDate,
      endDate,
      merchantLimit,
      productLimit,
      consumerLimit,
      merchantLimitQuota,
      productLimitQuota,
      consumerLimitQuota,
    }

    dispatch(createGroupDeals(payload))
      .then((data) => {
          setTimeout(() => {
            stopLoader();
            showToast(data.data.message, "success");
            history.push("/marketing/all-promo/group-deals");
          }, 1000);
      })
      .catch((error) => {
        let errorData = {error};
        showToast(errorData.error.response.data.message, "error");
      });
  }



  return (
    <React.Fragment>
      <div className=" d-flex flex-column justify-content-between container_body vh-100">
        <div className="container-fluid mb-5">
          <div className="card p-3">
            <h6>Create Group Deal</h6>
            <br />

            <div className="row mb-2">
              <label className="col-3 d-flex justify-content-end align-items-center">Start Date</label>
              <div className="col-9">
                <input
                  type="date"
                  className="mr-2"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
            </div>
            <div className="row mb-2">
              <label className="col-3 d-flex justify-content-end align-items-center">End Date</label>
              <div className="col-9">
                <input
                  type="date"
                  className="mr-2"
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-2">
              <label className="col-3 d-flex justify-content-end">Merchant Limit</label>
              <div className="col-9 ">
                <div className="d-flex flex-row">
                  <div className="pr-4">
                    <input
                      // onClick={() => setMerchantLimit(false)}
                      onClick={(e) => handleInput(e, "merchantLimitFalse")}
                      type="radio"
                      name="merchant_limit"
                      className="btn"
                      checked={merchantLimit == false ? true : ""}
                      value={merchantLimit == false ? merchantLimit : ""}

                    />
                    <label className="px-2">No</label>
                  </div>
                  <div className="pr-4">
                    <input
                      onClick={() => setMerchantLimit(true)}
                      type="radio"
                      name="merchant_limit"
                      className="btn"
                      checked={merchantLimit == true ? merchantLimit : ""}
                      value={merchantLimit == true ? merchantLimit : ""}
                    />
                    <label className="px-2">Yes</label>
                    <div className={`${merchantLimit == true ? "d-block" : "d-none"}`}>
                      <input
                        type="text"
                        className="col-6  form-control"
                        value={merchantLimitQuota != null ? merchantLimitQuota : 0}
                        onChange={(e) => setMerchantLimitQuota(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <label className="col-3 d-flex justify-content-end">Product Limit Per Merchant</label>
              <div className="col-9 ">
                <div className="d-flex flex-row">
                  <div className="pr-4">
                    <input
                      // onClick={() => setProductLimit(false)}
                      onClick={(e) => handleInput(e, "productLimitFalse")}
                      type="radio"
                      name="product_limit"
                      className="btn"
                      checked={productLimit == false ? true : ""}
                      value={productLimit == false ? productLimit : ""}
                    />
                    <label className="px-2">No</label>
                  </div>
                  <div className="pr-4">
                    <input
                      onClick={() => setProductLimit(true)}
                      type="radio"
                      name="product_limit"
                      className="btn"
                      checked={productLimit == true ? productLimit : ""}
                      value={productLimit == true ? productLimit : ""}
                    />
                    <label className="px-2">Yes</label>
                    <div className={`${productLimit == true ? "d-block" : "d-none"}`}>
                      <input
                        type="text"
                        className="col-6  form-control"
                        value={productLimitQuota != null ? productLimitQuota : 0}
                        onChange={(e) => setProductLimitQuota(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-2">
              <label className="col-3 d-flex justify-content-end">Consumer Purchase Limit</label>
              <div className="col-9 ">
                <div className="d-flex flex-row">
                  <div className="pr-4">
                    <input
                      // onClick={() => setConsumerLimit(false)}
                      onClick={(e) => handleInput(e, "consumerLimitFalse")}
                      type="radio"
                      name="consumer_purchase_limit"
                      className="btn"
                      checked={consumerLimit == false ? true : ""}
                      value={consumerLimit == false ? consumerLimit : ""}
                    />
                    <label className="px-2">No</label>
                  </div>
                  <div className="pr-4">
                    <input
                      onClick={() => setConsumerLimit(true)}
                      type="radio"
                      name="consumer_purchase_limit"
                      className="btn"
                      checked={consumerLimit == true ? consumerLimit : ""}
                      value={consumerLimit == true ? consumerLimit : ""}
                    />
                    <label className="px-2">Yes</label>
                    <div className={`${consumerLimit == true ? "d-block" : "d-none"}`}>
                      <input
                        type="text"
                        className="col-6  form-control"
                        value={consumerLimitQuota != null ? consumerLimitQuota : 0}
                        onChange={(e) => setConsumerLimitQuota(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>


        </div>
        <div className="">
          <footer className="bg-white p-3 ">
            <div className="d-flex justify-content-end">
              <button
                onClick={() => history.push("/marketing/all-promo/group-deals")}
                type="button"
                className="btn btn-outline-dark mx-2"
              >
                Back
              </button>
              <button
                type="button"
                className="btn btn-dark text-light text-nowrap btn-outline-dark mx-2"
                onClick={() => onSubmit()}
              >
                Save
              </button>

            </div>
          </footer>
        </div>
      </div>
    </React.Fragment>
  )
}

export default GroupDealsCreate;