import { DeleteWithToken, PutWithToken, PostWithToken,GetWithToken } from "../lib/request"

export const getAllCommisionRate = (query) => {
    return async function (dispatch) {
        const result = await GetWithToken(`/v2/admin/marketing/commision-rate?merchantName=${query.merchantName || ""}&storeId=${query.storeId || ""}&order=${query.order || ""}&offset=${query.offset || 0}&limit=${query.limit || 0}`);
        return result
    }
}

export const getCommisionRateById = (id) => {
    return async function (dispatch) {
        const result = await GetWithToken(`/v2/admin/marketing/commision-rate/${id}`);
        return result
    }
}

export const addCommisionRate = (data) => {
    return async function (dispatch) {
        const result = await PostWithToken('/v2/admin/marketing/commision-rate', data)
        return result
    }
}

export const updateCommisionRateById = (id, data) => {
    return async function (dispatch) {
        const result = await PutWithToken(`/v2/admin/marketing/commision-rate/${id}`, data)
        return result
    }
}

export const deleteCommisionRateById = (id) => {
    return async function (dispatch) {
        const result = await DeleteWithToken(`/v2/admin/marketing/commision-rate/${id}`);
        return result
    }
}

