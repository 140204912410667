import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { string } from "yup";
import Datepicker from "../../../components/datepicker/datepicker";
import InputField from "../../../components/formControl/input/InputField";
import RadioButtonsGroup from "../../../components/radio-button/RadioButtonsGroup";
import SelectInput from "../../../components/select";
import {
    ADD_AUTHORIED,
    ADD_BUSINESS_PARTNER,
    ADD_MERCHANT,
    ADD_SHIPPING_ADDRESS,
    ADD_SUB_ACCOUNT,
    EDIT_BANK_ACCOUNT_DIALOG,
    EDIT_MERCHANT_ACCOUNT_DIALOG,
    EDIT_PARTNERS_ACCOUNT_DIALOG,
    EDIT_SUB_ACCOUNT_DIALOG,
    EDIT_MERCHANT_SHIPPING_ADDRESS_DIALOG,
} from "../../../lib/constant";
import {
    open_dialog,
    showToast,
    startLoader,
    stopLoader,
    UploadImage,
} from "../../../lib/global";
import { getLocalStorage } from "../../../lib/session";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import {
    addNewBusinessMerchant,
    addNewMerchant,
    generateMerchantIdAction,
    getMerchnatById,
    sendEmailToMerchantToLogin,
    updateMerchantByMercnahtId,
} from "../../../redux/actions/merchant-center.action";

import { statusData } from "../../../shared/language";
const merchantTypeOptions = [
    { label: "Individual", value: "Individual",disabled:true },
    { label: "Business", value: "Business",disabled:true },
];
const businessTypeOptions = [
    { label: "Sole Proprietor", value: "Sole Proprietor" ,disabled:true },
    { label: "Government Entity", value: "Government Entity" ,disabled:true },
    { label: "Partnership", value: "Partnership" ,disabled:true },
    { label: "Corporation", value: "Corporation" ,disabled:true },
    { label: "Non-Profit Org.", value: "Non-Profit Org." ,disabled:true },
    { label: "Others", value: "Others" ,disabled:true },
];

const initialMerchantDetail = {
    preferredMerchantName: "",
    businessNature: "",
    merchantImage: "",
    description: "",
    merchantName: "",
    passportNumber: "",
    designation: "",
    formReceivedDate: "",
    instagramAccountName: "",
    facebookAccountName: "",
    companyRegisteredName: "",
    companyRegisteredNumber: "",
    companyRegisteredDomain: "",
    registeredAddress: "",
    companyEmail: "",
    businessType: "",


};

const requiredFields = [
    "preferredMerchantName",
    "businessNature",
    "merchantName",
    "passportNumber",
    "designation",
    "formReceivedDate",
    "bankDetails",
    "authorizedPerson",
    "address",
];
const businessRequiredFields = [
    "preferredMerchantName",
    "businessNature",
    "merchantImage",
    "description",
    "merchantName",
    "passportNumber",
    "designation",
    "formReceivedDate",
    "bankDetails",
    "authorizedPerson",
    "address",
    "companyRegisteredName",
    "companyRegisteredNumber",
    "companyRegisteredDomain",
    "registeredAddress",
    "companyEmail",
    "businessType",
];
const initialBankInfo = [];
const initialAuthorizedPerson = [];
const initialShippingInfo = [];
const initialBusinessPartaners = [];
const initialSubAccount = [];


// const allStatus = ["New", "Active", "Blocked", "Closed"]

const ViewMerchantAccount = () => {
    let { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [merchantType, setMerchantType] = useState("Business");
    const [merchantDetail, setMerchantDetail] = useState(initialMerchantDetail);
    const [bankInfo, setBankInfo] = useState(initialBankInfo);
    const [authorizedPerson, setAuthorizedPerson] = useState(
        initialAuthorizedPerson
    );
    // console.log(getLocalStorage("userName"));
    const [employeeAccountDetail, setEmployeeAccoutnDEtail] = useState();
    const [shippingInfo, setShippingInfo] = useState(initialShippingInfo);
    const [businessPartners, setBusinessPartners] = useState(
        initialBusinessPartaners
    );
    const [poster, setPoster] = useState();
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [error, setError] = useState("");
    const [employeeId, setEmployeeId] = useState();
    const [storeId, setStoreId] = useState(null);
    const [status, setStatus] = useState("")
    const [indexStatus, setIndexStatus] = useState(0)
    const [errorList, setErrorList] = useState([]);
    const validateIndividual = () => {

        let error = {};
        let formIsValid = true;
        if (!merchantDetail.preferredMerchantName) {
            formIsValid = false;
            error['preferredMerchantName'] = "preferred MerchantName is required"
        }
        // if (!storeId) {
        //   formIsValid = false;
        //   error['storeId'] = "Merchant Id is required"
        // }
        if (!merchantDetail.businessNature) {
            formIsValid = false;
            error['businessNature'] = "business Nature is required"
        }
        if (!merchantDetail.passportNumber) {
            formIsValid = false;
            error['passportNumber'] = "passport Number is required"
        }
        if (!merchantDetail.designation) {
            formIsValid = false;
            error['designation'] = "designation is required"
        }
        if (!merchantDetail.formReceivedDate) {
            formIsValid = false;
            error['formReceivedDate'] = "form Received Date is required"
        }

        if (!merchantDetail.merchantName) {
            formIsValid = false;
            error['merchantName'] = "merchantName is required"
        }


        setErrorList(error);
        return formIsValid;
    };
    const validateBusiness = () => {
        let error = {};
        let formIsValid = true;
        const regexTest = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!merchantDetail.preferredMerchantName) {
            formIsValid = false;
            error['preferredMerchantName'] = "preferred MerchantName is required"
        }

        if (!merchantDetail.businessNature) {
            formIsValid = false;
            error['businessNature'] = "business Nature is required"
        }
        if (!merchantDetail.passportNumber) {
            formIsValid = false;
            error['passportNumber'] = "passport Number is required"
        }
        if (!merchantDetail.designation) {
            formIsValid = false;
            error['designation'] = "designation is required"
        }
        if (!merchantDetail.formReceivedDate) {
            formIsValid = false;
            error['formReceivedDate'] = "form Received Date is required"
        }
        if (!merchantDetail.companyRegisteredName) {
            formIsValid = false;
            error['companyRegisteredName'] = "company Registered Name is required"
        }
        if (!merchantDetail.merchantName) {
            formIsValid = false;
            error['merchantName'] = "merchantName is required"
        }

        if (!merchantDetail.companyRegisteredNumber) {
            formIsValid = false;
            error['companyRegisteredNumber'] = "company Registered Number is required"
        }
        if (!merchantDetail.registeredAddress) {
            formIsValid = false;
            error['registeredAddress'] = "registered Address is required"
        }
        if (!merchantDetail.companyEmail) {
            formIsValid = false;
            error['companyEmail'] = "company Email is required"
        }
        if (merchantDetail.companyEmail && regexTest.test(merchantDetail.companyEmail) === false) {
            // console.log('regex test', regexTest.test(email))
            formIsValid = false
            error['companyEmail'] = " valid email required"
        }
        if (!merchantDetail.businessType) {
            formIsValid = false;
            error['businessType'] = "business Type is required"
        }
        setErrorList(error);
        return formIsValid;
    };
    useEffect(() => {
        if(getLocalStorage('role') === "MERCHANT"){
            history.push("/404");
        }
        // console.log("kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
        if (id) {
            const response = dispatch(getMerchnatById(id))
            response.
                then((data) => {
                    console.log(data.data.data, "kkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkkk");
                    const merchantDetail = {
                        preferredMerchantName: data.data.data?.preferredMerchantName,
                        businessNature: data.data.data?.businessNature,
                        merchantImage: data.data.data?.merchantImage,
                        description: data.data.data?.description,
                        merchantName: data.data.data?.merchantName,
                        passportNumber: data.data.data?.passportNumber,
                        designation: data.data.data?.designation,
                        formReceivedDate: data.data.data?.formReceivedDate,
                        instagramAccountName: data.data.data?.instagramAccountName,
                        facebookAccountName: data.data.data?.facebookAccountName,
                        companyRegisteredName: data.data.data?.companyRegisteredName,
                        companyRegisteredNumber: data.data.data?.companyRegisteredNumber,
                        companyRegisteredDomain: data.data.data?.companyRegisteredDomain,
                        registeredAddress: data.data.data?.registeredAddress,
                        companyEmail: data.data.data?.companyEmail,
                        businessType: data.data.data?.businessType,
                        status: data.data.data?.status,
                        storeId: data.data.data?.storeId
                        // poster: data.data.data?.merchantImage

                    }
                    setPoster(data.data.data?.merchantImage)
                    setMerchantDetail(merchantDetail)
                    setAuthorizedPerson(data.data.data?.authorizedPerson)
                    setShippingInfo(data.data.data?.address)
                    setBankInfo(data.data.data?.bankDetails)
                    setMerchantType(data.data.data?.merchantType)
                    setStatus({ value: data.data.data?.status, label: data.data.data?.status })
                    setStoreId(data.data.data?.storeId)
                    setBusinessPartners(data.data.data?.partners)
                    // setIndexStatus(allStatus.indexOf(data.data.data?.status))
                    // address()
                }).catch((err) => {
                    console.log(err);
                })
        }
    }, [id])
    console.log(status, 'status');
    console.log(authorizedPerson);
    useEffect(() => {
        const breadcrumb = [
            { label: "Merchant Account", url: "/merchant-center/merchant-account" },
            { label: id ? "View Merchant" : "Add Merchant", url: null },
        ];
        dispatch(breadcrumbAction(breadcrumb));
    }, []);
    const handleSelectFile = (e) => {
        console.log('ghjjjjjjjjjjjj');
        const fileData = e.target.files[0];

        setFile(fileData);
        const reader = new FileReader();
        reader.readAsDataURL(fileData);
        reader.onload = function () {
            setPoster(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    };
    const onChangeMerchantDetail = (e, title, val) => {
        const key = title || e.target.id;
        const value = val || e.target.value;
        console.log(key, value);
        setMerchantDetail((pre) => ({ ...pre, [key]: value }));
    };

    const handleAddAccount = () => {
        open_dialog(ADD_MERCHANT, {
            onSubmit: onSubmitBankAccountModal,
        });
    };
    const onSubmitBankAccountModal = (data) => {
        console.log("data", data);
        setBankInfo((pre) => [...pre, data]);
    };

    const handleAddAuthoried = () => {
        open_dialog(ADD_AUTHORIED, {
            onSubmit: onSubmitAuthorizedModal,
            authorizedPerson
        });
    };

    const onSubmitAuthorizedModal = (data) => {
        const data1 = {
            ...data,
            storeId
        };
        console.log("data1", data1);
        setAuthorizedPerson((pre) => [...pre, data1]);
        // console.log("data employeeId", data);
        // setEmployeeId(data.employeeId);
        // setEmployeeAccoutnDEtail(data);
    };

    const handleAddShippingAddress = () => {
        open_dialog(ADD_SHIPPING_ADDRESS, {
            onSubmit: onSubmitShippingAddressModal,
        });
    };
    const onSubmitShippingAddressModal = (data) => {
        console.log("data", data);
        setShippingInfo((pre) => [...pre, data]);
    };

    const handleAddPartner = () => {
        open_dialog(ADD_BUSINESS_PARTNER, {
            onSubmit: onSubmitPartnerModal,
        });
    };
    const onSubmitPartnerModal = (data) => {
        console.log("data", data);
        setBusinessPartners((pre) => [...pre, data]);
    };

    const handleSubAddAuthoried = () => {
        open_dialog(ADD_SUB_ACCOUNT, {
            onSubmit: onSubmitSubAccountModal,
        });
    };

    const onSubmitSubAccountModal = (data) => {
        // console.log(data, "data");
        const data1 = {
            ...data,
            employeeId: employeeId,
        };
        console.log("data1", data1);
        setAuthorizedPerson((pre) => [...pre, data1]);
    };



    const onSubmitAddMerchant = async (userStatus) => {
        console.log(merchantType, "kkkkkkkkkkkkkkkkkkkkkkkkk");
        if (merchantType == "Individual") {
            console.log(error, validateIndividual(), 'ghjkl');
            if (validateIndividual()) {
                console.log('inininininin');
                // startLoader();
                let imageUrl = await UploadImage(file, `uu2/merchant`);

                const payload = {
                    ...merchantDetail,
                    ...employeeAccountDetail,
                    merchantImage: imageUrl,
                    bankDetails: bankInfo,
                    authorizedPerson,
                    address: shippingInfo,
                    createdBy: getLocalStorage("userName"),
                    partners: businessPartners,
                    status: userStatus == "New" ? "New" : status.value,
                    storeId,
                    merchantType
                };
                // let invalidFieldName = [];
                // for (let item of requiredFields) {
                //   if (!payload[item]) {
                //     invalidFieldName.push(item);
                //   }
                // }
                // if (invalidFieldName?.length) {
                //   const label = invalidFieldName.join(", ");
                //   stopLoader()
                //   return setError(`${label} fields are invalid or missing!`);
                // }

                console.log(id, { payload });

                if (!id) {
                    // dispatch(addNewMerchant(payload))

                    const response = dispatch(addNewMerchant(payload));
                    response.then((data) => {
                        console.log('fghjyk');
                        if (data && data.data.status === 200) {
                            console.log(authorizedPerson, "storeId");
                            const payloadData = {
                                authorizedPerson,
                                storeId,
                                preferredMerchantName: merchantDetail.preferredMerchantName,
                            }
                            console.log(payloadData);

                            showToast(data.data.message, "success");
                            stopLoader();
                            history.push("/merchant-center/merchant-account");
                            // dispatch(sendEmailToMerchantToLogin(payloadData))
                            //     .then(data => {
                            //         stopLoader()
                            //         console.log(data);
                            //     }).catch(err => {
                            //         stopLoader()
                            //         console.log({ err });
                            //     })
                        } else {
                            setTimeout(() => {
                                stopLoader();
                                showToast(data.data.message, "error");
                            }, 1000);
                        }
                    });
                } else {
                    console.log(merchantType, "merchantType  merchantType");
                    const payloadData = {
                        authorizedPerson,
                        storeId: merchantDetail.storeId,
                        preferredMerchantName: merchantDetail.preferredMerchantName,
                    }
                    // console.log(payloadData, "kkkkkkkkkkkkkkk");
                    // dispatch(sendEmailToMerchantToLogin(payloadData))
                    // console.log(payload);

                    const response = dispatch(updateMerchantByMercnahtId(payload, id));
                    // history.push('/merchant-center/merchant-account')
                    response.then((data) => {
                        setTimeout(() => {
                            showToast(data.data.message, "success");
                            stopLoader();
                            history.push("/merchant-center/merchant-account");
                        }, 1000);

                    }).catch((err) => {
                        console.log({ err });
                        stopLoader();
                    })
                }
            }
        } else {
            if (validateBusiness()) {
                startLoader();
                let imageUrl = await UploadImage(file, `uu2/merchant`);
                console.log(merchantDetail);
                const payload = {
                    ...merchantDetail,
                    ...employeeAccountDetail,
                    merchantImage: imageUrl,
                    bankDetails: bankInfo,
                    authorizedPerson,
                    createdBy: getLocalStorage("userName"),
                    address: shippingInfo,
                    partners: businessPartners,
                    status: userStatus == "New" ? "New" : status.value,
                    storeId,
                    merchantType
                };

                let invalidFieldName = [];
                for (let item of requiredFields) {
                    if (!payload[item]) {
                        invalidFieldName.push(item);
                    }
                }
                if (invalidFieldName?.length) {
                    const label = invalidFieldName.join(", ");
                    return setError(`${label} fields are invalid or missing!`);
                }

                console.log(id, { payload });

                if (!id) {
                    // dispatch(addNewMerchant(payload))

                    const response = dispatch(addNewMerchant(payload));
                    response.then((data) => {
                        console.log('fghjyk');
                        if (data && data.data.status === 200) {
                            const payloadData = {
                                authorizedPerson,
                                storeId: merchantDetail.storeId,
                                preferredMerchantName: merchantDetail.preferredMerchantName,
                            }
                            console.log(payloadData);

                            showToast(data.data.message, "success");
                            stopLoader();
                            history.push("/merchant-center/merchant-account");
                            // dispatch(sendEmailToMerchantToLogin(payloadData))
                        } else {
                            setTimeout(() => {
                                stopLoader();
                                showToast(data.data.message, "error");
                            }, 1000);
                        }
                    });
                } else {
                    const payloadData = {
                        authorizedPerson,
                        storeId: merchantDetail.storeId,
                        preferredMerchantName: merchantDetail.preferredMerchantName,
                    }
                    // console.log(payloadData);
                    // dispatch(sendEmailToMerchantToLogin(payloadData))
                    // console.log(payload);

                    const response = dispatch(updateMerchantByMercnahtId(payload, id));
                    // history.push('/merchant-center/merchant-account')
                    response.then((data) => {
                        setTimeout(() => {
                            showToast(data.data.message, "success");
                            stopLoader();
                            history.push("/merchant-center/merchant-account");
                        }, 1000);

                    }).catch((err) => {
                        console.log({ err });
                        stopLoader();
                    })
                }
            }
        }

    };


    const onSaveAndDeactivate = () => {
        onSubmitAddMerchant("New");
    };
    const back = () => history.goBack();

    const onSubmitEditBankAccount = (data) => {
        const items = [...bankInfo];
        items[data.index] = data;
        setBankInfo(items);
    };

    const editBankAccount = (bankName, bankAccountNo, beneciaryName, index) => {
        open_dialog(EDIT_BANK_ACCOUNT_DIALOG, {
            onSubmit: onSubmitEditBankAccount,
            bankName,
            bankAccountNo,
            beneciaryName,
            index,
        });
    };

    const onSubmitEditShippingAddress = (data) => {

        const items = [...shippingInfo];
        items[data.index] = data;
        setShippingInfo(items);
    };

    const editShippingAddress = (fullName, phoneNumber, addressLine1, addressLine2, postalCode, state, setAsPickupAddress, setAsReturnAddress, index) => {
        open_dialog(EDIT_MERCHANT_SHIPPING_ADDRESS_DIALOG, {
            onSubmit: onSubmitEditShippingAddress,
            fullName,
            phoneNumber,
            addressLine1,
            addressLine2,
            postalCode,
            state,
            setAsPickupAddress,
            setAsReturnAddress,
            index
        });
    };

    const deleteBankAccount = (index1) => {
        console.log(index1);
        setBankInfo(bankInfo.filter((item, index) => index !== index1));
    };

    const deleteShippingAddress = (index1) => {
        console.log(index1);
        setShippingInfo(shippingInfo.filter((item, index) => index !== index1));
    };

    const onSubmitEditMerchantAccount = (data) => {
        console.log(data);
        setEmployeeAccoutnDEtail(data);
    };
    const editMerchantAccount = (
        employeeName,
        passportNumber,
        email,
        phoneNumber,
        loginID
    ) => {
        open_dialog(EDIT_MERCHANT_ACCOUNT_DIALOG, {
            onSubmit: onSubmitEditMerchantAccount,
            employeeName,
            passportNumber,
            email,
            phoneNumber,
            loginID,
        });
    };

    // const deleteMerchantAccount = ()=>{
    //    setAuthorizedPerson(authorizedPerson.filter((item, index) => index !== index1))
    // }
    const onSubmitEditSubAccount = (data) => {
        console.log(data);
        const items = [...authorizedPerson];
        items[data.index] = data;
        console.log(items);
        setAuthorizedPerson(items);
    };
    // console.log(authorizedPerson);
    const editsubAccount = (
        employeeName,
        passportNumber,
        email,
        phone,
        password,
        index,
        employeeId
    ) => {

        open_dialog(EDIT_SUB_ACCOUNT_DIALOG, {
            onSubmit: onSubmitEditSubAccount,
            employeeName,
            passportNumber,
            email,
            phone,
            password,
            index,
            employeeId,
        });
    };

    const deleteSubAccount = (index1) => {
        setAuthorizedPerson(
            authorizedPerson.filter((item, index) => index !== index1)
        );
    };

    const onSubmitEditParterAccount = (data) => {
        const items = [...businessPartners];
        items[data.index] = data;
        setBusinessPartners(items);
    };

    const editPartnerAccount = (partnerName, passportNumber, phone, password, index) => {
        open_dialog(EDIT_PARTNERS_ACCOUNT_DIALOG, {
            onSubmit: onSubmitEditParterAccount,
            partnerName,
            passportNumber,
            phone,
            index,
        });
    };

    const deletePartnerAccount = (index1) => {
        setBusinessPartners(
            businessPartners.filter((item, index) => index !== index1)
        );
    };

    const generateMerchantIdFun = () => {
        const response = dispatch(generateMerchantIdAction())
        response.then((data) => {
            console.log(data && data.data.storeId);
            setStoreId(data && data.data.storeId)
        })
    }
   
    return (
        <React.Fragment>
            <div className="container_body container-fluid">
                <div className="card p-3">
                    <h4 className="">SELECT YOUR TYPE</h4>
                    <div className="ml-5 d-flex">
                        <label className="mt-2 mx-3">Merchant Type</label>
                        <RadioButtonsGroup
                            options={merchantTypeOptions}
                            name="merchant-type"
                            value={merchantType}
                            onChange={(e) => setMerchantType(e.target.value)}
                        />
                    </div>
                </div>
                <br />
                <div className="card p-3">
                    <div className="col-12 row">
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small"
                        >
                            Merchant Id
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                                //  disabled
                                type="text"
                                id="merchantId"
                                onChange={onChangeMerchantDetail}
                                value={merchantDetail.storeId ? merchantDetail.storeId : storeId}

                                formClass="col-6 m-0 p-0"
                                className={""}
                                disabled={true}
                                formSubmitted={formSubmitted}
                            />
                            <button onClick={() => generateMerchantIdFun()} className="btn btn-dark mx-2 h-fit-content m-auto" visible={String(!id ? true : false)}>
                                Generate
                            </button>
                        </div>
                        <p style={{ marginLeft: "27%", fontSize: "12px" }} className="text-danger">{errorList.storeId}</p>

                    </div>
                    <div className="col-12 py-3 row" visible={id ? 'true' : 'false'}>

                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small"
                        >
                            Status
                        </label>
                        <div className="col-4">
                            <SelectInput
                                disabled={true}
                                options={statusData}
                                placeholder="Select status"
                                value={status}
                                onChange={setStatus}
                            />
                        </div>
                    </div>

                    <div
                        visible={String(merchantType === "Business")}
                        className="col-12 row"
                    >
                        <label
                            htmlFor="businessType"
                            className="col-3 my-auto text-right small imp"
                        >
                            Type of business
                        </label>
                        <div className="col-9 d-flex">
                            <RadioButtonsGroup
                                options={businessTypeOptions}
                                name="business-type"
                                size="tiny"
                                labelClass="my-0"
                                value={merchantDetail.businessType}
                                onChange={(e) => onChangeMerchantDetail(e, "businessType")}
                            />
                        </div>
                        <p style={{ marginLeft: "27%", fontSize: "12px" }} className="text-danger">{errorList.businessType}</p>

                    </div>

                    <div
                        visible={String(merchantType === "Business")}
                        className="col-12 row"
                    >
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small imp"
                        >
                            Company Registered Name
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                                disabled
                                type="text"
                                id="companyRegisteredName"
                                value={merchantDetail.companyRegisteredName}
                                onChange={onChangeMerchantDetail}
                                className={"w-100"}
                                formSubmitted={formSubmitted}
                                placeholder="input"
                            />
                        </div>
                        <p style={{ marginLeft: "27%", fontSize: "12px" }} className="text-danger">{errorList.companyRegisteredName}</p>
                    </div>

                    <div
                        visible={String(merchantType === "Business")}
                        className="col-12 row"
                    >
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small imp"
                        >
                            Company Registered No.
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                                type="text"
                                disabled
                                id="companyRegisteredNumber"
                                value={merchantDetail.companyRegisteredNumber}
                                onChange={onChangeMerchantDetail}
                                className={"w-100"}
                                formSubmitted={formSubmitted}
                                placeholder="input"
                            />
                        </div>
                        <p style={{ marginLeft: "27%", fontSize: "12px" }} className="text-danger">{errorList.companyRegisteredNumber}</p>
                    </div>

                    <div
                        visible={String(merchantType === "Business")}
                        className="col-12 row"
                    >
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small "
                        >
                            Company Domain Name
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                                disabled
                                type="text"
                                id="companyRegisteredDomain"
                                value={merchantDetail.companyRegisteredDomain}
                                onChange={onChangeMerchantDetail}
                                className={"w-100"}
                                formSubmitted={formSubmitted}
                                placeholder="input(Optional)"
                            />
                        </div>

                    </div>

                    <div
                        visible={String(merchantType === "Business")}
                        className="col-12 row"
                    >
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small imp"
                        >
                            Registered Address
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                                disabled
                                type="text"
                                id="registeredAddress"
                                value={merchantDetail.registeredAddress}
                                onChange={onChangeMerchantDetail}
                                className={"w-100"}
                                formSubmitted={formSubmitted}
                                placeholder="input"
                            />
                        </div>
                        <p style={{ marginLeft: "27%", fontSize: "12px" }} className="text-danger">{errorList.registeredAddress}</p>

                    </div>

                    <div className="col-12 row">
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small imp"
                        >
                            Preferred Merchant Name
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                                disabled
                                type="text"
                                id="preferredMerchantName"
                                value={merchantDetail.preferredMerchantName}
                                onChange={onChangeMerchantDetail}
                                className={"w-100"}
                                formSubmitted={formSubmitted}
                                placeholder="input"
                            />


                        </div>
                        <p style={{ marginLeft: "27%", fontSize: "12px" }} className="text-danger">{errorList.preferredMerchantName}</p>
                    </div>

                    <div
                        visible={String(merchantType === "Business")}
                        className="col-12 row"
                    >
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small imp"
                        >
                            Company Email
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                                disabled
                                type="text"
                                id="companyEmail"
                                value={merchantDetail.companyEmail}
                                onChange={onChangeMerchantDetail}
                                className={"w-100"}
                                formSubmitted={formSubmitted}
                                placeholder="input"
                            />
                        </div>
                        <p style={{ marginLeft: "27%", fontSize: "12px" }} className="text-danger">{errorList.companyEmail}</p>

                    </div>

                    <div className="col-12 row">
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small imp"
                        >
                            Business Nature
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                                disabled
                                type="text"
                                id="businessNature"
                                value={merchantDetail.businessNature}
                                onChange={onChangeMerchantDetail}
                                className={"w-100"}
                                formSubmitted={formSubmitted}
                                placeholder="input"
                            />
                        </div>
                        <p style={{ marginLeft: "27%", fontSize: "12px" }} className="text-danger">{errorList.businessNature}</p>

                    </div>

                    <div className="col-12 row my-2">
                        <label htmlFor="merchantId" className="col-3 text-right ">
                            Image
                        </label>
                        <div className="col-9">
                            <div className="col-12 row">
                                <input
                                    disabled
                                    type="file"
                                    name="playlist_poster"
                                    id="playlist-poster"
                                    onChange={handleSelectFile}
                                    className="form-input col-md-8 p-0"
                                />
                                {/* <input 
                    accept="image/*" 
                    id="product-image-2" 
                    className="d-none"
                    multiple 
                    type="file" />
                  <label htmlFor="product-image-2">
                    <Image 
                      src="/icons/upload_icon.svg"
                      alt="upload"
                      className={"pointer"}
                    />
                  </label> */}
                            </div>

                            <div className="col-12 px-0 py-2" style={{ height: "120px" }}>
                                {poster ? (
                                    <img
                                        src={poster}
                                        height="100px"
                                        width="120px"
                                        className="object-fit-cover"
                                        alt="playlist poster"
                                    />
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 row">
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small"
                        >
                            Description
                        </label>
                        <div className="col-9 d-flex">
                            <textarea
                                disabled
                                type="text"
                                id="description"
                                value={merchantDetail.description}
                                onChange={onChangeMerchantDetail}
                                className={"form-control w-100"}
                                placeholder="input(Optional)"
                                rows={5}
                            ></textarea>
                        </div>
                    </div>

                    <div className="col-12 row">
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small imp"
                        >
                            Name
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                                disabled
                                type="text"
                                id="merchantName"
                                value={merchantDetail.merchantName}
                                onChange={onChangeMerchantDetail}
                                className={"w-100"}
                                formSubmitted={formSubmitted}
                                placeholder="input"
                            />
                        </div>
                        <p style={{ marginLeft: "27%", fontSize: "12px" }} className="text-danger">{errorList.merchantName}</p>

                    </div>

                    <div className="col-12 row">
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small imp"
                        >
                            NRIC / Passport No.
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                                disabled
                                type="text"
                                id="passportNumber"
                                value={merchantDetail.passportNumber}
                                onChange={onChangeMerchantDetail}
                                className={"w-100"}
                                formSubmitted={formSubmitted}
                                placeholder="input"
                            />
                        </div>
                        <p style={{ marginLeft: "27%", fontSize: "12px" }} className="text-danger">{errorList.passportNumber}</p>

                    </div>

                    <div className="col-12 row">
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small imp"
                        >
                            Designation
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                                disabled
                                type="text"
                                id="designation"
                                value={merchantDetail.designation}
                                onChange={onChangeMerchantDetail}
                                className={"w-100"}
                                formSubmitted={formSubmitted}
                                placeholder="input"
                            />
                        </div>
                        <p style={{ marginLeft: "27%", fontSize: "12px" }} className="text-danger">{errorList.designation}</p>

                    </div>

                    <div className="col-12 row my-2">
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small imp"
                        >
                            Form Received Date
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                              disabled
                              type="date"
                              id="designation"
                              value={merchantDetail.formReceivedDate}
                              onChange={onChangeMerchantDetail}
                              className={"w-100"}
                              formSubmitted={formSubmitted}
                              placeholder="DD/MM/YYYY"
                            />

                            {/*<Datepicker*/}
                            {/*    disabled={true}*/}
                            {/*    color="#fff"*/}
                            {/*    value={merchantDetail.formReceivedDate}*/}
                            {/*    label="DD/MM/YYYY"*/}
                            {/*    onChange={(e) => {*/}
                            {/*        onChangeMerchantDetail(e, "formReceivedDate", e);*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </div>
                        <p style={{ marginLeft: "27%", fontSize: "12px" }} className="text-danger">{errorList.formReceivedDate}</p>

                    </div>

                    <div className="col-12 row">
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small"
                        >
                            Instagram Account
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                                disabled
                                type="text"
                                id="instagramAccountName"
                                value={merchantDetail.instagramAccountName}
                                onChange={onChangeMerchantDetail}
                                className={"w-100"}
                                formSubmitted={formSubmitted}
                                placeholder="input(Optional)"
                            />
                        </div>
                    </div>

                    <div className="col-12 row">
                        <label
                            htmlFor="merchantId"
                            className="col-3 my-auto text-right small"
                        >
                            Facebook Account
                        </label>
                        <div className="col-9 d-flex">
                            <InputField
                                type="text"
                                id="facebookAccountName"
                                value={merchantDetail.facebookAccountName}
                                onChange={onChangeMerchantDetail}
                                className={"w-100"}
                                formSubmitted={formSubmitted}
                                placeholder="input(Optional)"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <br />
                <div
                    visible={String(merchantType === "Business")}
                    className="card p-3 "
                >
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <h6 className="m-0">NAME OF DIRECTORS/PARTNERS</h6>
                            <label className="text-muted  font-size-account">
                                Manage merchant’s company director/partners
                            </label>
                        </div>
                        <div className="">
                            <button onClick={handleAddPartner} disabled className="btn btn-dark px-2">
                                Add Director / Partners
                            </button>
                        </div>
                    </div>
                    {businessPartners && businessPartners.length > 0 ? (
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 650 }}
                                stickyHeader
                                aria-label="sticky table"
                            >
                                <TableHead style={{ background: "#F5F8FA" }}>
                                    <TableRow>
                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            Director / Partners Name
                                        </TableCell>
                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            NRIC / Passport No.
                                        </TableCell>

                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            Phone Number
                                        </TableCell>

                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...businessPartners].map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            <TableCell align="center" className="td_cell">
                                                {row.partnerName || "N/A"}
                                            </TableCell>
                                            <TableCell align="center" className="td_cell">
                                                {row.passportNumber || "N/A"}
                                            </TableCell>

                                            <TableCell align="center" className="td_cell">
                                                {row.phone || "N/A"}
                                            </TableCell>
                                            <TableCell align="center" className="td_cell">
                                                <div className="col-2 d-flex">
                                                    <div
                                                        disabled
                                                        className="text-info"
                                                        // onClick={() =>
                                                        //     editPartnerAccount(
                                                        //         row.partnerName,
                                                        //         row.passportNumber,
                                                        //         row.phone,
                                                        //         index
                                                        //     )
                                                        // }
                                                    >
                                                        Edit
                                                    </div>
                                                    <div className="text-info mx-2">|</div>
                                                    <div
                                                        disabled
                                                        className="text-info"
                                                        // onClick={() => deletePartnerAccount(index)}
                                                    >
                                                        Delete
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : null}

                </div>
                <br />
                <div className="card p-3 ">
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <h6 className="m-0">BANK ACCOUNT INFORMATION</h6>
                            <label className="text-muted  font-size-account">
                                Manage merchant's bank account
                            </label>
                        </div>
                        <div className="">
                            <button onClick={handleAddAccount} disabled className="btn btn-dark px-2">
                                + Add Bank Account
                            </button>
                        </div>
                    </div>

                    {bankInfo?.map((item, index) => (
                        <div key={index} className="col-12 row">
                            <hr className="w-100" />
                            <div className="col-10">
                                <div>
                                    <label className="col-3 my-auto small text-right text-muted">
                                        Bank Name
                                    </label>
                                    <label className="col-9 my-auto small">{item.bankName}</label>
                                </div>
                                <div>
                                    <label className="col-3 my-auto small text-right text-muted">
                                        Account No.
                                    </label>
                                    <label className="col-9 my-auto small">
                                        {item.bankAccountNo}
                                    </label>
                                </div>
                                <div>
                                    <label className="col-3 my-auto small text-right text-muted">
                                        Beneficiary Name
                                    </label>
                                    <label className="col-9 my-auto small">
                                        {item.beneciaryName}
                                    </label>
                                </div>
                            </div>
                            <div className="col-2 d-flex">
                                <div
                                    className="text-info"
                                    disabled
                                    // onClick={() =>
                                    //     editBankAccount(
                                    //         item.bankName,
                                    //         item.bankAccountNo,
                                    //         item.beneciaryName,
                                    //         index
                                    //     )
                                    // }
                                >
                                    Edit
                                </div>
                                <div className="text-info mx-2">|</div>
                                <div
                                    className="text-info"
                                    // onClick={() => deleteBankAccount(index)}
                                    disabled
                                >
                                    Delete
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <br />

                <div className="card p-3">
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <h6 className="m-0">NAME OF AUTHORIZED</h6>
                            <label className="text-muted  font-size-account">
                                Manage merchant's login credential
                            </label>
                        </div>
                        <div className="">
                            <button
                                onClick={handleAddAuthoried}
                                className="btn btn-dark px-2"
                                disabled
                            >
                                + Add Authorized Person
                            </button>
                        </div>
                    </div>

                    {authorizedPerson && authorizedPerson.length > 0 ? (
                        <TableContainer>
                            {/* <p style={{ textAlign: "center" }} className="text-align-center">
                Sub Account
              </p> */}
                            <Table
                                sx={{ minWidth: 650 }}
                                stickyHeader
                                aria-label="sticky table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            Employee Name
                                        </TableCell>
                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            LoginId.
                                        </TableCell>
                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            password.
                                        </TableCell>
                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            NRIC / Passport No.
                                        </TableCell>
                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            Email Address
                                        </TableCell>
                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            Phone Number
                                        </TableCell>

                                        <TableCell
                                            className="th_cell"
                                            component="th"
                                            align="center"
                                        >
                                            Action
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {[...authorizedPerson].map((row, index) => (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            <TableCell align="center" className="td_cell">
                                                {row.username || "N/A"}
                                            </TableCell>
                                            <TableCell align="center" className="td_cell">
                                                {row.loginId || "N/A"}
                                            </TableCell>
                                            <TableCell align="center" className="td_cell">
                                                {"*************"}
                                            </TableCell>
                                            <TableCell align="center" className="td_cell">
                                                {row.passportNumber || "N/A"}
                                            </TableCell>
                                            <TableCell align="center" className="td_cell">
                                                {row?.email || "N/A"}
                                            </TableCell>
                                            <TableCell align="center" className="td_cell">
                                                {row.phone || "N/A"}
                                            </TableCell>
                                            <TableCell align="center" className="td_cell">
                                                <div className="col-2 d-flex">
                                                    <div
                                                        disabled
                                                        className="text-info"
                                                        // onClick={() =>
                                                        //     editsubAccount(
                                                        //         row.username,
                                                        //         row.passportNumber,
                                                        //         row.email,
                                                        //         row.phone,
                                                        //         row.password,
                                                        //         index,
                                                        //         row.loginId
                                                        //     )
                                                        // }
                                                    >
                                                        Edit
                                                    </div>
                                                    <div className="text-info mx-2">|</div>
                                                    <div
                                                        disabled
                                                        className="text-info"
                                                        // onClick={() => deleteSubAccount(index)}
                                                    >
                                                        Delete
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : null}
                    {/* ))} */}
                </div>

                <br />

                <div className="card p-3">
                    <div className="d-flex justify-content-between">
                        <div className="">
                            <h6 className="m-0">SHIPPING INFORMATION</h6>
                            <label className="text-muted  font-size-account">
                                Manage pickup and return address
                            </label>
                        </div>
                        <div className="">
                            <button
                                onClick={handleAddShippingAddress}
                                className="btn btn-dark px-2"
                                disabled
                            >
                                + Add New Address
                            </button>
                        </div>
                    </div>
                    {shippingInfo?.map((item, index) => (
                        <div key={index} className="col-12 row">
                            <hr className="w-100" />
                            <div className="col-10">
                                <div>
                                    <label className="col-3 my-auto small text-right text-muted">
                                        Full Name
                                    </label>
                                    <label className="col-9 my-auto small">
                                        {item.fullName}
                                        {item.setAsPickupAddress && (
                                            <span className="px-3 text-danger">Pickup Address</span>
                                        )}
                                        {item.setAsReturnAddress && (
                                            <span className="px-3 text-success">Return Address</span>
                                        )}
                                    </label>
                                </div>
                                <div>
                                    <label className="col-3 my-auto small text-right text-muted">
                                        Phone Number
                                    </label>
                                    <label className="col-9 my-auto small">
                                        {item.phoneNumber}
                                    </label>
                                </div>
                                <div>
                                    <label className="col-3 my-auto small text-right text-muted">
                                        Address
                                    </label>
                                    <label className="col-9 my-auto small">{item.address}</label>
                                </div>
                            </div>
                            <div className="col-2 d-flex">
                                <div className="text-info"
                                    disabled
                                // onClick={() => editShippingAddress(
                                //     item.fullName,
                                //     item.phoneNumber,
                                //     item.addressLine1,
                                //     item.addressLine2,
                                //     item.postalCode,
                                //     item.state,
                                //     item.setAsPickupAddress,
                                //     item.setAsReturnAddress,
                                //     index
                                // )}
                                >Edit
                                </div>
                                <div className="text-info mx-2">|</div>


                                <div
                                    className="text-info"
                                    // onClick={() => deleteShippingAddress(index)}
                                    disabled
                                >
                                    Delete
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <br />
                <br />
                <div className="d-flex justify-content-end">
                    {error && <label className="text-danger">{error}</label>}
                </div>
                <footer className="card p-3">
                    <div className="d-flex justify-content-end">

                        <button
                            type="button"
                            className="btn btn-dark"
                            onClick={() => history.goBack()}
                        >
                            Back
                        </button>
                    </div>
                </footer>
                <br />
            </div>
        </React.Fragment>
    );
};

export default ViewMerchantAccount;

