import { GetWithTokenFile, GetWithTokenParams } from "../../../lib/request";

export const getAllSalesOrder = (query) => {
  console.log(query);
  const url = "/v2/merchant-dashboard";
  return async function (dispatch) {
    const result = await GetWithTokenParams(url, query);
    // console.log(result, "rrrrrrrrrrrrrrrrrrrr");
    return result;
  };
};

export const exportSalesMerchantOrder = (query) => {
  const { status, startDate, endDate, categories } = query;
  const start =
    new Date(startDate).toISOString().split("T")[0] + "T00:00:00.000Z";
  const end = new Date(endDate).toISOString().split("T")[0] + "T00:00:00.000Z";
  const URL_categories = [];
  categories.map((cate) => {
    cate = cate.split(" & ").join("+%26+");
    URL_categories.push(`categories[]=${cate}`);
  });
  const url =
    `/v2/merchant-dashboard?status=${status}&startDate=${start}&endDate=${end}&downloadFile=${true}&` +
    URL_categories.join("&");

  //   console.log(url, "++++++++++++++++++++++++++++++++");
  return async function (dispatch) {
    const result = await GetWithTokenFile(url);
    // console.log(result, "result from export");
    return result;
  };
};
