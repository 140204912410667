import { IconButton, Pagination, Stack } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import SvgIcon from "../../../components/Images/SvgIcon";
import InputField from "../../../components/formControl/input/InputField";
import { DELETE_DIALOG, DELETE_USER_ROLE } from "../../../lib/constant";
import { AllowAccess, open_dialog, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getAllMerchantList } from "../../../redux/actions/merchant-center.action";
import "../../../static/css/merchantCss/MerchantAccount.css";
import { getAllRoleUser } from "../../../redux/actions/user.role.action";
import { ExportCSV } from "../../../components/export/exportXlsxFile";
import BasicDateRangePicker from "../../../components/datepicker/dateRangePicker";
import PagenationDropdown from "../../../components/DropDown/pagenationDropdown";
import ablity from "../../../helpers/abilityGuard";

const initialUserSearchDetail = {
  userName: "",
  approvedDate: "",
};
const ListUserRole = (props) => {
  AllowAccess(["SUPERADMIN","SALES OPERATION"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const [searchData, setSearchData] = useState(initialUserSearchDetail);
  const [userName, setUserName] = useState();
  const [approvedDate, setApprovedDate] = useState();
  const [totalUser, setTotalUser] = useState();

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    startLoader();
    const breadcrumb = [{ label: "User Account", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    // let filtringData = searchData;
    let filteringdata;
    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      setSearchData(initialSearchData);
      setPageNo(1);
      setOffset(0);
      filteringdata = {
        limit: pageLimit,
      };
    } else {
      filteringdata = {
        ...searchData,
        offset,
        limit: pageLimit,
      };
    }

    apiData(filteringdata);
  }, [searchData, offset, pageLimit]);

  const apiData = (filteringData) => {
    startLoader();

    const response = dispatch(getAllRoleUser(filteringData));
    response
      .then((data) => {
        if (data && data.data && data.data.status === 200) {
          setUserData(data && data.data && data.data.data);
          setTotalUser(data.data.total);
          setTimeout(() => {
            stopLoader();
          }, 1000);
        } else {
          setTimeout(() => {
            stopLoader();
          }, 1000);
        }
      })

      .catch((error) => console.log("----------error----", error));
    setTimeout(() => {
      stopLoader();
    }, 1000);
  };
  useEffect(() => {
  }, [userData]);

  const addUserAccount = () => {
    history.push(`/user-role`);
  };
  const viewUserAccount = () => {
    history.push(`/user-role`);
  };

  const getUserDataAfterDelete = (data) => {
    setUserData(data);
  };

  const deleteUserAccount = async (id) => {
    open_dialog(DELETE_USER_ROLE, {
      onSubmit: getUserDataAfterDelete,
      id,
    });
  };

  const searchMerchat = () => {
    setSearchData({
      ...searchData,

      userName,
      approvedDate,
    });
  };
  const resetSearch = () => {
    setUserName("");
    setApprovedDate("");
    setSearchData({
      ...initialUserSearchDetail,
    });
  };

  const editMerchantAccount = (userId) => {
    history.push(`user-role/edit/${userId}`);
  };

  const handlePageChange = (event, value) => {
    setOffset(value - 1);
    setPageNo(value);
  };

  return (
    <React.Fragment>
      {/*<div className="mt-1 mx-3 px-2 py-3 rounded bg-white">*/}
      {/*  <div className="col-12 row flex-wrap">*/}
      {/*    <div className="min-width-200 col-md-3">*/}
      {/*      <label>User Name</label>*/}
      {/*      <InputField*/}
      {/*        type="text"*/}
      {/*        className="form-control"*/}
      {/*        placeholder="Input"*/}
      {/*        id="userName"*/}
      {/*        value={userName}*/}
      {/*        onChange={(e) => setUserName(e.target.value)}*/}
      {/*      />*/}
      {/*    </div>*/}

      {/*    <div className="min-width-200 col-md-3">*/}
      {/*      <label>Approved Date</label>*/}
      {/*      <InputField*/}
      {/*        type="date"*/}
      {/*        className="form-control"*/}
      {/*        id="merchantRegisteredDate"*/}
      {/*        value={approvedDate}*/}
      {/*        onChange={(e) => setApprovedDate(e.target.value)}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  <div className="d-flex justify-content-end mt-3">*/}
      {/*    <button*/}
      {/*      type="button"*/}
      {/*      className="btn input-inside-text mr-1 btn-white border px-4"*/}
      {/*      onClick={() => resetSearch()}*/}
      {/*    >*/}
      {/*      Reset*/}
      {/*    </button>*/}
      {/*    <button*/}
      {/*      type="button"*/}
      {/*      className="btn input-inside-text mx-1 btn-secondary px-3"*/}
      {/*      onClick={() => searchMerchat()}*/}
      {/*    >*/}
      {/*      Search*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <div className="mt-3 ">
        <div className="mx-3 my-3">
          <div className="py-3">
            <span>ACCOUNT LISTING</span>
            <div className="border card p-3 my-3 ">
              <div className="d-flex justify-content-between">
                <div>
                  <span>ALL User</span>
                </div>
                <div className="d-flex">
                  <ExportCSV csvData={userData} fileName={"userData"} />

                  {ablity({model:'AssignRole', action:'create'}) == 1 && (
                      <button
                        type="button"
                        onClick={addUserAccount}
                        className="btn add-product-botton py-1 mr-2 btn-dark"
                      >
                        <span className="text-nowrap">
                          <small className="text-nowrap">Add User</small>
                        </span>
                      </button>
                  )}
                </div>
              </div>
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="th_cell" component="th">
                        No
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        Email
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        Phone
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        Create On
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        Updated On
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        Status
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        Role
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        Role Type
                      </TableCell>
                      <TableCell className="table_action_head" component="th">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!userData?.length && (
                      <h3 className="text-muted m-auto">No data found!</h3>
                    )}
                    {[...userData].map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" className="td_cell">
                          {index + 1}
                        </TableCell>

                        <TableCell align="center" className="td_cell">
                          {row.username || "N/A"}
                        </TableCell>

                        <TableCell align="center" className="td_cell">
                          {row.email || "N/A"}
                        </TableCell>
                        <TableCell align="center" className="td_cell">
                          {row.phone || "N/A"}
                        </TableCell>
                        <TableCell align="center" className="td_cell">
                          {row.createdAt
                            ? moment(row.createdAt).format("DD/MM/YY, HH:mm")
                            : "N/A"}
                        </TableCell>

                        <TableCell align="center" className="td_cell">
                          {row.updatedAt
                            ? moment(row.updatedAt).format("DD/MM/YY, HH:mm")
                            : "N/A"}
                        </TableCell>
                        <TableCell align="center" className="td_cell">
                          {row.status || "N/A"}
                        </TableCell>
                        <TableCell align="center" className="td_cell" s>
                          {row.role.name || "N/A"}
                        </TableCell>
                        <TableCell align="center" className="td_cell" s>
                          {row.roleType || "N/A"}
                        </TableCell>

                        <TableCell className="d-flex justify-content-between table_action_item">
                          {ablity({model:'AssignRole', action:'update'}) == 1 && (
                              <IconButton
                                className="pointer"
                                onClick={() => editMerchantAccount(row.userId)}
                              >
                                <SvgIcon
                                  src="/icons/edit_icon.svg"
                                  alt="view icon"
                                />
                              </IconButton>
                            )}

                          {ablity({model:'AssignRole', action:'delete'}) == 1 && (
                              <IconButton
                                className="pointer"
                                onClick={() => deleteUserAccount(row.userId)}
                              >
                                <SvgIcon
                                  src="/icons/delete_icon.svg"
                                  alt="delete icon"
                                />
                              </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <div className="d-flex justify-content-between align-items-center">
                <PagenationDropdown setPageQuant={setPageLimit} />

                <div className="float-right mb-1">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil((totalUser || 0) / pageLimit)}
                      shape="rounded"
                      size="small"
                      page={pageNo}
                      onChange={handlePageChange}
                    />
                  </Stack>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListUserRole;
