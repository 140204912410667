
import React from "react";

import "../../static/css/deleteModal.css"
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { startLoader, stopLoader } from "../../lib/global";

const DeleteOrder = (props) => {
    const dispatch = useDispatch();
    const actionButton=()=>{
      if(props.isSuccess){
        props.onClose()
        startLoader()
        props.onSubmit()
        setTimeout(()=>{
          stopLoader()
        },1000)
      }else{
        props.onClose()
      }
    }
  
    return (
  
        <React.Fragment>
            <div className="delete_modal_body">


                <div className="close_icon" style={{ top: "0px" }}>
                    <CloseIcon
                        className="close_icon_btn m-2"
                        color="primary2"
                        fontSize="large"
                        title="Close dialog"
                        onClick={actionButton}
                    />
                </div>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="confirmation_dialogbox__content">
                        <div className="dialogbox_body__content">
                            <h6 className="message"> {props.message}</h6>
                            {/* <p>Delete this will delete the entire order's data and login credential.<br />You are unable to restore back again.</p> */}
                        </div>
                    </div>
                </div>
                <div className="dialogbox_footer__content">
    
                    <button type="button" className="dialogbox_cancel__btn" onClick={actionButton}>Back</button>

                </div>
            </div>
            <style jsx="true">{`
          .card-body {
            min-height: 50vh;
          }
          .submit_btn {
            position: absolute;
            bottom: 15px;
            width: 100%;
            justify-content: center;
            display: flex;
          }
          .close_icon_btn {
            position: absolute;
            right: 10;
            cursor: pointer;
          }
          .message{
            white-space: initial;
          }
        `}</style>
        </React.Fragment>

    )
}

export default DeleteOrder