import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Image from "../../../components/Images/Image";
import { generateRoomWithoutSeparator } from "random-word-generator-npm";
import InputField from "../../../components/formControl/input/InputField";
import uuid from "react-uuid";
import { checkExistUser } from "../../../redux/actions/merchant-center.action";
import { useDispatch } from "react-redux";
import { startLoader, stopLoader, showToast, UploadImage, UploadDocument, UploadVideo } from "../../../lib/global";
import { EmailSharp } from "@mui/icons-material";
const AddDocumentOverrideTp = (props) => {
  const dispatch = useDispatch();
  const [documentName, setDocumentName] = useState(null);
  const [documentFile, setDocumentFile] = useState(null);
  const [documentFileTemp, setDocumentFileTemp] = useState(null);

  const handleSubmit = async () => {
    let imageUrl = await UploadImage(documentFileTemp, `uu2/merchant/override_document`);
    // setDocumentFile(imageUrl);
    console.log("@@@@@@@@DDD", documentFile, imageUrl);

    const payload = {
      documentName,
      documentPath: imageUrl
    };
    props.onSubmit(payload);
    props.onClose();
  };
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };
  return (
    <React.Fragment>
      <div className="col-12 p-0 m-0 card">
        <div className="close_icon">
          <CloseIcon
            className="close_icon_btn m-2"
            color="primary2"
            fontSize="large"
            title="Close dialog"
            onClick={props.onClose}
          />
        </div>
        <div className="card-header d-flex justify-content-center">
          <h4>Add Document Override TP</h4>
        </div>
        <div
          style={{ height: "75vh" }}
          className="card-body add-authoried-card-body"
        >
          <form className="col-12 row justify-content-center">
            <div className="col-12 mb-2">
              <label className="col-12 m-0 p-0">Document Name</label>
              <InputField
                className="w-100"
                type="text"
                placeholder="Document Name"
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
              />
            </div>
            <div className="col-12 my-2">
              <label className="col-12 m-0 p-0">Document File (Only Image and Pdf Format)</label>
              <input
                className="rounded"
                type="file"
                id="file"
                name="file"
                onChange={(e) => {
                  let file = e.target.files[0];
                  setDocumentFileTemp(file);
                }}
              />
            </div>


          </form>
          <div className="d-flex justify-content-end col-md-12 px-5">
            <button
              type="button"
              className="btn btn-outline-dark mx-2"
              onClick={props.onClose}
            >
              Cancel
            </button>

            <button
              className="btn btn-dark"
              disabled={!documentName || !documentFileTemp}
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>

      </div>
      <style jsx="true">{`
        .card-body {
          min-height: 60vh;
        }
        .submit_btn {
          position: absolute;
          bottom: 15px;
          width: 100%;
          justify-content: center;
          display: flex;
        }
        .close_icon_btn {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      `}</style>
    </React.Fragment>
  );
};

export default AddDocumentOverrideTp;
