import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Image from "../../../../../components/Images/Image";
import { breadcrumbAction } from "../../../../../redux/actions/config.action";
import { AllowAccess, open_dialog, showToast } from "../../../../../lib/global";
import { GO_LIVESTREAM_SHOW_PRODUCT,GO_LIVESTREAM_SHOW_PROMO } from "../../../../../lib/constant";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { changeLiveStreamStatus, getLiveStreamById } from "../../../../../redux/actions/marketing.action";
import { SendBirdProvider as SBProvider,OpenChannel as SBConversation, } from "sendbird-uikit";
import { SENDBIRD_ADMIN_ID, SENDBIRD_API_KEY } from '../../../../../lib/config';
import { getLocalStorage } from "../../../../../lib/session";
const ContentGoLive = () => {
  AllowAccess(["SUPERADMIN","MERCHANT","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const { liveStreamId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [storeId, setStoreId] = useState(localStorage.getItem("storeId"));
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [liveChat,setLiveChat] = useState(null)
  const [livestreamData, setLivestreamData] = useState({
    name:"",
    description:"",
    productLimit:10,
    isTest:false,
    isLive:false,
    merchant:null,
  });
  const [link,setLink] = useState();
  const [allProductData, setallProductData] = useState([]);
  const [uu2VoucherData, setUU2VoucherData] = useState([]);
  const [merchantVoucherData, setMerchantVoucherData] = useState([]);
  const [userId, setUserId] = useState(getLocalStorage('storeId')?getLocalStorage('storeId'):getLocalStorage('uid'));
  const [name, setName] = useState(getLocalStorage('userName'));
  const breadcrumb = [
    { label: "Livestream", url: "/marketing/all-promo/livestream" },
    { label: "Go Live", url: null },
  ];
  const appId    = SENDBIRD_API_KEY;

  useEffect(() => {
    if(liveStreamId){
      getLiveStream()
    }

    setUserId(getLocalStorage('storeId')?getLocalStorage('storeId'):getLocalStorage('uid'));
    setName(getLocalStorage('userName'));
  },[])

  const getLiveStream = () => {
    dispatch(getLiveStreamById(liveStreamId))
    .then((response) => {
      if(response.data.data && role == "MERCHANT" && response.data.data.merchant.storeId != storeId){
        history.push("/merchant/marketing/promo/livestream");
        showToast("You are not authorized to access this page","error");
      }
      let temp = response.data.data;
      delete temp.createdAt
      delete temp.updatedAt
      if(temp.startDateTime != null && temp.endDateTime == null){
        temp.isLive = true
      }else{
        temp.isLive = false
      }
      setLivestreamData(temp);
      setallProductData(temp.products);
      setLiveChat(temp.liveChat)
      setLink(convertLink(temp.link))
      if(temp.vouchers){
        let uu2 = temp.vouchers.filter((item)=> item.merchantCode == null);
        let merchant = temp.vouchers.filter((item)=> item.merchantCode != null);
        setUU2VoucherData(uu2);
        setMerchantVoucherData(merchant);
      }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const convertLink = (link) => {
    if(isValidURL(link)){
      const url = new URL(link)
      const searchParams = new URLSearchParams(url.search)
      const vidioId = searchParams.get('v')

      if(isFBWatch(link)){
        return getFBEmbedLink(link)
      }

      if(vidioId){
        if(url.origin == "https://www.youtube.com"){
          return `https://www.youtube.com/embed/${vidioId}`
        }else if(url.origin == "https://facebook.com"){
          return `https://www.facebook.com/plugins/video.php?height=500&href=${link}&show_text=false&width=476&t=0`
        }
      }
    }

    return link
  }

  function getFBEmbedLink(originalLink) {
    // Ubah URL Facebook Live menjadi URL embed
    return 'https://www.facebook.com/plugins/video.php?href='+originalLink+'&show_text=false&width=100&height=50';
  }

  const isFBWatch = (link) => {
    const fbWatchLinkRegex = /https:\/\/fb\.watch\/[A-Za-z0-9_-]+/;
    return fbWatchLinkRegex.test(link);
  }

  const isValidURL = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return false;
    }
  }

  const handelAddProduct = () => {
    open_dialog(GO_LIVESTREAM_SHOW_PRODUCT, {
      onSubmit: selectProductSuccess,
      setProductData:setallProductData,
      product: allProductData,
      liveStreamId: liveStreamId
    });
  };
  const handelAddPromo = () => {
    open_dialog(GO_LIVESTREAM_SHOW_PROMO, {
      onSubmit: selectPromoSuccess,
      uu2VoucherData: uu2VoucherData,
      merchantVoucherData: merchantVoucherData,
      liveStreamId: liveStreamId
    });
  };
  const selectProductSuccess = (data) => {
    console.log(data);
  };
  const selectPromoSuccess = (data) => {
    console.log(data);
  };

  const shareLink = () => {
    // copy link
    navigator.clipboard.writeText(link);
    showToast("Link copied to clipboard");
  }
  const changeStatus = (status) => {
    dispatch(changeLiveStreamStatus({id:liveStreamId,status}))
    .then((res) => {
      getLiveStream()
      showToast("Success Change Status", "success");
    })
    .catch((err) => {
      console.log("err", err);
    })
  }
  dispatch(breadcrumbAction(breadcrumb));

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-6">
                <h4>{livestreamData.name}</h4>
              </div>
              <div className="col-6">
                <div className="float-right">
                  <button className={"btn btn-outline-dark mr-1 border-0"} onClick={shareLink}>
                    <Image
                      src="/icons/Share_live.png"
                      className="teamPurchase_chat_icon mx-1 mb-1"
                      alt="Header logo for admin dashboard"
                    />
                    Share Link</button>
                  {/* <button className={"btn btn-outline-dark mr-1"}>Notify Follower</button> */}
                  <button className={"btn btn-outline-dark mr-1"} onClick={history.goBack}>Back</button>
                  {livestreamData.startDateTime == null && (
                    <button className={"btn btn-dark"} onClick={()=>changeStatus('START')}>Go Live</button>
                  )}
                  {livestreamData.startDateTime != null && livestreamData.endDateTime == null && (
                    <button className={"btn btn-dark"} onClick={()=>changeStatus('STOP')}>Stop Livestream</button>
                  )}
                  {livestreamData.endDateTime != null && (
                    <button className={"btn btn-dark"} onClick={()=>changeStatus('RESTART')}>Restart Livestream</button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-4 mb-2">
                  <h6>Toolbox</h6>
                  <div className="d-flex flex-row mx-2">
                    <button
                      className={"btn btn-outline-dark btn-sm mr-2"}
                      onClick={handelAddProduct}
                    >
                      <Image
                        src="/icons/best_product.png"
                        className="mx-1 mb-1"
                        style={{ width: "25px" }}
                      />
                      Product</button>
                    <button
                      className={"btn btn-outline-dark btn-sm"}
                      style={{fontSize:'13px'}}
                      onClick={() => handelAddPromo()}
                    >
                      <Image
                        src="/icons/promo.png"
                        className="mx-1 mb-1"
                        style={{ width: "25px" }}
                      />
                      Promotion</button>
                  </div>
                  
              </div>
              <div className="col-9">
                {link && isValidURL(link) && (
                  <embed async defer crossOrigin="anonymous" src={link} width="100%" height="100%" allowFullScreen style={{height:'75vh'}} allowfullscreen="true" ></embed>
                )}
                {!link || !isValidURL(link) && (
                  <>
                  <h5 className="text-center">Live stream link not valid / not supported</h5>
                  <h6 className="text-center">Link : <a className="" href={link}>{link}</a></h6>
                  </>
                )}
              </div>
              {/* <div className="col-8">
                <div className="card">
                  <div
                    className="bg-dark rounded-top"
                    style={{ minHeight: "60vh" }}
                  >
                    <div className="d-flex flex-column justify-content-center vh-50">
                      <h5 className="text-white text-center">
                        Live with streaming software
                      </h5>
                      <br />
                      <div className="row m-0 mb-2">
                        <div className="col-2 text-white text-right">Url</div>
                        <div className="col-8">
                          <input
                            type="text"
                            className="form-control w-100"
                            placeholder="http://testing.com"
                          />
                        </div>
                        <div className="col-2">
                          <button className="btn btn-warning text-dark">Copy</button>
                        </div>
                      </div>
                      <div className="row m-0">
                        <div className="col-2 text-white text-right">Key</div>
                        <div className="col-8">
                          <input
                            type="text"
                            className="form-control w-100"
                            placeholder="my.1234.live"
                          />
                        </div>
                        <div className="col-2">
                          <button className="btn btn-warning text-dark">Copy</button>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                  <div className="row m-0 fw-lighter py-2">
                    <div className="col-6 row m-0 justify-content-end">
                    </div>
                  </div>
                </div>
                <br />
                <h6>Live Real-Time Data</h6>
                <div className="card p-2 pt-3">
                  <div className="row">
                    <div className="col-6 p-2" style={{backgroundColor:"#ecf0f1"}}>
                      <div className="text-secondary fntSz14 text-center">Viewers</div>
                      <div className="text-center">0</div>
                    </div>
                    <div className="col-6 p-2" style={{backgroundColor:"#ecf0f1"}}>
                      <h4 className="text-secondary fntSz14 text-center text">Likes</h4>
                      <div className="text-center">0</div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-3">
                <div className="card">
                  <div className="card-header">
                    <h6>Live Chats</h6>
                  </div>
                  {liveChat != null && (
                    <SBProvider appId={appId} userId={userId} nickname={name}>
                      <div className="sendbird-app__conversation-wrap">
                        <SBConversation
                          channelUrl={liveChat.channel_url}
                          // channelUrl={`TEST_TITLE_LIVE_bfcfd8635dd04afd9c58084151a87678`}
                          minHeight={300}
                        />
                      </div>
                    </SBProvider>
                  )}
                  {liveChat == null && (
                  <div className="card-body" style={{border:'1px solid gray'}}>
                    <div
                      className="p-2 d-flex justify-content-center align-items-center text-secondary"
                      style={{ minHeight: "45vh" }}
                    >
                      No Live Chat are Avaible
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .sendbird-openchannel-conversation{
            max-height: 30rem;
          }
        `}</style>
      </div>
    </React.Fragment>
  );
};

export default ContentGoLive;