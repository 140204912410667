import React from "react";


function MerketingVoucherCategory() {
    return (
        <React.Fragment>
            <div> 
                Voucher
            </div>
        </React.Fragment>
    )
}

export default MerketingVoucherCategory