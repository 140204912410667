import { Breadcrumbs, Link, Typography } from "@mui/material";
import React, { PureComponent } from "react";
import { withRouter } from "react-router";
// import GalleryComponent from '../../components/gallery'
import Layout from "../components/Layout";
import Homepage from "./Homepage";

export class Index extends PureComponent {
  componentDidMount() {
    if (this.props.location.pathname == "/") {
      this.props.history.replace("/dashboard");
    }
  }
  render() {
    // this.props.history.replace('/analytics')
    return (
      <React.Fragment>
        <Layout>
          {/* <Breadcrumbs aria-label="breadcrumb">
                  <Link color="inherit" href="/" onClick={()=>{}}>
                    Home
                  </Link>
                  <Typography color="textPrimary">Dashboard</Typography>
                </Breadcrumbs> */}
          <Homepage></Homepage>
        </Layout>
      </React.Fragment>
    );
  }
}

export default withRouter(Index);
