
import CloseIcon from "@mui/icons-material/Close";

import React, { useState } from 'react'
import InputField from '../../../components/formControl/input/InputField';
import SelectDropdown from '../../../components/select';
import { bankAccout } from "../../../shared/language";

const EditBankAccount = (props) => {
    const [bankName, setBankName] = useState({ value: props.bankName, label: props.bankName });
    const [bankAccountNo, setBankAccountNo] = useState(props.bankAccountNo);
    const [beneciaryName, setBeneciaryName] = useState(props.beneciaryName);
    const [errors, setErrors] = useState();

    const handleSubmit = () => {
        const payload = {
            bankName: bankName.value,
            bankAccountNo,
            beneciaryName,
            index: props.index
        };
        props.onSubmit(payload);
        props.onClose();
    };



    return (
        <React.Fragment>
            <div className="col-12 p-0 m-0 card">
                <div className="close_icon">
                    <CloseIcon
                        className="close_icon_btn m-2"
                        color="primary2"
                        fontSize="large"
                        title="Close dialog"
                        onClick={props.onClose}
                    />
                </div>
                <div className="card-header d-flex justify-content-center">
                    <h4>Add Bank Account</h4>
                </div>
                <div style={{ height: "60vh" }} className="card-body">
                    <form className="col-12 row">
                        <div className="col-12 mb-2">
                            <label className="col-12 m-0 p-0">Bank Name</label>
                            <SelectDropdown
                                options={bankAccout}
                                placeholder="Select Bank"
                                value={bankName}
                                onChange={setBankName}
                            />
                        </div>
                        <div className="col-12 my-2">
                            <label htmlFor="accountNumber" className="col-12 m-0 p-0">
                                Account No.
                            </label>
                            <InputField
                                className="w-100"
                                type="number"
                                id="accountNumber"
                                placeholder="Enter Account No"
                                value={bankAccountNo}
                                onChange={(e) => setBankAccountNo(e.target.value)}
                            />
                        </div>

                        <div className="col-12 my-2">
                            <label htmlFor="name" className="col-12 m-0 p-0">
                                Beneficiary Name
                            </label>

                            <InputField
                                className="w-100"
                                type="text"
                                id="name"
                                placeholder="Enter Beneficiary "
                                value={beneciaryName}
                                onChange={(e) => setBeneciaryName(e.target.value)}
                            />
                        </div>
                    </form>
                </div>
                <div className="submit_btn col-md-12">
                    <button
                        disabled={!bankName || !bankAccountNo || !beneciaryName}
                        onClick={handleSubmit} className="btn btn-primary">
                        Submit
                    </button>
                </div>
            </div>
            <style jsx="true">{`
        .card-body {
          min-height: 60vh;
        }
        .submit_btn {
          position: absolute;
          bottom: 15px;
          width: 100%;
          justify-content: center;
          display: flex;
        }
        .close_icon_btn {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      `}</style>
        </React.Fragment>
    )
}

export default EditBankAccount