import { IconButton, Pagination, Stack, TablePagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import InputField from "../../../components/formControl/input/InputField";
import SvgIcon from "../../../components/Images/SvgIcon";
import { AllowAccess, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getAllMerchantList } from "../../../redux/actions/merchant-center.action";
import "../../../static/css/merchantCss/MerchantAccount.css";
import { ExportCSV } from "../../../components/export/exportXlsxFile";
import PagenationDropdown from "../../../components/DropDown/pagenationDropdown";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import ablity from "../../../helpers/abilityGuard";
import { getLocalStorage } from "../../../lib/session";

const initialMerchantSearchDetail = {
  storeId: "",
  merchantName: "",
  preferredMerchantName: "",
  merchantRegisteredDate: "",
};
const MerchantProfiles = () => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [merchantData, setMerchantData] = useState([]);
  const [searchData, setSearchData] = useState(initialMerchantSearchDetail);
  const [storeId, setStoreId] = useState();
  const [merchantName, setMerchantName] = useState();
  const [preferredMerchantName, setPreferredMerchantName] = useState();
  const [merchantRegisteredDate, setMerchantRegisteredDate] = useState();
  const [totalCount, setTotalCount] = useState();
  const [errorMessage, setErrorMessage] = useState("");

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);
  const refSearchData = useRef({});

  useEffect(() => {
    if(getLocalStorage('role') === "MERCHANT"){
      history.push("/404");
    }

    const breadcrumb = [{ label: "Merchant Profile", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    let filteringdata = { ...searchData };

    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      resetSearch();
      setPageNo(1);
      setOffset(0);
      filteringdata = {
        // status: props.status,
        limit: pageLimit,
        offset: 0,
      };
    } else if (refSearchData.current !== searchData) {
      setPageNo(1);
      setOffset(0);
      filteringdata = {
        ...searchData,
        // status: props.status,
        limit: pageLimit,
        offset: 0,
      };
      refSearchData.current = searchData;
    } else {
      filteringdata = {
        ...searchData,
        // status: props.status,
        offset,
        limit: pageLimit,
      };
    }

    apiData(filteringdata);
  }, [offset, pageLimit, searchData]);

  const handlePageChange = (event, value) => {
    setOffset(value - 1);
    setPageNo(value);
  };

  const apiData = (filteringdata) => {
    startLoader();
    setErrorMessage("Loading Data...");
    const response = dispatch(getAllMerchantList(filteringdata));
    response
      .then((data) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        if (data && data.data && data.data.status === 200) {
          // setMerchantData(data && data.data && data.data.data);
          setMerchantData(
            data &&
              data.data &&
              data.data.data.map((row, index) => {
                // console.log("rowwwwwwwwwwwwwwwwwwwwwwww", row);
                return {
                  merchantId: row?.storeId || "N/A",
                  merchantName: row?.merchantName || "N/A",
                  preferredMerchantName: row?.preferredMerchantName || "N/A",
                  rating: row?.rating || 0,
                  earning: row?.earning || "N/A",
                  product: Number(row?.products?.length) || 0,
                  productSold: row?.productSold || 0,
                  email: row?.authorizedPerson[0]?.email || "N/A",
                  updatedAt: row.updatedAt
                    ? moment(row.updatedAt).format("DD/MM/YY, HH:mm")
                    : "N/A",
                  status: row?.status || "N/A",
                  accountBalance: row?.accountBalance || 0,
                  formReceivedDate: row?.formReceivedDate,
                };
              })
          );
          setTotalCount(data && data.data && data.data.totalCount);
          setTimeout(() => {
            stopLoader();
          }, 1500);
          setErrorMessage("");
        } else {
          setErrorMessage("No Data !")
          setTimeout(() => {
            stopLoader();
          }, 1000);
        }
      })
      .catch((error) => {
        setErrorMessage("Error : "+error.message)
      });
    setTimeout(() => {
      stopLoader();
    }, 1000);
  };

  const searchMerchat = () => {
    setSearchData({
      ...searchData,
      storeId,
      merchantName,
      preferredMerchantName,
      merchantRegisteredDate,
    });
  };
  const resetSearch = () => {
    setStoreId("");
    setMerchantName("");
    setPreferredMerchantName("");
    setMerchantRegisteredDate("");
    setSearchData({
      ...initialMerchantSearchDetail,
      offset: searchData.offset,
    });
  };

  const download = () => {
    let filter = {
      // status: props.status,
      limit: 100000,
      offset: 0,
    };
    const response = dispatch(getAllMerchantList(filter));
    response
      .then((data) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        if (data && data.data && data.data.status === 200) {
          let result = data.data.data.map((row, index) => {
            return {
              merchantId: row?.storeId || "N/A",
              merchantName: row?.merchantName || "N/A",
              preferredMerchantName: row?.preferredMerchantName || "N/A",
              rating: row?.rating || 0,
              earning: row?.earning || "N/A",
              product: Number(row?.products?.length) || 0,
              productSold: row?.productSold || 0,
              email: row?.authorizedPerson[0]?.email || "N/A",
              updatedAt: row.updatedAt
                ? moment(row.updatedAt).format("DD/MM/YY, HH:mm")
                : "N/A",
              status: row?.status || "N/A",
              accountBalance: row?.accountBalance || 0,
              formReceivedDate: row?.formReceivedDate,
            };
          });
          setTimeout(() => {
            stopLoader();
            exportToCSV(result, "MerchantProfile");
          }, 1000);
        } else {
          setTimeout(() => {
            stopLoader();
          }, 1000);
        }
      })
      .catch((error) => console.log("----------error----", error));
    setTimeout(() => {
      stopLoader();
    }, 1000);
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof merchantData[0][item] === "number") {
        let sortedData = merchantData.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setMerchantData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof merchantData[0][item] === "string" && !isDate) {
        let sortedData = merchantData.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
        setTimeout(() => {
          setMerchantData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof merchantData[0][item] === "string" && isDate) {
        let sortedData = merchantData.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setMerchantData([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      console.log({ error });
      stopLoader();
    }
  };

  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-12 d-flex flex-wrap">
          <div className="min-width-200 col-md-4">
            <label className="d-block">Merchant ID</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="storeId"
              value={storeId}
              onChange={(e) => setStoreId(e.target.value)}
            />
          </div>
          {/* <div className="min-width-200 col-md-4">
            <label className="d-block">Merchant Name</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="merchantName"
              value={merchantName}
              onChange={(e) => setMerchantName(e.target.value)}
            />
          </div> */}
          <div className="min-width-200 col-md-4">
            <label className="row flex-nowrap ml-1">
              Merchant Preferred Name
            </label>
            <InputField
              type="text"
              className="form-control ml-1"
              placeholder="Input"
              id="preferredMerchantName"
              value={preferredMerchantName}
              onChange={(e) => setPreferredMerchantName(e.target.value)}
            />
          </div>

          <div className="min-width-200 col-md-4">
            <label className="d-block">Registered Date</label>
            <InputField
              type="date"
              className="form-control"
              id="merchantRegisteredDate"
              value={merchantRegisteredDate}
              onChange={(e) => setMerchantRegisteredDate(e.target.value)}
              // disabled={true}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn input-inside-text mr-1 btn-white border px-4"
            onClick={() => resetSearch()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn input-inside-text mx-1 btn-secondary px-3"
            onClick={() => searchMerchat()}
          >
            Search
          </button>
        </div>
      </div>
      <div className="mt-3 ">
        <div className="mx-3 my-3">
          <div className="py-3">
            <div className="border card p-3 my-3 ">
              <div className="d-flex justify-content-between">
                <div>
                  <span>ALL MERCHANT ACCOUNTS LISTING</span>
                </div>
                <div className="d-flex">
                  <button
                    type="button"
                    onClick={download}
                    className="btn add-product-botton py-1 mr-2 btn-dark"
                  >
                    <span className="text-nowrap">
                      <small className="text-nowrap">Export</small>
                    </span>
                  </button>
                </div>
              </div>
              <TableContainer>
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="th_cell" component="th">
                        No
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        {/* Merchant ID */}
                        <div className="d-flex  ">
                          <div>Merchant ID</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("merchantId", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("merchantId", "desc")
                              }
                            />
                          </div>
                        </div>
                      </TableCell>
                      {/* <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex">
                          <div>Merchant Name</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("merchantName", "asc")}

                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("merchantName", "desc")}

                            />
                          </div>
                        </div>
                      </TableCell> */}
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex">
                          <div>Merchant Preferred Name</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() =>
                                handleSorting("preferredMerchantName", "asc")
                              }
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("preferredMerchantName", "desc")
                              }
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div>Rating</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("rating", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("rating", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex">
                          <div>Earning</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("earning", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("earning", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex">
                          <div>Product</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("product", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("product", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex">
                          <div>Product Sold </div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() =>
                                handleSorting("productSold", "asc")
                              }
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("productSold", "desc")
                              }
                            />
                          </div>
                        </div>
                      </TableCell>

                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div>Form Received Date</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() =>
                                handleSorting("formReceivedDate", "asc", true)
                              }
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("formReceivedDate", "desc", true)
                              }
                            />
                          </div>
                        </div>
                      </TableCell>

                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex">
                          <div>Email</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("email", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("email", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex">
                          <div> Updated On</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("updatedAt", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("updatedAt", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex">
                          <div>Status</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("status", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("status", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      {/* <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex">
                          <div> Account Balance</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() =>
                                handleSorting("accountBalance", "asc")
                              }
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("accountBalance", "desc")
                              }
                            />
                          </div>
                        </div>
                      </TableCell> */}
                      <TableCell className="table_action_head" component="th">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {errorMessage != "" && (
                      <TableRow>
                        <TableCell align="center" colSpan={5}>
                          <h4>{errorMessage}</h4>
                        </TableCell>
                      </TableRow>
                    )}
                    {merchantData &&
                      merchantData.length > 0 &&
                      merchantData.map((row, index) => {
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="center" className="td_cell">
                              {offset * pageLimit + (index + 1)}
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              {row.merchantId || "N/A"}
                            </TableCell>
                            {/* <TableCell align="left" className="td_cell">
                              {row.merchantName || "N/A"}
                            </TableCell> */}
                            <TableCell align="left" className="td_cell">
                              {row.preferredMerchantName || "N/A"}
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              {String(row.rating || 0)}
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              {row.earning || "N/A"}
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              {String(row?.product || 0)}
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              {row.productSold || 0}
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              {row.formReceivedDate
                                ? moment(row.formReceivedDate).format(
                                    "DD/MM/YY"
                                  )
                                : "N/A"}
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              {row?.email || "N/A"}
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              {row.updatedAt || "N/A"}
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              {row.status || "N/A"}
                            </TableCell>
                            {/* <TableCell align="left" className="td_cell">
                              {row.accountBalance || "N/A"}
                            </TableCell> */}

                            <TableCell className="d-flex justify-content-between table_action_item">
                              {ablity({ model: "Merchant", action: "read" }) ==
                                1 && (
                                <IconButton
                                  onClick={() => {
                                    history.push(
                                      `profiles/${row.merchantId}/${row.preferredMerchantName}`
                                    );
                                  }}
                                  className="pointer"
                                >
                                  <SvgIcon
                                    src="/icons/view_icon.svg"
                                    alt="view icon"
                                  />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {/* {merchantData && merchantData.length > 0 && merchantData.map((row, index) => (

                    ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <div className="d-flex justify-content-between align-items-center">
                <PagenationDropdown setPageQuant={setPageLimit} />
                <div className=" float-right mb-1">
                  <Stack spacing={2}>
                    <Pagination
                      // value={searchData.offset}
                      count={Math.ceil(totalCount / pageLimit)}
                      shape="rounded"
                      size="small"
                      page={pageNo}
                      onChange={handlePageChange}
                    />
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MerchantProfiles;
