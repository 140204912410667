
import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

// import rating_stars from "../../../language"

import SelectInput from '../../components/select';
import { breadcrumbAction } from '../../redux/actions/config.action';
import { TabPanel } from "../../components/Tabs/TabPannel";
import ShopRatingDetail from './ShopRattingDetail';

import { getMerchantShopRating } from '../../redux/actions/merchant-center.action';
import ablity from "../../helpers/abilityGuard";
import { getLocalStorage } from '../../lib/session';
import { AllowAccess } from '../../lib/global';

const initialSearchData = {
    productName: "",
    variationName: "",
    reviewDate: "",
    consumerName:"",
    stars:""
}

const  rating_stars=[
    {label:"All",value:""},
    {label:"0",value:"0"},
    {label:"1",value:"1"},
    {label:"2",value:"2"},
    {label:"3",value:"3"},
    {label:"4",value:"4"},
    {label:"5",value:"5"},
]


const ShopRatting = (props) => {
    AllowAccess(["MERCHANT"]);
    const dispatch = useDispatch()
    const storeId = getLocalStorage("storeId")
    const { mid, merchantName } = useParams()
    const [searchData, setSearchData] = useState(initialSearchData)
    const [shopRating, setShopRating] = useState()

    const [activeTab, setActiveTab] = useState("basicSetting");
    const [replyStatus, setReplyStatus] = useState("");

    const [search,setSearch]=useState('')

    useEffect(() => {
        const breadcrumb = [
            { label: "Merchant Profile", url: "/merchant-center/profiles" },
            { label: merchantName, url: null }
        ];
        dispatch(breadcrumbAction(breadcrumb));
    }, [])

    const onSetRatting = (ratting) =>{
        setShopRating(ratting)
    }

    const onChangeSearchDetail = (e, title, val) => {
        const key = title || e.target.name;
        const value = val || e.target.value;
        setSearchData((pre) => ({ ...pre, [key]: value }));
    };

    const ResetSearchData = () => {
        setSearchData(initialSearchData)
        setSearch(initialSearchData)
    }

    const searchDataFun = () => {
        setSearch({
            ...searchData,
            stars: searchData.stars === "" ? "" : searchData.stars.value
        })
    }

    return (
        <Fragment>
            <div>
                <div className="mt-1 rounded bg-white">
                    <div className="col-12 p-0 row flex-wrap">
                        <div className="min-width-180 col-md-2">
                            <label>Product Name</label>
                            <input type="text"
                                className="form-control"
                                placeholder="Input"
                                name="productName"
                                value={searchData.productName}
                                onChange={onChangeSearchDetail}
                            />
                        </div>
                        {/* <div className="min-width-180 col-md-2">
                            <label>Variation Name</label>
                            <input type="text"
                                className="form-control"
                                placeholder="Input"
                                name="variationName"
                                value={searchData.variationName}
                                onChange={onChangeSearchDetail}
                            />
                        </div> */}
                        <div className="min-width-180 col-md-2">
                            <label>Consumer Name</label>
                            <input type="text"
                                className="form-control"
                                placeholder="Input"
                                name="consumerName"
                                value={searchData.consumerName}
                                onChange={onChangeSearchDetail}
                            />
                        </div>
                        <div className="min-width-180 col-md-2">
                            <label>Review Date</label>
                            <input type='date'
                                className="form-control"
                                placeholder="Date"
                                name="reviewDate"
                                value={searchData.reviewDate}
                                onChange={onChangeSearchDetail}
                            />
                        </div>
                        <div className="col-md-2">
                            <label>Stars</label>
                            <SelectInput
                                options={rating_stars}
                                name="stars"
                                value={searchData.stars}
                                onChange={(e) => onChangeSearchDetail(e, "stars", e)}
                                // formSubmitted={false}
                                placeholder="All"
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-3">
                        <button onClick={ResetSearchData}
                            type="button"
                            className="btn input-inside-text mr-1 btn-white border px-4">
                            Reset
                        </button>
                        <button
                            type="button"
                            className="btn input-inside-text mx-1 btn-secondary px-3"
                            onClick={() => searchDataFun()}
                        >
                            Search
                        </button>
                    </div>
                </div>
                <hr />
                <div>
                    <div className="px-1">
                        <div className='float-right px-2'>
                            <div className="text-secondary text-nowrap"><span className="bold fntSz28 px-2 text-dark">{shopRating?.avg}</span>/5</div>
                        </div>
                        <h4 className="bold m-0">Shop Rating</h4>
                        <p className="text-muted small">View merchant&apos;s shop rating</p>

                    </div>
                    <div className="d-flex px-1">
                        <button
                            onClick={() => setReplyStatus("")}
                            className={`btn btn-${replyStatus == "" ? "dark" : "outline-dark"
                                } btn-sm mr-3 px-4`}
                        >
                            All
                        </button>
                        <button
                            onClick={() => setReplyStatus("TOREPLY")}
                            className={`btn btn-${replyStatus == "TOREPLY" ? "dark" : "outline-dark"
                                } btn-sm mr-3 px-4`}
                        >
                            To Reply
                        </button>
                        <button
                            onClick={() => setReplyStatus("REPLY")}
                            className={`btn btn-${replyStatus == "REPLY" ? "dark" : "outline-dark"
                                } btn-sm mr-3 px-4`}
                        >
                            Replied
                        </button>
                    </div>
                    <TabPanel
                        style={{ display: activeTab == "basicSetting" ? "block" : "none" }}
                        value={0} index={0}
                        costumStyle={{ padding: "0px" }}
                    >
                        <ShopRatingDetail replyStatus={replyStatus} storeId={storeId} search={search} setRatting={onSetRatting} />
                    </TabPanel>
                </div>
            </div>



            <style jsx="true" >{`
                .shopRating_inputDiv{
                    margin: -26px; 
                }
                .min-width-180{
                    min-width: 180px !important;
                }
            `}

            </style>
        </Fragment>
    )
}

export default ShopRatting;