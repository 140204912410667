import React from "react";
import useTheme from "../../hooks/useTheme";

const StatusBar = ({ activeTab, setActiveTab, options }) => {
    const [theme] = useTheme()
  return (
    <React.Fragment>
      <div className="d-flex">
        {options.map((item, i) => {
          return (
            <div
              onClick={() => setActiveTab(item)}
              className={`status_select_bar ${!i ? "first" : ""} ${
                i == options.length - 1 ? "last" : ""
              } ${activeTab == item ? "active" : ""}`}
            >
              {item}
            </div>
          );
        })}
      </div>
      <style jsx="true">{`
        .status_select_bar {
          border: 1px solid ${theme.color1};
          padding: 10px;
          cursor: pointer;
          color: ${theme.color2}
        }
        .status_select_bar.first {
          border-radius: 5px 0 0 5px;
        }
        .status_select_bar.last {
          border-radius: 0 5px 5px 0;
        }
        .status_select_bar.active {
          background:  ${theme.color2};
          color: #fff;
          font-weight: bold;
        }
      `}</style>
    </React.Fragment>
  );
};

export default StatusBar;
