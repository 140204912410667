import {
    IconButton,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
  import ExpandLessIcon from "@mui/icons-material/ExpandLess";
  
  import React, { useEffect, useRef, useState } from "react";
  import { useHistory, useParams, useLocation } from "react-router-dom";
  import { useDispatch } from "react-redux";
  import SvgIcon from "../../../../components/Images/SvgIcon";
  import { getProductsBymerchantId } from "../../../../redux/actions/product.action";
  import moment from "moment";
  
  import {
    startLoader,
    stopLoader,
    showToast,
    open_dialog,
    AllowAccess,
  } from "../../../../lib/global";
  import {
    ACCESS_DENIED,
    DELETE_PRODUCT_BY_MERCHANT_DIALOG,
    PRODUCT_DELETE_DIALOG,
  } from "../../../../lib/constant";
  import { getLocalStorage } from "../../../../lib/session";
  import PagenationDropdown from "../../../../components/DropDown/pagenationDropdown";
  
  const initialSearhData = {
    item: "",
    order: "",
    search: "",
    offset: 0,
  };
  
  function Allproduct(props) {
    AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
    const [permissions, setPermissions] = useState(
      getLocalStorage("assignMethod")
    );
    const history = useHistory();
    const dispatch = useDispatch();
  
    const [allProductData, setAllProductData] = useState([]);
  
    const [pageNo, setPageNo] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const limit = useRef(10);
    const [offset, setOffset] = useState(0);
    const refSearchData = useRef({});
    const { afterDelete } = props;
  
    const { merchantId, merchantName } = props;
  
    useEffect(() => {
      if(getLocalStorage('role') === "MERCHANT"){
        history.push("/404");
      }

      if (permissions !== "undefined") {
        setPermissions(JSON.parse(getLocalStorage("assignMethod")));
      } else {
        setPermissions({});
      }
    }, []);
  
    useEffect(() => {
      let filteringdata;
      let storeId = props.merchantId
      console.log(storeId,'storeId')
      if (pageLimit != limit.current) {
        limit.current = pageLimit;
        props.reset();
        setPageNo(1);
        setOffset(0);
        filteringdata = {
          status: props.status,
          limit: pageLimit,
          offset: 0,
        };
      } else if (refSearchData.current !== props.search) {
        setPageNo(1);
        setOffset(0);
        filteringdata = {
          ...props.search,
          status: props.status,
          limit: pageLimit,
          offset: 0,
        };
        refSearchData.current = props.search;
      } else {
        filteringdata = {
          ...props.search,
          status: props.status,
          offset,
          limit: pageLimit,
        };
      }
      startLoader();
  
      const response = dispatch(getProductsBymerchantId(storeId,{ ...filteringdata }));
      response
        .then((data) => {
          props.setTotals(data.data.total || {});
          setAllProductData(
            data &&
              data.data &&
              data.data.data.map((row, index) => {
                return {
                  productName: row?.title || "N/A",
                  productType: row?.productType || "N/A",
                  rating: row?.rating || 0,
                  categoryName: row?.categoryName || "N/A",
                  lowestPrice: Number(row?.lowestPrice?.price) || "N/A",
                  createdDate: row?.createdAt,
                  //   ? moment(row.createdAt).format("DD/MM/YY")
                  //   : "N/A",
                  merchantName: row?.merchant?.preferredMerchantName || "N/A",
                  stock: row?.totalStock || 0,
                  status: row?.statusText || "N/A",
                  productId: row?.productId,
                };
              })
          );
  
          stopLoader();
        })
        .catch((error) => {
          stopLoader();
        });
    }, [offset, pageLimit, props.search]);
  
    /**
     *
     * @param {list for appling sorting} item
     * @param {type of sorting (ascending ,descending)} sortType
     * @param {option when you have to sort the date} isDate
     */
  
    const handleSorting = (item, sortType, isDate = false) => {
      startLoader();
      try {
        if (typeof allProductData[0][item] === "number") {
          let sortedData = allProductData.sort((a, b) => {
            if (sortType == "asc") {
              return a[item] - b[item];
            } else if (sortType == "desc") {
              return b[item] - a[item];
            }
          });
          setTimeout(() => {
            setAllProductData([...sortedData]);
            stopLoader();
          }, [1000]);
        } else if (typeof allProductData[0][item] === "string" && !isDate) {
          let sortedData = allProductData.sort((a, b) => {
            let fa = a[item].toLowerCase(),
              fb = b[item].toLowerCase();
            if (sortType == "asc") {
              if (fa < fb) {
                return -1;
              }
              if (fa > fb) {
                return 1;
              }
              return 0;
            } else if (sortType == "desc") {
              if (fa < fb) {
                return 1;
              }
              if (fa > fb) {
                return -1;
              }
              return 0;
            }
          });
  
          setTimeout(() => {
            setAllProductData([...sortedData]);
            stopLoader();
          }, [1000]);
        } else if (typeof allProductData[0][item] === "string" && isDate) {
          let sortedData = allProductData.sort((a, b) => {
            let da = new Date(a[item]);
            let db = new Date(b[item]);
  
            if (sortType == "asc") {
              return da - db;
            } else if (sortType == "desc") {
              return db - da;
            }
          });
          setTimeout(() => {
            setAllProductData([...sortedData]);
            stopLoader();
          }, [1000]);
        }
      } catch (error) {
        stopLoader();
      }
    };
  
    const handlePageChange = (event, value) => {
      setOffset(value - 1);
      setPageNo(value);
    };
  
    const addProduct = (item) => {
      if (!item && item !== undefined) {
        open_dialog(ACCESS_DENIED, {});
      } else {
        if (merchantId) {
          history.push(`/merchant/add-product/${merchantId}/${merchantName}`);
        } else {
          history.push("/merchant/add-product");
        }
      }
    };
  
    const editAction = (id, item) => {
      if (!item && item !== undefined) {
        open_dialog(ACCESS_DENIED, {});
      } else {
        if (merchantId) {
          history.push(
            `/merchant/edit-product/${id}/${merchantId}/${merchantName}`
          );
        } else {
          history.push(`/merchant/edit-product/${id}`);
        }
      }
    };
    const viewAction = (id) => {
      history.push(`/products/view?id=${id}`);
    };
  
    const getProductAfterDelete = (data) => {
      afterDelete(data);
      setAllProductData(
        data &&
          data.data &&
          data.data.map((row, index) => {
            return {
              productName: row?.title || "N/A",
              productType: row?.productType || "N/A",
              rating: row?.rating || 0,
              categoryName: row?.categoryName || "N/A",
              lowestPrice: Number(row?.lowestPrice?.price) || "N/A",
              createdDate: row?.createdAt,
              merchantName: row?.merchant?.preferredMerchantName || "N/A",
              stock: row?.totalStock || 0,
              status: row?.statusText || "N/A",
              productId: row?.productId,
            };
          })
      );
    };
  
    const handleDeleteProduct = (id, item) => {
      if (!item && item !== undefined) {
        open_dialog(ACCESS_DENIED, {});
      } else if (item == "undefined" || item == undefined) {
        open_dialog(DELETE_PRODUCT_BY_MERCHANT_DIALOG, {
          onSubmit: getProductAfterDelete,
          id,
          merchantId,
        });
      } else {
        open_dialog(DELETE_PRODUCT_BY_MERCHANT_DIALOG, {
          onSubmit: getProductAfterDelete,
          id,
          merchantId,
        });
      }
    };
  
    return (
      <React.Fragment>
        <div className="d-flex justify-content-between">
          <div>
            <span>{props?.totalItem || 0} Products</span>
          </div>
          <div className="d-flex my-3">
            <button
              onClick={() => addProduct(permissions?.product?.includes("create"))}
              type="button"
              className="btn btn-dark btn-sm text-nowrap mx-3"
            >
              + Add product
            </button>
          </div>
        </div>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell className="th_cell" component="th">
                  No
                </TableCell>
                <TableCell className="th_cell" component="th" align="left">
                  <div className="d-flex  ">
                    <div>Name</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("productName", "asc")}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("productName", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Type</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("productType", "asc")}
                        // onClick={() => getApiData({ item: "title", order: "ASC" })}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("productType", "desc")}
                        // onClick={() => getApiData({ item: "title", order: "DESC" })}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="th_cell " component="th" align="center">
                  <div className="d-flex  ">
                    <div>Rating</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("rating", "asc")}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("rating", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>
  
                <TableCell className="th_cell " component="th" align="center">
                  <div className="d-flex  ">
                    <div>Category</div>
  
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("categoryName", "asc")}
  
                        // onClick={() => getApiData({ item: "categoryName", order: "ASC" })}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("categoryName", "desc")}
  
                        // onClick={() => getApiData({ item: "categoryName", order: "DESC" })}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Comm Rate</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("lowestPrice", "asc")}
  
                        // onClick={() => getApiData({ item: "title", order: "ASC" })}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("lowestPrice", "desc")}
  
                        // onClick={() => getApiData({ item: "title", order: "DESC" })}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Date Added</div>
  
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={(isDate) =>
                          handleSorting("createdDate", "asc", (isDate = true))
                        }
                        // onClick={() => getApiData({ item: "createdAt", order: "ASC" })}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={(isDate) =>
                          handleSorting("createdDate", "desc", (isDate = true))
                        }
                        // onClick={() => getApiData({ item: "createdAt", order: "DESC" })}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Merchant</div>
  
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("merchantName", "asc")}
                        // onClick={() => getApiData({ item: "merchantName", order: "ASC" })}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("merchantName", "desc")}
  
                        // onClick={() => getApiData({ item: "merchantName", order: "DESC" })}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Stock</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("stock", "asc")}
  
                        // onClick={() => getApiData({ item: "title", order: "ASC" })}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("stock", "desc")}
  
                        // onClick={() => getApiData({ item: "title", order: "DESC" })}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Status</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("status", "asc")}
                        // onClick={() => getApiData({ item: "title", order: "ASC" })}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("status", "desc")}
  
                        // onClick={() => getApiData({ item: "title", order: "DESC" })}
                      />
                    </div>
                  </div>
                </TableCell>
  
                <TableCell className="table_action_head" component="th">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!allProductData?.length && (
                <div className="d-flex justify-content-center">
                  <h3 className="text-muted m-auto">No data found!</h3>
                </div>
              )}
  
              {allProductData &&
                allProductData.length > 0 &&
                allProductData.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left" className="td_cell">
                      {offset * pageLimit + (index + 1)}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row?.productName || "N/A"}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.productType || "N/A"}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {String(row.rating) || "N/A"}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.categoryName || "N/A"}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row?.lowestPrice?.price || "N/A"}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.createdDate
                        ? moment(row?.createdDate).format("DD/MM/YY")
                        : "N/A"}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.merchantName || "N/A"}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {String(row.stock) || "N/A"}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                      {row.status || "N/A"}
                    </TableCell>
  
                    <TableCell className="d-flex justify-content-between table_action_item">
                      <IconButton
                        className="pointer"
                        onClick={() => viewAction(row.productId)}
                      >
                        <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                      </IconButton>
  
                      {row.status !== "NEW" && (
                        <IconButton
                          // onClick={() => history.push(`edit?id=${row.productId}`)}
                          className="pointer"
                          onClick={() =>
                            editAction(
                              row.productId,
                              permissions?.product?.includes("update")
                            )
                          }
                        >
                          <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />
                        </IconButton>
                      )}
  
                      <IconButton
                        className="pointer"
                        // onClick={() => handleDeleteProduct(row.productId)}
                        onClick={() =>
                          handleDeleteProduct(
                            row.productId,
                            permissions?.product?.includes("delete")
                          )
                        }
                      >
                        <SvgIcon src="/icons/delete_icon.svg" alt="delete icon" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <br />
        <div className="d-flex justify-content-between align-items-center">
          <PagenationDropdown setPageQuant={setPageLimit} />
  
          <div className="float-right mb-1">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil((props?.totalItem || 0) / pageLimit)}
                shape="rounded"
                size="small"
                page={pageNo}
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </div>
        <br />
      </React.Fragment>
    );
  }
  
  export default Allproduct;
  