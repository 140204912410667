import {
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SvgIcon from "../../components/Images/SvgIcon";
import { breadcrumbAction } from "../../redux/actions/config.action";
import {
  getAllOutletApi,
  exportExcel
} from "../../services/outlet.service";
import "../../static/css/productCss/ProductBrand.css";
import {
  AllowAccess,
  open_dialog,
  showToast,
  startLoader,
  stopLoader,
} from "../../lib/global";
import { DELETE_OUTLET_MERCHANT } from "../../lib/constant";
import PagenationDropdown from "../../components/DropDown/pagenationDropdown";
import ablity from "../../helpers/abilityGuard";
import SelectInput from "../../components/select";

const statusList = [
  // {
  //   "label": "",
  //   "value": ""
  // },
  {
    "label": "Active",
    "value": 1
  },
  {
    "label": "Non Active",
    "value": 0
  }
]

const initialSearchData = {
  search: "",
  outletCode: "",
  outletName: "",
  state: "",
  postalCode: "",
  status: ""
};

const MerchantOutletListing = () => {
  AllowAccess(["MERCHANT"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [outletData, setOutletData] = useState([]);
  // const [searchData, setSearchData] = useState(initialSearchData);
  const [search, setSearch] = useState(initialSearchData);
  const [totalOutlets, setTotalOutlets] = useState(0);

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);

  const [outletCode, setOutletCode] = useState('');
  const [outletName, setOutletName] = useState('');
  const [state, setState] = useState('');
  const [status, setStatus] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [statusData, setStatusData] = useState("");
  const [filterData, setFilterData] = useState("");
  const [order, setOrder] = useState("");
  const [item, setItem] = useState("");

  useEffect(() => {
    AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
    const breadcrumb = [{ label: "Merchant Outlet", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    startLoader();
    // const data= {...searchData }

    let filteringdata;
    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      setSearch(initialSearchData);
      setPageNo(1);
      setOffset(0);
      filteringdata = {
        limit: pageLimit,
        item: item,
        order: order,
        status: status
      };
    } else {
      filteringdata = {
        ...search,
        offset,
        limit: pageLimit,
        item: item,
        order: order,
        status: status
      };
    }

    setFilterData(filteringdata);
    apiData(filteringdata);
  }, [offset, pageLimit, search]);

  const apiData = (filteringdata) => {
    dispatch(getAllOutletApi(filteringdata))
      .then((data) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        if (data && data.data && data.data.status === 200) {
          setOutletData(
            data &&
            data.data &&
            data.data.data.map((row, index) => {
              return {
                outletCode: row?.outletCode || "N/A",
                outletName: row?.outletName || "N/A",
                address: row?.address || '-',
                postalCode: row?.postalCode || 0,
                state: row?.state || "N/A",
                operationalHours: row?.startTime + ' - ' + row?.endTime,
                startTime: row?.startTime,
                endTime: row?.endTime,
                contactNumber: row?.contactNumber,
                createdAt: moment(row?.createdAt).format('YYYY-MM-DD HH:mm:ss'),
                createdBy: row?.createdBy,
                updatedAt: moment(row?.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
                updatedBy: row?.updatedBy,
                id: row?.id || "",
                status: row.status == 1 ? "Active" : "Non Active"
              };
            })
          );
          setTotalOutlets(
            data && data.data && data.data.data && data.data.totalOutlet
          );
        }
      })
      .catch((error) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
      });
  };

  const handlePageChange = (event, value) => {
    setOffset(value - 1);
    setPageNo(value);
  };

  const getOutletDataAfterDelete = (data, totalOutlet) => {
    setTotalOutlets(totalOutlet);
    setOutletData(data);
  };
  const DeleteOutletById = (id) => {
    open_dialog(DELETE_OUTLET_MERCHANT, {
      onSubmit: getOutletDataAfterDelete,
      id,
    });
  };

  const addOutletFun = () => {
    history.push("/merchant/outlet/add");
  };

  const EditOutletfun = (id) => {
    history.push(`/merchant/outlet/add/${id}`);
  };

  const ViewOutletfun = (id) => {
    history.push(`/merchant/outlet/detail/${id}`);
  };

  const onChangeSeachData = (data) => {
    setSearchData(data);
  };

  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof outletData[0][item] === "number") {
        let sortedData = outletData.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setOutletData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof outletData[0][item] === "string" && !isDate) {
        let sortedData = outletData.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });

        setTimeout(() => {
          setOutletData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof outletData[0][item] === "string" && isDate) {
        let sortedData = outletData.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setOutletData([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      console.log({ error });
      stopLoader();
    }
  };

  const handleResetSearch = () => {
    setOutletCode("");
    setOutletName("");
    setState("");
    setStatus("");
    setStatusData("");
    setPostalCode("");
    setSearch({ outletCode: "", outletName: "", state: "", status: "", postalCode: "" });
    setFilterData("");
  };

  const onChangeBasicInfoStatus = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setStatus(val.value);
    let statusBasic = [
      {
        "label": val.label,
        "value": val.value
      }
    ]
    setStatusData(statusBasic);
  };

  const searchData = () => {
    setSearch({ outletCode, outletName, state, status, postalCode });
  };


  const exportExcelFun = async () => {
    startLoader()
    dispatch(exportExcel(filterData))
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${Date.now()}.xlsx`);
        // Append to html link element page
        document.body.appendChild(link);
        setTimeout(() => {
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          stopLoader()
        }, 1000)
      }).catch(err => {
        console.log({ err });
        alert(err)
        stopLoader()
      })

  }

  const handleSortingHours = (field, order) => {
    // let sortedData = outletData.sort((a, b) => {
    //   // Get the first operational hour of each outlet
    //   let startTimeA = a[field];
    //   let startTimeB = b[field];

    //   // Convert startTime strings to Date objects
    //   let da = new Date(`1970-01-01T${startTimeA}`);
    //   let db = new Date(`1970-01-01T${startTimeB}`);

    //   if (order === "asc") {
    //     return da - db;
    //   } else if (order === "desc") {
    //     return db - da;
    //   }
    // });

    // setTimeout(() => {
    //   setOutletData([...sortedData]);
    //   stopLoader();
    // }, 1000);
    setItem(field);
    setOrder(orderType);

    apiData({
      ...search,
      offset,
      storeId,
      limit: pageLimit,
      item: "startTime",
      order: orderType
    })
  };


  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-12 row flex-wrap">
          <div className="min-width-200 col-md-4">
            <label>Outlet Code</label>
            <input
              type="text"
              className="form-control"
              placeholder="Input Outlet Code"
              value={outletCode}
              onChange={(e) => setOutletCode(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-4">
            <label>Outlet Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Input Outlet Name"
              value={outletName}
              onChange={(e) => setOutletName(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-4">
            <label>State</label>
            <input
              type="text"
              className="form-control"
              placeholder="Input State"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-4">
            <label>Postal Code</label>
            <input
              type="text"
              className="form-control"
              placeholder="Input Postal Code"
              value={postalCode}
              onChange={(e) => setPostalCode(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-4">
            <label>Status</label>
            <SelectInput
              options={statusList}
              name="status"
              value={statusData}
              onChange={(e) => onChangeBasicInfoStatus(e, "status", e)}
              required={true}
              formSubmitted={false}
              placeholder="Select Status"
            />
          </div>


        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            onClick={() => handleResetSearch()}
            className="btn btn-outline-dark px-4 mx-3 btn-sm"
          >
            Reset
          </button>
          <button
            type="button"
            className="btn input-inside-text mx-1 btn-secondary px-3"
            onClick={() => searchData()}
          >
            Search
          </button>
        </div>
      </div>
      <br />
      <br />

      <div className="container-fluid">
        <div className="card px-4 py-3 bg-body bg-white rounded shadow-sm">
          <span>OUTLET LISTING</span>
          <div className="border card p-3 my-3">
            <div className="d-flex justify-content-between">
              <span>{`All Outlets (${totalOutlets || 0})`}</span>
              <div className="d-flex my-3">
                {/* <input
                    type="search"
                    placeholder="search"
                    className="form-control mx-3"
                    value={searchData?.search || ""}
                    onChange={(e) =>
                      onChangeSeachData({
                        ...searchData,
                        search: e.target.value,
                      })
                    }
                  /> */}
                <button
                  onClick={() => exportExcelFun()}
                  type="button"
                  className="btn btn-success btn-sm text-nowrap mx-3"
                >
                  {" "}
                  Export Excel
                </button>

                {ablity({ model: 'Product', action: 'create' }) == 1 && (
                  <button
                    onClick={() => addOutletFun()}
                    type="button"
                    className="btn btn-dark btn-sm text-nowrap mx-3"
                  >
                    {" "}
                    + Add Outlet
                  </button>
                )}
              </div>
            </div>
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell className="th_cell" component="th">
                      No
                    </TableCell>

                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Outlet Code</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("outletCode", "asc")}

                          // onClick={() => getApiData({ item: "category", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("outletCode", "desc")}

                          // onClick={() => getApiData({ item: "category", order: "DESC" })}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell className="th_cell" component="th" align="left">
                      <div className="d-flex  ">
                        <div>Outlet Name</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            // onClick={() => setSearchData({ ...searchData, item: "brandName", order: "ASC" })}
                            onClick={() => handleSorting("outletName", "asc")}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            // onClick={() => setSearchData({ ...searchData, item: "brandName", order: "DESC" })}
                            onClick={() => handleSorting("outletName", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Address</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("address", "asc")}

                          // onClick={() => getApiData({ item: "product", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("address", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div> Postal Code</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() =>
                              handleSorting("postalCode", "asc")
                            }

                          // onClick={() => getApiData({ item: " releventMerchant", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() =>
                              handleSorting("postalCode", "desc")
                            }

                          // onClick={() => getApiData({ item: " releventMerchant", order: "DESC" })}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell className="th_cell" component="th" align="left">
                      <div className="d-flex  ">
                        <div> State</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("state", "asc")}

                          // onClick={() => getApiData({ item: "  status", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("state", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Operational Hours</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() => handleSortingHours("startTime", "asc")}

                          // onClick={() => getApiData({ item: "product", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() => handleSortingHours("startTime", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Contact Number</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("contactNumber", "asc")}

                          // onClick={() => getApiData({ item: "product", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("contactNumber", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Status</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("status", "asc")}

                          // onClick={() => getApiData({ item: "product", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("status", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Created At</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("createdAt", "asc")}

                          // onClick={() => getApiData({ item: "product", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("createdAt", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Created By</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("createdBy", "asc")}

                          // onClick={() => getApiData({ item: "product", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("createdBy", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Updated At</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("updatedAt", "asc")}

                          // onClick={() => getApiData({ item: "product", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("updatedAt", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      <div className="d-flex  ">
                        <div>Updated By</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("updatedBy", "asc")}

                          // onClick={() => getApiData({ item: "product", order: "ASC" })}
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("updatedBy", "desc")}
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell className="table_action_head" component="th">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!outletData?.length && (
                    <TableRow>
                      <TableCell align="center" className="td_cell">
                        <div className="d-flex justify-content-center">
                          <h3 className="text-muted m-auto">No data found!</h3>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}

                  {outletData.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center" className="td_cell">
                        {offset * pageLimit + (index + 1)}
                      </TableCell>
                      <TableCell align="left" className="td_cell capitalize">
                        {row.outletCode || "N/A"}
                      </TableCell>
                      <TableCell align="left" className="td_cell capitalize">
                        {row.outletName || "N/A"}
                      </TableCell>
                      <TableCell align="left" className="td_cell">
                        {row.address || "-"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.postalCode || "-"}
                      </TableCell>
                      <TableCell align="left" className="td_cell">
                        {row.state || "N/A"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.operationalHours || "0"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.contactNumber || "-"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.status || "-"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.createdAt || "-"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.createdBy || "-"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.updatedAt || "-"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.updatedBy || "-"}
                      </TableCell>

                      <TableCell className="d-flex justify-content-between table_action_item">
                        {ablity({ model: 'Product', action: 'update' }) == 1 && (
                          <IconButton
                            className="pointer"
                            onClick={() => EditOutletfun(row.id)}
                          >
                            <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />
                          </IconButton>
                        )}

                        <IconButton
                          className="pointer"
                          onClick={() => ViewOutletfun(row.id)}
                        >
                          <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                        </IconButton>

                        {ablity({ model: 'Product', action: 'delete' }) == 1 && (
                          <IconButton
                            className="pointer"
                            onClick={() => DeleteOutletById(row.id)}
                          >
                            <SvgIcon
                              src="/icons/delete_icon.svg"
                              alt="delete icon"
                            />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            </TableContainer>

            <br />
            <hr />
            <div className="d-flex justify-content-between align-items-center">
              <PagenationDropdown setPageQuant={setPageLimit} />

              <div className="float-right mb-1">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil((totalOutlets || 0) / pageLimit)}
                    shape="rounded"
                    size="small"
                    page={pageNo}
                    onChange={handlePageChange}
                  />
                </Stack>
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MerchantOutletListing;
