import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import Button from "../../components/buttons";
import CustomCheckbox from "../../components/checkbox";
import Input from "../../components/formControl/input";
import {
  CATEGORY_SELECTION_DIALOG,
  PASSWORD_FORGET_INFO_MODAL,
} from "../../lib/constant";
import { open_dialog, setSessionData, showToast } from "../../lib/global";
import {
  getLocalStorage,
  removeLocalStorageKey,
  setLocalStorage,
} from "../../lib/session";
import { forgetPasswordUpdate } from "../../redux/actions/auth.action";

const ForgetPasswordRest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id,token } = useParams();
  const [confPassword, setConfPassword] = useState("");
  const [password, setPassword] = useState("");
  const [storeId, setStoreId] = useState("");
  // const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFormInput = (event, key) => {
    const value = event.target.value;
    switch (key) {
      case "email":
        setEmail(value);
        break;
      // case "storeId":
      //   setStoreId(value);
      //   break;
      case "password":
        setPassword(value);
        break;
      case "confpassword":
        setConfPassword(value);
        break;
      default:
        return;
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    let isValid = true;
    // setLoading(true)
    if (!password || !confPassword) {
      isValid = false;
      showToast("All fields are requrired!", "error");
    }

    if (password !== confPassword) {
      isValid = false;
      showToast("Password and Retype Password Not Match", "error");
    }

    if(isValid){
      const payload = { password, userId: id,token };
      dispatch(forgetPasswordUpdate(payload))
      .then((response) => {
        showToast("Reset Password in successfully!", "success");
        history.push("/auth");
      })
      .catch((error) => {
        console.log(error);
        showToast(
          error?.response?.data?.message || "Failed to Reset Password!",
          "error"
        );
      });
    }
  };

  // const handleSubmitPasswordForgetForm=(e)=>{
  //   e.preventDefault()
  //   // dispatch(forgetPassword(email))
  //   open_dialog(PASSWORD_FORGET_INFO_MODAL,{
  //     onSubmit:()=>setForgetPassword(false),
  //     email
  //   })
  // }

  const handleTest = () => {
    open_dialog(CATEGORY_SELECTION_DIALOG, {
      minWidth: "80vw",
      maxWidth: "80vw",
    });
  };

  return (
    <React.Fragment>
      <div
        className="vh-100"
        style={{
          backgroundImage: "url(/images/loginPageBackgroundNew.png)",
          backgroundSize: "cover",
          overflow: "scroll",
        }}
      >
        <div className="m-auto px-5 pt-5 h-100 d-flex flex-column justify-content-between">
          <div className="row d-none d-sm-block">
            {/* <div className="col-md-2">
              <h3 className="font-weight-bold mb-1">
                <a href="http://uu2.appsdelite.com/#home">HOME</a>
              </h3>
              <div>
                <a href="http://uu2.appsdelite.com/#about">ABOUT US</a>
              </div>
              <div>
                <a href="http://uu2.appsdelite.com/#story">OUR STORY</a>
              </div>
              <div>
                <a href="http://uu2.appsdelite.com/#contact">CONTACT US</a>
              </div>
            </div> */}
            <div className="col-2">
              <h3 className="font-weight-bold mb-1">
                <a href="https://uu2.co/">HOME</a>
              </h3>
              <div>
                <a href="https://uu2.co/">ABOUT US</a>
              </div>
              <div>
                <a href="https://uu2.co/story/">OUR STORY</a>
              </div>
              <div>
                <a href="https://uu2.co/about-us/">CONTACT US</a>
              </div>
            </div>
            <div className="col-md-7 d-flex">
              <h1 className="display-1 font-weight-bolder pt-5 pl-4 d-flex justify-content-center">Welcome</h1>
            </div>
          </div>

          <div className="d-flex justify-content-md-end justify-content-center px-5">
            <div
              className="p-4 text-white"
              style={{
                backgroundImage: "url(/login-box-bg.png)",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                boxShadow: "0px 1px 1px 3px #52130c",
                borderRadius: "25px",
                top: "7rem",
                right: "10rem",
                maxWidth: "20rem",
              }}
            >
              <div className="mt-5">
                <form onSubmit={handleSubmitForm}>
                  <p className="text-nowrap">Please key in your new password</p>
                  <div>
                    <Input
                      className="input__textField mt-2 w-100"
                      type="password"
                      name="password"
                      id="password"
                      onChange={(e) => handleFormInput(e, "password")}
                      value={password}
                      placeholder="New Password"
                    />
                  </div>
                  <div>
                    <Input
                      className="input__textField mt-2 w-100"
                      type="password"
                      name="confpassword"
                      id="confpassword"
                      onChange={(e) => handleFormInput(e, "confpassword")}
                      value={confPassword}
                      placeholder="Retype New Password"
                    />
                  </div>
                  <Button
                    type="submit"
                    role="button"
                    className="login_btn mt-4"
                  >
                    {loading ? "loading..." : "Reset Password"}
                  </Button>
                </form>
              </div>
            </div>
          </div>

          <div className="row my-5">
            <div className="col-12 mb-3 d-flex flex-row align-items-center justify-content-end">
              <div className="mx-2">FOLLOW US ON</div>
              <a href="https://www.instagram.com/uu2_official/" target="_blank">
                <div className="mx-2" style={{ width: "35px" }}>
                  <img className="w-100" src="/images/instagram-icon.png" />
                </div>
              </a>
              <a href="https://www.facebook.com/uu2official" target="_blank">
                <div className="mx-2" style={{ width: "35px" }}>
                  <img className="w-100" src="/images/facebook-icon.png" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgetPasswordRest;
