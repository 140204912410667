import {
  DeleteWithToken,
  GetWithToken,
  PostWithToken,
  PutWithToken,
  DeleteById,
  Get,
  GetWithTokenParams,
  GetWithTokenFile,
} from "../../lib/request";


export const getAllNotification = (query) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/api/merchant-notification?type=${query.type}`);
    return result;
  };
};
