import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { breadcrumbAction } from "../../../redux/actions/config.action";

import { IOSSwitch } from "../../../components/switch/IOSSwitch";
import { getOtpById, updateOtpById } from "../../../redux/actions/otp.action";
import ablity from "../../../helpers/abilityGuard";
import { AllowAccess } from "../../../lib/global";

const OtpSetting = () => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const dispatch = useDispatch();
  const [otpStatus, setOtpStatus] = useState();

  useEffect(() => {
    const breadcrumb = [{ label: "OTP Setting", url: null }];
    dispatch(breadcrumbAction(breadcrumb));

    getOtpStatus();
  }, []);

  const getOtpStatus = () => {
    dispatch(getOtpById())
      .then(({ data }) => {
        setOtpStatus(data?.data?.isActive);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleActive = (e) => {
    const { checked } = e.target;
    const payload = { isActive: checked };
    dispatch(updateOtpById(payload))
      .then(({ data }) => {
        if (data.status == 201) {
          getOtpStatus();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      <div className="mt-1 mx-3 p-4 rounded bg-white">
        <div className="min-width-200 d-flex justify-content-between">
          <label className="d-flex">
            OTP <p className="text-muted ml-1 fst-italic">(Enable/Disable)</p>
          </label>
          {ablity({model:'Setting', action:'create'}) == 1 && (
            <IOSSwitch
              name="isActive"
              checked={otpStatus == true ? true : false}
              onClick={handleActive}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OtpSetting;
