import React from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import Checkbox from "@mui/material/Checkbox";

const ListItems = (props) => {
  const { item, index, categoriesList, setCategoriesList, statusDate } = props;
  const handleCategories = (e) => {
    let { value, checked } = e.target;
    let temp = [...categoriesList];
    if (checked) {
      temp = temp.filter((cate) => cate != value);
      temp.push(value);
    } else {
      temp = temp.filter((cate) => cate != value);
    }
    setCategoriesList([...temp]);
  };

  return (
    <div className="list_card pointer m-2" key={index}>
      <div className="card_item">
        <div className="count">
          <span className="bold text-dark font-weight-bold badge">
            {item.title}
            <Checkbox
              value={item.title}
              sx={{ "& .MuiSvgIcon-root": { fontSize: 16 } }}
              // sx={{ "& .MuiSvgIcon-root": { fontSize: 22 } }}
              onChange={(e) => handleCategories(e)}
            />
          </span>
        </div>
        <div className="count">
          <span className="h4 bold text-dark font-weight-bold ml-1">
            {item.amount}
          </span>
        </div>
        <div className="d-flex flex-row justify-content-between badge">
          <p className="text-muted">vs Previous {statusDate}</p>
          {item?.percentage < 0 ? (
            <p className="text-danger font-weight-bold">
              <AiFillCaretDown />
              {`${Math.abs(item.percentage).toFixed(1)}%`}
            </p>
          ) : (
            <p className="text-success font-weight-bold">
              <AiFillCaretUp />
              {`${Math.abs(item.percentage).toFixed(1)}%`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListItems;
