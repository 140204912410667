import { IconButton, MenuItem, Select } from "@mui/material";
import Divider from "@mui/material/Divider";

import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../../components/Images/Image";
import InputField from "../../../components/formControl/input/InputField";
import RadioButtonsGroup from "../../../components/radio-button/RadioButtonsGroup";
import SelectInput from "../../../components/select";
import {
  brandList,
  searchBrandsList,
  searchCategoryList,
} from "../../../redux/actions/merchant-center.action";
import "../../../static/css/productCss/allProduct.css";
import { countriesList } from "../../../lib/countriesList";
import {
  AllowAccess,
  open_dialog,
  parseParam,
  parseQuery,
  showToast,
  startLoader,
  stopLoader,
  UploadImage,
  UploadVideo,
} from "../../../lib/global";
import {
  addNewProductByStore,
  createNewProduct,
  updateProduct,
} from "../../../redux/actions/product.action";
import { getProductById } from "../../../redux/actions/merchant/product.action";
import SvgIcon from "../../../components/Images/SvgIcon";
import {
  CANCEL_EDIT_MODAL,
  CATEGORY_SELECTION_DIALOG,
} from "../../../lib/constant";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { MAX_IMAGE_SIZE } from "../../../lib/config";
import CheckboxTree from "react-checkbox-tree";
import Tree from "../../../components/categoriesTree/Tree";
import ImageModal from "../../../components/carousel/modal";
import { getLocalStorage } from "../../../lib/session";
import { IOSSwitch } from "../../../components/switch/IOSSwitch";
import { getByStoreId } from "../../../services/outlet.service";
import Select2 from "react-select";

const productTypes = [
  { label: "Physical", value: "physical" },
  { label: "Non-Physical", value: "non-physical" },
];

const expiredType = [
  { label: "Date Range", value: "date-range" },
  { label: "Duration", value: "duration" },
];

const initialCommRate = {
  commRate: "",
  prelaunchPromo: "",
  commStartDate: "",
  commEndDate: "",
};

const statusConstant = {
  New: "NEW",
  "Submit Approval": "NEW",
  Publish: "PUBLISHED",
  Draft: "DRAFT",
  Reject: "REJECTED",
  Deactivate: "DEACTIVATED",
  Delete: "DELETED",
};

const MerchantEditProduct = () => {
  AllowAccess(["SUPERADMIN", "MERCHANT", "SUPPORT", "OPERATOR", "MARKETING & E-COMMERCE", "FINANCIAL", "SALES OPERATION", "VIEWER", "DEVELOPER"]);
  const param = useParams();
  const merchantId = param.merchantId
    ? param.merchantId
    : localStorage.getItem("storeId");
  const merchantName = param.merchantName
    ? param.merchantName
    : localStorage.getItem("userName");

  const history = useHistory();
  const dispatch = useDispatch();
  const [editProduct, setEditProduct] = useState(false);
  const [productId, setProductId] = useState();
  const [errors, setErrors] = useState({});
  const [steps, setSteps] = useState(1);
  const [categoryList, setCategoryList] = useState([]);
  const [brandsList, setBrandsList] = useState([]);
  const [outletList, setOutletList] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [videoFile, setVideoFile] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [variationInfo, setVariationInfo] = useState({});
  const [editStatusText, setEditStatusText] = useState("");
  const [hide, setHide] = useState(true);
  const [hideVideo, setHideVideo] = useState(true);

  const [showCategory, setShowCategory] = useState([]);
  const [idCategory, setIdCategory] = useState([]);
  const [openCategory, setOpenCategory] = useState(false);
  const [openSubCategory, setSubOpenCategory] = useState(false);
  const [oneCategory, setOneCategory] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [dataCategory, setDataCategory] = useState([]);
  const [variationDataOld, setVariationDataOld] = useState([]);

  const role = getLocalStorage("role");
  const storeId = role == "MERCHANT" ? getLocalStorage("storeId") : null;

  var productStatus = useRef("");
  var statusOptions = !param.merchantId ? ["Submit Approval"] : ["Publish"];

  const [basicInformation, setBasicInformation] = useState({
    title: "",
    category: null,
    categoryLink: [],
    images: [],
    video: "",
    description: "",
    type: "",
    preOrder: false,
    outlets: []
  });
  const [salesInformation, setSalesInformation] = useState({
    variation: false,
    price: null,
    stock: null,
  });
  const [variations, setVariations] = useState([]);
  const [teamPurchaseInfo, setTeamPurchaseInfo] = useState({
    price: null,
    member: 0,
    unitCapped: 0,
    stock: null,
  });
  const [specification, setSpecification] = useState({
    brand: "",
    shipFrom: "", // country
    warrantyPeriod: "", // in months
    expiryDate: "",
    expiredType: "", // days / date
    expiredEndDate: "", // in date
    expiredStartDate: "", // in date
    expiredDuration: "", // in days
    warrantyPeriod: "", // in month
  });
  const [shippingInfo, setShippingInfo] = useState({
    weight: null,
    parcelWidth: null,
    parcelHeight: null,
    parcelLength: null,
    shippingFee: null,
    coverShippingFee: false,
  });

  const [variationData, setVariationData] = useState([]);

  useEffect(() => {
    const paramList = parseParam(history.location.pathname);
    var breadcrumb = [];
    if (param.merchantId) {
      breadcrumb = [
        { label: "Merchant Profile", url: "/merchant-center/profiles" },
        {
          label: param.merchantName,
          url: `/merchant-center/profiles/${param.merchantId}/${param.merchantName}`,
        },
        { label: param.id ? "Edit Product" : "Add Product", url: null },
      ];
    } else {
      breadcrumb = [
        {
          label: "Products",
          url: param.mid
            ? `/merchant-center/profiles/${param.mid}/${param.merchantName}`
            : "/merchant/products",
        },
        {
          label:
            paramList && paramList[1] === "edit-product"
              ? "Edit Product"
              : "Add Product",
          url: null,
        },
      ];
    }

    dispatch(breadcrumbAction(breadcrumb));
    dispatch(searchCategoryList()).then(({ data }) => {
      setDataCategory(data.data);
    });

    if (paramList && paramList[1] === "edit-product") {
      setEditProduct(true);
      const { id } = { id: paramList[2] } || {};
      setProductId(id);
      getProductDetail(id);
    }
    getCategiesList();
    getBrandsList();
  }, []);

  useEffect(() => {
    if (basicInformation.merchant && basicInformation.type == "non-physical") {
      loadDataOutlet()
    }
  }, [basicInformation.merchant, basicInformation.type])

  const getProductDetail = (productId) => {
    dispatch(getProductById(productId))
      .then((response) => {
        if (response?.data?.data) {
          if (role == "MERCHANT" && response?.data?.data?.storeId != storeId) {
            history.push("/404");
          }
          const result = response.data.data;
          setBasicInformation({
            title: result.title,
            merchant: {
              label: result.merchantName,
              value: result.storeId,
            },
            category: {
              id: result.categoryId,
              title: result.categoryName,
            },

            images: result.productImages || [],
            video: result.productVideo,
            description: result.description,
            type: result.productType,
            preOrder: !!result.preOrder,
            productStatus: result.statusText,
            outlets: result.outlets,
          });
          setEditStatusText(result.statusText);

          productStatus.current = result.statusText;

          setImageFiles(result.productImages || []);
          setImageUrls(result.productImages || []);
          setImageFiles(result.productImages);
          setVideoUrl(result.productVideo);
          setVariations(result.variation);
          setVariationData(result.variationList);
          setVariationDataOld(result.variationList);
          setSalesInformation({
            variation: result.variation?.length,
            price: result.price,
            stock: result.stock,
          });
          setSpecification({
            brand: {
              label: result.brand,
              value: result.brandId == "undefined" ? "" : result.brandId,
            },
            shipFrom: {
              label: result.shipFrom,
              value: result.shipFrom,
            }, // country
            warrantyPeriod: result.warranty,
            expiryDate: result.expiryDate,
            expiredType: result.expiredType,
            expiredStartDate: result.expiredStartDate,
            expiredEndDate: result.expiredEndDate,
            expiredDuration: result.expiredDuration,
          });
          setTeamPurchaseInfo({
            price: result.teamPurchasePrice,
            member: result.teamPurchaseMember,
            unitCapped: result.teamUnitCapped,
            stock: result.teamPurchaseStock,
          });
          setShippingInfo({
            weight: result.shippingInfo?.weight,
            parcelWidth: result.shippingInfo?.parcelSize?.width,
            parcelHeight: result.shippingInfo?.parcelSize?.height,
            parcelLength: result.shippingInfo?.parcelSize?.length,
            shippingFee: result.shippingInfo?.shippingFee,
            coverShippingFee: !!result.shippingInfo?.coverShippingFee,
          });
        }

        response?.data?.data?.categorylinks.map((category) => {
          idCategory.push(category.productCategoryId);
          showCategory.push(category.category.title);
        });
      })
      .catch((error) => {
        // history.push("/404");
      });
  };

  const getCategiesList = (search) => {
    dispatch(searchCategoryList(search))
      .then((response) => {
        if (response?.data?.data) {
          const data = response.data.data.map((item) => ({
            label: item.title,
            value: item.id,
          }));
          setCategoryList(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  const getBrandsList = (search) => {
    dispatch(brandList(search))
      .then((response) => {
        if (response?.data?.data) {
          const data = response.data.data.map((item) => ({
            label: item.brandName,
            value: item.brandId,
          }));
          setBrandsList(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const onChangeBasicInfo = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setBasicInformation((pre) => ({ ...pre, [key]: value }));
  };
  const onChangeSalesInfo = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setSalesInformation((pre) => ({ ...pre, [key]: value }));
  };
  const onChangeTeamPurchaseInfo = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setTeamPurchaseInfo((pre) => ({ ...pre, [key]: value }));
  };
  const onChangeSpecification = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setSpecification((pre) => ({ ...pre, [key]: value }));
  };
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };

  const handleKeyPressMoreZero = (e) => {
    const key = e.key;
    const regex = /[1-9]|\./;
    if (!regex.test(key)) {
      showToast("Expiry Duration Must be more than 0", "error", 3000, "bottom", "center");
      e.preventDefault();
    }
  };

  const onChangeShippingInfo = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setShippingInfo((pre) => ({ ...pre, [key]: value }));
  };

  const handleUploadFile = async (e, index) => {
    const fileData = e.target.files[0];
    if (fileData.size > MAX_IMAGE_SIZE) {
      showToast(
        `Image with size more than ${MAX_IMAGE_SIZE / 1000000
        }MB is not supported.\nPlease upload image of size smaller than ${MAX_IMAGE_SIZE / 1000000
        }MB.\nCurrent file size: ${(fileData.size / 1000000).toFixed(2)}MB`,
        "error",
        3000,
        "bottom",
        "center"
      );
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      const fileIns = [...imageUrls];
      const imageUrlIns = [...imageUrls];
      if (fileIns[index]) {
        fileIns[index] = fileData;
        imageUrlIns[index] = reader.result;
      } else {
        fileIns.push(fileData);
        imageUrlIns.push(reader.result);
      }
      setImageFiles(fileIns);
      setImageUrls(imageUrlIns);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const handleUploadVideoFile = async (e) => {
    const fileData = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      const fileIns = fileData;
      const videoUrlIns = reader.result;
      if (videoUrl) {
        setVideoFile();
        setVideoUrl();
      }
      setVideoFile(fileIns);
      setVideoUrl(videoUrlIns);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const validateBasicInfo = () => {
    const errorsList = {
      title: "",
      category: "",
      images: "",
      description: "",
      type: "",
    };
    if (!basicInformation.title) {
      errorsList.title = "Product Name";
    }
    // if (!basicInformation.category) {
    //   errorsList.category = "Category";
    // }
    if (idCategory.length == 0) {
      errorsList.category = "Category";
    }
    if (!imageFiles?.length) {
      errorsList.images = "Images";
    }
    if (!basicInformation.description) {
      errorsList.description = "Product Description";
    }
    if (!basicInformation.type) {
      errorsList.type = "Product Type";
    }
    setErrors((pre) => ({ ...pre, ...errorsList }));
    return errorsList;
  };
  const validateSalesInfo = () => {
    const errorsList = {
      price: "",
      stock: "",
      teamPurchasePrice: "",
      teamPurchaseStock: "",
      unitCapped: "",
      teamPurchaseMember: "",
    };
    if (!salesInformation.price) {
      errorsList.price = "Product Price";
    }
    if (!salesInformation.stock) {
      errorsList.stock = "Product Stock";
    }
    if (!teamPurchaseInfo.price) {
      errorsList.teamPurchasePrice = "Team Price";
    }
    if (!teamPurchaseInfo.member) {
      errorsList.teamPurchaseMember = "Team Member";
    }
    if (!teamPurchaseInfo.stock) {
      errorsList.teamPurchaseStock = "Team Product Stock";
    }
    if (!teamPurchaseInfo.unitCapped) {
      errorsList.unitCapped = "Unit Capped";
    }
    setErrors((pre) => ({ ...pre, ...errorsList }));
    return errorsList;
  };
  const validateVariationInfo = () => {
    const errorsList = {
      price: "",
      teamPrice: "",
      teamMembers: "",
      variation: "",
      variationData: "",
      unitCapped: "",
      teamUnitCapped: "",
    };
    variationData?.map((item) => {
      if (!item.price || item.price <= 0) {
        errorsList.price = "Individual Price";
      }
      if (!item.teamPrice || item.teamPrice <= 0) {
        errorsList.teamPrice = "Team Price";
      }
      if (!item.teamMembers || item.teamMembers < 2) {
        errorsList.teamMembers = "Team Members";
      }
      if (item.unitCapped === "") {
        errorsList.unitCapped = "Unit Capped";
      }
      if (!item.stock || item.stock < 0) {
        errorsList.stock = "Individual Stock";
      }
      if (!item.teamStock || item.stock < 0) {
        errorsList.teamStock = "Team Stock";
      }
      if (item.teamUnitCapped === "") {
        errorsList.teamUnitCapped = "Team Unit Capped";
      }
    });
    // if (variations.length) {
    if (!variations[0]?.name || !variations[0].options?.length) {
      errorsList.variation = "Variation";
    }
    if (!variationData.length) {
      errorsList.stock = "Variation List";
    }
    // }
    setErrors((pre) => ({ ...pre, ...errorsList }));
    return errorsList;
  };
  const validateShippingInfo = () => {
    const errorsList = {
      shipFrom: "",
      warrantyPeriod: "",
      weight: "",
      parcelSize: "",
      shippingFee: "",
      brand: "",
    };
    if (!specification.shipFrom) {
      errorsList.shipFrom.value = "Ship from";
    }
    if (!specification.brand) {
      errorsList.brand.value = "Brand Name";
    }
    if (basicInformation.type == "non-physical") {
      if (!specification.expiredType) {
        errorsList.expiredType = "Expired Type";
      }

      if (specification.expiredType == 'date-range') {
        if (!specification.expiredStartDate) {
          errorsList.expiredStartDate = "Expired Start Date";
        }
        if (!specification.expiredEndDate) {
          errorsList.expiredEndDate = "Expired End Date";
        }
      }
      if (specification.expiredType == 'duration' && !specification.expiredDuration) {
        errorsList.expiredDuration = "Expired Duration";
      }
    }
    if (basicInformation.type == "physical") {
      if (
        !specification.warrantyPeriod ||
        specification.warrantyPeriod == "null"
      ) {
        errorsList.warrantyPeriod = "Warranty";
      }
      if (!shippingInfo.weight) {
        errorsList.weight = "Product Weight";
      }
      if (
        !shippingInfo.parcelHeight ||
        !shippingInfo.parcelWidth ||
        !shippingInfo.parcelLength
      ) {
        errorsList.parcelSize = "Parcel Size";
      }
    }

    setErrors((pre) => ({ ...pre, ...errorsList }));
    return errorsList;
  };

  const handleAdditionalValidation = () => {
    let isValid = true;
    let message = "";

    variationData?.map(async (item) => {
      if (item.teamMembers < 2) {
        isValid = false;
        message = "Team Member must be more than 1";
        return;
      }

      if (item.teamPrice == 0) {
        isValid = false;
        message = "Team Price must be more than 0";
        return;
      }

      if (item.price == 0) {
        isValid = false;
        message = "Individual Price must be more than 0";
        return;
      }
    });
    if (
      basicInformation.type == "physical" &&
      Number(shippingInfo.weight) <= 0
    ) {
      isValid = false;
      message = "Product weight must be filled in more than 0 kg";
      return { isValid, message };
    }

    return { isValid, message };
  };

  const handleClickNext = async (status) => {
    startLoader();
    setErrors({});
    try {
      // startLoader();
      const error = {
        ...validateBasicInfo(),
        ...validateVariationInfo(),
        ...validateShippingInfo(),
      };
      console.log(error)
      if (Object.values(error).filter((item) => item).length) {
        stopLoader();
        return;
      }

      let { isValid, message } = handleAdditionalValidation();

      if (!isValid) {
        showToast(message, "error", 3000, "bottom", "center");
        stopLoader();
        return;
      } else {
        const payload = {
          title: basicInformation.title,
          storeId: param.merchantId ? param.merchantId : merchantId,
          merchantName: localStorage.getItem("userName"),
          // categoryId: String(basicInformation.category?.id),
          // categoryName: basicInformation.category?.title,
          categoryId: String(idCategory[0]),
          categoryName: String(showCategory[0]),
          categoryLink: idCategory,
          // productImages: basicInformation.images,
          productImages: [],
          productVideo: basicInformation.video,
          description: basicInformation.description,
          productType: basicInformation.type,
          outlets: basicInformation.outlets,
          variation: variations,
          variationList: variationData,
          // price: String(salesInformation.price),
          currencyName: "RM",
          // statusText: status || "PUBLISHED",
          statusText: "NEW", // productStatus.current,
          stock: Number(salesInformation.stock),
          teamPurchasePrice: Number(teamPurchaseInfo.price),
          teamPurchaseStock: Number(teamPurchaseInfo.stock),
          teamPurchaseMember: Number(teamPurchaseInfo.member),
          unitCapped: Number(teamPurchaseInfo.unitCapped),
          brand: specification.brand?.label || "",
          brandId: String(specification.brand?.value) || "",
          shipFrom: specification.shipFrom?.label || "",
          warranty: String(specification.warrantyPeriod) || "",
          expiryDate: String(specification.expiryDate) || "",
          expiredType: specification.expiredType,
          expiredStartDate: specification.expiredStartDate,
          expiredEndDate: specification.expiredEndDate,
          expiredDuration: specification.expiredDuration,

          shippingInfo: {
            // weight: Number(shippingInfo.weight) || "",
            weight:
              shippingInfo.weight == "0"
                ? "0"
                : Number(shippingInfo.weight) || "",
            parcelSize: {
              height: Number(shippingInfo.parcelHeight) || "",
              width: Number(shippingInfo.parcelWidth) || "",
              length: Number(shippingInfo.parcelLength) || "",
            },
            shippingFee: shippingInfo.shippingFee || "", // object
          },
          preOrder: basicInformation.preOrder,
        };
        // uploading product images and videos
        let images = [];
        for (let file of imageFiles) {
          if (!basicInformation.images.includes(file)) {
            let url = await UploadImage(file);
            // if (url?.error) {
            //   showToast(url?.error?.message || "file size is too big", 'error')
            // }
            // else {
            images.push(url);
            // }
          } else {
            images.push(file);
          }
        }

        payload.productImages = [...payload.productImages, ...images];

        if (videoFile) {
          let videoUrl = await UploadVideo(videoFile);
          payload.productVideo = videoUrl;
        } else {
          payload.productVideo = videoUrl;
        }

        if (editProduct) {
          dispatch(updateProduct(payload, productId))
            .then((response) => {
              stopLoader();
              showToast("Product updated successfully", "success");
              if (param.merchantId) {
                history.push(
                  `/merchant-center/profiles/${param.merchantId}/${param.merchantName}`
                );
              } else {
                history.push("/merchant/products");
              }
            })
            .catch((error) => {
              stopLoader();
              showToast(error?.response?.data?.message, "danger");
            });
        } else {
          dispatch(addNewProductByStore(payload))
            .then((response) => {
              stopLoader();
              showToast("Product Added successFully", "success");
              if (param.merchantId) {
                history.push(
                  `/merchant-center/profiles/${param.merchantId}/${param.merchantName}`
                );
              } else {
                history.push("/merchant/products");
              }
            })
            .catch((error) => {
              stopLoader();
              showToast(error?.response?.data?.message, "danger");
            });
        }
      }
    } catch (error) {
      console.log(error);
      stopLoader();
    }
  };

  const loadDataOutlet = async () => {
    if (basicInformation.merchant.value) {
      setOutletList([])
      dispatch(getByStoreId(basicInformation.merchant.value)).then((res) => {
        if (res.data.data.length > 0) {
          res.data.data.map((item) => {
            setOutletList((pre) => {
              return [...pre, {
                title: item.outletName,
                label: `${item.outletName} - (${item.storeId})`,
                value: item,
              }]
            })
          })
        } else {
          showToast("Please add Merchant Outlet to allow this feature.", "error", 3000, "bottom", "center");
        }
      })
    } else {
      showToast("Please select merchant first.", "error", 3000, "bottom", "center");
    }
  }

  const handleAddVariationOption = (varitionIndex) => {
    const variationIns = [...variations];
    variationIns[varitionIndex].options.push("");
    setVariations(variationIns);
    handleGenerateVariationTable();
  };

  const handleDeleteVariationOption = (varitionIndex, optionIndex) => {
    if (variations.length > 1) {
      let name = variations[varitionIndex].name;
      let option = variations[varitionIndex].options[optionIndex];
      let dataArrayMe = [];

      const variationIns = [...variations];
      variationIns[varitionIndex].options.splice(optionIndex, 1);
      setVariations(variationIns);
      variationDataOld.find((r, i) => {
        if (r[name] != option) {
          dataArrayMe.push(r);
        }
      });

      setVariationData(dataArrayMe);
      handleGenerateVariationTable();
    } else {
      if (optionIndex > 0) {
        const variationIns = [...variations];
        variationIns[varitionIndex].options.splice(optionIndex, 1);
        setVariations(variationIns);

        const dataArray = [];
        const firstVariationName = variations[0].name;
        const firstVariationOptions = variations[0].options;

        for (let firstVariation of firstVariationOptions) {
          for (let variationIndex in variations) {
            if (variations.length == 1) {
              if (firstVariation) {
                let data = {
                  price: null,
                  stock: null,
                  sku: "",
                  [firstVariationName]: firstVariation,
                  teamPrice: null,
                  teamMembers: null,
                  teamUnitCapped: null,
                  teamStock: null,
                  teamSku: "",
                };
                dataArray.push(data);
              }
            } else {
              if (variationIndex > 0) {
                let name = variations[variationIndex].name;
                for (let opt of variations[variationIndex].options) {
                  if (opt) {
                    let data = {
                      price: null,
                      stock: null,
                      sku: "",
                      [firstVariationName]: firstVariation,
                      [name]: opt,
                      teamPrice: null,
                      teamMembers: null,
                      teamUnitCapped: null,
                      teamStock: null,
                      teamSku: "",
                    };
                    dataArray.push(data);
                  }
                }
              }
            }
          }
        }

        variationDataOld.map((r, i) => {
          r.key = JSON.parse(JSON.stringify(r[`${firstVariationName}`]));
        });

        dataArray.map((r, i) => {
          r.key = JSON.parse(JSON.stringify(r[`${firstVariationName}`]));
        });

        let newVariationDataOld = [];
        if (dataArray.length < variationDataOld.length) {
          variationDataOld.map((r, i) => {
            dataArray.forEach((r2, i2) => {
              if (r.key == r2.key) {
                newVariationDataOld.push(r);
              }
            });
          });
          setVariationData(newVariationDataOld);
        }
      }
    }
  };

  const handleChangeVariationName = (e, variationNumber) => {
    const value = e.target.value;
    const variationIns = [...variations];
    const variationDataIns = [...variationData];
    const variationNameOld = variationIns[variationNumber].name;
    variationIns[variationNumber].name = value;
    setVariations(variationIns);
    let validate = validateUniqueVariation();
    if (validate.length > 0) {
      showToast(validate[0], "error");
      return;
    }
    variationDataIns.map((r, i) => {
      r[value] = r[variationNameOld];
      delete r[variationNameOld];
    });
    handleGenerateVariationTable();
  };
  const validateUniqueVariation = () => {
    let error = [];
    let variationName = [];
    variations.forEach((item) => {
      if (variationName.includes(item.name)) {
        error.push(
          "Variation " + item.name + " has exists,Variation name must be unique"
        );
      }
      variationName.push(item.name);
    });
    return error;
  };
  const handleChangeVariationOption = (e, variationIndex, optionIndex) => {
    const value = e.target.value;
    const variationIns = [...variations];
    variationIns[variationIndex].options[optionIndex] = value;
    setVariations(variationIns);
    handleGenerateVariationTable();
  };

  const handleSort = (item) => {
    return item.map((r, i) => {
      r.options.sort();
      return r;
    })
  }

  const handleSortVariation = () => {
    const variationIns = [...variations];
    // check is need to ordering
    let isValid = true
    variationIns.map((a, b) => {
      if (a.options) {
        a.options.map((c, d) => {
          if (c == "") {
            isValid = false
          }
        })
      }
    })
    if (isValid) {
      handleSort(variationIns);
      setVariations(variationIns);
      handleGenerateVariationTable();
    }
  }

  const handleAddMoreVariation = () => {
    setVariations((pre) => [...pre, { name: "", options: [""] }]);
  };

  const handleDeleteVariation = (variationIndex) => {
    if (variations.length <= 1) {
      setVariations([]);
      setVariationData([]);
      setSalesInformation((pre) => ({ ...pre, variation: false }));
    } else {
      const variationIns = [...variations];
      variationIns.splice(variationIndex, 1);
      setVariations(variationIns);
    }
    handleGenerateVariationTable();
  };

  const handleAsignVariationOption = (variationIndex, optIndex) => {
    if (variations.length > 1) {
      const dataArrayMe = [];
      const firstVariationNameMe = variations[0].name;
      const firstVariationOptionsMe = variations[0].options;
      for (let firstVariation of firstVariationOptionsMe) {
        for (let variationIndex in variations) {
          if (variationIndex > 0) {
            let name = variations[variationIndex].name;
            for (let opt of variations[variationIndex].options) {
              if (opt) {
                let data = {
                  price: null,
                  stock: null,
                  sku: "",
                  [firstVariationNameMe]: firstVariation,
                  [name]: opt,
                  teamPrice: null,
                  teamMembers: null,
                  teamUnitCapped: null,
                  teamStock: null,
                  teamSku: "",
                  unitCapped: null,
                  key1: firstVariationNameMe,
                  key2: name,
                  val1: firstVariation,
                  val2: opt,
                };
                dataArrayMe.push(data);
              }
            }
          }
        }
      }

      let variationDataFinalTemp = [];
      if (dataArrayMe.length == variationDataOld.length) {
        variationDataOld.map((r, i) => {
          let newDataMe = dataArrayMe[i];
          delete r["key"];
          delete r[newDataMe.key1];
          delete r[newDataMe.key2];
          r[newDataMe.key1] = newDataMe.val1;
          r[newDataMe.key2] = newDataMe.val2;
          variationDataFinalTemp.push(r);
        });
        setVariationData(variationDataFinalTemp);
      } else if (dataArrayMe.length > variationDataOld.length) {
        variationDataOld.map((r, i) => {
          variationDataFinalTemp.push(r);
        });
        dataArrayMe.map((r, i) => {
          if (i >= variationDataOld.length) {
            delete r["key"];
            delete r["key1"];
            delete r["key2"];
            delete r["val1"];
            delete r["val2"];
            variationDataFinalTemp.push(r);
          }
        });
        setVariationData(variationDataFinalTemp);
      }
    } else {
      const dataArray = [];
      const firstVariationName = variations[0].name;
      const firstVariationOptions = variations[0].options;

      for (let firstVariation of firstVariationOptions) {
        for (let variationIndex in variations) {
          if (variations.length == 1) {
            if (firstVariation) {
              let data = {
                price: null,
                stock: null,
                sku: "",
                [firstVariationName]: firstVariation,
                teamPrice: null,
                teamMembers: null,
                teamUnitCapped: null,
                teamStock: null,
                teamSku: "",
                unitCapped: null,
              };
              dataArray.push(data);
            }
          } else {
            if (variationIndex > 0) {
              let name = variations[variationIndex].name;
              for (let opt of variations[variationIndex].options) {
                if (opt) {
                  let data = {
                    price: null,
                    stock: null,
                    sku: "",
                    [firstVariationName]: firstVariation,
                    [name]: opt,
                    teamPrice: null,
                    teamMembers: null,
                    teamUnitCapped: null,
                    teamStock: null,
                    teamSku: "",
                    unitCapped: null,
                  };
                  dataArray.push(data);
                }
              }
            }
          }
        }
      }

      let newVariationDataOld = variationDataOld;
      if (dataArray.length > variationDataOld.length) {
        dataArray.map((r, i) => {
          if (i == dataArray.length - 1) {
            newVariationDataOld.push(r);
          }
        });
        setVariationData(newVariationDataOld);
      } else {
        let changeVariationData = [];

        newVariationDataOld.forEach((r, i) => {
          dataArray.forEach((r2, i2) => {
            if (r[`${firstVariationName}`] == r2[`${firstVariationName}`]) {
              changeVariationData.push(r);
            } else {
              if (
                i == i2 &&
                r[`${firstVariationName}`] !== r2[`${firstVariationName}`]
              ) {
                let data = {
                  price: r.price,
                  stock: r.stock,
                  sku: r.sku,
                  [firstVariationName]: r2[`${firstVariationName}`],
                  teamPrice: r.teamPrice,
                  teamMembers: r.teamMembers,
                  teamUnitCapped: r.teamUnitCapped,
                  teamStock: r.teamStock,
                  teamSku: r.teamSku,
                  unitCapped: r.unitCapped,
                };
                changeVariationData.push(data);
              }
            }
          });
        });

        setVariationData(changeVariationData);
      }
    }
  };

  function generateVariation(
    result,
    variation,
    currentVariation = {},
    currentIndex = 0
  ) {
    if (currentIndex === variation.length) {
      result.push(currentVariation);
      return;
    }

    let currentOption = variation[currentIndex];
    let optionName = currentOption.name;

    for (let i = 0; i < currentOption.options.length; i++) {
      let optionValue = currentOption.options[i];
      let updatedVariation = { ...currentVariation, [optionName]: optionValue };
      generateVariation(result, variation, updatedVariation, currentIndex + 1);
    }
  }

  function mergeVariation(variationData, variationList) {
    console.log(variationList, variationData, "data");
    let defaultValues = {
      price: 0,
      stock: 0,
      teamPrice: 0,
      teamStock: 0,
      unitCapped: 0,
      teamMembers: 0,
      teamUnitCapped: 0,
    };

    let mergedData = [];
    let oldData = [...variationDataOld];

    for (let i = 0; i < variationList.length; i++) {
      let found = false;

      for (let j = 0; j < oldData.length; j++) {
        let isMatch = true;

        for (let key in variationList[i]) {
          if (variationList[i][key] !== oldData[j][key]) {
            isMatch = false;
            break;
          }
        }

        if (isMatch) {
          oldData[j].found = true
          let data = { ...variationList[i], ...oldData[j] };
          mergedData.push(data);
          found = true;
          break;
        }
      }

      if (!found) {
        let data = {
          ...variationList[i]
        }
        oldData.map((r, i) => {
          if (!r.found) {
            data = {
              ...data,
              price: r.price,
              stock: r.stock,
              teamMembers: r.teamMembers,
              teamPrice: r.teamPrice,
              teamStock: r.teamStock,
              teamUnitCapped: r.teamUnitCapped,
              unitCapped: r.unitCapped,
            }
            return true
          }
        })
        mergedData.push(data);
      }
    }
    setVariationDataOld(mergedData)
    return mergedData;
  }

  const handleGenerateVariationTable = () => {
    let listVariationData = [];
    generateVariation(listVariationData, variations);
    let mergedData = mergeVariation(variationData, listVariationData);
    setVariationData(mergedData);
  };

  const handleVariationDataChange = (e, key, index) => {
    let value = e.target.value;
    const dataIns = [...variationData];
    if (inArray(key, ['price', 'teamPrice'])) {
      value = handleOnly2DecimalPlace(value);
    }

    if (inArray(key, ['unitCapped', 'teamMembers', 'stock', 'teamUnitCapped', 'teamStock']) && value != '') {
      value = handleNotAllowDecimalPlace(value);
    }

    if (value || value == '') {
      dataIns[index][key] = value;
      setVariationData(dataIns);
    }
  };

  const inArray = (value, array) => {
    return array.includes(value);
  }

  function isFloatNumber(stringNumber) {
    const parsedNumber = parseFloat(stringNumber);
    return !isNaN(parsedNumber)
  }

  function isIntegerNumber(stringNumber) {
    const parsedNumber = parseInt(stringNumber);
    return !isNaN(parsedNumber) && Number.isInteger(parsedNumber);
  }

  function getDecimalValue(number) {
    const parts = number.split('.');
    return parts[1] ? parts[1].length : 0;
  }

  const handleOnly2DecimalPlace = (value) => {
    if (isFloatNumber(value) && value != '') {
      if (getDecimalValue(value) <= 2) {
        return value
      }
    } else {
      return value
    }
  }

  const handleNotAllowDecimalPlace = (value) => {
    if (isIntegerNumber(value)) {
      return parseInt(value)
    }
  }

  const onChangeVariationInfo = (e) => {
    let key = e.target.name;
    let value = e.target.value;
    if (inArray(key, ['unitCapped', 'stock']) && value != '') {
      value = handleNotAllowDecimalPlace(value);
    }
    setVariationInfo((pre) => ({ ...pre, [key]: value }));
  };
  const handleApplyAll = () => {
    if (!variationInfo.unitCapped || !variationInfo.stock) {
      return;
    }
    const variationDataIns = [...variationData];
    const data = variationDataIns.map((item) => ({
      ...item,
      sku: variationInfo.sku,
      teamSku: variationInfo.sku,
      stock: variationInfo.stock,
      unitCapped: variationInfo.unitCapped,
      teamStock: variationInfo.stock,
      teamUnitCapped: variationInfo.unitCapped,
    }));
    setVariationData(data);
  };

  const handleSelectCategory = () => {
    if (openCategory) setOpenCategory(false);
    else setOpenCategory(true);
  };

  const CancelEditing = () => {
    open_dialog(CANCEL_EDIT_MODAL, {
      goBackUrl: param.merchantId
        ? `/merchant-center/profiles/${param.merchantId}/${param.merchantName}`
        : "/merchant/products",
    });
  };

  if (param.merchantId) {
    if (editStatusText == "NEW") {
      statusOptions = [...statusOptions, "Reject"];
    } else if (editStatusText == "PUBLISHED") {
      statusOptions = [...statusOptions, "Reject", "Deactivate"];
    }
  } else {
    if (editStatusText == "PUBLISHED") {
      statusOptions = [...statusOptions, "Deactivate"];
    } else if (editStatusText == "DEACTIVATED") {
      statusOptions = [...statusOptions, "Publish"];
    }
  }

  const changeProductStatus = (e) => (productStatus.current = e.target.value);
  const deleteImage = (item) => {
    $("#product-image-" + item).val("");
    setImageUrls(imageUrls.filter((i, index) => index !== item));
    setImageFiles(imageFiles.filter((i, index) => index !== item));
  };
  const deleteVideo = () => {
    $(`#product-video-${0}`).val("");
    setVideoFile("");
    setVideoUrl("");
  };
  const viewModalImage = (e) => setModalIsOpen(!modalIsOpen);

  return (
    <React.Fragment>
      <div>
        <div
          id="basic-info-container"
          // visible={String(steps == 1)}
          className="mr-3 ml-4 rounded mb-2 px-3 pt-4 bg-white"
        >
          <h6>BASIC INFORMATION</h6>
          <form className="mt-3 mb-2 p-0">
            <div className="col-12 row my-3">
              <label
                htmlFor="product-name"
                className="col-3 text-right m-0 p-0 small imp"
              >
                Product Name
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                id="product-name"
                placeholder="Product Name"
                name="title"
                value={basicInformation.title}
                onChange={onChangeBasicInfo}
                required={true}
              />
            </div>

            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Category
              </label>
              <div className="form-group col-9">
                <div
                  onClick={handleSelectCategory}
                  className="col border form-control pointer bg-muted h-auto"
                >
                  <div className="d-flex alight-item-center flex-wrap">
                    {(showCategory.length > 0 &&
                      showCategory.map((a, i) => (
                        <span
                          className=" d-flex mr-3 mb-3 pb-1 px-2 rounded bg-secondary text-white"
                          key={i}
                        >
                          {a}
                        </span>
                      ))) || <p className="text-muted">Select Category</p>}
                  </div>
                </div>

                {openCategory && (
                  <div
                    id="checkBoxes"
                    className="category-container col border form-control pointer bg-muted pb-2"
                  >
                    <Tree
                      data={dataCategory}
                      setShowCategory={setShowCategory}
                      setIdCategory={setIdCategory}
                      showCategory={showCategory}
                      idCategory={idCategory}
                    />
                    {/* <div
                      id="checkBoxes"
                      className="col d-flex flex-wrap bg-muted h-auto"
                    > */}
                    {/* {dataCategory.map((category, idx) => {
                          return (
                            <div className="col-md-4 d-flex flex-wrap">
                              <label key={idx}>
                                <input
                                  className="mx-1"
                                  type="checkbox"
                                  onClick={(e) => onSelectCategory(e, category)}
                                />
                                {category.title}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        {oneCategory?.SubCategory && openSubCategory && (
                          <>
                            <div
                              id="checkBoxes"
                              className="col bg-muted h-auto"
                            >
                              <p>Sub Categories of {oneCategory.title} :</p>
                              <div
                                id="checkBoxes"
                                className="col d-flex flex-wrap bg-muted h-auto"
                              >
                                {oneCategory.SubCategory.map((subCate, idx) => {
                                  return (
                                    <div className="col-md-4 d-flex  flex-wrap">
                                      <label key={idx} className="text-sm">
                                        <input
                                          className="mx-1"
                                          type="checkbox"
                                          onClick={(e) =>
                                            onSelectSubCategory(e, subCate)
                                          }
                                          onChange={(e) =>
                                            checkedOnSubCategory(e, subCate)
                                          }
                                        />
                                        {subCate.title}
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )} */}
                    {/* </div> */}
                  </div>
                )}
              </div>
            </div>
            {/* call modal for show carousel product images */}
            <ImageModal
              modalIsOpen={modalIsOpen}
              setModalIsOpen={setModalIsOpen}
              imageUrls={imageUrls}
            />

            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Product Images
              </label>
              <div className="icon-button-file col">
                <IconButton
                  className="m-0 mb-1 p-0 small pointer"
                  onClick={(e) => viewModalImage(e)}
                >
                  <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                </IconButton>
                <div className="icon-button-file col-12 d-flex flex-wrap">
                  {[0, 1, 2, 3, 4, 5, 6, 7].map((item) => (
                    <div className="ml-n3 mx-5 mb-2" key={item}>
                      <input
                        accept="image/*"
                        id={`product-image-${item}`}
                        className="d-none"
                        onChange={(e) => handleUploadFile(e, item)}
                        multiple={false}
                        type="file"
                      />
                      <div
                        className="position-relative"
                        onMouseEnter={() => setHide(false)}
                        onMouseLeave={() => setHide(true)}
                      >
                        <label
                          htmlFor={
                            imageUrls?.[item] ? "#" : `product-image-${item}`
                          }
                        >
                          <Image
                            src={imageUrls?.[item] || "/icons/upload_icon.svg"}
                            alt="upload"
                            className={`pointer upload_image_label`}
                          />
                        </label>

                        {!hide && imageUrls?.[item] && (
                          <div
                            className="b-0 bg-dark text-light opacity-25 position-absolute w-100 d-flex justify-content-between"
                            style={{ opacity: "0.7" }}
                          >
                            <IconButton className="pointer">
                              <label
                                htmlFor={`product-image-${item}`}
                                className="mb-0"
                              >
                                <SvgIcon
                                  src="/icons/edit_icon.svg"
                                  alt="view icon"
                                />
                              </label>
                            </IconButton>
                            <Divider
                              orientation="vertical"
                              variant="middle"
                              flexItem
                              className="bg-light text-light"
                            />

                            <IconButton
                              className="pointer"
                              onClick={() => deleteImage(item)}
                            >
                              <SvgIcon
                                src="/icons/delete_icon.svg"
                                alt="delete icon"
                              />
                            </IconButton>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small">
                Product Video
              </label>
              <div className="icon-button-file col-9">
                <div className="icon-button-file col-12 d-flex flex-wrap">
                  <div className="ml-n3 mx-5 mb-2">
                    <input
                      accept="video/*"
                      id={`product-video-${0}`}
                      className="d-none"
                      onChange={(e) => handleUploadVideoFile(e)}
                      multiple={false}
                      type="file"
                    />
                    <div
                      className="position-relative"
                      onMouseEnter={() => setHideVideo(false)}
                      onMouseLeave={() => setHideVideo(true)}
                    >
                      <label htmlFor={videoUrl ? "#" : `product-video-${0}`}>
                        {videoUrl ? (
                          <video width="80" height="80" controls>
                            <source src={videoUrl} type="video/mp4" />
                          </video>
                        ) : (
                          <Image
                            src={"/icons/upload_icon.svg"}
                            alt="upload"
                            className={`pointer upload_image_label`}
                          />
                        )}
                      </label>

                      {!hideVideo && videoUrl && (
                        <div
                          className="b-0 bg-dark text-light opacity-25 position-absolute w-100 d-flex justify-content-between"
                          style={{ opacity: "0.7" }}
                        >
                          <IconButton className="pointer">
                            <label
                              htmlFor={`product-video-${0}`}
                              className="mb-0"
                            >
                              <SvgIcon
                                className="pointer"
                                src="/icons/edit_icon.svg"
                                alt="view icon"
                                style={{ opacity: "0.9" }}
                              />
                            </label>
                          </IconButton>
                          <Divider
                            orientation="vertical"
                            variant="middle"
                            flexItem
                            className="bg-light text-light"
                          />

                          <IconButton
                            className="pointer"
                            onClick={() => deleteVideo()}
                          >
                            <SvgIcon
                              src="/icons/delete_icon.svg"
                              alt="delete icon"
                            />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 row">
              <label
                htmlFor="description"
                className="col-3 text-right small imp"
              >
                Description
              </label>
              <div className="col-9 d-flex">
                <textarea
                  type="text"
                  id="description"
                  name="description"
                  value={basicInformation.description}
                  onChange={onChangeBasicInfo}
                  className={"form-control"}
                  placeholder="Enter product description"
                  rows={3}
                ></textarea>
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 my-auto text-right small imp">
                Product Type
              </label>
              <RadioButtonsGroup
                options={productTypes}
                name="product-type"
                className="col-9 px-3"
                value={basicInformation.type}
                onChange={(e) => onChangeBasicInfo(e, "type", e.target.value)}
              />
            </div>
            {basicInformation.type == "non-physical" && (
              <>
                <div className="col-12 row my-3">
                  <label className="col-3 my-auto text-right small imp">
                    Redemption Outlets
                  </label>
                  <div className="col-9">
                    <Select2
                      options={outletList}
                      // disabled={voucherData.isEnableForAllMerchant}
                      name="outletName"
                      id="outlets"
                      value={basicInformation.outlets}
                      onChange={(e) => onChangeBasicInfo(e, "outlets", e)}
                      required={true}
                      isMulti={true}
                      placeholder="Select Outlets"
                    />
                  </div>
                </div>
              </>
            )}
            <br />
          </form>
        </div>
        <div
          id="sales-info-variation"
          // visible={String(salesInformation?.variation ? "true" : "false")}
          visible={String(salesInformation?.variation ? "true" : "true")}
          className="mr-3 ml-4 rounded  px-3 pt-4 pb-2 bg-white"
        >
          <h6 className="my-0">SALES INFORMATION</h6>
          <form className="mt-3  p-0">
            <div className="col-12 row my-3">
              <label
                htmlFor="product-variation"
                className="col-2 text-right m-0 p-0 small imp"
              >
                Variation
              </label>
              <div className="col-10">
                {variations?.map((item, variationIndex) => (
                  <form className="local-bg p-3 w-100 my-3">
                    <div className="col-12 row my-3">
                      <label
                        htmlFor="variation-name"
                        className="col-3 small imp text-nowrap"
                      >
                        Name
                      </label>
                      <InputField
                        className="pr-5"
                        formClass="col-9"
                        type="text"
                        id="variation-name"
                        placeholder="Enter variation name eg. colour, size, etc"
                        name="title"
                        value={item.name}
                        onChange={(e) =>
                          handleChangeVariationName(e, variationIndex)
                        }
                        required={true}
                        iconType="text"
                        iconPosition="right"
                        iconLabel={`${item.name?.length || 0}/20`}
                        maxLength={20}
                      />
                    </div>
                    <div className="col-12 row my-3">
                      <label
                        htmlFor="variation-name"
                        className="col-3 small imp text-nowrap"
                      >
                        Options
                      </label>
                      <div className="col-9 p-0">
                        {item.options.map((opt, optIndex) => (
                          <div className="col-12 p-0 my-2 d-flex">
                            <InputField
                              className="pr-5"
                              formClass="col-11"
                              type="text"
                              id="variation-options"
                              placeholder="Enter variation option eg. red, etc"
                              name="variation-options"
                              value={opt}
                              onChange={(e) =>
                                handleChangeVariationOption(
                                  e,
                                  variationIndex,
                                  optIndex
                                )
                              }
                              required={true}
                              iconType="text"
                              iconPosition="right"
                              iconLabel={`${opt?.length || 0}/20`}
                              maxLength={20}
                            />
                            {/* <IconButton
                              onClick={() =>
                                handleAsignVariationOption(
                                  variationIndex,
                                  optIndex
                                )
                              }
                              className="pointer"
                            >
                              <SvgIcon
                                src="/icons/lessThen.png"
                                alt="delete icon"
                              />
                            </IconButton> */}
                            <IconButton
                              onClick={() =>
                                handleDeleteVariationOption(
                                  variationIndex,
                                  optIndex
                                )
                              }
                              className="pointer"
                            >
                              <SvgIcon
                                src="/icons/delete_icon.svg"
                                alt="delete icon"
                              />
                            </IconButton>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="col-12 row justify-content-end">
                      <button
                        type="button"
                        className="btn btn-outline-dark mx-2"
                        onClick={() => handleSortVariation()}
                      >
                        Sort Options Ascending
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-dark mx-2"
                        onClick={() => handleDeleteVariation(variationIndex)}
                      >
                        delete
                      </button>
                      <button
                        type="button"
                        onClick={() => handleAddVariationOption(variationIndex)}
                        className="btn btn-dark mx-2"
                      >
                        Add Option
                      </button>
                    </div>
                  </form>
                ))}
                <div className="col-12 mt-3">
                  <button
                    type="button"
                    onClick={handleAddMoreVariation}
                    visible={String(variations?.length < 2)}
                    className="btn btn-outline-dark w-100"
                  >
                    + Add variation
                  </button>
                </div>
                {/* <div className="col-12 mt-2 mb-3">
                  <button
                    type="button"
                    onClick={handleGenerateVariationTable}
                    // visible={String(variations.length < 2)}
                    className="btn btn-dark w-100"
                  >
                    Regenerate Variation Form
                  </button>
                </div> */}
              </div>
            </div>
            <br />
            <br />
            <div className="col-12 row mt-3 mb-5">
              <label className="col-2 text-right m-0 p-0 small">
                Variation Information
              </label>
              <div className="col-10 pl-5 row">
                <div className="col-3 px-2">
                  <InputField
                    formClass="col-12 p-0"
                    type="number"
                    min="0"
                    onKeyPress={(e) => handleKeyPress(e)}
                    id="unitCapped"
                    placeholder="Unit Capped"
                    name="unitCapped"
                    value={variationInfo.unitCapped}
                    onChange={onChangeVariationInfo}
                    required={true}
                  />
                </div>
                <div className="col-3 px-2">
                  <InputField
                    formClass="col-12 p-0"
                    type="number"
                    min="0"
                    onKeyPress={(e) => handleKeyPress(e)}
                    id="stock"
                    placeholder="Stock"
                    name="stock"
                    value={variationInfo.stock}
                    onChange={onChangeVariationInfo}
                    required={true}
                  />
                </div>
                <div className="col-3 px-2">
                  <InputField
                    formClass="col-12 p-0"
                    type="text"
                    // min="0"
                    // onKeyPress={(e) => handleKeyPress(e)}
                    id="sku"
                    placeholder="SKU(Optional)"
                    name="sku"
                    value={variationInfo.sku}
                    onChange={onChangeVariationInfo}
                    required={false}
                  />
                </div>
                <div className="col-3 px-2">
                  <button
                    onClick={handleApplyAll}
                    type="button"
                    className="btn btn-dark btn-sm text-nowrap"
                  >
                    Apply To All
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 my-3 d-flex">
              <label htmlFor="" className="col-2">
                Individual Variation List
              </label>
              <table className="col-10 border p-3 mx-3">
                <tr>
                  {variations &&
                    variations.length > 0 &&
                    variations.map((item, index) => (
                      <th
                        key={index}
                        className="small border py-1 px-2 local-bg"
                      >
                        {item?.name || "-"}
                      </th>
                    ))}
                  <th className="small border py-1 px-2 local-bg">Price</th>
                  <th className="small border py-1 px-2 local-bg">
                    Unit Capped
                  </th>
                  <th className="small border py-1 px-2 local-bg">Stock</th>
                  <th className="small border py-1 px-2 local-bg">SKU</th>
                </tr>
                {variationData &&
                  variationData.length > 0 &&
                  variationData
                    ?.filter((item) => {
                      return (
                        item[variations.map((item) => item.name)[0]]?.trim()
                          .length !== 0
                      );
                    })
                    .map((row, rowIndex) => (
                      <tr className="" key={rowIndex}>
                        {variations.map((item, index) => (
                          <th
                            key={index}
                            className="border px-2 small text-muted"
                          >
                            {row[item.name]}
                          </th>
                        ))}
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text  border-0 input_icon_left"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="price"
                            placeholder="Input"
                            name="price"
                            value={row.price}
                            onChange={(e) =>
                              handleVariationDataChange(e, "price", rowIndex)
                            }
                            required={false}
                            icon="currency"
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="unitCapped"
                            placeholder="Input"
                            name="unitCapped"
                            value={row.unitCapped}
                            onChange={(e) =>
                              handleVariationDataChange(
                                e,
                                "unitCapped",
                                rowIndex
                              )
                            }
                            required={false}
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="stock"
                            placeholder="Input"
                            name="stock"
                            value={row.stock}
                            onChange={(e) =>
                              handleVariationDataChange(e, "stock", rowIndex)
                            }
                            required={false}
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="text"
                            // onKeyPress={(e) => handleKeyPress(e)}
                            // min="0"
                            id="sku"
                            placeholder="Input"
                            name="sku"
                            value={row.sku}
                            onChange={(e) =>
                              handleVariationDataChange(e, "sku", rowIndex)
                            }
                            required={false}
                          />
                        </td>
                      </tr>
                    ))}
              </table>
            </div>
            <div className="col-12 my-3 d-flex">
              <label htmlFor="" className="col-2">
                Team Variation List
              </label>
              <table className="col-10 border p-3 mx-3">
                <tr>
                  {variations &&
                    variations.length > 0 &&
                    variations.map((item, index) => (
                      <th
                        key={index}
                        className="small border py-1 px-2 local-bg"
                      >
                        {item?.name || "-"}
                      </th>
                    ))}
                  <th className="small border py-1 px-2 local-bg">TP Price</th>
                  <th className="small border py-1 px-2 local-bg">TP Member</th>
                  <th className="small border py-1 px-2 local-bg">
                    Unit Capped
                  </th>
                  <th className="small border py-1 px-2 local-bg">Stock</th>
                  <th className="small border py-1 px-2 local-bg">SKU</th>
                </tr>
                {variationData &&
                  variationData.length > 0 &&
                  variationData
                    ?.filter((item) => {
                      return (
                        item[variations.map((item) => item.name)[0]]?.trim()
                          .length !== 0
                      );
                    })
                    .map((row, rowIndex) => (
                      <tr className="" key={rowIndex}>
                        {variations &&
                          variations.length > 0 &&
                          variations.map((item, index) => (
                            <th
                              key={index}
                              className="border px-2 small text-muted"
                            >
                              {row[item.name]}
                            </th>
                          ))}
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text input_icon_left border-0"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="teamPrice"
                            placeholder="Input"
                            name="teamPrice"
                            value={row.teamPrice}
                            icon="currency"
                            onChange={(e) =>
                              handleVariationDataChange(
                                e,
                                "teamPrice",
                                rowIndex
                              )
                            }
                            required={false}
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="teamMembers"
                            placeholder="Input"
                            name="teamMembers"
                            value={row.teamMembers}
                            onChange={(e) =>
                              handleVariationDataChange(
                                e,
                                "teamMembers",
                                rowIndex
                              )
                            }
                            required={false}
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="teamUnitCapped"
                            placeholder="Input"
                            name="teamUnitCapped"
                            value={row.teamUnitCapped}
                            onChange={(e) =>
                              handleVariationDataChange(
                                e,
                                "teamUnitCapped",
                                rowIndex
                              )
                            }
                            required={false}
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="number"
                            onKeyPress={(e) => handleKeyPress(e)}
                            min="0"
                            id="teamStock"
                            placeholder="Input"
                            name="teamStock"
                            value={row.teamStock}
                            onChange={(e) =>
                              handleVariationDataChange(
                                e,
                                "teamStock",
                                rowIndex
                              )
                            }
                            required={false}
                          />
                        </td>
                        <td className="border">
                          <InputField
                            formClass="col-12 p-0"
                            className="input-box input-inside-text pl-2 border-0"
                            type="text"
                            // onKeyPress={(e) => handleKeyPress(e)}
                            // min="0"
                            id="teamSku"
                            placeholder="Input"
                            name="teamSku"
                            value={row.teamSku}
                            onChange={(e) =>
                              handleVariationDataChange(e, "teamSku", rowIndex)
                            }
                            required={false}
                          />
                        </td>
                      </tr>
                    ))}
              </table>
            </div>
          </form>
        </div>

        <div
          id="specification"
          // visible={String(steps == 3)}
          className="mr-3 ml-4 rounded  px-3 pt-4 pb-2 bg-white"
        >
          <h6 className="my-0">SPECIFICATION</h6>
          <form className="mt-3 p-0">
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small">Brand</label>
              <div className="form-group col-9">
                <SelectInput
                  options={brandsList}
                  name="brand"
                  value={specification.brand}
                  onChange={(e) => onChangeSpecification(e, "brand", e)}
                  required={true}
                  formSubmitted={false}
                  placeholder="Select brand"
                />
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Ship From
              </label>
              <div className="form-group col-9">
                <SelectInput
                  options={countriesList}
                  name="shipFrom"
                  value={specification.shipFrom}
                  onChange={(e) => onChangeSpecification(e, "shipFrom", e)}
                  required={true}
                  formSubmitted={false}
                  placeholder="Select country"
                />
              </div>
            </div>
            {basicInformation.type == "non-physical" && (
              <>
                <div className="col-12 row my-3">
                  <label className="col-3 my-auto text-right small imp">
                    Expired Type
                  </label>
                  <RadioButtonsGroup
                    options={expiredType}
                    name="expiredType"
                    className="col-9 px-3"
                    value={specification.expiredType}
                    onChange={(e) => onChangeSpecification(e, "expiredType", e.target.value)}
                  />
                </div>
                {specification.expiredType == "date-range" && (
                  <div className="col-12 row my-3">
                    <label className="col-3 text-right m-0 p-0 small imp">
                      Expiry Date
                    </label>
                    <div className="form-group col-3">
                      <InputField
                        className="pr-5"
                        formClass="p-0"
                        type="date"
                        min="0"
                        id="expiry-date"
                        placeholder="Input"
                        name="expiredStartDate"
                        value={specification?.expiredStartDate || ""}
                        onChange={onChangeSpecification}
                        required={true}
                        iconType="text"
                        iconPosition="right"
                      />
                    </div>
                    <div className="form-group col-3">
                      <InputField
                        className="pr-5"
                        formClass="p-0"
                        type="date"
                        min="0"
                        id="expiry-date"
                        placeholder="Input"
                        name="expiredEndDate"
                        value={specification?.expiredEndDate || ""}
                        onChange={onChangeSpecification}
                        required={true}
                        iconType="text"
                        iconPosition="right"
                      />
                    </div>
                  </div>
                )}
                {specification.expiredType == "duration" && (
                  <div className="col-12 row my-3">
                    <label className="col-3 text-right m-0 p-0 small imp">
                      Expiry Duration
                    </label>
                    <div className="form-group col-9">
                      <InputField
                        className="pr-5"
                        formClass="p-0"
                        type="number"
                        onKeyPress={(e) => handleKeyPressMoreZero(e)}
                        min="1"
                        id="expiry-duration"
                        placeholder="Input"
                        name="expiredDuration"
                        value={specification.expiredDuration}
                        onChange={(e) => onChangeSpecification(e, "expiredDuration", e.target.value)}
                        required={true}
                        iconType="text"
                        iconLabel="Days"
                        iconPosition="right"
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            {basicInformation.type == "physical" && (
              <>
                <div className="col-12 row my-3">
                  <label className="col-3 text-right m-0 p-0 small imp">
                    Warranty Period
                  </label>
                  <InputField
                    className="pr-5"
                    formClass="p-0"
                    type="number"
                    onKeyPress={(e) => handleKeyPress(e)}
                    min="0"
                    id="warranty-period"
                    placeholder="Input"
                    name="warrantyPeriod"
                    value={specification.warrantyPeriod}
                    onChange={onChangeSpecification}
                    required={true}
                    iconType="text"
                    iconLabel="Months"
                    iconPosition="right"
                  />
                </div>
              </>
            )}
          </form>
        </div>
        <br />
        <div
          id="shipping-address"
          visible={String(
            basicInformation.type == "non-physical" ? false : true
          )}
          className="mr-3 ml-4 rounded mb-2 px-3 pt-4 bg-white"
        >
          <h6 className="my-0">SHIPPING INFORMATION</h6>
          <form className="mt-3 p-0">
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Weight
              </label>
              <div className="form-group col-9">
                <InputField
                  className="input_icon_right"
                  formClass="p-0"
                  type="number"
                  min="0"
                  id="weight"
                  placeholder="Input"
                  name="weight"
                  icon="kg"
                  value={shippingInfo.weight}
                  onChange={onChangeShippingInfo}
                  required={true}
                  onKeyPress={(e) => handleKeyPress(e)}
                />
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Parcel Size
              </label>
              <div className="form-group col-9 d-flex flex-wrap">
                <InputField
                  className="input_icon_left input_icon_right"
                  icon="width-cm"
                  formClass="col-4 p-0"
                  type="number"
                  min="0"
                  onKeyPress={(e) => handleKeyPress(e)}
                  id="parcel-width"
                  placeholder="Input"
                  name="parcelWidth"
                  value={shippingInfo.parcelWidth}
                  onChange={onChangeShippingInfo}
                  required={true}
                />
                <InputField
                  className="input_icon_left input_icon_right"
                  icon="length-cm"
                  formClass="col-4 p-0"
                  type="number"
                  min="0"
                  onKeyPress={(e) => handleKeyPress(e)}
                  id="parcel-length"
                  placeholder="Input"
                  name="parcelLength"
                  value={shippingInfo.parcelLength}
                  onChange={onChangeShippingInfo}
                  required={true}
                />
                <InputField
                  className="input_icon_left input_icon_right"
                  icon="height-cm"
                  formClass="col-4 p-0"
                  type="number"
                  min="0"
                  onKeyPress={(e) => handleKeyPress(e)}
                  id="parcel-height"
                  placeholder="Input"
                  name="parcelHeight"
                  value={shippingInfo.parcelHeight}
                  onChange={onChangeShippingInfo}
                  required={true}
                />
              </div>
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small imp">
                Shipping Fee
              </label>
              <div className="form-group col-9">
                <p>
                  You can also{" "}
                  <button href="#" className="btn bold" disabled>
                    Click Here
                  </button>{" "}
                  to enable more shipping channel.
                </p>
                <div className="card border">
                  <div className="card-header d-flex">
                    Delivery Fee: <div className="bold">RM0:0</div>
                  </div>
                  <div className="card-body">
                    {/* <p>DHL Express (max 30kg)</p> */}
                    <p>JNT Express (max 30kg)</p>
                  </div>
                </div>
                <p className="small text-muted">
                  Shipping settings will be applicable for this product only.
                  Shipping fees displayed are base rates and will be subject to
                  change depending on buyer and seller location.
                </p>
              </div>
            </div>
          </form>
        </div>
        <br />
        <div
          id="other-information"
          // visible={String(steps == 3)}
          className="mr-3 ml-4 rounded mb-2 px-3 pt-4 bg-white"
        >
          <h6 className="my-0">OTHERS</h6>
          <form className="mt-3 p-0">
            <div className="col-12 row my-3">
              <label className="col-3 text-right mx-0 mt-2 p-0 small imp">
                Pre-Order
              </label>
              <div className="form-group col-9">
                <RadioButtonsGroup
                  options={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                  ]}
                  name="preOrder"
                  className="col-9 px-3"
                  value={basicInformation.preOrder}
                  onChange={(e) =>
                    onChangeBasicInfo(e, "preOrder", e.target.value)
                  }
                />
              </div>
            </div>
          </form>
        </div>
        <div className="error_container mr-3 ml-4 rounded px-3 d-flex">
          {Object.values(errors).filter((item) => item).length ? (
            <label className="error fntSz14">
              {Object.values(errors)
                .filter((item) => item)
                .join(", ")}{" "}
              fields are missing or invalid!
            </label>
          ) : (
            <></>
          )}
        </div>
        <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 my-3 bg-white border-top">
          <div className="col-md-4 d-flex  align-items-center mr-2 justify-content-end">
            <button
              type="button"
              className="btn border input-inside-text mr-2"
              onClick={() => CancelEditing()}
            // onClick={() => history.push(param.merchantId ? `/merchant-center/profiles/${param.merchantId}/${param.merchantName}` : "/merchant/products")}
            >
              Cancel
            </button>

            <div
              // onClick={() => handleClickNext()}
              className="mr-2"
              visible={String(
                !param.merchantId &&
                  (editStatusText == "NEW" || editStatusText == "DELETED")
                  ? false
                  : true
              )}
            >
              <select
                className="btn border input-inside-text mr-2"
                onChange={(e) => changeProductStatus(e)}
              >
                <option
                  value={statusConstant[editStatusText]}
                  disabled
                  selected
                >
                  Action
                </option>
                {statusOptions.map((item, index) => (
                  <option key={index} value={statusConstant[item]}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="button"
              className="btn input-inside-text btn-dark text-nowrap"
              onClick={() => handleClickNext()}
              visible={String(
                !param.merchantId &&
                  (editStatusText == "NEW" || editStatusText == "DELETED")
                  ? false
                  : true
              )}
            >
              Save
              {/* { !param.merchantId? "Submit Approval": "Save & Publish"} */}
            </button>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default MerchantEditProduct;
