import {
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../components/Images/SvgIcon";
import { getProductOverride } from "../../redux/actions/merchant/productOverride.action";
import moment from "moment";

import {
  startLoader,
  stopLoader,
  showToast,
  open_dialog,
  AllowAccess,
} from "../../lib/global";
import { DELETE_PRODUCT_OVERRIDE_DIALOG } from "../../lib/constant";
import { getLocalStorage } from "../../lib/session";
import PagenationDropdown from "../../components/DropDown/pagenationDropdown";

const initialSearhData = {
  item: "",
  order: "",
  search: "",
  offset: 0,
};

function Allproduct(props) {
  AllowAccess(["MERCHANT"]);
  const [permissions, setPermissions] = useState(
    getLocalStorage("assignMethod")
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const [allProductData, setAllProductData] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);
  const refSearchData = useRef({});
  const { afterDelete } = props;

  const { merchantId, merchantName } = props;

  useEffect(() => {
    if (permissions !== "undefined") {
      setPermissions(JSON.parse(getLocalStorage("assignMethod")));
    } else {
      setPermissions({});
    }
  }, []);

  useEffect(() => {
    let filteringdata;
    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      props.reset();
      setPageNo(1);
      setOffset(0);
      filteringdata = {
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
    } else if (refSearchData.current !== props.search) {
      setPageNo(1);
      setOffset(0);
      filteringdata = {
        ...props.search,
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
      refSearchData.current = props.search;
    } else {
      filteringdata = {
        ...props.search,
        status: props.status,
        offset,
        limit: pageLimit,
      };
    }
    startLoader();

    let categoryMapping = {
      isNormal: "Normal",
      isStream: "Live Streams",
      isFlashDeal: "Flash Deals",
      isGroupDeal: "Group Deals"
    };

    let category;
    const response = dispatch(getProductOverride({ ...filteringdata }));
    response
      .then((data) => {
        props.setTotals(data.data.total || {});
        setAllProductData(
          data &&
          data.data &&
          data.data.data.map((row, index) => {
            if (props.status == "") {
              let categories = Object.keys(categoryMapping)
                .filter(key => row[key])
                .map(key => categoryMapping[key]);

              category = categories.join(', ')
            };



            //   if (row?.isNormal == true && row?.isStream == false && row?.isFlashDeal == false && row?.isGroupDeal == false) {
            //     category = 'Normal'
            //   }
            //   if (row?.isNormal == true && row?.isStream == true && row?.isFlashDeal == false && row?.isGroupDeal == false) {
            //     category = 'Normal, Live Streams'
            //   }
            //   if (row?.isNormal == true && row?.isStream == true && row?.isFlashDeal == true && row?.isGroupDeal == false) {
            //     category = 'Normal, Live Streams, Flash Deals'
            //   }
            //   if (row?.isNormal == true && row?.isStream == true && row?.isFlashDeal == true && row?.isGroupDeal == true) {
            //     category = 'Normal, Live Streams, Flash Deals, Group Deals'
            //   }


            if (row?.isNormal == true && props.status == "isNormal") {
              category = 'Normal'
            }
            if (row?.isStream == true && props.status == "isStream") {
              category = 'Live Streams'
            }
            if (row?.isFlashDeal == true && props.status == "isFlashDeal") {
              category = 'Flash Deals'
            }
            if (row?.isGroupDeal == true && props.status == "isGroupDeal") {
              category = 'Group Deals'
            }
            return {
              id: row?.id,
              productId: row?.productId,
              productName: row?.product.title || "N/A",
              productType: row?.product.productType || "N/A",
              category: category || "N/A",
              status: row?.status === true ? "ACTIVE" : "NON ACTIVE",
            };
          })
        );

        stopLoader();
      })
      .catch((error) => {
        stopLoader();
      });
  }, [offset, pageLimit, props.search]);

  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof allProductData[0][item] === "number") {
        let sortedData = allProductData.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setAllProductData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof allProductData[0][item] === "string" && !isDate) {
        let sortedData = allProductData.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });

        setTimeout(() => {
          setAllProductData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof allProductData[0][item] === "string" && isDate) {
        let sortedData = allProductData.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setAllProductData([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      stopLoader();
    }
  };

  const handlePageChange = (event, value) => {
    setOffset(value - 1);
    setPageNo(value);
  };

  const addProduct = (item) => {
    history.push("/merchant/add-product-override");
  };

  const editAction = (id, item) => {
    history.push(`/merchant/edit-product-override/${id}`);
  };
  const viewAction = (id) => {
    history.push(`/products/view?id=${id}`);
  };

  const getProductAfterDelete = (data) => {
    afterDelete(data);
    setAllProductData(
      data &&
      data.data &&
      data.data.map((row, index) => {
        return {
          id: row?.id,
          productName: row?.product.title || "N/A",
          productType: row?.product.productType || "N/A",
          // category: row?.category || "N/A",
          status: row?.statusText || "N/A",
        };
      })
    );
  };

  const handleDeleteProduct = (id) => {
    open_dialog(DELETE_PRODUCT_OVERRIDE_DIALOG, {
      onSubmit: getProductAfterDelete,
      id,
      merchantId,
    });
  };

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between">
        <div>
          <span>{props?.totalItem || 0} Products</span>
        </div>
        <div className="d-flex my-3">
          <button
            onClick={() => addProduct()}
            type="button"
            className="btn btn-dark btn-sm text-nowrap mx-3"
          >
            + Add product
          </button>
        </div>
      </div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className="th_cell" component="th">
                No
              </TableCell>
              <TableCell className="th_cell" component="th" align="left">
                <div className="d-flex  ">
                  <div>Name</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("productName", "asc")}
                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("productName", "desc")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="left">
                <div className="d-flex  ">
                  <div>Category</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                    // onClick={() => handleSorting("productName", "asc")}
                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                    // onClick={() => handleSorting("productName", "desc")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Type</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("productType", "asc")}
                    // onClick={() => getApiData({ item: "title", order: "ASC" })}
                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("productType", "desc")}
                    // onClick={() => getApiData({ item: "title", order: "DESC" })}
                    />
                  </div>
                </div>
              </TableCell>
              {/*<TableCell className="th_cell" component="th" align="center">*/}
              {/*  <div className="d-flex  ">*/}
              {/*    <div>Category</div>*/}
              {/*    <div className="d-flex flex-column ">*/}
              {/*      <ExpandLessIcon*/}
              {/*        style={{*/}
              {/*          width: "15px",*/}
              {/*          height: "15px",*/}
              {/*          margin: "0px 3px",*/}
              {/*        }}*/}
              {/*        onClick={() => handleSorting("category", "asc")}*/}
              {/*      />*/}
              {/*      <ExpandMoreIcon*/}
              {/*        style={{*/}
              {/*          width: "15px",*/}
              {/*          height: "15px",*/}
              {/*          margin: "-5px 3px",*/}
              {/*        }}*/}
              {/*        onClick={() => handleSorting("category", "desc")}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</TableCell>*/}
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Status</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("status", "asc")}
                    // onClick={() => getApiData({ item: "title", order: "ASC" })}
                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("status", "desc")}

                    // onClick={() => getApiData({ item: "title", order: "DESC" })}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="table_action_head" component="th">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!allProductData?.length && (
              <div className="d-flex justify-content-center">
                <h3 className="text-muted m-auto">No data found!</h3>
              </div>
            )}

            {allProductData &&
              allProductData.length > 0 &&
              allProductData.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left" className="td_cell">
                    {offset * pageLimit + (index + 1)}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row?.productName || "N/A"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {String(row.category) || "N/A"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.productType || "N/A"}
                  </TableCell>
                  {/*<TableCell align="left" className="td_cell">*/}
                  {/*  {String(row.category) || "N/A"}*/}
                  {/*</TableCell>*/}
                  <TableCell align="left" className="td_cell">
                    {row.status || "N/A"}
                  </TableCell>

                  <TableCell className="d-flex  table_action_item">
                    {/*<IconButton*/}
                    {/*  className="pointer"*/}
                    {/*  onClick={() => editAction(row.id)}*/}
                    {/*>*/}
                    {/*  <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />*/}
                    {/*</IconButton>*/}

                    <IconButton
                      className="pointer"
                      onClick={() => handleDeleteProduct(row.id)}
                    >
                      <SvgIcon src="/icons/delete_icon.svg" alt="delete icon" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <div className="d-flex justify-content-between align-items-center">
        <PagenationDropdown setPageQuant={setPageLimit} />

        <div className="float-right mb-1">
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil((props?.totalItem || 0) / pageLimit)}
              shape="rounded"
              size="small"
              page={pageNo}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
}

export default Allproduct;
