import React, { useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File
import MuiButton from "../components/buttons/MuiButton";

const PrivacyPolicy = () => {
  const [editor, setEditor] = useState("");

  const handleSubmit = () => {
    console.log("handlesubmit", editor);
  };
  return (
    <React.Fragment>
      <div className="container py-3 h-100">
        <SunEditor
          height="100%"
          width="100%"
          lang="en"
          autoFocus={true}
          defaultValue={editor}
          onChange={(e) => setEditor(e)}
          placeholder="Please type here..."
          name="Privacy Policy"
          charCounter={true}
          setOptions={{
            height: 200,
            buttonList: [
              ["undo", "redo"],
              ["font", "fontSize", "formatBlock"],
              [
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
                "removeFormat",
              ],
              [
                "fontColor",
                "hiliteColor",
                "outdent",
                "indent",
                "align",
                "horizontalRule",
                "list",
                "table",
              ],
              ["link", "image", "video", "fullScreen", "showBlocks"],
            ], // Or Array of button list, eg. [['font', 'align'], ['image']]
            // Other option
          }}
        />
        <MuiButton
          title="Submit"
          type="submit"
          color="secondary"
          onClick={handleSubmit}
          className="m-3"
        />
      </div>
    </React.Fragment>
  );
};

export default PrivacyPolicy;
