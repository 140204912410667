import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { GrDownload } from "react-icons/gr";
import SalesGraph from "../../dashboard/salesCharts/SalesGraph";
import {
  exportOverviewProduct,
  getAllOverviewProduct,
} from "../../../redux/actions/product.action";
import { startLoader, stopLoader, showToast, AllowAccess } from "../../../lib/global";
import ListItems from "./listItems";
import Fade from "react-bootstrap/Fade";
import { MdDateRange } from "react-icons/md";
import DatePickerSelect from "./date.picker";

const overviewProduct = () => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const dispatch = useDispatch();
  const [growthList, setGrowthList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [chartData, setChartData] = useState();
  const [categoriesList, setCategoriesList] = useState([
    "All Product",
    "Published",
  ]);
  const [open, setOpen] = useState(false);

  // DateFIlterDropdown state
  const [statusDate, setStatusDate] = useState("Year");
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(
    new Date(new Date(endDate) - 1000 * 60 * 60 * 24 * 365)
  );
  // DateFIlterDropdown state
  useEffect(() => {
    const breadcrumb = [{ label: "Product Overview", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    const filteringData = {
      status: statusDate,
      startDate,
      endDate,
      categories: categoriesList,
      downloadFile: false,
    };

    apiData(filteringData);
  }, [startDate, categoriesList]);

  const apiData = (filteringData) => {
    startLoader();
    const response = dispatch(getAllOverviewProduct(filteringData));
    response
      .then(({ data }) => {
        // console.log(data, "dataaaaaaaaaaaaaaaaaaaaaaaaaaa");
        setChartData(data["chartData"]);
        setGrowthList(data["growth"]);
        setCategoryList(data["category"]);

        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((error) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log({ error }, "error");
      });
  };

  const handleExportData = () => {
    console.log("button to export");
    const filteringData = {
      status: statusDate,
      startDate,
      endDate,
      categories: categoriesList,
    };

    dispatch(exportOverviewProduct(filteringData))
      .then((response) => {
        console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${Date.now()}.xlsx`);
        // Append to html link element page
        document.body.appendChild(link);
        setTimeout(() => {
          // Start download
          link.click();
          // Clean up and remove the link
          link.parentNode.removeChild(link);
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        console.log({ err });
        alert("error .somthing is wrong");
        stopLoader();
      });
    apiData(filteringData);
  };

  return (
    <React.Fragment>
      <div className="mx-3 pr-3 py-2 rounded bg-white d-flex flex-row justify-content-between">
        <div className="row flex-wrap mx-1 w-75">
          <div className="row flex-nowrap ml-2 py-2 font-weight-bold">
            <span className="badge">Data Period</span>
          </div>
          <div className="col mx-1">
            <div
              className="d-flex justifly-content-center align-items-center pointer"
              onClick={() => setOpen(!open)}
            >
              <div
                className="d-flex rounded border border-muted py-2"
                onClick={() => setOpen(!open)}
              >
                <MdDateRange className="ml-1" />
                <span className="font-weight-bold badge">By {statusDate}:</span>
                <span className="text-muted badge">
                  {moment(startDate).format("ll")} -{" "}
                  {moment(endDate).format("ll")}
                </span>
              </div>
            </div>
            <Fade
              in={open}
              className="rounded align-items-center border border-muted mt-2 bg-white"
            >
              <div id="example-fade-text" onMouseLeave={() => setOpen(false)}>
                <DatePickerSelect
                  statusDate={statusDate}
                  setStatusDate={setStatusDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                />
              </div>
            </Fade>
          </div>
        </div>
        <div
          className="d-flex flex-row flex-nowrap font-weight-bold px-4 btn border border-muted align-items-center btn-outline-dark pointer h-15"
          onClick={handleExportData}
        >
          <span className="badge badge-light">
            <GrDownload />
          </span>
          <span className="badge">Export Data</span>
        </div>
      </div>
      <div className="mt-3 mx-3 py-3 rounded bg-white d-flex flex-row justify-content-end">
        <div className="col-12 d-flex flex-column px-1 p-0">
          <div className="d-flex text-m font-weight-bold mx-2 rounded align-items-center">
            <h5 className="font-weight-bold">
              <span className="badge">Graph Overview</span>
            </h5>
          </div>
          <div className="px-2">
            <div className="dahsboard_header_tab">
              <SalesGraph
                series={chartData?.series}
                categories={chartData?.categories}
                type="line"
              ></SalesGraph>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 mx-3 py-3 rounded bg-white d-flex flex-row justify-content-end">
        <div className="col-12 d-flex flex-column px-1 p-0">
          <div className="text-m font-weight-bold mx-2 rounded align-items-center">
            <h5 className="font-weight-bold">
              <span className="badge">Product Overview</span>
            </h5>
          </div>
          <div className="dashboard_container row mx-3 border rounded-bottom-0 border-bottom-0">
            <div className="dashboard_menu col-1 bold">Growth</div>
            <div className="dashboard_main col-11 flex-wrap py-2">
              {growthList?.map((item, idx) => {
                return (
                  <div key={idx}>
                    <ListItems
                      item={item}
                      statusDate={statusDate}
                      index={idx}
                      categoriesList={categoriesList}
                      setCategoriesList={setCategoriesList}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {categoryList.length > 0 && (
            <>
              <div className="dashboard_container row mx-3 border rounded-top-0 ">
                <div className="dashboard_menu col-1 font-weight-bold">
                  Category
                </div>
                <div className="dashboard_main col-11 flex-wrap py-2">
                  {categoryList?.map((item, idx) => {
                    return (
                      <div key={idx}>
                        <ListItems
                          item={item}
                          statusDate={statusDate}
                          index={idx}
                          categoriesList={categoriesList}
                          setCategoriesList={setCategoriesList}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <style jsx="true">{`
        .dahsboard_header_tab {
          //   background: #000000d6;
        }
        .tab_list {
          list-style: none;
        }
        .dahsboard_header_tab > ul {
          flex-wrap: wrap;
        }
        .list_card {
          padding: 0px 8px;
          display: flex;
          min-height: 100px;
          min-width: 220px;
          border-radius: 3px;
          box-shadow: 0 0 2px 0px #0000004f;
        }
        .card_item {
          flex-grow: 8;
          text-align: left;
        }
        .card_icon {
          flex-grow: 2;
          display: flex;
          align-items: center;
        }
        .date_picker {
        }
        #example-fade-text {
          position: absolute;
          z-index: 1020;
        }

        .dashboard_container {
        }
        .dashboard_menu {
          display: flex;
          flex-direction: wrap;
          background: rgb(210, 210, 210);
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
        .dashboard_main {
          display: flex;
          justify-content: between;
          align-items: center;
          float: left;
        }
      `}</style>
    </React.Fragment>
  );
};

export default overviewProduct;

const colors = ["#247BA0", "#f48024"];
