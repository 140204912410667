import CloseIcon from '@mui/icons-material/Close';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { IconButton, ListItemText } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { createStyles, makeStyles } from '@mui/styles';
import { spacing } from '@mui/system';
import React from 'react';
import { useHistory } from 'react-router-dom';
import useTheme from '../../hooks/useTheme';
import { close_drawer } from '../../lib/global';
import sidebarList from '../sidebar';
import merchantSidebarList from "../sidebar/MerchantSidebar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  nested: {
    paddingLeft: spacing(4),
  },
}));

const MvSidebar = (props) => {
  const [theme] = useTheme()
  const classes = useStyles();
  const [open, setOpen] = React.useState({});
  const Route = useHistory()
  const {pathname=""} = Route.location;
  const userRole = localStorage.getItem("role");
  const createdBy = localStorage.getItem("createdBy");

  const handleClick = (title, item) => {
    setOpen(prev=>{
        return {
            ...prev,
            [title]: !prev[title]
        }
    });
    if(item.path){
        Route.push(item.path);
        setTimeout(() => {
            handleToggleDrawer()
        }, 1);
    }
  };

  const handleClickChild = (path) => {
    Route.push(path);
    setTimeout(() => {
        handleToggleDrawer()
    }, 1);
  }
  const handleToggleDrawer = () => {
      close_drawer("SIDEBAR")
  }

//   const itemList = sidebarList(theme.iconColor)
    let itemList = [];
    if (userRole === "SUPERADMIN" || createdBy == "SUPERADMIN") {
    itemList = sidebarList(theme.iconColor);
    } else {
    itemList = merchantSidebarList(theme.iconColor)
    }
  return (
    <React.Fragment>
        {/* <AppBar
            style={{
            backgroundColor: theme.headerColor,

            }}
            className={`header_sidebar`}
            position="static"
        > */}

        {/* </AppBar> */}
        <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        style={{
            color: theme.textColor,
            backgroundColor: theme.drawerBgColor,
            boxShadow: theme.drawerShadow,
            height: "100%",
            overflowX: 'hidden'
        }}
        className={classes.root, 'p-0'}
        >
            <ListItem className="w-100">
                <IconButton
                    edge="start"
                    className={classes.menuButton}
                    onClick={handleToggleDrawer}
                    color="inherit"
                    style={{zIndex: 100}}
                    aria-label="open drawer"
                >
                    <CloseIcon fontSize="large" />
                </IconButton>
                <div className="w-100 position-absolute text-center">
                    <h3 className="m-0">
                      {/*{userRole == "SUPERADMIN" || createdBy == "SUPERADMIN" ? "Admin Center" : "Merchant Panel"}*/}
                      {userRole == "MERCHANT" && createdBy != "SUPERADMIN" ? "Merchant Panel" : "Admin Center"}
                    </h3>
                </div>
            </ListItem>
            <hr className="mt-0"/>

        {itemList.map((item,index)=>{
            return (
              <React.Fragment key={index}>
                <ListItem
                  className={`d-flex justify-content-between side_nav_item ${pathname === item.path ? 'active' : ''}`}
                  button
                  onClick={() => handleClick(item.title, item)}>
                        <span className="d-flex sidebar nav__link" style={{ color: theme.textColor }}>
                            <ListItemIcon style={{ color: pathname === item.path ? "#fff" : theme.iconColor }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText className="nav_item_text" primary={item.title} />
                        </span>
                  {item.children && item.children.length ? (open[item.title] ? <ExpandLess /> : <ExpandMore />) : null}
                </ListItem>
                {item.children && item.children.map((childItem, childIndex) => {
                  return (
                    <Collapse key={childItem.title + childIndex} in={open[item.title]} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <ListItem
                          button
                          onClick={() => handleClickChild(childItem.path)}
                          className={`${classes.nested} side_nav_item child ${pathname === item.path ? 'active' : ''}`}>
                          <ListItemIcon style={{ color: pathname === item.path ? "#fff" : theme.iconColor }}>
                            {childItem.icon}
                          </ListItemIcon>
                          <ListItemText className="nav_item_text" primary={childItem.title} />
                        </ListItem>
                      </List>
                    </Collapse>
                  )
                })}
              </React.Fragment>
            )
        })}
        </List>
    </React.Fragment>
  );
}

export default MvSidebar;