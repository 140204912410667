import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import React, { useEffect, useState } from "react";
import Image from "../../../components/Images/Image";

const HelpChatLog = () => {
  const [assignChat, setAssignChat] = useState(" ");
  const history = useHistory();
  const dispatch = useDispatch();
  const [k,setK] = useState("merchantChat")

  useEffect(() => {
    const breadcrumb = [
      { label: `Help Center`, url: "/merchant-center/merchant-help-center" },
      { label: `Chat Log `, url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
  },[]);
  return (
    <React.Fragment>
      <div className="container-fluid position-relative row mx-0">
        <div className="col-8">
          <div className="d-flex">
            <p role="button" onClick={history.goBack}>
              &lt; Back
            </p>
          </div>
          <div>REQUEST REFUND INFORMATION</div>
          <div className="card d-flex flex-column justify-content-between my-3 vh-100">
            <div
              style={{ maxHeight: "80vh", overflowY: "auto" }}
              className="p-3"
            >
              <br />
              <div className={`row ${k == "cutumerChat" ? "d-block" : "d-none"}`}>
                <div className="col-1 pt-1">
                  <Image
                    src="/mouse-img.png"
                    alt={"photo"}
                    width="40px"
                    height="40px"
                    className="overflow-hidden rounded-circle"
                  />
                </div>
                <div className="col-10  fntSz12">
                  <div>Cristo Mentren</div>
                  <div>Hellooooo!!! Please respond</div>
                  <div className="fntSz11 text-secondary">
                    11/12/2022 11:00pm
                  </div>
                </div>
              </div>
              <br />
              <div className={`${k == "merchantChat" ? "d-block" : "d-none"}`}>
                <div className="row flex-row-reverse">
                  <div className="col-2 pt-1">
                    <Image
                      src="/mouse-img.png"
                      alt={"photo"}
                      width="40px"
                      height="40px"
                      className="overflow-hidden rounded-circle"
                    />
                  </div>
                  <div className="col-10 pr-0 d-flex flex-column align-items-end fntSz12">
                    <div>Cristo Mentren</div>
                    <div>Hellooooo!!! Please respond</div>
                    <div className="fntSz11 text-secondary">
                      11/12/2022 11:00pm
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <hr />
              <div className={`row align-items-center pl-3 pr-2 pb-2`}>
                <div className="col-1 pr-1">
                  <Image
                    src="/imageIcon.png"
                    alt={"photo"}
                    // width="40px"
                    // height="40px"
                  />
                </div>
                <div className="col-9 pl-0">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type a message"
                  />
                </div>
                <div className="col-2 pl-0">
                  <button className="btn btn-secondary fntSz13 px-4" disabled>
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 p-0">
          <div
            style={{ top: "0", right: "0" }}
            className=" position-sticky  p-3 card my-5 fntSz13"
          >
            <div className="mb-3 ">
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Status</div>
                <div className="text-danger fntSz16">Open</div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Merchant ID</div>
                <div className="">
                  #390174
                  {/* {"#" + "012201" + (fullData?.orderId?.slice(-2) || "N/A")} */}
                  {/* {fullData?.orderId || "N/A"} */}
                </div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Order ID</div>
                <div className="">
                  #892345
                  {/* {"#" + "012201" + (fullData?.orderId?.slice(-2) || "N/A")} */}
                  {/* {fullData?.orderId || "N/A"} */}
                </div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Created</div>
                <div className="">Buy Now</div>
              </div>
              <div className="d-flex justify-content-between py-1">
                <div className="text-muted">Updated</div>
                <div>Yet to assign</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HelpChatLog;
