import { GetWithToken, PostWithToken } from "../../lib/request";

export const getAllVersionsData = (type) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/app-versions?applicationType=${type}`);
    return result;
  };
};

export const createNewAppVersion = (data) => {
  return async function (dispatch) {
    const result = await PostWithToken(`/app-versions`, data);
    return result;
  };
};
