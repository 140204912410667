import React from "react";

import "../../../static/css/deleteModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { showToast, startLoader, stopLoader } from "../../../lib/global";
import {
  deleteOutletById,
  getAllOutletApi,
} from "../../../services/outlet.service";

const DeleteOutletModal = (props) => {
  const dispatch = useDispatch();
  const deleteFun = () => {
    const data = {
      status: "DELETED",
    };

    const filter = { limit: 10, offset: 0, search: "", storeId: props.storeId };

    startLoader();
    dispatch(deleteOutletById(props.id))
      .then((data) => {
        dispatch(getAllOutletApi(filter))
          .then((data) => {
            if (data && data.data && data.data.status === 200) {
              stopLoader();


              showToast(data.data.message, "success");
              props.onSubmit([...data.data.data], data.data.totalOutlet);
              props.onClose();
            } else if (data.status === 204) {
              stopLoader();
              showToast("deleted successfully", "success");
              props.onSubmit([]);
              props.onClose();
            } else {
              stopLoader();
              // props.onSubmit([...data.data.data]);
              props.onClose();
            }
          })
          .catch((error) => {
            stopLoader();
            showToast("error", "danger");
            console.log({ error });
            props.onClose();
          });
      })
      .catch((error) => {
        stopLoader();
        showToast(error, "danger");
        console.log("{ error }", "error in delete by id ");
        props.onClose();
      });
  };
  return (
    <React.Fragment>
      <div className="delete_modal_body">
        <div className="close_icon" style={{ top: "0px" }}>
          <CloseIcon
            className="close_icon_btn m-2"
            color="primary2"
            fontSize="large"
            title="Close dialog"
            onClick={props.onClose}
          />
        </div>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="confirmation_dialogbox__content">
            <div className="dialogbox_body__content">
              <h1 className="d-flex flex-direction-row justify-content-center align-items-center">
                <span className="dialogbox_header__icon">!</span>
              </h1>
              <h4>Are you sure you want to delete?</h4>
              <p>
                Delete this will delete the entire Outlet data and login
                credential.
                <br />
                You are unable to restore back again.
              </p>
            </div>
          </div>
        </div>
        <div className="dialogbox_footer__content">
          <button
            type="button"
            className="dialogbox_delete__btn"
            onClick={() => deleteFun()}
          >
            Delete
          </button>
          <button
            type="button"
            className="dialogbox_cancel__btn"
            onClick={props.onClose}
          >
            Cancel
          </button>
        </div>
      </div>
      <style jsx="true">{`
        .card-body {
          min-height: 50vh;
        }
        .submit_btn {
          position: absolute;
          bottom: 15px;
          width: 100%;
          justify-content: center;
          display: flex;
        }
        .close_icon_btn {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      `}</style>
    </React.Fragment>
  );
};

export default DeleteOutletModal;
