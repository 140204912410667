import React, { useState, useEffect } from "react";

import CloseIcon from "@mui/icons-material/Close";
import decimalFormat from "../../../helpers/decimal.format";

function ShowTrasactionHistoryModal(props) {
  const { id, fullData, commissionFee } = props;
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    gettingData();
  }, [id]);

  const gettingData = () => {
    const currency = `${fullData?.currencyName || "RM"}`;
    const productPrice = decimalFormat(
      (fullData?.unitPrice || fullData?.price/fullData?.quantity || 0) * (fullData?.quantity || 1)
    );
    const shippingFee = decimalFormat(
      fullData?.shippingDetails?.shippingCharge ||
        fullData?.trackingInfo?.price ||
        0
    );

    const actualFee = decimalFormat(fullData?.trackingInfo?.price || 0);
    const unitPrice = () => {
      return fullData.unitPrice || fullData?.price/fullData?.quantity || 0;
    };
  
    const subTotal = () => {
      return unitPrice() * (fullData?.quantity || 1);
    };
    const transactionFee = (
      Number(0.0212 * Number(fullData?.finalAmount || 0))
    );

    let voucherUU2Func = () => {
      if(fullData?.voucherUsage && fullData?.voucherUsage?.voucherType == 'uu2'){
        return (fullData?.voucherUsage?.amount || 0)
      }else{
        return 0
      }
    }

    const voucherUU2CodeFunc = () => {
      if(fullData?.voucherUsage && fullData?.voucherUsage?.voucherType == 'uu2'){
        return '('+ (fullData?.voucherUsage?.voucher?.code || '') + ')'
      }else{
        return ''
      }
    }

    const voucherMerchantFunc = () => {
      if(fullData?.voucherUsage && fullData?.voucherUsage?.voucherType == 'merchant'){
        return (fullData?.voucherUsage?.amount || 0)
      }else{
        return 0
      }
    }

    const voucherMerchantCode = () => {
      if(fullData?.voucherUsage && fullData?.voucherUsage?.voucherType == 'merchant'){
        return '('+ (fullData?.voucherUsage?.voucher?.code || '') + ')'
      }else{
        return ''
      }
    }

    const commission = (
      (Number(subTotal() - voucherMerchantFunc()) * Number(commissionFee))/100
    );

    const feeAndCharges = (
      Number(transactionFee) + Number(commission)
    );
    const incomeTotal = (
      Number(fullData?.finalAmount) + Number(shippingFee) - Number(feeAndCharges)
      // Number(fullData?.finalAmount) - Number(feeAndCharges)
    );

    const buyerPaid = () => {
      return Number.parseFloat(fullData.finalAmount || 0);
    };

    const shippingCharge = () => {
      return Number.parseFloat(
        fullData?.shippingDetails?.shippingCharge ||
          fullData?.trackingInfo?.price ||
          0
      );
    };

    const feesAndCharge = () => {
      // return (((buyerPaid() - shippingCharge())*4.6)/100) + ((buyerPaid() * 2.12)/100)
      let charge = (buyerPaid() * 2.12)/100;
      let fee = (((subTotal()-voucherMerchantFunc()) * commissionFee)/100);
      return (Number(charge)+Number(fee))
    }

    const merchantIncome = () => {
      return subTotal() - (feesAndCharge() + voucherMerchantFunc());
    };

    let voucherUU2Val = decimalFormat( voucherUU2Func())
    let voucherMerchantVal = decimalFormat( voucherMerchantFunc() )
    let voucherUU2CodeVal = voucherUU2CodeFunc()
    let voucherMerchantCodeVal = voucherMerchantCode()
    let subTotalDecimal = decimalFormat(subTotal())
    let merchantIncomeDecimal = decimalFormat(merchantIncome())

    setOrderData({
      currency,
      productPrice,
      shippingFee,
      actualFee,
      transactionFee,
      commission,
      feeAndCharges,
      incomeTotal,
      voucherUU2Val,
      voucherUU2CodeVal,
      voucherMerchantVal,
      voucherMerchantCodeVal,
      subTotal:subTotalDecimal,
      merchantIncome:merchantIncomeDecimal
    });
  };

  return (
    <React.Fragment>
      <div className="card m-3">
        <div className="d-flex border-bottom w-100 justify-content-between">
          <div className="my-3" style={{ fontSize: "20px", fontWeight: "700" }}>
            Transaction History
          </div>
          <div className="close_icon" style={{ top: "0px" }}>
            <CloseIcon
              className="close_icon_btn m-2"
              color="primary2"
              fontSize="large"
              title="Close dialog"
              onClick={props.onClose}
            />
          </div>
        </div>

        <div className="col-12 mt-3">
          <table className="w-100">
            <tr className="w-100">
              <th className=" text-right ">Subtotal</th>
              <th className="text-right ">
                {orderData.currency + orderData.subTotal}
              </th>
            </tr>
            <tr className="w-100">
              <td className=" text-right text-data text-muted">
                Product Price
              </td>
              <td className="text-right text-data text-muted  w-0 pl-5">
                {orderData.currency + orderData.productPrice}
              </td>
            </tr>
            <br />

            <tr className="w-100">
              <th className=" text-right ">Admin Discount</th>
              <th className="text-right ">
                {`${fullData?.currencyName || "RM"}`}
                {orderData.voucherUU2Val} {orderData.voucherUU2CodeVal}
              </th>
            </tr>
            <tr className="w-100">
              <td className="text-right text-data">
                Merchant Discount
              </td>
              <td className="text-right text-data  w-0 pl-5">
                {`${fullData?.currencyName || "RM"}`}
                {orderData.voucherMerchantVal} {orderData.voucherMerchantCodeVal}
              </td>
            </tr>
            <br/>


            <tr className="w-100">
              <th className=" text-right ">Shipping</th>
              <th className="text-right   w-0 pl-5">
                {orderData.currency + orderData.shippingFee}
              </th>
            </tr>
            <tr className="w-100">
              <td className=" text-right text-data text-muted">
                Shipping Fee Paid by Buyer
              </td>
              <td className="text-right text-data text-muted  w-0 pl-5">
                {orderData.currency + orderData.shippingFee}
              </td>
            </tr>
            <tr className="w-100">
              <td className=" text-right text-data text-muted">
                Actual Shipping Fee
              </td>
              <td className="text-right text-data text-muted  w-0 pl-5">
                {orderData.currency + orderData.actualFee}
              </td>
            </tr>

            <br />
            <tr className="w-100">
              <th className=" text-right">Fee & Charges</th>
              <th className="text-right   w-0 pl-5">
                {orderData.currency + decimalFormat(orderData.feeAndCharges)}
              </th>
            </tr>
            <tr className="w-100">
              <td className=" text-right text-data text-muted">Service Fee</td>
              <td className="text-right text-data text-muted  w-0 pl-5">
                0.00
              </td>
            </tr>
            <tr className="w-100">
              <td className=" text-right text-data text-muted">
                Transaction Fee
              </td>
              <td className="text-right text-data text-muted  w-0 pl-5">
                {orderData.currency + decimalFormat(orderData.transactionFee)}
              </td>
            </tr>
            <tr className="w-100">
              <td className=" text-right text-data text-muted">
                Commission Fee
              </td>
              <td className="text-right text-data text-muted  w-0 pl-5">
                {orderData.currency + decimalFormat(orderData.commission)}
              </td>
            </tr>
            <br />

            <tr className="w-100">
              <td className=" text-right text-data text-muted pr-2">
                Total Income
              </td>
              <td className="text-right text-success text-total">
                {orderData.currency + orderData.merchantIncome}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <style jsx="true">{`
        .w-0 {
          width: 0 !important;
        }
        .text-head-black {
          color: #000000;
        }
        .text-data {
          font-size: 15px;
        }
        .text-total {
          font-size: 20px;
          font-weight: 800;
        }
      `}</style>
    </React.Fragment>
  );
}

export default ShowTrasactionHistoryModal;
