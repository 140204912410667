import React from 'react'
import Layout from '../components/Layout'

const FAQ = () => {
    return (
        <React.Fragment>
            FAQ
        </React.Fragment>
    )
}

export default FAQ
