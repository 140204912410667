import React from "react";
import SvgIcon from "../Images/SvgIcon";

/**
 * @description all sidebar items list
 * Add your sidebar item here in this list
 * @author jagannath
 * @date 09/04/2021
 * @param color String - icon color
 * @return Array of sidebar items
 */
const merchantSidebarList = (color) => {
  const isOverrideTp = localStorage.getItem("isOverrideTp");
  console.log(isOverrideTp)
  return [
    {
      title: "Dashboard",
      icon: <SvgIcon src="/icons/order_icon.svg" alt="order icon" />,
      home: true,
      path: "/merchant/dashboard",
      // children: [
      //   {
      //     title: "Sales",
      //     path: "/merchant/dashboard/sales",
      //   },
      //   {
      //     title: "Products",
      //     path: "/merchant/dashboard/products",
      //   },
      //   {
      //     title: "Traffic",
      //     path: "/merchant/dashboard/traffic",
      //   },
      //   {
      //     title: "Marketing",
      //     path: "/merchant/dashboard/marketing",
      //   },
      //   {
      //     title: "Livestream",
      //     path: "/merchant/dashboard/livestream",
      //   },
      // ],
    },

    // {
    //   title:"PRODUCTS",
    //   icon: <SvgIcon src="/icons/product_icon.svg" alt="product icon" />,
    //   home: true,
    //   path: "/merchant/PRODUCTS",
    // },

    // {
    //   title: "My Products",
    //   icon: <SvgIcon src="/icons/product_icon.svg" alt="product icon" />,
    //   home: false,
    //   path: "/merchant/products",
    // },

    {
      title: "My Products",
      icon: <SvgIcon src="/icons/product_icon.svg" alt="product icon" />,
      home: false,
      children: [
        {
          title: "Products",
          path: "/merchant/products",
        },
        isOverrideTp != 0 && (
          {
            title: "Override Setup",
            path: "/merchant/product-override",
          }
        ),
        isOverrideTp != 0 && (
          {
            title: "Override Report",
            path: "/merchant/override-report",
          }
        )
      ].filter(Boolean),
    },

    {
      title: "Order",
      icon: <SvgIcon src="/icons/order_icon.svg" alt="order icon" />,
      home: false,
      children: [
        // {
        //     title: "Overview",
        //     path: "/merchant/orders/overview"
        // },
        {
          title: "Buy Now",
          path: "/merchant/orders/all-orders",
        },
        {
          title: "Team Purchase",
          path: "/merchant/orders/team-purchase",
        },
        {
          title: "Return/Refund",
          path: "/merchant/orders/return-and-refund",
        },
        {
          title: "Redemption",
          path: "/merchant/orders/redemption",
        },
      ],
    },
    // {
    //   title: "My Shipment",
    //   icon: (
    //     <SvgIcon
    //       src="/icons/mercant_center_icon.svg"
    //       alt="merchant center icon"
    //     />
    //   ),
    //   home: true,
    //   path: "/merchant/shipment",
    // },
    // {
    //   title: "Promotion",
    //   icon: (
    //     <SvgIcon
    //       src="/icons/customer_center_icon.svg"
    //       alt="Consumer center icon"
    //     />
    //   ),
    //   home: false,
    //   children: [
    //     {
    //       title: "Campaign",
    //       path: "/merchant/promotion/campaign",
    //     },
    //     {
    //       title: "Sales Promotion",
    //       path: "/merchant/promotion/sales_promotion",
    //     },
    //   ],
    // },
    {
      title: "Marketing",
      icon: <SvgIcon src="/icons/cs_center_icon.svg" alt="Promotion icon" />,
      home: false,
      children: [
        {
          title: "All Promo",
          path: "/merchant/marketing/promo",
        },
      ],
    },
    {
      title: "Finance",
      icon: <SvgIcon src="/icons/cs_center_icon.svg" alt="Ecommerce icon" />,
      home: false,
      children: [
        {
          title: "My Income ",
          path: "/merchant/finance/my-income",
        },
        {
          title: "My Report",
          path: "/merchant/finance/my-balance",
        },
        {
          title: "My Bank Account",
          path: "/merchant/finance/my-bank-account",
        },
      ],
    },
    // {
    //   title: "Data",
    //   icon: (
    //     <SvgIcon src="/icons/customer_center_icon.svg" alt="Finance icon" />
    //   ),
    //   home: false,
    //   children: [
    //     {
    //       title: "Overview",
    //       path: "/merchant/data/overview",
    //     },
    //     {
    //       title: "Livestream",
    //       path: "/merchant/data/livestream",
    //     },
    //     {
    //       title: "Team Purchase",
    //       path: "/merchant/data/team-purchase",
    //     },
    //   ],
    // },
    // {
    //   title: "Notifications",
    //   icon: <SvgIcon src="/icons/product_icon.svg" alt="product icon" />,
    //   home: true,
    //   path: "/merchant/notifications",
    // },
    //
    {
      title: "Account",
      icon: <SvgIcon src="/icons/cs_center_icon.svg" alt="CS center icon" />,
      home: false,
      children: [
        {
          title: "My Account",
          path: "/merchant/account",
        },
        {
          title: "My Profile",
          path: "/merchant/profile",
        },
      ],
    },

    // {
    //   title: "My Outlets",
    //   icon: <SvgIcon src="/icons/mercant_center_icon.svg" alt="outlet icon" />,
    //   home: false,
    //   path: "/merchant/outlet",
    // },

    {
      title: "Chat",
      icon: <SvgIcon src="/icons/chat_icon.svg" alt="CS center icon" />,
      home: false,
      // path: "/merchant/chat",
      children: [
        {
          title: "All Chat",
          path: "/merchant/chat",
        },
        {
          title: "Chat To Customer",
          path: "/merchant/chat/merchant-to-customer",
        },
        {
          title: "Chat To Admin",
          path: "/merchant/chat/merchant-to-admin",
        },
      ],
    },

    {
      title: "Notification",
      icon: <SvgIcon src="/icons/bell_icon_grey.svg" alt="CS center icon" />,
      home: false,
      path: "/merchant/notification",
    },
    // {
    //   title: "Help Center",
    //   icon: <SvgIcon src="/icons/cs_center_icon.svg" alt="Promotion icon" />,
    //   home: true,
    //   path: "/merchant/help-center",
    // },
  ].filter(Boolean);
};

export default merchantSidebarList;
