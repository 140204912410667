import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,

} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SvgIcon from "../../../../components/Images/SvgIcon";
import SelectInput from "../../../../components/select";
import InputField from "../../../../components/formControl/input/InputField";
import { getAllTimeSlotByDate } from "../../../../redux/actions/marketing.action";
import { stopLoader, startLoader, AllowAccess } from "../../../../lib/global";
import moment from "moment";
import ablity from "../../../../helpers/abilityGuard";

const FlashSaleListing = () => {
    AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
    const dispatch = useDispatch()
    const history  = useHistory()
    const [allTimeSlot, setAllTimeSlot] = useState([])
    const [timeSlotDate, setTimeSlotDate] = useState()
    let filterPayload = {}

    useEffect(() => {
        let filterData
        filterData = {
            timeSlotDate
        }
        apiData(filterData)
    },[timeSlotDate])

    const apiData = (filterData) => {
        startLoader()
        dispatch(getAllTimeSlotByDate(filterData))
          .then((data) => {
              console.log(data);
              setAllTimeSlot(
                data &&
                data.data &&
                data.data.data.map((row, index) => {
                    return {
                        promoSlotId: row?.promoSlotId || "N/A",
                        promoSlot: row?.promoSlot || "N/A",
                        totalMerchant: row?.totalMerchant || 0,
                        totalProduct: row?.totalProduct || 0,
                        totalReminder: row?.totalReminder|| 0,
                        status: row?.status|| "N/A",
                        datePromo: row?.datePromo || "N/A",
                    };
                })
              );

              setTimeout(() => {
                  stopLoader();
              }, 1000);
          })
          .catch((err) => {
              setTimeout(() => {
                  stopLoader();
              }, 1000);
              console.log({ err });
          });
    }

    const getData = (filter) => {
         filterPayload = {
            timeSlotDate: filter
        }
        apiData(filterPayload)
    }

    const viewAction = (promoSlotId, totalMerchant, totalProduct, totalReminder, datePromo) => {
        history.push(`/marketing/all-promo/flash-sale/participate-overview?totalmerchant=${totalMerchant}&totalproduct=${totalProduct}&totalreminder=${totalReminder}&promoslotid=${promoSlotId}&datepromo=${datePromo}`);
    };

    return (
        <React.Fragment>
            <div className=" d-flex flex-column justify-content-between container_body vh-100">
                <div className="container-fluid">
                    <div className="card p-3">
                        <h5>
                            FLASH DEALS LISTING
                        </h5>
                        <div className="card p-3 m-1 border rounded">
                            <div>
                                <h6 className="float-left">ALL FLASH DEALS</h6>
                                <div className="float-right">
                                    <InputField
                                      type="date"
                                      className="form-control"
                                      id="timeSlotDate"
                                      value={timeSlotDate}
                                      onChange ={(e) => getData(e.target.value)}
                                    />
                                </div>
                            </div>

                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                className="th_cell"
                                                component="th"
                                                align="center"
                                            >
                                                <div className="d-flex  ">
                                                    <div>Date & Time</div>
                                                    <div className="d-flex flex-column ">
                                                        <ExpandLessIcon
                                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                                            // onClick={() => getApiData({ item: "title", order: "ASC" })}

                                                        />
                                                        <ExpandMoreIcon
                                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                                            //onClick={() => getApiData({ item: "title", order: "DESC" })}

                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className="th_cell"
                                                component="th"
                                                align="center"
                                            >
                                                <div className="d-flex  ">
                                                    <div>Total Merchant</div>
                                                    <div className="d-flex flex-column ">
                                                        <ExpandLessIcon
                                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                                            //onClick={() => getApiData({ item: "title", order: "ASC" })}

                                                        />
                                                        <ExpandMoreIcon
                                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                                           // onClick={() => getApiData({ item: "title", order: "DESC" })}

                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className="th_cell "
                                                component="th"
                                                align="center"
                                            >
                                                <div className="d-flex  ">
                                                    <div>Total Products</div>
                                                    <div className="d-flex flex-column ">
                                                        <ExpandLessIcon
                                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                                            // onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                                                        />
                                                        <ExpandMoreIcon
                                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                                            // onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className="th_cell "
                                                component="th"
                                                align="center"
                                            >
                                                <div className="d-flex  ">
                                                    <div>Total Reminders Set</div>
                                                    <div className="d-flex flex-column ">
                                                        <ExpandLessIcon
                                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                                            // onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                                                        />
                                                        <ExpandMoreIcon
                                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                                            // onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className="th_cell "
                                                component="th"
                                                align="center"
                                            >
                                                <div className="d-flex  ">
                                                    <div>Status</div>
                                                    <div className="d-flex flex-column ">
                                                        <ExpandLessIcon
                                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                                            // onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                                                        />
                                                        <ExpandMoreIcon
                                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                                            // onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>

                                            <TableCell className="table_action_head text-secondary" component="th">
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {allTimeSlot &&
                                          allTimeSlot.length > 0 &&
                                          allTimeSlot.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell align="center" className="td_cell">
                                                        {moment(row.datePromo).format("DD/MM/YY") } <br/>
                                                        {row?.promoSlot}
                                                    </TableCell>
                                                    <TableCell align="center" className="td_cell">
                                                        {row?.totalMerchant}
                                                    </TableCell>
                                                    <TableCell align="center" className="td_cell">
                                                        {row?.totalProduct}
                                                    </TableCell>

                                                    <TableCell align="center" className="td_cell">
                                                        {row?.totalReminder}
                                                    </TableCell>
                                                    <TableCell align="center" className="td_cell">
                                                        {row?.status}
                                                    </TableCell>


                                                    <TableCell className="d-flex  table_action_item">
                                                        {ablity({model:'Marketing', action:'read'}) == 1 && (
                                                            <IconButton
                                                                // onClick={() => history.push("/marketing/all-promo/flash-sale/participate-overview")}
                                                                className="pointer"
                                                                onClick={() => viewAction(row.promoSlotId, row.totalMerchant, row.totalProduct, row.totalReminder, row.datePromo)}
                                                            >
                                                                <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                                                            </IconButton>
                                                        )}
                                                        {/*<IconButton*/}
                                                        {/*    // onClick={() => history.push(`edit?id=${row.productId}`)}*/}
                                                        {/*    className="pointer"*/}
                                                        {/*// onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}*/}


                                                        {/*>*/}
                                                        {/*    <SvgIcon src="/icons/delete_icon.svg" alt="view icon" />*/}
                                                        {/*</IconButton>*/}


                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={10}
                                component="div"
                                // count={allProductData.length}
                                count={2}
                                rowsPerPage={10}
                                page={0}
                                onPageChange={(e) => {
                                    console.log("page change", e);
                                }}
                            // onRowsPerPageChange={(e)=>{console.log('page change', e)}}
                            />
                        </div>

                    </div>
                </div>

                <footer className="bg-white p-3 ">
                    <div className="d-flex justify-content-end">
                        <button
                            onClick={() => history.push("/marketing/all-promo")}
                            type="button"
                            className="btn btn-outline-dark mx-2"
                        // onClick={back}
                        >
                            Back
                        </button>


                    </div>
                </footer>
            </div>
        </React.Fragment>
    )
}


export default FlashSaleListing;
