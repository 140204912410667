import React, { useEffect, useState } from "react";
import "../../../../../static/css/deleteModal.css";
import { useDispatch } from "react-redux";
import {
  startLoader,
  stopLoader,
} from "../../../../../lib/global";
import Image from "../../../../../components/Images/Image";
import { useHistory } from "react-router-dom";
import {
  getAllProductByMerchantId,
} from "../../../../../redux/actions/marketing.action";


const MerchantFlashSaleDuplicateAddProduct = (props) => {
  const [productData, setProductData] = useState([]);
  const dispatch = useDispatch();
  let arrayProduct = [];

  useEffect(() => {
    apiData(props.merchantId);
  }, []);

  const apiData = (merchantId) => {
    startLoader();
    dispatch(getAllProductByMerchantId(merchantId))
      .then((data) => {
        setProductData(
          data &&
          data.data &&
          data.data.data
            ?.filter((el) => el.statusText == "PUBLISHED")
            .map((row, index) => {
              let addVar = {
                productId: row.productId,
                campaignStock: "",
                unitCapped: "",
                discountPrice: "",
                teamCampaignStock: "",
                teamUnitCapped: "",
                teamDiscountPrice: "",
                isActive: false,
                teamIsActive: false,
              };

              let priceProduct = 0;
              let stockProduct = 0;
              let newVariation = row.variationList?.map((r, i) => {
                stockProduct = stockProduct + parseInt(r.stock);
                priceProduct = parseInt(r.price);
                return Object.assign(r, addVar);
              });
              return {
                productId: row?.productId || "N/A",
                title: row?.title || "N/A",
                productImages: row?.productImages || "N/A",
                variationList: newVariation,
                variation: row?.variation || "N/A",
                stock: stockProduct,
                price: priceProduct,
              };
            })
        );

        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log({ err });
      });
  };

  const handleProductInput = (e, index, productId, data) => {
    if (e.target.checked == true) {
      arrayProduct.push(data);
      let datame = props.allProductData;
      datame.forEach((r, i) => {
        arrayProduct.push(r)
      })

    }
    else {
      arrayProduct.map((row, index) => {
        if (row.productId == productId) {
          arrayProduct.splice(index, 1);
        }
      });
    }
  };

  const confirmAction = () => {
    props.setAllProductData(arrayProduct);
    console.log(props.allProductData, 'bbbb');
  };


  return (
    <React.Fragment>
      <div>
        <div className="d-flex flex-row align-items-center justify-content-between px-3">
          <p className="mb-0">Add Product</p>
          <button
            onClick={props.onClose}
            className="btn fntSz24 text-secondary"
          >
            x
          </button>
        </div>
        <div className="border-top border-bottom py-1">
          <div
            style={{ maxHeight: "35vh", overflow: "auto" }}
            className="fntSz14 position-relative px-2"
          >
            <table className="table border-0">
              <thead className="position-sticky" style={{ top: "0" }}>
              <tr className="bg-light rounded-lg text-secondary">
                <th className="border-0">
                </th>
                <th className="border-0">
                  <div className="d-flex ">
                    <div>Products</div>
                  </div>
                </th>
                <th className="border-0">
                  <div className="d-flex  ">
                    <div>Price</div>
                  </div>
                </th>
                <th className="border-0">
                  <div className="d-flex  ">
                    <div>Stock</div>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
              {productData &&
                productData.length > 0 &&
                productData.map((row, index) => (
                  <tr
                    key={"merchant-flash-sale-add-product-dialog-" + index}
                    style={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <td
                      style={{ textAlign: "end" }}
                      className="align-middle border-0"
                    >
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          handleProductInput(e, index, row.productId, {
                            productId: row.productId,
                            title: row.title,
                            productImages: row.productImages[0],
                            variationList: row.variationList,
                            variation: row.variation,
                          })
                        }
                      />
                    </td>
                    <td className=" align-middle border-0">
                      <div className="d-flex flex-row align-items-center">
                        <Image
                          src={row.productImages[0]}
                          alt={"photo"}
                          className="flash_sale_product_image mr-1"
                        />
                        <div>{row.title}</div>
                      </div>
                    </td>
                    <td className="align-middle border-0">{row.price}</td>
                    <td className="align-middle border-0">{row.stock}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div className="d-flex justify-content-end m-3">
            <button
              onClick={props.onClose}
              type="button"
              className="btn btn-outline-danger p-1 px-3 mx-2"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                props.onClose();
                confirmAction();
              }}
              type="button"
              className="btn btn-danger p-1 px-3 mx-2"
            >
              Confirm
            </button>
          </div>
        </div>

        <style jsx="true">{`
          .card-body {
            min-height: 50vh;
          }
          .submit_btn {
            position: absolute;
            bottom: 15px;
            width: 100%;
            justify-content: center;
            display: flex;
          }
          .close_icon_btn {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
          .flash_sale_product_image {
            width: 45px;
            height: 40px;
            border-radius: 3px;
          }
          input {
            cursor: pointer;
          }
        `}</style>
      </div>
    </React.Fragment>
  );
};

export default MerchantFlashSaleDuplicateAddProduct;
