export const salesChart = {
  series: [
    {
      name: "Sale (₹)",
      data: [8000, 4000, 5600, 10000, 11000, 3000, 2000,
        2000, 4000, 1200, 10000, 11000, 3000, 2000,
        7300, 12000, 9100, 9000, 1000, 2000, 2000,
        8100, 4000, 5600, 10000, 11000, 13000, 2000,
        0, 0
      ],
    },
  ],
  categories: [
    "01 April 2021",
    "02 April 2021",
    "03 April 2021",
    "04 April 2021",
    "05 April 2021",
    "06 April 2021",
    "07 April 2021",
    "08 April 2021",
    "09 April 2021",
    "10 April 2021",
    "11 April 2021",
    "12 April 2021",
    "13 April 2021",
    "14 April 2021",
    "15 April 2021",
    "16 April 2021",
    "17 April 2021",
    "18 April 2021",
    "19 April 2021",
    "20 April 2021",
    "21 April 2021",
    "22 April 2021",
    "23 April 2021",
    "24 April 2021",
    "25 April 2021",
    "26 April 2021",
    "27 April 2021",
    "28 April 2021",
    "29 April 2021",
    "30 April 2021",
  ],
};


 // "10:59pm",
    // "11:59pm",
    // // "12:59am",
    // // "1:59am",
    // // "2:59am",
    // // "3:59am",
    // // "4:59am",
    // // "5:59am",
    // // "6:59am",
    // // "7:59am",
    // // "8:59am",
    // // "9:59am",