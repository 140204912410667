import React, { PureComponent } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom"; // import GalleryComponent from '../../components/gallery'
import Layout from "../components/Layout";
import { getLocalStorage } from "../lib/session";
import { PrivateRoutes } from "../routes";
import jwt_decode from "jwt-decode";

const Homepage = (props) => {
  const PrivateRoute = (props) => {
    const token = getLocalStorage("token");
    let currentDate = new Date();
    let exp;
    if (token) {
      const decoded = jwt_decode(`${token}`);
      exp = decoded.exp;
    }

    // console.log("expireddddd ===", exp * 1000);
    // console.log("currentDate ===", currentDate.getTime());

    if (!getLocalStorage("token") || exp * 1000 < currentDate.getTime()) {
      return <Redirect to={{ pathname: "/auth" }} />;
    }
    return <Route exact {...props} />;
  };

  return (
    <React.Fragment>
      {/* <Router> */}
      {/* <Layout> */}
      <Switch>
        {PrivateRoutes.map(({ component: Cmp, ...route }, index) => (
          <PrivateRoute
            exact
            key={"privateRoute" + index}
            {...route}
            render={(routeProps) => <Cmp {...props} {...routeProps} />}
          />
        ))}
      </Switch>
      {/* </Layout> */}
      {/* </Router> */}
    </React.Fragment>
  );
};

export default Homepage;
