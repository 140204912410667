import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InputField from "../../../components/formControl/input/InputField";
import SelectInput from "../../../components/select";
import {
  assignRole, getAllRoleUser,
  getAllRoleUserById,
  UpdateUserRoleByRoleId,
  getRole
} from "../../../redux/actions/user.role.action";

import { AllowAccess, showToast, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";

const initialUserDetail = {
  username: "",
  email: "",
  password: "",
  phone: "",
};

const supportType = [
  {
    label: "mainsupport",
    value: "mainsupport",
  },
  {
    label: "subsupport",
    value: "subsupport",
  }
];

const viewerType = [
  {
    label: "mainviewer",
    value: "mainviewer",
  },
  {
    label: "subviewer",
    value: "subviewer",
  }
];

const EditUserRole = () => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState(initialUserDetail);
  const [role, setRole] = useState([]);
  const [roleType,setRoleType]=useState(false)
  const [roleTypeStatus,setRoleTypeStatus]=useState(false)
  const [basicInformation, setBasicInformation] = useState({
    role: null,
    roleType: null
  });
  let { userId } = useParams();



  useEffect( () => {
    const breadcrumb = [
      { label: "User Account", url: "/user-Account" },
      { label: "Edit User Account", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));

    if(userId) {
      dispatch(getAllRoleUserById(userId))
        .then((data) => {
          let {
            username,
            role,
            email,
            phone,
            roleType
          } = data.data.data;

          setUserDetails({
            username,
            //role,
            email,
            phone,
            password: ""
          });


          if (role.name == "SUPPORT" || role.name == "VIEWER") {
            setRoleTypeStatus(true)
          }

          setBasicInformation({
            role: {
              label: role.name,
              value: role.roleId,
              name: role.name
            },
            roleType: {
              label: roleType,
              value: roleType
            }
          })

          setTimeout(() => {
            stopLoader();
          }, 1000);

        })
        .catch((error) => {
          setTimeout(() => {
            stopLoader();
          }, 1000);
          console.log(error);
        });
    }

    let roleList = dispatch(getRole());
    roleList.then((data) => {
      if (data && data.data && data.data.status === 200) {
        const dataRole = data.data.data.map((item) => ({
          roleId: item.roleId,
          name: item.name,
          label: item.name,
          value: item.roleId,
        }));
        setRole(dataRole);
        setTimeout(() => {
          stopLoader();
        }, 1000);
      } else {
        setTimeout(() => {
          stopLoader();
        }, 1000);
      }
    })
  }, []);



  const onChangeBasicInfo = (e, opt, val) => {
    // console.log(val);
    if(val.name == "SUPPORT" || val.name == "VIEWER"){
      setRoleTypeStatus(true)
      setBasicInformation({roleType: null})
    }
    else{
      setRoleTypeStatus(false)
    }
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setBasicInformation((pre) => ({ ...pre, [key]: value }));
  };

  const onChangeBasicInfoType = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setBasicInformation((pre) => ({ ...pre, [key]: value }));
  };

  const handlePermissions = (e, mathod, type) => {
    if (e.target.checked) {
      permissions[type].push(mathod);
      setPermissions({ ...permissions, [type]: permissions[type] });
    } else {
      setPermissions({
        ...permissions,
        [type]: permissions[type].filter((item) => item !== mathod),
      });
    }
  };

  const onChangeUserDetail = (e, title, val) => {
    const key = title || e.target.id;
    const value = val || e.target.value;
    setUserDetails((pre) => ({ ...pre, [key]: value }));
  };

  const validateData = () => {
    let error = false;
    Object.keys(userDetails).map((item, index) => {
      if (item !== "password" && !userDetails[item]) {
        showToast(`${item} should not be empty`, "error", 5000);
        error = true;
      }
    });

    // Object.keys(userDetails).map((item, index) => {
    //   if (!userDetails[item]) {
    //     showToast(`${item} should not to be empty`, "error",5000);
    //     error = true;
    //   }
    // });

    // if (!basicInformation.role?.value) {
    //   showToast(`Assign a role to user`, "error",5000);
    //   error = true;
    // }
    return error;
  };

  const editUser = () => {
    if (validateData()) {
      return;
    }
    const data = {
      ...userDetails,
      roleId: String(basicInformation.role?.value),
      roleType: basicInformation.roleType.value?basicInformation.roleType?.value:null
      // assignMethod: permissions,
    };
    const response =  dispatch(UpdateUserRoleByRoleId(userId, data));
    response
      .then((data) => {
        history.push("/user-Account");
        showToast(
          userId ? "User update successfully" : "User created successfully",
          "success"
        );
      })
      .catch((err) => {
        showToast(err.response.data.message, "error");
      });
  };


  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-between flex-column h-100">
        <div className="mr-3 ml-4 mt-3 rounded  px-3 pt-4 pb-2 bg-white">
          <small>EDIT USER ROLE</small>
          <form className="mt-3  p-0">
            <div className="col-12 row mb-2">
              <label
                className="col-3 text-right m-0 p-0 small imp"
              >
                Name
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                placeholder="Enter name"
                id="username"
                value={userDetails.username}
                onChange={onChangeUserDetail}
                required={true}
              />
            </div>

            <div className="col-12 py-3 row">
              <label
                htmlFor="Role"
                className="col-3 my-auto text-right small imp"
              >
                Role
              </label>
              <div className="col-9">
                <SelectInput
                  options={role}
                  name="role"
                  value={basicInformation.role}
                  onChange={(e) => onChangeBasicInfo(e, "role", e)}
                  required={false}
                  placeholder="Select Role"
                />
              </div>
            </div>

            { roleTypeStatus == true && (
              <div className="col-12 py-3 row">
                <label
                  htmlFor="Role"
                  className="col-3 my-auto text-right small imp"
                >
                  Role Type
                </label>
                <div className="col-9">
                  <SelectInput
                    options={basicInformation.role && basicInformation.role.name == "SUPPORT"?supportType:viewerType}
                    name="roleType"
                    value={basicInformation.roleType}
                    onChange={(e) => onChangeBasicInfoType(e, "roleType", e)}
                    required={true}
                    // formSubmitted={false}
                    placeholder="Select Role Type"
                  />
                </div>
              </div>
            )}

            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Email
              </label>
              <InputField
                className=""
                formClass="col-9"
                id="email"
                type="text"
                placeholder="Enter email"
                value={userDetails.email}
                onChange={onChangeUserDetail}
                required={true}
              />
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Phone
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                id="phone"
                placeholder="Enter phone"
                value={userDetails.phone}
                onChange={onChangeUserDetail}
                required={true}
              />
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Password
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                id="password"
                placeholder="Enter password"
                value={userDetails.password}
                onChange={onChangeUserDetail}
                required={false}
              />
            </div>
          </form>
        </div>
        <br />



        <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
          <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
            <button
              onClick={history.goBack}
              type="button"
              className="btn border input-inside-text mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-dark btn-sm mr-2"
              onClick={() => editUser()}
            >
              Save
            </button>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default EditUserRole;
