import {
  GET_ONE_PRODUCT,
  MERCHANTS_LISTING,
  CATEGORY_LISTING,
  BRANDS_LISTING,
} from "../types/productTypes";

const initialState = {
  product: {},
  merchants: [],
  categories: [],
  brands: [],
};

export const productReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ONE_PRODUCT:
      return {
        ...state,
        product: payload,
      };
    case MERCHANTS_LISTING:
      return {
        ...state,
        merchants: payload,
      };
    case CATEGORY_LISTING:
      return {
        ...state,
        categories: payload,
      };
    case BRANDS_LISTING:
      return {
        ...state,
        brands: payload,
      };
    default:
      return state;
  }
};
