import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

/**
 * @description use this comp for group radio buttons 
 * @author jagannath
 * @date 18/01/2022
 * @param props - [options:[],labelPlacement, name, value, onChange(), color, size ]
 */
export default function RadioButtonsGroup(props) {
  const {
    options=[],
    labelPlacement="right",
    name,
    value,
    onChange,
    color,
    size,
    labelClass,
    className
  } = props
  return (
    <FormControl className={className} component="fieldset">
      <RadioGroup 
        row 
        aria-label={name}
        onChange={onChange}
        value={value}
         name="row-radio-buttons-group">
        {options.map((item, index)=>(
          <FormControlLabel 
            key={index} 
            value={item.value} 
            control={<Radio size={size || 'small'} style={{color: color || "#000"}} />} 
            label={item.label}
            className={labelClass || ""}
            disabled={item.disabled || false}
            labelPlacement={labelPlacement}
            />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
