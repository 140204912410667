import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TimelineDot from "@mui/lab/TimelineDot";
import { makeStyles } from "@mui/styles";
import SelectDropdown from "../../../components/select";

import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../../components/Images/Image";
import { orderStatuses } from "../../../shared/language";
import {
  getOrderById,
  getTrackingStatus,
  getLogisticDetail,
  updateOrderShippingStatusById,
  updateOrderStatus,
  updateOrderShippingStatusByIdByButton,
  PrintAirwayBillByAwbNo,
} from "../../../redux/actions/order.action";
import { useDispatch } from "react-redux";
import { SHOW_TRANSACTION_HISTORY } from "../../../lib/constant";
import {
  open_dialog,
  startLoader,
  stopLoader,
  showToast,
  AllowAccess,
} from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getLocalStorage } from "../../../lib/session";
import moment from "moment";
import CustomizedSteppers from "../../../components/stepper/stepper";
import SvgIcon from "../../../components/Images/SvgIcon";
import decimalFormat from "../../../helpers/decimal.format";
import { getOrderByIdByMerchant } from "../../../redux/actions/merchant/order.action";

const useStyles = makeStyles({
  timelineitem: {
    // minHeight:"0px"
  },
  timelineContentOppositeContainer: {
    maxWidth: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  timing: {
    marginTop: "-5px",
    fontSize: "0.7rem",
  },
  timelineContent: {
    display: "inline-block",
    transform: "rotate(-90deg)",
    textAlign: "center",
    minWidth: 50,
  },
  timelineIcon: {
    transform: "rotate(-90deg)",
  },
  mainText: {
    fontSize: "0.9rem",
  },
  dot: {
    padding: "2px  !important",

    margin: "8.5px 4px !important",
  },
});

const order_status_names = {
  orderPlaced: "Order Placed",
  orderPaid: "Order Paid",
  prepairingToShipped: "Preparing To Ship",
  orderShipped: "Order Shipped",
  orderDelivered: "Order Delivered",
};

const orderStatusOptions_NonPhysicalProduct = ["ACTIVE", "EXPIRED", "REDEEMED"];

const orderStatusOptions_PhysicalProduct = [
  "PENDINGPAYMENT",
  "TOSHIP",
  "SHIPPING",
  "DELIVERED",
  "COMPLETED",
  "CANCELLED",
];

const orderStatusOptions_PhysicalProductTeam = [
  "PENDINGPAYMENT",
  "TOSHIP",
  "SHIPPING",
  "DELIVERED",
  "COMPLETED",
];

function ViewOrder() {
  AllowAccess(["SUPERADMIN","MERCHANT","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const [fullData, setFullData] = useState({});
  const [orderShippingStatus, setOrderShippingStatus] = useState("");
  const [editOrderShipped, setEditOrderShipped] = useState(false);
  const [timeline, setTimeline] = useState([]);
  const [lastOrderUpdate, setLastOrderUpdate] = useState({});
  const [logistic, setLogistic] = useState({});

  const [showStatusChange, setShowStatusChange] = useState(false);
  const changedStatus = useRef();
  const [submitStatusChange, setSubmitStatusChange] = useState(false);
  const [commissionFee, setCommissionFee] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();

  const { id } = useParams();
  const param = useParams();

  const queryParams = new URLSearchParams(window.location.search);
  const productId = queryParams.get("productId");
  const role = getLocalStorage("role");
  const storeId = role == "MERCHANT" ? getLocalStorage("storeId") : null;

  useEffect(() => {
    var breadcrumb = [];
    if (param?.merchantId && param?.merchantName) {
      breadcrumb = [
        { label: "Merchant Profile", url: "/merchant-center/profiles" },
        {
          label: param.merchantName,
          url: `/merchant-center/profiles/${param.merchantId}/${param.merchantName}`,
        },
        { label: `Order ID ${id}`, url: null },
      ];
    } else if (param?.storeId && getLocalStorage("role") == "MERCHANT") {
      breadcrumb = [
        { label: "My Income", url: null },
        { label: `View Order`, url: null },
      ];
    } else {
      const url =
        getLocalStorage("role") == "MERCHANT"
          ? "/merchant/orders/all-orders"
          : "/orders/all-orders";
      breadcrumb = [
        { label: "Orders", url },
        { label: `Order ID ${id}`, url: null },
      ];
    }

    dispatch(breadcrumbAction(breadcrumb));
    dispatch(getTrackingStatus({ orderId: "2011120003106484" }))
      .then((data) => {})
      .catch((err) => {});
    dispatch(getLogisticDetail(id))
      .then((data) => {
        setLogistic(data.data.data);
      })
      .catch((err) => {});

    if (id) {
      startLoader();
      const response =
        getLocalStorage("role") == "SUPERADMIN"
          ? dispatch(getOrderById(id, { productId }))
          : dispatch(getOrderByIdByMerchant(id, { productId }));
      response
        .then((data) => {
          if (data && data.data && data.data.data) {
            if (
              role == "MERCHANT" &&
              data?.data?.data.orderDetails.storeId != storeId
            ) {
              history.push("/404");
            }
            let orderDetails = { ...data?.data?.data?.orderDetails };
            let trackingDetails = data?.data?.data?.trackingDetails;
            setFullData({ ...orderDetails, trackingDetails });
            if(orderDetails.commisionRate){
              setCommissionFee(orderDetails.commisionRate.toFixed(2) || 0);
            }else{
              orderDetails.product.productType == "physical"
              ? setCommissionFee(
                  orderDetails.merchant.physicalCommission.toFixed(2) || 0
                )
              : setCommissionFee(
                  orderDetails.merchant.nonPhysicalCommission.toFixed(2) || 0
                );
            }

            setTimeline(
              data?.data?.data?.shippingStatus?.map((item) => item.date)
            );
            const latest = data?.data?.data?.shippingStatus
              ?.filter((item) => item.date !== null)
              .splice(-1)[0];

            setLastOrderUpdate(latest);
            setOrderShippingStatus({
              value: latest?.status,
              label: order_status_names[latest?.status],
            });
            setTimeout(() => {
              stopLoader();
            }, 1000);
          }
        })
        .catch((error) => {
          setTimeout(() => {
            stopLoader();
          }, 1000);
        });
    }
  }, [id]);

  const updateOrderStatusFun = () => {
    let orderType =
      fullData?.status == "CANCELLED"
        ? "cancelled"
        : fullData?.product?.productType;
    let status = changedStatus?.current;

    dispatch(updateOrderStatus(id, orderType, status))
      .then((data) => {
        if (id) {
          startLoader();
          dispatch(getOrderById(id, { productId }))
            .then((data) => {
              if (data && data.data && data.data.data) {
                let orderDetails = { ...data?.data?.data?.orderDetails };
                let trackingDetails = data?.data?.data?.trackingDetails;
                setFullData({ ...orderDetails, trackingDetails });
                setTimeline(
                  data?.data?.data?.shippingStatus?.map((item) => item.date)
                );
                const latest = data?.data?.data?.shippingStatus
                  ?.filter((item) => item.date !== null)
                  .splice(-1)[0];
                setLastOrderUpdate(latest);
                setOrderShippingStatus({
                  value: latest?.status,
                  label: order_status_names[latest?.status],
                });
                setTimeout(() => {
                  stopLoader();
                }, 1000);
              }
            })
            .catch((error) => {
              setTimeout(() => {
                showToast(error.message, "error");
                stopLoader();
              }, 1000);
            });
        }
        setTimeout(() => {
          showToast(data.data.message, "success");
        }, 1500);
      })
      .catch((error) => {
        setTimeout(() => {
          showToast(error.message, "error");
          stopLoader();
        }, 1000);
      });
  };

  const handleGoPrintAirwayBill = (billCode) => {
    dispatch(PrintAirwayBillByAwbNo(billCode))
      .then((data) => {
        if (data && data.data) {
          setTimeout(() => {
            window.open(`${data.data.waybillUrl}`);
          }, 500);
        }
      })
      .catch((error) => {});
  };

  const changeShippingStatusFun = async () => {
    const data = {
      shippingStatus: orderShippingStatus.value,
    };
    dispatch(updateOrderShippingStatusById(id, data))
      .then((data) => {
        dispatch(getOrderById(id))
          .then((data) => {
            if (data && data.data && data.data.data) {
              let orderDetails = { ...data?.data?.data?.orderDetails };
              setFullData({ ...data?.data?.data?.orderDetails });
              setTimeline(
                data?.data?.data?.shippingStatus?.map((item) => item.date)
              );
              const latest = data.data.data.shippingStatus
                .filter((item) => item.date !== null)
                .splice(-1)[0];
              setLastOrderUpdate(latest);
              setOrderShippingStatus({
                value: latest.status,
                label: order_status_names[latest.status],
              });
            }
          })
          .catch((error) => {});
      })
      .catch((error) => {});

    setEditOrderShipped(false);
  };

  const shippedUpdateStatus = () => {
    const data = { status: "SHIPPING" };
    startLoader();
    dispatch(updateOrderShippingStatusByIdByButton(id, data))
      .then((data) => {
        if (id) {
          dispatch(getOrderById(id, { productId }))
            .then((data) => {
              if (data && data.data && data.data.data) {
                let orderDetails = { ...data?.data?.data?.orderDetails };
                let trackingDetails = data?.data?.data?.trackingDetails;
                setFullData({ ...orderDetails, trackingDetails });
                setTimeline(
                  data?.data?.data?.shippingStatus?.map((item) => item.date)
                );
                const latest = data?.data?.data?.shippingStatus
                  ?.filter((item) => item.date !== null)
                  .splice(-1)[0];
                setLastOrderUpdate(latest);
                setOrderShippingStatus({
                  value: latest?.status,
                  label: order_status_names[latest?.status],
                });
              }
            })
            .catch((error) => {
              setTimeout(() => {
                showToast(error.message, "error");
                stopLoader();
              }, 1000);
            });
        }
        setTimeout(() => {
          stopLoader();
          showToast(data.data.message, "success");
        }, 1500);
      })
      .catch((error) => {
        setTimeout(() => {
          showToast(error.message, "error");
          stopLoader();
        }, 1000);
      });
  };

  const handleShowTrasactionHistory = () => {
    open_dialog(SHOW_TRANSACTION_HISTORY, {
      id,
      fullData,
      commissionFee,
    });
  };

  const updateAfterUpdateStatus = () => {
    if (fullData?.product?.productType == "physical") {
      setFullData({ ...fullData, status: changedStatus?.current });
    } else {
      setFullData({ ...fullData, NpStatus: changedStatus?.current });
    }
  };

  const editStatusSave = () => {
    setShowStatusChange(false);
    setSubmitStatusChange(true);
    updateOrderStatusFun();
    // updateAfterUpdateStatus();
  };

  const unitPrice = () => {
    return fullData.unitPrice || fullData?.price/fullData?.quantity || 0;
  };

  const subTotal = () => {
    return fullData.unitPrice? unitPrice() * (fullData?.quantity || 1) : Number(fullData.price || 0).toFixed(2)
  };

  const uu2Voucher = () => {
    if (
      fullData?.voucherUsage &&
      fullData?.voucherUsage?.voucherType == "uu2" &&
      fullData?.voucherUsage?.voucher
    ) {
      return fullData?.voucherUsage?.amount || 0;
    } else {
      return 0;
    }
  };

  const uu2VoucherCode = () => {
    if (
      fullData?.voucherUsage &&
      fullData?.voucherUsage?.voucherType == "uu2" &&
      fullData?.voucherUsage?.voucher
    ) {
      return "(" + (fullData?.voucherUsage?.voucher?.code || "") + ")";
    } else {
      return "";
    }
  };

  const merchantVoucher = () => {
    if (
      fullData?.voucherUsage &&
      fullData?.voucherUsage?.voucherType == "merchant" &&
      fullData?.voucherUsage?.voucher
    ) {
      return fullData?.voucherUsage?.amount || 0;
    } else {
      return 0;
    }
  };

  const merchantVoucherCode = () => {
    if (
      fullData?.voucherUsage &&
      fullData?.voucherUsage?.voucherType == "merchant" &&
      fullData?.voucherUsage?.voucher
    ) {
      return "(" + (fullData?.voucherUsage?.voucher?.code || "") + ")";
    } else {
      return "";
    }
  };

  const creditCardPromotion = () => {
    return fullData?.shippingDetails?.creaditDebitCardVoucher || 0;
  };

  const totalDiscount = () => {
    return uu2Voucher() + merchantVoucher() + creditCardPromotion();
  };

  const shippingCharge = () => {
    return Number.parseFloat(
      fullData?.shippingDetails?.shippingCharge ||
        fullData?.trackingInfo?.price ||
        0
    );
  };
  const buyerPaid = () => {
    return Number.parseFloat(fullData.finalAmount || 0);
  };

  const feesAndCharge = () => {
    // return (((buyerPaid() - shippingCharge())*4.6)/100) + ((buyerPaid() * 2.12)/100)
    // IPay88 commision
    let charge = (buyerPaid() * 2.12)/100;
    // UU2 Commision
    let fee = (((subTotal() - merchantVoucher()) * commissionFee)/100);
    return (Number(charge)+Number(fee))
  }

  const merchantIncome = () => {
    return subTotal() - (feesAndCharge()+merchantVoucher());
  };

  const merchantdiseSubTotal = () => {
    return subTotal() - totalDiscount();
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className="d-flex  position-relative  mx-4 ">
        <div className="col-8 pr-3">
          <div className="py-3">
            <div className="d-flex box justify-content-between  mb-2">
              <div>ORDER INFORMATION</div>
              <div
                className="d-flex align-items-center "
                role="button"
                onClick={() => handleShowTrasactionHistory()}
              >
                <Image src="/trans.png" alt="image" width={18} height={18} />
                <div className="pl-2">Transaction History</div>
              </div>
            </div>
            <div className="card p-3">
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        Product
                      </TableCell>
                      <TableCell className="th_cell" component="th"></TableCell>
                      <TableCell className="th_cell" component="th"></TableCell>
                      <TableCell className="th_cell" component="th"></TableCell>
                      <TableCell className="th_cell" component="th"></TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="right"
                      >
                        Price
                      </TableCell>
                      <TableCell
                        className="th_cell "
                        component="th"
                        align="right"
                      >
                        Quantity
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="right"
                      >
                        <div>Subtotal</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {[1].map((row, index) => ( */}
                    <TableRow
                      // key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="left"
                        colSpan={5}
                      >
                        <div className="d-flex">
                          <Image
                            // src="/images/shoes.jpeg"
                            src={fullData?.productImages || ""}
                            className="order_page_product_image"
                            alt="img"
                          />
                          <div className="px-2">
                            <div className="capitalize">
                              {fullData?.productName || "N/A"}
                            </div>
                            <div className="text-muted text-capitalize">
                              {`variation: ${
                                fullData?.variation?.[0]?.value || "N/A"
                              }`}

                              {/* white */}
                            </div>
                          </div>
                        </div>
                      </TableCell>

                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                      >
                        {`${fullData?.currencyName || "RM"}${decimalFormat(
                          unitPrice()
                        )}`}
                      </TableCell>
                      <TableCell
                        className="td_cell "
                        component="td"
                        align="right"
                      >
                        {fullData?.quantity || 0}
                      </TableCell>
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                      >
                        {`${fullData?.currencyName || "RM"}${decimalFormat(
                          unitPrice() * (fullData?.quantity || 1)
                        )}`}
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                  <TableRow
                    sx={{
                      " td, th": {
                        border: 0,
                        "padding-top": 0,
                        "padding-bottom": 0,
                        "font-size": "1rem",
                        "font-weight": "700",
                      },
                    }}
                  >
                    <TableCell
                      className="td_cell"
                      component="td"
                      align="right"
                      colSpan={8}
                    >
                      Total
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      " td, th": { border: 0, "padding-top": 1 },
                    }}
                  >
                    <TableCell
                      className="td_cell"
                      component="td"
                      align="right"
                      colSpan={7}
                    >
                      <div>Admin Discount :</div>
                      <div>Merchant Discount :</div>
                      <div>Subtotal :</div>
                      <div>Shipping :</div>
                      <div>Fee & Charges :</div>
                      <div className="py-3">Merchant Income :</div>
                    </TableCell>
                    <TableCell className="td_cell" component="td" align="right">
                      <div>
                        {`${fullData?.currencyName || "RM"}${decimalFormat(
                          uu2Voucher()
                        )} ${uu2VoucherCode()}`}
                      </div>
                      <div>
                        {`${fullData?.currencyName || "RM"}${decimalFormat(
                          merchantVoucher()
                        )} ${merchantVoucherCode()}`}
                      </div>
                      <div>
                        {`${fullData?.currencyName || "RM"}${decimalFormat(
                          subTotal()
                        )}`}
                      </div>
                      <div>
                        {`${fullData?.currencyName || "RM"}${decimalFormat(
                          shippingCharge()
                        )}`}
                      </div>
                      <div>
                        {`${fullData?.currencyName || "RM"}${decimalFormat(
                          feesAndCharge()
                        )}`}
                      </div>
                      <div
                        className="py-3 text-success "
                        style={{ fontSize: "1.2rem" }}
                      >
                        {`${fullData?.currencyName || "RM"}${decimalFormat(
                          merchantIncome()
                        )}`}
                      </div>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </div>
          </div>

          {param?.storeId && getLocalStorage("role") == "MERCHANT" ? (
            ""
          ) : (
            <div
              className="py-2"
              //   style={{
              //     visibility:
              //       param?.storeId && getLocalStorage("role") == "MERCHANT"
              //         ? "hidden"
              //         : "visible",
              //   }}
            >
              <div className="d-flex box justify-content-between mb-2">
                <div>BUYER PAYMENT</div>
              </div>
              <div className="card p-3">
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="th_cell"
                          component="th"
                          align="right"
                          colSpan={8}
                        >
                          <div>Subtotal</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          " td, th": {
                            border: 0,
                            "padding-top": "1rem",
                            width: "8rem",
                          },
                        }}
                      >
                        <TableCell
                          className="td_cell"
                          component="td"
                          align="right"
                          colSpan={5}
                        >
                          <div>Merchandise Subtotal :</div>
                          <div>Shipping :</div>
                          <div>UU2 Voucher :</div>
                          <div>Seller Voucher :</div>
                          <div>Credit/Debit Card Promotion :</div>
                          <div className="py-3">Buyer Paid :</div>
                        </TableCell>
                        <TableCell
                          className="td_cell"
                          component="td"
                          align="right"
                        >
                          <div>
                            {`${fullData?.currencyName || "RM"}${decimalFormat(
                              merchantdiseSubTotal()
                            )}`}
                          </div>
                          <div>
                            {`${fullData?.currencyName || "RM"}${decimalFormat(
                              shippingCharge()
                            )}`}{" "}
                          </div>
                          <div>
                            {`${fullData?.currencyName || "RM"}${decimalFormat(
                              uu2Voucher()
                            )}`}{" "}
                          </div>
                          <div className="text-muted">
                            {`${fullData?.currencyName || "RM"}${decimalFormat(
                              merchantVoucher()
                            )}`}{" "}
                          </div>
                          <div className="text-muted">
                            {`${fullData?.currencyName || "RM"}${decimalFormat(
                              creditCardPromotion()
                            )}`}
                          </div>
                          <div className="py-3" style={{ fontSize: "1.2rem" }}>
                            {`${fullData?.currencyName || "RM"}${decimalFormat(
                              buyerPaid()
                            )}`}
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}
          {fullData?.product?.productType == "physical" ? (
            <div className="py-3">
              <div className="d-flex box justify-content-between mb-2">
                LOGISTIC INFORMATION
              </div>
              <div className="card py-3 ">
                <div className="d-flex position-relative">
                  <div className="col-11 d-flex flex-column justify-content-center  align-items-center">
                    <div
                      style={{ fontSize: "1.4rem", fontWeight: "700" }}
                      className="text-success"
                    >
                      {/* {order_status_names[lastOrderUpdate?.status] || "- - -"} */}
                      {fullData.status || "- - -"}
                    </div>
                    <div>
                      {lastOrderUpdate?.date? moment(lastOrderUpdate?.date).format(
                        "dddd MM-DD-YYYY "
                      ) +
                        " at " +
                        moment(lastOrderUpdate?.date).format("hh:mma"):"N/A"}
                    </div>
                  </div>
                  {/*<div className="d-flex mb-3 position-absolute r-0  mr-1 top-0">*/}
                  {/*  {!editOrderShipped ? (*/}
                  {/*    getLocalStorage("role") == "MERCHANT" ? (*/}
                  {/*      ""*/}
                  {/*    ) : (*/}
                  {/*      <button*/}
                  {/*        className=" btn border input-inside-text  "*/}
                  {/*        onClick={() => setEditOrderShipped(true)}*/}
                  {/*      >*/}
                  {/*        Edit*/}
                  {/*      </button>*/}
                  {/*    )*/}
                  {/*  ) : (*/}
                  {/*    <div className="mr-2">*/}
                  {/*      <button*/}
                  {/*        className=" btn border input-inside-text mr-1 "*/}
                  {/*        onClick={() => setEditOrderShipped(false)}*/}
                  {/*      >*/}
                  {/*        Cancel*/}
                  {/*      </button>*/}
                  {/*      <button*/}
                  {/*        className=" btn border btn-dark input-inside-text "*/}
                  {/*        // onClick={() => setEditOrderShipped(false)}*/}
                  {/*        onClick={() => changeShippingStatusFun()}*/}
                  {/*        visible={String(editOrderShipped)}*/}
                  {/*      >*/}
                  {/*        save*/}
                  {/*      </button>*/}
                  {/*    </div>*/}
                  {/*  )}*/}
                  {/*</div>*/}
                </div>
                <br />
                <CustomizedSteppers timeline={logistic} />
                <div
                  className="d-flex justify-content-center "
                  visible={String(editOrderShipped)}
                >
                  {/*<label className={`col-3 text-center m-0 px-2 py-3 ${classes.mainText}`}>*/}
                  {/*  Logistic Status*/}
                  {/*</label>*/}
                  {/*<div className=" mt-2 col-9 form-group">*/}
                  {/*  <SelectDropdown*/}
                  {/*    options={orderStatuses}*/}
                  {/*    placeholder="Select status"*/}
                  {/*    changedStatus={orderShippingStatus}*/}
                  {/*    onChange={setOrderShippingStatus}*/}
                  {/*  />*/}
                  {/*  <div className={`text-muted ${classes.timing} pt-1`}>*/}
                  {/*    *Please note that you are only allowed to edit status if*/}
                  {/*    only logistic partner unable to update the status or*/}
                  {/*    system error*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
                <hr className="mx-3" />
                <div className="ml-4">
                  {/*<div className="d-flex">*/}
                  {/*  <div className="mx-2">*/}
                  {/*    <TimelineDot className={`text-muted ${classes.dot}`} />*/}
                  {/*  </div>*/}
                  {/*  <div>*/}
                  {/*    <div className={`text-success ${classes.mainText}`}>*/}
                  {/*      Seller is preparing to ship your parcel*/}
                  {/*    </div>*/}
                  {/*    <div className={`text-muted ${classes.timing}`}>*/}
                  {/*      02/03/2022 14:00pm*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<br />*/}
                  {/*<div className="d-flex">*/}
                  {/*  <div className="mx-2">*/}
                  {/*    <TimelineDot className={`text-muted ${classes.dot}`} />*/}
                  {/*  </div>*/}
                  {/*  <div>*/}
                  {/*    <div className={`text-success ${classes.mainText}`}>*/}
                  {/*      Parcel has been picked up by courie*/}
                  {/*    </div>*/}
                  {/*    <div className={`text-muted ${classes.timing}`}>*/}
                  {/*      02/03/2022 14:00pm*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<br />*/}
                  {/*<div className="d-flex">*/}
                  {/*  <div className="mx-2">*/}
                  {/*    <TimelineDot className={`text-muted ${classes.dot}`} />*/}
                  {/*  </div>*/}
                  {/*  <div>*/}
                  {/*    <div className={`text-success ${classes.mainText}`}>*/}
                  {/*      Parcel has departed from station*/}
                  {/*    </div>*/}
                  {/*    <div className={`text-muted ${classes.timing}`}>*/}
                  {/*      02/03/2022 14:00pm*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<br />*/}
                  {/*<div className="d-flex">*/}
                  {/*  <div className="mx-2">*/}
                  {/*    <TimelineDot className={`text-muted ${classes.dot}`} />*/}
                  {/*  </div>*/}
                  {/*  <div>*/}
                  {/*    <div className={`text-success ${classes.mainText}`}>*/}
                  {/*      Parcel has arrived at Station: PCH Hub*/}
                  {/*    </div>*/}
                  {/*    <div className={`text-muted ${classes.timing}`}>*/}
                  {/*      02/03/2022 14:00pm*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<br />*/}
                  {/*<div className="d-flex">*/}
                  {/*  <div className="mx-2">*/}
                  {/*    <TimelineDot className={`text-muted ${classes.dot}`} />*/}
                  {/*  </div>*/}
                  {/*  <div>*/}
                  {/*    <div className={`text-success ${classes.mainText}`}>*/}
                  {/*      Parcel is out for delivery*/}
                  {/*    </div>*/}
                  {/*    <div className={`text-muted ${classes.timing}`}>*/}
                  {/*      02/03/2022 14:00pm*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                  {/*<br />*/}
                  {/*<div className="d-flex">*/}
                  {/*  <div className="mx-2">*/}
                  {/*    <TimelineDot className={`text-muted ${classes.dot}`} />*/}
                  {/*  </div>*/}
                  {/*  <div>*/}
                  {/*    <div className={`text-success ${classes.mainText}`}>*/}
                  {/*      Parcel has been delivered*/}
                  {/*    </div>*/}
                  {/*    <div className={`text-muted ${classes.timing}`}>*/}
                  {/*      02/03/2022 14:00pm*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <div className="d-flex">
                    <div className="mx-2">
                      <TimelineDot className={`text-muted ${classes.dot}`} />
                    </div>
                    <div>
                      <div className={`text-success ${classes.mainText}`}>
                        Preparing To Ship
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        {logistic.paidDate
                          ? moment(logistic.paidDate).format(
                              "dddd MM-DD-YYYY "
                            ) +
                            " " +
                            moment(logistic.paidDate).format("hh:mma")
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex">
                    <div className="mx-2">
                      <TimelineDot className={`text-muted ${classes.dot}`} />
                    </div>
                    <div>
                      <div className={`text-success ${classes.mainText}`}>
                        Pickup
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        {logistic.pickupDate
                          ? moment(logistic.pickupDate).format(
                              "dddd MM-DD-YYYY "
                            ) +
                            " " +
                            moment(logistic.pickupDate).format("hh:mma")
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex">
                    <div className="mx-2">
                      <TimelineDot className={`text-muted ${classes.dot}`} />
                    </div>
                    <div>
                      <div className={`text-success ${classes.mainText}`}>
                        On Delivery
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        {logistic.onDeliveryDate
                          ? moment(logistic.onDeliveryDate).format(
                              "dddd MM-DD-YYYY "
                            ) +
                            " " +
                            moment(logistic.onDeliveryDate).format("hh:mma")
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex">
                    <div className="mx-2">
                      <TimelineDot className={`text-muted ${classes.dot}`} />
                    </div>
                    <div>
                      <div className={`text-success ${classes.mainText}`}>
                        Delivered
                      </div>
                      <div className={`text-muted ${classes.timing}`}>
                        {logistic.deliveredDate
                          ? moment(logistic.deliveredDate).format(
                              "dddd MM-DD-YYYY "
                            ) +
                            " " +
                            moment(logistic.deliveredDate).format("hh:mma")
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="py-3">
            <div className="d-flex box justify-content-between mb-2">
              <div>CONSUMER INFORMATION</div>
            </div>
            <div className="card p-4 ">
              <div className="d-flex justify-content-between">
                <div className="col-4 p-0">
                  <div className="pb-3">Personal detail</div>
                  <div
                    className="d-flex justifly-content-between pt-1"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <label htmlFor="" className="col-4 text-muted p-0">
                      Name:{" "}
                    </label>
                    <div className="col-8 p-0">
                      {fullData?.consumer?.consumerName || "N/A"}
                    </div>
                  </div>
                  <div
                    className="d-flex justifly-content-between pt-1"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <label htmlFor="" className="col-4 text-muted p-0">
                      Email:{" "}
                    </label>
                    <div className="col-8 text-nowrap p-0">
                      {fullData?.consumer?.email || "N/A"}
                    </div>
                  </div>
                  <div
                    className="d-flex justifly-content-between pt-1"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <label htmlFor="" className="col-4 text-muted p-0">
                      Phone:{" "}
                    </label>
                    <div className="col-8 p-0">
                      {fullData?.consumer?.phoneNumber || "N/A"}
                    </div>
                  </div>
                </div>
                <div className="col-7 p-0 ">
                  <div className=" p-0 mb-3">
                    <div className="pb-3">Billing Address</div>
                    <div
                      className="d-flex justifly-content-between pt-1"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <label htmlFor="" className="col-2 text-muted p-0">
                        Address:{" "}
                      </label>
                      <div className="col-10 ">
                        {fullData?.billingAddress?.address || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className=" p-0 mt-2">
                    <div className="pb-3">Shipping Address</div>
                    <div
                      className="d-flex justifly-content-between pt-1"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <label htmlFor="" className="col-2 text-muted p-0">
                        Address:{" "}
                      </label>
                      <div className="col-10 ">
                        {fullData?.shippingAddress?.address || "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-4 p-0">
          <div
            className="d-flex box justify-content-between mt-3 position-sticky r-0"
            style={{ right: "0px" }}
          ></div>
          <div
            className=" position-sticky r-0  "
            style={{ top: "0px", right: "0px" }}
          >
            <div className="d-flex justify-content-between">
              <div>OTHER INFORMATION</div>
              {getLocalStorage("role") == "SUPERADMIN" && (
                <div className=" bg-secondry" style={{ height: "25px" }}>
                  <IconButton
                    className="pointer"
                    onClick={() => setShowStatusChange(true)}
                  >
                    <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />
                  </IconButton>
                </div>
              )}
            </div>
            <div className="card p-3  mt-2">
              <div className="mb-3 " style={{ fontSize: "0.8rem" }}>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Status</div>

                  <div
                    className="font-weight-bold text-success"
                    style={{ fontSize: "1rem" }}
                  >
                    {!showStatusChange ? (
                      fullData?.status || "N/A"
                    ) : (
                      <>
                        <div className="d-flex justify-content-center">
                          <div
                            className="btn btn-light mr-1"
                            onClick={() => setShowStatusChange(false)}
                          >
                            cancel
                          </div>
                          <div
                            className="btn btn-dark btn-sm"
                            onClick={editStatusSave}
                          >
                            save
                          </div>
                        </div>
                        <br />
                        <div className="">
                          <select
                            className="btn border input-inside-text "
                            onChange={(e) =>
                              (changedStatus.current = e.target.value)
                            }
                          >
                            <option disabled selected>
                              {fullData?.status || "N/A"}
                            </option>
                            {fullData?.product?.productType !== "physical"
                              ? orderStatusOptions_NonPhysicalProduct.map(
                                  (item, index) =>
                                    fullData?.NpStatus !== item && (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    )
                                )
                              : fullData?.purchaseType == "TEAM"
                              ? orderStatusOptions_PhysicalProductTeam.map(
                                  (item, index) =>
                                    fullData?.status !== item && (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    )
                                )
                              : orderStatusOptions_PhysicalProduct.map(
                                  (item, index) =>
                                    fullData?.status !== item && (
                                      <option key={index} value={item}>
                                        {item}
                                      </option>
                                    )
                                )}
                          </select>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Order ID</div>
                  <div className="">
                    {/* {"#" + "012201" + (fullData?.orderId?.slice(-2) || "N/A")} */}
                    {fullData?.orderId || "N/A"}
                  </div>
                </div>
              </div>

              <div className="font-weight-bolder py-1">Payment</div>
              <div className="mb-3 " style={{ fontSize: "0.8rem" }}>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Payment Method</div>
                  <div className="">
                    {fullData?.payment?.payment_method?.Description || "N/A"}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Payment Date</div>
                  <div className="">
                    {fullData?.payment?.createdAt? moment(fullData?.payment?.createdAt).format(
                      "DD/MM/YY, HH:mm"
                    ) : "N/A"}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Transaction ID</div>
                  <div className="">{fullData?.payment?.TransId || "N/A"}</div>
                </div>
              </div>

              {fullData?.product?.productType === "physical" ? (
                <>
                  <div className="font-weight-bolder py-1">
                    Shipping Details
                  </div>
                  <div className="" style={{ fontSize: "0.8rem" }}>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Merchant</div>
                      <div className="">
                        {fullData?.merchant?.preferredMerchantName || "N/A"}
                        {/* Nike eStore */}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Shipping</div>
                      <div className="">
                        {fullData?.shippingDetails?.status || "N/A"}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Courier</div>
                      <div className="">{fullData?.courierName || "N/A"}</div>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Tracking Number</div>
                      <div className="">
                        {fullData?.trackingInfo?.awb_no || "N/A"}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="font-weight-bolder py-1">
                    Redemption Details
                  </div>
                  <div className="" style={{ fontSize: "0.8rem" }}>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Merchant</div>
                      <div className="">
                        {fullData?.merchant?.preferredMerchantName || "N/A"}

                        {/* Nike eStore */}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Purchase Date</div>
                      <div className="">
                        {fullData?.createdAt ? moment(fullData?.createdAt).format(
                          "DD/MM/YY, HH:mm"
                        ) : "N/A"}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Validity</div>

                      <div className="">
                        {fullData?.product?.expiryDate? moment(fullData?.product?.expiryDate).format(
                          "DD/MM/YY"
                        ) : "N/A"}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Redemption Code</div>
                      <div className="">
                        {fullData?.redemptionCode || "N/A"}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between py-1">
                      <div className="text-muted">Redemption Date</div>
                      <div className="">
                        {fullData?.redemptionDate? moment(fullData?.redemptionDate).format(
                          "DD/MM/YY, HH:mm"
                        ) : "N/A"}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="d-flex flex-row">
              <div className="col-md-6 mt-2 d-flex flex-row align-items-center justify-content-start">
                {fullData?.status == "TOSHIP" && (
                  <button
                    type="button"
                    className="w-full btn-danger btn border input-inside-text"
                    onClick={() =>
                      handleGoPrintAirwayBill(fullData?.trackingInfo?.awb_no)
                    }
                  >
                    Print Airway Bill
                  </button>
                )}
              </div>
              <div className="col-md-6 mt-2 d-flex align-items-center justify-content-end">
                {fullData?.status == "TOSHIP" && (
                  <button
                    type="button"
                    className="w-full btn-danger btn border input-inside-text"
                    onClick={shippedUpdateStatus}
                    // disabled
                  >
                    Already Shipped
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top b-0">
        <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
          <button
            type="button"
            className="btn border input-inside-text mr-2"
            onClick={history.goBack}
          >
            Cancel
          </button>
          {param?.storeId && getLocalStorage("role") == "MERCHANT" && (
            <button
              type="button"
              className="btn-danger btn border input-inside-text mr-2"
              // onClick={history.goBack}
              disabled
            >
              Contact Merchant
            </button>
          )}
        </div>
      </footer>
    </React.Fragment>
  );
}

export default ViewOrder;
