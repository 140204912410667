import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import InputField from "../../../../components/formControl/input/InputField";
import { startLoader, stopLoader, showToast, AllowAccess, open_dialog } from "../../../../lib/global";
import { breadcrumbAction } from "../../../../redux/actions/config.action";
import "../../../../static/css/consumerCenterCss/consumerCenterOverview.css";
import ablity from "../../../../helpers/abilityGuard";
import PagenationDropdown from "../../../../components/DropDown/pagenationDropdown";
import {
    Icon,
    IconButton,
    Pagination,
    PaginationItem,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { getAllCommisionRate } from "../../../../services/commissionRate.service";
import SvgIcon from "../../../../components/Images/SvgIcon";
import { DELETE_COMMISION_RATE } from "../../../../lib/constant";

function CommisionRateListing() {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    storeId:"",
    merchantName:""
  });
  const [total, setTotal] = useState(0);
  const [commisionRate, setCommisionRate] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);
  const handleOffSet = (num) => {
    setOffSet(num);
  };

  // const [searchData, setSearchData] = useState(initialConsumerSearchDetail);

  useEffect(() => {
    // startLoader();
    const breadcrumb = [{ label: "Commision Rate", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    getData()
  }, [offset,pageLimit]);

  const handleExport = () => {};

  const handleAddPromo = () => {
    history.push("/marketing/all-promo/commision-rate/create");
  };

  const onChangeSearch = (e, opt, val) => {
    const key = opt || e.target.name;
    let value = val || e.target.value;
    setSearch((pre) => ({ ...pre, [key]: value }));
  };

  const handlePageChange = (event, value) => {
    setOffset(value - 1);
    setPageNo(value);
  };

  const searchVoucher = () => {
    getData()
  };

  const handleResetSearch = () => {
    setSearch({
      storeId: "",
      merchantName: "",
    });
    getData()
  };

  const handleEdit = (id) => {
    history.push(`/marketing/all-promo/commision-rate/edit/${id}`);
  };

  const handleDelete = async (id) => {
    open_dialog(DELETE_COMMISION_RATE, {
      onSubmit: getData,
      id,
    });
  };

  const getData = () => {
    dispatch(getAllCommisionRate({
        ...search,
        offset:offset,
        limit:pageLimit
    })).then((response) => {
        if (response.data.data) {
          console.log(response.data.data)
          setCommisionRate(response.data.data);
          // setTotal(response.data.total);
        }
      });
  }

  return (
    <div className="main_consumer_account_div">
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-12 row flex-wrap">
          <div className="min-width-200 col-md-3">
            <label>Merchant Name</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="merchantName"
              name="merchantName"
              value={search.merchantName}
              onChange={onChangeSearch}
            />
          </div>

          <div className="min-width-200 col-md-3">
            <label>Merchant ID</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="storeId"
              name="storeId"
              value={search.storeId}
              onChange={onChangeSearch}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn btn-outline-dark mr-1 px-4"
            onClick={() => handleResetSearch()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-dark mx-1 btn-secondary px-3"
            onClick={() => searchVoucher()}
          >
            Search
          </button>
        </div>
      </div>
        <div className="container_body container-fluid mt-2">
            <div className="card p-3">
            <span>COMMISION RATE</span>
            <div className=" border card rounded-lg my-3">
            <div className="d-flex justify-content-between">
                <div>
                <span></span>
                </div>
                <div className="d-flex mb-3">
                {/* {ablity({model:'Marketing', action:'export'}) == 1 && (
                    <button
                        type="button"
                        onClick={() => handleExport()}
                        className="btn btn-dark btn-sm text-nowrap mx-3 my-2"
                    >
                        Export
                    </button>
                )} */}
                {ablity({model:'Marketing', action:'create'}) == 1 && (
                    <button
                        type="button"
                        onClick={() => handleAddPromo()}
                        className="btn btn-dark btn-sm text-nowrap mx-3 my-2"
                    >
                        + Add Promo Rate
                    </button>
                )}
                </div>
            </div>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                    <TableCell className="th_cell" component="th">
                        No.
                    </TableCell>

                    <TableCell className="th_cell" component="th" align="center">
                        <div className="d-flex  ">
                        <div>Merchant ID</div>
                        <div className="d-flex flex-column ">
                            <ExpandLessIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("name", "asc")}

                            />
                            <ExpandMoreIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("name", "desc")}

                            />
                        </div>
                        </div>
                    </TableCell>
                    <TableCell className="th_cell" component="th" align="center">
                        <div className="d-flex  ">
                        <div>Name</div>
                        <div className="d-flex flex-column ">
                            <ExpandLessIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("type", "asc", true)}

                            />
                            <ExpandMoreIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("type", "desc", true)}

                            />
                        </div>
                        </div>
                    </TableCell>
                    <TableCell className="th_cell" component="th" align="center">
                        <div className="d-flex  ">
                        <div>Email</div>
                        <div className="d-flex flex-column ">
                            <ExpandLessIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("category", "asc", true)}

                            />
                            <ExpandMoreIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("category", "desc", true)}
                            />
                        </div>
                        </div>
                    </TableCell>
                    <TableCell className="th_cell" component="th" align="center">
                        <div className="d-flex  ">
                        <div>Promo Rate Physical</div>
                        <div className="d-flex flex-column ">
                            <ExpandLessIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("description", "asc")}

                            />
                            <ExpandMoreIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("description", "desc")}
                            />
                        </div>
                        </div>
                    </TableCell>
                    <TableCell className="th_cell" component="th" align="center">
                        <div className="d-flex  ">
                        <div>Promo Start Date Physical</div>
                        <div className="d-flex flex-column ">
                            <ExpandLessIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("usageLimitPerVoucher", "asc")}

                            />
                            <ExpandMoreIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("usageLimitPerVoucher", "desc")}

                            />
                        </div>
                        </div>
                    </TableCell>
                    <TableCell className="th_cell" component="th" align="center">
                        <div className="d-flex  ">
                        <div>Promo End Date Physical</div>
                        <div className="d-flex flex-column ">
                            <ExpandLessIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("usage", "asc")}

                            />
                            <ExpandMoreIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("usage", "desc")}

                            />
                        </div>
                        </div>
                    </TableCell>
                    <TableCell className="th_cell" component="th" align="center">
                        <div className="d-flex  ">
                        <div>Promo Rate Non Physical</div>
                        <div className="d-flex flex-column ">
                            <ExpandLessIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("amount", "asc")}

                            />
                            <ExpandMoreIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("amount", "desc")}

                            />
                        </div>
                        </div>
                    </TableCell>
                    <TableCell className="th_cell" component="th" align="center">
                        <div className="d-flex  ">
                        <div>Promo Start Date Non Physical</div>
                        <div className="d-flex flex-column ">
                            <ExpandLessIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("usageLimitPerVoucher", "asc")}

                            />
                            <ExpandMoreIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("usageLimitPerVoucher", "desc")}

                            />
                        </div>
                        </div>
                    </TableCell>
                    <TableCell className="th_cell" component="th" align="center">
                        <div className="d-flex  ">
                        <div>Promo End Date Non Physical</div>
                        <div className="d-flex flex-column ">
                            <ExpandLessIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                            }}
                            onClick={() => handleSorting("usage", "asc")}

                            />
                            <ExpandMoreIcon
                            style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                            }}
                            onClick={() => handleSorting("usage", "desc")}

                            />
                        </div>
                        </div>
                    </TableCell>
                    <TableCell
                        className="table_action_head"
                        component="th"
                        align="center"
                    >
                        Action
                    </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!commisionRate?.length && (
                    <TableRow>
                        <TableCell colSpan={8} align="center">
                        <h5 className="text-muted m-auto">No data found!</h5>
                        </TableCell>
                    </TableRow>
                    )}
                    {commisionRate &&
                    commisionRate.length > 0 &&
                    commisionRate.map((row, iindex) => (
                        <TableRow
                        key={iindex}
                        sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        >
                        <TableCell align="center" className="td_cell">
                            {offset * pageLimit + (iindex + 1)}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                            {row.storeId}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                            {row.merchantName || "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                            {"N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                            {row.physicalPercentage +'%' || "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                            {row.physicalStartPeriodDate
                            ? moment(row.physicalStartPeriodDate).format("DD/MM/YYYY")
                            : "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                            {row.physicalEndPeriodDate
                            ? moment(row.physicalEndPeriodDate).format("DD/MM/YYYY")
                            : "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                            {row.nonPhysicalPercentage+'%' || "0"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                            {row.nonPhysicalStartPeriodDate
                            ? moment(row.nonPhysicalStartPeriodDate).format("DD/MM/YYYY")
                            : "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                            {row.nonPhysicalStartPeriodDate
                            ? moment(row.nonPhysicalEndPeriodDate).format("DD/MM/YYYY")
                            : "N/A"}
                        </TableCell>

                        <TableCell className="td_cell justify-content-between table_action_item">
                            {ablity({model:'Marketing', action:'update'}) == 1 && (
                                <IconButton
                                className="pointer"
                                onClick={() => handleEdit(row.id)}
                                >
                                <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />
                                </IconButton>
                            )}

                            {ablity({model:'Marketing', action:'delete'}) == 1 && (
                                <IconButton
                                className="pointer"
                                onClick={() => handleDelete(row.id)}
                                >
                                <SvgIcon src="/icons/delete_icon.svg" alt="delete icon" />
                                </IconButton>
                            )}
                        </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
            <br />
            <div className="d-flex justify-content-between align-items-center">
                <PagenationDropdown setPageQuant={setPageLimit} />
                <div className="float-right mb-1">
                <Stack spacing={2}>
                    <Pagination
                    count={Math.ceil((total || 0) / pageLimit)}
                    shape="rounded"
                    size="small"
                    page={pageNo}
                    onChange={handlePageChange}
                    />
                </Stack>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommisionRateListing;
