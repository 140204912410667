import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Image from "../../../components/Images/Image";

import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../../components/Images/SvgIcon";
import moment from "moment";
import { startLoader, stopLoader, showToast, AllowAccess, open_dialog } from "../../../lib/global";

import { getTeamPurcheaseById } from "../../../redux/actions/order.action";
import ViewOrder from "../Order_Listing/ViewOrder";
import decimalFormat from "../../../helpers/decimal.format";
import { getLocalStorage } from "../../../lib/session";
import { OVERRIDE_TP_MODAL } from "../../../lib/constant";

function TPRetailViewPage() {
  AllowAccess(["SUPERADMIN","MERCHANT"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [viewData, setViewData] = useState({});
  const [otherDetails, setOtherDetails] = useState({});
  const [tpStatus, setTpStatus] = useState("");
  const { id } = useParams();

  const [timerHours, setTimerHours] = useState("00");
  const [timerMinutes, setTimerMinutes] = useState("00");
  const [timerSeconds, setTimerSeconds] = useState("00");
  const role = getLocalStorage("role");
  const storeId = role == "MERCHANT" ? getLocalStorage("storeId") : null;
  const [showStatusChange, setShowStatusChange] = useState(false);

  const viewAction = (id) => {
    history.push(`/orders/view-order/${id}`);
  };

  const apiData = () => {
    dispatch(getTeamPurcheaseById(id))
      .then((data) => {
        if (role == "MERCHANT" && data?.data?.data?.order?.storeId != storeId) {
          history.push("/404");
        }

        let detail = data.data.data;
        let variation = detail?.order?.variation;
        let aa = detail?.product?.variationList?.filter((item) => {
          let a = false;
          variation.map((it) => {
            // console.log(it.name,item[it.name],item.value);
            a =
              item.hasOwnProperty(it.name) && item[it.name] == it.value
                ? true
                : false;
          });
          if (a == true) {
            return item;
          }
        });

        startTimer(data.data.data.teamCloseingTime);
        var tpStatus = getStatusOrder(
          detail.teamStatus,
          detail.totalMembersRequired,
          detail.activeMembers.length
        );
        setViewData({ ...data?.data?.data, teamVariations: aa?.[0], tpStatus });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  let interval;
  const startTimer = (teamCloseingTime) => {
    // const countDownDate = new Date("Nov 8, 2022 20:25:25").getTime();
    const countDownDate = new Date(teamCloseingTime).getTime();

    interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      hours = hours < 10 ? `0${hours}` : hours;
      minutes = minutes < 10 ? `0${minutes}` : minutes;
      seconds = seconds < 10 ? `0${seconds}` : seconds;

      if (distance < 0) {
        clearInterval(interval.current);
      } else {
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    }, 500);
  };

  const getStatusOrder = (status, totalMembersRequired, activeMember) => {
    if (status == "PENDINGPAYMENT") return "PENDING PAYMENT";
    if (status == "AWAITINGTP") return "AWAITING TP";
    if (status == "DELIVERED") return "COMPLETED / REDEEMED";
    if (status == "CLOSED-OVERRIDDEN") return "CLOSED OVERRIDDEN";
    if (totalMembersRequired == activeMember) return "Closed-Fullfilled";
    return "Closed-Unfullfilled";
  };

  useEffect(() => {
    apiData()
  }, [id]);

  const overrideSave = () => {
    open_dialog(OVERRIDE_TP_MODAL, {
      onSubmit: getDataAfterOverride,
      id,
    });
  }

  const getDataAfterOverride = () => {
    apiData()
  };

  return (
    <>
      <React.Fragment>
        <div style={{ height: "90vh" }}>
          <div className="d-flex  position-relative  mx-4 ">
            <div className="col-8  pr-3">
              <div className="py-3">
                <div className="d-flex box justify-content-between  mb-2">
                  <div>ORDER INFORMATION</div>
                </div>
                <div className="card p-3">
                  <div className="d-flex justify-content-between ">
                    <div>Product</div>
                    <div className=" d-flex justify-content-between mx-3">
                      <span className="mx-2">Time :</span>
                      <span className="font-weight-bolder">
                        {/* {viewData.status == "EXPIRED" ? "00:00:00" : "12:32:90"} */}
                        {`${timerHours}:${timerMinutes}:${timerSeconds}`}
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <div
                      className="d-flex mb-2"
                      onClick={() => viewAction(viewData?.teamCreator?.orderId)}
                    >
                      <Image
                        src={
                          viewData?.product?.productImages[0] ||
                          "/images/noImage.png"
                        }
                        className="order_page_product_image"
                        alt="img"
                      />
                      <div className="px-2">
                        <div className="text-muted text-capitalize ">
                          {viewData?.order?.productName || "N/A"}
                        </div>
                        <div className="  text-capitalize text-muted">
                          {`SKU: ${viewData?.teamVariations?.SKU || "N/A"}`}
                        </div>
                      </div>
                    </div>

                    <TableContainer>
                      <Table>
                        <TableBody className="">
                          <TableRow
                            sx={{
                              " td, th": { border: 0 }
                            }}
                            className=""
                          >
                            <TableCell
                              className="align-middle text-muted w-50 py-0"
                              align="right"
                            >
                              TP Duration
                            </TableCell>
                            <TableCell
                              className="align-middle text-muted w-50 pl-5 py-0"
                              align="left"
                            >
                              : 24:00:00
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              " td, th": { border: 0 }
                            }}
                            className=""
                          >
                            <TableCell
                              sx={{
                                " td, th": { border: 0, "padding-left": 50 }
                              }}
                              className="align-middle text-muted w-50 py-0"
                              align="right"
                            >
                              Stock
                            </TableCell>
                            <TableCell
                              className="align-middle text-muted w-50 pl-5 py-0"
                              align="left"
                            >
                              : {viewData?.teamVariations?.teamStock || "N/A"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              " td, th": { border: 0 }
                            }}
                            className=""
                          >
                            <TableCell
                              className="align-middle text-muted w-25 py-0"
                              align="right"
                            >
                              TP Member
                            </TableCell>
                            <TableCell
                              className="align-middle text-muted w-50 pl-5 py-0"
                              align="left"
                            >
                              : {viewData?.teamVariations?.teamMembers || "N/A"}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            sx={{
                              " td, th": { border: 0 }
                            }}
                            className=""
                          >
                            <TableCell
                              className="align-middle text-muted w-25 py-0"
                              align="right"
                            >
                              Unit Capped
                            </TableCell>
                            <TableCell
                              className="align-middle text-muted w-50 pl-5 py-0"
                              align="left"
                            >
                              : {viewData?.teamVariations?.unitCapped || "N/A"}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
                {/* <div className='card p-3 '>
                                    <TableContainer>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow
                                                    sx={{
                                                        " td, th": { border: 0 },
                                                    }}>
                                                    <TableCell className="w-25" align="left">
                                                        Product
                                                    </TableCell>

                                                    <TableCell
                                                        className="w-25"
                                                        align="right"
                                                    >
                                                        Time Left
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                <TableRow
                                                    sx={{
                                                        " td, th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell className="" component="td" align="left">
                                                        <div
                                                            className="d-flex"
                                                            onClick={() =>
                                                                viewAction(
                                                                    row?.orderId
                                                                )
                                                            }>
                                                            <Image
                                                                src={viewData?.product?.productImages[0] || "/images/noImage.png"}
                                                                className="order_page_product_image"
                                                                alt="img"
                                                            />
                                                            <div className="px-2" >
                                                                <div className="text-muted">
                                                                    {viewData?.order?.productName || "N/A"}

                                                                </div>
                                                                <div className="text-dark bolder text-capitalize">
                                                                    {`variation: ${viewData?.order?.variation?.[0]?.value || "N/A"}`}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </TableCell>

                                                    <TableCell className="align-text-top font-weight-bold" component="td" align="right" style={{ fontSize: '20px' }}>
                                                        09:34:00
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div> */}
              </div>

              <div className="py-3">
                <div className="d-flex box justify-content-between  mb-2">
                  <div>MEMBER LISTING</div>
                </div>
                <div className="card p-3 ">
                  <TableContainer>
                    <Table
                      sx={{ minWidth: 950 }}
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <TableHead>
                        <TableRow
                          sx={{
                            " td, th": { border: 0 }
                          }}
                        >
                          <TableCell className="" align="left">
                            No.
                          </TableCell>
                          <TableCell className="" align="left">
                            Buyer
                          </TableCell>
                          <TableCell className="" align="left">
                            Order ID
                          </TableCell>
                          <TableCell className="" align="left">
                            QTY
                          </TableCell>
                          <TableCell className="" align="left">
                            Unit Price
                          </TableCell>
                          <TableCell className="" align="left">
                            Total Price
                          </TableCell>

                          <div
                            className="tableheadfix tableheadfix_allorder teamPurchase_borderLine"
                            style={{ width: "60px", border: "none" }}
                          >
                            Action
                          </div>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!viewData?.activeMembers?.length && (
                          <h3 className="text-muted m-auto">No data found!</h3>
                        )}
                        {viewData?.activeMembers?.map((row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              " td, th": { border: 0 }
                            }}
                          >
                            <TableCell className="" component="td" align="left">
                              {index + 1}
                            </TableCell>

                            <TableCell
                              className="text-decoration-underline"
                              component="td"
                              align="left"
                            >
                              {row?.consumerName || "N/A"}
                            </TableCell>
                            <TableCell
                              className=""
                              component="td"
                              align="left"
                              onClick={() => viewAction(row?.orderId)}
                              style={{ textDecorationLine: "underline" }}
                            >
                              {row?.orderId || "N/A"}
                            </TableCell>

                            <TableCell className="" component="td" align="left">
                              {viewData?.activeMembers[index]?.orderDetails[0]
                                .quantity || "N/A"}
                            </TableCell>
                            <TableCell className="" component="td" align="left">
                              {viewData?.activeMembers[index]
                                ? `RM${decimalFormat(
                                  viewData?.activeMembers[index]
                                    ?.orderDetails[0]?.unitPrice || 0
                                )}`
                                : 0}
                            </TableCell>
                            <TableCell className="" component="td" align="left">
                              {
                                // viewData?.activeMembers[index]?.orderDetails[0]
                                // .quantity &&
                                viewData?.activeMembers[index]?.orderDetails[0]
                                  .price
                                  ? `RM${decimalFormat(
                                    // viewData?.activeMembers[index]
                                    // ?.orderDetails[0].quantity *
                                    viewData?.activeMembers[index]
                                      ?.orderDetails[0]?.price || 0
                                  )}`
                                  : 0
                              }
                            </TableCell>
                            {/* <TableCell
                                                            className=""
                                                            component="td"
                                                            align="left"
                                                        >
                                                            {viewData?.order?.buyNowPrice || "N/A"}
                                                        </TableCell> */}

                            {/* <TableCell
                                                            className=""
                                                            component="td"
                                                            align="left"
                                                        >
                                                            {viewData?.order?.unitCapped || "N/A"}
                                                        </TableCell> */}
                            <button
                              className="tableheadfix tableheadfix_allorder teamPurchase_borderLine text-primary"
                              style={{ width: "60px", border: "none" }}
                              onClick={() => viewAction(row?.orderId)}
                            >
                              view
                            </button>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>



            <div className="col-4 position-absolute  p-3 r-0 card my-5 ">
              {getLocalStorage("role") == "SUPERADMIN" && viewData?.tpStatus == "AWAITING TP" && (
                <div className="d-flex justify-content-between py-1 mb-5">
                  <div className="font-weight-bolder py-1">Override TP</div>
                  <div className=" bg-secondry" style={{ height: "25px" }}>
                    <div
                      className="btn btn-dark btn-sm"
                      onClick={overrideSave}
                    >
                      save
                    </div>
                  </div>
                </div>
              )}

              <div className="mb-3" style={{ fontSize: "0.8rem" }}>
                <div className="d-flex justify-content-between py-1">
                <div className="text-muted">TP Status</div>
                  <div
                    className="font-weight-bold"
                    style={{ fontSize: "1rem" }}
                  >
                    {viewData?.tpStatus || "N/A"}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Member Fulfilled</div>
                  <div className="">
                    {" "}
                    {`${viewData?.activeMembers?.length || 0} / ${
                      viewData?.totalMembersRequired
                    }`}
                  </div>
                </div>
              </div>

              <div className="font-weight-bolder py-1">Product Details</div>
              <div className="mb-3 " style={{ fontSize: "0.8rem" }}>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Category</div>
                  <div className="">
                    {viewData?.product?.categoryName || "N/A"}
                    {/* {fullData?.paymentMethods || "N/A"} */}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted"> Date Started</div>
                  <div className="">
                    {viewData.createdAt
                      ? moment(viewData.createdAt).format("DD/MM/YY, HH:mm")
                      : "N/A"}

                    {/* {fullData?.paymentDate || "N/A"} */}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Date Ended</div>
                  <div className="">
                    {viewData.teamCloseingTime
                      ? moment(viewData.teamCloseingTime).format(
                        "DD/MM/YY, HH:mm"
                      )
                      : "N/A"}
                    {/* {fullData?.paymentDate || "N/A"} */}
                  </div>
                </div>
              </div>

              <div className="font-weight-bolder py-1">Merchant Details</div>
              <div className="mb-3" style={{ fontSize: "0.8rem" }}>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Merchant ID</div>
                  <div className="">
                    {" "}
                    {viewData?.product?.merchant?.storeId || "N/A"}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Merchant Name</div>
                  <div className="">
                    {" "}
                    {viewData?.product?.merchant?.merchantName || "N/A"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer
            className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top position-absolute b-0 "
            style={{ width: "79vw" }}
          >
            <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
              <button
                type="button"
                className="btn border btn-dark input-inside-text mr-5 px-4"
                onClick={history.goBack}
              >
                Back
              </button>
            </div>
          </footer>
        </div>
      </React.Fragment>
    </>
  );
}

export default TPRetailViewPage;
