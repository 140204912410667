import { SET_BREADCRUMB } from "../actionTypes"

 /**
 * @description use this action to set breadcrumb in headers
 * @author jagannath
 * @data [{label: string, url: string}, {label: string, url: string | null}, ...]
 */
export const breadcrumbAction = (data=[]) => {
    return async function(dispatch){
        dispatch({
            type: SET_BREADCRUMB,
            payload: data
        })
    }
}