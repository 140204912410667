import { GetWithToken, PatchWithToken } from "../../lib/request";

export const getOtpById = () => {
  return async function (dispatch) {
    const result = await GetWithToken(`/otp-master`);
    return result;
  };
};

export const updateOtpById = (status) => {
  const id = "20c2871b-cee8-430c-a7ca-4fc3b341bf5c";
  return async function (dispatch) {
    const result = await PatchWithToken(`/otp-master/${id}`, status);
    return result;
  };
};
