import React, {useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,

} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SvgIcon from "../../../../components/Images/SvgIcon";
import { AllowAccess, parseParam, parseQuery, startLoader, stopLoader } from "../../../../lib/global";
import { useDispatch } from "react-redux";
import { getAllMerchantParticipateBySlotId } from "../../../../redux/actions/marketing.action";

const FlashSaleParticipateOverview = () => {
    AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
    const history = useHistory();
    const dispatch = useDispatch();
    const [allDataParticipant, setAllDataParticipant] = useState([])
    const { promoslotid, totalmerchant, totalproduct, totalreminder, datepromo } = parseQuery(history.location.search) || {};
    const [totalMerchant, setTotalMerchant] = useState(0);
    const [totalProduct, setTotalProduct] = useState(0);
    const [totalReminder, setTotalReminder] = useState(0);
    let filterData = {}

    useEffect(() => {
        filterData = {
            timeSlotDate:datepromo
        }
        console.log(promoslotid, filterData, 'ripto');
        apiData(promoslotid, filterData)
    },[])

    const apiData = (promoSlotId, filterData) => {
        startLoader()
        dispatch(getAllMerchantParticipateBySlotId(promoSlotId, filterData))
          .then((data) => {
              let totalMerchant = 0 
              let totalProduct = 0
              let totalReminder = 0
              setAllDataParticipant(
                data &&
                data.data &&
                data.data.data.map((row, index) => {
                    totalMerchant++
                    totalProduct = totalProduct + (row?.totalProduct || 0 )
                    totalReminder = totalReminder + (row?.totalReminder || 0)
                    return {
                        timeSlotId: row?.timeSlotId || "N/A",
                        promoSlotId: row?.promoSlotId || "N/A",
                        merchantId: row?.merchantId || "N/A",
                        date: row?.date || "N/A",
                        totalProduct: row?.totalProduct || 0,
                        merchantType: row?.merchantType || "N/A",
                        merchantName: row?.merchantName || "N/A",
                        totalReminder: row?.totalReminder|| 0,
                        mid: row?.id || "N/A",
                    };
                })
              );

              setTotalMerchant(totalMerchant)
              setTotalProduct(totalProduct)
              setTotalReminder(totalReminder)

              setTimeout(() => {
                  stopLoader();
              }, 1000);
          })
          .catch((err) => {
              setTimeout(() => {
                  stopLoader();
              }, 1000);
              console.log({ err });
          });
    }
    const viewAction = (merchantId, timeSlotId) => {
        history.push(`/marketing/all-promo/flash-sale/participate-overview/listing-product?merchantid=${merchantId}&promoslotid=${promoslotid}&timeslotid=${timeSlotId}&datepromo=${datepromo}`)
    }

    return (
        <React.Fragment>
            <div className=" d-flex flex-column justify-content-between container_body vh-100">
                <div className="container-fluid">
                    <div className="card p-3 px-4 my-3">
                        <div className="">
                            <span>OVERVIEW</span>
                            <div className=" p-0 card mt-3 d-flex flex-row ">
                                <div className="cord col-3 mx-1 rounded overview-card border">
                                    <p className="m-0 text-muted p-0 input-inside-text">
                                        Total Merchant
                                    </p>
                                    <h4>{totalMerchant}</h4>
                                </div>
                                <div className="cord col-3 mx-1 rounded overview-card border">
                                    <p className="m-0 text-muted p-0 input-inside-text ">
                                        Total Products
                                    </p>
                                    <h4>{totalProduct}</h4>
                                </div>
                                <div className="cord col-3 mx-1 rounded overview-card border">
                                    <p className="m-0 text-muted p-0 input-inside-text">
                                        Total Remainders Set
                                    </p>
                                    <h4>{totalReminder}</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card p-3 px-4">
                        <h5>
                            MERCHANT PARTICIPATE LISTING
                        </h5>
                        <div className="card p-3 m-1 border rounded">
                            {/*<h6>2 Merchant</h6>*/}
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                className="th_cell"
                                                component="th"
                                                align="center"
                                            >
                                                <div className="d-flex  ">
                                                    <div>Merchant</div>
                                                    <div className="d-flex flex-column ">
                                                        <ExpandLessIcon
                                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                                            // onClick={() => getApiData({ item: "title", order: "ASC" })}

                                                        />
                                                        <ExpandMoreIcon
                                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                                            // onClick={() => getApiData({ item: "title", order: "DESC" })}

                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className="th_cell"
                                                component="th"
                                                align="center"
                                            >
                                                <div className="d-flex  ">
                                                    <div>MID</div>
                                                    <div className="d-flex flex-column ">
                                                        <ExpandLessIcon
                                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                                            // onClick={() => getApiData({ item: "title", order: "ASC" })}

                                                        />
                                                        <ExpandMoreIcon
                                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                                            // onClick={() => getApiData({ item: "title", order: "DESC" })}

                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className="th_cell "
                                                component="th"
                                                align="center"
                                            >
                                                <div className="d-flex  ">
                                                    <div>Products</div>
                                                    <div className="d-flex flex-column ">
                                                        <ExpandLessIcon
                                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                                            // onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                                                        />
                                                        <ExpandMoreIcon
                                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                                            // onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className="th_cell "
                                                component="th"
                                                align="center"
                                            >
                                                <div className="d-flex  ">
                                                    <div>Reminders Set</div>
                                                    <div className="d-flex flex-column ">
                                                        <ExpandLessIcon
                                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                                            // onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                                                        />
                                                        <ExpandMoreIcon
                                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                                            // onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell
                                                className="th_cell "
                                                component="th"
                                                align="center"
                                            >
                                                <div className="d-flex  ">
                                                    <div>Type</div>
                                                    <div className="d-flex flex-column ">
                                                        <ExpandLessIcon
                                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                                            // onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                                                        />
                                                        <ExpandMoreIcon
                                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                                            // onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                                                        />
                                                    </div>
                                                </div>
                                            </TableCell>

                                            <TableCell className="table_action_head text-secondary" component="th">
                                                Action
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {allDataParticipant &&
                                          allDataParticipant.length > 0 &&
                                          allDataParticipant.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >

                                                    <TableCell align="left" className="td_cell">
                                                        {row?.merchantName}
                                                    </TableCell>
                                                    <TableCell align="center" className="td_cell">
                                                        {row?.merchantId}
                                                    </TableCell>
                                                    <TableCell align="center" className="td_cell">
                                                        {row?.totalProduct}
                                                    </TableCell>

                                                    <TableCell align="center" className="td_cell">
                                                        {row?.totalReminder}
                                                    </TableCell>
                                                    <TableCell align="center" className="td_cell">
                                                        {row?.merchantType}
                                                    </TableCell>


                                                    <TableCell className="d-flex  table_action_item">
                                                        <IconButton
                                                            onClick={() => viewAction(row.merchantId, row.timeSlotId)}
                                                            className="pointer"
                                                            // onClick={() => history.push("/marketing/all-promo/flash-sale/participate-overview")}
                                                            // onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}
                                                        >
                                                            <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                                                        </IconButton>
                                                        {/*<IconButton*/}
                                                        {/*    onClick={() => history.push("/marketing/all-promo/flash-sale/participate-overview/nike-estore")}*/}
                                                        {/*    className="pointer"*/}
                                                        {/*// onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}*/}


                                                        {/*>*/}
                                                        {/*    <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />*/}
                                                        {/*</IconButton>*/}
                                                        {/*<IconButton*/}
                                                        {/*    // onClick={() => history.push(`edit?id=${row.productId}`)}*/}
                                                        {/*    className="pointer"*/}
                                                        {/*// onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}*/}


                                                        {/*>*/}
                                                        {/*    <SvgIcon src="/icons/delete_icon.svg" alt="view icon" />*/}
                                                        {/*</IconButton>*/}


                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={10}
                                component="div"
                                // count={allProductData.length}
                                count={2}
                                rowsPerPage={10}
                                page={0}
                                onPageChange={(e) => {
                                    console.log("page change", e);
                                }}
                            // onRowsPerPageChange={(e)=>{console.log('page change', e)}}
                            />
                        </div>
                    </div>

                </div>

                <footer className="bg-white p-3 mt-5">
                    <div className="d-flex justify-content-end">
                        <button
                            onClick={() => history.push("/marketing/all-promo/flash-sale")}
                            type="button"
                            className="btn btn-outline-danger mx-2"
                        // onClick={back}
                        >
                            Back
                        </button>


                    </div>
                </footer>
            </div>
        </React.Fragment>
    )
}

export default FlashSaleParticipateOverview;
