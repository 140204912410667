import React, { useEffect, useState } from "react";
import LineChart from "../../../components/charts/LineChart";
import useTheme from "../../../hooks/useTheme";

/**
 * @description component to show chart for each category
 * @author jagannath
 * @date 13/04/2021
 * @param series: Array[] - graph data
 * @param categories: Array[] - graph footer label
 * @param eventType: String - evnet type - pageView|videoPlay|clicks|...
 * @param categoryTitle: String - categoryTitle - Page Views
 * @memberof AnalyticsPage
 */
const SalesGraph = (props) => {
  // console.log("props", props);
  const {
    eventType,
    filterBy,
    categoryTitle,
    filterDate,
    xaxisTitle,
    //   series,
    //   categories
    // selectedVideoCategory
  } = props;
  const [series, setSeries] = useState([]);
  const [theme, themeType] = useTheme();

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    markers: {
      size: 6,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: "3",
    },
    grid: {
      row: {
        colors: [theme.graph?.rowColor, "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
      borderColor: theme.graph?.rowColor,
    },
    xaxis: {
      categories: [
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
        "Monday",
        "Tuesday",
      ],
      title: {
        text: "Date or Time",
      },
      labels: {
        show: true,
        style: {
          colors: theme.textColor,
        },
      },
    },
    yaxis: {
      title: {
        text: categoryTitle + " Count",
        style: {
          colors: "#fff",
          fill: "#fff",
        },
      },
      labels: {
        show: true,
        style: {
          colors: theme.textColor,
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => {
          return `${val}`;
        },
      },
      theme: themeType,
    },
  });

  // useEffect(()=>{
  //   if(eventType && filterDate){
  //     // startLoader()
  //     let query = {
  //         eventType,
  //         filterBy: filterBy,
  //         // selectedVideoCategory: selectedVideoCategory,
  //         startDate: filterBy == 'randomDate' ? null : new Date(filterDate?.startDate).valueOf(),
  //         endDate: filterBy == 'randomDate' ? null : new Date(filterDate?.endDate).valueOf()
  //     }

  //   }
  // },[eventType, filterDate])

  useEffect(() => {
    setSeries(props.series);
    setOptions((prev) => ({
      ...prev,
      xaxis: {
        ...prev.xaxis,
        categories: props.categories,
        title: {
          text: props.xaxisTitle,
        },
      },
      yaxis: {
        ...prev.yaxis,
        title: {
          text: props.yaxisTitle,
        },
      },
    }));
  }, [props.series]);

  return (
    <div>
      <LineChart
        options={options}
        series={series}
        type={props.type}
        width="30"
      />
    </div>
  );
};

export default SalesGraph;
