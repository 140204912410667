import React from 'react'
import Layout from '../../components/Layout'

const ReviewsPage = () => {
    return (
        <React.Fragment>
            ReviewsPage
        </React.Fragment>
    )
}

export default ReviewsPage
