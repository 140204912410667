import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Stack,
  Pagination,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Image from "../../../components/Images/Image";
import SvgIcon from "../../../components/Images/SvgIcon";
import { getAllCategoryList } from "../../../redux/actions/category.action";
import { useDispatch } from "react-redux";
import { AllowAccess, open_dialog, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { DELETE_CATEGORY } from "../../../lib/constant";
import PagenationDropdown from "../../../components/DropDown/pagenationDropdown";
import ablity from "../../../helpers/abilityGuard";

const initialSearchData = {
  search: "",
};

function ProductCategory() {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const history = useHistory();
  const dispatch = useDispatch();

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);

  // const [title, setTitle] = useState()
  const [categoryData, setCategoryData] = useState([]);
  const [totalCategory, setTotalCategory] = useState(0);
  const [totalDisableCategory, setTotalDisableCategory] = useState(0);
  const [totalActiveCategory, setTotalActiveCategory] = useState(0);
  const [mainCategory, setMainCategory] = useState(0);
  const [searchData, setSearchData] = useState(initialSearchData);

  useEffect(() => {
    const breadcrumb = [{ label: "Product Category", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    startLoader();
    // const data = { ...searchData };
    let filteringdata;
    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      filteringdata = {
        limit: pageLimit,
      };
    } else {
      filteringdata = {
        ...searchData,
        offset,
        limit: pageLimit,
      };
    }
    const response = dispatch(getAllCategoryList(filteringdata));
    response
      .then((data) => {
        // console.log(data, "fghjk");
        if (data && data.data && data.data.status === 200) {
          setTotalCategory(data && data.data.TotelCategory);
          setTotalDisableCategory(data && data.data.disableCategory);
          setTotalActiveCategory(data && data.data.activeCategory);
          setMainCategory(data && data.data.mainCategory);
          console.log(data.data.data);
          setCategoryData(data && data.data && data.data.data);
          setTimeout(() => {
            stopLoader();
          }, 1000);
        } else {
          setTimeout(() => {
            stopLoader();
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("----------error----", error);
        stopLoader();
      });
  }, [offset, pageLimit, searchData]);

  const getApiData = (searchingData) => {
    startLoader();
    dispatch(getAllCategoryList(searchingData))
      .then((data) => {
        setCategoryData(data && data.data && data.data.data);
        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((error) => {
        stopLoader();
        console.log({ error }, "errorr");
      });
  };

  const handlePageChange = (event, value) => {
    console.log(event, value, "gggggggggggggggggggggggggggggggg");
    setOffset(value - 1);
    setPageNo(value);
  };

  const onChangeSeachData = (data) => {
    setSearchData({ ...searchData, search: data });
  };

  const addCategory = () => {
    history.push("/products/add-category");
  };
  const editCategory = (id) => {
    history.push(`/products/edit-category/${id}`);
  };

  const getProductAfterDelete = (data) => {
    console.log(data, "jjjkkkjkkk");
    setTotalCategory(data && data.data.TotelCategory);
    setTotalDisableCategory(data && data.data.disableCategory);
    setTotalActiveCategory(data && data.data.activeCategory);
    setMainCategory(data && data.data.mainCategory);
    setCategoryData(data && data.data && data.data.data);
  };

  const deleteCategory = (id) => {
    open_dialog(DELETE_CATEGORY, {
      onSubmit: getProductAfterDelete,
      id,
    });
  };

  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof categoryData[0][item] === "number") {
        let sortedData = categoryData.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setCategoryData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof categoryData[0][item] === "string" && !isDate) {
        let sortedData = categoryData.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
        console.log(sortedData, "llllllllll");
        setTimeout(() => {
          setCategoryData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof categoryData[0][item] === "string" && isDate) {
        let sortedData = categoryData.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          console.log(da, db);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setCategoryData([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      console.log({ error });
      stopLoader();
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="px-2 py-3 mb-3 card">
          <div className=" px-2 py-3 bg-body bg-white rounded shadow-sm">
            <span>OVERVIEW</span>
            <div className="py-3 row">
              <div className="col-3">
                <div className="card p-4 border overview-card">
                  <p className="m-0 text-muted p-0 input-inside-text">
                    Total Category
                  </p>
                  <h4>{totalCategory}</h4>
                </div>
              </div>
              <div className="col-3">
                <div className="card p-4 rounded overview-card border">
                  <p className="m-0 text-muted p-0 input-inside-text ">
                    Main Category
                  </p>
                  <h4>{mainCategory}</h4>
                </div>
              </div>
              <div className="col-3">
                <div className="card p-4 rounded overview-card border">
                  <p className="m-0 text-muted p-0 input-inside-text ">
                    Active Category
                  </p>
                  <h4>{totalActiveCategory}</h4>
                </div>
              </div>
              <div className="col-3">
                <div className="card p-4 overview-card rounded border">
                  <p className="m-0 text-muted p-0 input-inside-text">
                    Disable Category
                  </p>
                  <h4>{totalDisableCategory}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-2 py-3 mb-3 card ">
          <div className="card">
            <div className="card-header">
              <div className="card-title">
                <div className="row">
                  <div className="col-6">ALL CATEGORY</div>
                  <div className="col-6">
                    <div className="row float-right">
                      <div className="col-6">
                        <input
                          type="search"
                          placeholder="search"
                          className="form-control mx-3"
                          onChange={(e) => onChangeSeachData(e.target.value)}
                        />
                      </div>
                      <div className="col-3">
                        {ablity({model:'Product', action:'create'}) == 1 && (
                            <button
                              onClick={() => addCategory()}
                              type="button"
                              className="btn btn-dark btn-sm text-nowrap mx-3 mt-1"
                            >
                              {" "}
                              + Add Category
                            </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <TableContainer
              // style={{ minHeight:"54rem" ,overfloxY:'overlay'}}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  stickyHeader
                  aria-label="sticky table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell className="th_cell" component="th">
                        No
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div>Name</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("title", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("title", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="right"
                      >
                        <div className="d-flex  ">
                          <div>Products</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("product", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("product", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div> Sub Categories</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() =>
                                handleSorting("SubCategory", "asc")
                              }
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() =>
                                handleSorting("SubCategory", "desc")
                              }
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                      >
                        <div className="d-flex  ">
                          <div> Relevent Merchant</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("merchant", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("merchant", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className="th_cell"
                        component="th"
                        align="left"
                      >
                        <div className="d-flex  ">
                          <div> Status</div>
                          <div className="d-flex flex-column ">
                            <ExpandLessIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                              }}
                              onClick={() => handleSorting("status", "asc")}
                            />
                            <ExpandMoreIcon
                              style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                              }}
                              onClick={() => handleSorting("status", "desc")}
                            />
                          </div>
                        </div>
                      </TableCell>

                      <TableCell className="table_action_head" component="th">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {!categoryData?.length && (
                      // <div className="d-flex justify-content-center">

                      // </div>
                      <TableRow>
                        <TableCell colSpan={7} align="center">
                          <h4 className="text-muted">No data found!</h4>
                        </TableCell>
                      </TableRow>
                    )}
                    {categoryData?.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" className="td_cell">
                          {index + 1}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {row.title || "N/A"}
                        </TableCell>
                        <TableCell align="center" className="td_cell">
                          {row.product || "0"}
                        </TableCell>
                        <TableCell align="center" className="td_cell">
                          <Link
                            className="text-info underline"
                            to={{
                              pathname: "categories/" + row.id,
                              search: `?p=${row.title}`,
                            }}
                          >
                            {row.SubCategory.length || "0"}
                          </Link>
                        </TableCell>
                        <TableCell align="center" className="td_cell">
                          {row.merchant || "0"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {row.status || "N/A"}
                        </TableCell>
                        <TableCell className="d-flex justify-content-between table_action_item">
                          {ablity({model:'Product', action:'update'}) == 1 && (
                              <IconButton
                                className="pointer"
                                onClick={() => editCategory(row.id)}
                              >
                                <SvgIcon
                                  src="/icons/edit_icon.svg"
                                  alt="view icon"
                                />
                              </IconButton>
                          )}

                          {ablity({model:'Product', action:'delete'}) == 1 && (
                              <IconButton
                                className="pointer"
                                onClick={() => deleteCategory(row.id)}
                              >
                                <SvgIcon
                                  src="/icons/delete_icon.svg"
                                  alt="delete icon"
                                />
                              </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductCategory;
