import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IOSSwitch } from "../../../../../components/switch/IOSSwitch";

import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import Image from "../../../../../components/Images/Image";
import {
  AllowAccess,
  open_dialog,
  parseQuery,
  startLoader,
  stopLoader,
} from "../../../../../lib/global";
import { MERCHANT_FLASH_SALE_DUPLICATE_ADD_PRODUCT } from "../../../../../lib/constant";
import InputField from "../../../../../components/formControl/input/InputField";
import SelectInput from "../../../../../components/select";
import {
  createFlashSaleProduct,
  getAllFlashSaleProductByTimeSlotId,
  getTimeSLotBySlotId,
  updateFlashSaleProductByTimeSlotID,
} from "../../../../../redux/actions/marketing.action";
import { getLocalStorage } from "../../../../../lib/session";
import { v2GetAllFlashSaleProductByTimeSlotIdByMerchant } from "../../../../../redux/actions/merchant/marketing.merchant.action";

const MerchantFlashDealsTimeSlotDuplicate = () => {
  AllowAccess(["MERCHANT"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, date, promoslotid } = parseQuery(history.location.search) || {};
  const [allProductData, setAllProductData] = useState([]);
  const [allProductDataTemp, setAllProductDataTemp] = useState([]);
  const [newProductData, setNewProductData] = useState([]);
  let filterData = {};
  let productData = [];
  let newData = [];
  const [variationInfo, setVariationInfo] = useState({});
  const [variationData, setVariationData] = useState([]);
  const [errors, setErrors] = useState({});
  const [isActived, setIsActived] = useState(0);
  const [timeSlotDateState, setTimeSlotDateState] = useState();
  const [buttonTimeSlot, setButtonTimeSlot] = useState(false);

  useEffect(() => {
    apiData(id);
  }, []);

  const checkExistTimeSlot = (timeSlotDate) => {
    setTimeSlotDateState(timeSlotDate);
    filterData = {
      merchantId: getLocalStorage("storeId"),
      timeSlotDate: timeSlotDate,
    };
    dispatch(getTimeSLotBySlotId(promoslotid, filterData))
      .then((data) => {
        if (data.data.data.length > 0) {
          setButtonTimeSlot(false);
          window.alert("Time Slot Available, Please change another time slot");
        } else {
          setButtonTimeSlot(true);
        }

        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log({ err });
      });
  };

  const apiData = (timeSlotId) => {
    startLoader();
    dispatch(v2GetAllFlashSaleProductByTimeSlotIdByMerchant(timeSlotId))
      .then((data) => {
        setAllProductData(
          data &&
            data.data &&
            data.data.data.map((row, index) => {
              return {
                // flashSaleProductId: row?.flashSaleProductId,
                productId: row?.product.productId,
                productImages: row?.product.productImages[0],
                title: row?.product.title,
                variation: row?.product.variation,
                variationList: row?.variation,
              };
            })
        );
        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
      });
  };

  const handelAddProduct = () => {
    open_dialog(MERCHANT_FLASH_SALE_DUPLICATE_ADD_PRODUCT, {
      onSubmit: selectProductSuccess,
      allProductData: allProductData,
      setAllProductData: setAllProductData,
      merchantId: getLocalStorage("storeId"),
    });
  };

  const selectProductSuccess = (data) => {
    console.log(data);
    setAllProductData(data);
  };

  const validationBeforeConfirm = () => {
    const errorsList = {
      campaignStock: false,
      unitCapped: false,
      discountPrice: false,
    };

    allProductData.map((row, index) => {
      row.variationList.map((row2, idx) => {
        if (!row2.campaignStock) {
          errorsList.campaignStock = true;
        }
        if (!row2.unitCapped) {
          errorsList.unitCapped = true;
        }
        if (!row2.discountPrice) {
          errorsList.discountPrice = true;
        }
      });
    });

    setErrors((pre) => ({ ...pre, ...errorsList }));
    return errorsList;
  };

  const saveActionAll = async () => {
    startLoader();
    allProductData.map((row, index) => {
      console.log(row);
      // productData.push(row)
      let productDataItem = {
        productId: row.productId,
        variation: row.variationList,
      };
      productData.push(productDataItem);
    });

    let payload = {
      promoSlotId: promoslotid,
      merchantId: getLocalStorage("storeId"),
      date: timeSlotDateState,
      productData: productData,
    };

    setTimeout(() => {
      dispatch(createFlashSaleProduct(payload))
        .then((response) => {
          stopLoader();
          history.push(
            `/merchant/marketing/promo/flash-deals/time-slot-listing?promoslotid=${promoslotid}`
          );
        })
        .catch((error) => {
          stopLoader();
          console.error("Failed to upload product!", error);
        });
    }, 500);
  };

  const onChangeVariation = (
    newProductData,
    productIndex,
    variationIndex,
    e
  ) => {
    const key = e.target.name;
    const value = e.target.value;
    let tempProductData = [];

    allProductData.map((row, index) => {
      let tempProduct = row;
      if (index === productIndex) {
        tempProduct.variationList.map((rowData, indexData) => {
          if (indexData === variationIndex) {
            if (key === "campaignStock") {
              rowData.campaignStock = value;
            } else if (key === "unitCapped") {
              rowData.unitCapped = value;
            } else if (key === "discountPrice") {
              rowData.discountPrice = value;
            } else if (key === "teamCampaignStock") {
              rowData.teamCampaignStock = value;
            } else if (key === "teamUnitCapped") {
              rowData.teamUnitCapped = value;
            } else if (key === "teamDiscountPrice") {
              rowData.teamDiscountPrice = value;
            }
          }
        });
      }
      tempProductData.push(tempProduct);
    });
    setAllProductData(tempProductData);
    setNewProductData(tempProductData);
  };

  const onChangeStatus = (arrayVariation, row, i, e) => {
    const keyCheck = e.target.name;
    const valCheck = e.target.checked;
    arrayVariation.map((rowData, indexData) => {
      if (indexData == i) {
        if (keyCheck === "isActive") {
          rowData.isActive = valCheck;
          rowData.teamIsActive = valCheck;
        }
        // else if (keyCheck === "teamIsActive") {
        //   rowData.teamIsActive = valCheck;
        // }
      }
    });
    validationBeforeConfirm();
  };

  return (
    <React.Fragment>
      <div className=" d-flex flex-column justify-content-between container_body vh-100">
        <div className="container-fluid">
          <div className="card p-4 px-5 mb-3">
            <h6>BASIC INFORMATION</h6>
            <br />
            <div>
              <div className="row">
                <label className="col-md-3 d-flex justify-content-end">
                  Time Slot
                </label>
                <input
                  type="date"
                  className="form-control col-md-3"
                  onChange={(e) => checkExistTimeSlot(e.target.value)}
                />
              </div>
              <br />
              <div className="row">
                <label className="col-md-3 d-flex justify-content-end">
                  Product criteria
                </label>
                <textarea
                  id="textarea"
                  className="form-control col-md-7"
                  style={{ minHeight: "20vh" }}
                />
              </div>
            </div>
          </div>

          <div className="card p-4 mb-3">
            <div>
              <div className="float-left">
                <h6>EDIT FLASH DEALS PRODUCT</h6>
                <p className="fntSz14 mb-0 text-secondary">
                  Edit product to participate in flash sale event.
                </p>
              </div>
              <div className={`float-right d-block`}>
                <button
                  onClick={() => handelAddProduct()}
                  type="button"
                  className="rounded btn btn-danger text-nowrap p-2 px-4"
                >
                  Add Product
                </button>
              </div>
            </div>
            <hr />
            <br />
            <br />

            {allProductData &&
              allProductData.length > 0 &&
              allProductData.map((row, index) => (
                <div className="mb-3" key={"all-product-data-" + row.productId}>
                  <div>
                    <div className="float-left d-flex align-items-center flex-nowrap ">
                      <Image
                        src={row.productImages}
                        alt={"photo"}
                        className="teamPurchase_page_product_image"
                      />
                      <div className="px-2">{row.title}</div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <div className={`float-right d-block w-100`}>
                    <div
                      className="border"
                      style={{ borderRadius: "10px", overflow: "hidden" }}
                    >
                      <div className=" bg-light">
                        <div className="pl-3 pt-3 mb-0 fntSz18 bg-light">
                          Individual Purchase
                        </div>
                        <hr />
                      </div>
                      <div className="mx-3">
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 650 }}
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <TableHead>
                              <TableRow>
                                {row.variation &&
                                  row.variation.length > 0 &&
                                  row.variation.map((item, idx) => (
                                    <TableCell
                                      className="th_cell"
                                      component="th"
                                      align="left"
                                      key={idx}
                                    >
                                      <div className="d-flex  ">
                                        <div>{item?.name}</div>
                                      </div>
                                    </TableCell>
                                  ))}
                                <TableCell
                                  className="th_cell"
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Original Price</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Discounted Price</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Campaign Stock</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Stock</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Unit Capped</div>
                                  </div>
                                </TableCell>

                                <TableCell
                                  className="table_action_head text-secondary"
                                  component="th"
                                >
                                  Enable/Disable
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.variationList &&
                                row.variationList.length > 0 &&
                                row.variationList
                                  .filter((item) => {
                                    return (
                                      item[
                                        row.variation.map(
                                          (item) => item.name
                                        )[0]
                                      ].trim().length !== 0
                                    );
                                  })
                                  .map((row1, index1) => (
                                    <TableRow
                                      key={index1}
                                      className="border-0"
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      {row.variation.map((item, idx) => (
                                        <TableCell
                                          align="left"
                                          className="td_cell border-0"
                                          key={idx}
                                        >
                                          {row1[item.name]}
                                        </TableCell>
                                      ))}
                                      <TableCell
                                        align="left"
                                        className="td_cell border-0"
                                      >
                                        RM. {row1?.price || "0"}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className="td_cell border-0"
                                      >
                                        <InputField
                                          className="input_icon_left"
                                          formClass="col-12 p-0"
                                          type="number"
                                          id="discountPrice"
                                          icon="currency"
                                          placeholder="Input"
                                          name="discountPrice"
                                          value={row1?.discountPrice}
                                          onChange={(e) =>
                                            onChangeVariation(
                                              allProductData,
                                              index,
                                              index1,
                                              e
                                            )
                                          }
                                          required={false}
                                        />
                                      </TableCell>

                                      <TableCell
                                        align="left"
                                        className="td_cell border-0"
                                      >
                                        <input
                                          type="number"
                                          id="campaignStock"
                                          name="campaignStock"
                                          placeholder="Input"
                                          className="w-75 form-control"
                                          value={row1?.campaignStock}
                                          onChange={(e) =>
                                            onChangeVariation(
                                              allProductData,
                                              index,
                                              index1,
                                              e
                                            )
                                          }
                                          required={false}
                                        />
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className="td_cell border-0"
                                      >
                                        {row1?.stock || "N/A"}
                                      </TableCell>
                                      <TableCell
                                        align="left"
                                        className="td_cell border-0"
                                      >
                                        <input
                                          type="text"
                                          placeholder="Input"
                                          className="w-75 form-control"
                                          id="unitCapped"
                                          name="unitCapped"
                                          value={row1?.unitCapped}
                                          onChange={(e) =>
                                            onChangeVariation(
                                              allProductData,
                                              index,
                                              index1,
                                              e
                                            )
                                          }
                                          required={false}
                                        />
                                      </TableCell>

                                      <TableCell className="d-flex border-0 table_action_item">
                                        <IOSSwitch
                                          name="isActive"
                                          checked={
                                            row1.isActive == true ? true : false
                                          }
                                          value={row1?.isActive}
                                          onChange={(e) =>
                                            onChangeStatus(
                                              row.variationList,
                                              row1,
                                              index1,
                                              e
                                            )
                                          }
                                        />
                                      </TableCell>
                                    </TableRow>
                                  ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                    <br />

                    <div
                      className="border"
                      style={{ borderRadius: "10px", overflow: "hidden" }}
                    >
                      <div className=" bg-light">
                        <div className="pl-3 pt-3 mb-0 fntSz18">
                          Team Purchase
                        </div>
                        <hr />
                      </div>
                      <div className="mx-3">
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 650 }}
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <TableHead>
                              <TableRow>
                                {row.variation &&
                                  row.variation.length > 0 &&
                                  row.variation.map((item, idx) => (
                                    <TableCell
                                      className="th_cell"
                                      component="th"
                                      align="left"
                                      key={idx}
                                    >
                                      <div className="d-flex">
                                        <div>{item?.name}</div>
                                      </div>
                                    </TableCell>
                                  ))}
                                <TableCell
                                  className="th_cell"
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Original Price</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Discounted Price</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Campaign Stock</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Stock</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Unit Capped</div>
                                  </div>
                                </TableCell>

                                {/* <TableCell
                                  className="table_action_head text-secondary"
                                  component="th"
                                >
                                  Enable/Disable
                                </TableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.variationList &&
                                row.variationList.length > 0 &&
                                row.variationList
                                  .filter((item) => {
                                    return (
                                      item[
                                        row.variation.map(
                                          (item) => item.name
                                        )[0]
                                      ].trim().length !== 0
                                    );
                                  })
                                  .map((row2, index2) => (
                                    <TableRow
                                      key={index2}
                                      className="border-0"
                                      sx={{
                                        "&:last-child td, &:last-child th": {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      {row2.teamIsActive == true && (
                                        <>
                                          {row.variation.map((item, idx) => (
                                            <TableCell
                                              align="left"
                                              className="td_cell border-0"
                                              key={idx}
                                            >
                                              {row2[item.name]}
                                            </TableCell>
                                          ))}
                                          <TableCell
                                            align="left"
                                            className="td_cell border-0"
                                          >
                                            RM. {row2?.teamPrice || "0"}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className="td_cell border-0"
                                          >
                                            <InputField
                                              className="input_icon_left"
                                              formClass="col-12 p-0"
                                              type="number"
                                              id="teamDiscountPrice"
                                              icon="currency"
                                              placeholder="Input"
                                              name="teamDiscountPrice"
                                              value={row2?.teamDiscountPrice}
                                              onChange={(e) =>
                                                onChangeVariation(
                                                  allProductData,
                                                  index,
                                                  index2,
                                                  e
                                                )
                                              }
                                              required={false}
                                            />
                                          </TableCell>

                                          <TableCell
                                            align="left"
                                            className="td_cell border-0"
                                          >
                                            <input
                                              type="text"
                                              placeholder="Input"
                                              className="w-75 form-control"
                                              id="teamCampaignStock"
                                              name="teamCampaignStock"
                                              value={row2?.teamCampaignStock}
                                              onChange={(e) =>
                                                onChangeVariation(
                                                  allProductData,
                                                  index,
                                                  index2,
                                                  e
                                                )
                                              }
                                              required={false}
                                            />
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className="td_cell border-0"
                                          >
                                            {row2?.stock || "N/A"}
                                          </TableCell>
                                          <TableCell
                                            align="left"
                                            className="td_cell border-0"
                                          >
                                            <input
                                              type="text"
                                              placeholder="Input"
                                              className="w-75 form-control"
                                              id="teamUnitCapped"
                                              name="teamUnitCapped"
                                              value={row2?.teamUnitCapped}
                                              onChange={(e) =>
                                                onChangeVariation(
                                                  allProductData,
                                                  index,
                                                  index2,
                                                  e
                                                )
                                              }
                                              required={false}
                                            />
                                          </TableCell>

                                          {/* <TableCell className="d-flex border-0 table_action_item">
                                          <IOSSwitch
                                            checked={
                                              row2.teamIsActive == true
                                                ? true
                                                : false
                                            }
                                            value={row2?.teamIsActive}
                                            name="teamIsActive"
                                            onChange={(e) =>
                                              onChangeStatus(
                                                row.variationList,
                                                row2,
                                                index2,
                                                e
                                              )
                                            }
                                          />
                                        </TableCell> */}
                                        </>
                                      )}
                                    </TableRow>
                                  ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              ))}
          </div>
        </div>

        <footer className="bg-white p-3 mt-5">
          <div className={`d-block`}>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  history.push(
                    `/merchant/marketing/promo/flash-deals/time-slot-listing?promoslotid=${promoslotid}`
                  )
                }
                type="button"
                className="btn btn-outline-danger mx-2"
              >
                Cancel
              </button>
              <button
                onClick={() => saveActionAll()}
                type="button"
                className={`btn btn-danger mx-2 d-${
                  buttonTimeSlot == true ? "block" : "none"
                }`}
              >
                Confirm
              </button>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default MerchantFlashDealsTimeSlotDuplicate;
