import { Button } from "@mui/material";
import React from "react";

/**
 * @description use this for material ui button
 * @author jagannath
 * @date 10/06/2021
 * @param variant: contained | outlined | text
 * @param title | label - button text
 */
const MuiButton = (props) => {
  const { variant, title, label, ...rest } = props;
  return (
    <React.Fragment>
      <Button variant={variant || "contained"} {...rest}>
        {label || title || props.children}
      </Button>
    </React.Fragment>
  );
};

export default MuiButton;
