import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InputField from "../../components/formControl/input/InputField";
import Image from "../../components/Images/Image";
import { AllowAccess, showToast, startLoader, stopLoader } from "../../lib/global";
import { breadcrumbAction } from "../../redux/actions/config.action";
import { addBrandApi, getBrandById, updateBrandById } from "../../services/brand.service";
import SelectDropdown from '../../components/select/index'


const CreateNewTicket = () => {
  AllowAccess(["MERCHANT"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [brandName, setBrandName] = useState();
  const [description, setDescription] = useState();
  const [imageUrls, setImageUrls] = useState([]);

  const { id } = useParams()

  useEffect(() => {


    if (id) {
      startLoader();
      dispatch(getBrandById(id))
        .then((data) => {
          stopLoader()
          console.log(data);
          const { brandName, description } = data.data.data
          setBrandName(brandName)
          setDescription(description)
        })
        .catch((error) => {
          stopLoader
          console.log({ error }, 'error')
        })

    }
  }, [id])


  useEffect(() => {
    const breadcrumb = [
      { label: "Help Center", url: "/merchant/help-center" },
      { label: id ? "Edit Ticket" : "Create New Ticket", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
  }, [])


  const addBrandFun = () => {
    startLoader();
    const data = {
      brandName,
      description,

    };
    if (id) {
      dispatch(updateBrandById(id, data))
        .then((data) => {
          console.log(data);
          if (data && data.data && data.data.status === 201) {
            setTimeout(() => {
              showToast(data.data.message);
              stopLoader();
              history.push("/help-center/create-new-ticket");
            }, 2000);
          } else {
            showToast(data.data.message);
            stopLoader();
            history.push("/help-center/create-new-ticket");
          }
        })
        .catch((error) => {
          console.log(error);
          stopLoader();
        });



    } else {
      const response = dispatch(addBrandApi(data))
      response
        .then((data) => {
          if (data && data.data && data.data.status === 200) {
            setTimeout(() => {
              showToast(data.data.message);
              stopLoader();
              history.push("/help-center/create-new-ticket");
            }, 2000);
          } else {
            stopLoader();
          }
        })
        .catch((error) => {
          console.log(error);
          stopLoader();
        });
    }

  };
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-between flex-column h-100">
        <div>
            <h6 className="mx-4">NEW TICKET INFORMATION</h6>
        </div>
        <div className="mr-3 ml-4 mt-3 rounded align-item-start px-3 pt-4 pb-2 bg-white">
          <form className="mt-3 p-0">
            <div className="col-12">
              <label className="small imp">
                Tell us your feedback
              </label>
              <br/>
              <small className="text-secondary m-0 p-0">Please use this form to raise your enquiries or complaints</small>
            </div>
            <hr/>
            <div className="col-9 mb-2">
              <label className="small imp">
                Topic
              </label>
              <SelectDropdown
                className="dropdown-menu"
                formClass="col-9"
                type="text"
                placeholder="Select a topic"
                value={brandName}
                onChange={(e) => setBrandName(e.target.value)}
                required={true}
              />
            </div>
            <div className="col-12 mb-2">
              <label className="small imp">
                Your Issue
              </label>
              <InputField
                className=""
                formClass="col-9 pl-0"
                type="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Explain your issue"
                // style={{paddingLeft:'0'}}
              />
            </div>
            <div className="col-12 my-3">
                <label className="small imp">
                    Images
                </label>
                <div className="icon-button-file pl-0 col-9 d-flex flex-wrap">
                    {[0, 1, 2].map((item) => (
                        <div className="mx-2">
                            <input
                                accept="image/*"
                                id={`product-image-${item}`}
                                className="d-none"
                                onChange={(e) => handleUploadFile(e, item)}
                                multiple={false}
                                type="file"
                                disabled
                            />
                            <label htmlFor={`product-image-${item}`}>
                                <Image
                                    src={imageUrls?.[item] || "/icons/upload_icon.svg"}
                                    alt="upload"
                                    className={`pointer upload_image_label`}
                                />
                            </label>
                        </div>
                    ))}
                </div>
                <div>
                    <ol className="mx-3">
                        <small>
                            <li className="text-secondary">Size: Max 5MB</li>
                            <li className="text-secondary">Up to 3 files</li>
                            <li className="text-secondary">Format: JPG, PNG</li>
                        </small>
                    </ol>
                </div>
            </div>
          </form>
        </div>
        <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
          <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
            <button
              onClick={history.goBack}
              type="button"
              className="btn border input-inside-text mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-dark btn-sm mr-2"
              onClick={() => addBrandFun()}
              disabled={!brandName || !description}
            >
              {id ? "Save" : "Create"}
            </button>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default CreateNewTicket;