import { Get, PatchWithToken, Post, UpdateById, Patch } from "../../lib/request";
import { INIT_LANGUAGE_CHANGE, IS_MOBILE } from "../actionTypes";

export const setMobileView = (data) => {
    // console.log('data', data)
    return {
        type: IS_MOBILE,
        payload: data,
    };
};

export const setLanguage = (selectedLang) => {
    return {
        type: INIT_LANGUAGE_CHANGE,
        payload: selectedLang,
    };
};

export const loginApi = (payload) => {
    return async function (dispatch) {
        const result = await Post("/merchants/login", payload);
        return result
    }
}

export const forgetPasswordSendLink = (payload) => {
    return async function (dispatch) {
        const result = await Post("/merchants/forget-password", payload);
        console.log(result);
        return result
    }
}

export const forgetPasswordUpdate = (payload) => {
    // console.log(payload);
    return async function (dispatch) {
        const result = await Patch("/merchants/forget-password-update", payload);
        return result
    }
}

export const consumerForgetPasswordUpdate = (payload) => {
    // console.log(payload);
    return async function (dispatch) {
        const result = await Patch("/website/consumer/forget-password-update", payload);
        return result
    }
}



export const changesPassword = (data) => {
    return async function (dispatch) {
        const result = await PatchWithToken('/merchants/forget-possword', data);
        return result;
    }
}