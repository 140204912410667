import { DeleteWithToken, GetWithToken, PatchWithToken, PostWithToken, PutWithToken } from "../../lib/request";

export const assignRole = (data) => {
    return async function (dispatch) {
        // const result = await PostWithToken('/user-role', data);
        const result = await PostWithToken('/auth/signup', data);
        console.log(result, data);
        return result;
    }
}

export const getRole = () => {
    return async function (dispatch) {
        const result = await GetWithToken('/roles');
        return result;
    }
}

export const getAllRoleUser = (query) => {
    return async function (dispatch) {
        // const result = await GetWithToken(`/user-role?userName=${query.userName || ""}&approvedDate=${query.approvedDate || ""}`)
        const result = await GetWithToken(`/user`);
        return result;
    }
}
export const getAllRoleUserById = (userId) => {
    return async function (dispatch) {
        // const result = await GetWithToken(`/user-role/getUser/?userId=${userId || ""}`)
        const result = await GetWithToken(`/user/${userId}`);
        return result;
    }
}

export const UpdateUserRoleByRoleId = (userId,data) => {
    return async function (dispatch) {
        // const result = await PatchWithToken(`/user-role/${userId}`,data)
        const result = await PatchWithToken(`/user/${userId}`,data)
        return result;
    }
}

export const deleteUserRoleByRoleId = (data) => {
    return async function (dispatch) {
        // const result = await DeleteWithToken(`/user-role/${roleId}`)
        const result = await PutWithToken(`/user/update-user-status`, data)
        return result;
    }
}

// export const updateProduct = (data, id) => {
//     return async function (dispatch) {
//         const result = await PutWithToken(`/store/products/${id}`, data);
//         return result;
//     }
// }


// export const getAllProductListAfterDelete = (query = {}) => {
//     return async function (dispatch) {
//         const result = await GetWithToken("/product")
//         console.log(result);
//         return result;
//     }
// }

// export const getProductById = (id) => {
//     return async function (dispatch) {
//         const result = await GetWithToken(`/product/${id}`);
//         return result;
//     }
// }

// export const getStoreProducts = (query) => {
//     return async function (dispatch) {
//         const result = await GetWithToken("/merchant/product")
//         return result;
//     }
// }


// export const addNewProductByStore = (data) => {
//     return async function (dispatch) {
//         const result = await PostWithToken("/store/products", data)
//         return result;
//     }
// }


// export const deleteProduct = (id) => {
//     return async function (dispatch) {
//         const result = await DeleteWithToken(`/product/${id}`)
//         return result;
//     }
// }

// export const getProductsBymerchantId = (id, search) => {
//     console.log(search, "bbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbbb");
//     return async function (dispatch) {
//         const result = await GetWithToken(`/product/storeid/${id}?productName=${search?.productName || ''}&categoryId=${search?.categoryId?.value || ''}`)
//         // console.log(result, "jjjjjjjjjjjjjjjjjmmmmmmmmmmmmmmmmmmmm");
//         return result;
//     }
// }

