import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import Button from "../../components/buttons";
import CustomCheckbox from "../../components/checkbox";
import Input from "../../components/formControl/input";
import { CATEGORY_SELECTION_DIALOG } from "../../lib/constant";
import { AllowAccess, open_dialog, setSessionData, showToast } from "../../lib/global";
import {
  getLocalStorage,
  removeLocalStorageKey,
  setLocalStorage,
} from "../../lib/session";
import { changesPassword, loginApi } from "../../redux/actions/auth.action";

const ChangesPassword = () => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loginId, setLoginId] = useState("");
  const [newPassword, setPassword] = useState("");
  const [password, setOldPassword] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const payload = { loginId, newPassword, password, storeId: id };
    dispatch(changesPassword(payload))
      .then((response) => {
        history.push("/auth");
      })
      .catch((error) => {
        console.log(error);
        showToast(
          error?.response?.data?.message || "Failed to login!",
          "error"
        );
      });
  };

  return (
    <React.Fragment>
      <div className="vh-100 landing_image position-relative">
        <div className="position-absolute w-100 h-100">
          <img
            className="landing_image_src"
            src="/images/loginPageBackgroundNew.png"
            alt="login page background image"
          />
        </div>
        <br />
        <br />
        <div className="m-auto px-5 h-100 d-flex flex-column justify-content-between">
          <div className="row">
            <div className="col-2">
              <h3 className="font-weight-bold mb-1">
                <a href="https://uu2.co/">HOME</a>
              </h3>
              <div>
                <a href="https://uu2.co/">ABOUT US</a>
              </div>
              <div>
                <a href="https://uu2.co/story/">OUR STORY</a>
              </div>
              <div>
                <a href="https://uu2.co/about-us/">CONTACT US</a>
              </div>
            </div>
            <div className="col-7">
              <h1 className="display-1 font-weight-bolder pt-3">Welcome</h1>
            </div>
          </div>
        </div>
        <div className="p-4 position-absolute text-white login_input_box_section">
          <div>
            <form onSubmit={handleSubmitForm}>
              <h6 className="mt-4">UU2 Admin Site</h6>
              <h4 className="mt-3">Change your password</h4>
              <p className="text-nowrap small">
                The best deals happen when you buy together
              </p>
              <div className="mt-2">
                <Input
                  className="input__textField mt-2 w-100"
                  type="text"
                  name="email"
                  id="email"
                  onChange={(e) => setLoginId(e.target.value)}
                  value={loginId}
                  placeholder="Enter your ID"
                />
              </div>
              <div className="mt-2">
                <Input
                  className="input__textField mt-2 w-100"
                  type="password"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => setOldPassword(e.target.value)}
                  placeholder="Enter old password"
                />
              </div>
              <div className="mt-2">
                <Input
                  className="input__textField mt-2 w-100"
                  type="password"
                  name="password"
                  id="password"
                  value={newPassword}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter new password"
                />
              </div>
              <Button type="submit" role="button" className="login_btn mt-4">
                {loading ? "loading..." : "Submit"}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ChangesPassword;
