import React from "react";

import "../../../../../../static/css/deleteModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  showToast,
  startLoader,
  stopLoader,
} from "../../../../../../lib/global";
import {
  deleteGroupDealsProduct,
  getAllGroupDealsProduct,
} from "../../../../../../redux/actions/marketing.action";
import { getLocalStorage } from "../../../../../../lib/session";
import { v2DeleteGroupDealsProduct, v2GetAllGroupDealsProduct } from "../../../../../../redux/actions/merchant/marketing.merchant.action";
const DeleteGroupDealProductModal = (props) => {
  const dispatch = useDispatch();

  const deleteFun = () => {
    startLoader();
    dispatch(v2DeleteGroupDealsProduct(props.id)).then((data) => {
      if (data.data.status == 200) {
        let payload = {
          groupDealsId: props.groupDealsId,
          merchantId: getLocalStorage("storeId"),
        };
        const response = dispatch(v2GetAllGroupDealsProduct(payload));
        response
          .then((data) => {
            setTimeout(() => {
              stopLoader();
              showToast("Group Deal Product deleted succesfully", "success");
              props.onSubmit([...(data?.data?.data || [])]);
            }, 1000);
            props.onClose();
          })
          .catch((error) => {
            console.log({ error });
            props.onClose();
            stopLoader();
          });
      } else {
        stopLoader();
        props.onClose();
        showToast("Internal Server Error", "error");
      }
    });
  };
  return (
    <React.Fragment>
      <div className="delete_modal_body">
        <div className="close_icon" style={{ top: "0px" }}>
          <CloseIcon
            className="close_icon_btn m-2"
            color="primary2"
            fontSize="large"
            title="Close dialog"
            onClick={props.onClose}
          />
        </div>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="confirmation_dialogbox__content">
            <div className="dialogbox_body__content">
              <h1 className="d-flex flex-direction-row justify-content-center align-items-center">
                <span className="dialogbox_header__icon">!</span>
              </h1>
              <h4>Are you sure you want to delete?</h4>
              <p>
                Delete this will delete the entire live stream data.
                <br />
                You are unable to restore back again.
              </p>
            </div>
          </div>
        </div>
        <div className="dialogbox_footer__content">
          <button
            type="button"
            className="dialogbox_delete__btn"
            onClick={() => deleteFun()}
          >
            Delete
          </button>
          <button
            type="button"
            className="dialogbox_cancel__btn"
            onClick={props.onClose}
          >
            Cancel
          </button>
        </div>
      </div>
      <style jsx="true">{`
        .card-body {
          min-height: 50vh;
        }
        .submit_btn {
          position: absolute;
          bottom: 15px;
          width: 100%;
          justify-content: center;
          display: flex;
        }
        .close_icon_btn {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      `}</style>
    </React.Fragment>
  );
};

export default DeleteGroupDealProductModal;
