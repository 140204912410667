import { IconButton } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SvgIcon from "../../../components/Images/SvgIcon";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import "../../../static/css/marketingCss/marketing.css"
import { AllowAccess } from "../../../lib/global";


function MerchantMerketingAllPromo() {
  AllowAccess(["MERCHANT"]);
  const history = useHistory()
  const dispatch = useDispatch()

  const breadcrumb = [
    { label: "All Promo", url: null }
  ];
  dispatch(breadcrumbAction(breadcrumb));

  return (
    <React.Fragment>
      <div className="card m-3 p-3">
        <h5>UU2 OFFICIAL EVENTS</h5>
        <div className="row">
          <div className="col-4 p-2">
            <div className="p-3 rounded marketing_Promo_Official_Card" onClick={() => history.push("/merchant/marketing/promo/flash-deals")}>
              <div>
                <p>Flash Deals</p>
                <p className="text-secondary">Lower price than before</p>
              </div>

              <div className="d-flex justify-content-end w-100 pointer" >
                <img
                  height={"40px"}
                  src="/icons/next_arrow.png"
                  alt="view icon"

                />
              </div>
            </div>
          </div>
          <div className="col-4 p-2">
            <div className="p-3 rounded marketing_Promo_Official_Card" onClick={() => history.push("/merchant/marketing/promo/livestream")}>
              <div>
                <p>Livestream</p>
                <p className="text-secondary">Increase exspresure and sales</p>
              </div>

              <div className="d-flex justify-content-end w-100 pointer" >
                <img
                  height={"40px"}
                  src="/icons/next_arrow.png"
                  alt="view icon"

                />
              </div>
            </div>
          </div>
          <div className="col-4 p-2">
            <div className="p-3 rounded marketing_Promo_Official_Card" onClick={() => history.push("/merchant/marketing/promo/voucher")}>
              <div>
                <p>Vouchers</p>
                <p className="text-secondary">Vouchers</p>
              </div>

              <div className="d-flex justify-content-end w-100 pointer" >
                <img
                  height={"40px"}
                  src="/icons/next_arrow.png"
                  alt="view icon"

                />
              </div>
            </div>
          </div>
          <div className="col-4 p-2">
            <div className="p-3 rounded marketing_Promo_Official_Card" onClick={() => history.push("/merchant/marketing/promo/group-deals")}>
              <div>
                <p>Group Deals</p>
                <p className="text-secondary">Target group of people to join the event to hit the target</p>
              </div>

              <div className="d-flex justify-content-end w-100 pointer" >
                <img
                  height={"40px"}
                  src="/icons/next_arrow.png"
                  alt="view icon"

                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MerchantMerketingAllPromo
