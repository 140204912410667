import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import formatDate from "../../helpers/date.format";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#eaeaf0",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#4af457",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#fff",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#ffffff",
    zIndex: "1",
    fontSize: 20,
    padding: 1,
    background: "#30ff00",
    borderRadius: "50%",
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "#eaeaf0",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

// const steps = [
//   { status: "Order Placed", time: "02-11-2022 11:30pm" },
//   { status: "Order Paid", time: "02-11-2022 11:30pm" },
//   { status: "Preparing To Ship", time: "02-11-2022 11:30pm" },
//   { status: "Order Shipped", time: "02-11-2022 11:30pm" },
//   { status: "Order Delivered", time: "02-11-2022 11:30pm" },
// ];



export default function CustomizedSteppers(props) {
  const steps = [
    { status: "Preparing To Ship", time: props.timeline?.paidDate },
    { status: "Picked Up", time: props.timeline?.pickupDate },
    { status: "On Delivery", time: props.timeline?.onDeliveryDate },
    { status: "Delivered", time: props.timeline?.deliveredDate },
  ];

  // const timeline = props.timeline;
  const timeline = [
    props.timeline?.paidDate,
    props.timeline?.pickupDate,
    props.timeline?.onDeliveryDate,
    props.timeline?.deliveredDate
  ];
  const totalStep = (timeline && timeline.length > 0 && timeline.filter((item) => item !== null).length) || 0;
  const data = steps && steps.map((item, index) => {
      return { ...item, time: timeline && timeline[index] };
    });

  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={totalStep}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <div style={{ fontSize: "13px", lineHeight: "4px" }}>
                {label.status}
              </div>
              <span style={{ fontSize: "10px" }}>
                {label.time ? formatDate(label.time,"MM-DD-YYYY hh:mma") : "- - -"}
              </span>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
