import React from "react";

const OrderIncomeBalance = () => {
    return(
        <React.Fragment>
            kashi2
        </React.Fragment>
    )
}

export default OrderIncomeBalance;