import { Post, Get,Put, PutWithToken } from "../lib/request"

/**
 * @description method to post add merchant
 * @author prabhkar
 * @date 11/01/2022
 */
export const addCategoryApi = (data) => {
    // console.log(data, "dddddddddddddddddddddddddddddd");
    return Post('/categories', data)
}

export const updateCategoryApi = (data,id) => {
    console.log(data,id,"ghjkl");
    // console.log(data, "dddddddddddddddddddddddddddddd");
    return PutWithToken(`/categories/${id}`, data)
}

export const getParentApi = (data) => {
    return Get('/categories/get-parent-category')
}


export const getAllCategoryApi = (data) => {
    return Get('/categories')
}