import React from "react";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { BsQuestionCircle } from "react-icons/bs";
import Checkbox from "@mui/material/Checkbox";

const ListButtonItem = (props) => {
  const { item, index, categoriesList, setCategoriesList, statusDate } = props;

  const handleCategories = (e, title) => {
    console.log(e);
    console.log("111111111111111111111111111");
    console.log(title);
    let { value, checked } = e.target;
    let temp = [...categoriesList];
    if (checked) {
      temp = temp.filter((cate) => cate != value);
      temp.push(value);
    } else {
      temp = temp.filter((cate) => cate != value);
    }
    setCategoriesList([...temp]);
    // if (temp.includes(title)) {
    //   temp = temp.filter((cate) => cate != title);
    //   temp.push(title);
    // } else {
    //   temp = temp.filter((cate) => cate != title);
    // }
    // setCategoriesList([...temp]);
  };

  return (
    <div className="list_card m-2 pointer rounded btn btn-light" key={index}>
      <div className="card_item">
        <div className="count">
          <span className="bold text-dark font-weight-bold badge">
            {item.title} <BsQuestionCircle />
            <span className="text-muted">
              <Checkbox
                value={item.title}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 17 } }}
                onChange={(e) => handleCategories(e, item.title)}
              />
            </span>
          </span>
        </div>
        <div className="count">
          <p className="bold text-dark ml-1">
            {item?.currency || ""}{" "}
            <span className="h4 bold text-dark font-weight-bold">
              {item.amount}
            </span>
          </p>
        </div>
        <div className="d-flex flex-row justify-content-between badge">
          <p className="text-muted">vs Previous {statusDate}</p>
          {item?.percentage < 50 ? (
            <p className="text-danger font-weight-bold">
              <AiFillCaretDown />
              {`${Math.abs(item.percentage).toFixed(1)}%`}
            </p>
          ) : (
            <p className="text-success text- font-weight-bold">
              <AiFillCaretUp />
              {`${Math.abs(item.percentage).toFixed(1)}%`}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListButtonItem;
