import React, { useState } from 'react'
import "../../static/css/productCss/productPerformance.css"
import Image from '../../components/Images/Image'

const Performance = () => {
    const [Index, setIndex] = useState(0)
    return (
        <React.Fragment>
            <div className='product_performance_main_container'>
                <div className='product_performance_overview'>
                    <div className='product_performance_overview_head'>
                        <p>OVERVIEW</p>
                        <div className='product_performance_overview_head_selectbtns'>
                            <form className="product_performance_overview_head_product_qty">
                                <select >
                                    <option value="10">Product</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                </select>
                            </form>
                            <form className="product_performance_overview_head_today">
                                <select >
                                    <option value="10">Today</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                </select>
                            </form>
                        </div>

                    </div>
                    <div className='product_performance_overview_body'>
                        <div className="product_performance_overview_body_itembox">
                            <div className='product_performance_overview_body_itemboxtop'>
                                <div className='product_performance_overview_body_itemboxtop_title'>Total Recvienue</div>
                                <div className='product_performance_overview_body_itemboxtop_detail'>RM123,920.00</div>
                            </div>
                            <div className='product_performance_overview_body_itemboxbottom_result'>
                                % total <strong>100%</strong>
                            </div>
                        </div>

                        <div className="product_performance_overview_body_itembox">
                            <div className='product_performance_overview_body_itemboxtop'>
                                <div className='product_performance_overview_body_itemboxtop_title'>Total Unique Parchases</div>
                                <div className='product_performance_overview_body_itemboxtop_detail'>-</div>
                            </div>
                            <div className='product_performance_overview_body_itemboxbottom_result'>
                                % total <strong>100%</strong>
                            </div>
                        </div>

                        <div className="product_performance_overview_body_itembox">
                            <div className='product_performance_overview_body_itemboxtop'>
                                <div className='product_performance_overview_body_itemboxtop_title'>Total Quantity</div>
                                <div className='product_performance_overview_body_itemboxtop_detail'>6,190</div>
                            </div>
                            <div className='product_performance_overview_body_itemboxbottom_result'>
                                % total <strong>100%</strong>
                            </div>
                        </div>

                        <div className="product_performance_overview_body_itembox">
                            <div className='product_performance_overview_body_itemboxtop'>
                                <div className='product_performance_overview_body_itemboxtop_title'>Total Avg.Price</div>
                                <div className='product_performance_overview_body_itemboxtop_detail'>15.93</div>
                            </div>
                            <div className='product_performance_overview_body_itemboxbottom_result'>
                                % total <strong>0.00%</strong>
                            </div>
                        </div>

                        <div className="product_performance_overview_body_itembox">
                            <div className='product_performance_overview_body_itemboxtop'>
                                <div className='product_performance_overview_body_itemboxtop_title'>Total Avg. QTY</div>
                                <div className='product_performance_overview_body_itemboxtop_detail'>2.00</div>
                            </div>
                            <div className='product_performance_overview_body_itemboxbottom_result'>
                                % total <strong>100%</strong>
                            </div>
                        </div>

                        <div className="product_performance_overview_body_itembox">
                            <div className='product_performance_overview_body_itemboxtop'>
                                <div className='product_performance_overview_body_itemboxtop_title'>Total Product Refund Amount</div>
                                <div className='product_performance_overview_body_itemboxtop_detail'>15.93</div>
                            </div>
                            <div className='product_performance_overview_body_itemboxbottom_result'>
                                % total <strong>100%</strong>
                            </div>
                        </div>


                    </div>


                </div>

                <div className='product_performance_performance_listing'>

                    <div className="product_performance_page_mainContainer_head">
                        <p>PERFORMANCE LISTING</p>
                    </div>
                    <div className="product_performance_page_mainContainer_body">
                        <div className="product_performance_page_mainContainer_body_allTabs">
                            <div className={`product_performance_page_TabsBtn ${Index === 0 ? "AllorderPage_activeTab" : null}`} onClick={() => { setIndex(0) }}>Product</div>
                            <div className={`product_performance_page_TabsBtn ${Index === 1 ? "AllorderPage_activeTab" : null}`} onClick={() => { setIndex(1) }}>Product SKU</div>
                            <div className={`product_performance_page_TabsBtn ${Index === 2 ? "AllorderPage_activeTab" : null}`} onClick={() => { setIndex(2) }}>Product Category</div>
                            <div className={`product_performance_page_TabsBtn ${Index === 3 ? "AllorderPage_activeTab" : null}`} onClick={() => { setIndex(3) }}>Product Brand</div>
                        </div>
                        <div className="product_performance_page_mainContainer_body_allContent">
                            <div className="product_performance_page_allContent_top">
                                <div className="product_performance_page_allContent_top_totalOrder">6 Product</div>

                                <div id="product_performance_page_allContent_top_searchBtn">
                                    <Image
                                        src="/search.png"
                                        className="order_page_search_icon"
                                        alt="Header logo for admin dashboard"
                                    />
                                    <input type="text" placeholder='Search' />
                                </div>
                            </div>
                            <div className="product_performance_page_content product_performance_page_content_AllOrder" hidden={Index != 0}>
                                <table >
                                    <tr>
                                        <th className='product_performance_page_table_right_item'>
                                            <input type="checkbox" />
                                            Name
                                        </th>
                                        <th>
                                            <select className="custom-select" >
                                                <option selected>Revenue</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <Image src="ques.png" alt="" />
                                        </th>
                                        <th className='product_performance_page_table_unique_parchases'>
                                            <select className="custom-select custom_select_unique_parchases" >
                                                <option selected>uniquie Parchases</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </select>
                                            <Image src="ques.png" alt="" />
                                        </th>
                                        <th className='product_performance_page_table_Quantity'>Quantity</th>
                                        <th className='product_performance_page_table_table_last_item'>Avg. Price</th>
                                    </tr>
                                    <tr>
                                        <td className='product_performance_page_table_right_item'>
                                            <input type="checkbox" />
                                            Logitech Mouse MK244
                                        </td>
                                        <td>RM3,600.00 <span>(2.42%)</span></td>
                                        <td>3 (0.12%)</td>
                                        <td>12 (0.12%)</td>
                                        <td className='product_performance_page_table_last_item'>RM 12.22</td>
                                    </tr>
                                    <tr>
                                        <td className='product_performance_page_table_right_item'>
                                            <input type="checkbox" />
                                            Nick K12 shoe
                                        </td>
                                        <td>RM3,600.00 <span>(2.42%)</span></td>
                                        <td>3 (0.12%)</td>
                                        <td>12 (0.12%)</td>
                                        <td >RM 12.22</td>
                                    </tr>
                                </table>
                            </div>
                            <div className="product_performance_page_content product_performance_page_content_Pending_payment" hidden={Index != 1}>2</div>
                            <div className="product_performance_page_content product_performance_page_content_AwaitTP" hidden={Index != 2}>3</div>
                            <div className="product_performance_page_content product_performance_page_content_ProccesOrder" hidden={Index != 3}>4</div>
                            <div className="product_performance_page_content product_performance_page_content_DeliveredOrder" hidden={Index != 4}>5</div>
                            <div className="product_performance_page_content product_performance_page_content_Cancellation" hidden={Index != 5}>6</div>
                            <div className="product_performance_page_content product_performance_page_content_Return" hidden={Index != 6}>7</div>
                        </div>
                        <div className="product_performance_page_mainContainer_body_bottom">
                            <form className="product_performance_page_mainContainer_body_bottom_selectQty">
                                <select >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="40">40</option>
                                    <option value="50">50</option>
                                </select>
                            </form>
                            <div className="product_performance_page_mainContainer_body_bottom_pagenation">
                                <a href="#">&lt;</a>
                                <a href="#">1</a>
                                <a href="#">2</a>
                                <a href="#">3</a>
                                <a href="#">&gt;</a>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </React.Fragment>
    )
}

export default Performance
