import { GetWithToken, PatchWithToken, PostWithToken, PutWithToken } from "../../lib/request";


export const verifyMerchantAccount = (password) => {
    return async function (dispatch) {
        let url = '/store/verify-merchant';
        const result = await PostWithToken(url, {password});
        return result;
    }
}


export const getMerchantAccountDetails = () => {
    return async function (dispatch) {
        let url = '/store/account';
        const result = await GetWithToken(url);
        return result;
    }
}

export const updateMerchantAccount = (data) => {
    return async function (dispatch) {
        let url = '/store/account';
        const result = await PatchWithToken(url, data);
        return result;
    }
}