import { IconButton, Pagination, Stack, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Image from "../../../components/Images/Image";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect, useRef, useState } from "react";
import "../../../static/css/orderCss/allOrder.css";
import PagenationDropdown from "../../../components/DropDown/pagenationDropdown";
import { Table } from "react-bootstrap";
import { getAllRedemptionListApi } from "../../../services/redemption.service";
import { useDispatch } from "react-redux";
import { AllowAccess, startLoader, stopLoader } from "../../../lib/global";
import { formatDate } from "../../../lib/helper";
import { getLocalStorage } from "../../../lib/session";

function RedemptionList(props) { 
    AllowAccess(["SUPERADMIN","MERCHANT"]);
    const [data, setData] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageLimit, setPageLimit] = useState(10);
    const limit = useRef(10);
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();

    const handlePageChange = (event, value) => {
        setOffset(value - 1);
        setPageNo(value);
    };

    useEffect(() => {
        apiData()
    },[props.search, offset, pageLimit]);

    const apiData = () => {
      console.log("lalal", props?.search)
        startLoader()
        let filter = {
            merchantName: props?.search?.merchantName,
            redemptionStatus: props?.search?.redemptionStatus,
            productName: props?.search?.productName,
            orderDateRange: props?.search?.orderDateRange,
            startDate: props?.search?.startDate,
            endDate: props?.search?.endDate,
            offset: offset,
            limit: pageLimit,
        }
        dispatch(getAllRedemptionListApi(filter)).then((res) => {
            setData(res.data.data);
            setTotal(res.data.total);
            stopLoader()
        }).catch((err) => {
            stopLoader()
            console.log(err)
        })
    }

    const handleSorting = (item, sortType, isDate = false) => {
        startLoader();
        try {
          if (typeof data[0][item] === "number") {
            let sortedData = data.sort((a, b) => {
              if (sortType == "asc") {
                return a[item] - b[item];
              } else if (sortType == "desc") {
                return b[item] - a[item];
              }
            });
            setTimeout(() => {
              setData([...sortedData]);
              stopLoader();
            }, [1000]);
          } else if (typeof data[0][item] === "string" && !isDate) {
            let sortedData = data.sort((a, b) => {
              let fa = a[item].toLowerCase(),
                fb = b[item].toLowerCase();
              if (sortType == "asc") {
                if (fa < fb) {
                  return -1;
                }
                if (fa > fb) {
                  return 1;
                }
                return 0;
              } else if (sortType == "desc") {
                if (fa < fb) {
                  return 1;
                }
                if (fa > fb) {
                  return -1;
                }
                return 0;
              }
            });
           
            setTimeout(() => {
              setData([...sortedData]);
              stopLoader();
            }, [1000]);
          } else if (typeof data[0][item] === "string" && isDate) {
            let sortedData = data.sort((a, b) => {
              let da = new Date(a[item]);
              let db = new Date(b[item]);
    
              if (sortType == "asc") {
                return da - db;
              } else if (sortType == "desc") {
                return db - da;
              }
            });
            setTimeout(() => {
              setData([...sortedData]);
              stopLoader();
            }, [1000]);
          }
        } catch (error) {
          console.log({ error });
          stopLoader();
        }
    };
    return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="border card p-3 my-3">
            <TableContainer>
            <Table
                sx={{ minWidth: 650 }}
                stickyHeader
                aria-label="sticky table"
            >
                <TableHead>
                    <TableRow>
                  
                        <TableCell className="th_cell" component="th">
                        No
                        </TableCell> 

                        {!(getLocalStorage("role") === "MERCHANT" )&& (
                        <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                        >
                        <div className="d-flex  ">
                            <div>Merchant ID</div>
                            <div className="d-flex flex-column ">
                            <ExpandLessIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                                }}
                                onClick={() => handleSorting("merchantId", "asc")}

                                // onClick={() => getApiData({ item: "category", order: "ASC" })}
                            />
                            <ExpandMoreIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                                }}
                                onClick={() => handleSorting("merchantId", "desc")}

                                // onClick={() => getApiData({ item: "category", order: "DESC" })}
                            />
                            </div>
                        </div>
                        </TableCell> )}
                        
                        {!(getLocalStorage("role") === "MERCHANT" )&& (
                        <TableCell className="th_cell" component="th" align="left"> 
                        <div className="d-flex  ">
                            <div>Merchant Name</div>
                            <div className="d-flex flex-column ">
                            <ExpandLessIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                                }}
                                // onClick={() => setSearchData({ ...searchData, item: "brandName", order: "ASC" })}
                                onClick={() => handleSorting("merchantName", "asc")}
                            />
                            <ExpandMoreIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                                }}
                                // onClick={() => setSearchData({ ...searchData, item: "brandName", order: "DESC" })}
                                onClick={() => handleSorting("merchantName", "desc")}
                            />
                            </div>
                        </div>
                        </TableCell> 
                        )}

                        <TableCell className="th_cell" component="th" align="left">
                        <div className="d-flex  ">
                            <div>Order Id</div>
                            <div className="d-flex flex-column ">
                            <ExpandLessIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                                }}
                                // onClick={() => setSearchData({ ...searchData, item: "brandName", order: "ASC" })}
                                onClick={() => handleSorting("orderId", "asc")}
                            />
                            <ExpandMoreIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                                }}
                                // onClick={() => setSearchData({ ...searchData, item: "brandName", order: "DESC" })}
                                onClick={() => handleSorting("orderId", "desc")}
                            />
                            </div>
                        </div>
                        </TableCell>
                        

                        <TableCell className="th_cell" component="th" align="left">
                        <div className="d-flex  ">
                            <div>Product Name</div>
                            <div className="d-flex flex-column ">
                            <ExpandLessIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                                }}
                                // onClick={() => setSearchData({ ...searchData, item: "brandName", order: "ASC" })}
                                onClick={() => handleSorting("productName", "asc")}
                            />
                            <ExpandMoreIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                                }}
                                // onClick={() => setSearchData({ ...searchData, item: "brandName", order: "DESC" })}
                                onClick={() => handleSorting("productName", "desc")}
                            />
                            </div>
                        </div>
                        </TableCell>
                        
                        <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                        >
                        <div className="d-flex  ">
                            <div>Purchase Date</div>
                            <div className="d-flex flex-column ">
                            <ExpandLessIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                                }}
                                onClick={() => handleSorting("purchaseDate", "asc",true)}

                                // onClick={() => getApiData({ item: "product", order: "ASC" })}
                            />
                            <ExpandMoreIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                                }}
                                onClick={() => handleSorting("purchaseDate", "desc")}
                            />
                            </div>
                        </div>
                        </TableCell>

                        <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                        >
                        <div className="d-flex  ">
                            <div> Expired Datetime</div>
                            <div className="d-flex flex-column ">
                            <ExpandLessIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                                }}
                                onClick={() =>
                                handleSorting("expiredDate", "asc")
                                }

                                // onClick={() => getApiData({ item: " releventMerchant", order: "ASC" })}
                            />
                            <ExpandMoreIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                                }}
                                onClick={() =>
                                handleSorting("expiredDate", "desc")
                                }

                                // onClick={() => getApiData({ item: " releventMerchant", order: "DESC" })}
                            />
                            </div>
                        </div>
                        </TableCell>

                        <TableCell className="th_cell" component="th" align="left">
                        <div className="d-flex  ">
                            <div> Redemption Outlet Name</div>
                            <div className="d-flex flex-column ">
                            <ExpandLessIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                                }}
                                onClick={() => handleSorting("redemptionOutlet", "asc")}

                                // onClick={() => getApiData({ item: "  status", order: "ASC" })}
                            />
                            <ExpandMoreIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                                }}
                                onClick={() => handleSorting("redemptionOutlet", "desc")}
                            />
                            </div>
                        </div>
                        </TableCell>

                        <TableCell
                        className="th_cell"
                        component="th"
                        align="center"
                        >
                        <div className="d-flex  ">
                            <div>Status</div>
                            <div className="d-flex flex-column ">
                            <ExpandLessIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "0px 3px",
                                }}
                                onClick={() => handleSorting("status", "asc")}

                                // onClick={() => getApiData({ item: "product", order: "ASC" })}
                            />
                            <ExpandMoreIcon
                                style={{
                                width: "15px",
                                height: "15px",
                                margin: "-5px 3px",
                                }}
                                onClick={() => handleSorting("status", "desc")}
                            />
                            </div>
                        </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {!data?.length && (
                    <TableRow>
                    <TableCell align="center" className="td_cell">
                        <div className="d-flex justify-content-center">
                        <h3 className="text-muted m-auto">No data found!</h3>
                        </div>
                    </TableCell>
                    </TableRow>
                )}

                {data.map((row, index) => (
                    <TableRow
                    key={index}
                    sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    >
                    <TableCell align="center" className="td_cell">
                        {offset * pageLimit + (index + 1)}
                    </TableCell>
                    {!(getLocalStorage("role") === "MERCHANT" )&& (
                    <TableCell align="left" className="td_cell capitalize">
                        {row.storeId || "N/A"}
                    </TableCell> )}
                    {!(getLocalStorage("role") === "MERCHANT" )&& (
                    <TableCell align="left" className="td_cell capitalize">
                        {row.merchantName || "N/A"}
                    </TableCell> )}
                    
                    <TableCell align="left" className="td_cell capitalize">
                        {row.orderId || "N/A"}
                    </TableCell>
                    <TableCell align="left" className="td_cell capitalize">
                        {row.productName || "N/A"}
                    </TableCell>
                    <TableCell align="center" className="td_cell">
                        {formatDate(row.createdAt,"DD/MM/YYYY HH:mm:ss") || "-"}
                    </TableCell>
                    <TableCell align="center" className="td_cell">
                        {row.expiredAt ? formatDate(row.expiredAt,"DD/MM/YYYY HH:mm:ss") : "N/A"}
                    </TableCell>
                    <TableCell align="left" className="td_cell">
                        {row.merchantoutlet?.outletName || "N/A"}
                    </TableCell>
                    <TableCell align="center" className="td_cell">
                        {row.redemptionStatus || "N/A"}
                    </TableCell>
                    </TableRow>
                ))}
                
                </TableBody>
            </Table>
            </TableContainer>

            <br />
            <hr />
            <div className="d-flex justify-content-between align-items-center">
            <PagenationDropdown setPageQuant={setPageLimit} />

            <div className="float-right mb-1">
                <Stack spacing={2}>
                <Pagination
                    count={Math.ceil((total || 0) / pageLimit)}
                    shape="rounded"
                    size="small"
                    page={pageNo}
                    onChange={handlePageChange}
                />
                </Stack>
            </div>
            </div>
            <br />
        </div>
    </div>
    </React.Fragment>
    );
}

export default RedemptionList;
