import allReducers from './reducers';
import { applyMiddleware, compose, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";


/**
 * @description use this method to bind redux middlewares for develooment OR production separately
 * @author jagannath
 * @date 08/09/2021
 * @param middleware list of middlewares
 */
 const bindMiddlewares = (middleware) => {
    if (process.env.NODE_ENV !== "production") {
        return composeWithDevTools(applyMiddleware(...middleware));
    } else {
        return compose(applyMiddleware(...middleware));
    }
};



const configureStore = createStore(
    allReducers,
    bindMiddlewares([thunk])
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default configureStore;