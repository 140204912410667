import React, { useEffect, useState } from "react";
import { EMAIL_REGEX } from "../../../lib/constant";

/**
 * @description use this component for password input
 * @author jagannath
 * @date 28/01/2022
 * @param props
 * @param id: string;
 * @param value: string | number;
 * @param onChange: any;
 * @param className: string;
 * @param formSubmitted: boolean;
 * @param name: string;
 * @param label: string;
 * @param lang: string;
 * @param error?: string;
 * @param formClass?: string;
 * @param compareValue?: string
 */
const PasswordInput = (props) => {
  const [showPwd, setShowPwd] = useState(false);
  const [error, setError] = useState(props.error);
  useEffect(() => {
    setError(props.error);
  }, [props.error]);
  const handleValidate = (e) => {
    if (!e.target.value || e.target.value == 0) {
      e.target.classList.add("field-invalid");
      e.target.classList.remove("field-validated");
      setError("fieldRequired");
    } else {
      if (props.compareValue && e.target.value !== props.compareValue) {
        e.target.classList.add("field-invalid");
        e.target.classList.remove("field-validated");
        setError("pwdNotMatched");
        return;
      }
      // if (!e.target.value.match(PASSWORD_REGEX)) {
      //     e.target.classList.add("field-invalid");
      //     e.target.classList.remove("field-validated");
      //     setError("invalidPassword");
      // }
      else {
        e.target.classList.remove("field-invalid");
        setError("");
        e.target.classList.remove("field-invalid");
        e.target.classList.add("field-validated");
      }
      // setError("");
    }
  };
  return (
    <div className={`form-group ${props.formClass || ""}`}>
      <input
        id={props.id || props.name || props.label}
        name={props.name || props.label}
        className={`form-control ${props.className}`}
        type={showPwd ? "text" : "password"}
        value={props.value}
        field-invalid={String(
          props.formSubmitted && !props.value && props.required
        )}
        field-not-empty={String(!!props.value)}
        onBlur={handleValidate}
        onChange={props.onChange}
        required={!!props.required}
        placeholder={props.placeholder || "input..."}
        disabled={!!props.disabled}
      />
      <p className="form-label">{props.label}</p>
      {(error || (props.required && props.formSubmitted && !props.value)) && (
        <label
          htmlFor={props.id || props.name || props.label}
          className="error"
        >
          This field is mandatory!
        </label>
      )}
      <i
        className={showPwd ? "view-pass icon-seen" : "view-pass icon-hide"}
        onClick={() => setShowPwd((prevState) => !prevState)}
      ></i>
    </div>
  );
};

export default PasswordInput;
