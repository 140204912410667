import { TextField } from "@mui/material";
import React from "react";
import Image from "../../../components/Images/Image";

function ChatLog(props) {
   const goBack=()=>{
       console.log(props,'propspas');
       return props.showChatFun()
   }
  return (
    <div className="d-flex  position-relative col-8 pr-3 ">
      <div className="py-3 col-12">
        <div className="d-flex align-items-center">
          <div className="mr-4">
            <Image src={"/icons/lessThen.png"} width={20} height={20} onClick={goBack}/>
          </div>
          <div className=" btn-sm btn-dark " onClick={goBack}> Back</div>
        </div>

        <br />
        <div className="d-flex box justify-content-between  mb-2 ">
          REQUEST RETURN INFORMATION
        </div>
        <div className="card p-3 chat_container">
          <div className="my-2 font-weight-bold">
            Waiting for seller to respond
          </div>

          <div className="card  fntSz-12 text-muted">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatem
            architecto odio distinctio voluptates placeat eius vitae ad itaque
            <br />
          </div>
          <hr />
          <div
            className="p-3"
            style={{ background: "#ececec", borderRadius: "10px" }}
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Omnis nam
            iure error natus soluta, numquam ducimus animi eius
          </div>
        </div>
        <div className="mt-1 p-3 card ">
          <div className="d-flex justify-content-center align-items-center ">
            <div className="">
              <Image src={"/icons/imageIcon.png"} width={40} height={40} />
            </div>

            <div className="col-8">
              <TextField
                id="outlined-basic"
                label="Type a massage"
                variant="outlined"
                size="small"
                className="col-12"
              />
            </div>

            <div className=" btn  bg-light ">Send</div>
          </div>
        </div>
      </div>

      <style jsx="true">{`
        .fntSz-12 {
          font: "7px";
        }
        .font-bold {
          fontweight: "700";
        }
      `}</style>
    </div>
  );
}

export default ChatLog;
