import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TabPanel } from "../../../components/Tabs/TabPannel";
import { AllowAccess, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getAllProductList } from "../../../redux/actions/product.action";
import InputField from "../../../components/formControl/input/InputField";
import DateRangePickerComp from "../../../components/datepicker/MUIDateRange";

import Return_Refund from "./Return.refund";
import { getAllReturnOrder } from "../../../redux/actions/finance.action";
import { getLocalStorage } from "../../../lib/session";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const initialSearchData = {
  buyerName: "",
  merchantName: "",
  orderId: "",
  productName: "",
  orderDate: "",
};
const initOrderRangeDate = {
  startDate: "",
  endDate: "",
};

const OrderListing = () => {
  AllowAccess(["SUPERADMIN","MERCHANT","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState("");
  const [buyerName, setBuyerName] = useState("");
  const [merchantName, setMerchantName] = useState("");
  const [agentId, setAgentId] = useState("");

  const [search, setSearch] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [total, setTotal] = useState({});

  const [reset, setReset] = useState(false);
  const [orderDateRange, setOrderDateRange] = useState(initOrderRangeDate);
  const handleDateChange = (data) => {
    //   console.log(data,'sdfasfdasdfadsfasdfasdfa');
    setOrderDateRange(data);
  };

  useEffect(() => {
    // startLoader();
    const breadcrumb = [{ label: "Return/Refund", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
  }, [tabIndex]);


  const searchData = () => {
    setSearch({
      orderId,
      buyerName,
      merchantName,
      agentId,
      ...orderDateRange,
    });
  };
  const handleResetSearch = () => {
    setOrderId("");
    setBuyerName("");
    setMerchantName("");
    setAgentId("");
    setReset(true);
    setOrderDateRange(initOrderRangeDate);

    setSearch({
      agentId: "",
      buyerName: "",
      merchantName: "",
      orderId: "",
      ...initOrderRangeDate,
    });
  };

  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-11 row flex-wrap">
          <div className="min-width-200 col-md-3">
            <label>Order ID</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="storeId"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-3">
            <label>Consumer Name</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="merchantName"
              value={buyerName}
              onChange={(e) => setBuyerName(e.target.value)}
            />
          </div>
          {
            !getLocalStorage('role') === "MERCHANT" &&
            <div className="min-width-200 col-md-3">
              <label>Merchant Name/ID</label>
              <InputField
                type="text"
                className="form-control"
                placeholder="Input"
                id="storeId"
                value={merchantName}
                onChange={(e) => setMerchantName(e.target.value)}
              />
            </div>
          }
          <div className="min-width-200 col-md-3">
            {/* Product Name */}
            <label>Product Name </label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="agentId"
              value={agentId}
              disable={true}
            // onChange={(e) => setAgentId(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-3 ">
            <label >Order Date</label>
            <div className="">
              <DateRangePickerComp
                reset={reset}
                setReset={setReset}
                handleDateChange={handleDateChange}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn input-inside-text mr-1 btn-white border px-4"
            onClick={() => handleResetSearch()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn input-inside-text mx-1 btn-secondary px-3"
            onClick={() => searchData()}
          >
            Search
          </button>
        </div>
      </div>

      <br />
      <br />
      <div className="container_body container-fluid">
        <div className="card p-3">
          <span>ORDER RETURN/REFUND LISTING</span>
          <div className=" border card  my-3">
            <Tabs
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              variant="scrollable"
              orientation="horizontal"
              scrollButtons={false}
              visibleScrollbar={false}
              className="px-3"
              aria-label="basic tabs example"
              style={{ backgroundColor: "secondary" }}
            >
              <Tab label={`All(${total?.total || 0})`} {...a11yProps(0)} />
              <Tab
                label={`Processing(${total?.processing || 0})`}
                {...a11yProps(1)}
              />
              <Tab label={`Under Review(${total?.review || 0})`} {...a11yProps(2)} />
              <Tab label={` Refunded(${total?.refund || 0}) `} {...a11yProps(3)} />
              <Tab
                label={`Merchant To Respond(${total?.merchatToRespond || 0})`}
                {...a11yProps(4)}
              />
              <Tab
                label={`UU2 To Respond(${total?.UU2ToRespond || 0})`}
                {...a11yProps(5)}
              />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <Return_Refund
                totalItem={total.total}
                search={search}
                status=""
                setTotals={setTotal}
                reset={handleResetSearch}
              />

            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <Return_Refund
                totalItem={total.processing}
                search={search}
                status="PROCESSING"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <Return_Refund
                totalItem={total.review}
                search={search}
                status="UNDER_REVIEW"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <Return_Refund
                totalItem={total.refund}
                search={search}
                status="REFUNDED"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              <Return_Refund
                totalItem={total.merchatToRespond}
                search={search}
                status="MERCHANT_TO_RESPOND"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
              <Return_Refund
                totalItem={total.UU2ToRespond}
                search={search}
                status="UU2_TO_RESPOND"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderListing;
