import React, { useState } from "react";
import { useEffect } from "react";
import "./index.css";

const Tree = ({
  data = [],
  setShowCategory,
  setIdCategory,
  showCategory,
  idCategory,
}) => {
  return (
    <div className="container">
      <div className="d-tree">
        <ul className="d-flex d-tree-container flex-column">
          {data.map((tree, i) => (
            <TreeNode
              node={tree}
              key={i}
              setShowCategory={setShowCategory}
              setIdCategory={setIdCategory}
              showCategory={showCategory}
              idCategory={idCategory}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

const TreeNode = ({
  node,
  setShowCategory,
  setIdCategory,
  showCategory,
  idCategory,
}) => {
  const [childVisible, setChildVisiblity] = useState(true);
  const hasChild = node.SubCategory ? true : false;
  const [checkedBox, setCheckedBox] = useState(true);

  const handleSelectCategory = async (e, category) => {
    const { id, title } = category;
    const { checked } = e.target;
    let exist = false;
    try {
      for (let i = 0; i < showCategory.length; i++) {
        if (showCategory[i] == title) {
          exist = true;
        } else {
          continue;
        }
      }

      if (exist) {
        if (checkedBox) {
          setCheckedBox(!checkedBox);
          setTimeout(() => {
            setShowCategory(
              showCategory.filter((e) => {
                return e != title;
              })
            );
            setIdCategory(
              idCategory.filter((e) => {
                return e != id;
              })
            );
          }, 300);
        }
      } else {
        if (checked) {
          setTimeout(() => {
            setCheckedBox(true);
            setShowCategory([...showCategory, title]);
            setIdCategory([...idCategory, id]);
          }, 300);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <li className="d-tree-node border-0">
      <div className="d-flex">
        {showCategory.filter((el) => el == node.title).length > 0 ? (
          <input
            type="checkbox"
            onChange={(e) => handleSelectCategory(e, node)}
            checked={checkedBox}
          />
        ) : (
          <input
            type="checkbox"
            onChange={(e) => handleSelectCategory(e, node)}
            checked={false}
          />
        )}
        <div className="d-flex" onClick={(e) => setChildVisiblity((v) => !v)}>
          {hasChild && (
            <div
              className={`d-inline d-tree-toggler ${
                childVisible ? "active" : ""
              }`}
            >
              <div className="col d-flex d-tree-head">{node.title}</div>
            </div>
          )}
        </div>
      </div>

      {hasChild && childVisible && (
        <div className="d-tree-content">
          <ul className="d-flex d-tree-container flex-column">
            <Tree
              data={node.SubCategory}
              setShowCategory={setShowCategory}
              setIdCategory={setIdCategory}
              showCategory={showCategory}
              idCategory={idCategory}
            />
          </ul>
        </div>
      )}
    </li>
  );
};

export default Tree;
