import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InputField from "../../../components/formControl/input/InputField";
import SelectInput from "../../../components/select";
import { showToast, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { searchMerchantList, searchStateList } from "../../../redux/actions/merchant-center.action";
import {
  addOutletApi,
  getOutletById,
  updateOutletById,
} from "../../../services/outlet.service";
import { getLocalStorage } from "../../../lib/session";
import { stateName } from "../../../shared/language";
import SelectDropdown from "../../../components/select";
import {
  TextField
} from "@mui/material";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import 'react-datepicker/dist/react-datepicker.module.css'

const AddOutlet = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [outletName, setOutletName] = useState();
  const [address, setAddress] = useState();
  const [postalCode, setPostalCode] = useState();
  const [state, setState] = useState();
  const [merchantList, setMerchantList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [basicInformation, setBasicInformation] = useState({ merchant: null });
  const [storeId, setStoreId] = useState("");
  const [merchantData, setMerchantData] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [status, setStatus] = useState(1);
  const [statusData, setStatusData] = useState("");
  const [stateData, setStateData] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [startDate, setStartDate] = useState(new Date());

  const { id } = useParams();

  const statusList = [
    {
      "label": "Active",
      "value": 1
    },
    {
      "label": "Non Active",
      "value": 0
    }
  ]

  useEffect(() => {
    if (getLocalStorage('role') === "MERCHANT") {
      history.push("/404");
    }

    if (id) {
      startLoader();
      dispatch(getOutletById(id))
        .then((data) => {
          stopLoader();
          const { outletName, address, postalCode, state, merchant, contactNumber, startTime, endTime, status } = data.data.data;
          let statusDbVal;
          let statusDbName;
          if (status === 1 || status === true) {
            statusDbVal = status;
            statusDbName = "Active"
          }
          else {
            statusDbVal = status;
            statusDbName = "Non Active"
          }

          let statusDataDb = [
            {
              "label": statusDbName,
              "value": statusDbVal
            }
          ]

          let stateDataDb = [
            {
              "label": state,
              "value": state
            }
          ]



          // Split the time string into hours, minutes, and seconds
          var startTimeParts = startTime.split(':');
          var startHours = parseInt(startTimeParts[0], 10);
          var startMinutes = parseInt(startTimeParts[1], 10);
          var startSeconds = parseInt(startTimeParts[2], 10);

          // Create a new Date object and set the time components
          var startTimeFormat = new Date();
          startTimeFormat.setHours(startHours);
          startTimeFormat.setMinutes(startMinutes);
          startTimeFormat.setSeconds(startSeconds);

          // Split the time string into hours, minutes, and seconds
          var endTimeParts = endTime.split(':');
          var endHours = parseInt(endTimeParts[0], 10);
          var endMinutes = parseInt(endTimeParts[1], 10);
          var endSeconds = parseInt(endTimeParts[2], 10);

          // Create a new Date object and set the time components
          var endTimeFormat = new Date();
          endTimeFormat.setHours(endHours);
          endTimeFormat.setMinutes(endMinutes);
          endTimeFormat.setSeconds(endSeconds);



          setOutletName(outletName);
          setAddress(address);
          setPostalCode(postalCode);
          setState(state);
          setContactNumber(contactNumber);
          setStartTime(startTimeFormat);
          setEndTime(endTimeFormat);
          setStatus(status);
          setStatusData(statusDataDb);
          setStateData(stateDataDb);
          setStoreId(merchant.storeId);
          setMerchantData(merchant.preferredMerchantName + ' - ' + merchant.storeId);
          setIsEdit(true);
        })
        .catch((error) => {
          stopLoader;
          console.log({ error }, "error");
        });
    }


  }, [id]);

  useEffect(() => {
    const breadcrumb = [
      { label: "Merchant Outlet", url: "/merchant-center/outlet" },
      { label: id ? "Edit Outlet" : "Add Outlet", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
    getMerchantList();
    getStateList();
  }, []);

  const addOutletFun = () => {
    console.log("XXXX", startTime, moment(startTime).format('HH:mm:ss'))
    console.log("@@@@", statusData);
    startLoader();
    const data = {
      storeId,
      outletName,
      address,
      postalCode,
      state,
      contactNumber,
      startTime: moment(startTime).format('HH:mm:ss'),
      endTime: moment(endTime).format('HH:mm:ss'),
      status: id ? (statusData[0].value ? 1 : 0) : status
    };
    if (id) {
      dispatch(updateOutletById(id, data))
        .then((data) => {
          if (data && data.data && data.data.status === 201) {
            setTimeout(() => {
              showToast(data.data.message);
              stopLoader();
              history.push("/merchant-center/outlet");
            }, 2000);
          } else {
            showToast(data.data.message);
            stopLoader();
            history.push("/merchant-center/outlet");
          }
        })
        .catch((error) => {
          console.log(error);
          stopLoader();
        });
    } else {
      const response = dispatch(addOutletApi(data));
      response
        .then((data) => {
          if (data && data.data && data.data.status === 200) {
            setTimeout(() => {
              showToast(data.data.message);
              stopLoader();
              history.push("/merchant-center/outlet");
            }, 2000);
          } else {
            stopLoader();
          }
        })
        .catch((error) => {
          console.log(error);
          stopLoader();
        });
    }
  };

  const getMerchantList = (search) => {
    dispatch(searchMerchantList(search))
      .then((response) => {
        if (response?.data?.data) {
          const data = response.data.data.map((item) => ({
            title: item.merchantName,
            preferredName: item.preferredMerchantName,
            label: `${item.preferredMerchantName} - (${item.storeId})`,
            value: item.storeId,
          }));

          setMerchantList(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const getStateList = (search) => {
    dispatch(searchStateList(search))
      .then((response) => {
        if (response?.data?.data) {
          const data = response.data.data.map((item) => ({
            state_code: item.state_code,
            state_name: item.state_name,
            label: `${item.state_name}`,
            value: item.state_name,
          }));

          setStateList(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const onChangeBasicInfo = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setStoreId(val.value)
    setBasicInformation((pre) => ({ ...pre, [key]: value }));
  };

  const onChangeBasicInfoState = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setState(val.value);

    let stateBasic = [
      {
        "label": val.label,
        "value": val.value
      }
    ]
    setStateData(stateBasic);
  };

  const onChangeBasicInfoStatus = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setStatus(val.value);
    let statusBasic = [
      {
        "label": val.label,
        "value": val.value
      }
    ]
    setStatusData(statusBasic);
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9]|\./;
    // if (!regex.test(key)) {
    //   e.preventDefault();
    // }
    if (!regex.test(key) || (key === '-' && e.target.selectionStart === 0)) {
      e.preventDefault();
    }
  };

  const handleInput = (e) => {
    if (e.target.value.startsWith('-')) {
      e.target.value = e.target.value.substring(1);
    }
  };

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData('text');
    const regex = /^[0-9]+$/;
    if (!regex.test(pastedText)) {
      e.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-between flex-column h-100">
        <div className="mr-3 ml-4 mt-3 rounded  px-3 pt-4 pb-2 bg-white">
          <small>BASIC INFORMATION</small>
          <form className="mt-3  p-0">
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Merchant Name
              </label>
              {isEdit == false && (
                <div className="form-group col-9">
                  <SelectInput
                    options={merchantList}
                    name="merchant"
                    value={basicInformation.merchant}
                    onChange={(e) => onChangeBasicInfo(e, "merchant", e)}
                    required={true}
                    formSubmitted={false}
                    placeholder="Select Merchant"
                  />
                </div>
              )}

              {isEdit == true && (
                <InputField
                  className=""
                  formClass="col-9"
                  type="text"
                  value={merchantData}
                  disabled
                />
              )}
            </div>

            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Outlet Name
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                placeholder="Enter Outlet name"
                value={outletName}
                onChange={(e) => setOutletName(e.target.value)}
                required={true}
              />
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Address
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter Address Line 1"
              />
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Contact Number
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="number"
                value={contactNumber}
                onInput={(e) => handleInput(e)}
                onKeyPress={(e) => handleKeyPress(e)}
                onPaste={(e) => handlePaste(e)}
                onChange={(e) => setContactNumber(e.target.value)}
                placeholder="Enter Contact Number"
              />
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Start Time
              </label>
              <DatePicker
                selected={startTime}
                onChange={(date) => setStartTime(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="hh:mm:aa"
                className="col-8 text-center ml-3 p-0"
              />
              {/* <TextField
                id="startTime"
                label=""
                className="col-8 text-right ml-3 p-0"
                type="time"
                value={startTime}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                sx={{ width: 150 }}
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e) => setStartTime(e.target.value)}
              /> */}
              {/* <InputField
                className=""
                formClass="col-9"
                type="time"
                value={startTime}
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e) => setStartTime(e.target.value)}
                placeholder="Enter Start Time"
              /> */}
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                End Time
              </label>
              <DatePicker
                selected={endTime}
                onChange={(date) => setEndTime(date)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="hh:mm:aa"
                className="col-8 text-center ml-3 p-0"
              />
              {/* <TextField
                id="endTime"
                className="col-8 text-right ml-3 p-0"
                type="time"
                value={endTime}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                sx={{ width: 150 }}
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e) => setEndTime(e.target.value)}
              /> */}
              {/* <InputField
                className=""
                formClass="col-9"
                type="time"
                value={endTime}
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e) => setEndTime(e.target.value)}
                placeholder="Enter End Time"
              /> */}
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Postal Code
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="number"
                value={postalCode}
                onInput={(e) => handleInput(e)}
                onKeyPress={(e) => handleKeyPress(e)}
                onPaste={(e) => handlePaste(e)}
                onChange={(e) => setPostalCode(e.target.value)}
                placeholder="Enter Postal Code"
              />
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                State
              </label>
              {/*<InputField*/}
              {/*  className=""*/}
              {/*  formClass="col-9"*/}
              {/*  type="text"*/}
              {/*  value={state}*/}
              {/*  onChange={(e) => setState(e.target.value)}*/}
              {/*  placeholder="Enter State"*/}
              {/*/>*/}
              <div className="col-md-9 m-auto">
                {/*<SelectDropdown*/}
                {/*  options={stateName}*/}
                {/*  placeholder="Select State"*/}
                {/*  value={state}*/}
                {/*  onChange={setState}*/}
                {/*/>*/}
                {isEdit == false && (
                  <SelectInput
                    options={stateList}
                    name="state"
                    value={stateData}
                    onChange={(e) => onChangeBasicInfoState(e, "state", e)}
                    required={true}
                    formSubmitted={false}
                    placeholder="Select State"
                  />
                )}

                {isEdit == true && (
                  <SelectInput
                    options={stateList}
                    name="state"
                    value={stateData}
                    onChange={(e) => onChangeBasicInfoState(e, "state", e)}
                    required={true}
                    formSubmitted={false}
                    placeholder="Select State"
                  />
                )}
              </div>
            </div>
            {isEdit == true && (
              <div className="col-12 row my-3">
                <label className="col-3 text-right m-0 p-0 small imp">
                  Status
                </label>
                <div className="form-group col-9">
                  <SelectInput
                    options={statusList}
                    name="status"
                    value={statusData}
                    onChange={(e) => onChangeBasicInfoStatus(e, "status", e)}
                    required={true}
                    formSubmitted={false}
                    placeholder="Select Status"
                  />
                </div>
              </div>
            )}
          </form>
        </div>
        <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
          <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
            <button
              onClick={history.goBack}
              type="button"
              className="btn border input-inside-text mr-2"
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-dark btn-sm mr-2"
              onClick={() => addOutletFun()}
              disabled={
                !outletName ||
                !address ||
                !postalCode ||
                // !state ||
                !contactNumber ||
                !startTime ||
                !endTime
              }
            >
              {id ? "Save" : "Create"}
            </button>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default AddOutlet;
