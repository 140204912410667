import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import InputField from "../../components/formControl/input/InputField";
import PasswordInput from "../../components/formControl/input/PasswordInput";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { verifyMerchantAccount } from "../../redux/actions/merchant-account.action";
import { showToast } from "../../lib/global";
import { async } from "rxjs";

const VerifyMerchantDialog = (props) => {
  const [password, setPassword] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    try {
      setFormSubmitted(true);
      if (!password) {
        throw { message: "Password Required!", status: 400 };
      }
      // call api here to verify merchant password;
      dispatch(verifyMerchantAccount(password))
        .then((response) => {
          if (response?.status == 200) {
            props.onSubmit?.(true);
            setTimeout(() => {
              showToast(`${response?.data.message}`, "success");
            }, 1500);
            props.onClose();
          }
        })
        .catch((error) => {
          // props.onClose();
          setTimeout(() => {
            showToast("Incorrect Password!", "error");
          }, 1000);
        });
    } catch (error) {
      setTimeout(() => {
        showToast(`${error.message}`, "error");
      }, 1000);
    }
  };

  const onClose = () => {
    try {
      props.onClose();
      history.goBack();
      console.log("vv");
    } catch (error) {
      history.push("/");
      console.log("vvq");
      props.onClose();
    }
  };
  return (
    <div className="dialog_container">
      <div className="close_icon" style={{ top: "0px" }}>
        <CloseIcon
          className="close_icon_btn m-2"
          color="primary2"
          fontSize="large"
          title="Close dialog"
          onClick={onClose}
        />
      </div>
      <div className="text-center">
        <h3 className="mb-0">Verify Account</h3>
        <p className="text-muted small">
          To protect your account safely please enter your credential.
        </p>
      </div>
      <div className="px-3">
        <PasswordInput
          id="password"
          value={password}
          placeholder="Password"
          formClass="mt-4 mb-5"
          required={true}
          formSubmitted={formSubmitted}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn btn-outline-dark mx-2 px-4"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-dark mx-2 px-4"
          onClick={onSubmit}
        >
          Verify
        </button>
      </div>
    </div>
  );
};

export default VerifyMerchantDialog;
