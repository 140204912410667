import { GetWithToken, PatchWithToken, PostWithToken,DeleteWithToken } from "../../lib/request"


export const getAllConsumerList = (query) => {

    return async function (dispatch) {
        console.log('enter');
        const result = await GetWithToken(`/consumer?consumerName=${query.consumerName || ''}&consumerRegisteredDate=${query.consumerRegisteredDate || ''}&item=${query.item || ""}&order=${query.order || ""}&status=${query.status || ""}&offset=${query.offset ||0}&limit=${query.limit ||10}`)
        console.log(result,'result for all consumer ');
        return result;
    }    
}


export const addNewConsumer = (payload) => {
    return async function (dispatch) {
        const result = await PostWithToken('/consumer', payload)
        console.log('result', result)
        return result
    }
}

export const getConsumerById = (id,) => {
    console.log('enteraddd',id);
    return ( async function (dispatch) {
        const result = await GetWithToken(`/consumer/${id}`)
        console.log('result', result)
        return result
    })
}

export const updateConsumerById = (id,payload,) => {
    console.log('enteraddd',id,payload);
    return ( async function (dispatch) {
        const result = await PatchWithToken(`/consumer/${id}`,payload)
        console.log('result', result)
        return result
    })
}

export const deleteConsumerById = (id,) => {
    console.log('enteraddd',id);
    return ( async function (dispatch) {
        const result = await DeleteWithToken(`/consumer/delete/${id}`)
        console.log('result', result)
        return result
    })
}

