import { DeleteWithToken, GetWithToken, PatchWithToken, PostWithToken } from "../lib/request"

export const getLanguage = (list) => {
    return GetWithToken(`/language?status=2&skip=${list?.skip || 0}&limit=${list?.limit || 10}`)
}

export const changeLanguageStatus = (data) => {
    return PatchWithToken(`/language`, data);
}

export const addLanguage = (data) => {
    return PostWithToken(`/language`, data);
}

export const deleteLanguage = (data) => {
    return DeleteWithToken(`/language`, data)
}