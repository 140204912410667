import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TabPanel } from "../../components/Tabs/TabPannel";

import { AllowAccess, startLoader, stopLoader } from "../../lib/global";
import { breadcrumbAction } from "../../redux/actions/config.action";
import InputField from "../../components/formControl/input/InputField";

import Return_Refund from "./Return_Refund";
import {
  getAllReturnOrder,
  getOrderReturnBystoreId,
} from "../../redux/actions/finance.action";
import { useParams } from "react-router-dom";
import { getLocalStorage } from "../../lib/session";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MerchantReturnOrder = () => {
  AllowAccess(["MERCHANT"]);
  const param = useParams();
  const storeId = param.mid ? param.mid : getLocalStorage("storeId");
  const dispatch = useDispatch();
  const [productName, setProductName] = useState();
  const [categoryName, setCategoryName] = useState();
  const [allProductData, setAllProductData] = useState([]);
  const [search, setSearch] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [total, setTotal] = useState({});

  useEffect(() => {
    startLoader();
    const breadcrumb = [{ label: "Return/Refund", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    dispatch(getOrderReturnBystoreId(storeId))
      .then((data) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log(data);
        setTotal(data && data.data && data.data.total);
      })
      .catch((error) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log(error);
      });
  }, [tabIndex]);

  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-11 row flex-wrap">
          <div className="min-width-200 col-md-3">
            <label>Order ID</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="storeId"
              //   value={serchData.storeId}
              //   onChange={onChangeMerchantSearchDetail}
            />
          </div>
          <div className="min-width-200 col-md-3">
            <label>Buyer Name</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="merchantName"
              //   value={serchData.merchantName}
              //   onChange={onChangeMerchantSearchDetail}
            />
          </div>
          <div className="min-width-200 col-md-3">
            <label>Merchant Name</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="storeId"
              //   value={serchData.storeId}
              //   onChange={onChangeMerchantSearchDetail}
            />
          </div>
          <div className="min-width-200 col-md-3">
            <label>Agent ID</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="agentId"
              //   value={serchData.merchantName}
              //   onChange={onChangeMerchantSearchDetail}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn input-inside-text mr-1 btn-white border px-4"
            // onClick={() => resetSearch()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn input-inside-text mx-1 btn-secondary px-3"
            // onClick={() => searchMerchat()}
          >
            Search
          </button>
        </div>
      </div>

      <br />
      <br />
      <div className="container_body container-fluid">
        <div className="card p-3">
          <span>ORDER RETURN/REFUND LISTING</span>
          <div className=" border card  my-3">
            <Tabs
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              variant="scrollable"
              orientation="horizontal"
              scrollButtons={false}
              visibleScrollbar={false}
              className="px-3"
              aria-label="basic tabs example"
              style={{ backgroundColor: "secondary" }}
            >
              <Tab label={`All(${total?.total || 0})`} {...a11yProps(0)} />
              <Tab
                label={`Processing(${total?.processing || 0})`}
                {...a11yProps(1)}
              />
              <Tab
                label={`Under Review(${total?.review || 0})`}
                {...a11yProps(2)}
              />
              <Tab
                label={` Refunded(${total?.refund || 0}) `}
                {...a11yProps(3)}
              />
              <Tab
                label={`Merchant To Respond(${total?.merchatToRespond || 0})`}
                {...a11yProps(4)}
              />
              <Tab
                label={`UU2 To Respond(${total?.UU2ToRespond || 0})`}
                {...a11yProps(5)}
              />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <Return_Refund
                // search={search}
                status=""
                // totalItem={total.total}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <Return_Refund status="Processing" />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <Return_Refund status="underReview" />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <Return_Refund status="Refunded" />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              <Return_Refund status="merchatToRespond" />
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
              <Return_Refund status="UU2ToRespond" />
            </TabPanel>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MerchantReturnOrder;
