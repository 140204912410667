import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InputField from "../../../components/formControl/input/InputField";
import { IOSSwitch } from "../../../components/switch/IOSSwitch";
import { CATEGORY_SELECTION_DIALOG } from "../../../lib/constant";
import {
  AllowAccess,
  open_dialog,
  showToast,
  startLoader,
  stopLoader,
} from "../../../lib/global";
import {
  getAllSubCategoriesList,
  getCategoryDetailsById,
} from "../../../redux/actions/category.action";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import {
  addCategoryApi,
  getParentApi,
  updateCategoryApi,
} from "../../../services/category.service";
const ProductAddCategory = () => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const [errorList, setErrorList] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [title, setTitle] = useState();
  const [parentCategory, setParentCategory] = useState({});
  const [status, setStatus] = useState("Disable");
  const [categoryDiscription, setCategoryDiscription] = useState(null);
  const [commission, setCommission] = useState();
  const { id } = useParams();
  useEffect(() => {
    const breadcrumb = [
      { label: "Product Category", url: "/products/categories" },
      { label: id ? "Edit Category" : "Add Category", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
    dispatch(getCategoryDetailsById(id))
      .then((data) => {
        console.log(data), "hhhhhhhhhhhhhhhhhhhhh";
        const categories = data?.data?.data;
        console.log(categories), "mmmmmmmmmmmmmmmm";
        setTitle(categories.title);
        setParentCategory(categories.parentCategory);
        setStatus(categories.status == "Disable" ? false : true);
        setCategoryDiscription(categories.description);
        setCommission(categories.commission_rate);
      })
      .catch((error) => {
        console.log({ error });
      });
  }, []);

  const createCategoryFun = () => {
    startLoader();
    const data = {
      title,
      parentCategoryId: parentCategory?.id,
      status: status ? "Active" : "Disable",
      description: categoryDiscription,
      commission_rate: commission,
    };

    if (id) {
      console.log('ripto', id);
      updateCategoryApi(data, id)
        .then((data) => {
          stopLoader();
          console.log(data);
          showToast("category updated successfully.", "success");

          // history.push("/products/categories");
          history.goBack();
        })
        .catch((err) => {
          console.log(err);
          showToast("error occur", "danger");
          stopLoader();
        });
      stopLoader();
    } else {
      addCategoryApi(data)
        .then(() => {
          stopLoader();
          showToast("category added successfully.", "success");
          history.push("/products/categories");
        })
        .catch((err) => {
          console.log(err);
          stopLoader();
          if (err.response.status==409){
            showToast("can't add duplicate category", "error");
          }
          else {
            showToast(err.message, "error");
          }
        });
    }
  };

  const handleSelectCategory = () => {
    // validateSalesInfo()
    open_dialog(CATEGORY_SELECTION_DIALOG, {
      onSubmit: onSelectParentCategory,
      parentCategory,
      maxWidth: "70vw",
      disableLevel4: true,
    });
  };
  const onSelectParentCategory = (data) => {
    setParentCategory(data);
  };
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-between flex-column h-100">
        <div className="mr-3 ml-4 mt-3 rounded  px-3 pt-4 pb-2 bg-white">
          <small>BASIC INFORMATION</small>
          <form className="mt-3  p-0">
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Category Name
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                placeholder="Category Name"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required={true}
              />
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small">
                Parent Category
              </label>
              <div
                onClick={handleSelectCategory}
                className="col border form-control mx-3 pointer bg-muted"
              >
                {parentCategory?.title || (
                  <p className="text-muted">Root Level</p>
                )}
              </div>
            </div>

            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small">
                Category Description
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="textarea"
                value={categoryDiscription}
                onChange={(e) => setCategoryDiscription(e.target.value)}
                placeholder="Category Description"
              />
            </div>
            <div className="col-12 row mb-2">
              <label className="col-3 text-right m-0 p-0 small imp">
                Commission Rate (%)
              </label>
              <InputField
                className=""
                formClass="col-9"
                type="text"
                placeholder="Commission Rate"
                value={commission}
                onChange={(e) => setCommission(e.target.value)}
                required={true}
              />
            </div>
            <div className="col-12 row my-3">
              <label className="col-3 text-right m-0 p-0 small">Status</label>
              <div className="form-group col d-flex">
                <IOSSwitch
                  checked={status}
                  onChange={(e) => setStatus(e.target.checked)}
                />
                <p className="mx-3 text-muted">
                  {status ? "Enabled" : "Disabled"}
                </p>
              </div>
            </div>
          </form>
        </div>
        <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
          <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
            <button
              type="button"
              className="btn border input-inside-text mr-2"
              onClick={history.goBack}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn border btn-dark input-inside-text mr-2"
              onClick={() => createCategoryFun()}
              disabled={!title || !categoryDiscription}
            >
              {id ? "Save/Edit" : "Create"}
            </button>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};
export default ProductAddCategory;
