import React from "react";
import { salesChart } from "../../fixtures/salesChart";
import SalesGraph from "./salesCharts/SalesGraph";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import MySvgIcon from "../../components/Images/SvgIcon";
import { AllowAccess } from "../../lib/global";

const DashboardPage = () => {
  AllowAccess(["SUPERADMIN","MERCHANT","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const ListCard = ({item, index}) => {
    return (
      <div style={{background: gradientList[index]}} className="list_card m-2 pointer">
        <div className="card_item">
          <div className="count bold">{item.amount}</div>
          <div className="title">{item.title}</div>
        </div>
        <div className="card_icon">
          {item.icon}
        </div>
      </div>
    );
  };
  return (
    <React.Fragment>
      <div className="dahsboard_header_tab">
        <ul className="d-flex m-0 tab_list text-white p-2">
          <li>
            <ListCard item={dashboardItems[0]} index={0} />
          </li>
          <li>
            <ListCard item={dashboardItems[1]} index={1}  />
          </li>
          <li>
            <ListCard item={dashboardItems[2]} index={2}  />
          </li>
          <li>
            <ListCard item={dashboardItems[3]} index={3}  />
          </li>
        </ul>
      </div>
      <div className="container-fluid py-3">
        <div className="col-12 row m-0 p-0">
          <div className="sales_chart col-12 card shadow mb-3">
            <SalesGraph
              series={salesChart.series}
              categories={salesChart.categories}
              type="bar"
              xaxisTitle="This month sales"
              yaxisTitle="Sales income in Rupees (₹)"
            ></SalesGraph>
          </div>
          <div className="col-12 d-flex justify-content-between p-0">
            <div className="w-49 card shadow mb-3">
              <SalesGraph
                series={salesChart.series}
                categories={salesChart.categories}
                type="line"
                xaxisTitle="This month sales"
                yaxisTitle="Sales income in Rupees (₹)"
              ></SalesGraph>
            </div>
            <div className="w-49 card shadow mb-3">
              <SalesGraph
                series={salesChart.series}
                categories={salesChart.categories}
                type="area"
                xaxisTitle="This month sales"
                yaxisTitle="Sales income in Rupees (₹)"
              ></SalesGraph>
            </div>
          </div>
          <div className="col-12 d-flex justify-content-between p-0">
            <div className="w-49 card shadow mb-3">
              <SalesGraph
                series={salesChart.series}
                categories={salesChart.categories}
                type="bar"
                xaxisTitle="This month sales"
                yaxisTitle="Sales income in Rupees (₹)"
              ></SalesGraph>
            </div>
            <div className="w-49 card shadow mb-3">
              <SalesGraph
                series={salesChart.series}
                categories={salesChart.categories}
                type="bar"
                xaxisTitle="This month sales"
                yaxisTitle="Sales income in Rupees (₹)"
              ></SalesGraph>
            </div>
          </div>
        </div>
      </div>
      <style jsx="true">{`
        .dahsboard_header_tab {
          // background: #000000d6;
        }
        .tab_list {
          list-style: none;
        }
        .dahsboard_header_tab > ul {
          flex-wrap: wrap;
        }
        .list_card {
          padding: 10px;
          display: flex;
          min-width: 200px;
          border-radius: 4px;
          box-shadow: 0 0 5px 0px #0000004f;
        }
        .card_item {
          flex-grow: 8;
          text-align: left;
        }
        .card_icon {
          flex-grow: 2;
          display: flex;
          align-items: center;
        }
      `}</style>
    </React.Fragment>
  );
};

export default DashboardPage;


const gradientList = [
  "linear-gradient(270deg, #8e50ff 0, #6946ff 25%, #3c3cf2 50%, #0032da 75%, #0029c2 100%)",
  "linear-gradient(270deg, #776d4a 0, #706b46 7.14%, #676a42 14.29%, #5d673e 21.43%, #51653a 28.57%, #446137 35.71%, #345e34 42.86%, #1f5932 50%, #005431 57.14%, #005033 64.29%, #004c36 71.43%, #00483a 78.57%, #004540 85.71%, #004246 92.86%, #003f4d 100%)",
  "linear-gradient(270deg, #c4d472 0, #cdcd68 6.25%, #d6c55e 12.5%, #ddbc56 18.75%, #e3b24e 25%, #e9a647 31.25%, #ed9941 37.5%, #f08a3e 43.75%, #f2793c 50%, #f3673d 56.25%, #f45442 62.5%, #f3404a 68.75%, #f22753 75%, #ef005f 81.25%, #eb006c 87.5%, #e5007a 93.75%, #dd0089 100%)",
  "linear-gradient(70deg, #ff80d1 0, #ff76d8 10%, #ff6cdf 20%, #f562e6 30%, #db5aec 40%, #bc53f2 50%, #954ff8 60%, #604ffd 70%, #0051ff 80%, #0054ff 90%, #0056ff 100%)",

]

const dashboardItems = [
  {
    amount: 2050,
    title: "Total Sales",
    icon: <ShoppingCartOutlinedIcon fontSize="large" />
  },
  {
    amount: 3250,
    title: "Total Expense",
    icon: <AccountBalanceWalletOutlinedIcon fontSize="large" />
  },
  {
    amount: "87.5%",
    title: "Total Revenue",
    icon: <MonetizationOnOutlinedIcon fontSize="large" />
  },
  {
    amount: 2250,
    title: "New Users",
    icon: <PersonOutlineOutlinedIcon fontSize="large" />
  }
]