import React, {useEffect, useState } from "react";
import "../../../../static/css/deleteModal.css"
import CloseIcon from "@mui/icons-material/Close";
import { deleteMerchant, getAllMerchantListAfterDelete } from "../../../../redux/actions/merchant-center.action";
import { useDispatch } from "react-redux";
import {AllowAccess, open_dialog, parseQuery, showToast, startLoader, stopLoader } from "../../../../lib/global";
import Image from "../../../../components/Images/Image";
import { useHistory } from "react-router-dom";
import {
  getAllFlashSaleProductByMerchantId,
  getAllProductByMerchantId
} from "../../../../redux/actions/marketing.action";
import { FLASH_SALE_ADD_PRODUCT } from "../../../../lib/constant";
import { IOSSwitch } from "../../../../components/switch/IOSSwitch";
import SelectInput from "../../../../components/select";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import InputField from "../../../../components/formControl/input/InputField";


const FlashSaleAddProductListDialog = (props) => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [status, setStatus] = useState("Disable");


  return (
    <React.Fragment>
      <div className=" d-flex flex-column justify-content-between container_body vh-500">
        <div className="container-fluid">
          <div className="card p-4 mb-3">
            <div className="mb-3">
              <div>
                <div className="float-left d-flex align-items-center flex-nowrap ">
                  <Image
                    src="images/shoes.jpeg"
                    alt={'photo'}
                    className="teamPurchase_page_product_image"
                  />
                  <div className="px-2">TEST</div>
                </div>

                <div className={`float-right d-block`}>
                  <label className="px-3">Participate</label>
                  <IOSSwitch
                    checked={status}
                    onChange={(e) => setStatus(e.target.checked)}
                  />
                </div>
              </div>
              <div className={`float-right d-block`}>
                <div className="d-flex flex-row justify-content-between bg-light rounded p-3">
                  <div className="d-flex flex-row align-items-center ">
                    <p className="mt-4 text-nowrap">Batch Updated</p>
                    <div className="d-flex mx-4 flex-column w-50">
                      <label>Campaign Stock</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Input"
                      />
                    </div>
                    <div className="d-flex flex-column w-50">
                      <label>Unit Capped</label>
                      <div >

                      </div>
                    </div>
                  </div>
                  <button className="btn btn-outline-danger m-3 px-3">Update All</button>
                </div>

                <br/>

                <br />
                <div className="border" style={{ borderRadius: "10px", overflow: "hidden" }}>
                  <div className=" bg-light">
                    <div className="pl-3 pt-3 mb-0 fntSz18 bg-light">Individual Purchase</div>
                    <hr />
                  </div>
                  <div className="mx-3">
                    <TableContainer>
                      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              className="th_cell"
                              component="th"
                              align="left"
                            >
                              <div className="d-flex  ">
                                <div>Variation(s)</div>

                              </div>
                            </TableCell>
                            <TableCell
                              className="th_cell"
                              component="th"
                              align="left"
                            >
                              <div className="d-flex  ">
                                <div>Original Price</div>

                              </div>
                            </TableCell>
                            <TableCell
                              className="th_cell "
                              component="th"
                              align="left"
                            >
                              <div className="d-flex  ">
                                <div>Discounted Price</div>

                              </div>
                            </TableCell>
                            <TableCell
                              className="th_cell "
                              component="th"
                              align="left"
                            >
                              <div className="d-flex  ">
                                <div>Campaign Stock</div>

                              </div>
                            </TableCell>
                            <TableCell
                              className="th_cell "
                              component="th"
                              align="left"
                            >
                              <div className="d-flex  ">
                                <div>Stock</div>

                              </div>
                            </TableCell>
                            <TableCell
                              className="th_cell "
                              component="th"
                              align="left"
                            >
                              <div className="d-flex  ">
                                <div>Unit Capped</div>

                              </div>
                            </TableCell>

                            <TableCell className="table_action_head text-secondary" component="th">
                              Enable/Disable
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>


                              <TableRow
                                // key={}
                                className="border-0"
                                sx={{
                                  "&:last-child td, &:last-child th": { border: 0 },
                                }}

                              >

                                <TableCell align="left" className="td_cell border-0">
                                  "N/A"
                                </TableCell>
                                <TableCell align="left" className="td_cell border-0">
                                  "N/A"
                                </TableCell>
                                <TableCell align="left" className="td_cell border-0">
                                  <InputField
                                    className="input_icon_left"
                                    formClass="col-12 p-0"
                                    type="number"
                                    id="individual-price"
                                    icon="currency"
                                    placeholder="Input"
                                    name="price"
                                    // value={salesInformation.price}
                                    // onChange={onChangeSalesInfo}
                                    // required={true}
                                  />
                                </TableCell>

                                <TableCell align="left" className="td_cell border-0">
                                  <input
                                    type="text"
                                    placeholder="Input"
                                    className="w-75 form-control"
                                  />
                                </TableCell>
                                <TableCell align="left" className="td_cell border-0">
                                  "N/A"
                                </TableCell>
                                <TableCell align="left" className="td_cell border-0">
                                  <input
                                    type="text"
                                    placeholder="Input"
                                    className="w-75 form-control"
                                  />
                                </TableCell>


                                <TableCell className="d-flex border-0 table_action_item">
                                  <IOSSwitch
                                    checked={status}
                                    onChange={(e) => setStatus(e.target.checked)}
                                  />
                                </TableCell>
                              </TableRow>

                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
                <br />

              </div>
            </div>
          </div>
        </div>


        {/*<style jsx="true">{`*/}
        {/*            .card-body {*/}
        {/*            min-height: 50vh;*/}
        {/*            }*/}
        {/*            .submit_btn {*/}
        {/*            position: absolute;*/}
        {/*            bottom: 15px;*/}
        {/*            width: 100%;*/}
        {/*            justify-content: center;*/}
        {/*            display: flex;*/}
        {/*            }*/}
        {/*            .close_icon_btn {*/}
        {/*            position: absolute;*/}
        {/*            right: 0;*/}
        {/*            cursor: pointer;*/}
        {/*            }*/}
        {/*            .flash_sale_product_image{*/}
        {/*            width: 45px;*/}
        {/*            height: 40px;*/}
        {/*            border-radius: 3px;*/}
        {/*            }*/}
        {/*            input{*/}
        {/*                cursor:pointer;*/}
        {/*            }*/}
        {/*            `}</style>*/}

      </div>
    </React.Fragment>
  )
}

export default FlashSaleAddProductListDialog;