import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TabPanel } from "../../../components/Tabs/TabPannel";
import { AllowAccess, parseParam, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import InputField from "../../../components/formControl/input/InputField";
// import AllOrders from "./AllOrders";
import AllOrders from "../../../pages/order/Order_Listing/AllOrders";
import { getOrderByStoreId } from "../../../redux/actions/order.action";
import { useParams } from "react-router-dom";
import { getLocalStorage } from "../../../lib/session";
import DateRangePickerComp from "../../../components/datepicker/MUIDateRange";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialSearchData = {
  buyerName: "",
  merchantName: "",
  orderId: "",
  productName: "",
  orderDate: "",
};

const initOrderRangeDate = {
  startDate: "",
  endDate: "",
};

const MerchantOrderListing = () => {
  AllowAccess(["MERCHANT"]);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [buyerName, setBuyerName] = useState("");
  const [merchantName, setMerchantName] = useState("");
  const [productName, setProductName] = useState("");
  const [total, setTotal] = useState({});
  const [search, setSearch] = useState(initialSearchData);
  const [reset, setReset] = useState(false);
  const [orderDateRange, setOrderDateRange] = useState(initOrderRangeDate);
  const handleDateChange = (data) => {
    console.log(data, "sdfasfdasdfadsfasdfasdfa");
    setOrderDateRange(data);
  };

  const param = useParams();
  const storeId = param.mid ? param.mid : getLocalStorage("storeId");

  useEffect(() => {
    AllowAccess(["MERCHANT"]);
    // startLoader();
    // const paramList = parseParam(history?.location?.pathname);
    // console.log(paramList);
    let breadcrumb = [];
    if (param.mid) {
      breadcrumb = [
        { label: "Merchant Profile", url: "/merchant-center/profiles" },
        { label: param.merchantName, url: `` },
      ];
    } else {
      breadcrumb = [
        // { label: "Order", url: "/merchant/orders/all-orders" },
        { label: "Buy Now", url: "" },
      ];
    }
    dispatch(breadcrumbAction(breadcrumb));
    // dispatch(getOrderByStoreId(storeId, {}))
    //   .then((data) => {
    //     // setTimeout(() => {
    //     stopLoader();
    //     console.log(data?.data);
    //     const newData = data.data.data.filter(
    //       (item) => item.status !== "DELETED"
    //     );
    //     console.log(newData, "kkkkkkkkkk");
    //     // setTotal({ ...data.data?.total, total: newData.length });
    //     setTotal({ ...(data?.data?.total || {}) });
    //     // console.log(data);
    //     // setTotal(data && data.data && data.data.total);
    //     // }, 1000);
    //   })
    //   .catch((error) => {
    //     // stopLoader()
    //     console.log(error);
    //   });
  }, []);

  const afterDelete = (data) => {
    console.log(data, "total");
    setTotal(data);
    setTabIndex(0);
  };

  const searchData = () => {
    setSearch({
      buyerName,
      merchantName,
      productName,
      orderId,
      ...orderDateRange,
    });
  };
  const handleResetSearch = () => {
    setReset(true);
    setOrderDateRange(initOrderRangeDate);
    setBuyerName("");
    setMerchantName("");
    setOrderId("");
    setProductName("");
    setSearch({
      buyerName: "",
      merchantName: "",
      productName: "",
      orderId: "",
    });
  };
  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-11 row flex-wrap">
          <div className="min-width-200 col-md-3">
            <label>Order ID</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="storeId"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-3">
            <label>Buyer Name</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="merchantName"
              value={buyerName}
              onChange={(e) => setBuyerName(e.target.value)}
            />
          </div>
        
          <div className="min-width-200 col-md-3">
            <label>Product Name</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="merchantName"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-3">
            <label className=""> Order Date</label>

            <DateRangePickerComp
              reset={reset}
              setReset={setReset}
              handleDateChange={handleDateChange}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn input-inside-text mr-1 btn-white border px-4"
            onClick={() => handleResetSearch()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn input-inside-text mx-1 btn-secondary px-3"
            onClick={() => searchData()}
          >
            Search
          </button>
        </div>
      </div>
      <br />
      <br />
      <div className=" container_body container-fluid">
        <div className="card p-3">
          <span>ORDER LISTING</span>
          <div className=" border card  my-3">
            <Tabs
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              variant="scrollable"
              orientation="horizontal"
              scrollButtons={false}
              visibleScrollbar={false}
              className="px-3"
              aria-label="basic tabs example"
              style={{ backgroundColor: "secondary" }}
            >
              <Tab label={`All(${total?.total || 0})`} {...a11yProps(0)} />
              <Tab
                label={`Pending Payment(${total?.pending_payment || 0})`}
                {...a11yProps(1)}
              />
              {/* <Tab label={`Team Purchase(${total?.team_purchase || 0})`} {...a11yProps(2)} /> */}
              <Tab
                label={`To Ship(${total?.to_ship || 0})`}
                {...a11yProps(2)}
              />
              <Tab
                label={`Shipping(${total?.shipping || 0})`}
                {...a11yProps(3)}
              />
              {/* <Tab label={`Processing(${total?.processing || 0})`} {...a11yProps(3)} /> */}
              <Tab
                label={`Delivered(${total?.delivered || 0})`}
                {...a11yProps(4)}
              />
              <Tab
                label={`Completed(${total?.completed || 0})`}
                {...a11yProps(5)}
              />
              <Tab
                label={`Cancelled(${total?.cancelled || 0})`}
                {...a11yProps(6)}
              />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <AllOrders
                totalItem={total.total}
                search={search}
                afterDelete={afterDelete}
                status=""
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <AllOrders
                totalItem={total.pending_payment}
                search={search}
                afterDelete={afterDelete}
                status="PENDINGPAYMENT"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <AllOrders
                totalItem={total.to_ship}
                search={search}
                afterDelete={afterDelete}
                status="TOSHIP"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            {/* <TabPanel value={tabIndex} index={2}>
                            <TeamPurchase totalItem={total.team_purchase} search={search} afterDelete={afterDelete} />
                        </TabPanel> */}
            <TabPanel value={tabIndex} index={3}>
              <AllOrders
                totalItem={total.shipping}
                search={search}
                afterDelete={afterDelete}
                status="SHIPPING"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              <AllOrders
                totalItem={total.delivered}
                search={search}
                afterDelete={afterDelete}
                status="DELIVERED"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
              <AllOrders
                totalItem={total.delivered}
                search={search}
                afterDelete={afterDelete}
                status="COMPLETED"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={6}>
              <AllOrders
                totalItem={total.cancelled}
                search={search}
                afterDelete={afterDelete}
                status="CANCELLED"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MerchantOrderListing;
