import React from "react";
import {} from "@mui/material";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  createNewAppVersion,
  getAllVersionsData,
} from "../../../redux/actions/appVersion.action";
import { showToast, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";

const AppVersionForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const applicationType = queryParams.get("applicationType");

  const [appVersionData, setAppVersionData] = useState({
    type: "",
    major: "",
    minor: "",
    patch: "",
  });

  useEffect(() => {
    let label = "Add";
    if (applicationType) {
      label = "Edit";
      versionData(applicationType);
    }

    const breadcrumb = [{ label: `Versions / ${label}`, url: null }];
    dispatch(breadcrumbAction(breadcrumb));
  }, []);

  const versionData = (type) => {
    dispatch(getAllVersionsData(type))
      .then(({ data }) => {
        const version = data?.data[0].version.split(".");
        setAppVersionData({
          type,
          major: version[0],
          minor: version[1],
          patch: version[2],
        });
      })
      .catch((err) => {
        console.log(err);
        showToast("Internal Server Error", "error");
      });
  };

  const handleDeleteButton = () => {
    setAppVersionData({
      type: "",
      major: "",
      minor: "",
      patch: "",
    });
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setAppVersionData({
      ...appVersionData,
      [`${name}`]: value,
    });
  };

  const handleCancelButton = () => {
    history.push("/settings/app-version");
  };
  const handleSaveButton = () => {
    startLoader();
    const { type, major, minor, patch } = appVersionData;
    const payload = {
      applicationType: type,
      version: `${major}.${minor}.${patch}`,
    };
    dispatch(createNewAppVersion(payload))
      .then(({ data }) => {
        if (data.status === 201) {
          showToast(data.message, "success");
          setTimeout(() => {
            history.push("/settings/app-version");
            stopLoader();
          }, 1000);
        }
      })
      .catch((err) => {
        console.log(err);
        showToast("Internal Server Error", "error");
      });
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="card px-4 py-3 bg-body bg-white rounded shadow-sm">
          <div className="d-flex justify-content-between align-items-center">
            {applicationType ? (
              <>
                <p className="h3">
                  <strong>Edit Version</strong>
                </p>
                <Button
                  onClick={handleDeleteButton}
                  variant="danger"
                  type="button"
                  className="text-nowrap p-2 my-2 rounded-lg w-20"
                >
                  <strong>Delete</strong>
                </Button>
              </>
            ) : (
              <p className="h3">
                <strong>Add New Version</strong>
              </p>
            )}
          </div>
          <div>
            <form className="py-3">
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label for="inputType" className="text-right small imp">
                    Application type
                  </label>
                  <select
                    id="inputType"
                    name="type"
                    className="form-control"
                    value={appVersionData.type}
                    onChange={(e) => handleOnChange(e)}
                  >
                    <option selected>Choose...</option>
                    <option value="Android">Android</option>
                    <option value="iOS">iOS</option>
                    <option value="Huawei">Huawei</option>
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <label for="inputMajor" className="text-right small imp">
                    Major version
                  </label>
                  <input
                    type="text"
                    name="major"
                    className="form-control"
                    id="inputMajor"
                    placeholder="Major"
                    value={appVersionData.major}
                    onChange={(e) => handleOnChange(e)}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label for="inputMinor" className="text-right small imp">
                    Minor version
                  </label>
                  <input
                    type="text"
                    name="minor"
                    className="form-control"
                    id="inputMinor"
                    placeholder="Minor"
                    value={appVersionData.minor}
                    onChange={(e) => handleOnChange(e)}
                  />
                </div>
                <div className="form-group col-md-6">
                  {/* <label className="col-3 my-auto text-right small imp"> */}

                  <label for="inputPatch" className="text-right small imp">
                    Patch version
                  </label>
                  <input
                    type="text"
                    name="patch"
                    className="form-control"
                    id="inputPatch"
                    placeholder="Patch"
                    value={appVersionData.patch}
                    onChange={(e) => handleOnChange(e)}
                  />
                </div>
              </div>
            </form>
          </div>
          <div>
            {appVersionData.type == "" ||
            appVersionData.major == "" ||
            appVersionData.minor == "" ||
            appVersionData.patch == "" ? null : (
              <Button
                onClick={handleSaveButton}
                variant="danger"
                type="button"
                className="text-nowrap rounded-lg mr-2"
              >
                <strong>Save</strong>
              </Button>
            )}
            <Button
              onClick={handleCancelButton}
              variant="secondary"
              type="button"
              className="text-nowrap rounded-lg"
            >
              <strong>Cancel</strong>
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AppVersionForm;
