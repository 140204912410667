import React from "react";

import "../../static/css/deleteModal.css"
import CloseIcon from "@mui/icons-material/Close";
import { deleteMerchant, getAllMerchantListAfterDelete } from "../../redux/actions/merchant-center.action";
import { useDispatch } from "react-redux";
import { showToast, startLoader, stopLoader } from "../../lib/global";
import { useHistory } from "react-router-dom";
const CancelEditModal = (props) => {
    // console.log(props, "kkkkkkkkkkkkkkkkkkkkkkkk");
    const dispatch = useDispatch();
    const history=useHistory()
    const confirm=()=>{
        props.onClose();
        props.goBackUrl?
        history.push(props.goBackUrl)
        :history.goBack()
        
    }
    return (

        <React.Fragment>
            <div className="delete_modal_body">


                <div className="close_icon" style={{ top: "0px" }}>
                    <CloseIcon
                        className="close_icon_btn m-2"
                        color="primary2"
                        fontSize="large"
                        title="Close dialog"
                        onClick={props.onClose}
                    />
                </div>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="confirmation_dialogbox__content">
                        <div className="dialogbox_body__content">
                            <h1 className="d-flex flex-direction-row justify-content-center align-items-center"><span className="dialogbox_header__icon">!</span></h1>
                            <h4>Are you sure you want to Cancel?</h4>
                            <p>Your Changes will not be add or update.<br />You are unable to restore these changes back again.</p>
                        </div>
                    </div>
                </div>
                <div className="dialogbox_footer__content">
                    <button type="button" className="dialogbox_delete__btn" onClick={() => confirm()}>Confirm</button>
                    <button type="button" className="dialogbox_cancel__btn" onClick={props.onClose}>Cancel</button>

                </div>
            </div>
            <style jsx="true">{`
          .card-body {
            min-height: 50vh;
          }
          .submit_btn {
            position: absolute;
            bottom: 15px;
            width: 100%;
            justify-content: center;
            display: flex;
          }
          .close_icon_btn {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
        `}</style>
        </React.Fragment>

    )
}

export default CancelEditModal