import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../components/Images/Image";
import { MAX_IMAGE_SIZE } from "../../lib/config";
import {
  AllowAccess,
  showToast,
  startLoader,
  stopLoader,
  UploadImage,
} from "../../lib/global";
import {
  createNewBanner,
  getBannerById,
  updateBannerById,
} from "../../redux/actions/banner.action";
import { breadcrumbAction } from "../../redux/actions/config.action";
import Select2 from "react-select";
import { IOSSwitch } from "../../components/switch/IOSSwitch";
import InputField from "../../components/formControl/input/InputField";
import { formatDate } from "../../lib/helper";

function AddBanner() {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const { bannerId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [webImageFile, setWebImageFile] = useState();
  const [webImageUrl, setWebImageUrl] = useState();
  const [mobileImageFile, setMobileImageFile] = useState();
  const [mobileImageUrl, setMobileImageUrl] = useState();
  const [title, setTitle] = useState();
  const [linkedWith, setLinkedWith] = useState();
  const [platform, setPlatform] = useState();
  const [position, setPosition] = useState();
  const [description, setDescription] = useState();
  const [isActive, setIsActive] = useState(false);
  const [isScheduled, setIsScheduled] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    const breadcrumb = [
      { label: "Banners", url: "/Banner" },
      { label: bannerId ? "Edit Banner" : "Add Banner", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
    if (bannerId) {
      dispatch(getBannerById(bannerId))
        .then((data) => {
          if(data?.data?.data){
            let result = data?.data?.data
            console.log(result,findPositionValue(result?.position), "banner data by id");
            setWebImageUrl(result?.webImage);
            setMobileImageUrl(result?.mobileImage);
            setLinkedWith(result?.linkedWith);
            setTitle(result?.title);
            setDescription(result?.description);
            setPosition(findPositionValue(result?.position));
            setPlatform(findPlatformValue(result?.platform));
            setIsActive(result?.status);
            setIsScheduled(result?.isScheduled);
            setStartDate(result?.startDate);
            setEndDate(result?.endDate);
          }
          
        })
        .catch((err) => {
          console.log({ err });
        });
    }
  }, []);

  // useEffect(() => {
  //   console.log(isActive, "isActive");
  //   if(!isActive){
  //     setIsScheduled(false)
  //     setStartDate(null)
  //     setEndDate(null)
  //   }
  // },[isActive])

  const findPlatformValue = (value) => {
    let result = ""
    if(value){
      platformList?.map((item)=>{
        if(item?.value == value){
          result = item
        }
      })
    }
    return result
  }

  const findPositionValue = (value) => {
    let result = ""
    if(value){
      positionList?.map((item)=>{
        if(item?.value == value){
          result = item
        }
      })
    }
    return result
  }

  const handleUploadFile = async (e, type) => {
    const fileData = e.target.files[0];
    if (fileData.size > MAX_IMAGE_SIZE) {
      showToast(
        `Image with size more than ${
          MAX_IMAGE_SIZE / 1000000
        }MB is not supported.\nPlease upload image of size smaller than ${
          MAX_IMAGE_SIZE / 1000000
        }MB.\nCurrent file size: ${(fileData.size / 1000000).toFixed(2)}MB`,
        "error",
        3000,
        "bottom",
        "center"
      );
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      const imageUrlIns = reader.result;
      type == "web"
        ? setWebImageFile(imageUrlIns)
        : setMobileImageFile(imageUrlIns);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const validate = () => {
    if (!platform) {
      showToast("Platform is required", "error");
      return false;
    }
    if (!position) {
      showToast("Position is required", "error");
      return false;
    }
    if (!title) {
      showToast("Title is required", "error");
      return false;
    }
    if (!linkedWith) {
      showToast("Link is required", "error");
      return false;
    }
    if(linkedWith && checkValidUrl(linkedWith) === false){
      showToast("Link is not valid", "error");
      return false;
    }
    if (!description) {
      showToast("Description is required", "error");
      return false;
    }
    if(isScheduled){
      if(!startDate){
        showToast("Start date is required", "error");
        return false;
      }
      if(!endDate){
        showToast("End date is required", "error");
        return false;
      }
    }
    return true;
  }

  const checkValidUrl = (url) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    ); // fragment locator
    if (!pattern.test(url)) {
      return false;
    }
    return true;
  }

  const onSubmit = async () => {
    startLoader();

    if(!validate()){
      stopLoader();
      return
    }

    let webUrl = webImageFile? await UploadImage(webImageFile):webImageUrl;
    let mobileUrl =mobileImageFile? await UploadImage(mobileImageFile):mobileImageUrl;
  
   let payload = {
      title,
      description,
      platform:platform.value,
      position:position.value,
      status: isActive?true:false,
      linkedWith,
      // isScheduled:(!isActive && isScheduled)?true:false,
      // startDate:(!isActive && isScheduled)?startDate:null,
      // endDate:(!isActive && isScheduled)?endDate:null,
     isScheduled: isScheduled?true:false,
     startDate: isScheduled?startDate:null,
     endDate: isScheduled?endDate:null,
      webImage: webUrl,
      mobileImage: mobileUrl,
    };
    
    if (!bannerId) {
      dispatch(createNewBanner(payload))
        .then((res) => {
          console.log(res, "res res");
          setTimeout(() => {
            stopLoader();
            showToast("Banner added successfully", "success");
            history.push("/banner");
          }, 1000);
        })
        .catch((err) => {
          console.log({ err });
          stopLoader()
          // history.push("/banner");
        });
    } else {
        dispatch(updateBannerById(payload,bannerId))
        .then((res) => {
          console.log(res, "res res");
          setTimeout(() => {
            stopLoader();
            showToast("Banner updated successfully", "success");
            history.push("/banner");
          }, 1000);
        })
        .catch((err) => {
          console.log({ err });
          stopLoader()
          // history.push("/banner");
        });
    }
  };
  const platformList = [
    {
      title: "Both",
      label: "BOTH",
      value: "BOTH"
    },
    {
      title: "Web",
      label: "WEB",
      value: "WEB"
    },
    {
      title: "App",
      label: "App",
      value: "APP"
    }
  ]
  const positionList = [
    {
      title:"Header",
      label:"Header",
      value:"HEADER"
    },
    {
      title:"Middle",
      label:"Middle",
      value:"MIDDLE"
    }

  ]
  return (
    <>
      {/* <div className="position-relative" style={{height:""}}> */}
      <div className="card m-4 p-3">
        <div>
          <span>{bannerId ? "Edit Banner" : "Add Banner"}</span>
        </div>
        <br />
        <div className="row">
          <div className="col-12 col-md-6 p-4">
            <div className="row align-items-center">
              <label className="col-4 col-md-4 imp">
                Platform
              </label>
              <div className="px-0 col-8 col-md-8">
                <Select2
                  options={platformList}
                  // disabled={voucherData.isEnableForAllMerchant}
                  name="platform"
                  id="platform"
                  value={platform}
                  onChange={(e) => setPlatform(e)}
                  required={true}
                  isMulti={false}
                  placeholder="Select Platform"
                />
              </div>
            </div>
            <br />
            <div className="row align-items-center">
              <label className="col-4 col-md-4 imp">
                Position
              </label>
              <div className="px-0 col-8 col-md-8 ">
                <Select2
                  options={positionList}
                  // disabled={voucherData.isEnableForAllMerchant}
                  name="position"
                  id="position"
                  value={position}
                  onChange={(e) => setPosition(e)}
                  required={true}
                  isMulti={false}
                  placeholder="Select Position"
                />
              </div>
            </div>
            <br />
            <div className="row align-items-center">
              <label htmlFor="#" className="col-4 col-md-4 imp">
                Title
              </label>
              <input
                type="text"
                placeholder="Banner title"
                value={title}
                className="px-3 col-8 col-md-8 form-control"
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <br />
            <div className="row align-items-center">
              <label htmlFor="#" className="col-4 col-md-4 imp">
                Description
              </label>
              <div className="col-8 col-md-8 p-0">
                  <textarea
                    type="text"
                    id="description"
                    name="description"
                    value={description}
                    onChange={(e)=>setDescription(e.target.value)}
                    className={"form-control"}
                    placeholder="Banner description"
                    rows={3}
                  ></textarea>
              </div>
            </div>
            <br />
            { platform && (platform.value === "WEB" || platform.value === "BOTH" ) && (
              <div className="row align-items-center">
              <label htmlFor="#" className="col-4 col-md-4 imp">
                Web Image
              </label>
              <div className="mx-2 mb-1">
                <input
                  accept="image/*"
                  id={`product-image-${"web"}`}
                  className="d-none"
                  onChange={(e) => handleUploadFile(e, "web")}
                  multiple={false}
                  type="file"
                />
                <div className="position-relative">
                  <label htmlFor={`product-image-${"web"}`}>
                    <Image
                      src={webImageFile|| webImageUrl || "/icons/upload_icon.svg"}
                      alt="upload"
                      className={`pointer upload_image_label`}
                    />
                  </label>
                </div>
              </div>
              </div>
            )}
            { platform && (platform.value === "APP" || platform.value === "BOTH" ) && (
              <div className="row align-items-center">
                <label htmlFor="#" className="col-4 col-md-4 imp">
                  Mobile Image
                </label>
                <div className="mx-2 mb-1">
                  <input
                    accept="image/*"
                    id={`product-image-${"mobile"}`}
                    className="d-none"
                    onChange={(e) => handleUploadFile(e, "mobile")}
                    multiple={false}
                    type="file"
                  />
                  <div className="position-relative">
                    <label htmlFor={`product-image-${"mobile"}`}>
                      <Image
                        src={mobileImageFile || mobileImageUrl || "/icons/upload_icon.svg"}
                        alt="upload"
                        className={`pointer upload_image_label`}
                      />
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-12 col-md-6 p-4">
            <div className="row">
              <label htmlFor="#" className="col-4 col-md-4 pl-4">
                Link
              </label>
              <input
                type="text"
                placeholder="Link"
                value={linkedWith}
                className="px-3 col-8 col-md-8  form-control"
                onChange={(e) => setLinkedWith(e.target.value)}
              />
            </div>
            <br />
            <div className="row align-items-center">
                <label className="col-4 col-md-4 pl-4 imp">
                  Activate 
                </label>
                <div className="px-3 col-8 col-md-8 ">
                  <IOSSwitch
                    name="active"
                    id="active"
                    onChange={(e) => {setIsActive(e.target.checked)}}
                    checked={isActive}
                    className="mt-2"
                  />
                </div>
            </div>
            <br />
            {/*{!isActive && (*/}
            {/*{isScheduled && (*/}
              <div className="row row align-items-center">
                <label className="col-4 col-md-4 pl-4 imp">
                  Schedule 
                </label>
                <div className="px-3 col-8 col-md-8 ">
                  <IOSSwitch
                    name="active"
                    id="active"
                    onChange={(e)=>{setIsScheduled(e.target.checked)}}
                    checked={isScheduled}
                    className="mt-2"
                  />
                </div>
            </div>
            {/*)}*/}
            <br />
            {/*{!isActive && isScheduled && (*/}
            {isScheduled && (
              <div className="row row align-items-center">
                <label className="col-12 col-md-4 pl-4 imp">
                  Period
                </label>
                <div className="form-group col-6 col-md-4">
                  <InputField
                    type="date"
                    className="form-control"
                    id="startPeriodDate"
                    name="startPeriodDate"
                    value={formatDate(startDate,'YYYY-MM-DD')}
                    onChange={(e)=>{setStartDate(e.target.value)}}
                  />
                </div>
                <div className="form-group col-6 col-md-4">
                  <InputField
                    type="date"
                    className="form-control"
                    id="endPeriodDate"
                    name="endPeriodDate"
                    value={formatDate(endDate,'YYYY-MM-DD')}
                    onChange={(e)=>{setEndDate(e.target.value)}}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <br />
        <footer className="d-flex position-sticky b-0 flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
          <div className="col-md-12  ">
            <div className="d-flex justify-content-end ">
              <button
                type="button"
                className="btn  btn-outline-danger input-inside-text mr-2"
                onClick={history.goBack}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn  btn-outline-danger  input-inside-text mr-2"
                onClick={() => onSubmit()}
                
              >
                {bannerId ? "Save" : "Create"}
              </button>
            </div>
          </div>
        </footer>
      </div>
      
      {/* </div> */}
    </>
  );
}

export default AddBanner;
