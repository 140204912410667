import {
  Icon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { makeStyles } from "@mui/styles";
import SelectDropdown from "../../../components/select";

import React, { useEffect, useState } from "react";
import { Div } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../../components/Images/Image";
import Box from "@mui/material/Box";
import decimalFormat from "../../../helpers/decimal.format";

import Paper from "@mui/material/Paper";
import SelectInput from "@mui/material/Select/SelectInput";
import merchantSidebarList from "../../../components/sidebar/MerchantSidebar";
import { orderStatuses } from "../../../shared/language";
import { getOrderById } from "../../../redux/actions/order.action";
import { useDispatch } from "react-redux";
import { getAllDatesInRange } from "react-multi-date-picker";
import {
  SHOW_TRANSACTION_HISTORY,
  VIEW_ORDER_TRANSACTION_HISTORY,
} from "../../../lib/constant";
import { open_dialog } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import {
  getOrderReturnById,
  patchOrderReturnById,
} from "../../../redux/actions/finance.action";
import InputField from "../../../components/formControl/input/InputField";
import moment from "moment";

const initialOrderInfo = {
  MerchantName: "nike 11-c",
  MerchantId: "12341234",
  BuyerName: "Jessica",
  ProductName: "Nike shoes 11",
  ProductCategory: "",
  OrderId: "",
  OrderDate: "",
  TransactionType: "",
  OrderStatus: "",
};
function ViewOrderAndReceiptInfo() {
  const [orderInfo, setOrderInfo] = useState();
  const [paymentInfo, setPaymentInfo] = useState();
  const [totalDeduction, setTotalDeduction] = useState();
  const [editRemark, setEditRemark] = useState(false);
  const [remark, setRemark] = useState("");

  const [item, setItem] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    const breadcrumb = [
      { label: "Return/Refund", url: "/finance/return-refund" },
      { label: `Order ID ${id || ""}`, url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
    if (id) {
      dispatch(getOrderReturnById(id))
        .then((data) => {
          if (data && data.data && data.data.data) {
            // console.log(data?.data?.data[0]);
            setItem(data?.data?.data[0]);
            setRemark(data?.data?.data[0].remark);
          }
          // setFullData()
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [id]);

  function totalBuyerPayment(item) {
    return item?.order?.finalAmount || 0.0;
  }
  function transactionFee(item) {
    return (totalBuyerPayment(item) * 2.12) / 100 || 0.0;
  }
  function shippingFee(item) {
    let order = item.order;
    if (order) {
      if (order.trackingInfo != null && order.trackingInfo.price) {
        return order.trackingInfo.price;
      } else if (order.shippingDetails != null) {
        return order.shippingDetails.shippingCharge || 0.0;
      }
    }
    return "N/A";
  }
  function netContraAmount(item) {
    return totalBuyerPayment(item) - transactionFee(item) - shippingFee(item);
  }
  return (
    <React.Fragment>
      <div className="m-3  position-relative">
        <div className="card p-3">
          <h5>REFUND INFORMATION</h5>
          <div className="my-2">
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Merchant Name
              </label>
              <div className="ml-2 text-capitalize">
                {item?.merchantName || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Merchant ID
              </label>
              <div className="ml-2 text-capitalize">
                {item?.storeId || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Buyer Name
              </label>
              <div className="ml-2 text-capitalize">
                {item?.consumerName || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Product Name
              </label>
              <div className="ml-2 text-capitalize">
                {item?.order?.productName || item?.productName || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Product Category
              </label>
              <div className="ml-2 text-capitalize">
                {item?.order?.product?.categoryName || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Order ID
              </label>
              <div className="ml-2 text-capitalize">{item?.orderId}</div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Order Date
              </label>
              <div className="ml-2 text-capitalize">
                {/* {item?.order?.createdAt || "N/A"} */}
                {moment(item?.order?.createdAt).format("DD/MM/YYYY HH:m:ss") ||
                  "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Transaction Type
              </label>
              <div className="ml-2 text-capitalize">Retail TP</div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Refund ID
              </label>
              <div className="ml-2 text-capitalize">{item?.caseId}</div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Refund status
              </label>
              <div className="ml-2 text-capitalize">
                {item?.status || "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Cancel/ Refund Date
              </label>
              <div className="ml-2 text-capitalize">
                {moment(item?.createdAt).format("DD/MM/YYYY HH:m:ss") || "N/A"}
                {/* {item?.createdAt || "N/A"} */}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Reason for Cancellation/ Refund Request
              </label>
              <div className="ml-2 text-capitalize">
                {item?.reason || "N/A"}
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="card p-3">
          <h5>PAYMENT INFORMATION</h5>
          <div className="my-2">
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Payment Method
              </label>
              <div className="ml-2 text-capitalize">
                {item?.order?.payment?.payment_method?.Payment_Method ||
                  item?.order?.payment?.paymentMethod ||
                  "N/A"}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Refund Method
              </label>
              <div className="ml-2 text-capitalize">
                {item?.order?.payment?.refundMethod || "Pending.."}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Total Paid Amount
              </label>
              <div className="ml-2 text-capitalize text-success font-weight-bold">
                RM {decimalFormat(totalBuyerPayment(item))}
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="card p-3">
          <h5>TOTAL DEDUCTION BY UU2</h5>
          <div className="my-2">
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Transaction Fee
              </label>
              <div className="ml-2 text-capitalize">
                RM {decimalFormat(transactionFee(item))}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Shipping Fee
              </label>
              <div className="ml-2 text-capitalize">
                RM {decimalFormat(shippingFee(item))}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Net Contra Amount
              </label>
              <div className="ml-2 text-capitalize">
                RM {decimalFormat(netContraAmount(item))}
              </div>
            </div>
            <div className="d-flex">
              <label htmlFor="" className="col-3 text-right">
                Settlement Date
              </label>
              <div className="ml-2 text-capitalize">
                {item?.order?.settlement
                  ? moment(item?.order?.settlement?.createdAt).format(
                      "DD/MM/YYYY HH:m:ss"
                    )
                  : "N/A"}
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="card p-3">
          <h5>REMARK INFORMATION</h5>
          <div className="my-2">
            <div className="d-flex align-items-center ">
              <label htmlFor="" className="col-3 text-right">
                Remark
              </label>
              <div className="input-group ml-2  col-7 border rounded bg-light rounded-3 ">
                {/* <input type="text" className="form-control border-0  shadow-none bg-light col-11" /> */}
                {/*<textarea*/}
                {/*    className="form-control border-0  shadow-none bg-light col-11 "*/}
                {/*    disabled={!editRemark}*/}
                {/*    value={remark}*/}
                {/*    rows={editRemark ? 3 : 1}*/}
                {/*    onChange={(e) => setRemark(e.target.value)}*/}
                {/*    placeholder="add remark content here"*/}
                {/*></textarea>*/}
                <InputField
                  formClass="border-0 shadow-none bg-light col-11"
                  type="textarea"
                  placeholder="add remark content here"
                  id="remark"
                  disabled={!editRemark}
                  rows={editRemark ? 3 : 1}
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />

                <span
                  className="text-primary font-weight-bold input-group-text border-0 bg-light p-0 ml-2"
                  onClick={(e) => setEditRemark(true)}
                  style={{
                    visibility: editRemark ? "hidden" : "visible",
                  }}
                >
                  Edit
                </span>
              </div>
            </div>
            <div
              style={{
                visibility: editRemark ? "visible" : "hidden",
              }}
              className="py-2 col-10 d-flex justify-content-end"
            >
              <button
                onClick={() => {
                  setRemark(item?.remark || "");
                  setEditRemark(false);
                }}
                className="btn btn-outline-danger mr-3 px-3 btn-sm"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setEditRemark(false);
                  dispatch(patchOrderReturnById(id, { remark }));
                }}
                className="btn btn-dark px-3 btn-sm"
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <br />
      </div>
      <footer className="d-flex position-sticky b-0 flex-wrap justify-content-end align-items-center pt-3 mt-3 bg-white border-top">
        <div className="col-md-12 d-flex align-items-center mr-2 justify-content-between">
          <div className="col-8">
            <p className="mb-0">Refund Amount To Buyer</p>
            <h5 className="font-weight-bold text-danger">
              {/* {`RM ${decimalFormat(
                item?.order?.settlement?.paymentAmount || 0
              )}`} */}
              RM {decimalFormat(netContraAmount(item))}
            </h5>
          </div>
          <button
            type="button"
            className="btn  btn-outline-danger input-inside-text mr-2"
            onClick={history.goBack}
          >
            Cancel
          </button>
          {/* <button
                      type="button"
                      className="btn border btn-dark input-inside-text mr-2"
                      onClick={history.goBack}
                  >
                      Create
                  </button> */}
        </div>
      </footer>
    </React.Fragment>
  );
}

export default ViewOrderAndReceiptInfo;
