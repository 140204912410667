import React from "react";
import { ErrorMessage, useField } from "formik";

const TextField = ({ label, textarea, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="mb-4 col-12 row">
      {label ? (
        <label className="col-md-3 text-start" htmlFor={field.name}>
          {label}
        </label>
      ) : (
        <></>
      )}
      <div className={`${props.classes}`}>
      {textarea ? (
        <textarea
          className={`form-control shadow-none ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          {...props}
          autoComplete="off"
        />
      ) : (
        <input
          className={`form-control shadow-none ${
            meta.touched && meta.error && "is-invalid"
          }`}
          {...field}
          {...props}
          autoComplete="off"
        />
      )}

      <ErrorMessage component="div" name={field.name} className="error" />
      </div>
    </div>
  );
};

export default TextField;
