import React, { useState } from "react";
import Input from "../../components/formControl/input";
import SelectDropdown from "../../components/select";
import { close_dialog, showToast, startLoader } from "../../lib/global";
// import { languageList } from "../../shared/language";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const AddLanguage = (props) => {
  console.log("props", props);
  const [value, setValue] = useState(null);

  const handleSelect = (data) => {
    setValue(data);
  };

  const handleSubmit = () => {
    startLoader();
    const payload = {
      language: value.label,
      code: value.value,
    };
    props
      ?.addLanguage(payload)
      .then((res) => {
        showToast(res.data.message, "success");
        props.handleClose();
        props.onClose();
      })
      .catch((err) => showToast(err?.response?.data?.message, "error"));
  };
  return (
    <React.Fragment>
      <div className="col-12 p-0 m-0 card">
        <div className="close_icon">
          <CloseIcon
            className="close_icon_btn"
            color="primary2"
            fontSize="large"
            title="Close dialog"
            onClick={props.onClose}
          />
        </div>
        <div className="card-header d-flex justify-content-center">
          <h4>Add New Language</h4>
        </div>
        <div className="card-body">
          <form className="col-12 row">
            <label htmlFor="language" className="col-md-12">
              Select Language
            </label>
            <div className="col-md-12">
              <SelectDropdown
                // options={languageList}
                placeholder="Select Language"
                value={value}
                onChange={handleSelect}
                isClearable={true}
                isSearchable={true}
              />
            </div>
            <br />
            <br />
            <br />

            <label htmlFor="language" className="col-md-12">
              Language Code
            </label>
            <Input
              className="input__textField mx-3 col-md-6"
              type="text"
              placeholder="Ex- en"
              value={value?.value || ""}
              disabled
            />
          </form>
        </div>
        <div className="submit_btn col-md-12">
          <button
            disabled={!value}
            onClick={handleSubmit}
            className="btn btn-primary"
          >
            Submit
          </button>
        </div>
      </div>
      <style jsx="true">{`
        .card-body {
          min-height: 60vh;
        }
        .submit_btn {
          position: absolute;
          bottom: 15px;
          width: 100%;
          justify-content: center;
          display: flex;
        }
        .close_icon_btn {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      `}</style>
    </React.Fragment>
  );
};

export default AddLanguage;
