import {
  DeleteWithToken,
  GetWithToken,
  PostWithToken,
  PutWithToken,
  DeleteById,
  Get,
  GetWithTokenParams,
  GetWithTokenFile,
} from "../../../lib/request";


export const getProductOverride = (query) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/v2/merchant/product-override?item=${query.item || ""}&order=${
        query.order || ""
      }&productName=${
        encodeURIComponent(query?.productName?.trim() || "") || ""
      }&search=${
        query.search || ""
      }&statusText=${query.status || ""}&offset=${query.offset || 0}`
    );

    return result;
  };
};

export const deleteProductOverride = (id) => {
  return async function (dispatch) {
    const result = await DeleteWithToken(`/v2/merchant/product-override/${id}`);
    return result;
  };
};

export const getProductOverrideById = (id) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/v2/merchant/product-override/${id}`);
    return result;
  };
};

export const getProductByMerchant = (query) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/v2/merchant/product-override/product-master?item=${query.item || ""}&order=${query.order || ""}&productName=${query?.productName || ""}&search=${query?.search || ""}&offset=${
      query.offset || 0}&limit=${query.limit || 0}`);
    return result;
  };
};

export const createProductOverride = (data) => {
  return async function (dispatch) {
    return await PostWithToken(`/v2/merchant/product-override`, data);
  };
};