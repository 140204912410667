import {
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  stopLoader,
  showToast,
  open_dialog,
  AllowAccess,
} from "../../lib/global";
import { breadcrumbAction } from "../../redux/actions/config.action";

import "../../static/css/consumerCenterCss/consumerCenterOverview.css";
import { createProductOverride } from "../../redux/actions/merchant/productOverride.action";
import { CREATE_PRODUCT_OVERRIDE_DIALOG } from "../../lib/constant";
import SvgIcon from "../../components/Images/SvgIcon";
import Checkbox from "../../components/checkbox";


function Create() {
  const storeId = localStorage.getItem("storeId");
  AllowAccess(["MERCHANT"]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [productOverrideData, setProductOverrideData] = useState({
    includeSaleItems: null,
    merchantCode: storeId,
    products: [],
  });
  const [includeSaleItemsList, setIncludeSaleItemsList] = useState([
    { value: "isFlashDeal", label: "Flash Deals" },
    { value: "isGroupDeal", label: "Group Deals" },
    { value: "isStream", label: "Live Deals" },
    { value: "isNormal", label: "Normal Deals" },
  ]);


  useEffect(() => {
    // startLoader();
    const breadcrumb = [
      { label: "Products Override", url: "/merchant/product-override" },
      { label: "Create", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
  }, []);

  const handleAddProduct = () => {
    open_dialog(CREATE_PRODUCT_OVERRIDE_DIALOG, {
      storeId,
      onSubmit: selectProductSuccess,
    });
  };

  const selectProductSuccess = async (data) => {
    console.log("@@@@", data);
    if (data !== []) {
      let tempProductOverrideData = {
        ...productOverrideData,
      };
      let listId = tempProductOverrideData.products.map((item) => item.productId);
      await data.map(async (item) => {
        if (!(await inArray(listId, item.productId))) {
          tempProductOverrideData.products.push(item);
          listId.push(item.productId);
        }
      });
      setProductOverrideData(tempProductOverrideData);
    }
  };

  const isIncludeSaleItems = (item) => {
    let isChecked = false;
    if (productOverrideData.includeSaleItems) {
      productOverrideData.includeSaleItems.map((i) => {
        if (i.value === item) {
          isChecked = true;
        }
      });
    }
    return isChecked;
  };

  const setIncludeSaleItems = (item) => {
    let temp = productOverrideData.includeSaleItems;
    if (temp) {
      let index = temp.findIndex((i) => i.value === item.value);
      if (index > -1) {
        temp.splice(index, 1);
      } else {
        temp.push(item);
      }
    } else {
      temp = [item];
    }
    setProductOverrideData((pre) => ({ ...pre, includeSaleItems: temp }));
  };

  const inArray = async (array, id) => {
    let result = false;
    await array.map((item) => {
      if (item === id) {
        result = true;
      }
    });
    return result;
  };

  const onSubmit = async () => {
    let payload = {
      ...productOverrideData
    };

    let isValidate = validateForm(payload);
    if (!isValidate) {
      return isValidate;
    }

    dispatch(createProductOverride(payload))
      .then((res) => {
        setTimeout(() => {
          stopLoader();
          showToast("Voucher added successfully", "success");
          history.push("/merchant/product-override");
        }, 1000);
      })
      .catch((err) => {
        stopLoader();
        const message = JSON.parse(err.request.responseText).message;
        showToast("Fail Add Product Override [" + message + "]", "error");
      });
  };

  const removeAction = (index) => {
    let temp = productOverrideData.products;
    temp.splice(index, 1);
    setProductOverrideData((pre) => ({ ...pre, products: temp }));
  };

  const validateForm = (payload) => {
    if (!payload.includeSaleItems) {
      showToast("Category Name is required", "error");
      return false;
    }

    if (payload.products.length === 0) {
      showToast("Product is required", "error");
      return false;
    }

    return true
  }


  return (
    <div className="main_consumer_account_div">
      <div className="container_body container-fluid mt-2">
        <div className="card p-3">
          <div className=" border card rounded-lg my-3">
            <div className="row">
              <div className="col-12 row mt-4">
                <label className="col-3 text-left ml-2 pt-0">
                  Include On Category
                </label>
                <div className="form-group col-8">
                  <div className="row">
                    {includeSaleItemsList.map((item, index) => {
                      return (
                        <div className="col-6">
                          <Checkbox
                            formControlClass={"text-muted m-0"}
                            className={"text-dark"}
                            checked={isIncludeSaleItems(item.value)}
                            onChange={() => setIncludeSaleItems(item)}
                            label={item.label}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="col-12 row my-0">
                  <div className="form-group col-12 m-0 text-end">
                    <button
                      className="ml-2 btn btn-dark"
                      type="button"
                      onClick={() => handleAddProduct()}
                    >
                      Add Product
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    stickyHeader
                    aria-label="sticky table"
                  >
                    <TableHead className="bg-light">
                      <TableRow>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Products</div>
                            <div className="d-flex flex-column "></div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Price</div>
                            <div className="d-flex flex-column "></div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="left"
                        >
                          <div className="d-flex  ">
                            <div>Stock</div>
                          </div>
                        </TableCell>
                        <TableCell
                          className="table_action_head text-secondary"
                          component="th"
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {productOverrideData.products &&
                        productOverrideData.products.length > 0 &&
                        productOverrideData.products.map((row, indexProduct) => (
                          <TableRow
                            key={row.productId + "-" + indexProduct}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell align="left" className="td_cell">
                              <div className="d-flex flex-row align-items-center">
                                <div>{row.title}</div>
                              </div>
                            </TableCell>
                            <TableCell align="left" className="td_cell">
                              RM
                              {row.lowestPrice
                                ? row.lowestPrice.price
                                : row.price}
                            </TableCell>
                            <TableCell
                              align="left"
                              className="td_cell"
                            >
                              {row?.totalStock || "N/A"}
                            </TableCell>
                            <TableCell className="d-flex table_action_item">
                              <IconButton
                                className="pointer"
                                onClick={() => removeAction(indexProduct)}
                              >
                                <SvgIcon
                                  src="/icons/delete_icon.svg"
                                  alt="view icon"
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
          <div className="card-footer">
            <div className="row float-right">
              <button
                className="btn btn-outline-dark m-2"
                onClick={history.goBack}
              >
                Cancel
              </button>
              <button className="btn btn-outline-dark m-2" onClick={() => onSubmit()}>Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Create;
