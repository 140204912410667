import React, { useState,useEffect,useRef } from "react";
import "../../../../static/css/deleteModal.css";
import CloseIcon from "@mui/icons-material/Close";

import {
  deleteMerchant,
  getAllMerchantListAfterDelete,
} from "../../../../redux/actions/merchant-center.action";
import { useDispatch } from "react-redux";
import { showToast, startLoader, stopLoader } from "../../../../lib/global";
import Image from "../../../../components/Images/Image";
import { Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs } from "@mui/material";
import InputField from "../../../../components/formControl/input/InputField";
import { IOSSwitch } from "../../../../components/switch/IOSSwitch";
import { TabPanel } from "../../../../components/Tabs/TabPannel";
import { getAllProductList, getProductById, getProductsBymerchantId } from "../../../../redux/actions/product.action";

const VoucherCreateProductDialog = (props) => {
  const [allProductDataList, setallProductDataList] = useState();
  const [limit, setLimit] = useState(props.data.productLimit);
  const [selectedProduct,setSelectedProduct] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [querySearch, setQuerySearch] = useState({
    order:'ASC',
    status:'PUBLISHED',
    limit:20
  });
  const [search, setSearch] = useState();
  const listInnerRef = useRef();
  const [currPage, setCurrPage] = useState(0); // storing current page number
  const [prevPage, setPrevPage] = useState(-1); // storing prev page number
  const [lastList, setLastList] = useState(false); // setting a flag to know the last list
  const [countProduct,setCountProduct] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!lastList && prevPage !== currPage) {
      getProductList();
    }
  }, [currPage, lastList, prevPage, allProductDataList]);

  const getProductList = () => {
    querySearch.offset = currPage
    querySearch.limit = 9999
    dispatch(getAllProductList(querySearch))
      .then((response) => {
        if (response?.data?.data) {
          if (!response.data.data.length) {
            setLastList(true);
            return;
          }
          setPrevPage(currPage);
          const data = response.data.data.map((item) => {
            let isChecked = isCheckedSelectedProduct(item.productId)
            return {
              ...item,
              isActive: true,
              isChecked
            }
          });
          setCountProduct(data.length)
          setallProductDataList(data);
        }
      })
  }

  const onScroll = () => {
    console.log("scrolling")
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setCurrPage(currPage + 1);
      }
    }
  };

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  }

  const searchProduct = (e) => {
    let temp = querySearch
    temp.productName = search
    getProductList()
  }

  const onSubmmit = () => {
    props.onSubmit(selectedProduct)
    props.onClose()
  }

  const findIndex = (id) => {
    return allProductDataList.findIndex((item) => item.productId === id);
  }

  const onCheckProduct = async (e,productId) => {
    let temp = []
    const index = findIndex(productId)
    const checked = e.target.checked;
    let _allProductDataList = [...allProductDataList]
    let _product = _allProductDataList[index]
    if(_product){
      _product.isChecked = checked
      _allProductDataList[index] = _product
      console.log(_allProductDataList)
      setallProductDataList(_allProductDataList)
    }
    if(checked){
      temp = selectedProduct.map((item) => item)
      await allProductDataList.map((item) => {
        if(item.productId === productId){
          temp.push(item)
        }
      })
    }else{
      selectedProduct.map((item) => {
        if (item.productId !== productId) {
          temp.push(item)
        }
      })
    }
    await setSelectedProduct(temp)
  }

  const onCheckAllProduct = (e) => {
    let temp = []
    let _allProductDataList = [...allProductDataList]
    temp = []
    if(e.target.checked){
      _allProductDataList = _allProductDataList.map((item) => {
        if(item.totalStock && item.totalStock !== 0){
          item.isChecked = true
          temp.push(item)
        }
        return item
      })
    }else{
      _allProductDataList.map((item) => {
        item.isChecked = false
      })
    }
    setSelectedProduct(temp)
    setallProductDataList(_allProductDataList)
  }

  function changeTabs(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const readUploadFile = (e) => {
    e.preventDefault();
    let xlsx = require("xlsx");
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { header: ["product_id"], range: 1 });
        console.log(json);
        if (json.length > 0) {
          let temp = []
          // setallProductDataList([])
          json.map((item) => {
            dispatch(getProductById(item.product_id))
              .then((response) => {
                console.log(response.data.data)
                if (response.data.data) {
                  temp.push(response.data.data)
                }
              })
          })
          setallProductDataList(temp)
          setSelectedProduct(temp)
          setTimeout(() => {
            setTabIndex(2)
          },2000)
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  const isCheckedSelectedProduct = (productId) => {
    let temp = false
    selectedProduct.map((item) => {
      if(item.productId == productId){
        temp = true
      }
    })
    console.log(temp)
    return temp
  }

  const deleteFun = () => {
    const data = {
      status: "DELETED",
    };
    startLoader();
    const response = dispatch(deleteMerchant(data, props.id));
    response.then((data) => {
      if (data.data.status == 200) {
        const response = dispatch(getAllMerchantListAfterDelete());
        response
          .then((data) => {
            if (data && data.data && data.data.status === 200) {
              setTimeout(() => {
                stopLoader();
                showToast(data.data.message, "success");
                props.onSubmit([...data.data.data]);
                props.onClose();
              }, 1000);
            } else {
              stopLoader();
              showToast("Internal Server Error", "error");
              props.onClose();
            }
          })
          .catch((error) => {
            console.log({ error });
          });
      } else {
        stopLoader();
        showToast("Internal Server Error", "error");
      }
    });
  };
  return (
    <React.Fragment>
      <div>
        <div className="d-flex flex-row align-items-center justify-content-between px-3">
          <p className="mb-0">Add Product</p>
          <button
            onClick={props.onClose}
            className="btn fntSz24 text-secondary"
          >
            x
          </button>
        </div>
        <div className=" border card rounded-lg my-3">
          <Tabs
            value={tabIndex}
            onChange={(e, value) => setTabIndex(value)}
            variant="scrollable"
            orientation="horizontal"
            scrollButtons={false}
            visibleScrollbar={false}
            className="px-3"
            aria-label="basic tabs example"
          >
            <Tab label={`Select`} {...changeTabs(0)} />
            <Tab label={`Upload`} {...changeTabs(1)} />
            <Tab label={`Selected Uploaded`} {...changeTabs(2)} />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <div className="border-top border-bottom py-2 row">
              <InputField
                className=""
                formClass="ml-2 col-6"
                type="text"
                id="search"
                placeholder="Search"
                name="search"
                value={search}
                onChange={onChangeSearch}
                onEnter={searchProduct}
              />
              <button
                type="button"
                className="btn btn-primary btn-sm p-1 px-3 mx-2"
                onClick={searchProduct}
              >
                Search
              </button>
            </div>
            <div className="border-top border-bottom py-1">
              <div
                style={{ maxHeight: "40vh", overflow: "auto" }}
                className="fntSz14 position-relative px-2"
              >
                <table className="table border-0">
                  <thead className="position-sticky" style={{ top: "0" }}>
                  <tr className="bg-light rounded-lg text-secondary">
                    <th className="border-0">
                      <div
                        style={{ textAlign: "end" }}
                        className="align-middle border-0"
                      >
                        <input type="checkbox" onClick={(e)=>onCheckAllProduct(e)}/>
                      </div>
                    </th>
                    <th className="border-0">
                      <div className="d-flex ">
                        <div>Products</div>
                      </div>
                    </th>
                    <th className="border-0 align-content-start">
                      <div className="d-flex  ">
                        <div>Price</div>
                      </div>
                    </th>
                    <th className="border-0 align-content-start">
                      <div className="d-flex  ">
                        <div>Stock</div>
                      </div>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {allProductDataList &&
                    allProductDataList.length > 0 &&
                    allProductDataList.map((row, index) => (
                      <tr key={row.productId}>
                        <td
                          style={{ textAlign: "end" }}
                          className="align-middle border-0"
                        >
                          <input type="checkbox" disabled={row.totalStock == 0 || row.totalStock == null} onClick={(e)=>onCheckProduct(e,row.productId)} checked={row.isChecked}/>
                        </td>
                        <td className=" align-middle border-0">
                          <div className="d-flex flex-row align-items-center">
                            <Image
                              src={row.productImages[0]}
                              alt={"photo"}
                              className="flash_sale_product_image mr-1"
                            />
                            <div>{row.title}</div>
                          </div>
                        </td>
                        <td className="border-0 align-content-start">
                          RM{row.lowestPrice?row.lowestPrice.price:row.price}
                        </td>
                        <td className="border-0 align-content-start">
                          {row.totalStock?row.totalStock:0}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-between m-3">
                <div className="text-secondary fntSz12">{selectedProduct.length} product selected of {countProduct} products</div>
                <div className="">
                  <button
                    onClick={props.onClose}
                    type="button"
                    className="btn btn-outline-danger btn-sm p-1 px-3 mx-2"
                    // onClick={back}
                  >
                    Cancel
                  </button>
                  <button
                    // onClick={() => history.push("/marketing/all-promo/flash-sale")}
                    type="button"
                    className="btn btn-danger btn-sm p-1 px-3 mx-2"
                    onClick={onSubmmit}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <form>
              <label htmlFor="upload">Upload File</label>
              <input
                type="file"
                name="upload"
                id="upload"
                onChange={readUploadFile}
              />
            </form>
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>

            <div className="border-top border-bottom py-1">
              <div
                style={{ maxHeight: "40vh", overflow: "auto" }}
                className="fntSz14 position-relative px-2"
              >
                <table className="table border-0">
                  <thead className="position-sticky" style={{ top: "0" }}>
                  <tr className="bg-light rounded-lg text-secondary">
                    <th className="border-0">
                      <div
                        style={{ textAlign: "end" }}
                        className="align-middle border-0"
                      >
                        <input type="checkbox" />
                      </div>
                    </th>
                    <th className="border-0">
                      <div className="d-flex ">
                        <div>Products</div>
                      </div>
                    </th>
                    <th className="border-0 float-right">
                      <div className="d-flex  ">
                        <div>Price</div>
                      </div>
                    </th>
                    <th className="border-0">
                      <div className="d-flex  ">
                        <div>Stock</div>
                      </div>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {selectedProduct &&
                    selectedProduct.length > 0 &&
                    selectedProduct.map((row, index) => (
                      <tr key={row.productId}>
                        <td
                          style={{ textAlign: "end" }}
                          className="align-middle border-0"
                        >
                          <input type="checkbox" disabled={row.totalStock == 0 && row.stock== 0} onChange={(e)=>onCheckProduct(e,row.productId)} checked={row.isChecked}/>
                        </td>
                        <td className=" align-middle border-0">
                          <div className="d-flex flex-row align-items-center">
                            <Image
                              src={row.productImages[0]}
                              alt={"photo"}
                              className="flash_sale_product_image mr-1"
                            />
                            <div>{row.title}</div>
                          </div>
                        </td>
                        <td className="align-middle border-0 float-right">
                          RM{row.lowestPrice?row.lowestPrice.price:row.price}
                        </td>
                        <td className="align-middle border-0">{row.totalStock?row.totalStock:row.stock}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-between m-3">
                <div className="text-secondary fntSz12">{selectedProduct.length}/{limit} product selected</div>
                <div className="">
                  <button
                    onClick={props.onClose}
                    type="button"
                    className="btn btn-outline-danger btn-sm p-1 px-3 mx-2"
                    // onClick={back}
                  >
                    Cancel
                  </button>
                  <button
                    // onClick={() => history.push("/marketing/all-promo/flash-sale")}
                    type="button"
                    className="btn btn-danger btn-sm p-1 px-3 mx-2"
                    onClick={onSubmmit}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>


        <style jsx="true">{`
          .card-body {
            min-height: 50vh;
          }
          .submit_btn {
            position: absolute;
            bottom: 15px;
            width: 100%;
            justify-content: center;
            display: flex;
          }
          .close_icon_btn {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
          .flash_sale_product_image {
            width: 45px;
            height: 40px;
            border-radius: 3px;
          }
          input {
            cursor: pointer;
          }
        `}</style>
      </div>
    </React.Fragment>
  );
};

export default VoucherCreateProductDialog;
