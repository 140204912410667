import {
  GetWithToken,
  GetWithTokenFile,
  PatchWithToken,
  PostExcelWithToken,
} from "../../../lib/request";

export const getIncomeOverviewData = () => {
  return async function (dispatch) {
    const result = await GetWithToken(`/v2/merchant/finance/income`);
    return result;
  };
};

export const exportMyIncomeToExcel = (query) => {
  const { startDate, endDate } = query;
  const url = `/v2/merchant/finance/income/excel/download?startDate=${startDate}&endDate=${endDate}&reportName=${query.reportName}&settlementStatus=${query.settlementStatus}`;
  // const url = `/api/excel/download/finance/my-income/store/${id}?startDate=${startDate}&endDate=${endDate}&reportName=${query.reportName}&settlementStatus=${query.settlementStatus}`;
  return async function (dispatch) {
    const result = await GetWithTokenFile(url);
    return result;
  };
};

export const reportGenerate = (query) => {
  return async function (dispatch) {
    const result = await GetWithTokenFile(
      `/v2/merchant/finance/report/settlement/excel/download?status=${query.reportType || ""}&startDate=${
        query.startDate || ""
      }&endDate=${query.endDate || ""}&storeId=${query.storeId || ""}`
    );
    return result;
  };
};

export const reportGenerateTaxInvoice = (query) => {
  return async function (dispatch) {
    const result = await GetWithTokenFile(
      `/v2/merchant/finance/report/tax/invoice/pdf/download?startDate=${query.startDate}&endDate=${query.endDate}`
    );
    return result;
  };
};