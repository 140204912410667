import React, {useEffect, useState } from "react";
import "../../../../static/css/deleteModal.css"
import CloseIcon from "@mui/icons-material/Close";
import { deleteMerchant, getAllMerchantListAfterDelete } from "../../../../redux/actions/merchant-center.action";
import { useDispatch } from "react-redux";
import {open_dialog, parseQuery, showToast, startLoader, stopLoader } from "../../../../lib/global";
import Image from "../../../../components/Images/Image";
import { useHistory } from "react-router-dom";
import { FLASH_SALE_ADD_PRODUCT_LIST } from "../../../../lib/constant";
import {
  createFlashSaleProduct,
  getAllFlashSaleProductByMerchantId,
  getAllProductByMerchantId
} from "../../../../redux/actions/marketing.action";
import { FLASH_SALE_ADD_PRODUCT } from "../../../../lib/constant";
import { getLocalStorage } from "../../../../lib/session";

const initialData = [
    {
        createDate: "Red",
        ticketNo: "RM50.00",
        ticketStatus: "45",
        updated: "6",
        merchantId: "30",
    },
    {
        createDate: "Red",
        ticketNo: "RM50.00",
        ticketStatus: "23",
        updated: "-",
        merchantId: "30",

    },
    {
        createDate: "Red",
        ticketNo: "RM50.00",
        ticketStatus: "23",
        updated: "-",
        merchantId: "30",

    },
    {
        createDate: "Red",
        ticketNo: "RM50.00",
        ticketStatus: "23",
        updated: "-",
        merchantId: "30",

    },
    {
        createDate: "Red",
        ticketNo: "RM50.00",
        ticketStatus: "23",
        updated: "-",
        merchantId: "30",

    },

]

const FlashSaleAddProductDialog = (props) => {
    const [productInput, setProductInput] = useState([])
    const [allProductData, setAllProductData] = useState([])
    const dispatch = useDispatch();
    const history = useHistory();
    let arrayProduct = []

    const handelAddProductList = () => {
        open_dialog(FLASH_SALE_ADD_PRODUCT_LIST, {
            listProductInput:props.listProductInput.listProductInput,
            setListProductInput:props.setListProductInput
        })
    }

    useEffect(() => {
        apiData(props.merchantId.merchantId)
    }, [])

    const apiData = (merchantId) => {
        startLoader()
        dispatch(getAllProductByMerchantId(merchantId))
          .then((data) => {
              setAllProductData(
                data &&
                data.data &&
                data.data.data.map((row, index) => {
                    return {
                        productId: row?.productId || "N/A",
                        title: row?.title || "N/A",
                        productImages: row?.productImages || "N/A",
                        variationList: row?.variationList || "N/A"
                    };
                })
              );

              setTimeout(() => {
                  stopLoader();
              }, 1000);
          })
          .catch((err) => {
              setTimeout(() => {
                  stopLoader();
              }, 1000);
              console.log({ err });
          });
    }

    const handleProductInput = (e, index,productId, data) =>{
        if(e.target.checked == true){
            arrayProduct.push(data)
        }
        else{
            arrayProduct.map((row, index) => {
                if(row.productId == productId){
                    arrayProduct.splice(index, 1);
                }
            })
        }
    }

    const saveProduct = ({ productData }) => {
      let payload = {
        promoSlotId: props.promoslotid,
        merchantId: props.merchantid,
        date: props.datepromo,
        productData: productData
      }

      dispatch(createFlashSaleProduct(payload))
        .then((response) => {
          stopLoader();
        })
        .catch((error) => {
          stopLoader();
          console.error("Failed to upload product!", error);
        });
    }

    const confirmAction = () => {
      let tempArrayProduct = []
      props.allProductData.forEach(item => {
        tempArrayProduct.push({
          productId: item.product.productId,
          discountPrice: null,
          campaignStock: null,
          unitCapped: null,
          variation: item.variation
        })
      })
      arrayProduct.forEach(item => {
        if (!props.allProductData.some(existingItem => item.productId === existingItem.product.productId)) {
          tempArrayProduct.push({
            productId: item.productId,
            discountPrice: null,
            campaignStock: null,
            unitCapped: null,
            variation: item.variationList
          })
        }
      })
      saveProduct({
        productData: tempArrayProduct
      })
        // props.setListProductInput(arrayProduct)
        // handelAddProductList()
      // props.setAllProductData([
      //   ...props.allProductData,
      //   ...tempArrayProduct
      // ])


      modalClose()
    }

    const modalClose = () => {
        return props.onClose
    }

    // const deleteFun = () => {
    //     const data = {
    //         status: "DELETED",
    //
    //     }
    //     startLoader()
    //     const response = dispatch(deleteMerchant(data, props.id))
    //     response
    //         .then((data) => {
    //             if (data.data.status == 200) {
    //                 const response = dispatch(getAllMerchantListAfterDelete());
    //                 response
    //                     .then((data) => {
    //                         if (data && data.data && data.data.status === 200) {
    //                             setTimeout(() => {
    //                                 stopLoader()
    //                                 showToast(data.data.message, "success")
    //                                 props.onSubmit([...data.data.data]);
    //                                 props.onClose();
    //                             }, 1000)
    //                         } else {
    //                             stopLoader()
    //                             showToast("Internal Server Error", "error")
    //                             props.onClose();
    //                         }
    //                     })
    //                     .catch(error => {
    //                         console.log({ error })
    //                     })
    //             }
    //             else {
    //                 stopLoader()
    //                 showToast("Internal Server Error", "error")
    //             }
    //         })
    // }
    return (
        <React.Fragment>
            <div>
                <div className="d-flex flex-row align-items-center justify-content-between px-3">
                    <p className="mb-0">Add Product</p>
                    <button
                        onClick={props.onClose}
                        className="btn fntSz24 text-secondary">x</button>
                </div>
                <div className="border-top border-bottom py-1" >
                    <div className="d-flex justify-content-end px-3">
                        <input
                            type="checkbox"
                            className="m-1"
                        />
                        <label className="text-secondary">Show Available Product</label>
                    </div>
                    <div
                        style={{ maxHeight: "35vh", overflow: "auto" }}
                        className="fntSz14 position-relative px-2">
                        <table className="table border-0">
                            <thead className="position-sticky" style={{ top: "0" }}>
                                <tr className="bg-light rounded-lg text-secondary">
                                    <th className="border-0">
                                        <div style={{textAlign: "end"}} className="align-middle border-0">
                                            <input
                                                type="checkbox"
                                            />

                                        </div>
                                    </th>
                                    <th className="border-0">
                                        <div className="d-flex ">
                                            <div>Products</div>

                                        </div>
                                    </th>
                                    <th className="border-0">
                                        <div className="d-flex  ">
                                            <div>Price</div>

                                        </div>
                                    </th>
                                    <th className="border-0">
                                        <div className="d-flex  ">
                                            <div>Stock</div>

                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {allProductData &&
                                  allProductData.length > 0 &&
                                  allProductData.map((row, index) => (
                                        <tr
                                            style={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            <td style={{textAlign: "end"}} className="align-middle border-0">
                                                <input
                                                    type="checkbox"
                                                    onClick={(e) => handleProductInput(e, index, row.productId, {
                                                        productId:row.productId,
                                                        title:row.title,
                                                        productImages:row.productImages[0],
                                                        variationList:row.variationList
                                                    })}
                                                />
                                            </td>
                                            <td className=" align-middle border-0">
                                                <div className="d-flex flex-row align-items-center">
                                                    <Image
                                                        src={row.productImages[0]}
                                                        alt={'photo'}
                                                        className="flash_sale_product_image mr-1"
                                                    />
                                                    <div>{row.title}</div>
                                                </div>
                                            </td>
                                            <td className="align-middle border-0">
                                                N/A
                                            </td>
                                            <td className="align-middle border-0">
                                                N/A
                                            </td>

                                        </tr>
                                    ))}
                            </tbody>
                        </table>

                    </div>

                </div>
                <div>
                    <div className="d-flex justify-content-end m-3">
                        <button
                            onClick={props.onClose}
                            type="button"
                            className="btn btn-outline-danger p-1 px-3 mx-2"
                        // onClick={back}
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => {confirmAction(); modalClose();}}
                            type="button"
                            className="btn btn-danger p-1 px-3 mx-2"
                        // onClick={back}
                        >
                            Confirm
                        </button>


                    </div>
                </div>

                <style jsx="true">{`
                    .card-body {
                    min-height: 50vh;
                    }
                    .submit_btn {
                    position: absolute;
                    bottom: 15px;
                    width: 100%;
                    justify-content: center;
                    display: flex;
                    }
                    .close_icon_btn {
                    position: absolute;
                    right: 0;
                    cursor: pointer;
                    }
                    .flash_sale_product_image{
                    width: 45px;
                    height: 40px;
                    border-radius: 3px;
                    }
                    input{
                        cursor:pointer;
                    }
                    `}</style>

            </div>

        </React.Fragment>
    )
}

export default FlashSaleAddProductDialog;
