export const countriesList = [
  {
    name: "Afghanistan",
    label: "Afghanistan",
    value: "Afghanistan",
    code: "AF",
    capital: "Kabul",
    flag: "https://restcountries.eu/data/afg.svg",
  },
  {
    name: "Åland Islands",
    label: "Åland Islands",
    value: "Åland Islands",
    code: "AX",
    capital: "Mariehamn",
    flag: "https://restcountries.eu/data/ala.svg",
  },
  {
    name: "Albania",
    label: "Albania",
    value: "Albania",
    code: "AL",
    capital: "Tirana",
    flag: "https://restcountries.eu/data/alb.svg",
  },
  {
    name: "Algeria",
    label: "Algeria",
    value: "Algeria",
    code: "DZ",
    capital: "Algiers",
    flag: "https://restcountries.eu/data/dza.svg",
  },
  {
    name: "American Samoa",
    label: "American Samoa",
    value: "American Samoa",
    code: "AS",
    capital: "Pago Pago",
    flag: "https://restcountries.eu/data/asm.svg",
  },
  {
    name: "Andorra",
    label: "Andorra",
    value: "Andorra",
    code: "AD",
    capital: "Andorra la Vella",
    flag: "https://restcountries.eu/data/and.svg",
  },
  {
    name: "Angola",
    label: "Angola",
    value: "Angola",
    code: "AO",
    capital: "Luanda",
    flag: "https://restcountries.eu/data/ago.svg",
  },
  {
    name: "Anguilla",
    label: "Anguilla",
    value: "Anguilla",
    code: "AI",
    capital: "The Valley",
    flag: "https://restcountries.eu/data/aia.svg",
  },
  {
    name: "Antigua and Barbuda",
    label: "Antigua and Barbuda",
    value: "Antigua and Barbuda",
    code: "AG",
    capital: "Saint John's",
    flag: "https://restcountries.eu/data/atg.svg",
  },
  {
    name: "Argentina",
    label: "Argentina",
    value: "Argentina",
    code: "AR",
    capital: "Buenos Aires",
    flag: "https://restcountries.eu/data/arg.svg",
  },
  {
    name: "Armenia",
    label: "Armenia",
    value: "Armenia",
    code: "AM",
    capital: "Yerevan",
    flag: "https://restcountries.eu/data/arm.svg",
  },
  {
    name: "Aruba",
    label: "Aruba",
    value: "Aruba",
    code: "AW",
    capital: "Oranjestad",
    flag: "https://restcountries.eu/data/abw.svg",
  },
  {
    name: "Australia",
    label: "Australia",
    value: "Australia",
    code: "AU",
    capital: "Canberra",
    flag: "https://restcountries.eu/data/aus.svg",
  },
  {
    name: "Austria",
    label: "Austria",
    value: "Austria",
    code: "AT",
    capital: "Vienna",
    flag: "https://restcountries.eu/data/aut.svg",
  },
  {
    name: "Azerbaijan",
    label: "Azerbaijan",
    value: "Azerbaijan",
    code: "AZ",
    capital: "Baku",
    flag: "https://restcountries.eu/data/aze.svg",
  },
  {
    name: "Bahamas",
    label: "Bahamas",
    value: "Bahamas",
    code: "BS",
    capital: "Nassau",
    flag: "https://restcountries.eu/data/bhs.svg",
  },
  {
    name: "Bahrain",
    label: "Bahrain",
    value: "Bahrain",
    code: "BH",
    capital: "Manama",
    flag: "https://restcountries.eu/data/bhr.svg",
  },
  {
    name: "Bangladesh",
    label: "Bangladesh",
    value: "Bangladesh",
    code: "BD",
    capital: "Dhaka",
    flag: "https://restcountries.eu/data/bgd.svg",
  },
  {
    name: "Barbados",
    label: "Barbados",
    value: "Barbados",
    code: "BB",
    capital: "Bridgetown",
    flag: "https://restcountries.eu/data/brb.svg",
  },
  {
    name: "Belarus",
    label: "Belarus",
    value: "Belarus",
    code: "BY",
    capital: "Minsk",
    flag: "https://restcountries.eu/data/blr.svg",
  },
  {
    name: "Belgium",
    label: "Belgium",
    value: "Belgium",
    code: "BE",
    capital: "Brussels",
    flag: "https://restcountries.eu/data/bel.svg",
  },
  {
    name: "Belize",
    label: "Belize",
    value: "Belize",
    code: "BZ",
    capital: "Belmopan",
    flag: "https://restcountries.eu/data/blz.svg",
  },
  {
    name: "Benin",
    label: "Benin",
    value: "Benin",
    code: "BJ",
    capital: "Porto-Novo",
    flag: "https://restcountries.eu/data/ben.svg",
  },
  {
    name: "Bermuda",
    label: "Bermuda",
    value: "Bermuda",
    code: "BM",
    capital: "Hamilton",
    flag: "https://restcountries.eu/data/bmu.svg",
  },
  {
    name: "Bhutan",
    label: "Bhutan",
    value: "Bhutan",
    code: "BT",
    capital: "Thimphu",
    flag: "https://restcountries.eu/data/btn.svg",
  },
  {
    name: "Bolivia (Plurinational State of)",
    label: "Bolivia (Plurinational State of)",
    value: "Bolivia (Plurinational State of)",
    code: "BO",
    capital: "Sucre",
    flag: "https://restcountries.eu/data/bol.svg",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    label: "Bonaire, Sint Eustatius and Saba",
    value: "Bonaire, Sint Eustatius and Saba",
    code: "BQ",
    capital: "Kralendijk",
    flag: "https://restcountries.eu/data/bes.svg",
  },
  {
    name: "Bosnia and Herzegovina",
    label: "Bosnia and Herzegovina",
    value: "Bosnia and Herzegovina",
    code: "BA",
    capital: "Sarajevo",
    flag: "https://restcountries.eu/data/bih.svg",
  },
  {
    name: "Botswana",
    label: "Botswana",
    value: "Botswana",
    code: "BW",
    capital: "Gaborone",
    flag: "https://restcountries.eu/data/bwa.svg",
  },
  {
    name: "Bouvet Island",
    label: "Bouvet Island",
    value: "Bouvet Island",
    code: "BV",
    capital: "",
    flag: "https://restcountries.eu/data/bvt.svg",
  },
  {
    name: "Brazil",
    label: "Brazil",
    value: "Brazil",
    code: "BR",
    capital: "Brasília",
    flag: "https://restcountries.eu/data/bra.svg",
  },
  {
    name: "British Indian Ocean Territory",
    label: "British Indian Ocean Territory",
    value: "British Indian Ocean Territory",
    code: "IO",
    capital: "Diego Garcia",
    flag: "https://restcountries.eu/data/iot.svg",
  },
  {
    name: "United States Minor Outlying Islands",
    label: "United States Minor Outlying Islands",
    value: "United States Minor Outlying Islands",
    code: "UM",
    capital: "",
    flag: "https://restcountries.eu/data/umi.svg",
  },
  {
    name: "Virgin Islands (British)",
    label: "Virgin Islands (British)",
    value: "Virgin Islands (British)",
    code: "VG",
    capital: "Road Town",
    flag: "https://restcountries.eu/data/vgb.svg",
  },
  {
    name: "Virgin Islands (U.S.)",
    label: "Virgin Islands (U.S.)",
    value: "Virgin Islands (U.S.)",
    code: "VI",
    capital: "Charlotte Amalie",
    flag: "https://restcountries.eu/data/vir.svg",
  },
  {
    name: "Brunei Darussalam",
    label: "Brunei Darussalam",
    value: "Brunei Darussalam",
    code: "BN",
    capital: "Bandar Seri Begawan",
    flag: "https://restcountries.eu/data/brn.svg",
  },
  {
    name: "Bulgaria",
    label: "Bulgaria",
    value: "Bulgaria",
    code: "BG",
    capital: "Sofia",
    flag: "https://restcountries.eu/data/bgr.svg",
  },
  {
    name: "Burkina Faso",
    label: "Burkina Faso",
    value: "Burkina Faso",
    code: "BF",
    capital: "Ouagadougou",
    flag: "https://restcountries.eu/data/bfa.svg",
  },
  {
    name: "Burundi",
    label: "Burundi",
    value: "Burundi",
    code: "BI",
    capital: "Bujumbura",
    flag: "https://restcountries.eu/data/bdi.svg",
  },
  {
    name: "Cambodia",
    label: "Cambodia",
    value: "Cambodia",
    code: "KH",
    capital: "Phnom Penh",
    flag: "https://restcountries.eu/data/khm.svg",
  },
  {
    name: "Cameroon",
    label: "Cameroon",
    value: "Cameroon",
    code: "CM",
    capital: "Yaoundé",
    flag: "https://restcountries.eu/data/cmr.svg",
  },
  {
    name: "Canada",
    label: "Canada",
    value: "Canada",
    code: "CA",
    capital: "Ottawa",
    flag: "https://restcountries.eu/data/can.svg",
  },
  {
    name: "Cabo Verde",
    label: "Cabo Verde",
    value: "Cabo Verde",
    code: "CV",
    capital: "Praia",
    flag: "https://restcountries.eu/data/cpv.svg",
  },
  {
    name: "Cayman Islands",
    label: "Cayman Islands",
    value: "Cayman Islands",
    code: "KY",
    capital: "George Town",
    demonym: "Caymanian",
    flag: "https://restcountries.eu/data/cym.svg",
  },
  {
    name: "Central African Republic",
    label: "Central African Republic",
    value: "Central African Republic",
    code: "CF",
    capital: "Bangui",
    flag: "https://restcountries.eu/data/caf.svg",
  },
  {
    name: "Chad",
    label: "Chad",
    value: "Chad",
    code: "TD",
    capital: "N'Djamena",
    flag: "https://restcountries.eu/data/tcd.svg",
  },
  {
    name: "Chile",
    label: "Chile",
    value: "Chile",
    code: "CL",
    capital: "Santiago",
    flag: "https://restcountries.eu/data/chl.svg",
  },
  {
    name: "China",
    label: "China",
    value: "China",
    code: "CN",
    capital: "Beijing",
    flag: "https://restcountries.eu/data/chn.svg",
  },
  {
    name: "Christmas Island",
    label: "Christmas Island",
    value: "Christmas Island",
    code: "CX",
    capital: "Flying Fish Cove",
    flag: "https://restcountries.eu/data/cxr.svg",
  },
  {
    name: "Cocos (Keeling) Islands",
    label: "Cocos (Keeling) Islands",
    value: "Cocos (Keeling) Islands",
    code: "CC",
    capital: "West Island",
    flag: "https://restcountries.eu/data/cck.svg",
  },
  {
    name: "Colombia",
    label: "Colombia",
    value: "Colombia",
    code: "CO",
    capital: "Bogotá",
    flag: "https://restcountries.eu/data/col.svg",
  },
  {
    name: "Comoros",
    label: "Comoros",
    value: "Comoros",
    code: "KM",
    capital: "Moroni",
    flag: "https://restcountries.eu/data/com.svg",
  },
  {
    name: "Congo",
    label: "Congo",
    value: "Congo",
    code: "CG",
    capital: "Brazzaville",
    flag: "https://restcountries.eu/data/cog.svg",
  },
  {
    name: "Congo (Democratic Republic of the)",
    label: "Congo (Democratic Republic of the)",
    value: "Congo (Democratic Republic of the)",
    code: "CD",
    capital: "Kinshasa",
    flag: "https://restcountries.eu/data/cod.svg",
  },
  {
    name: "Cook Islands",
    label: "Cook Islands",
    value: "Cook Islands",
    code: "CK",
    capital: "Avarua",
    flag: "https://restcountries.eu/data/cok.svg",
  },
  {
    name: "Costa Rica",
    label: "Costa Rica",
    value: "Costa Rica",
    code: "CR",
    capital: "San José",
    flag: "https://restcountries.eu/data/cri.svg",
  },
  {
    name: "Croatia",
    label: "Croatia",
    value: "Croatia",
    code: "HR",
    capital: "Zagreb",
    flag: "https://restcountries.eu/data/hrv.svg",
  },
  {
    name: "Cuba",
    label: "Cuba",
    value: "Cuba",
    code: "CU",
    capital: "Havana",
    flag: "https://restcountries.eu/data/cub.svg",
  },
  {
    name: "Curaçao",
    label: "Curaçao",
    value: "Curaçao",
    code: "CW",
    capital: "Willemstad",
    flag: "https://restcountries.eu/data/cuw.svg",
  },
  {
    name: "Cyprus",
    label: "Cyprus",
    value: "Cyprus",
    code: "CY",
    capital: "Nicosia",
    flag: "https://restcountries.eu/data/cyp.svg",
  },
  {
    name: "Czech Republic",
    label: "Czech Republic",
    value: "Czech Republic",
    code: "CZ",
    capital: "Prague",
    flag: "https://restcountries.eu/data/cze.svg",
  },
  {
    name: "Denmark",
    label: "Denmark",
    value: "Denmark",
    code: "DK",
    capital: "Copenhagen",
    flag: "https://restcountries.eu/data/dnk.svg",
  },
  {
    name: "Djibouti",
    label: "Djibouti",
    value: "Djibouti",
    code: "DJ",
    capital: "Djibouti",
    flag: "https://restcountries.eu/data/dji.svg",
  },
  {
    name: "Dominica",
    label: "Dominica",
    value: "Dominica",
    code: "DM",
    capital: "Roseau",
    flag: "https://restcountries.eu/data/dma.svg",
  },
  {
    name: "Dominican Republic",
    label: "Dominican Republic",
    value: "Dominican Republic",
    code: "DO",
    capital: "Santo Domingo",
    flag: "https://restcountries.eu/data/dom.svg",
  },
  {
    name: "Ecuador",
    label: "Ecuador",
    value: "Ecuador",
    code: "EC",
    capital: "Quito",
    flag: "https://restcountries.eu/data/ecu.svg",
  },
  {
    name: "Egypt",
    label: "Egypt",
    value: "Egypt",
    code: "EG",
    capital: "Cairo",
    flag: "https://restcountries.eu/data/egy.svg",
  },
  {
    name: "El Salvador",
    label: "El Salvador",
    value: "El Salvador",
    code: "SV",
    capital: "San Salvador",
    flag: "https://restcountries.eu/data/slv.svg",
  },
  {
    name: "Equatorial Guinea",
    label: "Equatorial Guinea",
    value: "Equatorial Guinea",
    code: "GQ",
    capital: "Malabo",
    flag: "https://restcountries.eu/data/gnq.svg",
  },
  {
    name: "Eritrea",
    label: "Eritrea",
    value: "Eritrea",
    code: "ER",
    capital: "Asmara",
    flag: "https://restcountries.eu/data/eri.svg",
  },
  {
    name: "Estonia",
    label: "Estonia",
    value: "Estonia",
    code: "EE",
    capital: "Tallinn",
    flag: "https://restcountries.eu/data/est.svg",
  },
  {
    name: "Ethiopia",
    label: "Ethiopia",
    value: "Ethiopia",
    code: "ET",
    capital: "Addis Ababa",
    flag: "https://restcountries.eu/data/eth.svg",
  },
  {
    name: "Falkland Islands (Malvinas)",
    label: "Falkland Islands (Malvinas)",
    value: "Falkland Islands (Malvinas)",
    code: "FK",
    capital: "Stanley",
    flag: "https://restcountries.eu/data/flk.svg",
  },
  {
    name: "Faroe Islands",
    label: "Faroe Islands",
    value: "Faroe Islands",
    code: "FO",
    capital: "Tórshavn",
    flag: "https://restcountries.eu/data/fro.svg",
  },
  {
    name: "Fiji",
    label: "Fiji",
    value: "Fiji",
    code: "FJ",
    capital: "Suva",
    flag: "https://restcountries.eu/data/fji.svg",
  },
  {
    name: "Finland",
    label: "Finland",
    value: "Finland",
    code: "FI",
    capital: "Helsinki",
    flag: "https://restcountries.eu/data/fin.svg",
  },
  {
    name: "France",
    label: "France",
    value: "France",
    code: "FR",
    capital: "Paris",
    demonym: "French",
    flag: "https://restcountries.eu/data/fra.svg",
  },
  {
    name: "French Guiana",
    label: "French Guiana",
    value: "French Guiana",
    code: "GF",
    capital: "Cayenne",
    flag: "https://restcountries.eu/data/guf.svg",
  },
  {
    name: "French Polynesia",
    label: "French Polynesia",
    value: "French Polynesia",
    code: "PF",
    capital: "Papeetē",
    flag: "https://restcountries.eu/data/pyf.svg",
  },
  {
    name: "French Southern Territories",
    label: "French Southern Territories",
    value: "French Southern Territories",
    code: "TF",
    capital: "Port-aux-Français",
    flag: "https://restcountries.eu/data/atf.svg",
  },
  {
    name: "Gabon",
    label: "Gabon",
    value: "Gabon",
    code: "GA",
    capital: "Libreville",
    flag: "https://restcountries.eu/data/gab.svg",
  },
  {
    name: "Gambia",
    label: "Gambia",
    value: "Gambia",
    code: "GM",
    capital: "Banjul",
    flag: "https://restcountries.eu/data/gmb.svg",
  },
  {
    name: "Georgia",
    label: "Georgia",
    value: "Georgia",
    code: "GE",
    capital: "Tbilisi",
    flag: "https://restcountries.eu/data/geo.svg",
  },
  {
    name: "Germany",
    label: "Germany",
    value: "Germany",
    code: "DE",
    capital: "Berlin",
    flag: "https://restcountries.eu/data/deu.svg",
  },
  {
    name: "Ghana",
    label: "Ghana",
    value: "Ghana",
    code: "GH",
    capital: "Accra",
    flag: "https://restcountries.eu/data/gha.svg",
  },
  {
    name: "Gibraltar",
    label: "Gibraltar",
    value: "Gibraltar",
    code: "GI",
    capital: "Gibraltar",
    flag: "https://restcountries.eu/data/gib.svg",
  },
  {
    name: "Greece",
    label: "Greece",
    value: "Greece",
    code: "GR",
    capital: "Athens",
    flag: "https://restcountries.eu/data/grc.svg",
  },
  {
    name: "Greenland",
    label: "Greenland",
    value: "Greenland",
    code: "GL",
    capital: "Nuuk",
    flag: "https://restcountries.eu/data/grl.svg",
  },
  {
    name: "Grenada",
    label: "Grenada",
    value: "Grenada",
    code: "GD",
    capital: "St. George's",
    flag: "https://restcountries.eu/data/grd.svg",
  },
  {
    name: "Guadeloupe",
    label: "Guadeloupe",
    value: "Guadeloupe",
    code: "GP",
    capital: "Basse-Terre",
    flag: "https://restcountries.eu/data/glp.svg",
  },
  {
    name: "Guam",
    label: "Guam",
    value: "Guam",
    code: "GU",
    capital: "Hagåtña",
    flag: "https://restcountries.eu/data/gum.svg",
  },
  {
    name: "Guatemala",
    label: "Guatemala",
    value: "Guatemala",
    code: "GT",
    capital: "Guatemala City",
    flag: "https://restcountries.eu/data/gtm.svg",
  },
  {
    name: "Guernsey",
    label: "Guernsey",
    value: "Guernsey",
    code: "GG",
    capital: "St. Peter Port",
    flag: "https://restcountries.eu/data/ggy.svg",
  },
  {
    name: "Guinea",
    label: "Guinea",
    value: "Guinea",
    code: "GN",
    capital: "Conakry",
    flag: "https://restcountries.eu/data/gin.svg",
  },
  {
    name: "Guinea-Bissau",
    label: "Guinea-Bissau",
    value: "Guinea-Bissau",
    code: "GW",
    capital: "Bissau",
    flag: "https://restcountries.eu/data/gnb.svg",
  },
  {
    name: "Guyana",
    label: "Guyana",
    value: "Guyana",
    code: "GY",
    capital: "Georgetown",
    flag: "https://restcountries.eu/data/guy.svg",
  },
  {
    name: "Haiti",
    label: "Haiti",
    value: "Haiti",
    code: "HT",
    capital: "Port-au-Prince",
    flag: "https://restcountries.eu/data/hti.svg",
  },
  {
    name: "Heard Island and McDonald Islands",
    label: "Heard Island and McDonald Islands",
    value: "Heard Island and McDonald Islands",
    code: "HM",
    capital: "",
    flag: "https://restcountries.eu/data/hmd.svg",
  },
  {
    name: "Holy See",
    label: "Holy See",
    value: "Holy See",
    code: "VA",
    capital: "Rome",
    flag: "https://restcountries.eu/data/vat.svg",
  },
  {
    name: "Honduras",
    label: "Honduras",
    value: "Honduras",
    code: "HN",
    capital: "Tegucigalpa",
    flag: "https://restcountries.eu/data/hnd.svg",
  },
  {
    name: "Hong Kong",
    label: "Hong Kong",
    value: "Hong Kong",
    code: "HK",
    capital: "City of Victoria",
    flag: "https://restcountries.eu/data/hkg.svg",
  },
  {
    name: "Hungary",
    label: "Hungary",
    value: "Hungary",
    code: "HU",
    capital: "Budapest",
    flag: "https://restcountries.eu/data/hun.svg",
  },
  {
    name: "Iceland",
    label: "Iceland",
    value: "Iceland",
    code: "IS",
    capital: "Reykjavík",
    flag: "https://restcountries.eu/data/isl.svg",
  },
  {
    name: "India",
    label: "India",
    value: "India",
    code: "IN",
    capital: "New Delhi",
    flag: "https://restcountries.eu/data/ind.svg",
  },
  {
    name: "Indonesia",
    label: "Indonesia",
    value: "Indonesia",
    code: "ID",
    capital: "Jakarta",
    flag: "https://restcountries.eu/data/idn.svg",
  },
  {
    name: "Côte d'Ivoire",
    label: "Côte d'Ivoire",
    value: "Côte d'Ivoire",
    code: "CI",
    capital: "Yamoussoukro",
    flag: "https://restcountries.eu/data/civ.svg",
  },
  {
    name: "Iran (Islamic Republic of)",
    label: "Iran (Islamic Republic of)",
    value: "Iran (Islamic Republic of)",
    code: "IR",
    capital: "Tehran",
    flag: "https://restcountries.eu/data/irn.svg",
  },
  {
    name: "Iraq",
    label: "Iraq",
    value: "Iraq",
    code: "IQ",
    capital: "Baghdad",
    flag: "https://restcountries.eu/data/irq.svg",
  },
  {
    name: "Ireland",
    label: "Ireland",
    value: "Ireland",
    code: "IE",
    capital: "Dublin",
    flag: "https://restcountries.eu/data/irl.svg",
  },
  {
    name: "Isle of Man",
    label: "Isle of Man",
    value: "Isle of Man",
    code: "IM",
    capital: "Douglas",
    flag: "https://restcountries.eu/data/imn.svg",
  },
  {
    name: "Israel",
    label: "Israel",
    value: "Israel",
    code: "IL",
    capital: "Jerusalem",
    flag: "https://restcountries.eu/data/isr.svg",
  },
  {
    name: "Italy",
    label: "Italy",
    value: "Italy",
    code: "IT",
    capital: "Rome",
    flag: "https://restcountries.eu/data/ita.svg",
  },
  {
    name: "Jamaica",
    label: "Jamaica",
    value: "Jamaica",
    code: "JM",
    capital: "Kingston",
    flag: "https://restcountries.eu/data/jam.svg",
  },
  {
    name: "Japan",
    label: "Japan",
    value: "Japan",
    code: "JP",
    capital: "Tokyo",
    flag: "https://restcountries.eu/data/jpn.svg",
  },
  {
    name: "Jersey",
    label: "Jersey",
    value: "Jersey",
    code: "JE",
    capital: "Saint Helier",
    flag: "https://restcountries.eu/data/jey.svg",
  },
  {
    name: "Jordan",
    label: "Jordan",
    value: "Jordan",
    code: "JO",
    capital: "Amman",
    flag: "https://restcountries.eu/data/jor.svg",
  },
  {
    name: "Kazakhstan",
    label: "Kazakhstan",
    value: "Kazakhstan",
    code: "KZ",
    capital: "Astana",
    flag: "https://restcountries.eu/data/kaz.svg",
  },
  {
    name: "Kenya",
    label: "Kenya",
    value: "Kenya",
    code: "KE",
    capital: "Nairobi",
    flag: "https://restcountries.eu/data/ken.svg",
  },
  {
    name: "Kiribati",
    label: "Kiribati",
    value: "Kiribati",
    code: "KI",
    capital: "South Tarawa",
    flag: "https://restcountries.eu/data/kir.svg",
  },
  {
    name: "Kuwait",
    label: "Kuwait",
    value: "Kuwait",
    code: "KW",
    capital: "Kuwait City",
    flag: "https://restcountries.eu/data/kwt.svg",
  },
  {
    name: "Kyrgyzstan",
    label: "Kyrgyzstan",
    value: "Kyrgyzstan",
    code: "KG",
    capital: "Bishkek",
    flag: "https://restcountries.eu/data/kgz.svg",
  },
  {
    name: "Lao People's Democratic Republic",
    label: "Lao People's Democratic Republic",
    value: "Lao People's Democratic Republic",
    code: "LA",
    capital: "Vientiane",
    flag: "https://restcountries.eu/data/lao.svg",
  },
  {
    name: "Latvia",
    label: "Latvia",
    value: "Latvia",
    code: "LV",
    capital: "Riga",
    flag: "https://restcountries.eu/data/lva.svg",
  },
  {
    name: "Lebanon",
    label: "Lebanon",
    value: "Lebanon",
    code: "LB",
    capital: "Beirut",
    flag: "https://restcountries.eu/data/lbn.svg",
  },
  {
    name: "Lesotho",
    label: "Lesotho",
    value: "Lesotho",
    code: "LS",
    capital: "Maseru",
    flag: "https://restcountries.eu/data/lso.svg",
  },
  {
    name: "Liberia",
    label: "Liberia",
    value: "Liberia",
    code: "LR",
    capital: "Monrovia",
    flag: "https://restcountries.eu/data/lbr.svg",
  },
  {
    name: "Libya",
    label: "Libya",
    value: "Libya",
    code: "LY",
    capital: "Tripoli",
    flag: "https://restcountries.eu/data/lby.svg",
  },
  {
    name: "Liechtenstein",
    label: "Liechtenstein",
    value: "Liechtenstein",
    code: "LI",
    capital: "Vaduz",
    flag: "https://restcountries.eu/data/lie.svg",
  },
  {
    name: "Lithuania",
    label: "Lithuania",
    value: "Lithuania",
    code: "LT",
    capital: "Vilnius",
    flag: "https://restcountries.eu/data/ltu.svg",
  },
  {
    name: "Luxembourg",
    label: "Luxembourg",
    value: "Luxembourg",
    code: "LU",
    capital: "Luxembourg",
    flag: "https://restcountries.eu/data/lux.svg",
  },
  {
    name: "Macao",
    label: "Macao",
    value: "Macao",
    code: "MO",
    capital: "",
    flag: "https://restcountries.eu/data/mac.svg",
  },
  {
    name: "Macedonia (the former Yugoslav Republic of)",
    label: "Macedonia (the former Yugoslav Republic of)",
    value: "Macedonia (the former Yugoslav Republic of)",
    code: "MK",
    capital: "Skopje",
    flag: "https://restcountries.eu/data/mkd.svg",
  },
  {
    name: "Madagascar",
    label: "Madagascar",
    value: "Madagascar",
    code: "MG",
    capital: "Antananarivo",
    flag: "https://restcountries.eu/data/mdg.svg",
  },
  {
    name: "Malawi",
    label: "Malawi",
    value: "Malawi",
    code: "MW",
    capital: "Lilongwe",
    flag: "https://restcountries.eu/data/mwi.svg",
  },
  {
    name: "Malaysia",
    label: "Malaysia",
    value: "Malaysia",
    code: "MY",
    capital: "Kuala Lumpur",
    flag: "https://restcountries.eu/data/mys.svg",
  },
  {
    name: "Maldives",
    label: "Maldives",
    value: "Maldives",
    code: "MV",
    capital: "Malé",
    flag: "https://restcountries.eu/data/mdv.svg",
  },
  {
    name: "Mali",
    label: "Mali",
    value: "Mali",
    code: "ML",
    capital: "Bamako",
    flag: "https://restcountries.eu/data/mli.svg",
  },
  {
    name: "Malta",
    label: "Malta",
    value: "Malta",
    code: "MT",
    capital: "Valletta",
    flag: "https://restcountries.eu/data/mlt.svg",
  },
  {
    name: "Marshall Islands",
    label: "Marshall Islands",
    value: "Marshall Islands",
    code: "MH",
    capital: "Majuro",
    flag: "https://restcountries.eu/data/mhl.svg",
  },
  {
    name: "Martinique",
    label: "Martinique",
    value: "Martinique",
    code: "MQ",
    capital: "Fort-de-France",
    flag: "https://restcountries.eu/data/mtq.svg",
  },
  {
    name: "Mauritania",
    label: "Mauritania",
    value: "Mauritania",
    code: "MR",
    capital: "Nouakchott",
    flag: "https://restcountries.eu/data/mrt.svg",
  },
  {
    name: "Mauritius",
    label: "Mauritius",
    value: "Mauritius",
    code: "MU",
    capital: "Port Louis",
    flag: "https://restcountries.eu/data/mus.svg",
  },
  {
    name: "Mayotte",
    label: "Mayotte",
    value: "Mayotte",
    code: "YT",
    capital: "Mamoudzou",
    flag: "https://restcountries.eu/data/myt.svg",
  },
  {
    name: "Mexico",
    label: "Mexico",
    value: "Mexico",
    code: "MX",
    capital: "Mexico City",
    flag: "https://restcountries.eu/data/mex.svg",
  },
  {
    name: "Micronesia (Federated States of)",
    label: "Micronesia (Federated States of)",
    value: "Micronesia (Federated States of)",
    code: "FM",
    capital: "Palikir",
    flag: "https://restcountries.eu/data/fsm.svg",
  },
  {
    name: "Moldova (Republic of)",
    label: "Moldova (Republic of)",
    value: "Moldova (Republic of)",
    code: "MD",
    capital: "Chișinău",
    flag: "https://restcountries.eu/data/mda.svg",
  },
  {
    name: "Monaco",
    label: "Monaco",
    value: "Monaco",
    code: "MC",
    capital: "Monaco",
    flag: "https://restcountries.eu/data/mco.svg",
  },
  {
    name: "Mongolia",
    label: "Mongolia",
    value: "Mongolia",
    code: "MN",
    capital: "Ulan Bator",
    flag: "https://restcountries.eu/data/mng.svg",
  },
  {
    name: "Montenegro",
    label: "Montenegro",
    value: "Montenegro",
    code: "ME",
    capital: "Podgorica",
    flag: "https://restcountries.eu/data/mne.svg",
  },
  {
    name: "Montserrat",
    label: "Montserrat",
    value: "Montserrat",
    code: "MS",
    capital: "Plymouth",
    flag: "https://restcountries.eu/data/msr.svg",
  },
  {
    name: "Morocco",
    label: "Morocco",
    value: "Morocco",
    code: "MA",
    capital: "Rabat",
    flag: "https://restcountries.eu/data/mar.svg",
  },
  {
    name: "Mozambique",
    label: "Mozambique",
    value: "Mozambique",
    code: "MZ",
    capital: "Maputo",
    flag: "https://restcountries.eu/data/moz.svg",
  },
  {
    name: "Myanmar",
    label: "Myanmar",
    value: "Myanmar",
    code: "MM",
    capital: "Naypyidaw",
    flag: "https://restcountries.eu/data/mmr.svg",
  },
  {
    name: "Namibia",
    label: "Namibia",
    value: "Namibia",
    code: "NA",
    capital: "Windhoek",
    flag: "https://restcountries.eu/data/nam.svg",
  },
  {
    name: "Nauru",
    label: "Nauru",
    value: "Nauru",
    code: "NR",
    capital: "Yaren",
    flag: "https://restcountries.eu/data/nru.svg",
  },
  {
    name: "Nepal",
    label: "Nepal",
    value: "Nepal",
    code: "NP",
    capital: "Kathmandu",
    flag: "https://restcountries.eu/data/npl.svg",
  },
  {
    name: "Netherlands",
    label: "Netherlands",
    value: "Netherlands",
    code: "NL",
    capital: "Amsterdam",
    flag: "https://restcountries.eu/data/nld.svg",
  },
  {
    name: "New Caledonia",
    label: "New Caledonia",
    value: "New Caledonia",
    code: "NC",
    capital: "Nouméa",
    flag: "https://restcountries.eu/data/ncl.svg",
  },
  {
    name: "New Zealand",
    label: "New Zealand",
    value: "New Zealand",
    code: "NZ",
    capital: "Wellington",
    flag: "https://restcountries.eu/data/nzl.svg",
  },
  {
    name: "Nicaragua",
    label: "Nicaragua",
    value: "Nicaragua",
    code: "NI",
    capital: "Managua",
    flag: "https://restcountries.eu/data/nic.svg",
  },
  {
    name: "Niger",
    label: "Niger",
    value: "Niger",
    code: "NE",
    capital: "Niamey",
    flag: "https://restcountries.eu/data/ner.svg",
  },
  {
    name: "Nigeria",
    label: "Nigeria",
    value: "Nigeria",
    code: "NG",
    capital: "Abuja",
    flag: "https://restcountries.eu/data/nga.svg",
  },
  {
    name: "Niue",
    label: "Niue",
    value: "Niue",
    code: "NU",
    capital: "Alofi",
    flag: "https://restcountries.eu/data/niu.svg",
  },
  {
    name: "Norfolk Island",
    label: "Norfolk Island",
    value: "Norfolk Island",
    code: "NF",
    capital: "Kingston",
    flag: "https://restcountries.eu/data/nfk.svg",
  },
  {
    name: "Korea (Democratic People's Republic of)",
    label: "Korea (Democratic People's Republic of)",
    value: "Korea (Democratic People's Republic of)",
    code: "KP",
    capital: "Pyongyang",
    flag: "https://restcountries.eu/data/prk.svg",
  },
  {
    name: "Northern Mariana Islands",
    label: "Northern Mariana Islands",
    value: "Northern Mariana Islands",
    code: "MP",
    capital: "Saipan",
    flag: "https://restcountries.eu/data/mnp.svg",
  },
  {
    name: "Norway",
    label: "Norway",
    value: "Norway",
    code: "NO",
    capital: "Oslo",
    flag: "https://restcountries.eu/data/nor.svg",
  },
  {
    name: "Oman",
    label: "Oman",
    value: "Oman",
    code: "OM",
    capital: "Muscat",
    flag: "https://restcountries.eu/data/omn.svg",
  },
  {
    name: "Pakistan",
    label: "Pakistan",
    value: "Pakistan",
    code: "PK",
    capital: "Islamabad",
    flag: "https://restcountries.eu/data/pak.svg",
  },
  {
    name: "Palau",
    label: "Palau",
    value: "Palau",
    code: "PW",
    capital: "Ngerulmud",
    flag: "https://restcountries.eu/data/plw.svg",
  },
  {
    name: "Palestine, State of",
    label: "Palestine, State of",
    value: "Palestine, State of",
    code: "PS",
    capital: "Ramallah",
    flag: "https://restcountries.eu/data/pse.svg",
  },
  {
    name: "Panama",
    label: "Panama",
    value: "Panama",
    code: "PA",
    capital: "Panama City",
    flag: "https://restcountries.eu/data/pan.svg",
  },
  {
    name: "Papua New Guinea",
    label: "Papua New Guinea",
    value: "Papua New Guinea",
    code: "PG",
    capital: "Port Moresby",
    flag: "https://restcountries.eu/data/png.svg",
  },
  {
    name: "Paraguay",
    label: "Paraguay",
    value: "Paraguay",
    code: "PY",
    capital: "Asunción",
    flag: "https://restcountries.eu/data/pry.svg",
  },
  {
    name: "Peru",
    label: "Peru",
    value: "Peru",
    code: "PE",
    capital: "Lima",
    flag: "https://restcountries.eu/data/per.svg",
  },
  {
    name: "Philippines",
    label: "Philippines",
    value: "Philippines",
    code: "PH",
    capital: "Manila",
    flag: "https://restcountries.eu/data/phl.svg",
  },
  {
    name: "Pitcairn",
    label: "Pitcairn",
    value: "Pitcairn",
    code: "PN",
    capital: "Adamstown",
    flag: "https://restcountries.eu/data/pcn.svg",
  },
  {
    name: "Poland",
    label: "Poland",
    value: "Poland",
    code: "PL",
    capital: "Warsaw",
    flag: "https://restcountries.eu/data/pol.svg",
  },
  {
    name: "Portugal",
    label: "Portugal",
    value: "Portugal",
    code: "PT",
    capital: "Lisbon",
    flag: "https://restcountries.eu/data/prt.svg",
  },
  {
    name: "Puerto Rico",
    label: "Puerto Rico",
    value: "Puerto Rico",
    code: "PR",
    capital: "San Juan",
    flag: "https://restcountries.eu/data/pri.svg",
  },
  {
    name: "Qatar",
    label: "Qatar",
    value: "Qatar",
    code: "QA",
    capital: "Doha",
    flag: "https://restcountries.eu/data/qat.svg",
  },
  {
    name: "Republic of Kosovo",
    label: "Republic of Kosovo",
    value: "Republic of Kosovo",
    code: "XK",
    capital: "Pristina",
    flag: "https://restcountries.eu/data/kos.svg",
  },
  {
    name: "Réunion",
    label: "Réunion",
    value: "Réunion",
    code: "RE",
    capital: "Saint-Denis",
    flag: "https://restcountries.eu/data/reu.svg",
  },
  {
    name: "Romania",
    label: "Romania",
    value: "Romania",
    code: "RO",
    capital: "Bucharest",
    flag: "https://restcountries.eu/data/rou.svg",
  },
  {
    name: "Russian Federation",
    label: "Russian Federation",
    value: "Russian Federation",
    code: "RU",
    capital: "Moscow",
    flag: "https://restcountries.eu/data/rus.svg",
  },
  {
    name: "Rwanda",
    label: "Rwanda",
    value: "Rwanda",
    code: "RW",
    capital: "Kigali",
    flag: "https://restcountries.eu/data/rwa.svg",
  },
  {
    name: "Saint Barthélemy",
    label: "Saint Barthélemy",
    value: "Saint Barthélemy",
    code: "BL",
    capital: "Gustavia",
    flag: "https://restcountries.eu/data/blm.svg",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    label: "Saint Helena, Ascension and Tristan da Cunha",
    value: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SH",
    capital: "Jamestown",
    flag: "https://restcountries.eu/data/shn.svg",
  },
  {
    name: "Saint Kitts and Nevis",
    label: "Saint Kitts and Nevis",
    value: "Saint Kitts and Nevis",
    code: "KN",
    capital: "Basseterre",
    flag: "https://restcountries.eu/data/kna.svg",
  },
  {
    name: "Saint Lucia",
    label: "Saint Lucia",
    value: "Saint Lucia",
    code: "LC",
    capital: "Castries",
    flag: "https://restcountries.eu/data/lca.svg",
  },
  {
    name: "Saint Martin (French part)",
    label: "Saint Martin (French part)",
    value: "Saint Martin (French part)",
    code: "MF",
    capital: "Marigot",
    flag: "https://restcountries.eu/data/maf.svg",
  },
  {
    name: "Saint Pierre and Miquelon",
    label: "Saint Pierre and Miquelon",
    value: "Saint Pierre and Miquelon",
    code: "PM",
    capital: "Saint-Pierre",
    flag: "https://restcountries.eu/data/spm.svg",
  },
  {
    name: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
    value: "Saint Vincent and the Grenadines",
    code: "VC",
    capital: "Kingstown",
    flag: "https://restcountries.eu/data/vct.svg",
  },
  {
    name: "Samoa",
    label: "Samoa",
    value: "Samoa",
    code: "WS",
    capital: "Apia",
    flag: "https://restcountries.eu/data/wsm.svg",
  },
  {
    name: "San Marino",
    label: "San Marino",
    value: "San Marino",
    code: "SM",
    capital: "City of San Marino",
    flag: "https://restcountries.eu/data/smr.svg",
  },
  {
    name: "Sao Tome and Principe",
    label: "Sao Tome and Principe",
    value: "Sao Tome and Principe",
    code: "ST",
    capital: "São Tomé",
    flag: "https://restcountries.eu/data/stp.svg",
  },
  {
    name: "Saudi Arabia",
    label: "Saudi Arabia",
    value: "Saudi Arabia",
    code: "SA",
    capital: "Riyadh",
    flag: "https://restcountries.eu/data/sau.svg",
  },
  {
    name: "Senegal",
    label: "Senegal",
    value: "Senegal",
    code: "SN",
    capital: "Dakar",
    flag: "https://restcountries.eu/data/sen.svg",
  },
  {
    name: "Serbia",
    label: "Serbia",
    value: "Serbia",
    code: "RS",
    capital: "Belgrade",
    flag: "https://restcountries.eu/data/srb.svg",
  },
  {
    name: "Seychelles",
    label: "Seychelles",
    value: "Seychelles",
    code: "SC",
    capital: "Victoria",
    flag: "https://restcountries.eu/data/syc.svg",
  },
  {
    name: "Sierra Leone",
    label: "Sierra Leone",
    value: "Sierra Leone",
    code: "SL",
    capital: "Freetown",
    flag: "https://restcountries.eu/data/sle.svg",
  },
  {
    name: "Singapore",
    label: "Singapore",
    value: "Singapore",
    code: "SG",
    capital: "Singapore",
    flag: "https://restcountries.eu/data/sgp.svg",
  },
  {
    name: "Sint Maarten (Dutch part)",
    label: "Sint Maarten (Dutch part)",
    value: "Sint Maarten (Dutch part)",
    code: "SX",
    capital: "Philipsburg",
    flag: "https://restcountries.eu/data/sxm.svg",
  },
  {
    name: "Slovakia",
    label: "Slovakia",
    value: "Slovakia",
    code: "SK",
    capital: "Bratislava",
    flag: "https://restcountries.eu/data/svk.svg",
  },
  {
    name: "Slovenia",
    label: "Slovenia",
    value: "Slovenia",
    code: "SI",
    capital: "Ljubljana",
    flag: "https://restcountries.eu/data/svn.svg",
  },
  {
    name: "Solomon Islands",
    label: "Solomon Islands",
    value: "Solomon Islands",
    code: "SB",
    capital: "Honiara",
    flag: "https://restcountries.eu/data/slb.svg",
  },
  {
    name: "Somalia",
    label: "Somalia",
    value: "Somalia",
    code: "SO",
    capital: "Mogadishu",
    flag: "https://restcountries.eu/data/som.svg",
  },
  {
    name: "South Africa",
    label: "South Africa",
    value: "South Africa",
    code: "ZA",
    capital: "Pretoria",
    flag: "https://restcountries.eu/data/zaf.svg",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    label: "South Georgia and the South Sandwich Islands",
    value: "South Georgia and the South Sandwich Islands",
    code: "GS",
    capital: "King Edward Point",
    flag: "https://restcountries.eu/data/sgs.svg",
  },
  {
    name: "Korea (Republic of)",
    label: "Korea (Republic of)",
    value: "Korea (Republic of)",
    code: "KR",
    capital: "Seoul",
    flag: "https://restcountries.eu/data/kor.svg",
  },
  {
    name: "South Sudan",
    label: "South Sudan",
    value: "South Sudan",
    code: "SS",
    capital: "Juba",
    flag: "https://restcountries.eu/data/ssd.svg",
  },
  {
    name: "Spain",
    label: "Spain",
    value: "Spain",
    code: "ES",
    capital: "Madrid",
    flag: "https://restcountries.eu/data/esp.svg",
  },
  {
    name: "Sri Lanka",
    label: "Sri Lanka",
    value: "Sri Lanka",
    code: "LK",
    capital: "Colombo",
    flag: "https://restcountries.eu/data/lka.svg",
  },
  {
    name: "Sudan",
    label: "Sudan",
    value: "Sudan",
    code: "SD",
    capital: "Khartoum",
    flag: "https://restcountries.eu/data/sdn.svg",
  },
  {
    name: "Suriname",
    label: "Suriname",
    value: "Suriname",
    code: "SR",
    capital: "Paramaribo",
    flag: "https://restcountries.eu/data/sur.svg",
  },
  {
    name: "Svalbard and Jan Mayen",
    label: "Svalbard and Jan Mayen",
    value: "Svalbard and Jan Mayen",
    code: "SJ",
    capital: "Longyearbyen",
    flag: "https://restcountries.eu/data/sjm.svg",
  },
  {
    name: "Swaziland",
    label: "Swaziland",
    value: "Swaziland",
    code: "SZ",
    capital: "Lobamba",
    flag: "https://restcountries.eu/data/swz.svg",
  },
  {
    name: "Sweden",
    label: "Sweden",
    value: "Sweden",
    code: "SE",
    capital: "Stockholm",
    flag: "https://restcountries.eu/data/swe.svg",
  },
  {
    name: "Switzerland",
    label: "Switzerland",
    value: "Switzerland",
    code: "CH",
    capital: "Bern",
    flag: "https://restcountries.eu/data/che.svg",
  },
  {
    name: "Syrian Arab Republic",
    label: "Syrian Arab Republic",
    value: "Syrian Arab Republic",
    code: "SY",
    capital: "Damascus",
    flag: "https://restcountries.eu/data/syr.svg",
  },
  {
    name: "Taiwan",
    label: "Taiwan",
    value: "Taiwan",
    code: "TW",
    capital: "Taipei",
    flag: "https://restcountries.eu/data/twn.svg",
  },
  {
    name: "Tajikistan",
    label: "Tajikistan",
    value: "Tajikistan",
    code: "TJ",
    capital: "Dushanbe",
    flag: "https://restcountries.eu/data/tjk.svg",
  },
  {
    name: "Tanzania, United Republic of",
    label: "Tanzania, United Republic of",
    value: "Tanzania, United Republic of",
    code: "TZ",
    capital: "Dodoma",
    flag: "https://restcountries.eu/data/tza.svg",
  },
  {
    name: "Thailand",
    label: "Thailand",
    value: "Thailand",
    code: "TH",
    capital: "Bangkok",
    flag: "https://restcountries.eu/data/tha.svg",
  },
  {
    name: "Timor-Leste",
    label: "Timor-Leste",
    value: "Timor-Leste",
    code: "TL",
    capital: "Dili",
    flag: "https://restcountries.eu/data/tls.svg",
  },
  {
    name: "Togo",
    label: "Togo",
    value: "Togo",
    code: "TG",
    capital: "Lomé",
    flag: "https://restcountries.eu/data/tgo.svg",
  },
  {
    name: "Tokelau",
    label: "Tokelau",
    value: "Tokelau",
    code: "TK",
    capital: "Fakaofo",
    flag: "https://restcountries.eu/data/tkl.svg",
  },
  {
    name: "Tonga",
    label: "Tonga",
    value: "Tonga",
    code: "TO",
    capital: "Nuku'alofa",
    flag: "https://restcountries.eu/data/ton.svg",
  },
  {
    name: "Trinidad and Tobago",
    label: "Trinidad and Tobago",
    value: "Trinidad and Tobago",
    code: "TT",
    capital: "Port of Spain",
    flag: "https://restcountries.eu/data/tto.svg",
  },
  {
    name: "Tunisia",
    label: "Tunisia",
    value: "Tunisia",
    code: "TN",
    capital: "Tunis",
    flag: "https://restcountries.eu/data/tun.svg",
  },
  {
    name: "Turkey",
    label: "Turkey",
    value: "Turkey",
    code: "TR",
    capital: "Ankara",
    flag: "https://restcountries.eu/data/tur.svg",
  },
  {
    name: "Turkmenistan",
    label: "Turkmenistan",
    value: "Turkmenistan",
    code: "TM",
    capital: "Ashgabat",
    flag: "https://restcountries.eu/data/tkm.svg",
  },
  {
    name: "Turks and Caicos Islands",
    label: "Turks and Caicos Islands",
    value: "Turks and Caicos Islands",
    code: "TC",
    capital: "Cockburn Town",
    flag: "https://restcountries.eu/data/tca.svg",
  },
  {
    name: "Tuvalu",
    label: "Tuvalu",
    value: "Tuvalu",
    code: "TV",
    capital: "Funafuti",
    flag: "https://restcountries.eu/data/tuv.svg",
  },
  {
    name: "Uganda",
    label: "Uganda",
    value: "Uganda",
    code: "UG",
    capital: "Kampala",
    flag: "https://restcountries.eu/data/uga.svg",
  },
  {
    name: "Ukraine",
    label: "Ukraine",
    value: "Ukraine",
    code: "UA",
    capital: "Kiev",
    flag: "https://restcountries.eu/data/ukr.svg",
  },
  {
    name: "United Arab Emirates",
    label: "United Arab Emirates",
    value: "United Arab Emirates",
    code: "AE",
    capital: "Abu Dhabi",
    flag: "https://restcountries.eu/data/are.svg",
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    label: "United Kingdom of Great Britain and Northern Ireland",
    value: "United Kingdom of Great Britain and Northern Ireland",
    code: "GB",
    capital: "London",
    flag: "https://restcountries.eu/data/gbr.svg",
  },
  {
    name: "United States of America",
    label: "United States of America",
    value: "United States of America",
    code: "US",
    capital: "Washington",
    flag: "https://restcountries.eu/data/usa.svg",
  },
  {
    name: "Uruguay",
    label: "Uruguay",
    value: "Uruguay",
    code: "UY",
    capital: "Montevideo",
    flag: "https://restcountries.eu/data/ury.svg",
  },
  {
    name: "Uzbekistan",
    label: "Uzbekistan",
    value: "Uzbekistan",
    code: "UZ",
    capital: "Tashkent",
    flag: "https://restcountries.eu/data/uzb.svg",
  },
  {
    name: "Vanuatu",
    label: "Vanuatu",
    value: "Vanuatu",
    code: "VU",
    capital: "Port Vila",
    flag: "https://restcountries.eu/data/vut.svg",
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    label: "Venezuela (Bolivarian Republic of)",
    value: "Venezuela (Bolivarian Republic of)",
    code: "VE",
    capital: "Caracas",
    flag: "https://restcountries.eu/data/ven.svg",
  },
  {
    name: "Viet Nam",
    label: "Viet Nam",
    value: "Viet Nam",
    code: "VN",
    capital: "Hanoi",
    flag: "https://restcountries.eu/data/vnm.svg",
  },
  {
    name: "Wallis and Futuna",
    label: "Wallis and Futuna",
    value: "Wallis and Futuna",
    code: "WF",
    capital: "Mata-Utu",
    flag: "https://restcountries.eu/data/wlf.svg",
  },
  {
    name: "Western Sahara",
    label: "Western Sahara",
    value: "Western Sahara",
    code: "EH",
    capital: "El Aaiún",
    flag: "https://restcountries.eu/data/esh.svg",
  },
  {
    name: "Yemen",
    label: "Yemen",
    value: "Yemen",
    code: "YE",
    capital: "Sana'a",
    flag: "https://restcountries.eu/data/yem.svg",
  },
  {
    name: "Zambia",
    label: "Zambia",
    value: "Zambia",
    code: "ZM",
    capital: "Lusaka",
    flag: "https://restcountries.eu/data/zmb.svg",
  },
  {
    name: "Zimbabwe",
    label: "Zimbabwe",
    value: "Zimbabwe",
    code: "ZW",
    capital: "Harare",
    flag: "https://restcountries.eu/data/zwe.svg",
  },
];
