import {
  IconButton, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead,
  TablePagination, TableRow
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "../../static/css/orderCss/allOrder.css"
import { useDispatch } from "react-redux";
import { getAllOrderOverrideTp } from "../../redux/actions/merchant/order.action";
import { AllowAccess, startLoader, stopLoader } from "../../lib/global";
import InputField from "../../components/formControl/input/InputField";
import { useParams } from "react-router-dom";
import { getLocalStorage } from "../../lib/session";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PagenationDropdown from "../../components/DropDown/pagenationDropdown";
import "../../static/css/orderCss/allOrder.css";
import DateRangePickerComp from "../../components/datepicker/MUIDateRange";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const initialSearch = {
  orderId: "",
  tpId: "",
  startDate: "",
  endDate: "",
}

const initOrderRangeDate = {
  startDate: "",
  endDate: "",
};

const OverrideReport = () => {
  AllowAccess(["MERCHANT"]);
  const isOverrideTp = getLocalStorage("isOverrideTp");
  if (!isOverrideTp || isOverrideTp == "" || isOverrideTp == 0 || isOverrideTp == false) {
    window.location.href = "/403";
  }

  const [tpId, setTpId] = useState('');
  const [orderId, setOrderId] = useState('');
  const [total, setTotal] = useState([])
  const classes = "btn border mx-1"
  const dispatch = useDispatch()
  const [search, setSearch] = useState({ initialSearch })
  const param = useParams()
  const storeId = param.mid ? param.mid : getLocalStorage("storeId");
  const [overrideData, setOverrideData] = useState([]);
  const [reset, setReset] = useState(false);
  const [orderDateRange, setOrderDateRange] = useState(initOrderRangeDate);
  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);
  const [totalItem, setTotalItem] = useState();


  useEffect(() => {
    startLoader();
    let filteringData = { ...search };
    apiData(filteringData);
  }, [offset, pageLimit, search]);

  const apiData = (filteringData) => {
    const response = dispatch(getAllOrderOverrideTp(filteringData));
    response.then((data) => {
      stopLoader();
      setOverrideData(data && data.data && data.data.data.map((row, index) => {
        return {
          category: row.category || "N/A",
          storeId: row.storeId || "N/A",
          merchantName: row.merchantName || "N/A",
          productName: row.productName || "N/A",
          tpId: row.tpId || "N/A",
          overrideDate: row.overrideDate || "N/A",
          overrideByName: row.overrideByName || "N/A",
          overrideById: row.overrideById || "N/A",
          overrideMethod: row.overrideMethod || "N/A",
          orderId: row.orderId || "N/A",
          consumerName: row.consumerName || "N/A",
          transactionId: row.transactionId || "N/A",
          transactionDate: row.transactionDate || "N/A",
        };
      })
      );
      setTotalItem(0);
    })
      .catch((error) => {
        stopLoader();
        console.log(error);
      });
  }


  const handlePageChange = (event, value) => {
    setOffset(value - 1);
    setPageNo(value);
  };
  const handleDateChange = (data) => {
    setOrderDateRange(data);
  };
  const searchData = () => {
    setSearch({
      ...search,
      ...orderDateRange,
      tpId,
      orderId,
    });
  };
  const resetSearch = () => {
    setOrderId("");
    setTpId("");
    setSearch({
      tpId: "",
      orderId: ""
    });
  };

  const download = () => {
    let filteringData = { ...search };
    const response = dispatch(getAllOrderOverrideTp(filteringData));
    response
      .then((data) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        if (data && data.data && data.data.status === 200) {
          setTimeout(() => {
            stopLoader();
            exportToCSV(data && data.data && data.data.data, "OverrideTp");
          }, 1000);
        } else {
          setTimeout(() => {
            stopLoader();
          }, 1000);
        }
      })
      .catch((error) => console.log("----------error----", error));
    setTimeout(() => {
      stopLoader();
    }, 1000);
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-11 row flex-wrap">
          <div className="min-width-200 col-md-3">
            <label>Order ID</label>
            <InputField type="text"
              className="form-control"
              placeholder="Input"
              id="orderId"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-3">
            <label>Team Purchase ID</label>
            <InputField type="text"
              className="form-control"
              placeholder="Input"
              id="tpId"
              value={tpId}
              onChange={(e) => setTpId(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-3">
            <label>Date Range</label>
            <DateRangePickerComp
              reset={reset}
              setReset={setReset}
              handleDateChange={handleDateChange}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn input-inside-text mr-1 btn-white border px-4"
            onClick={() => resetSearch()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn input-inside-text mx-1 btn-secondary px-3"
            onClick={() => searchData()}
          >
            Search
          </button>
        </div>
      </div>
      <br />
      <br />

      <div className="m-4 card p-4">
        <div className="d-flex justify-content-between">
          <div>
            <span> Override Report Listing</span>
          </div>
          <div className="d-flex">
            <button
              type="button"
              onClick={download}
              className="btn add-product-botton py-1 mr-2 btn-success"
            >
              <span className="text-nowrap">
                <small className="text-nowrap">Export</small>
              </span>
            </button>
          </div>
        </div>
        <br />
        <TableContainer>
          <Table
            sx={{ minWidth: 650 }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Category</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("category", "asc")}

                      // onClick={() => getApiData({ item: "category", order: "ASC" })}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("category", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>

                <TableCell className="th_cell" component="th" align="left">
                  <div className="d-flex  ">
                    <div>Merchant ID</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        // onClick={() => setSearchData({ ...searchData, item: "brandName", order: "ASC" })}
                        onClick={() => handleSorting("storeId", "asc")}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("storeId", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>

                <TableCell className="th_cell" component="th" align="left">
                  <div className="d-flex  ">
                    <div>Merchant Name</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("merchantName", "asc")}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("merchantName", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>


                <TableCell className="th_cell" component="th" align="left">
                  <div className="d-flex  ">
                    <div>Product Name</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("productName", "asc")}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("productName", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>

                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>TP ID</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("tpId", "asc", true)}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("tpId", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>

                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Order ID</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() =>
                          handleSorting("orderId", "asc")
                        }
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() =>
                          handleSorting("orderId", "desc")
                        }
                      />
                    </div>
                  </div>
                </TableCell>

                <TableCell className="th_cell" component="th" align="left">
                  <div className="d-flex  ">
                    <div> Consumer Name</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("consumerName", "asc")}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("redemptionOutlet", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>

                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Transaction Id</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("transactionId", "asc")}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("transactionId", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Transaction Date</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("transactionDate", "asc")}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("transactionDate", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Override Date</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("overrideDate", "asc")}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("overrideDate", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Override By Name</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("overrideDate", "asc")}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("overrideDate", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Override By ID</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("overrideDate", "asc")}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("overrideDate", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell className="th_cell" component="th" align="center">
                  <div className="d-flex  ">
                    <div>Override Method</div>
                    <div className="d-flex flex-column ">
                      <ExpandLessIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "0px 3px",
                        }}
                        onClick={() => handleSorting("overrideMethod", "asc")}
                      />
                      <ExpandMoreIcon
                        style={{
                          width: "15px",
                          height: "15px",
                          margin: "-5px 3px",
                        }}
                        onClick={() => handleSorting("overrideMethod", "desc")}
                      />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!overrideData?.length && (
                <TableRow>
                  <TableCell align="center" className="td_cell">
                    <div className="d-flex justify-content-center">
                      <h3 className="text-muted m-auto">No data found!</h3>
                    </div>
                  </TableCell>
                </TableRow>
              )}

              {overrideData.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >

                  <TableCell align="left" className="td_cell capitalize">
                    {item?.category}
                  </TableCell>
                  <TableCell align="left" className="td_cell capitalize">
                    {item?.storeId}
                  </TableCell>
                  <TableCell align="left" className="td_cell capitalize">
                    {item?.merchantName}
                  </TableCell>
                  <TableCell align="left" className="td_cell capitalize">
                    {item?.productName}
                  </TableCell>
                  <TableCell align="left" className="td_cell capitalize">
                    {item?.tpId}
                  </TableCell>
                  <TableCell align="left" className="td_cell capitalize">
                    {item?.orderId}
                  </TableCell>
                  <TableCell align="left" className="td_cell capitalize">
                    {item?.consumerName}
                  </TableCell>
                  <TableCell align="left" className="td_cell capitalize">
                    {item?.transactionId}
                  </TableCell>
                  <TableCell align="left" className="td_cell capitalize">
                    {item?.transactionDate}
                  </TableCell>
                  <TableCell align="left" className="td_cell capitalize">
                    {item?.overrideDate}
                  </TableCell>
                  <TableCell align="left" className="td_cell capitalize">
                    {item?.overrideByName}
                  </TableCell>
                  <TableCell align="left" className="td_cell capitalize">
                    {item?.overrideById}
                  </TableCell>
                  <TableCell align="left" className="td_cell capitalize">
                    {item?.overrideMethod}
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <PagenationDropdown setPageQuant={setPageLimit} />
        <div className="float-right mb-1">
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil((totalItem || 0) / pageLimit)}
              shape="rounded"
              size="small"
              page={pageNo}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <br />

    </React.Fragment>

  );
}

export default OverrideReport;
