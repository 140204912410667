import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Image from "../../../components/Images/Image";
import { generateRoomWithoutSeparator } from "random-word-generator-npm";
import InputField from "../../../components/formControl/input/InputField";
import uuid from "react-uuid";
import { checkExistUser } from "../../../redux/actions/merchant-center.action";
import { useDispatch } from "react-redux";
import { startLoader, stopLoader, showToast } from "../../../lib/global";
import { EmailSharp } from "@mui/icons-material";
const AddAuthoried = (props) => {
  const dispatch = useDispatch();
  const allEmails = props.authorizedPerson.map((item) => item.email);
  const [password] = React.useState(generateRoomWithoutSeparator());
  const [username, setUsername] = useState(null);
  const [passportNumber, setPassportNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  // const [errors,setError]=useState([])

  const handleSubmit = () => {
    if (phone.length < 11 || phone.length > 12) {
      showToast("Phone number should be of 11 or 12 digit long.", "error");
      return;
    }
    console.log(allEmails);

    if (allEmails.includes(email)) {
      // console.log("test1");
      showToast("email already exist", "error");
    } else {
      const response = dispatch(
        checkExistUser({
          username,
          passportNumber,
          email,
          phone,
        })
      );
      startLoader();
      response
        .then((data) => {
          if (data && data.data && data.data.status === 200) {
            const data1 = data.data;
            const payload = {
              username,
              passportNumber,
              email,
              phone,
              loginId: data1.loginId,
              password: data1.password,
              userId: data1.userId,
            };
            setTimeout(() => {
              stopLoader();
              props.onSubmit(payload);
              props.onClose();
              // showToast(data.data.message, 'success')
            }, 1000);
            stopLoader();
          } else {
            setTimeout(() => {
              stopLoader();
              showToast(data.data.message, "error");
            }, 1000);
            // stopLoader()
            // showToast(data.data.message)
          }
        })
        .catch((err) => {
          stopLoader();
          showToast("you enter some invalid data. pls.. check.", "error");
        });
    }
  };
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };
  return (
    <React.Fragment>
      <div className="col-12 p-0 m-0 card">
        <div className="close_icon">
          <CloseIcon
            className="close_icon_btn m-2"
            color="primary2"
            fontSize="large"
            title="Close dialog"
            onClick={props.onClose}
          />
        </div>
        <div className="card-header d-flex justify-content-center">
          <h4>Add Authorized Person</h4>
        </div>
        <div
          style={{ height: "75vh" }}
          className="card-body add-authoried-card-body"
        >
          <form className="col-12 row justify-content-center">
            <div className="col-12 mb-2">
              <div
                style={{ background: "#dbd8d3" }}
                className="col-12 mb-2  py-3 rounded d-flex align-items-center justify-content-center "
              >
                <Image
                  style={{ width: "21px" }}
                  src="/icons/roundExcla.png"
                  alt="upload"
                />
                <div className="text-dark-25" style={{ marginLeft: "10px" }}>
                  You will be getting login credential after clicking generate.
                </div>
              </div>
              <label className="col-12 m-0 p-0">Employee Name</label>
              <InputField
                className="w-100"
                type="text"
                placeholder="Employee Name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="col-12 my-2">
              <label className="col-12 m-0 p-0">NRIC/Passport No.</label>
              <InputField
                className="w-100"
                type="text"
                placeholder="NRIC/Passport No"
                value={passportNumber}
                onChange={(e) => setPassportNumber(e.target.value)}
              />
            </div>

            <div className="col-12 my-2">
              <label className="col-12 m-0 p-0">Email Address</label>

              <InputField
                className="w-100"
                type="text"
                placeholder=" Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-12 my-2">
              <label className="col-12 m-0 p-0">Phone Number</label>
              <InputField
                className="w-100"
                type="text"
                onKeyPress={(e) => handleKeyPress(e)}
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </form>
          <div className="d-flex justify-content-end col-md-12 px-5">
            <button
              type="button"
              className="btn btn-outline-dark mx-2"
              onClick={props.onClose}
            >
              Cancel
            </button>

            <button
              className="btn btn-dark"
              disabled={!username || !passportNumber || !email || !phone}
              onClick={handleSubmit}
            >
              Generate
            </button>
          </div>
        </div>
        {/* <div className="d-flex justify-content-end col-md-12">
          <button
            type="button"
            className="btn btn-outline-dark mx-2"
          // onClick={back}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-dark"
            disabled={!username || !passportNumber || !email || !phone}
            onClick={handleSubmit} className="btn btn-dark"
          >
            Generate
          </button>
        </div> */}
        {/* <div className="submit_btn col-md-12">
          <button
            disabled={!username || !passportNumber || !email || !phone}
            onClick={handleSubmit} className="btn btn-primary">
            Submit
          </button>
        </div> */}
      </div>
      <style jsx="true">{`
        .card-body {
          min-height: 60vh;
        }
        .submit_btn {
          position: absolute;
          bottom: 15px;
          width: 100%;
          justify-content: center;
          display: flex;
        }
        .close_icon_btn {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      `}</style>
    </React.Fragment>
  );
};

export default AddAuthoried;
