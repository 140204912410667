

import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts';

const MerketingOverview = () => {
    const [productOverview, setProductOverview] = useState({

        series: [{
            name: 'Revenue',
            data: [0, 300, 800, 400, 200, 900, 960]
        }],
        options: {
            chart: {
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'datetime',
                // categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]

            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy HH:mm'
                },
            },
        },
    });
    const [filter, setFilter] = useState(null)
    const [filter2, setFilter2] = useState(null)
    const [sort, setSort] = useState(1)

    const [productGrowth, setProductGrowth] = useState({
        series: [{
            name: 'Net Profit',
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
        }, {
            name: 'Revenue',
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
        }, {
            name: 'Free Cash Flow',
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
        }],
        options: {
            chart: {
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
            },
            yaxis: {
                title: {
                    text: '$ (thousands)'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$ " + val + " thousands"
                    }
                }
            }
        },
    })

    const [totalOrder, setTotalOrder] = useState({

        series: [911, 321, 7182],
        options: {
            chart: {
                type: 'donut',
            },
            labels: ["Browser", "Fb Browser", "App"],
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                showAlways: true,
                                show: true
                            }
                        }
                    }
                }
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },


    })

    return (
        <div className="product_overview">
            <div className='card m-3'>
                <div className="graph_header d-flex p-3">
                    <h3 className="title ">OVERVIEW</h3>
                </div>
                <div className="d-flex justify-content-between p-3">
                    <div className="filter_left d-flex">
                        {/* <Select  ></Select> */}

                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Revenue</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={filter}
                                    label="Revenue"
                                    onChange={(e) => setFilter(e.target.value)}
                                >
                                    <MenuItem value={1}>Option 1</MenuItem>
                                    <MenuItem value={2}>Option 2</MenuItem>
                                    <MenuItem value={3}>Option 3</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <div className="filter_right">
                        <Box sx={{ minWidth: 120 }}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Sort</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={sort}
                                    label="Sort"
                                    onChange={(e) => setSort(e.target.value)}
                                >
                                    <MenuItem value={1}>Today</MenuItem>
                                    <MenuItem value={2}>Weekly</MenuItem>
                                    <MenuItem value={3}>Monthly</MenuItem>
                                    <MenuItem value={3}>Yearly</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                </div>
                <ReactApexChart options={productOverview.options} series={productOverview.series} type="area" height={350} />
            </div>
            <br />
            <br />
       

            <br />
            <br />
        </div>
    )
}

export default MerketingOverview
