import { Divider } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import {
  getFlashDealsSetting,
  updateFlashDealSetting,
} from "../../../redux/actions/marketing.action"
import { AllowAccess, showToast, stopLoader } from "../../../lib/global";
import ablity from "../../../helpers/abilityGuard";

const FlashSaleSetting = () => {
    const [promoPeriod, setPromoPeriod] = useState()
    const [merchantParticipation, setMerchantParticipation] = useState()
    const [teamPurchase, setTeamPurchase] = useState()
    const [merchantLimit, setMerchantLimit] = useState()
    const [productLimit, setProductLimit] = useState()
    const [consumerLimit, setConsumerLimit] = useState()
    const [merchantLimitQuota, setMerchantLimitQuota] = useState()
    const [productLimitQuota, setProductLimitQuota] = useState()
    const [consumerLimitQuota, setConsumerLimitQuota] = useState()
    const [slot, setSlot] = useState([])
    const history = useHistory()
    const dispatch = useDispatch()
    let slotArray = []

    AllowAccess(["SUPERADMIN"]);

    useEffect(() => {
        const breadcrumb = [
            { label: "All Promo", url: "/marketing/all-promo" },
            { label: "Flash Deals Setting", url: null }
        ];

        dispatch(breadcrumbAction(breadcrumb));
        dispatch(getFlashDealsSetting())
          .then((data) => {
              setPromoPeriod(data.data.data[0].promoPeriod);
              setMerchantParticipation(data.data.data[0].merchantParticipation);
              setTeamPurchase(data.data.data[0].teamPurchase);
              setMerchantLimit(data.data.data[0].merchantLimit);
              setProductLimit(data.data.data[0].productLimit);
              setConsumerLimit(data.data.data[0].consumerLimit);
              setMerchantLimitQuota(data.data.data[0].merchantLimitQuota);
              setProductLimitQuota(data.data.data[0].productLimitQuota);
              setConsumerLimitQuota(data.data.data[0].consumerLimitQuota);
              setSlot(data.data.data[0].promoslots);
          })
          .catch((err) => {
              console.log({ err });
          });
    }, []);

    const handleSlot = async (e, indexSlot, type) => {
        slotArray =  slot.map((row, index) => {
            if(index === indexSlot){
                if(type === 'start'){
                    row.startTime = e.target.value
                }
                else if(type === 'end'){
                    row.endTime = e.target.value
                }
            }
            return row
        })

        setSlot(slotArray)
    }
    const handleInput = async (e, type) => {
        if(type === 'promoPeriod'){
            e.target.value == 'false' ? setPromoPeriod(true) : setPromoPeriod(false)
        }
        else if(type === 'merchantParticipation'){
            e.target.value == 'false' ? setMerchantParticipation(true) : setMerchantParticipation(false)
        }
        else if(type === 'teamPurchase'){
            e.target.value == 'false' ? setTeamPurchase(true) : setTeamPurchase(false)
        }
        else if (type === 'merchantLimitFalse'){
            setMerchantLimit(false)
            setMerchantLimitQuota(0)
        }
        else if (type === 'productLimitFalse'){
            setProductLimit(false)
            setProductLimitQuota(0)
        }
        else if (type === 'consumerLimitFalse'){
            setConsumerLimit(false)
            setConsumerLimitQuota(0)
        }
    }

  const onSubmit = async () => {
      let payload = {
        promoPeriod,
        merchantParticipation,
        teamPurchase,
        merchantLimit,
        productLimit,
        consumerLimit,
        merchantLimitQuota,
        productLimitQuota,
        consumerLimitQuota,
        promoSlots: slot
      }

    dispatch(updateFlashDealSetting(payload))
      .then((res) => {
        setTimeout(() => {
          stopLoader();
          showToast("Flash Deals Setting updated successfully", "success");
          history.push("/marketing/all-promo/flash-sale-setting");
        }, 1000);
      })
      .catch((err) => {
        console.log({ err });
        history.push("/marketing/all-promo/flash-sale-setting");
      });
  }



    return (
        <React.Fragment>
            <div className=" d-flex flex-column justify-content-between container_body vh-100">
                <div className="container-fluid mb-5">
                    <div className="card p-3">
                        <h6>FLASH DEALS SETTING</h6>
                        <br />
                        <div className="row mb-2">
                            <label className="col-3 d-flex justify-content-end align-items-center">Promo Period</label>
                            <div className="col-9">
                                <input
                                    type="radio"
                                    className="mr-2"
                                    checked={promoPeriod}
                                    value={promoPeriod}
                                    onClick={(e) => handleInput(e, "promoPeriod")}
                                />
                                <label htmlFor="">Daily</label>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-3 d-flex justify-content-end">Promo Slots Per Day</label>
                            <div className="col-9">
                                <div>(4 slots - 4hrs per slot)</div>
                                <div>
                                    {slot.map((row, index) => (
                                          <div className="row my-2 align-items-center">
                                              <div className="col-2 pr-0">{row.title}</div>
                                              <div className="col-10 pl-0 d-flex flex-row align-items-center">
                                                  <input type="hidden"  value={row.id}/>
                                                  <div className=" mx-1">
                                                      <input type="time"  value={row.startTime} onChange={(e) => handleSlot(e, index, 'start')}/>
                                                  </div>
                                                  <div className="col-2">
                                                      <Divider />
                                                  </div>
                                                  <div className="col-2 p-0">
                                                      <input type="time" value={row.endTime} onChange={(e) => handleSlot(e, index, 'end')}/>
                                                  </div>
                                              </div>
                                          </div>
                                      )
                                    )}

                                </div>
                            </div>

                        </div>
                        <div className="row mb-2">
                            <label className="col-3 d-flex justify-content-end">Merchant Participation</label>
                            <div className="col-9 d-flex flex-row">
                                <div className="pr-4">
                                    <input
                                        type="radio"
                                        className="btn"
                                        checked={merchantParticipation}
                                        value={merchantParticipation}
                                        onClick={(e) => handleInput(e, "merchantParticipation")}
                                    />
                                    <label className="px-2">All</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-3 d-flex justify-content-end">Team Purchase</label>
                            <div className="col-9 d-flex flex-row">
                                <div className="pr-4">
                                    <input
                                        type="radio"
                                        className="btn"
                                        checked={teamPurchase}
                                        value={teamPurchase}
                                        onClick={(e) => handleInput(e, "teamPurchase")}
                                    />
                                    <label className="px-2">Allow</label>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-3 d-flex justify-content-end">Merchant Limit</label>
                            <div className="col-9 ">
                                <div className="d-flex flex-row">
                                    <div className="pr-4">
                                        <input
                                            // onClick={() => setMerchantLimit(false)}
                                            onClick={(e) => handleInput(e, "merchantLimitFalse")}
                                            type="radio"
                                            name="merchant_limit"
                                            className="btn"
                                            checked={merchantLimit == false ? true : ""}
                                            value={merchantLimit == false ? merchantLimit : ""}

                                        />
                                        <label className="px-2">No</label>
                                    </div>
                                    <div className="pr-4">
                                        <input
                                            onClick={() => setMerchantLimit(true)}
                                            type="radio"
                                            name="merchant_limit"
                                            className="btn"
                                            checked={merchantLimit == true ? merchantLimit : ""}
                                            value={merchantLimit == true ? merchantLimit : ""}
                                        />
                                        <label className="px-2">Yes</label>
                                        <div className={`${merchantLimit == true ? "d-block" : "d-none"}`}>
                                            <input
                                                type="text"
                                                className="col-6  form-control"
                                                value={merchantLimitQuota != null ? merchantLimitQuota : 0}
                                                onChange={(e) => setMerchantLimitQuota(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-3 d-flex justify-content-end">Product Limit Per Merchant</label>
                            <div className="col-9 ">
                                <div className="d-flex flex-row">
                                    <div className="pr-4">
                                        <input
                                            // onClick={() => setProductLimit(false)}
                                            onClick={(e) => handleInput(e, "productLimitFalse")}
                                            type="radio"
                                            name="product_limit"
                                            className="btn"
                                            checked={productLimit == false ? true : ""}
                                            value={productLimit == false ? productLimit : ""}
                                        />
                                        <label className="px-2">No</label>
                                    </div>
                                    <div className="pr-4">
                                        <input
                                            onClick={() => setProductLimit(true)}
                                            type="radio"
                                            name="product_limit"
                                            className="btn"
                                            checked={productLimit == true ? productLimit : ""}
                                            value={productLimit == true ? productLimit : ""}
                                        />
                                        <label className="px-2">Yes</label>
                                        <div className={`${productLimit == true ? "d-block" : "d-none"}`}>
                                            <input
                                                type="text"
                                                className="col-6  form-control"
                                                value={productLimitQuota != null ? productLimitQuota : 0}
                                                onChange={(e) => setProductLimitQuota(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <label className="col-3 d-flex justify-content-end">Consumer Purchase Limit</label>
                            <div className="col-9 ">
                                <div className="d-flex flex-row">
                                    <div className="pr-4">
                                        <input
                                            // onClick={() => setConsumerLimit(false)}
                                            onClick={(e) => handleInput(e, "consumerLimitFalse")}
                                            type="radio"
                                            name="consumer_purchase_limit"
                                            className="btn"
                                            checked={consumerLimit == false ? true : ""}
                                            value={consumerLimit == false ? consumerLimit : ""}
                                        />
                                        <label className="px-2">No</label>
                                    </div>
                                    <div className="pr-4">
                                        <input
                                            onClick={() => setConsumerLimit(true)}
                                            type="radio"
                                            name="consumer_purchase_limit"
                                            className="btn"
                                            checked={consumerLimit == true ? consumerLimit : ""}
                                            value={consumerLimit == true ? consumerLimit : ""}
                                        />
                                        <label className="px-2">Yes</label>
                                        <div className={`${consumerLimit == true ? "d-block" : "d-none"}`}>
                                            <input
                                                type="text"
                                                className="col-6  form-control"
                                                value={consumerLimitQuota != null ? consumerLimitQuota : 0}
                                                onChange={(e) => setConsumerLimitQuota(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <div className="text-secondary fntSz12 ">&#42;Please note that new update will be reflect one week later(23/02/2011).</div>
                    <div className="text-secondary fntSz12 ">&#42;Merchant will receive the update immediately to prepare any action from their end.</div> */}
                </div>
                <div className="">
                    <footer className="bg-white p-3 ">
                        <div className="d-flex justify-content-end">
                            <button
                                onClick={() => history.push("/marketing/all-promo")}
                                type="button"
                                className="btn btn-outline-dark mx-2"
                            // onClick={back}
                            >
                                Back
                            </button>
                            {ablity({model:'Marketing', action:'create'}) == 1 && (
                                <button
                                    type="button"
                                    className="btn text-nowrap btn-outline-dark mx-2"
                                    onClick={() => onSubmit()}
                                >
                                    Save
                                </button>
                            )}

                        </div>
                    </footer>
                </div>
            </div>
        </React.Fragment>
    )
}

export default FlashSaleSetting;