import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TabPanel } from "../../../components/Tabs/TabPannel";
import { AllowAccess, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getAllProductList } from "../../../redux/actions/product.action";
import Allproduct from "./Allproduct";
import { searchMerchantList } from "../../../redux/actions/merchant-center.action";
import { getAllCategoryList } from "../../../redux/actions/category.action";
import SelectInput from "../../../components/select";
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProductListing = () => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [productName, setProductName] = useState();
  const [categoryName, setCategoryName] = useState();
  const [allProductData, setAllProductData] = useState([]);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState({});
  const [categoryList, setCategoryList] = useState([]);
  const [changeCategory, setChangeCategory] = useState({category: null});

  useEffect(() => {
    const breadcrumb = [{ label: "Products", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    getCategoryList();
  }, [tabIndex]);

  const afterDelete = (data) => {
    setTotal(data && data.total);
    setTabIndex(0);
  };

  const searchData = () => {
    setSearch({ productName, categoryName });
  };
  const handleResetSearch = () => {
    setProductName("");
    setCategoryName("");
    setChangeCategory({category: null});
    setSearch({ productName: "", categoryName: "" });
  };

  const getCategoryList = (search) => {
    dispatch(getAllCategoryList(search))
      .then((response) => {
        if (response?.data?.data) {
          const data = response.data.data.map((item) => ({
            title: item.title,
            label: `${item.title}`,
            value: item.title,
          }));

          setCategoryList(data);
        }
      })
      .catch((error) => {
        console.log({ error });
      });
  };

  const onChangeCategory = (e, opt, val) => {
    const key = opt || e.target.name;
    const value = val || e.target.value;
    setCategoryName(val.value)
    setChangeCategory((pre) => ({ ...pre, [key]: value }));
  };

  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-12 row flex-wrap">
          <div className="min-width-200 col-md-5">
            <label>Product Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Input"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-5">
            <label>Category</label>
            <SelectInput
              options={categoryList}
              name="category"
              value={changeCategory.category}
              onChange={(e) => onChangeCategory(e, "category", e)}
              required={true}
              formSubmitted={false}
              placeholder="Select Category"
            />
            {/*<input*/}
            {/*  type="text"*/}
            {/*  className="form-control"*/}
            {/*  placeholder="Enter your category"*/}
            {/*  value={categoryName}*/}
            {/*  onChange={(e) => setCategoryName(e.target.value)}*/}
            {/*/>*/}
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            onClick={() => handleResetSearch()}
            className="btn btn-outline-dark px-4 mx-3 btn-sm"
          >
            Reset
          </button>
          <button
            type="button"
            className="btn btn-dark btn-sm px-3"
            onClick={() => searchData()}
          >
            Search
          </button>
        </div>
      </div>
      <br />
      <br />
      <div className="container_body container-fluid">
        <div className="card p-3">
          <span>PRODUCT LISTING</span>
          <div className=" border card  my-3">
            <Tabs
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              variant="scrollable"
              orientation="horizontal"
              scrollButtons={false}
              visibleScrollbar={false}
              className="px-3"
              aria-label="basic tabs example"
            >
              <Tab label={`All(${total?.all || 0})`} {...a11yProps(0)} />
              <Tab
                label={`Published(${total?.published || 0})`}
                {...a11yProps(1)}
              />
              <Tab label={`New(${total?.New || 0})`} {...a11yProps(2)} />
              <Tab label={`Draft(${total?.draft || 0})`} {...a11yProps(3)} />
              <Tab
                label={`Deactivated(${total?.deactivated || 0})`}
                {...a11yProps(4)}
              />
              <Tab
                label={`Deleted(${total?.deleted || 0})`}
                {...a11yProps(5)}
              />
              <Tab
                label={`Rejected(${total?.rejected || 0})`}
                {...a11yProps(6)}
              />
              {/* <Tab
                label={`To remove(${total?.toremove || 0})`}
                {...a11yProps(7)}
              /> */}
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <Allproduct
                search={search}
                totalItem={total.all}
                status={""}
                setTotals={setTotal}
                afterDelete={afterDelete}
                reset={handleResetSearch}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={1}>
              <Allproduct
                afterDelete={afterDelete}
                search={search}
                totalItem={total.published}
                status={"PUBLISHED"}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={2}>
              <Allproduct
                afterDelete={afterDelete}
                search={search}
                totalItem={total.New}
                status={"NEW"}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={3}>
              <Allproduct
                afterDelete={afterDelete}
                search={search}
                totalItem={total.draft}
                status={"DRAFT"}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={4}>
              <Allproduct
                afterDelete={afterDelete}
                search={search}
                totalItem={total.deactivated}
                status={"DEACTIVATED"}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={5}>
              <Allproduct
                afterDelete={afterDelete}
                search={search}
                totalItem={total.deleted}
                status={"DELETED"}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={6}>
              <Allproduct
                afterDelete={afterDelete}
                search={search}
                totalItem={total.rejected}
                status={"REJECTED"}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>

            {/* <TabPanel value={tabIndex} index={7}>
              <Allproduct
                afterDelete={afterDelete}
                search={search}
                totalItem={total.toremove}

                status={"TOREMOVE"}
                setTotals={setTotal}
                reset={handleResetSearch}
                />
            </TabPanel> */}
          </div>
        </div>
      </div>
      <br />
      <br />
    </React.Fragment>
  );
};

export default ProductListing;
