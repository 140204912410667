export const handleDocumentTitleLogin = (role) => {
  if (role == "MERCHANT") {
    document.title = "UU2 Merchant";
  } else {
    document.title = "UU2 Admin";
  }
};

export const handleDocumentTitleLogout = () => {
  document.title = "UU2 Admin";
};
