


import React from "react";

import CloseIcon from "@mui/icons-material/Close";
function ViewOrderHistoryModal(props) {
  return (
    <React.Fragment>
      <div className="card m-3">
        <div className="d-flex border-bottom w-100 justify-content-between">
          <div className="my-3" style={{ fontSize: "20px", fontWeight: "700" }}>Transaction History</div>
          <div className="close_icon" style={{ top: "0px" }}>
            <CloseIcon
              className="close_icon_btn m-2"
              color="primary2"
              fontSize="large"
              title="Close dialog"
              role="button"
              onClick={props.onClose}
            />
          </div>
        </div>

        <div className="col-12 mt-3">
          <table className="w-100">
            <tr className="w-100">
              <th className=" text-right ">Subtotal</th>
              <th className="text-right ">
                RM30.00
              </th>
            </tr>
            <tr className="w-100">
              <td className=" text-right text-data text-muted">Product Price</td>
              <td className="text-right text-data text-muted  w-0 pl-5">
                 RM30.00
              </td>
            </tr>
            <br />

            <tr className="w-100">
              <th className=" text-right ">Shipping</th>
              <th className="text-right   w-0 pl-5">
                 RM30.00
              </th>
            </tr>
            <tr className="w-100">
              <td className=" text-right text-data text-muted">Shipping Fee Paid by Buyer</td>
              <td className="text-right text-data text-muted  w-0 pl-5">
                 RM30.00
              </td>
            </tr>
            <tr className="w-100">
              <td className=" text-right text-data text-muted">Actual Shipping Fee</td>
              <td className="text-right text-data text-muted  w-0 pl-5">
                 RM30.00
              </td>
            </tr>
           
            <br />
            <tr className="w-100">
              <th className=" text-right">Fee & Charges</th>
              <th className="text-right   w-0 pl-5">
                 RM30.00
              </th>
            </tr>
            <tr className="w-100">
              <td className=" text-right text-data text-muted">Service Fee</td>
              <td className="text-right text-data text-muted  w-0 pl-5">
                 RM30.00
              </td>
            </tr>
            <tr className="w-100">
              <td className=" text-right text-data text-muted">Transaction Fee</td>
              <td className="text-right text-data text-muted  w-0 pl-5">
                 RM30.00
              </td>
            </tr>
            <tr className="w-100">
              <td className=" text-right text-data text-muted">Commission Fee</td>
              <td className="text-right text-data text-muted  w-0 pl-5">
                 RM30.00
              </td>
            </tr>
            <br />
            
            <tr className="w-100">
              <td className=" text-right text-data text-muted pr-2">Total Income</td>
              <td className="text-right text-success text-total">
                RM30.00
              </td>
            </tr>

          </table>
        </div>

      </div>
      <style jsx="true">{`
          .w-0{
            width:0 !important;
          }
          .text-head-black{
            color:#000000;
          }
          .text-data{
            font-size:15px ;
           }
          .text-total{
            font-size:20px;
            font-weight:800;
          }
        `}</style>
    </React.Fragment>

  )
}

export default ViewOrderHistoryModal;

{/* <div className="my-2 ">
          <div className="">
            <div className="d-flex">
              <div className="large-col text-right text-head-black">SubTotal</div>
              <div className="small-col text-right text-head-black ">
                RM30.00
              </div>
            </div>
            <div className=" d-flex">
              <div className="large-col text-right text-muted text-data">SubTotal</div>
              <div className="small-col text-right text-muted text-data ">
                RM30.00
              </div>
            </div>
          </div>
          <br />

          <div className="">
            <div className="d-flex">
              <div className="large-col text-right text-head-black">SubTotal</div>
              <div className="small-col text-right text-head-black ">
                RM30.00
              </div>
            </div>
            <div className=" d-flex">
              <div className="large-col text-right text-muted text-data">SubTotal</div>
              <div className="small-col text-right text-muted text-data ">
                RM30.00
              </div>
            </div>
            <div className=" d-flex">
              <div className="large-col text-right text-muted text-data">SubTotal</div>
              <div className="small-col text-right text-muted text-data ">
                RM30.00
              </div>
            </div>
            <div className=" d-flex">
              <div className="large-col text-right text-muted text-data">SubTotal</div>
              <div className="small-col text-right text-muted text-data ">
                RM30.00
              </div>
            </div>
          </div>
          <br />

          <div className="">
            <div className="d-flex">
              <div className="large-col text-right text-head-black">SubTotal</div>
              <div className="small-col text-right text-head-black ">
                RM30.00
              </div>
            </div>
            <div className=" d-flex">
              <div className="large-col text-right text-muted text-data">SubTotal</div>
              <div className="small-col text-right text-muted text-data ">
                RM30.00
              </div>
            </div>
            <div className=" d-flex">
              <div className="large-col text-right text-muted text-data">SubTotal</div>
              <div className="small-col text-right text-muted text-data ">
                RM30.00
              </div>
            </div>
            <div className=" d-flex">
              <div className="large-col text-right text-muted text-data">SubTotal</div>
              <div className="small-col text-right text-muted text-data ">
                RM30.00
              </div>
            </div>
          </div>
          
          <br />
          <div className="">
            <div className=" w-100 d-flex">
              <div className="text-right text-data">SubTotal</div>
              <div className="text-right text-success text-total">
                RM30.00000000
              </div>
            </div>
          </div>

        </div> */}