export const bankAccout = [
  {
    value: "Maybank",
    label: "Maybank",
    nativeName: "Maybank",
  },
  {
    value: "CIMB Group Holdings",
    label: "CIMB Group Holdings",
    nativeName: "CIMB Group Holdings",
  },
  {
    value: "Public Bank Berhad",
    label: "Public Bank Berhad",
    nativeName: "Public Bank Berhad",
  },
  {
    value: "RHB Bank",
    label: "RHB Bank",
    nativeName: "RHB Bank",
  },
  {
    value: "AmBank",
    label: "AmBank",
    nativeName: "AmBank",
  },
  {
    value: "UOB Malaysia",
    label: "UOB Malaysia",
    nativeName: "UOB Malaysia",
  },
  {
    value: "Bank Rakyat",
    label: "Bank Rakyat",
    nativeName: "Bank Rakyat",
  },
  {
    value: "OCBC Bank Malaysia",
    label: "OCBC Bank Malaysia",
    nativeName: "OCBC Bank Malaysia",
  },
  {
    value: "HSBC Bank Malaysia",
    label: "HSBC Bank Malaysia",
    nativeName: "HSBC Bank Malaysia",
  },
  {
    value: "Bank Islam Malaysia",
    label: "Bank Islam Malaysia",
    nativeName: "Bank Islam Malaysia",
  },
  {
    value: "Affin Bank",
    label: "Affin Bank",
    nativeName: "Affin Bank",
  },
  {
    value: "Alliance Bank Malaysia Berhad",
    label: "Alliance Bank Malaysia Berhad",
    nativeName: "Alliance Bank Malaysia Berhad",
  },
  {
    value: "Standard Chartered Bank Malaysia",
    label: "Standard Chartered Bank Malaysia",
    nativeName: "Standard Chartered Bank Malaysia",
  },
  {
    value: "Bank Simpanan Nasionla (BSN)",
    label: "Bank Simpanan Nasionla (BSN)",
    nativeName: "Bank Simpanan Nasionla (BSN)",
  },
  {
    value: "Agro Bank",
    label: "Agro Bank",
    nativeName: "Agro Bank",
  },
  {
    value: "Bank Mualamat",
    label: "Bank Mualamat",
    nativeName: "Bank Mualamat",
  },
  {
    value: "Bank Islam",
    label: "Bank Islam",
    nativeName: "Bank Islam",
  },
  {
    value: "Kuwait Finance House",
    label: "Kuwait Finance House",
    nativeName: "Kuwait Finance House",
  },
  {
    value: "Hong Leong Bank",
    label: "Hong Leong Bank",
    nativeName: "Hong Leong Bank",
  },
  // {
  //   value: "Affin Bank Berhad",
  //   label: "Affin Bank Berhad",
  //   nativeName: "Affin Bank Berhad  ",
  // },
  // {
  //   value: "Alliance Bank Malaysia Berhad",
  //   label: "Alliance Bank Malaysia Berhad",
  //   nativeName: "Alliance Bank Malaysia Berhad",
  // },
  // {
  //   value: "AmBank (M) Berhad",
  //   label: "AmBank (M) Berhad",
  //   nativeName: "AmBank (M) Berhad",
  // },
  // {
  //   value: "BNP Paribas Malaysia Berhad",
  //   label: "BNP Paribas Malaysia Berhad",
  //   nativeName: "BNP Paribas Malaysia Berhad",
  // },
  // {
  //   value: "Bangkok Bank Berhad",
  //   label: "Bangkok Bank Berhad",
  //   nativeName: "Bangkok Bank Berhad",
  // },
  // {
  //   value: "Bank of America Malaysia Berhad",
  //   label: "Bank of America Malaysia Berhad",
  //   nativeName: "Bank of America Malaysia Berhad",
  // },
];

export const stateName = [
  { value: "Johor", label: "Johor", nativeName: "Johor" },
  { value: "Kuala Lumpur", label: "Kuala Lumpur", nativeName: "Kuala Lumpur" },
  { value: "Kedah", label: "Kedah", nativeName: "Kedah" },
  { value: "Kelantan", label: "Kelantan", nativeName: "Kelantan" },
  { value: "Melaka", label: "Melaka", nativeName: "Melaka" },
  {
    value: "Negeri Sembilan",
    label: "Negeri Sembilan",
    nativeName: "Negeri Sembilan",
  },
  { value: "Pahang", label: "Pahang", nativeName: "Pahang" },
  { value: "Perak", label: "Perak", nativeName: "Perak" },
  { value: "Perlis", label: "Perlis", nativeName: "Perlis" },
  { value: "Pulau Pinang", label: "Pulau Pinang", nativeName: "Pulau Pinang" },
  { value: "Sabah", label: "Sabah", nativeName: "Sabah" },
  { value: "Sarawak", label: "Sarawak", nativeName: "Sarawak" },
  { value: "Selangor", label: "Selangor", nativeName: "Selangor" },
  { value: "Terengganu", label: "Terengganu", nativeName: "Terengganu" },
];

export const orderStatuses = [
  { value: "orderPlaced", label: "Order Placed", nativeName: "Order Placed" },
  { value: "orderPaid", label: "Order Paid", nativeName: "Order Paid" },
  {
    value: "prepairingToShipped",
    label: "Preparing To Ship",
    nativeName: "Preparing To Ship",
  },
  {
    value: "orderShipped",
    label: "Order Shipped",
    nativeName: "Order Shipped",
  },
  {
    value: "orderDelivered",
    label: "Order Delivered",
    nativeName: "Order Delivered",
  },
];

export const rating_stars = [
  { value: "0", label: "0", nativeName: "0" },
  { value: "1", label: "1", nativeName: "1" },
  { value: "2", label: "2", nativeName: "2" },
  { value: "3", label: "3", nativeName: "3" },
  { value: "4", label: "4", nativeName: "4" },
  { value: "5", label: "5", nativeName: "5" },
];

export const statusData = [
  { value: "New", label: "New", nativeName: "New" },
  { value: "Active", label: "Active", nativeName: "Active" },
  { value: "Blocked", label: "Blocked", nativeName: "Blocked" },
  { value: "Closed", label: "Closed", nativeName: "Closed" },
];

export const roleData = [
  { value: "SUPERADMIN", label: "SUPERADMIN", nativeName: "SUPERADMIN" },
  { value: "DEVELOPER", label: "DEVELOPER", nativeName: "DEVELOPER" },
  { value: "MERCHANT", label: "MERCHANT", nativeName: "MERCHANT" },
  { value: "OPERATION", label: "OPERATION", nativeName: "OPERATION" },
  {
    value: "CUSTOMERSUPPORT",
    label: "CUSTOMERSUPPORT",
    nativeName: "CUSTOMERSUPPORT",
  },
  { value: "BUSINESS", label: "BUSINESS", nativeName: "BUSINESS" },
  { value: "MERCHANT", label: "MERCHANT", nativeName: "MERCHANT" },
  { value: "USER", label: "USER", nativeName: "USER" },
];

// Johor
// Kedah
// Kelantan
// Melaka
// Negeri Sembilan
// Pahang
// Perak
// Perlis
// Pulau Pinang
// Sabah
// Sarawak
// Selangor
// Terengganu

// export const languageList = [
//   {"value":"ab","label":"Abkhaz","nativeName":"аҧсуа"},
//   {"value":"aa","label":"Afar","nativeName":"Afaraf"},
//   {"value":"af","label":"Afrikaans","nativeName":"Afrikaans"},
//   {"value":"ak","label":"Akan","nativeName":"Akan"},
//   {"value":"sq","label":"Albanian","nativeName":"Shqip"},
//   {"value":"am","label":"Amharic","nativeName":"አማርኛ"},
//   {"value":"ar","label":"Arabic","nativeName":"العربية"},
//   {"value":"an","label":"Aragonese","nativeName":"Aragonés"},
//   {"value":"hy","label":"Armenian","nativeName":"Հայերեն"},
//   {"value":"as","label":"Assamese","nativeName":"অসমীয়া"},
//   {"value":"av","label":"Avaric","nativeName":"авар мацӀ, магӀарул мацӀ"},
//   {"value":"ae","label":"Avestan","nativeName":"avesta"},
//   {"value":"ay","label":"Aymara","nativeName":"aymar aru"},
//   {"value":"az","label":"Azerbaijani","nativeName":"azərbaycan dili"},
//   {"value":"bm","label":"Bambara","nativeName":"bamanankan"},
//   {"value":"ba","label":"Bashkir","nativeName":"башҡорт теле"},
//   {"value":"eu","label":"Basque","nativeName":"euskara, euskera"},
//   {"value":"be","label":"Belarusian","nativeName":"Беларуская"},
//   {"value":"bn","label":"Bengali","nativeName":"বাংলা"},
//   {"value":"bh","label":"Bihari","nativeName":"भोजपुरी"},
//   {"value":"bi","label":"Bislama","nativeName":"Bislama"},
//   {"value":"bs","label":"Bosnian","nativeName":"bosanski jezik"},
//   {"value":"br","label":"Breton","nativeName":"brezhoneg"},
//   {"value":"bg","label":"Bulgarian","nativeName":"български език"},
//   {"value":"my","label":"Burmese","nativeName":"ဗမာစာ"},
//   {"value":"ca","label":"Catalan; Valencian","nativeName":"Català"},
//   {"value":"ch","label":"Chamorro","nativeName":"Chamoru"},
//   {"value":"ce","label":"Chechen","nativeName":"нохчийн мотт"},
//   {"value":"ny","label":"Chichewa; Chewa; Nyanja","nativeName":"chiCheŵa, chinyanja"},
//   {"value":"zh","label":"Chinese","nativeName":"中文 (Zhōngwén), 汉语, 漢語"},
//   {"value":"cv","label":"Chuvash","nativeName":"чӑваш чӗлхи"},
//   {"value":"kw","label":"Cornish","nativeName":"Kernewek"},
//   {"value":"co","label":"Corsican","nativeName":"corsu, lingua corsa"},
//   {"value":"cr","label":"Cree","nativeName":"ᓀᐦᐃᔭᐍᐏᐣ"},
//   {"value":"hr","label":"Croatian","nativeName":"hrvatski"},
//   {"value":"cs","label":"Czech","nativeName":"česky, čeština"},
//   {"value":"da","label":"Danish","nativeName":"dansk"},
//   {"value":"dv","label":"Divehi; Dhivehi; Maldivian;","nativeName":"ދިވެހި"},
//   {"value":"nl","label":"Dutch","nativeName":"Nederlands, Vlaams"},
//   {"value":"en","label":"English","nativeName":"English"},
//   {"value":"eo","label":"Esperanto","nativeName":"Esperanto"},
//   {"value":"et","label":"Estonian","nativeName":"eesti, eesti keel"},
//   {"value":"ee","label":"Ewe","nativeName":"Eʋegbe"},
//   {"value":"fo","label":"Faroese","nativeName":"føroyskt"},
//   {"value":"fj","label":"Fijian","nativeName":"vosa Vakaviti"},
//   {"value":"fi","label":"Finnish","nativeName":"suomi, suomen kieli"},
//   {"value":"fr","label":"French","nativeName":"français, langue française"},
//   {"value":"ff","label":"Fula; Fulah; Pulaar; Pular","nativeName":"Fulfulde, Pulaar, Pular"},
//   {"value":"gl","label":"Galician","nativeName":"Galego"},
//   {"value":"ka","label":"Georgian","nativeName":"ქართული"},
//   {"value":"de","label":"German","nativeName":"Deutsch"},
//   {"value":"el","label":"Greek, Modern","nativeName":"Ελληνικά"},
//   {"value":"gn","label":"Guaraní","nativeName":"Avañeẽ"},
//   {"value":"gu","label":"Gujarati","nativeName":"ગુજરાતી"},
//   {"value":"ht","label":"Haitian; Haitian Creole","nativeName":"Kreyòl ayisyen"},
//   {"value":"ha","label":"Hausa","nativeName":"Hausa, هَوُسَ"},
//   {"value":"he","label":"Hebrew (modern)","nativeName":"עברית"},
//   {"value":"hz","label":"Herero","nativeName":"Otjiherero"},
//   {"value":"hi","label":"Hindi","nativeName":"हिन्दी, हिंदी"},
//   {"value":"ho","label":"Hiri Motu","nativeName":"Hiri Motu"},
//   {"value":"hu","label":"Hungarian","nativeName":"Magyar"},
//   {"value":"ia","label":"Interlingua","nativeName":"Interlingua"},
//   {"value":"id","label":"Indonesian","nativeName":"Bahasa Indonesia"},
//   {"value":"ie","label":"Interlingue","nativeName":"Originally called Occidental; then Interlingue after WWII"},
//   {"value":"ga","label":"Irish","nativeName":"Gaeilge"},
//   {"value":"ig","label":"Igbo","nativeName":"Asụsụ Igbo"},
//   {"value":"ik","label":"Inupiaq","nativeName":"Iñupiaq, Iñupiatun"},
//   {"value":"io","label":"Ido","nativeName":"Ido"},
//   {"value":"is","label":"Icelandic","nativeName":"Íslenska"},
//   {"value":"it","label":"Italian","nativeName":"Italiano"},
//   {"value":"iu","label":"Inuktitut","nativeName":"ᐃᓄᒃᑎᑐᑦ"},
//   {"value":"ja","label":"Japanese","nativeName":"日本語 (にほんご／にっぽんご)"},
//   {"value":"jv","label":"Javanese","nativeName":"basa Jawa"},
//   {"value":"kl","label":"Kalaallisut, Greenlandic","nativeName":"kalaallisut, kalaallit oqaasii"},
//   {"value":"kn","label":"Kannada","nativeName":"ಕನ್ನಡ"},
//   {"value":"kr","label":"Kanuri","nativeName":"Kanuri"},
//   {"value":"ks","label":"Kashmiri","nativeName":"कश्मीरी, كشميري‎"},
//   {"value":"kk","label":"Kazakh","nativeName":"Қазақ тілі"},
//   {"value":"km","label":"Khmer","nativeName":"ភាសាខ្មែរ"},
//   {"value":"ki","label":"Kikuyu, Gikuyu","nativeName":"Gĩkũyũ"},
//   {"value":"rw","label":"Kinyarwanda","nativeName":"Ikinyarwanda"},
//   {"value":"ky","label":"Kirghiz, Kyrgyz","nativeName":"кыргыз тили"},
//   {"value":"kv","label":"Komi","nativeName":"коми кыв"},
//   {"value":"kg","label":"Kongo","nativeName":"KiKongo"},
//   {"value":"ko","label":"Korean","nativeName":"한국어 (韓國語), 조선말 (朝鮮語)"},
//   {"value":"ku","label":"Kurdish","nativeName":"Kurdî, كوردی‎"},
//   {"value":"kj","label":"Kwanyama, Kuanyama","nativeName":"Kuanyama"},
//   {"value":"la","label":"Latin","nativeName":"latine, lingua latina"},
//   {"value":"lb","label":"Luxembourgish, Letzeburgesch","nativeName":"Lëtzebuergesch"},
//   {"value":"lg","label":"Luganda","nativeName":"Luganda"},
//   {"value":"li","label":"Limburgish, Limburgan, Limburger","nativeName":"Limburgs"},
//   {"value":"ln","label":"Lingala","nativeName":"Lingála"},
//   {"value":"lo","label":"Lao","nativeName":"ພາສາລາວ"},
//   {"value":"lt","label":"Lithuanian","nativeName":"lietuvių kalba"},
//   {"value":"lu","label":"Luba-Katanga","nativeName":""},
//   {"value":"lv","label":"Latvian","nativeName":"latviešu valoda"},
//   {"value":"gv","label":"Manx","nativeName":"Gaelg, Gailck"},
//   {"value":"mk","label":"Macedonian","nativeName":"македонски јазик"},
//   {"value":"mg","label":"Malagasy","nativeName":"Malagasy fiteny"},
//   {"value":"ms","label":"Malay","nativeName":"bahasa Melayu, بهاس ملايو‎"},
//   {"value":"ml","label":"Malayalam","nativeName":"മലയാളം"},
//   {"value":"mt","label":"Maltese","nativeName":"Malti"},
//   {"value":"mi","label":"Māori","nativeName":"te reo Māori"},
//   {"value":"mr","label":"Marathi (Marāṭhī)","nativeName":"मराठी"},
//   {"value":"mh","label":"Marshallese","nativeName":"Kajin M̧ajeļ"},
//   {"value":"mn","label":"Mongolian","nativeName":"монгол"},
//   {"value":"na","label":"Nauru","nativeName":"Ekakairũ Naoero"},
//   {"value":"nv","label":"Navajo, Navaho","nativeName":"Diné bizaad, Dinékʼehǰí"},
//   {"value":"nb","label":"Norwegian Bokmål","nativeName":"Norsk bokmål"},
//   {"value":"nd","label":"North Ndebele","nativeName":"isiNdebele"},
//   {"value":"ne","label":"Nepali","nativeName":"नेपाली"},
//   {"value":"ng","label":"Ndonga","nativeName":"Owambo"},
//   {"value":"nn","label":"Norwegian Nynorsk","nativeName":"Norsk nynorsk"},
//   {"value":"no","label":"Norwegian","nativeName":"Norsk"},
//   {"value":"ii","label":"Nuosu","nativeName":"ꆈꌠ꒿ Nuosuhxop"},
//   {"value":"nr","label":"South Ndebele","nativeName":"isiNdebele"},
//   {"value":"oc","label":"Occitan","nativeName":"Occitan"},
//   {"value":"oj","label":"Ojibwe, Ojibwa","nativeName":"ᐊᓂᔑᓈᐯᒧᐎᓐ"},
//   {"value":"cu","label":"Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic","nativeName":"ѩзыкъ словѣньскъ"},
//   {"value":"om","label":"Oromo","nativeName":"Afaan Oromoo"},
//   {"value":"or","label":"Oriya","nativeName":"ଓଡ଼ିଆ"},
//   {"value":"os","label":"Ossetian, Ossetic","nativeName":"ирон æвзаг"},
//   {"value":"pa","label":"Panjabi, Punjabi","nativeName":"ਪੰਜਾਬੀ, پنجابی‎"},
//   {"value":"pi","label":"Pāli","nativeName":"पाऴि"},
//   {"value":"fa","label":"Persian","nativeName":"فارسی"},
//   {"value":"pl","label":"Polish","nativeName":"polski"},
//   {"value":"ps","label":"Pashto, Pushto","nativeName":"پښتو"},
//   {"value":"pt","label":"Portuguese","nativeName":"Português"},
//   {"value":"qu","label":"Quechua","nativeName":"Runa Simi, Kichwa"},
//   {"value":"rm","label":"Romansh","nativeName":"rumantsch grischun"},
//   {"value":"rn","label":"Kirundi","nativeName":"kiRundi"},
//   {"value":"ro","label":"Romanian, Moldavian, Moldovan","nativeName":"română"},
//   {"value":"ru","label":"Russian","nativeName":"русский язык"},
//   {"value":"sa","label":"Sanskrit (Saṁskṛta)","nativeName":"संस्कृतम्"},
//   {"value":"sc","label":"Sardinian","nativeName":"sardu"},
//   {"value":"sd","label":"Sindhi","nativeName":"सिन्धी, سنڌي، سندھی‎"},
//   {"value":"se","label":"Northern Sami","nativeName":"Davvisámegiella"},
//   {"value":"sm","label":"Samoan","nativeName":"gagana faa Samoa"},
//   {"value":"sg","label":"Sango","nativeName":"yângâ tî sängö"},
//   {"value":"sr","label":"Serbian","nativeName":"српски језик"},
//   {"value":"gd","label":"Scottish Gaelic; Gaelic","nativeName":"Gàidhlig"},
//   {"value":"sn","label":"Shona","nativeName":"chiShona"},
//   {"value":"si","label":"Sinhala, Sinhalese","nativeName":"සිංහල"},
//   {"value":"sk","label":"Slovak","nativeName":"slovenčina"},
//   {"value":"sl","label":"Slovene","nativeName":"slovenščina"},
//   {"value":"so","label":"Somali","nativeName":"Soomaaliga, af Soomaali"},
//   {"value":"st","label":"Southern Sotho","nativeName":"Sesotho"},
//   {"value":"es","label":"Spanish; Castilian","nativeName":"español, castellano"},
//   {"value":"su","label":"Sundanese","nativeName":"Basa Sunda"},
//   {"value":"sw","label":"Swahili","nativeName":"Kiswahili"},
//   {"value":"ss","label":"Swati","nativeName":"SiSwati"},
//   {"value":"sv","label":"Swedish","nativeName":"svenska"},
//   {"value":"ta","label":"Tamil","nativeName":"தமிழ்"},
//   {"value":"te","label":"Telugu","nativeName":"తెలుగు"},
//   {"value":"tg","label":"Tajik","nativeName":"тоҷикӣ, toğikī, تاجیکی‎"},
//   {"value":"th","label":"Thai","nativeName":"ไทย"},
//   {"value":"ti","label":"Tigrinya","nativeName":"ትግርኛ"},
//   {"value":"bo","label":"Tibetan Standard, Tibetan, Central","nativeName":"བོད་ཡིག"},
//   {"value":"tk","label":"Turkmen","nativeName":"Türkmen, Түркмен"},
//   {"value":"tl","label":"Tagalog","nativeName":"Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔"},
//   {"value":"tn","label":"Tswana","nativeName":"Setswana"},
//   {"value":"to","label":"Tonga (Tonga Islands)","nativeName":"faka Tonga"},
//   {"value":"tr","label":"Turkish","nativeName":"Türkçe"},
//   {"value":"ts","label":"Tsonga","nativeName":"Xitsonga"},
//   {"value":"tt","label":"Tatar","nativeName":"татарча, tatarça, تاتارچا‎"},
//   {"value":"tw","label":"Twi","nativeName":"Twi"},
//   {"value":"ty","label":"Tahitian","nativeName":"Reo Tahiti"},
//   {"value":"ug","label":"Uighur, Uyghur","nativeName":"Uyƣurqə, ئۇيغۇرچە‎"},
//   {"value":"uk","label":"Ukrainian","nativeName":"українська"},
//   {"value":"ur","label":"Urdu","nativeName":"اردو"},
//   {"value":"uz","label":"Uzbek","nativeName":"zbek, Ўзбек, أۇزبېك‎"},
//   {"value":"ve","label":"Venda","nativeName":"Tshivenḓa"},
//   {"value":"vi","label":"Vietnamese","nativeName":"Tiếng Việt"},
//   {"value":"vo","label":"Volapük","nativeName":"Volapük"},
//   {"value":"wa","label":"Walloon","nativeName":"Walon"},
//   {"value":"cy","label":"Welsh","nativeName":"Cymraeg"},
//   {"value":"wo","label":"Wolof","nativeName":"Wollof"},
//   {"value":"fy","label":"Western Frisian","nativeName":"Frysk"},
//   {"value":"xh","label":"Xhosa","nativeName":"isiXhosa"},
//   {"value":"yi","label":"Yiddish","nativeName":"ייִדיש"},
//   {"value":"yo","label":"Yoruba","nativeName":"Yorùbá"},
//   {"value":"za","label":"Zhuang, Chuang","nativeName":"Saɯ cueŋƅ, Saw cuengh"}
// ]
