import { GetWithToken } from "../lib/request";
import { getLocalStorage } from "../lib/session";



export const getAllRedemptionListApi = (query) => {
    let role = getLocalStorage("role");

    console.log(query)
    
    if(role == "SUPERADMIN"){
        return async function (dispatch) {
            const result = await GetWithToken(`/v2/admin/redemption?merchantName=${query.merchantName || ""}&redemptionStatus=${query.redemptionStatus || ""}&productName=${query.productName || ""}&startDate=${query.startDate || ""}&endDate=${query.endDate || ""}&item=${query.item || ""}&order=${query.order || ""}&offset=${query.offset || 0}&limit=${query.limit || 0}`);
            return result
        }
    }else{
        console.log(query)
        return async function (dispatch) {
            const result = await GetWithToken(`/v2/merchant/redemption?redemptionStatus=${query.redemptionStatus || ""}&productName=${query.productName || ""}&startDate=${query.startDate || ""}&endDate=${query.endDate || ""}&item=${query.item || ""}&order=${query.order || ""}&offset=${query.offset || 0}&limit=${query.limit || 0}`);
            return result
        }
    }
}