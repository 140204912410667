import { getLocalStorage } from "../lib/session";

export default function ablity(payload = {}) {
  let rules = getLocalStorage('rules');
  let model = payload.model;
  let action = payload.action;
  let subject = 0;
  let allow   = false;
  let fullAccess = false;
  rules = JSON.parse(rules);

  if(rules){
    rules.map((r, i)=>{
      if(r.subject == "all"){
        fullAccess = true;
      }
    })
  
    rules.map((r, i)=>{
      if(r.subject == model){
        subject++;
        let perms = r.action;
        if(perms){
          perms.map((perm) => {
            if (action == 'create' && perm == 'create') allow = true;
            else if (action == 'read' && perm == 'read') allow = true;
            else if (action == 'update' && perm == 'update') allow = true;
            else if (action == 'delete' && perm == 'delete') allow = true;
          })
        }
      }
    })
  }

  if(fullAccess == true){
   return 1;
  }
  else{
    if(subject == 0){
      return 0;
    }

    if (!allow) {
      return 0
    }

    return 1;
  }
}