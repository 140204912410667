import { Post, Get, DeleteById, UpdateById, PostWithToken,GetWithToken } from "../lib/request"

/**
 * @description method to post add merchant
 * @author prabhkar
 * @date 11/01/2022
 */
export const addBrandApi = (data) => {
    // console.log(data, "dddddddddddddddddddddddddddddd");
    // return PostWithToken('/brand', data)
    return async function (dispatch) {
        const result = await PostWithToken('/brand', data)
        return result
    }

}

export const getParentApi = (data) => {
    // return Get('/categories/get-parent-category')
    return async function (dispatch) {
        const result = await Get('/categories/get-parent-category')
        return result
    }
}


export const getAllBrandApi = (query) => {
    console.log(query);
    return async function (dispatch) {
        const result = await GetWithToken(`/brand/?search=${query.search || ""}&item=${query.item || ""}&order=${query.order || ""}&offset=${query.offset || 0}&limit=${query.limit || 0}`)
        return result
    }
}

export const getBrandById = (id) => {
    return async function (dispatch) {
        const result = await Get(`/brand/${id}`)
        return result
    }

}
export const updateBrandById = (id, data) => {
    console.log(id, 'dahfj');
    return async function (dispatch) {
        const result = await UpdateById(`/brand/${id}`, data)
        return result
    }

}
export const deleteBrandById = (id) => {
    return async function (dispatch) {
        const result = await DeleteById(`/brand/${id}`)
        return result
    }
}