import React, { useEffect, useState } from "react";
import Image from "../../../components/Images/Image";

import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Datepicker from "../../../components/datepicker/datepicker";
import InputField from "../../../components/formControl/input/InputField";
import RadioButtonsGroup from "../../../components/radio-button/RadioButtonsGroup";
import {
  ADD_CONSUMER_BANK_ACCOUNT,
  ADD_CONSUMER_SHIPPING_ADDRESS,
  CANCEL_EDIT_MODAL,
  EDIT_CONSUMER_BANK_ACCOUNT,
  EDIT_CONSUMER_SHIPPING_ADDRESS,
} from "../../../lib/constant";
import {
  AllowAccess,
  open_dialog,
  showToast,
  startLoader,
  stopLoader,
  UploadImage
} from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import {
  addNewConsumer,
  getConsumerById,
  updateConsumerById,
} from "../../../redux/actions/consumer-center.action";

const initialConsumerDetail = {
  consumerName: "",
  phoneNumber: "",
  email: "",
  gender: "",
  dateOfBirth: "",
  password: "",
  confirmPassword: "",
};
const requiredFields = [
  "consumerName",
  "phoneNumber",
  "email",
  "gender",
  "dateOfBirth",
  "password",
];

const initialBankInfo = [];
const initialShippingInfo = [];

const consumerGenderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];



const AddConsumerAccount = () => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const [errorList, setErrorList] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [consumerDetail, setConsumerDetail] = useState(initialConsumerDetail);
  const [bankInfo, setBankInfo] = useState(initialBankInfo);
  const [shippingInfo, setShippingInfo] = useState(initialShippingInfo);
  const [profileImage, setProfileImage] = useState();
  const [file, setFile] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState("");

  const { id } = useParams();

  // ---------

  const [edit, setEdit] = useState(true);
  const [editConsumerDetail, setEditConsumerDetail] = useState({});

  const [resetPassword, setResetPassword] = useState(false);

  // -------
  const validateSalesInfo = () => {
    let error = {};
    let formIsValid = true;
    if (!consumerDetail.consumerName) {
      formIsValid = false;
      error["consumerName"] = "consumerName not be empty";
    }
    if (!consumerDetail.phoneNumber) {
      formIsValid = false;
      error["phoneNumber"] = "phone Number not be empty";
    }
    if (
      !(
        String(consumerDetail.phoneNumber).length == 11 ||
        String(consumerDetail.phoneNumber).length == 12
      )
    ) {
      formIsValid = false;
      error["phoneNumber"] = "phone Number should be 11 to 12 digit long";
    }
    if (!consumerDetail.email) {
      formIsValid = false;
      error["email"] = "Email not be empty";
    }
    if (!consumerDetail.gender) {
      formIsValid = false;
      error["gender"] = "Gender not be empty";
    }
    if (!consumerDetail.dateOfBirth) {
      formIsValid = false;
      error["dateOfBirth"] = "Date of Birth not be empty";
    }
    if (!id) {
      if (!consumerDetail.password) {
        formIsValid = false;
        error["password"] = "Password not be empty";
      }
      if (!consumerDetail.confirmPassword) {
        formIsValid = false;
        error["confirmPassword"] = "Password conform not be empty";
      }
      if (consumerDetail.password != consumerDetail.confirmPassword) {
        formIsValid = false;
        error["confirmPassword"] = "confirmPassword is wrong";
      }
    }
    if (shippingInfo.length > 0) {
      shippingInfo.map((item) => {
        if (!item.phoneNumber) {
          formIsValid = false;
          error["phoneNumber"] = "consumer address phone Number not be empty";
        }
        if (
          !(
            String(item.phoneNumber).length == 11 ||
            String(item.phoneNumber).length == 12
          )
        ) {
          formIsValid = false;
          error["phoneNumber"] =
            "consumer address phone Number should be 11 to 12 digit long";
        }
      });
      //   formIsValid = false;
      //   error["confirmPassword"] = "confirmPassword is wrong";
    }

    setErrorList(error);
    return formIsValid;
  };

  useEffect(() => {
    validateSalesInfo();
  }, [consumerDetail]);
  //--------
  useEffect(() => {
    console.log("daf", id);
    if (id) {
      startLoader();
      const response = dispatch(getConsumerById(id));
      response.then((data) => {
        console.log(data.data, "data   data");
        if (data && data.data.status === 200) {
          console.log(data.data.data, "kkkkssssssssssssss");
          const {
            consumerName,
            dateOfBirth,
            email,
            gender,
            password,
            phoneNumber,
            profileImage,
          } = data.data.data;

          setTimeout(() => {
            setConsumerDetail({
              consumerName,
              dateOfBirth,
              email,
              password,
              gender,
              phoneNumber,
              confirmPassword: password,
            });
            setEditConsumerDetail({
              consumerName,
              dateOfBirth,
              email,
              gender,
              password,
              phoneNumber,
              confirmPassword: password,
            });
            setBankInfo(data.data.data.consumerAccounts || []);
            setShippingInfo(data.data.data.consumerAddresses || []);
            setProfileImage(profileImage);

            stopLoader();
          }, 1000);
        } else {
          setTimeout(() => {
            stopLoader();
            showToast(data.data.message, "error");
          }, 1000);
        }
      });
    }
  }, [id]);

  useEffect(() => {
    const breadcrumb = [
      { label: "Consumer Account", url: "/consumer-center/account" },
      { label: id ? "Edit Account" : "Add Account", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
  }, []);

  const handleSelectFile = (e) => {
    const fileData = e.target.files[0];

    setFile(fileData);
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      setProfileImage(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const onChangeConsumerDetail = (e, title, val) => {
    console.log(id, "fgghjkl");
    const key = title || e.target.id;
    const value = val || e.target.value;
    console.log(key, value);
    if (id) {
      setEditConsumerDetail({ ...editConsumerDetail, [key]: value });
    } else {
      setConsumerDetail((pre) => ({ ...pre, [key]: value }));
    }
  };
  //   console.log(editConsumerDetail, "gfvhujbn");
  const saveEditConsumerDetail = (type) => {
    console.log(editConsumerDetail, "save");
    setConsumerDetail((pre) => ({ ...pre, ...editConsumerDetail }));
    if (type == "consumerBasicData") {
      setEdit(!edit);
    } else if (type == "password") {
      setResetPassword(!resetPassword);
    }
  };

  const onSubmitAddconsumer = async (status = "ACTIVE") => {
    console.log("kkkkkkkkkkkkkkk");

    // validateSalesInfo();
    let imageUrl = file ? await UploadImage(file, `uu2/merchant`) : null;
    console.log(imageUrl, "lllllllllllllll");
    console.log(consumerDetail.confirmPassword);
    const {
      consumerName,
      phoneNumber,
      email,
      gender,
      dateOfBirth,
      password,
      confirmPassword,
    } = consumerDetail;

    if (consumerDetail.password == consumerDetail.confirmPassword) {
      console.log("sssssss");
      let payload = {
        consumerName,
        phoneNumber: String(phoneNumber),
        email,
        gender,
        dateOfBirth,
        password,
        profileImage: imageUrl,
        bankDetails: bankInfo,
        address: shippingInfo,
        status: status,
      };

      if (!validateSalesInfo()) {
        console.log(errorList, "erororasdfads");
        Object.values(errorList).map((item) => showToast(item, "error"));
        return;
      }
      startLoader();
      // let invalidFieldName = [];
      // for (let item of requiredFields) {
      //     if (!payload[item]) {
      //         invalidFieldName.push(item);
      //     }
      // }
      // if (invalidFieldName?.length) {
      //     const label = invalidFieldName.join(", ");
      //     return setError(`${label} fields are invalid or missing!`);
      // }

      //----------------api call----------------

      // startLoader();
      if (payload?.bankDetails?.length == 0) {
        stopLoader();
        showToast("Please add at least one bank account", "error");
      } else {
        if (id) {
          const response = dispatch(updateConsumerById(id, payload));
          response
            .then((data) => {
              if (data && data.data.status === 201) {
                // setTimeout(() => {
                showToast("consumer updated successfully", "success");
                stopLoader();
                history.push("/consumer-center/account");
                // }, 1000);
              } else {
                // setTimeout(() => {
                stopLoader();
                showToast(data.data.message, "error");
                // }, 1000);
              }
            })
            .catch((error) => {
              stopLoader();
              showToast(error.response.data.message, "error");
            });
        } else {
          const response = dispatch(addNewConsumer(payload));
          response
            .then((data) => {
              console.log(data.data, "data   data");
              if (data && data.data.status === 201) {
                setTimeout(() => {
                  stopLoader();
                  showToast("Consumer add successfully", "success");
                  history.push("/consumer-center/account");
                }, 1000);
              } else {
                setTimeout(() => {
                  stopLoader();
                  showToast(data.data.message, "error");
                }, 1000);
              }
            })
            .catch((err) => {
              setTimeout(() => {
                stopLoader();
                showToast(err.response.data.message, "error");
              }, 1000);
            });
        }
      }
    } else {
      stopLoader();
      console.log("uui");
      showToast("password and confirm password is not matched", "error");
    }
  };

  const handleAddConsumerBankAccount = () => {
    open_dialog(ADD_CONSUMER_BANK_ACCOUNT, {
      onSubmit: onSubmitBankAccountModal,
    });
  };
  const onSubmitBankAccountModal = (data) => {
    setBankInfo((pre) => [...pre, data]);
  };

  const editConsumerBankAccount = (
    id,
    bankName,
    bankAccountNo,
    beneciaryName,
    index
  ) => {
    open_dialog(EDIT_CONSUMER_BANK_ACCOUNT, {
      onSubmit: onSubmitEditBankAccount,
      id,
      bankName,
      bankAccountNo,
      beneciaryName,
      index,
    });
  };
  const onSubmitEditBankAccount = (data) => {
    const items = [...bankInfo];
    const { id, bankName, bankAccountNo, beneciaryName } = data;
    items[data.index] = {
      id,
      bankName,
      bankAccountNo,
      beneciaryName,
    };
    setBankInfo(items);
  };

  const deleteConsumerBankAccount = (index1) => {
    setBankInfo(bankInfo.filter((item, index) => index !== index1));
  };

  const handleAddShippingAddress = () => {
    open_dialog(ADD_CONSUMER_SHIPPING_ADDRESS, {
      onSubmit: onSubmitShippingAddressModal,
    });
  };
  const onSubmitShippingAddressModal = (data) => {
    console.log(data);
    setShippingInfo((pre) => [...pre, data]);
  };

  const editConsumerShippingAddress = (
    id,
    fullName,
    phoneNumber,
    addressLine1,
    addressLine2,
    postalCode,
    state,
    setAsPickupAddress,
    setAsReturnAddress,
    address,
    index
  ) => {
    console.log(id);
    open_dialog(EDIT_CONSUMER_SHIPPING_ADDRESS, {
      onSubmit: onSubmitEditShippingAddress,
      id,
      fullName,
      phoneNumber,
      addressLine1,
      addressLine2,
      postalCode,
      state,
      setAsPickupAddress,
      setAsReturnAddress,
      address,
      index,
    });
  };
  const onSubmitEditShippingAddress = (data) => {
    const items = [...shippingInfo];
    console.log(data);

    console.log(items, "beforreeeeeehj");

    const {
      id,
      fullName,
      phoneNumber,
      addressLine1,
      addressLine2,
      postalCode,
      state,
      setAsPickupAddress,
      setAsReturnAddress,
      address,
    } = data;
    items[data.index] = {
      id,
      fullName,
      phoneNumber,
      addressLine1,
      addressLine2,
      postalCode,
      state,
      setAsPickupAddress,
      setAsReturnAddress,
      address,
    };
    console.log(items, "34567dfghj");
    setShippingInfo(items);
  };

  const deleteConsumerShippingAddress = (index1) => {
    setShippingInfo(shippingInfo.filter((item, index) => index !== index1));
  };

  const back = () => history.goBack();

  const CancelEditing = () => {
    open_dialog(CANCEL_EDIT_MODAL, {
      goBackUrl: "",
    });
  };

  const cancelEdit = () => {
    setEdit(true);
    setEditConsumerDetail(consumerDetail);
  };
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };

  return (
    <React.Fragment>
      <div className="container_body container-fluid">
        <div className="card p-3">
          <div className="row">
            <h6 className="mx-4">PROFILE PICTURE</h6>
          </div>
          <div className="row my-2">
            <div className="col d-flex justify-content-between align-items-center mx-1">
              <div className="mx-2 d-flex align-items-center">
                <label htmlFor={!id ? "profileImage" : ""}>
                  <Image
                    src={profileImage || "/icons/upload_icon.svg"}
                    alt="upload"
                    className={`pointer upload_image_label object-fit-cover ${
                      profileImage ? "rounded-circle" : ""
                    }`}
                  />
                </label>
                {id ? (
                  <p className="mx-3">{consumerDetail.consumerName}</p>
                ) : (
                  ""
                )}
              </div>
              <div className=" d-flex align-items-center  ">
                <InputField
                  accept="image/*"
                  name="profileImage"
                  id="profileImage"
                  // value={profileImage}
                  className="d-none justify-content-center align-item-center"
                  onChange={handleSelectFile}
                  multiple={false}
                  type="file"
                />
                {id ? (
                  <label
                    className="col-2 d-flex justify-content-center p-0 btn border rounded px-5 py-2"
                    htmlFor="profileImage"
                  >
                    Change
                  </label>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <br />
        {id ? (
          <>
            {edit ? (
              <div className="card p-3">
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-2 text-muted text-nowrap d-flex justify-content-end">
                    Username
                  </div>
                  <div className="col-5  text-nowrap d-flex p-0 ">
                    {editConsumerDetail.consumerName}
                  </div>
                  <div className="col-2"></div>
                  <div className="col-2 d-flex justify-content-end p-0 ">
                    <button
                      className="btn border rounded px-4 mr-3"
                      onClick={() => setEdit(false)}
                    >
                      Edit
                    </button>
                  </div>
                </div>

                <div className="row my-2">
                  <div className="col-1"></div>
                  <div className="col-2 text-muted text-nowrap d-flex justify-content-end">
                    Phone Number
                  </div>
                  <div className="col-5  text-nowrap d-flex p-0 ">
                    {`********${String(consumerDetail.phoneNumber).slice(-2)}`}
                  </div>
                  <div className="col-2"></div>
                </div>

                <div className="row my-2 ">
                  <div className="col-1"></div>
                  <div className="col-2 text-muted text-nowrap d-flex justify-content-end">
                    Email
                  </div>
                  <div className="col-5  text-nowrap d-flex p-0 ">
                    {consumerDetail.email.slice(0, 2) +
                      "**********" +
                      consumerDetail.email.slice(
                        consumerDetail.email.indexOf("@")
                      )}
                    {/* // {consumerDetail.email} */}
                  </div>
                  <div className="col-2"></div>
                </div>

                <div className="row my-2 ">
                  <div className="col-1"></div>
                  <div className="col-2 text-muted text-nowrap d-flex justify-content-end">
                    Gender
                  </div>
                  <div className="col-5  text-nowrap d-flex p-0 ">
                    {consumerDetail.gender}
                  </div>
                  <div className="col-2"></div>
                </div>

                <div className="row my-2 ">
                  <div className="col-1"></div>
                  <div className="col-2 text-muted text-nowrap d-flex justify-content-end">
                    Date of Birth
                  </div>
                  <div className="col-5  text-nowrap d-flex p-0 ">
                    {consumerDetail.dateOfBirth}
                  </div>
                  <div className="col-2"></div>
                </div>
              </div>
            ) : (
              <div className="card p-3 d-flex justify-content-center col-12 ">
                <div className="row my-2 ">
                  <h6 className="mx-4">DETAILS OF CONSUMER</h6>
                </div>
                <div className="col-12 row my-2 d-flex">
                  <label
                    htmlFor="consumerName"
                    className="col-3 my-auto text-right large imp"
                  >
                    UserName
                  </label>
                  <div className="col-7 d-flex justify-content-between">
                    <InputField
                      type="text"
                      name="consumerName"
                      id="consumerName"
                      value={editConsumerDetail.consumerName || "N/A"}
                      onChange={onChangeConsumerDetail}
                      className={"w-100"}
                      formSubmitted={formSubmitted}
                      placeholder="input"
                    />
                  </div>
                  <p
                    style={{ marginLeft: "27%", fontSize: "12px" }}
                    className="text-danger "
                  >
                    {errorList.consumerName}
                  </p>
                </div>

                <div className="col-12 row my-2">
                  <label
                    htmlFor="phoneNumber"
                    className="col-3 my-auto text-right large imp"
                  >
                    Phone Number
                  </label>
                  <div className="col-7 d-flex">
                    <InputField
                      type="text"
                      onKeyPress={(e) => handleKeyPress(e)}
                      name="phoneNumber"
                      id="phoneNumber"
                      value={editConsumerDetail.phoneNumber}
                      onChange={onChangeConsumerDetail}
                      className={"w-100"}
                      formSubmitted={formSubmitted}
                      placeholder="input"
                    />
                  </div>
                </div>
                <div className="col-12 row my-2">
                  <label
                    htmlFor="email"
                    className="col-3 my-auto text-right large imp"
                  >
                    Email
                  </label>
                  <div className="col-7 d-flex">
                    <InputField
                      type="text"
                      name="email"
                      id="email"
                      value={editConsumerDetail.email}
                      onChange={onChangeConsumerDetail}
                      className={"w-100"}
                      formSubmitted={formSubmitted}
                      placeholder="input"
                    />
                  </div>
                </div>
                <div className="col-12 row my-2">
                  <label
                    htmlFor="gender"
                    className="col-3 my-auto text-right large imp"
                  >
                    Gender
                  </label>
                  <div className="col-7 d-flex mt-2 ">
                    <RadioButtonsGroup
                      options={consumerGenderOptions}
                      id="gender"
                      name="gender"
                      value={editConsumerDetail.gender}
                      onChange={(e) =>
                        setEditConsumerDetail({
                          ...consumerDetail,
                          gender: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-12 row my-2">
                  <label
                    htmlFor="dateOfBirth"
                    className="col-3 my-auto text-right large imp"
                  >
                    Date of Birth
                  </label>
                  <div className="col-4 d-flex ">
                    <InputField
                      type="date"
                      className="form-control"
                      name="dateOfBirth"
                      id="dateOfBirth"
                      value={editConsumerDetail.dateOfBirth}
                      onChange={onChangeConsumerDetail}
                      formSubmitted={formSubmitted}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-end px-2">
                  <button
                    className="btn border rounded mr-3 px-4"
                    onClick={() => cancelEdit()}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-dark px-4"
                    onClick={() => saveEditConsumerDetail("consumerBasicData")}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
            <div className="card p-3 ">
              <div className="row">
                <div className="col-12">
                  <hr />
                </div>
              </div>
              <div className="row my-3">
                <div className="col-1"></div>
                {!resetPassword ? (
                  <>
                    <div className="col-2 text-muted text-nowrap d-flex justify-content-end">
                      Password
                    </div>
                    <div className="col-5  text-nowrap d-flex p-0 ">
                      ************
                    </div>
                    <div className="col-2"></div>
                    <div className="col-2 d-flex justify-content-end  ">
                      <button
                        className="btn border rounded px-4"
                        onClick={() => setResetPassword(true)}
                      >
                        Reset
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col-12  d-flex justify-content-center">
                      <div className="col-8 row mx-2">
                        <div className="col-12 row my-2">
                          <label
                            htmlFor="password"
                            className="col-4 my-auto text-right large imp"
                          >
                            New Password
                          </label>
                          <div className="col-7 d-flex">
                            <InputField
                              type="text"
                              name="password"
                              id="password"
                              value={null}
                              onChange={onChangeConsumerDetail}
                              className={"w-100"}
                              formSubmitted={formSubmitted}
                              placeholder="input"
                            />
                          </div>
                        </div>
                        <div className="col-12 row my-2">
                          <label
                            htmlFor="confirmPassword"
                            className="col-4 my-auto text-right large imp"
                          >
                            {" "}
                            Confirm
                            <br />
                            New Password
                          </label>
                          <div className="col-7 d-flex">
                            <InputField
                              type="password"
                              name="confirmPassword"
                              id="confirmPassword"
                              value={null}
                              onChange={onChangeConsumerDetail}
                              className={"w-100"}
                              formSubmitted={formSubmitted}
                              placeholder="input"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end h-25 ml-xl-5">
                        <button
                          className="btn border rounded mr-3 px-4"
                          onClick={() => setResetPassword(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-dark px-4"
                          onClick={() => saveEditConsumerDetail("password")}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="card p-3">
            <div className="row">
              <h6 className="mx-4">DETAILS OF CONSUMER</h6>
            </div>
            <div className="col-12 row my-2">
              <label
                htmlFor="consumerName"
                className="col-3 my-auto text-right large imp"
              >
                UserName
              </label>
              <div className="col-7 d-flex justify-content-between">
                <InputField
                  type="text"
                  name="consumerName"
                  id="consumerName"
                  value={consumerDetail.consumerName}
                  onChange={onChangeConsumerDetail}
                  className={"w-100"}
                  formSubmitted={formSubmitted}
                  placeholder="input"
                />
              </div>
              <p
                style={{ marginLeft: "27%", fontSize: "12px" }}
                className="text-danger "
              >
                {errorList.consumerName}
              </p>
            </div>
            <div className="col-12 row my-2">
              <label
                htmlFor="phoneNumber"
                className="col-3 my-auto text-right large imp"
              >
                Phone Number
              </label>
              <div className="col-7 d-flex">
                <InputField
                  type="text"
                  onKeyPress={(e) => handleKeyPress(e)}
                  name="phoneNumber"
                  id="phoneNumber"
                  value={consumerDetail.phoneNumber}
                  onChange={onChangeConsumerDetail}
                  className={"w-100"}
                  formSubmitted={formSubmitted}
                  placeholder="input"
                />
              </div>
              <p
                style={{ marginLeft: "27%", fontSize: "12px" }}
                className="text-danger "
              >
                {errorList.phoneNumber}
              </p>
            </div>
            <div className="col-12 row my-2">
              <label
                htmlFor="email"
                className="col-3 my-auto text-right large imp"
              >
                Email
              </label>
              <div className="col-7 d-flex">
                <InputField
                  type="text"
                  name="email"
                  id="email"
                  value={consumerDetail.email}
                  onChange={onChangeConsumerDetail}
                  className={"w-100"}
                  formSubmitted={formSubmitted}
                  placeholder="input"
                />
              </div>
              <p
                style={{ marginLeft: "27%", fontSize: "12px" }}
                className="text-danger "
              >
                {errorList.email}
              </p>
            </div>
            <div className="col-12 row my-2">
              <label
                htmlFor="gender"
                className="col-3 my-auto text-right large imp"
              >
                Gender
              </label>
              <div className="col-7 d-flex mt-2 ">
                <RadioButtonsGroup
                  options={consumerGenderOptions}
                  id="gender"
                  name="gender"
                  value={consumerDetail.gender}
                  onChange={(e) =>
                    setConsumerDetail({
                      ...consumerDetail,
                      gender: e.target.value,
                    })
                  }
                />
              </div>
              <p
                style={{ marginLeft: "27%", fontSize: "12px" }}
                className="text-danger "
              >
                {errorList.gender}
              </p>
            </div>
            <div className="col-12 row my-2">
              <label
                htmlFor="dateOfBirth"
                className="col-3 my-auto text-right large imp"
              >
                Date of Birth
              </label>
              <div className="col-4 d-flex ">
                <InputField
                  type="date"
                  className="form-control"
                  name="dateOfBirth"
                  id="dateOfBirth"
                  value={consumerDetail.dateOfBirth}
                  onChange={onChangeConsumerDetail}
                  formSubmitted={formSubmitted}
                />
              </div>
              <p
                style={{ marginLeft: "27%", fontSize: "12px" }}
                className="text-danger "
              >
                {errorList.dateOfBirth}
              </p>

              <div className="col-12 row my-2">
                <label
                  htmlFor="password"
                  className="col-3 my-auto text-right large imp"
                >
                  Password
                </label>
                <div className="col-7 d-flex">
                  <InputField
                    type="text"
                    name="password"
                    id="password"
                    value={consumerDetail.password}
                    onChange={onChangeConsumerDetail}
                    className={"w-100"}
                    formSubmitted={formSubmitted}
                    placeholder="input"
                  />
                </div>
                <p
                  style={{ marginLeft: "27%", fontSize: "12px" }}
                  className="text-danger "
                >
                  {errorList.password}
                </p>
              </div>
              <div className="col-12 row my-2">
                <label
                  htmlFor="confirmPassword"
                  className="col-3 my-auto text-right large imp"
                >
                  Confirm Password
                </label>
                <div className="col-7 d-flex">
                  <InputField
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    value={consumerDetail.confirmPassword}
                    onChange={onChangeConsumerDetail}
                    className={"w-100"}
                    formSubmitted={formSubmitted}
                    placeholder="input"
                  />
                </div>
                <p
                  style={{ marginLeft: "27%", fontSize: "12px" }}
                  className="text-danger "
                >
                  {errorList.confirmPassword}
                </p>
              </div>
            </div>
          </div>
        )}
        <br />
        <br />
        <div className="card p-3 ">
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 className="m-0 ">
                <label className="m-0 imp"></label>
                BANK ACCOUNT INFORMATION
              </h6>
              <label className="text-muted p-1 font-size-account">
                Manage consumer's bank account
              </label>
            </div>
            <div className="">
              <button
                className="btn btn-dark px-2"
                onClick={handleAddConsumerBankAccount}
              >
                + Add Bank Account
              </button>
            </div>
          </div>
          {bankInfo?.map((item, index) => (
            <div key={index} className="col-12 row">
              <hr className="w-100" />
              <div className="col-10">
                <div>
                  <label className="col-3 my-auto small text-right text-muted">
                    Bank Name
                  </label>
                  <label className="col-9 my-auto small">{item.bankName}</label>
                </div>
                <div>
                  <label className="col-3 my-auto small text-right text-muted">
                    Account No.
                  </label>
                  <label className="col-9 my-auto small">
                    {item.bankAccountNo}
                  </label>
                </div>
                <div>
                  <label className="col-3 my-auto small text-right text-muted">
                    Beneficiary Name
                  </label>
                  <label className="col-9 my-auto small">
                    {item.beneciaryName}
                  </label>
                </div>
              </div>
              <div className="col-2 d-flex">
                <div
                  className="text-info"
                  onClick={() =>
                    editConsumerBankAccount(
                      item.id,
                      item.bankName,
                      item.bankAccountNo,
                      item.beneciaryName,
                      index
                    )
                  }
                >
                  Edit
                </div>
                <div className="text-info mx-2">|</div>
                <div
                  className="text-info"
                  onClick={() => deleteConsumerBankAccount(index)}
                >
                  Delete
                </div>
              </div>
            </div>
          ))}
        </div>
        <br />

        <div className="card p-3">
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 className="m-0">SHIPPING INFORMATION</h6>
              <label className="text-muted  font-size-account">
                Manage pickup and return address
              </label>
            </div>
            <div className="">
              <button
                onClick={handleAddShippingAddress}
                className="btn btn-dark px-2"
              >
                + Add New Address
              </button>
            </div>
          </div>
          {shippingInfo?.map((item, index) => (
            <div key={index} className="col-12 row">
              <hr className="w-100" />
              <div className="col-10">
                <div>
                  <label className="col-3 my-auto small text-right text-muted">
                    Full Name
                  </label>
                  <label className="col-9 my-auto small">
                    {item.fullName}
                    {item.setAsPickupAddress && (
                      <span className="px-3 text-danger">Pickup Address</span>
                    )}
                    {item.setAsReturnAddress && (
                      <span className="px-3 text-success">Return Address</span>
                    )}
                  </label>
                </div>
                <div>
                  <label className="col-3 my-auto small text-right text-muted">
                    Phone Number
                  </label>
                  <label className="col-9 my-auto small">
                    {item.phoneNumber}
                  </label>
                </div>
                <div>
                  <label className="col-3 my-auto small text-right text-muted">
                    Address
                  </label>
                  <label className="col-9 my-auto small">{item.address}</label>
                </div>
              </div>
              <div className="col-2 d-flex">
                <div
                  className="text-info"
                  onClick={() =>
                    editConsumerShippingAddress(
                      item.id,
                      item.fullName,
                      item.phoneNumber,
                      item.addressLine1,
                      item.addressLine2,
                      item.postalCode,
                      item.state,
                      item.setAsPickupAddress,
                      item.setAsReturnAddress,
                      item.address,
                      index
                    )
                  }
                >
                  Edit
                </div>
                <div className="text-info mx-2">|</div>
                <div
                  className="text-info"
                  onClick={() => deleteConsumerShippingAddress(index)}
                >
                  Delete
                </div>
              </div>
            </div>
          ))}
        </div>
        <br />
        <br />
        <footer className="card p-3">
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-outline-dark mx-2"
              onClick={CancelEditing}
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => onSubmitAddconsumer()}
            >
              {id ? "Save" : "Create"}
            </button>
          </div>
        </footer>
        <br />
      </div>
    </React.Fragment>
  );
};

export default AddConsumerAccount;
