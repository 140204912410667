import {
    Get,
    GetWithToken,
    PatchWithToken,
    PostWithToken,
    PutWithToken,
  } from "../../lib/request";

export const getById = (id) => {
    return async function (dispatch) {
        const result = await GetWithToken(`/sendbird/users?user_id=${id}`);
        console.log(result);
        return result;
    };
}

export const addUser = (payload) => {
    return async function (dispatch) {
        const result = await PostWithToken(`/sendbird/users`, payload);
        return result;
    };
}

export const updateUser = async (payload) => {
    return await PutWithToken(`/sendbird/user`, payload);
}