import {
  DeleteWithToken,
  GetWithToken,
  GetWithTokenParams,
  PatchWithToken,
  PostWithToken,
  PutWithToken,
} from "../../../lib/request";

export const v2GetAllPromoSlotByMerchant = () => {
  return async function (dispatch) {
    const result = await GetWithToken(`/v2/merchant/flashdeal/slot`);
    return result;
  };
};
// done
export const v2GetTimeSLotBySlotIdByMerchant = (id, timeSlotDate) => {
  console.log(timeSlotDate);
  let url = `/v2/merchant/flashdeal/time-slot/slotid/${id}`;
  if (timeSlotDate) {
    url = url + `?timeSlotDate=${timeSlotDate}`;
  }
  return async function (dispatch) {
    const result = await GetWithTokenParams(url);
    return result;
  };
};

//  undone
export const v2CreateFlashSaleProductByMerchant = (data) => {
  // /v2/merchant/flashdeal/flash-sale-product/timeslot/:id
  return async function (dispatch) {
    const result = await PostWithToken(
      `/v2/merchant/flashdeal/flash-sale-product`,
      data
    );
    console.log(result, "result from action");
    return result;
  };
};

export const v2GetAllProductByMerchantId = (id, data) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/v2/merchant/flashdeal/product/merchant`
    );
    return result;
  };
};

export const v2UpdateFlashSaleProductByTimeSlotIDByMercahnt = (
  timeslotID,
  data
) => {
  return async function (dispatch) {
    return await PutWithToken(
      `/v2/merchant/flashdeal/flash-sale-product/timeslot/${timeslotID}`,
      data
    );
  };
};

export const v2GetAllFlashSaleProductByTimeSlotIdByMerchant = (id) => {
  return async function (dispatch) {
    const result = await GetWithTokenParams(
      `/v2/merchant/flashdeal/flash-sale-product/timeslot/${id}`
    );
    return result;
  };
};

export const v2GetAllGroupDealsProduct = (data) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/v2/merchant/groupdeal/product?groupDealsId=${data.groupDealsId}`
    );
    return result;
  };
};

export const v2GetGroupDealsProductById = (data) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/v2/merchant/groupdeal/product/${data}`);
    return result;
  };
};

export const v2UpdateGroupDealsProduct = (data) => {
  return async function (dispatch) {
    const result = await PatchWithToken(`/v2/merchant/groupdeal/product`, data);
    return result;
  };
};

export const v2DeleteGroupDealsProduct = (id) => {
  return async function (dispatch) {
    return await DeleteWithToken(`/v2/merchant/groupdeal/product/${id}`);
  };
};

export const v2GetAllProductMasterGroupDealsByMerchantId = (
  id,
  groupDealsId
) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/v2/merchant/groupdeal/product-master?merchantId=${id}&groupDealsId=${groupDealsId}`
    );
    return result;
  };
};

export const v2CreateGroupDealsProduct = (data) => {
  return async function (dispatch) {
    const result = await PostWithToken(`/v2/merchant/groupdeal/product`, data);
    return result;
  };
};
