import { IconButton } from "@mui/material";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../components/Images/SvgIcon";
import "../../static/css/marketingCss/marketing.css"
import { breadcrumbAction } from "../../redux/actions/config.action";
import { AllowAccess } from "../../lib/global";



function Sales_promotions() {
    AllowAccess(["MERCHANT"]);
    let { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        const breadcrumb = [
            { label: id ? "sales Promotion " : "Sales Promotion", url: "/merchant/promotion/sales_promotion" },
        ];
        dispatch(breadcrumbAction(breadcrumb));
    }, []);
    const history = useHistory()
    return (
        <React.Fragment>
            {/* <div className="card m-3 p-3"> */}
                <h5 className="mx-4">UU2 EVENTS</h5>
                <div className="row mx-4">
                    <div className="card col-4 p-2 ">
                        <div className="p-3 rounded marketing_Promo_Official_Card" onClick={() => history.push("/merchant/promotion/voucher-listing/voucher_listing")}>
                            <div>
                                <p>Products Campaign</p>
                                <p className="text-secondary">Join UU2 Official to reach more shoppers.</p>
                            </div>

                            <div className="d-flex justify-content-end w-100 pointer" >
                                <img
                                    height={"40px"}
                                    src="/icons/next_arrow.png"
                                    alt="view icon"

                                />
                            </div>
                        </div>
                    </div>

                    <div className="card col-4 mx-4 p-2 ">
                        <div className="p-3 rounded marketing_Promo_Official_Card">
                            <div>
                                <p>Voucher Campaign</p>
                                <p className="text-secondary">Join UU2 Official to reach more shoppers</p>
                            </div>

                            <div className="d-flex justify-content-end w-100 pointer" >
                                <img
                                    height={"40px"}
                                    src="/icons/next_arrow.png"
                                    alt="view icon"

                                />
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
            {/* <div className="card m-3 p-3"> */}
                <h5 className="mx-4 ">SALAS PROMOTION</h5>
                <div className="row  mx-4">
                    <div className="card col-4 p-2">
                        <div className="p-3 rounded marketing_Promo_Official_Card">
                            <div>
                                <p>Live Promote</p>
                                <p className="text-secondary">Testing</p>
                            </div>

                            <div className="d-flex justify-content-end w-100 pointer" >
                                <img
                                    height={"40px"}
                                    src="/icons/next_arrow.png"
                                    alt="view icon"

                                />
                            </div>
                        </div>
                    </div>
                    <div className="card col-4 mx-4 p-2">
                        <div className=" p-3 rounded marketing_Promo_Official_Card">
                            <div>
                                <p>Shop Voucher</p>
                                <p className="text-secondary">Offier buyer with discount price checkout with vouchers.</p>
                            </div>

                            <div className="d-flex justify-content-end w-100 pointer" >
                                <img
                                    height={"40px"}
                                    src="/icons/next_arrow.png"
                                    alt="view icon"

                                />
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}
        </React.Fragment>
    )
}

export default Sales_promotions;