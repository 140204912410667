import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TimelineDot from "@mui/lab/TimelineDot";
import { makeStyles } from "@mui/styles";
import SelectDropdown from "../../../components/select";

import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import Image from "../../../components/Images/Image";
import { orderStatuses } from "../../../shared/language";
import {
  getOrderById,
  updateOrderShippingStatusById,
} from "../../../redux/actions/order.action";
import {
  getOrderReturnById,
  patchOrderReturnById,
} from "../../../redux/actions/finance.action";
import { useDispatch } from "react-redux";
import { SHOW_TRANSACTION_HISTORY } from "../../../lib/constant";
import {
  AllowAccess,
  open_dialog,
  showToast,
  startLoader,
  stopLoader,
} from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getLocalStorage } from "../../../lib/session";
import CustomizedSteppers from "../../../components/stepper/stepper";
import SvgIcon from "../../../components/Images/SvgIcon";
import ChatLog from "./ChatLog";
import decimalFormat from "../../../helpers/decimal.format";
import formatDate from "../../../helpers/date.format";
import {
  getOrderReturnByIdByMerchant,
  patchOrderReturnByIdByMerchant,
} from "../../../redux/actions/merchant/order.action";

const useStyles = makeStyles({
  timelineitem: {
    // minHeight:"0px"
  },
  timelineContentOppositeContainer: {
    maxWidth: "0px",
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  timing: {
    marginTop: "-5px",
    fontSize: "0.7rem",
  },
  timelineContent: {
    display: "inline-block",
    transform: "rotate(-90deg)",
    textAlign: "center",
    minWidth: 50,
  },
  timelineIcon: {
    transform: "rotate(-90deg)",
  },
  mainText: {
    fontSize: "0.9rem",
  },
  dot: {
    padding: "2px  !important",

    margin: "8.5px 4px !important",
  },
});

const order_status_names = {
  orderPlaced: "Order Placed",
  orderPaid: "Order Paid",
  prepairingToShipped: "Preparing To Ship",
  orderShipped: "Order Shipped",
  orderDelivered: "Order Delivered",
};

const orderStatusOptions_Return_Refund = [
  "PROCESSING",
  "UNDER_REVIEW",
  "MERCHANT_TO_RESPOND",
  "UU2_TO_RESPOND",
  "REFUNDED",
];

function ReturnOverView() {
  AllowAccess(["SUPERADMIN","MERCHANT"]);
  const [fullData, setFullData] = useState({});
  const [orderShippingStatus, setOrderShippingStatus] = useState("");
  const [editOrderShipped, setEditOrderShipped] = useState(false);
  const [timeline, setTimeline] = useState([]);
  const [lastOrderUpdate, setLastOrderUpdate] = useState({});

  const [showStatusChange, setShowStatusChange] = useState(false);
  const changedStatus = useRef();
  const [submitStatusChange, setSubmitStatusChange] = useState(false);

  const [showChat, setShowChat] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const { id } = useParams();
  const param = useParams();

  const queryParams = new URLSearchParams(window.location.search);
  const productId = queryParams.get("productId");
  const role = getLocalStorage("role");
  const storeId = role == "MERCHANT" ? getLocalStorage("storeId") : null;
  // console.log(productId, "oooooo"); //pizza

  useEffect(() => {
    var breadcrumb = [];
    if (param?.merchantId && param?.merchantName) {
      breadcrumb = [
        { label: "Merchant Profile", url: "/merchant-center/profiles" },
        {
          label: param.merchantName,
          url: `/merchant-center/profiles/${param.merchantId}/${param.merchantName}`,
        },
        { label: `Order ID ${id}`, url: null },
      ];
    } else if (param?.storeId && getLocalStorage("role") == "MERCHANT") {
      breadcrumb = [
        { label: "My Income", url: null },
        { label: `View Order`, url: null },
      ];
    } else {
      const url =
        getLocalStorage("role") == "MERCHANT"
          ? "/merchant/orders"
          : "/orders/all-orders";
      breadcrumb = [
        { label: "Return/Refund", url: "/orders/return-and-refund" },
        { label: `Order ID ${id}`, url: null },
      ];
    }
    startLoader();
    dispatch(breadcrumbAction(breadcrumb));
    if (id) {
      const response =
        getLocalStorage("role") == "SUPERADMIN"
          ? dispatch(getOrderReturnById(id))
          : dispatch(getOrderReturnByIdByMerchant(id));
      response
        .then(async (data) => {
          // console.log(data?.data?.data[0], "data?.data?.data");
          if (data && data.data && data.data.data) {
            if (role == "MERCHANT" && data?.data?.data[0].storeId != storeId) {
              history.push("/404");
            }
            setFullData(data?.data?.data[0]);
            // console.log(data?.data?.data[0], "data?.data?.data");
            if (data?.data?.data[0].order.shippingStatus) {
              const tempTimeline = convertToTimeline(
                await data?.data?.data[0].order.shippingStatus?.filter(
                  (item) => item.date != null
                )
              );
              setTimeline(tempTimeline);
              console.log(tempTimeline, "timeline");
              console.log(timeline, "timeline");
            }

            const latest = data?.data?.data[0].order.shippingStatus
              ?.filter((item) => item.date != null)
              .splice(-1)[0];
            setLastOrderUpdate(latest);
            setOrderShippingStatus({
              value: latest?.status,
              label: order_status_names[latest?.status],
            });
            setTimeout(() => {
              stopLoader();
            }, 1000);
          }
        })
        .catch((error) => {
          setTimeout(() => {
            console.log(error);
            stopLoader();
          }, 1000);
        });
    }
  }, [id]);

  const convertToTimeline = (data) => {
    let temp = {};
    data.forEach((item) => {
      if (item.status == "orderPaid") {
        temp.paidDate = item.date;
      } else if (item.status == "prepairingToShipped") {
        temp.pickupDate = item.date;
      } else if (item.status == "orderShipped") {
        temp.onDeliveryDate = item.date;
      } else if (item.status == "orderDelivered") {
        temp.deliveredDate = item.date;
      }
    });
    return temp;
  };

  const stringBreaker = (data, breakChr) => {
    return data.replaceAll(breakChr, " ");
  };

  const updateOrderStatusFun = () => {
    let status = changedStatus?.current;
    startLoader();
    const update =
      getLocalStorage("role") == "SUPERADMIN"
        ? dispatch(patchOrderReturnById(id, status))
        : dispatch(patchOrderReturnByIdByMerchant(id, status));
    update
      .then((data) => {
        // console.log(data, "ooooooooooooooooooooooooooooooooooooooo");
        if (id) {
          const response =
            getLocalStorage("role") == "SUPERADMIN"
              ? dispatch(getOrderReturnById(id))
              : dispatch(getOrderReturnByIdByMerchant(id));
          response
            .then((data) => {
              // console.log(data?.data?.data[0], "data?.data?.data");
              if (data && data.data && data.data.data) {
                setFullData(data?.data?.data[0]);
                setTimeline(
                  data?.data?.data?.shippingStatus?.map((item) => item.date)
                );
                const latest = data?.data?.data?.shippingStatus
                  ?.filter((item) => item.date !== null)
                  .splice(-1)[0];
                // console.log(latest, "jjjjjjjjjj");
                setLastOrderUpdate(latest);
                setOrderShippingStatus({
                  value: latest?.status,
                  label: order_status_names[latest?.status],
                });
                setTimeout(() => {
                  stopLoader();
                  showToast("Status changed successfully", "success");
                }, 1000);
              }

              // console.log(
              //   data?.data?.data,
              //   "lllllllllllllllllllllllllllllllllllllllll"
              // );
            })
            .catch((error) => {
              setTimeout(() => {
                console.log(error);
                stopLoader();
                showToast(error.message, "error");
              }, 1000);
            });
        }
      })
      .catch((error) => {
        setTimeout(() => {
          console.log(error);
          stopLoader();
          showToast(error.message, "error");
        }, 1000);
      });
  };

  const changeShippingStatusFun = async () => {
    const data = {
      shippingStatus: orderShippingStatus?.value,
    };
    dispatch(updateOrderShippingStatusById(id, data))
      .then((data) => {
        // console.log(data);
        dispatch(getOrderById(id))
          .then((data) => {
            if (data && data.data && data.data.data) {
              setFullData(data?.data?.data);
              setTimeline(
                data?.data?.data?.shippingStatus.map((item) => item.date)
              );
              const latest = data.data.data.shippingStatus
                .filter((item) => item.date !== null)
                .splice(-1)[0];
              setLastOrderUpdate(latest);
              setOrderShippingStatus({
                value: latest.status,
                label: order_status_names[latest.status],
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    setEditOrderShipped(false);
  };

  const handleShowTrasactionHistory = () => {
    open_dialog(SHOW_TRANSACTION_HISTORY, {});
  };

  const editStatusSave = () => {
    setShowStatusChange(false);
    updateOrderStatusFun();
    if (fullData?.product?.productType == "physical") {
      setFullData({ ...fullData, status: changedStatus });
    } else {
      setFullData({ ...fullData, NpStatus: changedStatus.current });
    }
  };

  const showChatFun = () => {
    setShowChat(false);
    return;
  };
  const classes = useStyles();

  const updateReturnandRefundStatus = (status) => {
    startLoader();
    dispatch(patchOrderReturnById(id, status))
      .then((data) => {
        // console.log(data, "ooooooooooooooooooooooooooooooooooooooo");
        if (id) {
          dispatch(getOrderReturnById(id))
            .then((data) => {
              // console.log(data?.data?.data[0], "data?.data?.data");
              if (data && data.data && data.data.data) {
                setFullData(data?.data?.data[0]);
                setTimeline(
                  data?.data?.data?.shippingStatus?.map((item) => item.date)
                );
                const latest = data?.data?.data?.shippingStatus
                  ?.filter((item) => item.date !== null)
                  .splice(-1)[0];
                setLastOrderUpdate(latest);
                setOrderShippingStatus({
                  value: latest?.status,
                  label: order_status_names[latest?.status],
                });
                setTimeout(() => {
                  stopLoader();
                  showToast("Status changed successfully", "success");
                }, 1000);
              }
            })
            .catch((error) => {
              setTimeout(() => {
                console.log(error);
                stopLoader();
                showToast(error.message, "error");
              }, 1000);
            });
        }
      })
      .catch((error) => {
        setTimeout(() => {
          console.log(error);
          stopLoader();
          showToast(error.message, "error");
        }, 1000);
      });
  };

  const unitPrice = () => {
    return Number.parseFloat(
      fullData?.order?.unitPrice || fullData?.order?.price || 0
    );
  };

  const quantity = () => {
    return Number.parseFloat(fullData?.order?.quantity || 1);
  };

  const subTotal = () => {
    return unitPrice() * quantity();
  };

  const uu2Voucher = () => {
    if (
      fullData?.order?.voucherUsage &&
      fullData?.order?.voucherUsage?.voucherType == "uu2"
    ) {
      return fullData?.order?.voucherUsage?.amount || 0;
    } else {
      return 0;
    }
  };

  const uu2VoucherCode = () => {
    if (
      fullData?.order?.voucherUsage &&
      fullData?.order?.voucherUsage?.voucherType == "uu2"
    ) {
      return "(" + (fullData?.order?.voucherUsage?.voucher?.code || "") + ")";
    } else {
      return "";
    }
  };

  const merchantVoucher = () => {
    if (
      fullData?.order?.voucherUsage &&
      fullData?.order?.voucherUsage?.voucherType == "merchant"
    ) {
      return fullData?.order?.voucherUsage?.amount || 0;
    } else {
      return 0;
    }
  };

  const merchantVoucherCode = () => {
    if (
      fullData?.order?.voucherUsage &&
      fullData?.order?.voucherUsage?.voucherType == "merchant"
    ) {
      return "(" + (fullData?.order?.voucherUsage?.voucher?.code || "") + ")";
    } else {
      return "";
    }
  };

  const creditCardPromotion = () => {
    return fullData?.order?.shippingDetails?.creaditDebitCardVoucher || 0;
  };

  const totalDiscount = () => {
    return uu2Voucher() + merchantVoucher() + creditCardPromotion();
  };

  const shippingCharge = () => {
    return (
      fullData?.order?.shippingDetails?.shippingCharge ||
      fullData?.shippingDetails?.feeAndCharges ||
      0
    );
    // return Number.parseFloat(fullData?.shippingDetails?.shippingCharge ||
    //   fullData?.trackingInfo?.price ||
    //   0)
  };

  const totalIncome = () => {
    return subTotal() - (totalDiscount() + feesAndCharge());
  };

  const buyerPaid = () => {
    return Number.parseFloat(fullData?.order?.finalAmount || subTotal() || 0);
  };

  const feesAndCharge = () => {
    return (
      ((buyerPaid() - shippingCharge()) * 4.6) / 100 +
      (buyerPaid() * 2.12) / 100
    );
  };

  const merchantIncome = () => {
    return subTotal() - (feesAndCharge() + shippingCharge());
  };

  const merchantdiseSubTotal = () => {
    return subTotal() - totalDiscount();
  };

  // console.log(fullData, "bbbbbbbbbbbbbbbbbbbb");

  return (
    <React.Fragment>
      <div className="d-flex  position-relative  mx-4 ">
        {showChat ? (
          <ChatLog showChatFun={showChatFun} />
        ) : (
          <div className="col-8 pr-3">
            <div className="py-3">
              <div className="d-flex box justify-content-between mt-1 ">
                HISTORY
              </div>
              {/* <div className="card p-3 mt-2 mb-3 fntSz13">
                <div>Waiting for seller to respond</div>
                <div className="text-secondary fntSz12">
                  Seller needs to respond by 11/12/2022, otherwise it will be
                  automatically accepted and refund to buyer.
                </div>
                <div className="text-secondary fntSz12">
                  Seller is able to discuss with buyer for other possible
                  solutions.
                </div>
                <br />
                <div className="d-flex flex-row justify-content-end align-items-center">
                  <button
                    onClick={() =>
                      // history.push("/order/return-refund/chat-log")
                      setShowChat(true)
                    }
                    className="fntSz14 btn btn-danger"
                  >
                    Chat Log
                  </button>
                </div>
              </div> */}
              <div className="card p-3 mt-2 fntSz14">
                <div>
                  <div>Reason from Seller</div>
                  <p className="text-secondary">{fullData?.reason || "N/A"}</p>
                  <div>Reason from Buyer</div>
                  <p className="text-secondary">
                    {fullData?.merchantResponse || "N/A"}
                  </p>
                  <div>Description</div>
                  <p className="text-secondary">
                    {fullData?.Description || "N/A"}
                    {/* Please refund as I order keyboard not this. */}
                  </p>
                  <div>Image</div>
                  <p>
                    <Image
                      src={fullData?.productImages || "/mouse-img.png"}
                      alt={"photo"}
                      className="teamPurchase_page_product_image"
                    />
                  </p>
                  <div>Request Date</div>
                  <p className="text-secondary">
                    {formatDate(
                      fullData?.createdAt,
                      "dddd, YYYY/MM/DD, HH:mm "
                    )}
                  </p>
                  <div>Request Refund Amount</div>
                  <p className="text-danger">
                    RM
                    {decimalFormat(buyerPaid())}
                  </p>
                </div>
              </div>
            </div>

            <div className="p-3 card">
              <div className="d-flex box justify-content-between mt-1">
                HISTORY
              </div>
              <div className="d-flex">
                <div className="mx-2">
                  <TimelineDot className={`text-success ${classes.dot}`} />
                </div>
                <div>
                  <div className={`text-success ${classes.mainText}`}>
                    Buyer request refund
                  </div>
                  <div className={`text-muted ${classes.timing}`}>
                    {formatDate(
                      fullData?.createdAt,
                      "dddd, YYYY/MM/DD, HH:mm "
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />

            <div className="p-3 card">
              {/* {fullData?.product?.productType !== "physical" && ( */}
              <div className="py-1">
                <div className="d-flex box justify-content-between mt-1">
                  LOGISTIC INFORMATION
                </div>
                <div className="card py-3 ">
                  <div className="d-flex position-relative">
                    <div className="col-11 d-flex flex-column justify-content-center  align-items-center">
                      <div style={{ fontSize: "1.4rem", fontWeight: "700" }}>
                        {order_status_names[lastOrderUpdate?.status] || "- - -"}
                      </div>
                      <div>
                        {formatDate(
                          lastOrderUpdate?.date,
                          "dddd MM-DD-YYYY at HH:mma"
                        )}
                      </div>
                    </div>
                    <div className="d-flex mb-3 position-absolute r-0  mr-1 top-0">
                      {!editOrderShipped ? (
                        getLocalStorage("role") == "MERCHANT" ? (
                          ""
                        ) : (
                          <button
                            className=" btn border input-inside-text  "
                            onClick={() => setEditOrderShipped(true)}
                          >
                            Edit
                          </button>
                        )
                      ) : (
                        <div className="mr-2">
                          <button
                            className=" btn border input-inside-text mr-1 "
                            onClick={() => setEditOrderShipped(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className=" btn border btn-dark input-inside-text "
                            // onClick={() => setEditOrderShipped(false)}
                            onClick={() => changeShippingStatusFun()}
                            visible={String(editOrderShipped)}
                          >
                            save
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <br />

                  <CustomizedSteppers timeline={timeline} />

                  <br />

                  <div
                    className="d-flex justify-content-center "
                    visible={String(editOrderShipped)}
                  >
                    <label
                      className={`col-3 text-center m-0 px-2 py-3 ${classes.mainText}`}
                    >
                      Logistic Status
                    </label>
                    <div className=" mt-2 col-9 form-group">
                      <SelectDropdown
                        options={orderStatuses}
                        placeholder="Select status"
                        changedStatus={orderShippingStatus}
                        onChange={setOrderShippingStatus}
                      />
                      <div className={`text-muted ${classes.timing} pt-1`}>
                        *Please note that you are only allowed to edit status if
                        only logistic partner unable to update the status or
                        system error
                      </div>
                    </div>
                  </div>

                  <hr className="mx-3" />
                  <div className="ml-4">
                    <div className="d-flex">
                      <div className="mx-2">
                        <TimelineDot className={`text-muted ${classes.dot}`} />
                      </div>
                      <div>
                        <div className={`text-success ${classes.mainText}`}>
                          Seller is preparing to ship your parcel
                        </div>
                        <div className={`text-muted ${classes.timing}`}>
                          {formatDate(
                            timeline?.paidDate,
                            "dddd, YYYY/MM/DD, HH:mm "
                          )}
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="d-flex">
                      <div className="mx-2">
                        <TimelineDot className={`text-muted ${classes.dot}`} />
                      </div>
                      <div>
                        <div className={`text-success ${classes.mainText}`}>
                          Parcel has been picked up by courie
                        </div>
                        <div className={`text-muted ${classes.timing}`}>
                          {formatDate(
                            timeline?.pickupDate,
                            "dddd, YYYY/MM/DD, HH:mm "
                          )}
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="d-flex">
                      <div className="mx-2">
                        <TimelineDot className={`text-muted ${classes.dot}`} />
                      </div>
                      <div>
                        <div className={`text-success ${classes.mainText}`}>
                          Parcel has On Delivery
                        </div>
                        <div className={`text-muted ${classes.timing}`}>
                          {formatDate(
                            timeline?.onDeliveryDate,
                            "dddd, YYYY/MM/DD, HH:mm "
                          )}
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="d-flex">
                      <div className="mx-2">
                        <TimelineDot className={`text-muted ${classes.dot}`} />
                      </div>
                      <div>
                        <div className={`text-success ${classes.mainText}`}>
                          Parcel has been delivered
                        </div>
                        <div className={`text-muted ${classes.timing}`}>
                          {formatDate(
                            timeline?.deliveredDate,
                            "dddd, YYYY/MM/DD, HH:mm "
                          )}
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
              {/* )} */}
            </div>

            <div className="py-3">
              <div className="d-flex box justify-content-between  mb-2">
                <div>ORDER INFORMATION</div>
                {/* <div
                  className="d-flex align-items-center "
                  role="button"
                  onClick={() => handleViewOrderTrasactionHistory()}
                >
                  <Image
                    src="/trans.png"
                    alt="image"
                    width={18}
                    height={18}
                  />
                  <div className="pl-2">Transaction History</div>
                </div> */}
              </div>
              <div className="card p-3">
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="th_cell"
                          component="th"
                          align="left"
                        >
                          Product
                        </TableCell>
                        <TableCell
                          className="th_cell"
                          component="th"
                        ></TableCell>
                        <TableCell
                          className="th_cell"
                          component="th"
                        ></TableCell>
                        <TableCell
                          className="th_cell"
                          component="th"
                        ></TableCell>
                        <TableCell
                          className="th_cell"
                          component="th"
                        ></TableCell>
                        <TableCell
                          className="th_cell"
                          component="th"
                          align="right"
                        >
                          Price
                        </TableCell>
                        <TableCell
                          className="th_cell "
                          component="th"
                          align="right"
                        >
                          Quantity
                        </TableCell>
                        <TableCell
                          className="th_cell"
                          component="th"
                          align="right"
                        >
                          <div>Subtotal</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {[1].map((row, index) => ( */}
                      <TableRow
                        // key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          className="td_cell"
                          component="td"
                          align="left"
                          colSpan={5}
                        >
                          <div className="d-flex">
                            <Image
                              // src={fullData?.productImages}
                              src={fullData?.productImages || ""}
                              className="order_page_product_image"
                              alt="img"
                            />
                            <div className="px-2">
                              <div>{fullData?.productName || "N/A"}</div>
                              <div className="text-muted">
                                {`variation: ${
                                  fullData?.variation?.name || "N/A"
                                }`}

                                {/* white */}
                              </div>
                            </div>
                          </div>
                        </TableCell>

                        <TableCell
                          className="td_cell"
                          component="td"
                          align="right"
                        >
                          {`${fullData?.currencyName || "RM"}${decimalFormat(
                            unitPrice()
                          )}`}
                        </TableCell>
                        <TableCell
                          className="td_cell "
                          component="td"
                          align="right"
                        >
                          {quantity()}
                        </TableCell>
                        <TableCell
                          className="td_cell"
                          component="td"
                          align="right"
                        >
                          {`${fullData?.currencyName || "RM"}${decimalFormat(
                            subTotal()
                          )}`}
                        </TableCell>
                      </TableRow>
                      {/* ))} */}
                    </TableBody>
                    <TableRow
                      sx={{
                        " td, th": {
                          border: 0,
                          "padding-top": 0,
                          "padding-bottom": 0,
                          "font-size": "1rem",
                          "font-weight": "700",
                        },
                      }}
                    >
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                        colSpan={8}
                      >
                        Total
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{
                        " td, th": { border: 0, "padding-top": 1 },
                      }}
                    >
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                        colSpan={7}
                      >
                        <div>Subtotal :</div>
                        <div>Shipping :</div>
                        <div>Fee & Charges :</div>
                        <div className="py-3">Total Income :</div>
                      </TableCell>
                      <TableCell
                        className="td_cell"
                        component="td"
                        align="right"
                      >
                        <div>
                          {`${fullData?.currencyName || "RM"}${decimalFormat(
                            subTotal()
                          )}`}
                        </div>
                        <div>{`${fullData?.currencyName || "RM"}${decimalFormat(
                          shippingCharge()
                        )}`}</div>
                        <div>{`${fullData?.currencyName || "RM"}${decimalFormat(
                          feesAndCharge()
                        )}`}</div>
                        <div
                          className="py-3 text-success "
                          style={{ fontSize: "1.2rem" }}
                        >
                          {`${fullData?.currencyName || "RM"}${decimalFormat(
                            totalIncome()
                          )}`}
                        </div>
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>
              </div>
            </div>

            <div className="py-1">
              <div className="d-flex box justify-content-between mb-2">
                <div>BUYER PAYMENT</div>
              </div>
              <div className="card p-3">
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className="th_cell"
                          component="th"
                          align="right"
                          colSpan={8}
                        >
                          <div>Subtotal</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          " td, th": {
                            border: 0,
                            "padding-top": "1rem",
                            width: "8rem",
                          },
                        }}
                      >
                        <TableCell
                          className="td_cell"
                          component="td"
                          align="right"
                          colSpan={5}
                        >
                          <div>Merchandise Subtotal :</div>
                          <div>Shipping :</div>
                          <div>UU2 Voucher :</div>
                          <div>Seller Voucher :</div>
                          <div>Credit/Debit Card Promotion :</div>
                          <div className="py-3">Total Income :</div>
                        </TableCell>
                        <TableCell
                          className="td_cell"
                          component="td"
                          align="right"
                        >
                          <div>
                            {`${fullData?.currency || "RM"}${decimalFormat(
                              merchantdiseSubTotal()
                            )}`}
                          </div>
                          <div>
                            {`${fullData?.currency || "RM"}${decimalFormat(
                              shippingCharge()
                            )}`}{" "}
                          </div>
                          <div>
                            {`${fullData?.currency || "RM"}${decimalFormat(
                              uu2Voucher()
                            )}`}{" "}
                          </div>
                          <div className="text-muted">
                            {`${fullData?.currency || "RM"}${decimalFormat(
                              merchantVoucher()
                            )}`}{" "}
                          </div>
                          <div className="text-muted">
                            {`${fullData?.currency || "RM"}${decimalFormat(
                              creditCardPromotion()
                            )}`}
                          </div>
                          <div className="py-3" style={{ fontSize: "1.2rem" }}>
                            {`${fullData?.currency || "RM"}${decimalFormat(
                              totalIncome()
                            )}`}
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>

            <div className="py-2">
              <div className="d-flex box justify-content-between mb-2">
                <div>CONSUMER INFORMATION</div>
              </div>
              <div className="card p-4 ">
                <div className="d-flex justify-content-between">
                  <div className="col-4 p-0">
                    <div className="pb-3">Personal detail</div>
                    <div
                      className="d-flex justifly-content-between pt-1"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <label htmlFor="" className="col-4 text-muted p-0">
                        Name:{" "}
                      </label>
                      <div className="col-8 p-0 text-capitalize">
                        {fullData?.consumer?.consumerName || "N/A"}
                      </div>
                    </div>
                    <div
                      className="d-flex justifly-content-between pt-1 text-capitalize"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <label htmlFor="" className="col-4 text-muted p-0">
                        Email:{" "}
                      </label>
                      <div className="col-8 text-nowrap p-0">
                        {fullData?.consumer?.email || "N/A"}
                      </div>
                    </div>
                    <div
                      className="d-flex justifly-content-between pt-1"
                      style={{ fontSize: "0.8rem" }}
                    >
                      <label htmlFor="" className="col-4 text-muted p-0">
                        Phone:{" "}
                      </label>
                      <div className="col-8 p-0">
                        {fullData?.consumer?.phoneNumber || "N/A"}
                      </div>
                    </div>
                  </div>
                  <div className="col-7 p-0 ">
                    <div className=" p-0 mb-3">
                      <div className="pb-3">Billing Address</div>
                      <div
                        className="d-flex justifly-content-between pt-1"
                        style={{ fontSize: "0.8rem" }}
                      >
                        <label htmlFor="" className="col-2 text-muted p-0">
                          Address:{" "}
                        </label>
                        <div className="col-10 ">
                          {fullData?.order?.billingAddress?.address || "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className=" p-0 mt-2">
                      <div className="pb-3">Shipping Address</div>
                      <div
                        className="d-flex justifly-content-between pt-1"
                        style={{ fontSize: "0.8rem" }}
                      >
                        <label htmlFor="" className="col-2 text-muted p-0">
                          Address:{" "}
                        </label>
                        <div className="col-10 ">
                          {fullData?.order?.shippingAddress?.address || "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="col-4 p-0">
          <div
            className="d-flex box justify-content-between mt-3 position-sticky r-0"
            style={{ right: "0px" }}
          ></div>
          <div
            className=" position-sticky r-0  "
            style={{ top: "0px", right: "0px" }}
          >
            <div className="d-flex justify-content-between">
              <div>OTHER INFORMATION</div>
              {getLocalStorage("role") == "SUPERADMIN" && (
                <div className=" bg-secondry" style={{ height: "25px" }}>
                  <IconButton
                    className="pointer"
                    onClick={() => setShowStatusChange(true)}
                  >
                    <SvgIcon src="/icons/edit_icon.svg" alt="view icon" />
                  </IconButton>
                </div>
                )}
            </div>
            <div className="card p-3  mt-2">
              <div className="mb-3 " style={{ fontSize: "0.8rem" }}>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Status</div>

                  <div
                    className="font-weight-bold text-success"
                    style={{ fontSize: "1rem" }}
                  >
                    {!showStatusChange ? (
                      // fullData?.status || "N/A"
                      stringBreaker(fullData?.status || "N/A", "_")
                    ) : (
                      <>
                        <div className="d-flex justify-content-center align-center">
                          <div
                            className="btn btn-light mr-1"
                            onClick={() => setShowStatusChange(false)}
                          >
                            cancel
                          </div>
                          <div
                            className="btn btn-dark"
                            onClick={editStatusSave}
                          >
                            save
                          </div>
                        </div>
                        <br />
                        <div className="">
                          <select
                            className="btn border input-inside-text "
                            onChange={(e) =>
                              (changedStatus.current = e.target.value)
                            }
                          >
                            <option disabled selected>
                              {/* {fullData?.status || "N/A"} */}
                              {stringBreaker(fullData?.status || "N/A", "_")}
                            </option>
                            {orderStatusOptions_Return_Refund.map(
                              (item, index) =>
                                fullData?.NpStatus !== item && (
                                  <option key={index} value={item}>
                                    {/* {item} */}
                                    {stringBreaker(item, "_")}
                                  </option>
                                )
                            )}
                          </select>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Case ID</div>
                  <div className="">
                    {fullData?.caseId || "N/A"}
                    {/* {fullData?.orderId || "N/A"} */}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Order ID</div>
                  <div className="">
                    {fullData?.orderId || "N/A"}
                    {/* {fullData?.orderId || "N/A"} */}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Transaction ID</div>
                  <div className="">{fullData?.transactionId || "N/A"}</div>
                </div>
              </div>

              <div className="font-weight-bolder py-1">Payment</div>
              <div className="mb-3 " style={{ fontSize: "0.8rem" }}>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Payment Method</div>
                  <div className="">
                    {fullData?.order?.payment?.payment_method?.Payment_Method ||
                      "N/A"}
                    {/* Creadit/Debit Card */}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Payment Date</div>
                  <div className="">
                    {formatDate(
                      fullData?.order?.payment?.createdAt,
                      "DD/MM/YY, HH:mm"
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Ref Id</div>
                  <div className="">
                    {/* Shipping Address */}

                    {fullData?.order?.payment?.RefNo || "N/A"}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Settlement Status</div>
                  <div className="text-capitalize">
                    {fullData?.order?.settlementStatus || "N/A"}
                  </div>
                </div>
              </div>

              <div className="font-weight-bolder py-1">Shipping Details</div>
              <div className="" style={{ fontSize: "0.8rem" }}>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Shipping</div>
                  <div className="">
                    {fullData?.order?.shippingDetails?.status || "N/A"}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Courier</div>
                  <div className="">
                    {fullData?.order?.courierName || "N/A"}
                  </div>
                </div>
                <div className="d-flex justify-content-between py-1">
                  <div className="text-muted">Tracking Number</div>
                  <div className="">
                    {fullData?.order?.trackingInfo?.awb_no || "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className=" p-3 mt-1 card">
              <div className="pb-3 font-weight-bolder">Shipping Address</div>
              <div
                className="d-flex justifly-content-between pt-1"
                style={{ fontSize: "0.8rem" }}
              >
                <label htmlFor="" className="col-2 text-muted p-0">
                  Address:{" "}
                </label>
                <div className="col-10 ">
                  {fullData?.order?.shippingAddress?.address || "N/A"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!showChat ? (
        <footer className="d-flex flex-wrap justify-content-end align-items-center py-3 mt-3 bg-white border-top">
          <div className="col-md-4 d-flex align-items-center mr-2 justify-content-end">
            <button
              type="button"
              className="btn btn-outline-danger mr-2 text-nowrap"
              onClick={history.goBack}
            >
              Back
            </button>
            <button
              type="button"
              className="btn btn-outline-danger mr-2 text-nowrap"
              onClick={() => updateReturnandRefundStatus("UU2_TO_RESPOND")}
            >
              Reject
            </button>
            <button
              type="button"
              className="btn btn-danger mr-2 text-nowrap"
              onClick={() => updateReturnandRefundStatus("PROCESSING")}
            >
              Approve
            </button>
            {/* <button
              type="button"
              className="btn btn-outline-danger mr-2 text-nowrap"
              onClick={history.goBack}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-outline-danger mr-2 text-nowrap"
              onClick={history.goBack}
            >
              Assign To Me
            </button>
            <button
              type="button"
              className="btn btn-danger mr-2 text-nowrap"
              onClick={history.goBack}
            >
              Contact Merchant
            </button> */}
          </div>
        </footer>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}

export default ReturnOverView;

{
  /* <div className="col-4 p-0">
<div
  style={{ top: "0", right: "0" }}
  className=" position-sticky  p-3 card my-5 fntSz13"
>
  <div className="mb-3 ">
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">status</div>
      <div className="font-weight-bold" style={{ fontSize: "1rem" }}>
        Merchant To Respond
      </div>
    </div>
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">Case ID</div>
      <div className="">
        {"#" + "012201" + (fullData?.orderId?.slice(-2) || "N/A")}
        {fullData?.orderId || "N/A"}
      </div>
    </div>
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">Order ID</div>
      <div className="">
        {"#" + "012201" + (fullData?.orderId?.slice(-2) || "N/A")}
        {fullData?.orderId || "N/A"}
      </div>
    </div>
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">Transaction Type</div>
      <div className="">Buy Now</div>
    </div>
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">Agent Name</div>
      <div className="text-danger">Yet to assign</div>
    </div>
  </div>

  <div className="font-weight-bolder py-1 fntSz15">Payment</div>
  <div className="mb-3 ">
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">Payment Method</div>
      <div className="">
        {fullData?.paymentMethods || "N/A"}
      </div>
    </div>
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">Payment Date</div>
      <div className="">{fullData?.paymentDate || "N/A"}</div>
    </div>
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">Ref ID</div>
      <div className="">{fullData?.paymentDate || "N/A"}</div>
    </div>
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">Settlement Status</div>
      <div className="">{fullData?.paymentDate || "N/A"}</div>
    </div>
  </div>

  <div className="font-weight-bolder py-1 fntSz15 ">
    Shipping Details
  </div>
  <div className="mb-3">
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">Merchant Name</div>
      <div className="">
        {fullData?.merchantName || "N/A"}

        
      </div>
    </div>
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">Merchant ID</div>
      <div className="">
        {fullData?.merchantName || "N/A"}

        
      </div>
    </div>
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">Shipping</div>
      <div className="">
        {fullData?.shippingDetails?.shippingType || "N/A"}
      </div>
    </div>
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">Courier</div>
      <div className="">
        {fullData?.shippingDetails?.courierName || "N/A"}
      </div>
    </div>
    <div className="d-flex justify-content-between py-1">
      <div className="text-muted">Tracking Number</div>
      <div className="">
        {fullData?.shippingDetails?.trackingNo || "N/A"}
      </div>
    </div>
  </div>
</div>
<div className=" p-3 mt-1 card">
  <div className="pb-3 font-weight-bolder">Shipping Address</div>
  <div
    className="d-flex justifly-content-between pt-1"
    style={{ fontSize: "0.8rem" }}
  >
    <label htmlFor="" className="col-2 text-muted p-0">
      Address:{" "}
    </label>
    <div className="col-10 ">
      {fullData?.shippingAddress?.address || "N/A"}
    </div>
  </div>
</div>
</div> */
}
