import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { generateRoomWithoutSeparator } from "random-word-generator-npm";
import InputField from "../../../components/formControl/input/InputField";
import uuid from "react-uuid";
import { checkExistUser } from "../../../redux/actions/merchant-center.action";
import { useDispatch } from "react-redux";
import { startLoader, stopLoader, showToast } from "../../../lib/global";
const EditSubAccount = (props) => {
  const dispatch = useDispatch();
  console.log(props);
  // const [password] = React.useState(generateRoomWithoutSeparator());
  const [password, setPassword] = useState(props.password);
  const [employeeName, setEmployeeName] = useState(props.employeeName);
  const [passportNumber, setPassportNumber] = useState(props.passportNumber);
  const [email, setEmail] = useState(props.email);
  const [phone, setPhone] = useState(props.phone);
  // let randomNumber = Math.floor(Math.random() * 1000 + 4);

  const handleSubmit = () => {
    // const payload = {
    //     username: employeeName,
    //     passportNumber,
    //     email,
    //     phone,
    //     loginId: props.employeeId,
    //     index: props.index

    // };
    // props.onSubmit(payload);
    // props.onClose();
    var payload = {
      username: employeeName,
      passportNumber,
      email,
      phone,
      password,
      loginId: props.employeeId,
      index: props.index,
      userId: props.userId,
      storeId: props.storeId
    };
    if (props.email !== email) {
      const response = dispatch(
        checkExistUser({
          username: employeeName,
          passportNumber,
          email,
          phone,
        })
      );
      startLoader();
      response
        .then((data) => {
          // console.log(data.data, "llllllll,,,,,,,,,,lllll");
          if (data && data.data && data.data.status === 200) {
            console.log(payload,"payload");
            setTimeout(() => {
              stopLoader();
              props.onSubmit(payload);
              props.onClose();
              showToast(data.data.message, "success");
            }, 1000);
            stopLoader();
          } else {
            setTimeout(() => {
              stopLoader();
              showToast(data.data.message, "error");
            }, 1000);
          }
        })
        .catch((err) => {
          stopLoader();
          showToast(err, "error");
          console.log(err);
        });
    } else {
      console.log(payload);
      setTimeout(() => {
        stopLoader();
        props.onSubmit(payload);
        props.onClose();
        showToast("updated successfully", "success");
      }, 1000);
      stopLoader();
    }
    //
  };
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };
  return (
    <React.Fragment>
      <div className="col-12 p-0 m-0 card">
        <div className="close_icon">
          <CloseIcon
            className="close_icon_btn m-2"
            color="primary2"
            fontSize="large"
            title="Close dialog"
            onClick={props.onClose}
          />
        </div>
        <div className="card-header d-flex justify-content-center">
          <h4>Edit Authorized Person</h4>
        </div>
        <div
          style={{ height: "75vh" }}
          className="card-body add-authoried-card-body"
        >
          <form className="col-12 row">
            <div className="col-12 mb-2">
              <label className="col-12 m-0 p-0">Employee Name</label>
              <InputField
                className="w-100"
                type="text"
                placeholder="Employee Name"
                value={employeeName}
                onChange={(e) => setEmployeeName(e.target.value)}
              />
            </div>
            <div className="col-12 my-2">
              <label className="col-12 m-0 p-0">NRIC/Passport No.</label>
              <InputField
                className="w-100"
                type="text"
                placeholder="NRIC/Passport No"
                value={passportNumber}
                onChange={(e) => setPassportNumber(e.target.value)}
              />
            </div>

            <div className="col-12 my-2">
              <label className="col-12 m-0 p-0">Email Address</label>

              <InputField
                className="w-100"
                type="text"
                placeholder=" Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-12 my-2">
              <label className="col-12 m-0 p-0">Phone Number</label>

              <InputField
                className="w-100"
                type="text"
                onKeyPress={(e) => handleKeyPress(e)}
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="submit_btn col-md-12">
          <button
            disabled={!employeeName || !passportNumber || !email || !phone}
            onClick={handleSubmit}
            className="btn btn-primary"
          >
            Submit
          </button>
        </div>
      </div>
      <style jsx="true">{`
        .card-body {
          min-height: 60vh;
        }
        .submit_btn {
          position: absolute;
          bottom: 15px;
          width: 100%;
          justify-content: center;
          display: flex;
        }
        .close_icon_btn {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      `}</style>
    </React.Fragment>
  );
};

export default EditSubAccount;
