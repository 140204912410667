import React, { useState } from "react";
import Calendar from "react-calendar";
import { Button } from "react-bootstrap";
import "react-calendar/dist/Calendar.css";

const dateFilterList2 = ["Day", "Week", "Month", "Year"];

const DatePickerSelect = (props) => {
  const { statusDate, setStatusDate, setStartDate, setEndDate } = props;

  const [open, setOpen] = useState(statusDate);

  const handleDate = (date, dateFilter) => {
    console.log("+++++++++++++++++++++++", date, "+++++++++++++++++++++++");
    let count = 0;

    if (dateFilter == dateFilterList2[1]) {
      count = 6;
    } else if (dateFilter == dateFilterList2[2]) {
      const month = new Date(date).getMonth() + 1;
      if (month % 2 != 0 && month <= 7) {
        count = 30;
      } else if (month % 2 == 0 && month > 7) {
        count = 30;
      } else if (month % 2 == 0 && month != 2) {
        count = 29;
      } else if (month % 2 != 0 && month > 7) {
        count = 29;
      } else if (month == 2) {
        count = 28; // february
      }
    } else if (dateFilter == dateFilterList2[3]) {
      count = 364;
    }

    setStartDate(date);
    date = new Date(new Date(date).valueOf() + 1000 * 60 * 60 * 24 * count);
    setEndDate(date);
    setStatusDate(dateFilter);
  };

  //   console.log(startDate, "starttttttttttt");
  //   console.log(endDate, "endddddddddddddd");

  return (
    <div className="date_picker m-1 d-flex flex-row flex-nowrap justyfi-content-between ">
      <div className="w-25">
        {dateFilterList2.map((filter, idx) => {
          return (
            <div
              key={idx}
              className="d-flex flex-row flex-nowrap pointer"
              onMouseMove={() => setOpen(filter)}
            >
              <Button variant="light" className="w-100 rounded-0">
                <span className="h6 badge pointer">{filter}</span>
              </Button>
            </div>
          );
        })}
      </div>
      <div className="w-75">
        {open == dateFilterList2[0] && (
          <Calendar
            onClickDay={(date) => handleDate(date, dateFilterList2[0])}
            view="month"
            maxDate={new Date()}
          />
        )}
        {open == dateFilterList2[1] && (
          <Calendar
            onClickDay={(date) => handleDate(date, dateFilterList2[1])}
            view="month"
            maxDate={new Date()}
          />
        )}
        {open == dateFilterList2[2] && (
          <Calendar
            onClickMonth={(date) => handleDate(date, dateFilterList2[2])}
            view="year"
            maxDate={new Date()}
          />
        )}
        {open == dateFilterList2[3] && (
          <Calendar
            onClickYear={(date) => handleDate(date, dateFilterList2[3])}
            view="decade"
            maxDate={new Date()}
          />
        )}
      </div>
    </div>
  );
};

export default DatePickerSelect;
