
  import { makeStyles } from "@mui/styles";
  
  import React, { useEffect, useState } from "react";
  import { useHistory, useParams } from "react-router-dom";
  import {
    getOrderById,
    getTrackingStatus,
    getLogisticDetail,
  } from "../../../../redux/actions/order.action";
  import { useDispatch } from "react-redux";
  import {
    AllowAccess,
    showToast,
    startLoader,
    stopLoader,
  } from "../../../../lib/global";
  import { breadcrumbAction } from "../../../../redux/actions/config.action";
  import { getLocalStorage } from "../../../../lib/session";
  import decimalFormat from "../../../../helpers/decimal.format";
import { getMerchantOrderRecieptById } from "../../../../redux/actions/finance.action";

  const useStyles = makeStyles({
    timelineitem: {
      // minHeight:"0px"
    },
    timelineContentOppositeContainer: {
      maxWidth: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    timing: {
      marginTop: "-5px",
      fontSize: "0.7rem",
    },
    timelineContent: {
      display: "inline-block",
      transform: "rotate(-90deg)",
      textAlign: "center",
      minWidth: 50,
    },
    timelineIcon: {
      transform: "rotate(-90deg)",
    },
    mainText: {
      fontSize: "0.9rem",
    },
    dot: {
      padding: "2px  !important",
  
      margin: "8.5px 4px !important",
    },
  });
  
  const order_status_names = {
    orderPlaced: "Order Placed",
    orderPaid: "Order Paid",
    prepairingToShipped: "Preparing To Ship",
    orderShipped: "Order Shipped",
    orderDelivered: "Order Delivered",
  };
  
  function Detail() {
    AllowAccess(["MERCHANT"]);
    const [fullData, setFullData] = useState({});
    const [data, setData] = useState({})
    const [orderShippingStatus, setOrderShippingStatus] = useState("");
    const [timeline, setTimeline] = useState([]);
    const [lastOrderUpdate, setLastOrderUpdate] = useState({});
    const [logistic, setLogistic] = useState({});
  
    const [commissionFee, setCommissionFee] = useState(0);
    const history = useHistory();
    const dispatch = useDispatch();
  
    const { id } = useParams();
    const param = useParams();
    // console.log(param);
  
    const queryParams = new URLSearchParams(window.location.search);
    const productId = queryParams.get("productId");
    const role = getLocalStorage("role")
    const storeId = role=='MERCHANT'? getLocalStorage("storeId"):null
    // console.log(productId, "oooooo"); //pizza
  
    useEffect(() => {
      var breadcrumb = [];
      if (param?.merchantId && param?.merchantName) {
        breadcrumb = [
          { label: "Merchant Profile", url: "/merchant-center/profiles" },
          {
            label: param.merchantName,
            url: `/merchant-center/profiles/${param.merchantId}/${param.merchantName}`,
          },
          { label: `Order ID ${id}`, url: null },
        ];
      } else if (param?.storeId && getLocalStorage("role") == "MERCHANT") {
        breadcrumb = [
          { label: "My Income", url: null },
          { label: `View Order`, url: null },
        ];
      } else {
        const url =
          getLocalStorage("role") == "MERCHANT"
            ? "/merchant/orders/all-orders"
            : "/orders/all-orders";
        breadcrumb = [
          { label: "Orders", url },
          { label: `Order ID ${id}`, url: null },
        ];
      }
  
      dispatch(breadcrumbAction(breadcrumb));
      dispatch(getTrackingStatus({ orderId: "2011120003106484" }))
        .then((data) => {})
        .catch((err) => {
          console.log(err);
        });
      dispatch(getLogisticDetail(id))
        .then((data) => {
          setLogistic(data.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  
      if (id) {
        startLoader();
        // dispatch(getOrderById(id, { productId }))
        //   .then((data) => {
        //     if (data && data.data && data.data.data) {
        //       if(role == "MERCHANT" && data?.data?.data?.orderDetails.storeId != storeId){
        //         history.push('/404')
        //       }
        //       // console.log(
        //       //   data?.data?.data,
        //       //   "data?.data?.datavvdata?.data?.datadata?.data?.data"
        //       // );
        //       let orderDetails = { ...data?.data?.data?.orderDetails };
        //       let trackingDetails = data?.data?.data?.trackingDetails;
        //       setFullData({ ...orderDetails, trackingDetails });
  
        //       orderDetails.product.productType == "physical"
        //         ? setCommissionFee(
        //             orderDetails.merchant.physicalCommission / 100 || 0
        //           )
        //         : setCommissionFee(
        //             orderDetails.merchant.nonPhysicalCommission / 100 || 0
        //           );
  
        //       setTimeline(
        //         data?.data?.data?.shippingStatus?.map((item) => item.date)
        //       );
        //       const latest = data?.data?.data?.shippingStatus
        //         ?.filter((item) => item.date !== null)
        //         .splice(-1)[0];
        //       // console.log(latest, "jjjjjjjjjj");
        //       setLastOrderUpdate(latest);
        //       setOrderShippingStatus({
        //         value: latest?.status,
        //         label: order_status_names[latest?.status],
        //       });
        //       setTimeout(() => {
        //         stopLoader();
        //       }, 1000);
        //     }
        //   })
        //   .catch((error) => {
        //     setTimeout(() => {
        //       console.log(error);
        //       stopLoader();
        //     }, 1000);
        //   });

        dispatch(getMerchantOrderRecieptById(id))
        .then((res)=>{
          let result = res?.data?.data
          console.log(result)
          if(result){
            if(role == "MERCHANT" && result.storeId != storeId){
              history.push('/404')
            }
            setData(result)
          }else{
            showToast("No data found", "error")
          }
          stopLoader()
        }).catch((err)=>{
          showToast("Error : "+err, "error")
          stopLoader()
        })

      }
    }, [id]);
  
    const classes = useStyles();

    const totalBuyerPayment = () => {
        return (data?.price || 0) +
                (data?.shippingDetails?.shippingCharge || data?.trackingInfo?.price || 0) +
                (data?.payment?.Discount)
    }

    const serviceFee = () => {
        return 0
    }
    const transactionFee = () => {
        return (2.12 / 100) * (fullData.finalAmount || 0)
    }
    const discounts = () => {
        return (data?.shippingDetails?.sellerVoucher || 0) + 
        (data?.shippingDetails?.creaditDebitCardVoucher || 0) +
        (data?.shippingDetails?.uu2Voucher || 0) 
    }
    const totalDeduction = () => {
        return  Number(serviceFee()) +
        Number(transactionFee()) +
        Number(commissionFee) -
        Number(discounts())
    }

    const netSettlement = () => {
        return Number(totalBuyerPayment()) - Number(totalDeduction())
    }
  
    // console.log(fullData, "bbbbbbbbbbbbbbbbbbbb");
  
    return (
      <React.Fragment>
        <div className="d-flex  position-relative  mx-4 ">
          <div className="col-12 pr-3">
            <div className="py-3">
              <div className="card p-3">
                <div className="card-header">
                    ORDER INFORMATION
                </div>
                <div className="card-body d-flex justify-content-center">
                    <table className="table table-borderless text-muted" style={{fontSize:"0.8rem",width:"20rem"}}>
                        <tr>
                            <td>Merchant Name</td>
                            <td>{data?.merchantName || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Merchant ID</td>
                            <td>{data?.storeId || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Buyer Name</td>
                            <td>{data?.consumerName || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Product Name</td>
                            <td>{data?.product?.name || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Product Category</td>
                            <td>{data?.product?.category || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Order ID</td>
                            <td>{data?.order?.id || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Order Date</td>
                            <td>{data?.order?.date? moment(data?.order?.date).format(
                                  "MM-DD-YYYY HH:mm"
                                ):"N/A"}</td>
                        </tr>
                        <tr>
                            <td>Transaction Type</td>
                            <td>{data?.order?.transactionType || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Order Status</td>
                            <td>{data?.order?.status || "N/A"}</td>
                        </tr>
                    </table>
                </div>
              </div>
            </div>

            <div className="py-3">
              <div className="card p-3">
                <div className="card-header">
                    PAYMENT INFORMATION
                </div>
                <div className="card-body d-flex justify-content-center">
                    <table className="table table-borderless text-muted" style={{fontSize:"0.8rem",width:"20rem"}}>
                        <tr>
                            <td>Payment Method</td>
                            <td>{data?.payment?.method || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Payment Date</td>
                            <td>{data?.payment?.date || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Ref ID</td>
                            <td>{data?.payment?.refId || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>Paid Amount</td>
                            <td>{`${data?.currencyName || "RM"}${decimalFormat(
                                (data?.product?.subTotal || 0)
                              )}`}</td>
                        </tr>
                        <tr>
                            <td>Shipping Fee</td>
                            <td>{`${
                                data?.currencyName || "RM"
                              }${Number.parseFloat(
                                data?.fee?.shipping
                              ).toFixed(2)}`}{" "}</td>
                        </tr>
                        <tr>
                            <td>Discount/Rebate</td>
                            <td>{`${data?.currencyName || "RM"}${decimalFormat(
                                data?.payment?.discount || 0
                              )}`}</td>
                        </tr>
                        <tr>
                            <td>Total Buyer Payment</td>
                            <td>{`${
                                data?.currencyName || "RM"
                              }${Number.parseFloat(
                                data?.payment?.buyerPaid
                              ).toFixed(2)}`}</td>
                        </tr>
                    </table>
                </div>
              </div>
            </div>

            <div className="py-3">
              <div className="card p-3">
                <div className="card-header">
                    TOTAL DEDUCTION BY UU2
                </div>
                <div className="card-body d-flex justify-content-center">
                    <table className="table table-borderless text-muted" style={{fontSize:"0.8rem",width:"20rem"}}>
                        <tr>
                            <td>Service Fee</td>
                            <td>{`${data?.currencyName || "RM"}${decimalFormat(
                                data?.fee?.service
                              )}`}</td>
                        </tr>
                        <tr>
                            <td>Transaction Fee</td>
                            <td>
                            {`${data?.currencyName || "RM"}${decimalFormat(
                              data?.fee?.transaction
                            )}`}
                            </td>
                        </tr>
                        <tr>
                            <td>Commision Fee</td>
                            <td>
                            {`${data?.currencyName || "RM"}${decimalFormat(
                              data?.fee?.commission
                            )}`}
                            </td>
                        </tr>
                        <tr>
                            <td>Discount/Rebate</td>
                            <td>
                            {`${data?.currencyName || "RM"}${decimalFormat(
                                data?.fee?.discount
                              )}`} {data?.fee?.discount ? "("+data?.fee?.discountType+")" : ""}
                            </td>
                        </tr>
                        <tr>
                            <td>Settlement Status</td>
                            <td>{(data?.order?.status || "N/A")}</td>
                        </tr>
                    </table>
                </div>
              </div>
            </div>
          </div>
        </div>
            
  
        <footer className="d-flex flex-wrap  align-items-center py-3 mt-3 bg-white border-top b-0">
          <div className="col-md-6 justify-content-start">
            <h6>Net Settlement Report</h6>
            <h4 style={{color:'red'}}>
                {`${data?.currencyName || "RM"}${Number.parseFloat(data?.merchantIncome).toFixed(2)}`}
            </h4>
          </div>
          <div className="col-md-6 d-flex align-items-center justify-content-end">
            <button
              type="button"
              className="btn border input-inside-text mr-2"
              onClick={history.goBack}
            >
              Cancel
            </button>
          </div>
        </footer>
      </React.Fragment>
    );
  }
  
  export default Detail;
  