import {
  Icon,
  IconButton,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useState,useEffect, useRef } from "react";
import InputField from "../../../../components/formControl/input/InputField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SvgIcon from "../../../../components/Images/SvgIcon";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { breadcrumbAction } from "../../../../redux/actions/config.action";
import { getLiveStream } from "../../../../redux/actions/marketing.action";
import moment from "moment";
import { open_dialog, showToast } from "../../../../lib/global";
import { DELETE_BANNER_MODEL, DELETE_LIVESTREAM } from "../../../../lib/constant";
import PagenationDropdown from "../../../../components/DropDown/pagenationDropdown";
import { allowCharacterAndNumbersOnly, isNotEmptyString } from "../../../../lib/helper";

const LiveStreamCenter = () => {
  const history = useHistory();
  const [allLivestreamListing, setAllLivestreamListing] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [offset, setOffset] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [merchantName, setMerchantName] = useState();
  const [merchantId, setMerchantId] = useState();
  const [liveStreamName, setLiveStreamName] = useState();
  const [liveStreamDate, setLiveStreamDate] = useState();
  const userRole = localStorage.getItem("role");
  const limit = useRef(10);

  const dispatch = useDispatch();

  const breadcrumb = [{ label: "Livestream", url: null }];
  dispatch(breadcrumbAction(breadcrumb));

  useEffect(() => {
    let filteringData = {
      merchantId,
      merchantName,
      liveStreamName,
      liveStreamDate,
    }
    if (pageLimit != limit.current) {
      limit.current = pageLimit;
      setPageNo(1);
      setOffset(0);
      filteringData = {
        ...filteringData,
        limit: pageLimit,
        offset: 0,
      };
    } else {
      filteringData = {
        ...filteringData,
        offset,
        limit: pageLimit,
      };
    }
    getLiveStreamList(filteringData);
  },[offset, pageLimit])

  const resetSearch = async () => {
    setLiveStreamName("");
    setLiveStreamDate("");
    let filteringData = {
      merchantId,
      merchantName,
      liveStreamName:"",
      liveStreamDate:""
    }
    getLiveStreamList(filteringData)
  }

  const handleSort = (order) => {
    let filteringData = {
      merchantId,
      merchantName,
      liveStreamName,
      liveStreamDate,
      limit:pageLimit,
      offset,
      order
    }
    getLiveStreamList(filteringData)
  }

  const searchData = () => {
    let filteringData = {
      merchantId,
      merchantName,
      liveStreamName,
      liveStreamDate,
      limit:pageLimit,
      offset
    }
    getLiveStreamList(filteringData)
  }

  const handlePageChange = (event, value) => {
    setOffset(value - 1);
    setPageNo(value);
  };

  const getLiveStreamList = (filter) => {
    if(isNotEmptyString(filter.liveStreamName) && !allowCharacterAndNumbersOnly(filter.liveStreamName)){
      showToast("Please enter valid livestream name", "error")
      return
    }
    dispatch(getLiveStream(filter))
      .then((res) => {
        setAllLivestreamListing(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log("err", err);
      })
  }

  const getBannersAfterDelete = (data) => {
    setAllLivestreamListing(data);
  };

  const handleDelete = async (id) => {
    open_dialog(DELETE_LIVESTREAM, {
      onSubmit: getBannersAfterDelete,
      id,
    });
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="px-2 py-3 mb-3 card ">
          <div className="col-12 row flex-wrap">
            <div className="min-width-200 col-md-3">
              <label>Livestream Name</label>
              <InputField
                type="text"
                className="form-control"
                placeholder="Input"
                id="livestreamName"
                value={liveStreamName}
                onChange={(e)=>setLiveStreamName(e.target.value || "")}
              />
            </div>
            {userRole == "admin" && (
              <>
              <div className="min-width-200 col-md-3">
                <label>Merchant ID</label>
                <InputField
                  type="text"
                  className="form-control"
                  placeholder="Input"
                  id="merchantId"
                  value={merchantId}
                  onChange={(e)=>setMerchantId(e.target.value || "")}
                />
              </div>

              <div className="min-width-200 col-md-3">
                <label>Merchant Name</label>
                <InputField
                  type="text"
                  className="form-control"
                  id="merchantId"
                  value={merchantName}
                  onChange={(e)=>setMerchantName(e.target.value || "")}
                />
              </div>
              </>
            )}
            <div className="min-width-200 col-md-3">
              <label>Livestream Date</label>
              <InputField
                type="date"
                className="form-control"
                id="consumerId"
                value={liveStreamDate}
                onChange={(e)=>setLiveStreamDate(e.target.value || "")}
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button
              type="button"
              className="btn input-inside-text mr-1 btn-white border px-4"
              onClick={() => resetSearch()}
            >
              Reset
            </button>
            <button
              type="button"
              className="btn input-inside-text mx-1 btn-danger px-3"
              onClick={() => searchData()}
            >
              Search
            </button>
          </div>
        </div>
        <div className="card p-3">
          <h5>LIVESTREAM LISTING</h5>
          <div className="card p-3 m-1 border rounded">
            <div>
              <h6 className="float-left">ALL LIVESTREAM({total})</h6>
              <div className="float-right">
                <div>
                  <button
                    onClick={() =>
                      history.push(
                        "/marketing/all-promo/livestream/create"
                      )
                    }
                    className="btn btn-danger btn-sm mb-2"
                  >
                    + Create Live
                  </button>
                </div>
              </div>
            </div>

            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="th_cell "
                      component="th"
                      align="left"
                    >
                      <div className="d-flex  ">
                        <div>Livestream Name</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() =>
                              handleSort({ item: "name", order: "ASC" })
                            }
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() =>
                              handleSort({ item: "name", order: "DESC" })
                            }
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="left"
                    >
                      <div className="d-flex  ">
                        <div>Date</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() =>
                              handleSort({ item: "createdAt", order: "ASC" })
                            }
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() =>
                              handleSort({ item: "createdAt", order: "DESC" })
                            }
                          />
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className="th_cell "
                      component="th"
                      align="left"
                    >
                      <div className="d-flex  ">
                        <div>Livestream By</div>
                        <div className="d-flex flex-column ">
                          <ExpandLessIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "0px 3px",
                            }}
                            onClick={() =>
                              handleSort({ item: "liveStreamMerchant", order: "ASC" })
                            }
                          />
                          <ExpandMoreIcon
                            style={{
                              width: "15px",
                              height: "15px",
                              margin: "-5px 3px",
                            }}
                            onClick={() =>
                              handleSort({ item: "liveStreamMerchant", order: "DESC" })
                            }
                          />
                        </div>
                      </div>
                    </TableCell>

                    <TableCell
                      className="table_action_head text-secondary"
                      component="th"
                      align="left"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allLivestreamListing &&
                    allLivestreamListing.length > 0 &&
                    allLivestreamListing.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left" className="td_cell">
                          {row?.name || "N/A"}
                        </TableCell>
                        <TableCell align="left" className="td_cell">
                          {row.createdAt?moment(row.createdAt).format("DD/MM/YY"):"-"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.merchant.preferredMerchantName || "N/A"}
                        </TableCell>

                        <TableCell className="d-flex  table_action_item" align="center">
                          <IconButton
                            onClick={() =>
                              history.push(
                                "/marketing/all-promo/livestream/go-live/"+row.id
                              )
                            }
                            className="pointer"
                            // onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}
                          >
                            <SvgIcon
                              src="/icons/view_icon.svg"
                              alt="view icon"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              history.push(
                                "/marketing/all-promo/livestream/edit/"+row.id
                              )
                            }
                            className="pointer"
                            // onClick={() => editAction(row.productId, permissions?.product?.includes('update'))}
                          >
                            <SvgIcon
                              src="/icons/edit_icon.svg"
                              alt="view icon"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDelete(row.id)}
                            className="pointer"
                          >
                            <SvgIcon
                              src="/icons/delete_icon.svg"
                              alt="view icon"
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <div className="col-12">
              <PagenationDropdown setPageQuant={setPageLimit} />
              <div className="float-right mb-1">
                <Stack spacing={2}>
                  <Pagination
                    count={Math.ceil((total || 0) / pageLimit)}
                    shape="rounded"
                    size="small"
                    page={pageNo}
                    onChange={handlePageChange}
                  />
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LiveStreamCenter;
