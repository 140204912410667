import React, { useState,useEffect } from "react";
import "../../../../../static/css/deleteModal.css";
import { useDispatch } from "react-redux";
import { getVoucher, getVoucherByStoreId } from "../../../../../redux/actions/marketing.action";
import moment from "moment";

const CreateLivestreamAddPromoDialog = (props) => {
  const [uu2VoucherData, setuu2VoucherData] = useState([]);
  const [shopVoucherData, setshopVoucherData] = useState([]);
  const [countSelectedPromo,setCountSelectedPromo] = useState(0);
  const STATUS_SHOW = 1;
  const STATUS_REMOVE = 0;
  const dispatch = useDispatch();

  useEffect(() => {
    getUU2PromoList()
  },[])

  function statusButton(status,id,type) {
    if (status == 0) {
      return <button className='btn btn-outline-dark' onClick={()=>changeStatus(STATUS_SHOW,id,type)}>Select</button>;
    } else {
      return <button className='btn btn-outline-dark' onClick={()=>changeStatus(STATUS_REMOVE,id,type)}>Selected</button>;
    }
  }
  const getUU2PromoList = () => {
    const merchantId = props.data.merchant.value.storeId
    let currentDate = new Date();
    currentDate.setSeconds(currentDate.getSeconds() + 30);

    dispatch(getVoucherByStoreId(merchantId))
      .then((response) => {
        if (response?.data?.data) {
          const data = response.data.data.map((item) => ({
            ...item,
            status: 0,
            voucherStatus: item.status,
            visibleUntil: null
          }));
          setuu2VoucherData(data);
        }
      })
    let params = {
      merchantCode:'UU2',
      status:'ON_GOING'
    }
    dispatch(getVoucher(params))
      .then((response) => {
        console.log(response.data.data)
        if (response?.data?.data) {
          const data = response.data.data.map((item) => ({
            ...item,
            status: 0,
            voucherStatus: item.status,
            visibleUntil: null
          }));
          setuu2VoucherData((prev) => [...prev, ...data]);
        }
      })
  }

  function changeStatus(status,id,type){
    let temp = []
    if(type == 'uu2'){
      temp = [...uu2VoucherData];
      // temp = uu2VoucherData;
    }else{
      temp = [...shopVoucherData];
    }
    temp.forEach((item)=>{
      if(item.id == id){
        item.status = status;
      }
    })
    if(status == 1){
      setCountSelectedPromo(countSelectedPromo+1)
    }else{
      setCountSelectedPromo(countSelectedPromo-1)
    }
    if(type == 'uu2'){
      setuu2VoucherData(temp);
    }else{
      setshopVoucherData(temp);
    }
  }

  function showRemainStatus(remain,status) {
    if (status == 0) {
      let counter = 30
      // useEffect(() => {
      //   counter > 0 && setInterval(() => counter-1, 1000);
      // }, []);
      console.log(counter)
      return <span className='badge bg-danger text-white'>Showing {counter}s</span>;
    }
  }

  const onSubmmit = () => {
    let selectedPromo = []
    uu2VoucherData.forEach((item)=>{
      if(item.status == 1){
        selectedPromo.push(item)
      }
    })
    shopVoucherData.forEach((item)=>{
      if(item.status == 1){
        selectedPromo.push(item)
      }
    })
    props.onSubmit(selectedPromo)
    props.onClose()
  }

  return (
    <React.Fragment>
      <div>
        <div className="d-flex flex-row align-items-center justify-content-between px-3">
          <p className="mb-0 h4">Show Voucher</p>
          <button
            onClick={props.onClose}
            className="btn fntSz24 text-secondary"
          >
            x
          </button>
        </div>
        <div className="card m-2">
          <div className="h6 ml-2">UU2 Voucher</div>
          <div
            style={{ maxHeight: "30vh", overflow: "auto" }}
            className="fntSz14 position-relative px-2"
          >
            <table className="table border-0">
              <thead className="position-sticky" style={{ top: "0" }}>
              <tr className="bg-light rounded-lg text-secondary">
                <th className="border-0">
                  voucher(s)
                </th>
                <th className="border-0">
                  voucher code
                </th>
                <th className="border-0">
                  valid until
                </th>
                <th className="border-0">
                  Status
                </th>
              </tr>
              </thead>
              <tbody>
              {uu2VoucherData &&
                uu2VoucherData.length > 0 &&
                uu2VoucherData.map((row, index) => (
                  <tr key={row.id}>
                    <td className="border-0">
                      <span className="row">{row.name}</span>
                      <span className="row badge badge-danger text-left">{row.type?.label}</span>
                    </td>
                    <td className="align-middle border-0">
                      {row.code}
                    </td>
                    <td className="align-middle">
                      {row.endPeriodDate?moment(row.endPeriodDate).format("DD MMM YY"):'N/A'}
                    </td>
                    <td>
                      {(row.voucherStatus == 'EXPIRED' || row.voucherStatus == 'DRAFT')  && (
                      <>
                        {row.voucherStatus}
                      </>)}
                      {row.voucherStatus != 'EXPIRED' && row.voucherStatus != 'DRAFT' && (statusButton(row.status,row.id,'uu2'))}
                    </td>
                  </tr>
                ))}
                {uu2VoucherData.length == 0 && (
                  <tr>
                    <td colSpan={4} className="text-center">
                      No voucher are available, please add on going voucher
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {/*<div className="card m-2">*/}
        {/*  <div className="h6 ml-2">Shop Voucher</div>*/}
        {/*  <div*/}
        {/*    style={{ maxHeight: "30vh", overflow: "auto" }}*/}
        {/*    className="fntSz14 position-relative px-2"*/}
        {/*  >*/}
        {/*    <table className="table border-0">*/}
        {/*      <thead className="position-sticky" style={{ top: "0" }}>*/}
        {/*      <tr className="bg-light rounded-lg text-secondary">*/}
        {/*        <th className="border-0">*/}
        {/*          voucher(s)*/}
        {/*        </th>*/}
        {/*        <th className="border-0">*/}
        {/*          voucher code*/}
        {/*        </th>*/}
        {/*        <th className="border-0 float-right">*/}
        {/*          valid until*/}
        {/*        </th>*/}
        {/*        <th className="border-0">*/}
        {/*          Status*/}
        {/*        </th>*/}
        {/*      </tr>*/}
        {/*      </thead>*/}
        {/*      <tbody>*/}
        {/*      {shopVoucherData &&*/}
        {/*        shopVoucherData.length > 0 &&*/}
        {/*        shopVoucherData.map((row, index) => (*/}
        {/*          <tr key={row.id}>*/}
        {/*            <td*/}
        {/*              className="border-0"*/}
        {/*            >*/}
        {/*              <span>{row.voucherName}</span>*/}
        {/*              <span className="badge badge-danger text-left">{row.voucherType}</span>*/}
        {/*            </td>*/}
        {/*            <td className=" align-middle border-0">*/}
        {/*              {row.voucherCode}*/}
        {/*            </td>*/}
        {/*            <td className="align-middle border-0 float-right">*/}
        {/*              {row.endDate}*/}
        {/*            </td>*/}
        {/*            <td>*/}
        {/*              {statusButton(row.status,row.id,'shop')}*/}
        {/*              {showRemainStatus(row.remain,row.status)}*/}
        {/*            </td>*/}
        {/*          </tr>*/}
        {/*        ))}*/}
        {/*      </tbody>*/}
        {/*    </table>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div>
          <div className="d-flex justify-content-between m-3">
            <div className="text-secondary fntSz12">{countSelectedPromo} promo selected</div>
            <div className="">
              <button
                onClick={props.onClose}
                type="button"
                className="btn btn-outline-danger btn-sm p-1 px-3 mx-2"
                // onClick={back}
              >
                Cancel
              </button>
              <button
                // onClick={() => history.push("/marketing/all-promo/flash-sale")}
                type="button"
                className="btn btn-danger btn-sm p-1 px-3 mx-2"
                onClick={onSubmmit}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>

        <style jsx="true">{`
          .card-body {
            min-height: 50vh;
          }
          .submit_btn {
            position: absolute;
            bottom: 15px;
            width: 100%;
            justify-content: center;
            display: flex;
          }
          .close_icon_btn {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
          .flash_sale_product_image {
            width: 45px;
            height: 40px;
            border-radius: 3px;
          }
          input {
            cursor: pointer;
          }
        `}</style>
      </div>
    </React.Fragment>
  );
};

export default CreateLivestreamAddPromoDialog;
