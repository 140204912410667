import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TabPanel } from "../../../components/Tabs/TabPannel";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import MerchantIncome from "./MerchantIncome/merchantIncome";
import { AllowAccess } from "../../../lib/global";

const MyIncome = (props) => {
    AllowAccess(["MERCHANT"]);

    const [activeTab, setActiveTab] = useState("basicSetting");

       
    const dispatch=useDispatch()
    useEffect(() => {
       const breadcrumb = [{ label: "My Income", url: null }];
       dispatch(breadcrumbAction(breadcrumb));
   }, []);
    return (
        <div className="card mx-4 p-4" >
           <MerchantIncome />
        </div>
    )
}

export default MyIncome;
