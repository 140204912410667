import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import Button from "../../components/buttons";
import Input from "../../components/formControl/input";
import {
  CATEGORY_SELECTION_DIALOG,
  PASSWORD_FORGET_INFO_MODAL,
} from "../../lib/constant";
import { open_dialog, setSessionData, showToast } from "../../lib/global";
import { consumerForgetPasswordUpdate } from "../../redux/actions/auth.action";

const ForgetPasswordRest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id,token } = useParams();
  const [confPassword, setConfPassword] = useState("");
  const [password, setPassword] = useState("");
  // const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFormInput = (event, key) => {
    const value = event.target.value;
    switch (key) {
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        break;
      case "confpassword":
        setConfPassword(value);
        break;
      default:
        return;
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    // setLoading(true)
    if (!password || !confPassword) {
      showToast("All fields are requrired!", "error");
    }

    if (password !== confPassword) {
      showToast("Password and Retype Password Not Match", "error");
    }

    const payload = { password, consumerId: id,token };

    dispatch(consumerForgetPasswordUpdate(payload))
      .then((response) => {
        showToast("Reset Password in successfully!", "success");
        history.push("/auth");
      })
      .catch((error) => {
        console.log(error);
        showToast(
          error?.response?.data?.message || "Failed to Reset Password!",
          "error"
        );
      });
  };

  const handleTest = () => {
    open_dialog(CATEGORY_SELECTION_DIALOG, {
      minWidth: "80vw",
      maxWidth: "80vw",
    });
  };

  return (
    <React.Fragment>
      <div className="vh-100 landing_image position-relative">
        <div className="position-absolute w-100 h-100">
          <img
            className="landing_image_src"
            src="/images/loginPageBackgroundNew.png"
            // loginPageBackground.jpg
            alt="login page background image"
          />
        </div>
        <br />
        <br />

        <div className="m-auto px-5 h-100 d-flex flex-column justify-content-between">
          <div className="row d-none d-sm-block">
            <div className="col-2">
              <h3 className="font-weight-bold mb-1">
                <a href="https://uu2.co/">HOME</a>
              </h3>
              <div>
                <a href="https://uu2.co/">ABOUT US</a>
              </div>
              <div>
                <a href="https://uu2.co/story/">OUR STORY</a>
              </div>
              <div>
                <a href="https://uu2.co/about-us/">CONTACT US</a>
              </div>
            </div>
            <div className="col-md-7 d-flex">
              <h1 className="display-1 font-weight-bolder pt-5 pl-4 d-flex justify-content-center">
                Welcome
              </h1>
            </div>
            {/* <div className="col-3 d-flex flex-row-reverse">
              <div>
                <img
                  src="UU2_Transparent.png"
                  width={"auto"}
                  height={"50px"}
                  alt="uu2 icon"
                />
              </div>
            </div> */}
          </div>
          <div className="row mb-5">
            <div className="col-12 mb-3 d-flex flex-row align-items-center justify-content-end">
              <div className="mx-2">FOLLOW US ON</div>
              <a href="https://www.instagram.com/uu2_official/" target="_blank">
                <div className="mx-2" style={{ width: "35px" }}>
                  <img className="w-100" src="/images/instagram-icon.png" />
                </div>
              </a>
              <a href="https://www.facebook.com/uu2official" target="_blank">
                <div className="mx-2" style={{ width: "35px" }}>
                  <img className="w-100" src="/images/facebook-icon.png" />
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="p-4 position-absolute text-white login_input_box_section ">
          <div>
            <form onSubmit={handleSubmitForm}>
              <br />
              <br />
              <br />
              <p className="text-nowrap">Please key in your new password</p>
              <div>
                <Input
                  className="input__textField mt-2 w-100"
                  type="password"
                  name="password"
                  id="password"
                  onChange={(e) => handleFormInput(e, "password")}
                  value={password}
                  placeholder="New Password"
                />
              </div>
              <div>
                <Input
                  className="input__textField mt-2 w-100"
                  type="password"
                  name="confpassword"
                  id="confpassword"
                  onChange={(e) => handleFormInput(e, "confpassword")}
                  value={confPassword}
                  placeholder="Retype New Password"
                />
              </div>
              <br />

              <br />

              <Button type="submit" role="button" className="login_btn">
                {loading ? "loading..." : "Reset Password"}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgetPasswordRest;
