import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TabPanel } from "../../../components/Tabs/TabPannel";
import { AllowAccess, open_dialog, startLoader, stopLoader } from "../../../lib/global";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getAllProductList } from "../../../redux/actions/product.action";
import InputField from "../../../components/formControl/input/InputField";
import AllReturnRefund from "./allReturnRefund/AllReturnRefund";
import { VIEW_GENERATE_REPORT_MODAL } from "../../../lib/constant";
import { getAllReturnOrder } from "../../../redux/actions/finance.action";
import SelectInput from "../../../components/select";
import DateRangePickerComp from "../../../components/datepicker/MUIDateRange";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
var initialSearchData = {
  refundId: "",
  orderId: "",
  orderDate: "",
  refundMethod: "",
};
const initOrderRangeDate = {
  startDate: "",
  endDate: "",
};

const refundMethodOptions = [
  {
    title: "Online Banking",
    label: "Online Banking",
    value: "ONLINE_BANKING",
  },
  {
    title: "Credit/Debit Card",
    label: "Credit/Debit Card",
    value: "CREDIT_DEBIT_CARD",
  },
];
const ReturnRefundIndex = () => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const dispatch = useDispatch();
  const [orderId, setOrderId] = useState("");
  const [refundId, setRefundId] = useState("");
  const [orderDateRange, setOrderDateRange] = useState(initOrderRangeDate);
  const [refundMethod, setRefundMethod] = useState("");

  const [reset, setReset] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [total, setTotal] = useState({});
  const [search, setSearch] = useState(initialSearchData);


 
  useEffect(() => {
    // startLoader();
    const breadcrumb = [{ label: "Return/Refund", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
    // dispatch(getAllReturnOrder({}))
    //   .then((data) => {
    //     setTimeout(() => {
    //       stopLoader();
    //     }, 1000);
    //     console.log(data);
    //     setTotal(data && data.data && data.data.total);
    //   })
    //   .catch((error) => {
    //     setTimeout(() => {
    //       stopLoader();
    //     }, 1000);
    //     console.log(error);
    //   });
  }, [tabIndex]);

  const afterDelete = (data) => {
    setTotal(data && data.total);
    setTabIndex(0);
  };

  const   handleDateChange = (data) => {
    //   console.log(data,'sdfasfdasdfadsfasdfasdfa');
    setOrderDateRange(data);
  };

  const searchData = () => {
    setSearch({
      orderId,
      refundId,
      refundMethod:refundMethod.value,
      ...orderDateRange,
    });
  };

  const handleResetSearch = () => {
    setOrderId("");
    setRefundId("");
    setRefundMethod("");
    setOrderDateRange(initOrderRangeDate);
    setReset(true); 
    setSearch({
      refundId: "",
      refundMethod: "",
      orderId: "",
      ...initOrderRangeDate,
    });
  };
  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className=" row flex-wrap">
          <div className="min-width-200 col-md-4">
            <label className="">Order ID</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              id="storeId"
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
            />
          </div>
          <div className="min-width-200 col-md-4">
            <label className="">Refund ID</label>
            <InputField
              type="text"
              className="form-control"
              placeholder="Input"
              value={refundId}
              onChange={(e) => setRefundId(e.target.value)}
            />
          </div>
          
          <div className="min-width-200 col-md-4">
            <label className="">Refund Method</label>
            <div className="my-2">
              <SelectInput
                className="form-control "
                options={refundMethodOptions}
                label={[]}
                placeholder="Input"
                openIn={"left"}
                value={refundMethod}
                onChange={(e) =>
                  setRefundMethod({ label: e.label, value: e.value })
                }
              />
            </div>
          </div>
          <div className="min-width-200 col-md-3 ">
            <label className="">Order Date</label>
            <div className=" ">
              <DateRangePickerComp
                reset={reset}
                setReset={setReset}
                handleDateChange={handleDateChange}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            className="btn mr-1 btn-outline-danger py-1 px-4"
            onClick={() => handleResetSearch()}
          >
            Reset
          </button>
          <button
            type="button"
            className="btn mx-1 btn-danger py-1 px-3"
            onClick={() => searchData()}
          >
            Search
          </button>
        </div>
      </div>
      <br />
      <br />
      <div className=" container_body container-fluid">
        <div className="card p-3">
          <span>RETURN/REFUND LISTING</span>
          <div className=" border card  my-3">
            <Tabs
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              variant="scrollable"
              orientation="horizontal"
              scrollButtons={false}
              visibleScrollbar={false}
              className="px-3"
              aria-label="basic tabs example"
              style={{
                backgroundColor: "secondary",
                //  "&::-webkit-scrollbar": {display: "none"}, overflowX: "auto"
              }}
            >
              <Tab label={`All(${total?.total || 0})`} {...a11yProps(0)} />
              <Tab
                label={`Processing(${total?.processing || 0 })`}
                {...a11yProps(1)}
              />
              <Tab label={`Under Review(${total?.review || 0})`} {...a11yProps(2)} />
              <Tab label={` Refunded(${total?.refund || 0}) `} {...a11yProps(3)} />
              <Tab
                label={`Merchant To Respond(${total?.merchatToRespond || 0})`}
                {...a11yProps(4)}
              />
              <Tab
                label={`UU2 To Respond(${total?.UU2ToRespond || 0})`}
                {...a11yProps(5)}
              />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
              <AllReturnRefund
                totalItem={total.total}
                search={search}
                status=""
                setTotals={setTotal}
                reset={handleResetSearch}
              />

            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              <AllReturnRefund
                totalItem={total.processing}
                search={search}
                status="PROCESSING"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              <AllReturnRefund
                totalItem={total.review}
                search={search}
                status="UNDER_REVIEW"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={3}>
              <AllReturnRefund
                totalItem={total.refund}
                search={search}
                status="REFUNDED"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={4}>
              <AllReturnRefund
                totalItem={total.merchatToRespond}
                search={search}
                status="MERCHANT_TO_RESPOND"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
              <AllReturnRefund
                totalItem={total.UU2ToRespond}
                search={search}
                status="UU2_TO_RESPOND"
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={5}>
              <AllReturnRefund
                afterDelete={afterDelete}
                status="UU2ToRespond"
                search={search}
                totalItem={total.UU2ToRespond}
              />
            </TabPanel>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ReturnRefundIndex;
