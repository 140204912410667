import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IOSSwitch } from "../../../../../../components/switch/IOSSwitch";
import { breadcrumbAction } from "../../../../../../redux/actions/config.action";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Image from "../../../../../../components/Images/Image";
import {
  AllowAccess,
  parseQuery,
  showToast,
  startLoader,
  stopLoader
} from "../../../../../../lib/global";
import InputField from "../../../../../../components/formControl/input/InputField";
import {
  getAllGroupDealsProduct, getGroupDealsProductById,
  updateGroupDealsProduct
} from "../../../../../../redux/actions/marketing.action";
import { getLocalStorage } from "../../../../../../lib/session";
import { v2GetGroupDealsProductById } from "../../../../../../redux/actions/merchant/marketing.merchant.action";

const MerchantGroupDealsProductDetail = () => {
  AllowAccess(["MERCHANT"]);
  const { id } = useParams();
  const merchantId = getLocalStorage("storeId");
  const history = useHistory();
  const dispatch = useDispatch();
  const { groupDealsId } = parseQuery(history.location.search) || {};
  const [allProductData, setAllProductData] = useState([]);
  const [newProductData, setNewProductData] = useState([]);
  let filterData = {};
  let productData = [];
  let newData = [];
  const [variationInfo, setVariationInfo] = useState({});
  const [variationData, setVariationData] = useState([]);
  const [errors, setErrors] = useState({});
  const [isActived, setIsActived] = useState(0);
  const role = getLocalStorage("role");

  useEffect(() => {
    const breadcrumb = [
      { label: "All Promo", url: "/merchant/marketing/promo" },
      { label: "Group Deals", url: "/merchant/marketing/promo/group-deals" },
      { label: "Product", url: `/merchant/marketing/promo/group-deals-product?groupDealsId=${groupDealsId}` },
      { label: "Detail", url: null }
    ];
    dispatch(breadcrumbAction(breadcrumb));
    let payload;
    payload = id
    apiData(payload);
  }, []);

  const apiData = (payload) => {
    startLoader();
    // dispatch(getGroupDealsProductById(payload))
    dispatch(v2GetGroupDealsProductById(payload))
      .then((data) => {
        if(data && data.data && data.data.data && data.data.data.length > 0){
          if(role == "MERCHANT" && data.data.data[0].merchantId != merchantId){
            history.push(`/404`)
          }
          setAllProductData(
            data &&
            data.data &&
            data.data.data.map((row, index) => {
              return {
                groupDealsProductId: row?.groupDealsProductId,
                productId: row?.product.productId,
                productImages: row?.product.productImages[0],
                title: row?.product.title,
                variationList: row?.variation,
              };
            })
          );
        }
        
        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
      });
  };


  useEffect(() => {
    // validationBeforeConfirm();
  }, [allProductData]);


  const validationBeforeConfirm = () => {
    const errorsList = {
      teamCampaignStock: false,
      teamUnitCapped: false,
      teamDiscountPrice: false,
      groupDealsTeamMember: false,
    };

    allProductData.map((row, index) => {
      row.variationList.map((row2, idx) => {
        if (!row2.teamCampaignStock) {
          errorsList.teamCampaignStock = true;
        }
        if (!row2.teamUnitCapped) {
          errorsList.teamUnitCapped = true;
        }
        if (!row2.teamDiscountPrice) {
          errorsList.teamDiscountPrice = true;
        }
        if (!row2.groupDealsTeamMember) {
          errorsList.groupDealsTeamMember = true;
        }
        if (row2.isActive || row2.teamIsActive) {
          setIsActived(1);
        }
      });
    });

    setErrors((pre) => ({ ...pre, ...errorsList }));
    return errorsList;
  };

  const onChangeVariation = (
    newProductData,
    productIndex,
    variationIndex,
    e
  ) => {
    const key = e.target.name;
    const value = e.target.value;
    let tempProductData = [];

    allProductData.map((row, index) => {
      let tempProduct = row;
      if (index === productIndex) {
        tempProduct.variationList.map((rowData, indexData) => {
          if (indexData === variationIndex) {
            if (key === "teamCampaignStock") {
              rowData.teamCampaignStock = value;
            } else if (key === "teamUnitCapped") {
              rowData.teamUnitCapped = value;
            } else if (key === "teamDiscountPrice") {
              rowData.teamDiscountPrice = value;
            }
            else if (key === "groupDealsTeamMember") {
              rowData.groupDealsTeamMember = value;
            }
          }
        });
      }
      tempProductData.push(tempProduct);
    });
    setAllProductData(tempProductData);
    setNewProductData(tempProductData);
    // validationBeforeConfirm();
  };

  return (
    <React.Fragment>
      <div className=" d-flex flex-column justify-content-between container_body vh-100">
        <div className="container-fluid">

          <div className="card p-4 mb-3">
            <div>
              <div className="float-left">
                <h6>DETAIL GROUP DEALS PRODUCT</h6>
                <p className="fntSz14 mb-0 text-secondary">
                  Detail product to participate in Group Deal event.
                </p>
              </div>
              <div className={`float-right d-block`}></div>
            </div>
            <hr />
            <br />
            <br />

            {allProductData &&
              allProductData.length > 0 &&
              allProductData.map((row, index) => (
                <div className="mb-3" key={"all-product-data-" + row.productId}>
                  <div>
                    <div className="float-left d-flex align-items-center flex-nowrap ">
                      <Image
                        src={row.productImages}
                        alt={"photo"}
                        className="teamPurchase_page_product_image"
                      />
                      <div className="px-2">{row.title}</div>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />

                  <div className={`float-right d-block w-100`}>
                    <div
                      className="border"
                      style={{ borderRadius: "10px", overflow: "hidden" }}
                    >
                      <div className=" bg-light">
                        <div className="pl-3 pt-3 mb-0 fntSz18">
                          Team Purchase
                        </div>
                        <hr />
                      </div>
                      <div className="mx-3">
                        <TableContainer>
                          <Table
                            sx={{ minWidth: 650 }}
                            stickyHeader
                            aria-label="sticky table"
                          >
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  className="th_cell"
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Variation(s)</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell"
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Original Price</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Discounted Price</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>TP Member</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Campaign Stock</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Stock</div>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className="th_cell "
                                  component="th"
                                  align="left"
                                >
                                  <div className="d-flex  ">
                                    <div>Unit Capped</div>
                                  </div>
                                </TableCell>

                                <TableCell
                                  className="table_action_head text-secondary"
                                  component="th"
                                >
                                  Enable/Disable
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {row.variationList &&
                                row.variationList.length > 0 &&
                                row.variationList.map((row2, index2) => (
                                  <TableRow
                                    // key={'team-purchase-'+index2}
                                    className="border-0"
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <div dangerouslySetInnerHTML={{__html:row2.concatVariation}}></div>
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      RM. {row2?.teamPrice || "0"}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <InputField
                                        className="input_icon_left"
                                        formClass="col-12 p-0"
                                        type="number"
                                        id="teamDiscountPrice"
                                        icon="currency"
                                        placeholder="Input"
                                        name="teamDiscountPrice"
                                        value={row2?.teamDiscountPrice || 0}
                                        disabled={true}
                                        required={false}
                                      />
                                      <div className="error_container mr-3 rounded px-1 d-flex">
                                        {errors.teamDiscountPrice &&
                                        !row2.teamDiscountPrice ? (
                                          <label className="error fntSz10">
                                            *input team Discount Price !
                                          </label>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <input
                                        type="text"
                                        placeholder="Input"
                                        className="w-75 form-control"
                                        id="groupDealsTeamMember"
                                        name="groupDealsTeamMember"
                                        value={row2?.groupDealsTeamMember || 0}
                                        disabled={true}
                                      />
                                      <div className="error_container mr-3 rounded px-1 d-flex">
                                        {errors.groupDealsTeamMember &&
                                        !row2.groupDealsTeamMember ? (
                                          <label className="error fntSz10">
                                            *input TP Member !
                                          </label>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <input
                                        type="text"
                                        placeholder="Input"
                                        className="w-75 form-control"
                                        id="teamCampaignStock"
                                        name="teamCampaignStock"
                                        value={row2?.teamCampaignStock || 0}
                                        disabled={true}
                                      />
                                      <div className="error_container mr-3 rounded px-1 d-flex">
                                        {errors.teamCampaignStock &&
                                        !row2.teamCampaignStock ? (
                                          <label className="error fntSz10">
                                            *input team Campaign Stock !
                                          </label>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      {row2?.stock || "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <input
                                        type="text"
                                        placeholder="Input"
                                        className="w-75 form-control"
                                        id="teamUnitCapped"
                                        name="teamUnitCapped"
                                        value={row2?.teamUnitCapped || 0}
                                        required={false}
                                        disabled={true}
                                      />
                                      <div className="error_container mr-3 rounded px-1 d-flex">
                                        {errors.teamUnitCapped &&
                                        !row2.teamUnitCapped ? (
                                          <label className="error fntSz10">
                                            *input team Unit Capped !
                                          </label>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    </TableCell>

                                    <TableCell className="d-flex border-0 table_action_item">
                                      <IOSSwitch
                                        checked={
                                          row2.teamIsActive == true
                                            ? true
                                            : false
                                        }
                                        value={row2?.teamIsActive}
                                        name="teamIsActive"
                                      />
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              ))}
          </div>
          {Object.values(errors).filter((item) => item).length ? (
            <div className="error_container mr-3 rounded px-1 d-flex">
              <label className="error fntSz12">
                **Please fill in all input pages !
              </label>
            </div>
          ) : (
            <></>
          )}
        </div>

        <footer className="bg-white p-3 mt-5">
          <div className={`d-block`}>
            <div className="d-flex justify-content-end">
              <button
                onClick={() =>
                  history.push(
                    `/merchant/marketing/promo/group-deals-product?groupDealsId=${groupDealsId}`
                  )
                }
                type="button"
                className="btn btn-outline-danger mx-2"
              >
                Back
              </button>
            </div>
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default MerchantGroupDealsProductDetail;
