import { useMemo, useState } from 'react';
import {
  Avatar,
  Badge,
  Button,
  Card,
  CardHeader,
  Fade,
  Paper,
  Popper,
  Typography,
  IconButton as MIconButton,
} from '@mui/material';
import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import { makeStyles, withStyles } from 'tss-react/mui';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import sendbirdSelectors from '@sendbird/uikit-react/sendbirdSelectors';

const SmallAvatar = withStyles(Avatar, (theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid white`,
  },
}));
const useStyles = makeStyles()((theme) => ({
  selected: {
    backgroundColor: '#9b59b6',
  },
}));


export default function CustomizedChannelPreviewItem({
  channel,
  currentChannelUrl,
}) {
  const classes = useStyles();

  const store = useSendbirdStateContext();
  const onLeaveChannel = sendbirdSelectors.getLeaveGroupChannel(store);
//   const hideChannel = sendbirdSelectors.getHideGroupChannel(store);

  // props
  const channelName = null;
  const { lastMessage } = channel;

  //useState
  const [openLeaveChannel, setOpenLeaveChannel] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // useMemo
  const isCurrentChannel = useMemo(() => {
    return channel.url === currentChannelUrl;
  }, [currentChannelUrl, channel.url]);

  const channelAvatar = useMemo(() => {
    // if (channel.coverUrl) {
    //   return <Avatar src={channel.coverUrl} />;
    // }
    const { members } = channel;
    const membersExcludingMe = members.filter(
      (member) => member.userId !== store.config.userId
    );
    const [firstMember, secondMember] = membersExcludingMe;
    return (
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          membersExcludingMe.length > 1 && (
            <SmallAvatar
              alt="second member"
              src={secondMember && secondMember.profileUrl}
            />
          )
        }
      >
        <Avatar src={firstMember && firstMember.profileUrl} />
      </Badge>
    );
  }, [store.config.userId, channel]);

  const channelTitle = useMemo(() => {
    if (channelName) {
      return channelName;
    }
    const membersNamesExcludingMe = channel.members
      .filter((member) => member.userId !== store.config.userId)
      .map((member) => member.nickname || member.userId)
      .join(', ');
    return membersNamesExcludingMe.length < 30
      ? membersNamesExcludingMe.length === 0
        ? '(No members)'
        : membersNamesExcludingMe
      : `${membersNamesExcludingMe.slice(0, 30)}...`;
  }, [store.config.userId, channel, channelName]);

  // channel type filter
  if (
    channel.isOpenChannel() ||
    channel.isFrozen ||
    channel.isEphemeral ||
    channel.isSuper ||
    channel.isBroadcast
  ) {
    return null;
  }

  // event handlers
  const handleClickChannelPreviewMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenLeaveChannel(!openLeaveChannel);
    event.stopPropagation();
  };

  const handleBlurChannelPreviewItem = (event) => {
    setOpenLeaveChannel(false);
  };

  const handleClickLeaveChannel = async (event) => {
    await channel.hide(
        false,
        true,
    );
  };

  return (
    <div className="customized-channelpreview-item">
      <Popper
        transition
        id="popper-id"
        open={openLeaveChannel}
        anchorEl={anchorEl}
        placement="right-start"
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Button onClick={handleClickLeaveChannel}>Leave Channel</Button>
            </Paper>
          </Fade>
        )}
      </Popper>
      <Card className={isCurrentChannel ? 'sendbird-channel-preview--active' : ''} square>
        <CardHeader
          avatar={channelAvatar}
          action={
            <MIconButton
              aria-describedby="popper-id"
              aria-label="settings"
              size="small"
              onClick={handleClickChannelPreviewMenu}
              onBlur={handleBlurChannelPreviewItem}
            >
              <MoreVertIcon />
            </MIconButton>
          }
          title={channelTitle}
          subheader={
            lastMessage && (
              <Typography variant="caption" color="textSecondary">
                {(lastMessage.messageType === 'admin' ||
                  lastMessage.messageType === 'user') &&
                  (lastMessage.message.length < 60
                    ? lastMessage.message
                    : `${lastMessage.message.slice(0, 60)}...`)}
                {lastMessage.messageType === 'file' &&
                  (lastMessage.url.length < 30
                    ? lastMessage.url
                    : `${lastMessage.url.slice(0, 30)}...`)}
              </Typography>
            )
          }
        />
      </Card>
    </div>
  );
}