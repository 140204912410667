import React from 'react'
import ReactApexChart from 'react-apexcharts';

const LineChart = (props) => {
    return (
        <div id="chart">
            <ReactApexChart
                options={props.options || []}
                series={props.series || []}
                type="line"
                height={props.height || 350} />
        </div>
    )
}

export default LineChart
