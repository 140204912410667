import { Tab, Tabs } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import { TabPanel } from '../../../../components/Tabs/TabPannel';
import Allproduct from './AllProduct';
import InputField from '../../../../components/formControl/input/InputField';
import { AllowAccess } from '../../../../lib/global';



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const initialSearhData = {
    productName: "",
    categoryId: "",
    dateAdded: ""
}

function MerchantAllProduct() {
    AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
    const { mid, merchantName } = useParams()
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState(0);
    const [productName, setProductName] = useState();
    const [categoryName, setCategoryName] = useState("");
    const [search, setSearch] = useState(initialSearhData);
    const [total, setTotal] = useState({})
    const [dateAdded, setDateAdded] = useState('')
    const history = useHistory();

    useEffect(() => {
        if(getLocalStorage('role') === "MERCHANT"){
            history.push("/404");
        }

        const breadcrumb = [
            { label: "Merchant Profile", url: "/merchant-center/profiles" },
            { label: merchantName, url: null }
        ];
        // dispatch(breadcrumbAction(breadcrumb));
        // dispatch(getProductsBymerchantId(mid, { ...search }))
        //     .then((data) => {
        //         console.log(data, "lljljjhggghhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhg");
        //         setTotal(data && data.data && data.data.total);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     })
    }, [tabIndex]);


    const afterDelete = (data) => {
        setTotal(data && data.total);
        setTabIndex(0)
    }

    const searchData = () => {
        setSearch({ productName, categoryName, dateAdded });
    };
    const handleResetSearch = () => {
        setProductName("");
        setCategoryName("");
        setDateAdded("")
        setSearch({ productName: "", categoryName: "", dateAdded: "" });
    };
    return (
        <React.Fragment>
            <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
                <div className="col-12 row flex-wrap">
                    <div className="min-width-200 col-md-3">
                        <label>Product Name</label>
                        <InputField type="text"
                            className="form-control"
                            placeholder="Input"
                            id="productName"
                            value={productName}
                            onChange={(e) => setProductName(e.target.value)}
                        />
                    </div>
                    <div className="min-width-200 col-md-3">
                        <label>Category</label>
                        <InputField type="text"
                            className="form-control"
                            placeholder="Input"
                            id="categoryName"
                            value={categoryName}
                            onChange={(e) => setCategoryName(e.target.value)}
                        />
                    </div>
                    <div className="min-width-200 col-md-3">
                        <label>Date Added</label>
                        <InputField type="date"
                            className="form-control"
                            placeholder="Input"
                            id="dateAdded"
                            value={dateAdded}
                            onChange={(e) => setDateAdded(e.target.value)}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-end mt-3">
                    <button
                        type="button"
                        onClick={() => handleResetSearch()}
                        className="btn btn-outline-dark px-4 mx-3 btn-sm"
                    >
                        Reset
                    </button>
                    <button
                        type="button"
                        className="btn btn-dark btn-sm px-3"
                        onClick={() => searchData()}
                    >
                        Search
                    </button>
                </div>
            </div>
            <br />
            <br />
            <div className="container_body container-fluid">
                <div className="card ">
                    <span>PRODUCT LISTING</span>
                    <div className=" border card  my-3">
                        <Tabs
                            className='col-12 px-0'
                            value={tabIndex}
                            onChange={(e, value) => setTabIndex(value)}
                            aria-label="basic tabs example"
                        >
                            <Tab label={`All(${total?.all || 0})`} {...a11yProps(0)} />
                            <Tab label={`Published(${total?.published || 0})`} {...a11yProps(1)} />
                            <Tab label={`New(${total?.New || 0})`}{...a11yProps(2)} />
                            <Tab label={`Draft(${total?.draft || 0})`} {...a11yProps(3)} />
                            <Tab label={`Deactivated(${total?.deactivated || 0})`} {...a11yProps(4)} />
                            <Tab label={`Deleted(${total?.deleted || 0})`} {...a11yProps(5)} />
                            <Tab label={`Rejected(${total?.rejected || 0})`} {...a11yProps(6)} />
                            {/* <Tab label={`To remove(${total?.toremove || 0})`} {...a11yProps(7)} /> */}
                        </Tabs>
                        <TabPanel value={tabIndex} index={0} >
                            <Allproduct afterDelete={afterDelete} totalItem={total.all} search={search} status={''} merchantId={mid} merchantName={merchantName} setTotals={setTotal}
                                reset={handleResetSearch} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            <Allproduct afterDelete={afterDelete} totalItem={total.published} search={search} status={"PUBLISHED"} merchantId={mid} merchantName={merchantName} setTotals={setTotal}
                                reset={handleResetSearch} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={2}>
                            <Allproduct afterDelete={afterDelete} totalItem={total.New} search={search} status={"NEW"} merchantId={mid} merchantName={merchantName} setTotals={setTotal}
                                reset={handleResetSearch} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={3}>
                            <Allproduct afterDelete={afterDelete} totalItem={total.draft} search={search} status={"DRAFT"} merchantId={mid} merchantName={merchantName} setTotals={setTotal}
                                reset={handleResetSearch} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={4}>
                            <Allproduct afterDelete={afterDelete} totalItem={total.deactivated} search={search} status={"DEACTIVATED"} merchantId={mid} merchantName={merchantName} setTotals={setTotal}
                                reset={handleResetSearch} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={5}>
                            <Allproduct afterDelete={afterDelete} totalItem={total.deleted} search={search} status={"DELETED"} merchantId={mid} merchantName={merchantName} setTotals={setTotal}
                                reset={handleResetSearch} />
                        </TabPanel>
                        <TabPanel value={tabIndex} index={6}>
                            <Allproduct afterDelete={afterDelete} totalItem={total.rejected} search={search} status={"REJECTED"} merchantId={mid} merchantName={merchantName} setTotals={setTotal}
                                reset={handleResetSearch} />
                        </TabPanel>
                        {/* <TabPanel value={tabIndex} index={7}>
                            <Allproduct afterDelete={afterDelete} totalItem={total.toremove} search={search} status={"TOREMOVE"} merchantId={mid} merchantName={merchantName}       setTotals={setTotal}
                reset={handleResetSearch}/>
                        </TabPanel> */}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MerchantAllProduct


// <>
// <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
//     <div className="col-12 row flex-wrap">
//         <div className="min-width-200 col-md-4">
//             <label>Product Name</label>
//             <input type="text"
//                 className="form-control"
//                 placeholder="Input"
//                 name="productName"
//                 value={searchData.productName}
//                 onChange={onChangeSearchDetail}
//             />
//         </div>
//         <div className="min-width-200 col-md-4">
//             <label>Category</label>
//             <SelectInput
//                 options={categoryList}
//                 name="categoryId"
//                 value={searchData.categoryId}
//                 onChange={(e) => onChangeSearchDetail(e, "categoryId", e)}
//                 formSubmitted={false}
//                 placeholder="Select country"
//             />

//         </div>
//         <div className="min-width-200 col-md-4">
//             <label>Date Added</label>
//             <input type="text"
//                 disabled={true}
//                 className="form-control"
//                 placeholder="Date Added"
//             />

//         </div>
//     </div>
//     <div className="d-flex justify-content-end mt-3">
//         <button onClick={ResetSearchDataFun}
//             type="button"
//             className="btn input-inside-text mr-1 btn-white border px-4">
//             Reset
//         </button>
//         <button
//             type="button"
//             className="btn input-inside-text mx-1 btn-secondary px-3"
//             onClick={() => searchDataFun()}
//         >
//             Search
//         </button>
//     </div>
// </div>
// <hr />
// <br />
// <Allproduct merchantId={mid} merchantName={merchantName} productSearchData={productSearchData} />
// </>