import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IOSSwitch } from "../../../../../components/switch/IOSSwitch";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Image from "../../../../../components/Images/Image";
import {
  AllowAccess,
  open_dialog,
  parseQuery,
  showToast,
  startLoader,
  stopLoader
} from "../../../../../lib/global";
import InputField from "../../../../../components/formControl/input/InputField";
import {
  getAllGroupDealsProduct,
  updateGroupDealsProduct,
} from "../../../../../redux/actions/marketing.action";
import ablity from "../../../../../helpers/abilityGuard";

const GroupDealsProduct = () => {
  const [allProductData, setAllProductData] = useState([]);
  const [tempEditProductData, setTempEditProductData] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("Disable");
  const [editProduct, setEditProduct] = useState("disabledEdit");
  const { merchantId, groupDealsId } =
    parseQuery(history.location.search) || {};
  const [listProductInput, setListProductInput] = useState([]);
  const [isActivedProduct, setIsActivedProduct] = useState(0);

  AllowAccess(["SUPERADMIN"]);

  let filterData = {};

  const handleEditTempProductData = ({ indexKey, key, value }) => {
    let tempVariationData = tempEditProductData;
    tempVariationData.variation[indexKey][key] = value;
    console.log(indexKey, key, value, tempVariationData);
    setTempEditProductData(tempVariationData);
  };

  const toggleEditProduct = (productId = null) => {
    if (productId === null) {
      setEditProduct(`disabledEdit`);
      setTempEditProductData([]);
    } else {
      setEditProduct(`enableEdit${productId}`);
      let productIndex = allProductData.findIndex(
        (item) => item.product.productId === productId
      );
      setTempEditProductData(allProductData[productIndex]);
    }
  };

  const handleEditProduct = (productID) => {
    toggleEditProduct();
    let tempPatchGroupDealsProduct = allProductData;
    let keyProduct = tempPatchGroupDealsProduct.findIndex(
      (item) => item.product.productId === productID
    );

    tempPatchGroupDealsProduct[keyProduct] = tempEditProductData;

    let tempToSave = {
      productData: [],
    };
    tempPatchGroupDealsProduct.forEach((item) => {
      tempToSave.productData.push({
        groupDealsProductId: item.groupDealsProductId,
        discountPrice: null,
        campaignStock: null,
        unitCapped: null,
        variation: item.variation,
      });
    });

    console.log(tempToSave);

    startLoader();
    dispatch(updateGroupDealsProduct(tempToSave))
      .then((response) => {
        showToast(response.data.message, "success");
        console.log(response.data);
        stopLoader();
      })
      .catch((error) => {
        console.log(error.response.data);
        showToast(error.response.data, "error");
        stopLoader();
      });
  };

  const selectProductSuccess = (data) => {};

  useEffect(() => {
    filterData = {
      groupDealsId: groupDealsId,
      merchantId: merchantId,
    };
    apiData(filterData);
  }, []);

  useEffect(() => {}, [allProductData]);

  const apiData = (filterData) => {
    startLoader();
    dispatch(getAllGroupDealsProduct(filterData))
      .then((data) => {
        setAllProductData(
          data &&
            data.data &&
            data.data.data.map((row, index) => {
              return {
                groupDealsProductId: row?.groupDealsProductId || "N/A",
                product: row?.product || "N/A",
                variation: row?.variation || "N/A",
              };
            })
        );

        setTimeout(() => {
          stopLoader();
        }, 1000);
      })
      .catch((err) => {
        setTimeout(() => {
          stopLoader();
        }, 1000);
        console.log({ err });
      });
  };

  const onChangeStatus = (tempProduct, row, i, e) => {
    const { variation } = tempProduct;
    const keyCheck = e.target.name;
    const valCheck = e.target.checked;

    variation.map((rowData, indexData) => {
      if (indexData == i) {
        if (valCheck) {
          setIsActivedProduct((a) => a + 1);
          if (keyCheck === "isActive") {
            rowData.isActive = valCheck;
          } else if (keyCheck === "teamIsActive") {
            rowData.teamIsActive = valCheck;
          }
        } else {
          setIsActivedProduct((a) => a - 1);
          if (keyCheck === "isActive") {
            rowData.isActive = valCheck;
          } else if (keyCheck === "teamIsActive") {
            rowData.teamIsActive = valCheck;
          }
        }
      }
    });
  };

  return (
    <React.Fragment>
      <div className=" d-flex flex-column justify-content-between container_body vh-100">
        <div className="container-fluid">
          <div className="card p-4 mb-3">
            <div>
              <div className="float-left">
                <h6>GROUP DEALS PRODUCT</h6>
                <p className="fntSz14 mb-0 text-secondary">
                  List product to participate in group deal event.
                </p>
              </div>
              <div className="float-right"></div>
            </div>
            <hr />
            {allProductData.map((row, index) => (
              <div className="mb-3">
                <div className="row pb-3">
                  <div className="col-6 d-flex align-items-center flex-nowrap ">
                    <input type={"hidden"} value={JSON.stringify(row)} />
                    <Image
                      src={row?.product?.productImages[0]}
                      alt={"photo"}
                      className="teamPurchase_page_product_image"
                    />
                    <div className="px-2">{row.product.title}</div>
                  </div>
                  <div className="col-6 d-flex justify-content-end">
                    <div
                      className={`d-${
                        editProduct !== `enableEdit${row.product.productId}`
                          ? "block"
                          : "none"
                      }`}
                    >
                      <div className="text-primary d-flex flex-nowrap ">
                        <div
                          role="button"
                          onClick={() =>
                            toggleEditProduct(row.product.productId)
                          }
                          className="btn btn-link text-danger"
                        >
                          Edit
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`bg-light d-${
                    editProduct !== `enableEdit${row.product.productId}`
                      ? "block"
                      : "none"
                  } rounded fntSz14 p-3`}
                >
                  <div>
                    <h6 className="mb-3 fntSz18">Team Purchase</h6>
                    {row.variation.map((row, index) => (
                      <div className="row text-secondary">
                        <div className="col-md-3 text-nowrap mb-2">
                          Variation:{" "}
                          <span className="text-dark">
                            {row.variationOption}
                          </span>
                        </div>
                        <div className="col-md-3 text-nowrap mb-2">
                          Original Price:{" "}
                          <span className="text-dark">RM {row.teamPrice}</span>
                        </div>
                        <div className="col-md-2 text-nowrap mb-2">
                          Discounted Price:{" "}
                          <span className="text-dark">
                            RM {row.teamDiscountPrice}
                          </span>
                        </div>
                        <div className="col-md-2 text-nowrap mb-2">
                          Campaign Stock:{" "}
                          <span className="text-dark">
                            {row.teamCampaignStock}
                          </span>
                        </div>
                        <div className="col-md-2 text-center text-nowrap mb-2">
                          Stock:{" "}
                          <span className="text-dark">{row.teamStock}</span>
                        </div>
                        <div className="col-md-3 text-nowrap mb-2">
                          Unit Capped:{" "}
                          <span className="text-dark">
                            {row.teamUnitCapped}
                          </span>
                        </div>
                        <div className="col-md-3 text-nowrap mb-2">
                          Team Member:{" "}
                          <span className="text-dark">
                            {row.groupDealsTeamMember}
                          </span>
                        </div>
                        <div className="col-md-3 text-nowrap mb-2">
                          Enable/Disable:
                          <span
                            className={
                              row.teamIsActive == true
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {row.teamIsActive == true ? " Enable" : " Disable"}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className={`d-${
                    editProduct === `enableEdit${row.product.productId}`
                      ? "block"
                      : "none"
                  }`}
                >
                  <br />
                  <br />
                  <div
                    className="border"
                    style={{ borderRadius: "10px", overflow: "hidden" }}
                  >
                    <div className=" bg-light">
                      <div className="pl-3 pt-3 mb-0 fntSz18">
                        Team Purchase
                      </div>
                      <hr />
                    </div>
                    <div className="mx-3">
                      <TableContainer>
                        <Table
                          sx={{ minWidth: 650 }}
                          stickyHeader
                          aria-label="sticky table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell
                                className="th_cell"
                                component="th"
                                align="left"
                              >
                                <div className="d-flex  ">
                                  <div>Variation(s)</div>
                                </div>
                              </TableCell>
                              <TableCell
                                className="th_cell"
                                component="th"
                                align="left"
                              >
                                <div className="d-flex  ">
                                  <div>Original Price</div>
                                </div>
                              </TableCell>
                              <TableCell
                                className="th_cell "
                                component="th"
                                align="left"
                              >
                                <div className="d-flex  ">
                                  <div>Discounted Price</div>
                                </div>
                              </TableCell>
                              <TableCell
                                className="th_cell "
                                component="th"
                                align="left"
                              >
                                <div className="d-flex  ">
                                  <div>TP Member</div>
                                </div>
                              </TableCell>
                              <TableCell
                                className="th_cell "
                                component="th"
                                align="left"
                              >
                                <div className="d-flex  ">
                                  <div>Campaign Stock</div>
                                </div>
                              </TableCell>
                              <TableCell
                                className="th_cell "
                                component="th"
                                align="left"
                              >
                                <div className="d-flex  ">
                                  <div>Stock</div>
                                </div>
                              </TableCell>
                              <TableCell
                                className="th_cell "
                                component="th"
                                align="left"
                              >
                                <div className="d-flex  ">
                                  <div>Unit Capped</div>
                                </div>
                              </TableCell>

                              <TableCell
                                className="table_action_head text-secondary"
                                component="th"
                              >
                                Enable/Disable
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tempEditProductData.variation &&
                              tempEditProductData.variation.length > 0 &&
                              tempEditProductData.variation.map(
                                (variation, variationKey) => (
                                  <TableRow
                                    key={"team-purchase-" + variationKey}
                                    className="border-0"
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      {variation?.variationName || "N/A"}:{" "}
                                      {variation?.variationOption || "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      {variation?.teamPrice || "0"}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <input
                                        type="text"
                                        placeholder="Input"
                                        className="w-75 form-control"
                                        defaultValue={
                                          variation?.teamDiscountPrice
                                        }
                                        onChange={(e) =>
                                          handleEditTempProductData({
                                            indexKey: variationKey,
                                            key: "teamDiscountPrice",
                                            value: e.target.value,
                                          })
                                        }
                                      />
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <input
                                        type="text"
                                        placeholder="Input"
                                        className="w-75 form-control"
                                        defaultValue={
                                          variation?.groupDealsTeamMember ?? 0
                                        }
                                        onChange={(e) =>
                                          handleEditTempProductData({
                                            indexKey: variationKey,
                                            key: "groupDealsTeamMember",
                                            value: e.target.value,
                                          })
                                        }
                                      />
                                    </TableCell>

                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <input
                                        type="text"
                                        placeholder="Input"
                                        className="w-75 form-control"
                                        defaultValue={
                                          variation?.teamCampaignStock ?? 0
                                        }
                                        onChange={(e) =>
                                          handleEditTempProductData({
                                            indexKey: variationKey,
                                            key: "teamCampaignStock",
                                            value: e.target.value,
                                          })
                                        }
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      {variation?.stock || "N/A"}
                                    </TableCell>
                                    <TableCell
                                      align="left"
                                      className="td_cell border-0"
                                    >
                                      <input
                                        type="text"
                                        placeholder="Input"
                                        className="w-75 form-control"
                                        defaultValue={
                                          variation?.teamUnitCapped ?? 0
                                        }
                                        onChange={(e) =>
                                          handleEditTempProductData({
                                            indexKey: variationKey,
                                            key: "teamUnitCapped",
                                            value: e.target.value,
                                          })
                                        }
                                      />
                                    </TableCell>
                                    <TableCell className="d-flex border-0 table_action_item">
                                      <IOSSwitch
                                        checked={
                                          variation?.teamIsActive == true
                                            ? true
                                            : false
                                        }
                                        name="teamIsActive"
                                        value={variation?.teamIsActive}
                                        onChange={(e) =>
                                          onChangeStatus(
                                            tempEditProductData,
                                            variation,
                                            variationKey,
                                            e
                                          )
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                  <br />
                  <div className="d-flex flex-row justify-content-end">
                    <button
                      onClick={() => toggleEditProduct()}
                      type="button"
                      className="btn btn-outline-danger px-4 mx-2"
                    >
                      Cancel
                    </button>
                    {ablity({ model: "Marketing", action: "update" }) == 1 && (
                      <button
                        onClick={handleEditProduct}
                        className="btn btn-danger px-4 mx-2"
                        aria-disabled="true"
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <footer className="bg-white p-3 mt-5">
          <div className={`d-flex justify-content-end`}></div>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default GroupDealsProduct;
