


import Image from "../../components/Images/Image";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

import Rating from '@mui/material/Rating';

import "../../static/css/merchantProfileCenter/merchantprofileShop.css"

import { getMerchantShopRating } from "../../redux/actions/merchant-center.action";
import {
  AllowAccess,
    startLoader,
    stopLoader
  } from "../../lib/global";

function ShopRattingDetail(props) {
    AllowAccess(["MERCHANT"]);
    const history = useHistory()
    const viewAction = () => {
        history.push("/orders/view-order")
    }
    const [shopRatingData, setShopRatingData] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
      // dispatch(getMerchantShopRating(props.storeId))
      //     .then((data) => {
      //         setShopRatingData(data?.data?.data?.orders || [])
      //         props.setRatting(data?.data?.data?.ratings)
      //         console.log(data?.data?.data?.ratings, "shopRatingData")
      //     }).catch((error) => {
      //         console.log(error);
      //     })
      if(props.storeId || props.replyStatus || props.search) {
          let query = {
              ... props.search,
              replyStatus: props.replyStatus,
          }
          dispatch(getMerchantShopRating(props.storeId,query))
          .then((data) => {
              setShopRatingData(data?.data?.data?.orders || [])
              props?.setRatting(data?.data?.data?.ratings)
          }).catch((error) => {
              setShopRatingData([])
              console.log(error);
          })
      }
    }, [props.storeId, props.replyStatus, props.search])

    const handleSorting = (item, sortType, isDate = false) => {
        startLoader();
        console.log("sotring",item,sortType)
        try {
          if (typeof shopRatingData[0][item] === "number") {
            let sortedData = shopRatingData.sort((a, b) => {
              if (sortType == "asc") {
                return a[item] - b[item];
              } else if (sortType == "desc") {
                return b[item] - a[item];
              }
            });
            setTimeout(() => {
              setShopRatingData([...sortedData]);
              stopLoader();
            }, [1000]);
          } else if (typeof shopRatingData[0][item] === "string" && !isDate) {
            let sortedData = shopRatingData.sort((a, b) => {
              let fa = a[item].toLowerCase(),
                fb = b[item].toLowerCase();
              if (sortType == "asc") {
                if (fa < fb) {
                  return -1;
                }
                if (fa > fb) {
                  return 1;
                }
                return 0;
              } else if (sortType == "desc") {
                if (fa < fb) {
                  return 1;
                }
                if (fa > fb) {
                  return -1;
                }
                return 0;
              }
            });
    
            setTimeout(() => {
              setShopRatingData([...sortedData]);
              stopLoader();
            }, [1000]);
          } else if (typeof shopRatingData[0][item] === "string" && isDate) {
            let sortedData = shopRatingData.sort((a, b) => {
              let da = new Date(a[item]);
              let db = new Date(b[item]);
    
              if (sortType == "asc") {
                return da - db;
              } else if (sortType == "desc") {
                return db - da;
              }
            });
            setTimeout(() => {
              setShopRatingData([...sortedData]);
              stopLoader();
            }, [1000]);
          }
        } catch (error) {
          stopLoader();
        }
      };

    return (
        <React.Fragment>
            <div className="allShopRatingMain">
                <div className="mt-1 py-1  rounded bg-white">
                    <div className="allShopRatingMain">
                        <div className="tableheadShopRatingProduct teamPurchase_borderLine" style={{ minWidth: "18rem" }}>

                            <div className="d-flex  " >
                                <div>Product</div>
                                <div className="d-flex flex-column ">
                                    <ExpandLessIcon
                                    style={{ width: "15px", height: "15px", margin: "0px 3px" }}
                                    onClick={() => handleSorting("productName", "asc")}
                                    />
                                    <ExpandMoreIcon
                                    style={{ width: "15px", height: "15px", margin: "-5px 3px" }}
                                    onClick={() => handleSorting("productName", "desc")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="shopRating_tablehead teamPurchase_borderLine" style={{ minWidth: "30rem" }}>

                            <div className="d-flex  ">
                                <div>Review</div>

                            </div>
                        </div>
                        <div className="shopRating_tablehead teamPurchase_borderLine " style={{ minWidth: "30rem" }}>


                            <div className="d-flex  ">
                                <div>Merchant Reply</div>

                            </div>
                        </div>
                        {!shopRatingData.length && <div>No data found</div>}

                        {shopRatingData && shopRatingData.length > 0 && shopRatingData.map((item, index) => (

                            <div className="shop_rating_info">
                                <div className="shopRating-orderId-detail-maindiv">
                                    <div className="justify-content-between d-flex shopRating-orderId-detail-section"
                                    >
                                        <div className="shopRating_orderId_Detail_Name mx-4">
                                            <p>Buyer: {item?.rating?.consumerName || "N/A"}</p>
                                        </div>
                                    </div>
                                    <div className="shopRating-orderId-detail-indexId">
                                        <div className="justify-content-between d-flex shopRating-orderId-detail-section">
                                            <Rating name="read-only" value={item?.rating?.rating || 0} readOnly />
                                        </div>
                                    </div>
                                    <div className="shopRating-orderId-detail-indexId">
                                        <div className="justify-content-between d-flex shopRating-orderId-detail-section"
                                        >
                                            <div className="shopRating_orderId_Detail_brand mx-4" >
                                                <p>Order ID: {item?.rating?.orderId || "N/A"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="shopRating_tablehead_details_head">
                                    <div className="tableheadShopRatingProduct" style={{ minWidth: "18rem" }} onClick={() => viewAction(item?.orderId)}>
                                        <div className="d-flex">
                                            <Image
                                                src={item?.productImages || "N/A"}
                                                alt={'photo'}
                                                className="shopRating_page_product_image"
                                            />
                                            <div className=" mx-2">
                                                <div className="shopRating_Page_ProductBox_ProductName">
                                                    {item?.productName || "N/A"}
                                                </div>
                                                <div className="shopRating_Page_ProductBox_Product_varient">Variation: {item?.variation?.value}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shopRating_tablehead" style={{ minWidth: "30rem" }}>
                                        <div className="shopRating_Page_productBox_mainSection">
                                            <div className="shopRating_Page_ProductBox_ProductName flex-column mb-1">
                                                {item?.rating?.review || "N/A"}
                                            </div>
                                            <div className="shopRating_Page_ProductBox_Product_varient">{item?.rating?.createdAt ? moment(item?.rating?.createdAt).format("DD/MM/YY HH:mm") : "N/A"}</div>
                                        </div>
                                    </div>
                                    <div className="shopRating_tablehead" style={{ minWidth: "30rem" }}>
                                        <div className="shopRating_Page_productBox_mainSection">
                                            <div className="shopRating_Page_ProductBox_ProductName">{item?.rating?.reply || "N/A"}</div>
                                            <div className="shopRating_Page_ProductBox_Product_varient ">{item?.rating?.updatedAt ? moment(item?.rating?.updatedAt).format("DD/MM/YY HH:mm") : "N/A"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                </div>
            </div>
        </React.Fragment>

    );
}

export default ShopRattingDetail;
