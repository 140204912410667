import {
    Icon,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,

} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../../../../components/Images/SvgIcon";
import { getAllProductList, getProductsBymerchantId, getSortedProducts } from "../../../../../redux/actions/product.action";
import moment from "moment";
// import { getAllCategoryApi } from "../../../services/category.service";
// import {
//     startLoader,
//     stopLoader,
//     showToast    
// } from "../../../lib/global";

import { startLoader, stopLoader, showToast, open_dialog } from "../../../../../lib/global";
import { ACCESS_DENIED, PRODUCT_DELETE_DIALOG } from "../../../../../lib/constant";
import { getLocalStorage } from "../../../../../lib/session";
import Image from "../../../../../components/Images/Image";

const initial = [
    {
        createAt: "11/03/2022",
        description: "nikeStore",
        status: "completed",
        rating: "+RM20.00",
    },
    {
        createAt: "11/03/2022",
        description: "nikeStore",
        status: "completed",
        rating: "-RM20.00",
    },
]

const AllBalance = () => {
    const [allBalanceData, setAllBalanceData] = useState(initial)

    return (
        <React.Fragment >
            <div className="d-flex justify-content-between">
                <div>
                    <input
                        type="date"
                        placeholder="search..."
                        className="form-control mx-3"
                    // onChange={(e) => setSearchData(e.target.value)}
                    />
                </div>
                <div className="d-flex my-3">

                    <button
                        // onClick={() => addProduct()}
                        onClick={() => addProduct(permissions?.product?.includes('create'))}

                        type="button"
                        className="btn btn-dark px-4 text-nowrap mx-3"
                    // disabled={permmisions.product.includes('add') ? "false" : "true"}
                    >
                        Export
                    </button>
                </div>
            </div>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>

                            <TableCell
                                className="th_cell"
                                component="th"
                                align="center"
                            >
                                <div className="d-flex  ">
                                    <div>Date</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            onClick={() => getApiData({ item: "title", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            onClick={() => getApiData({ item: "title", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                className="th_cell "
                                component="th"
                                align="center"
                            >
                                <div className="d-flex  ">
                                    <div>Description</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            onClick={() => getApiData({ item: "Rating", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            onClick={() => getApiData({ item: "Rating", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>

                            <TableCell
                                className="th_cell "
                                component="th"
                                align="center"
                            >
                                <div className="d-flex  ">
                                    <div>Status</div>

                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            onClick={() => getApiData({ item: "categoryName", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            onClick={() => getApiData({ item: "categoryName", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                className="th_cell"
                                component="th"
                                align="center"
                            >
                                <div className="d-flex  ">
                                    <div>Amount</div>

                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            onClick={() => getApiData({ item: "createdAt", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            onClick={() => getApiData({ item: "createdAt", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {!allProductData?.length && (
                            <div className="d-flex justify-content-center">
                                <h3 className="text-muted m-auto">No data found!</h3>
                            </div>
                        )} */}

                        {allBalanceData &&
                            allBalanceData.length > 0 &&
                            allBalanceData.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                    }}

                                >
                                    <TableCell align="start" className="td_cell">
                                        {row.createAt
                                            ? moment(row.createAt).format("DD/MM/YY")
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell align="start" className="td_cell">
                                        {row.description}
                                    </TableCell>
                                    <TableCell align="start" className="">
                                        {row.status}
                                    </TableCell>
                                    <TableCell align="start" className="td_cell">
                                        {row.rating || "N/A"}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={10}
                component="div"
                // count={allProductData.length}
                count={2}
                rowsPerPage={10}
                page={0}
                onPageChange={(e) => {
                    console.log("page change", e);
                }}
            // onRowsPerPageChange={(e)=>{console.log('page change', e)}}
            />

        </React.Fragment >
    )
}

export default AllBalance;