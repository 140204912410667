import React, { useState,useEffect } from "react";
import "../../../../../static/css/deleteModal.css";
import reactTable from "../../../../../components/reactTable/reactTable";
import CloseIcon from "@mui/icons-material/Close";
import ReactTable from 'react-table';
import {
  deleteMerchant,
  getAllMerchantListAfterDelete,
} from "../../../../../redux/actions/merchant-center.action";
import {updateLiveStreamVoucher} from "../../../../../redux/actions/marketing.action";
import { useDispatch } from "react-redux";
import { showToast, startLoader, stopLoader } from "../../../../../lib/global";
import Image from "../../../../../components/Images/Image";
import { formatDate } from "../../../../../lib/helper";
import { changeLiveStreamStatus, updateLiveStream } from "../../../../../redux/actions/marketing.action";

const initialData = [
  {
    id:'7f48bcb4-bd83-4bbd-8c02-f0429dabd350',
    voucherName: "RM 5.0 OFF with min. spend of RM 50.0",
    voucherCode: "ADKLXNCMZ",
    voucherType: "Streaming Exclusive",
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    remain:30,
    status: 0
  },
  {
    id:'f984ecba-e8a6-41d5-8056-8e62cbd32985',
    voucherName: "RM 3.0 OFF with min. spend of RM 50.0",
    voucherCode: "SLKXNCNZ",
    voucherType: "Streaming Exclusive",
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    status: 1
  }
];
const initialData2 = [
  {
    id:'7f48bcb4-bd83-4bbd-8c02-f0429dabd350',
    voucherName: "RM 5.0 OFF with min. spend of RM 50.0",
    voucherCode: "ADKLXNCMZ",
    voucherType: "Streaming Exclusive",
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    remain:30,
    status: 0
  },
  {
    id:'f984ecba-e8a6-41d5-8056-8e62cbd32985',
    voucherName: "RM 3.0 OFF with min. spend of RM 50.0",
    voucherCode: "SLKXNCNZ",
    voucherType: "Streaming Exclusive",
    startDate: "2022-09-01",
    endDate: "2022-09-30",
    status: 1
  }
];

const GoLiveShowPromoDialog = (props) => {
  const [uu2VoucherData, setuu2VoucherData] = useState(props.uu2VoucherData);
  const [shopVoucherData, setshopVoucherData] = useState(props.merchantVoucherData);
  const [liveStreamId, setLiveStreamId] = useState(props.liveStreamId);
  let [remainingSecondsShop, setRemainingSecondsShop] = useState(30);
  let [remainingSecondsUu2, setRemainingSecondsUu2] = useState(30);
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [visibleStatusUu2, setVisibleStatusUu2] = useState(false);
  const [visibleStatusShop, setVisibleStatusShop] = useState(false);
  const STATUS_SHOW = 1;
  const STATUS_REMOVE = 0;
  const dispatch = useDispatch();
  const deleteFun = () => {
    const data = {
      status: "DELETED",
    };
    startLoader();
    const response = dispatch(deleteMerchant(data, props.id));
    response.then((data) => {
      if (data.data.status == 200) {
        const response = dispatch(getAllMerchantListAfterDelete());
        response
          .then((data) => {
            if (data && data.data && data.data.status === 200) {
              setTimeout(() => {
                stopLoader();
                showToast(data.data.message, "success");
                props.onSubmit([...data.data.data]);
                props.onClose();
              }, 1000);
            } else {
              stopLoader();
              showToast("Internal Server Error", "error");
              props.onClose();
            }
          })
          .catch((error) => {
            console.log({ error });
          });
      } else {
        stopLoader();
        showToast("Internal Server Error", "error");
      }
    });
  };
  function statusButtonUu2(status,id, visibleUntil, type) {
      let currentDate = new Date();
      let visibleUntilDate = new Date(visibleUntil);

      if (currentDate > visibleUntilDate) {
        return <button className='badge badge-outline  bg-white text-black-50 text-sm-center' onClick={()=>changeStatus(STATUS_REMOVE,id,type)}>show</button>;
      }else{
        return <button className='badge badge-outline  bg-white text-black-50 text-sm-center' onClick={()=>changeStatus(STATUS_SHOW,id,type)}>remove</button>;
      }
  }

  function statusButtonShop(status,id, visibleUntil, type) {
    let currentDate = new Date();
    let visibleUntilDate = new Date(visibleUntil);

    if (currentDate > visibleUntilDate) {
      return <button className='badge bg-white badge-outline text-black-50 text-sm-center' onClick={()=>changeStatus(STATUS_REMOVE,id,type)}>show</button>;
    }else{
      return <button className='badge bg-white badge-outline text-black-50 text-sm-center' onClick={()=>changeStatus(STATUS_SHOW,id,type)}>remove</button>;
    }
  }

  function changeStatus(status,id,type){
    let temp1 = []
    let temp2 = []
    if(type === 'uu2'){
      temp1 = [...uu2VoucherData];
    }else{
      temp2 = [...shopVoucherData];

    }
    temp1.forEach((item1)=>{
      let currentDate = new Date();
      currentDate.setSeconds(currentDate.getSeconds() + 30);

      let oldDate = new Date();
      oldDate.setDate(oldDate.getDate() - 1);

      if(item1.id === id){
        item1.status = status;
        if(status === 0){
          item1.visibleUntil = currentDate;
        }else{
          item1.visibleUntil = oldDate;
        }
      }
    })

    temp2.forEach((item2)=>{
      let currentDate = new Date();
      currentDate.setSeconds(currentDate.getSeconds() + 30);

      let oldDate = new Date();
      oldDate.setDate(oldDate.getDate() - 1);

      if(item2.id === id){
        item2.status = status;
        if(status === 0){
          item2.visibleUntil = currentDate;
        }else{
          item2.visibleUntil = oldDate;
        }
      }
    })

    if(type === 'uu2'){
      setuu2VoucherData(temp1);
    }else{
      setshopVoucherData(temp2);
    }

      let voucherJoin = [...uu2VoucherData,...shopVoucherData];

      voucherJoin = voucherJoin.map(item => ({
        ...item,
        visibleUntil: item.id === id ? item.visibleUntil : null
      }));

      dispatch(updateLiveStreamVoucher({ id: liveStreamId, vouchers: voucherJoin }))
        .then((res) => {
          console.log("success");
        })
        .catch((err) => {
          console.log("err", err);
        })

    if(status === 0) {
      if(type === 'uu2'){
        setRemainingSecondsUu2(30);
      }else{
        setRemainingSecondsShop(30);
      }
    }

  }

  function showRemainStatusUu2(remain, status, visibleUntil) {
      let currentDate = new Date();
      let visibleUntilDate = new Date(visibleUntil);

        useEffect(() => {
          const checkInterval = setInterval(() => {
            if (currentDate > visibleUntilDate || remainingSecondsUu2 <= 0) {
              clearInterval(checkInterval);
            } else {
              setRemainingSecondsUu2(prevSeconds => prevSeconds - 1);
            }
          }, 1000);

          return () => clearInterval(checkInterval);
        }, [visibleUntil, remainingSecondsUu2]);

        if(currentDate < visibleUntilDate){
          return <span className='badge bg-danger text-white'>Showing {remainingSecondsUu2}s</span>;
        }else{
          return <span className='badge bg-danger text-white'>Showing 0s</span>;
        }
  }

  function showRemainStatusShop(remain, status, visibleUntil) {
    let currentDate = new Date();
    let visibleUntilDate = new Date(visibleUntil);

    useEffect(() => {
      const checkInterval = setInterval(() => {
        if (currentDate > visibleUntilDate || remainingSecondsShop <= 0) {
          clearInterval(checkInterval);
        } else {
          setRemainingSecondsShop(prevSeconds => prevSeconds - 1);
        }
      }, 1000);

      return () => clearInterval(checkInterval);
    }, [visibleUntil, remainingSecondsShop]);

    if(currentDate < visibleUntilDate){
      return <span className='badge bg-danger text-white'>Showing {remainingSecondsShop}s</span>;
    }else{
      return <span className='badge bg-danger text-white'>Showing 0s</span>;
    }
  }



  return (
    <React.Fragment>
      <div>
        <div className="d-flex flex-row align-items-center justify-content-between px-3">
          <p className="mb-0 h4">Show Voucher</p>
          <button
            onClick={props.onClose}
            className="btn fntSz24 text-secondary"
          >
            x
          </button>
        </div>
        <div className="card m-2">
          <div className="h6 ml-2">UU2 Voucher</div>
          <div
            style={{ maxHeight: "30vh", overflow: "auto" }}
            className="fntSz14 position-relative px-2"
          >
            <table className="table border-0">
              <thead className="position-sticky" style={{ top: "0" }}>
              <tr className="bg-light rounded-lg text-secondary">
                <th className="border-0">
                  voucher(s)
                </th>
                <th className="border-0">
                  voucher code
                </th>
                <th className="border-0">
                  valid until
                </th>
                <th className="border-0">
                  Status
                </th>
              </tr>
              </thead>
              <tbody>
              {uu2VoucherData &&
                uu2VoucherData.length > 0 &&
                uu2VoucherData.map((row, index) => (
                  <tr key={row.id}>
                    <td className="border-0 align-middle">
                      <span>{row.name}</span>
                    </td>
                    <td className=" align-middle border-0">
                      {row.code}
                    </td>
                    <td className="align-middle border-0">
                      {formatDate(row.endPeriodDate,"DD-MM-YYYY")}
                    </td>
                    <td>
                      {statusButtonUu2(row.status,row.id,row.visibleUntil,"uu2")}
                      &nbsp;
                      {showRemainStatusUu2(row.remain,row.status,row.visibleUntil)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="text-black"><hr/></div>

        <div className="card m-2">
          <div className="h6 ml-2">Shop Voucher</div>
          <div
            style={{ maxHeight: "30vh", overflow: "auto" }}
            className="fntSz14 position-relative px-2"
          >
            <table className="table border-0">
              <thead className="position-sticky" style={{ top: "0" }}>
              <tr className="bg-light rounded-lg text-secondary">
                <th className="border-0">
                  voucher(s)
                </th>
                <th className="border-0">
                  voucher code
                </th>
                <th className="border-0">
                  valid until
                </th>
                <th className="border-0">
                  Status
                </th>
              </tr>
              </thead>
              <tbody>
              {shopVoucherData &&
                shopVoucherData.length > 0 &&
                shopVoucherData.map((row, index) => (
                  <tr key={row.id}>
                    <td className="border-0 align-middle">
                      <span>{row.name}</span>
                    </td>
                    <td className=" align-middle border-0">
                      {row.code}
                    </td>
                    <td className="align-middle border-0">
                      {formatDate(row.endPeriodDate,"DD-MM-YYYY")}
                    </td>
                    <td>
                      {statusButtonShop(row.status,row.id, row.visibleUntil, 'shop')}
                      &nbsp;
                      {showRemainStatusShop(row.remain,row.status, row.visibleUntil)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <style jsx="true">{`
          .card-body {
            min-height: 70vh;
          }
          .submit_btn {
            position: absolute;
            bottom: 15px;
            width: 100%;
            justify-content: center;
            display: flex;
          }
          .close_icon_btn {
            position: absolute;
            right: 0;
            cursor: pointer;
          }
          .flash_sale_product_image {
            width: 45px;
            height: 40px;
            border-radius: 3px;
          }
          input {
            cursor: pointer;
          }
          .badge-outline {
              border: 1px solid #6c757d; /* Gray border color */
              color: #6c757d; /* Gray text color */
              background-color: transparent; /* Transparent background */
              padding: 0.25rem 0.5rem; /* Adjust padding as needed */
          }
        `}</style>
      </div>
    </React.Fragment>
  );
};

export default GoLiveShowPromoDialog;
