import { GetWithToken, PostWithToken, DeleteById } from "../../lib/request";

export const createNewProduct = (data) => {
  return async function (dispatch) {
    const result = await PostWithToken("/product/createproduct", data);
    return result;
  };
};

export const getAllCategoryList = (query = { search: "" }) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/categories/search?search=${
        encodeURIComponent(query?.search?.trim()) || ""
      }&item=${query?.item || ""}&order=${query?.order || ""}&offset=${
        query?.offset || 0
      }&limit=${query?.limit || 0}`
    );
    return result;
  };
};

export const getCategoryDetailsById = (id) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/categories/getone/${id}`);
    return result;
  };
};

export const getAllSubCategoriesList = (id, query) => {
  console.log(id, query, "hhhhhhhhjjjjjj");
  return async function (dispatch) {
    const result = await GetWithToken(
      `/categories/${id}?&search=${query?.search || ""}&item=${
        query?.item || ""
      }&order=${query?.order || ""}`
    );
    return result;
  };
};

export const getAllCategoryForSearch = (id) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/categories/get-category-for-search`);
    return result;
  };
};

export const deleteCategoryById = (id) => {
  return async function (dispatch) {
    const result = await DeleteById(`/categories/${id}`);
    console.log(result);
    return result;
  };
};
