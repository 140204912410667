import { GET_ONE_PRODUCT_BY_MERCHANT } from "../../types/merchant/productTypes.merchant";

const initialState = {
  product: {},
};

export const merchantProductReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ONE_PRODUCT_BY_MERCHANT:
      return {
        ...state,
        product: payload,
      };
    default:
      return state;
  }
};
