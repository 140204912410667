import {
    Icon,
    IconButton,
    Pagination,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,

} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import React, { useEffect, useState } from "react";
import { useHistory,useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../../../../components/Images/SvgIcon";
import { getAllProductList } from "../../../../../redux/actions/product.action";
import { getAllSettlementData } from "../../../../../redux/actions/order.action";


import moment from "moment";


import { startLoader, stopLoader, showToast, open_dialog } from "../../../../../lib/global";
import { ACCESS_DENIED, PRODUCT_DELETE_DIALOG } from "../../../../../lib/constant";
import { getLocalStorage } from "../../../../../lib/session";
import Image from "../../../../../components/Images/Image";


function IncomeToRelease(props) {
    const [permissions, setPermissions] = useState(getLocalStorage("assignMethod"))
    const history = useHistory();
    // const [title, setTitle] = useState()
    const dispatch = useDispatch();
    const [allProductData, setAllProductData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [searchData, setSearchData] = useState({item:"",order:""})
    const [totolCatetory, setTotalCategory] = useState(0);

   const {mid}=useParams()

    useEffect(() => {

        if (permissions !== 'undefined') {
            // console.log(typeof(permissions),'hfggyhhhhhhhhhhhhhgyfffff');
            // console.log(getLocalStorage("assignMethod"),'lllllllllllllllllllllllllljj');
            setPermissions(JSON.parse(getLocalStorage("assignMethod")))
            console.log(JSON.parse(getLocalStorage("assignMethod")), "hhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
        } else {
            setPermissions({})
        }
    }, [])


    useEffect(() => {
        // startLoader()
        const payload={
            ...props.search,
            searchData,
            status:"toSettle"

        }
        if (!props.merchantId) {
            const response = dispatch(getAllSettlementData(mid,payload))
            response
                .then((data) => {
                    console.log(data.data.data, 'data data ');
                    setAllProductData(data && data.data && data.data.data)
                    setTotalCount(data.data.total)
                    // stopLoader()
                }).catch(error => {
                    // stopLoader()
                    console.log({ error }, 'error');
                })
        } else {
            const response = dispatch(getAllSettlementData(props.merchantId,payload))
            response
                .then((data) => {
                    console.log(data.data.data, "datvvvvvvvvvvvvvvvabbbbbbbbbbbbbbbbbbbbbbb");
                    setAllProductData(data && data.data && data.data.data)
                    setTotalCount(data.data.total)
                    // stopLoader()
                }).catch(error => {
                    // stopLoader()
                    console.log({ error }, 'error');
                })
        }
    }, [searchData,props.search]);



  

    return (
        <React.Fragment >

            <TableContainer>
                <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>

                            <TableCell
                                className="th_cell"
                                component="th"
                                align="center"
                            >
                                <div className="d-flex  ">
                                    <div>Order</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            // onClick={() => setSearchData({ item: "title", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            // onClick={() => setSearchData({ item: "title", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                className="th_cell "
                                component="th"
                                align="center"
                            >
                                <div className="d-flex  ">
                                    <div>Estimated Released Date</div>
                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            // onClick={() => setSearchData({ item: "Rating", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            // onClick={() => setSearchData({ item: "Rating", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>

                            <TableCell
                                className="th_cell "
                                component="th"
                                align="center"
                            >
                                <div className="d-flex  ">
                                    <div>Status</div>

                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            // onClick={() => setSearchData({ item: "categoryName", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            // onClick={() => setSearchData({ item: "categoryName", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell
                                className="th_cell"
                                component="th"
                                align="center"
                            >
                                <div className="d-flex  ">
                                    <div>Payout Amount</div>

                                    <div className="d-flex flex-column ">
                                        <ExpandLessIcon
                                            style={{ width: '15px', height: '15px', margin: "0px 3px" }}
                                            // onClick={() => setSearchData({ item: "createdAt", order: "ASC" })}

                                        />
                                        <ExpandMoreIcon
                                            style={{ width: '15px', height: '15px', margin: "-5px 3px" }}
                                            // onClick={() => setSearchData({ item: "createdAt", order: "DESC" })}

                                        />
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {!allProductData?.length && (
                            <div className="d-flex justify-content-center">
                                <h3 className="text-muted m-auto">No data found!</h3>
                            </div>
                        )} */}

                        {allProductData &&
                            allProductData.length > 0 &&
                            allProductData.map((row, index) => (
                                <TableRow
                                    key={"s"+index}
                                    sx={{
                                        "&:last-child td, &:last-child th": { border: 0 },
                                    }}

                                >
                                    <TableCell align="start" className="td_cell">

                                        <div className="d-flex">
                                            <Image
                                                src={row?.productImages || "N/A"}
                                                alt={'photo'}
                                                className="shopRating_page_product_image"
                                            />
                                            <div className=" mx-2">
                                                <div className="shopRating_Page_ProductBox_ProductName">
                                                    {row.productName || "0"}
                                                </div>
                                                <div className="shopRating_Page_ProductBox_Product_varient">Buyer: {row.consumerName}</div>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell align="start" className="td_cell">
                                        {row.updatedAt
                                            ? moment(row.updatedAt).format("DD/MM/YY")
                                            : "N/A"}
                                    </TableCell>
                                    <TableCell align="start" className="td_cell">
                                        {row?.status || "N/A"}
                                    </TableCell>
                                    <TableCell align="start" className="td_cell">
                                        {row?.price*row?.quantity || "N/A"}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
            <div className="float-right mb-1">
                <Stack spacing={2}>
                    <Pagination
                        count={Math.ceil(totalCount / 10)}
                        shape="rounded"
                        size="small"
                        onChange={(e,value)=>setSearchData({...searchData,offset:value-1})}
                    />
                </Stack>
            </div>
            <br />

        </React.Fragment >
    );

}

export default IncomeToRelease;