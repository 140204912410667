import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import Checkbox from "../../../components/checkbox";
import InputField from "../../../components/formControl/input/InputField";
import SelectDropdown from "../../../components/select";
import { AllowAccess, showToast } from "../../../lib/global";
import { languageList, stateName } from "../../../shared/language";

function EditConsumerShoppingAddress(props) {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  console.log(props, "shopping");
  const [fullName, setFullName] = useState(props.fullName);
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber);
  const [addressLine1, setAddressLine1] = useState(props.addressLine1);
  const [addressLine2, setAddressLine2] = useState(props.addressLine2);
  const [postalCode, setPostalCode] = useState(props.postalCode);
  const [state, setState] = useState({
    value: props.state,
    label: props.state,
  });
  const [setAsPickupAddress, setSetAsPickupAddress] = useState(
    props.setAsPickupAddress
  );
  const [setAsReturnAddress, setSetAsReturnAddress] = useState(
    props.setAsReturnAddress
  );
  console.log(setAsPickupAddress, setAsReturnAddress);

  const handleSubmit = () => {
    if (!phoneNumber) {
      showToast("phone Number not be empty", "error");

      return;
    }
    if (
      !(String(phoneNumber).length == 11 || String(phoneNumber).length == 12)
    ) {
      showToast("phone Number should be 11 to 12 digit long", "error");
      return;
    }
    if (!addressLine1) {
      showToast("address must", "error");
      return;
    }
    if (!postalCode) {
      showToast("postal code not be empty", "error");
      return;
    }
    if (!state.value) {
      showToast("state is must ", "error");
      return;
    }
    if (!state.value) {
      showToast("state is must ", "error");
      return;
    }

    const address = `${addressLine1}, ${addressLine2}, ${postalCode}, ${state.value}`;
    const payload = {
      id: props.id,
      fullName,
      phoneNumber,
      addressLine1,
      addressLine2,
      postalCode,
      state: state.value,
      setAsPickupAddress,
      setAsReturnAddress,
      address,
      index: props.index,
    };
    console.log(payload);
    props.onSubmit(payload);
    props.onClose();
  };
  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };
  return (
    <React.Fragment>
      <div className="col-12 p-0 m-0 card">
        <div className="close_icon">
          <CloseIcon
            className="close_icon_btn"
            color="primary2"
            fontSize="large"
            title="Close dialog"
            onClick={props.onClose}
          />
        </div>
        <div className="card-header d-flex justify-content-center">
          <h4>Edit Shopping Address</h4>
        </div>
        <div
          style={{ height: "78vh" }}
          className="card-body add-authoried-card-body"
        >
          <form className="col-12 row">
            <div className="col-12 my-2">
              <label htmlFor="fullname" className="col-12 m-0 p-0 imp">
                Full Name
              </label>
              <InputField
                id="fullname"
                className="w-100"
                type="text"
                placeholder="Fullname"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            <div className="col-12 mt-2">
              <label htmlFor="phoneNumber" className="col-12 m-0 p-0 imp">
                Phone Number
              </label>
              <InputField
                className="w-100"
                type="text"
                onKeyPress={(e) => handleKeyPress(e)}
                id="phoneNumber"
                placeholder="Enter your phone Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
            <div className="col-12 mt-2">
              <label htmlFor="address" className="col-12 m-0 p-0 imp">
                Address
              </label>
              <InputField
                className="w-100"
                type="text"
                id="address"
                placeholder="Address line 1"
                value={addressLine1}
                onChange={(e) => setAddressLine1(e.target.value)}
              />
              <InputField
                className="w-100"
                type="text"
                placeholder="Address line 2"
                value={addressLine2}
                onChange={(e) => setAddressLine2(e.target.value)}
              />
            </div>

            <div className="col-12 row m-0 p-0">
              <div className="col-md-6">
                <InputField
                  className="w-100"
                  formClass="mt-0"
                  type="number"
                  id="postal-code"
                  placeholder="Postal code"
                  onKeyPress={(e) => handleKeyPress(e)}
                  value={postalCode}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </div>
              <div className="col-md-6 m-auto">
                <SelectDropdown
                  options={stateName}
                  placeholder="Select State"
                  value={state}
                  onChange={setState}
                />
              </div>
            </div>
            <div className="col-12 my-2">
              <Checkbox
                formControlClass={"text-muted m-0"}
                className={"text-dark"}
                checked={setAsPickupAddress}
                onChange={(e) => setSetAsPickupAddress(e.target.checked)}
                label={"Set As Pickup Address"}
              />
              <br />
              <Checkbox
                formControlClass={"text-muted m-0"}
                className={"text-dark"}
                checked={setAsReturnAddress}
                onChange={(e) => setSetAsReturnAddress(e.target.checked)}
                label={"Set As Return Address"}
              />
            </div>
            {/* <div className="col-12 my-2">
              </div> */}
          </form>
        </div>
        <div className="submit_btn col-12 m-0 p-0">
          <button
            disabled={
              !fullName ||
              !phoneNumber ||
              !addressLine1 ||
              !postalCode ||
              !state
            }
            onClick={handleSubmit}
            className="btn btn-primary"
          >
            Submit
          </button>
        </div>
      </div>
      <style jsx="true">{`
        .card-body {
          min-height: 60vh;
        }
        .submit_btn {
          position: absolute;
          bottom: 15px;
          width: 100%;
          justify-content: center;
          display: flex;
        }
        .close_icon_btn {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      `}</style>
    </React.Fragment>
  );
}

export default EditConsumerShoppingAddress;
