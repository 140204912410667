import React from 'react'
import { useDispatch } from 'react-redux';
import { breadcrumbAction } from '../../../redux/actions/config.action';
import { AllowAccess } from '../../../lib/global';

const WebBanner = () => {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);

    const dispatch = useDispatch();

  const breadcrumb = [{ label: "Web-Banner", url: null }];
  dispatch(breadcrumbAction(breadcrumb));

  return (
    <div>WebBanner</div>
  )
}

export default WebBanner