import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ImageCarousel from "./index";

const ImageModal = (props) => {
  const { modalIsOpen, setModalIsOpen, imageUrls } = props;
  const handleClose = () => setModalIsOpen(false);
  return (
    <div
      className="modal show"
      style={{
        display: "block",
        position: "initial",
      }}
    >
      <Modal show={modalIsOpen} onHide={handleClose}>
        <Modal.Body
          className="d-block w-100 d-flex justify-content-center align-items-center"
          style={{ width: "1000px", height: "350px" }}
        >
          <ImageCarousel imageUrls={imageUrls} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImageModal;
