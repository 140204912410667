import { Tab, TablePagination, Tabs } from "@mui/material";
import React, { useState } from "react";
import { TabPanel } from "../../../../../components/Tabs/TabPannel";
import AllBalance from "./AllBalance";
import OrderIncomeBalance from "./OrderIncomeBalance";
import WithdrawalBalance from "./WithdrawalBalance";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MerchantBalance = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [activeButton, setActiveButton] = useState("release");

  return (
    <React.Fragment>
      <div>
        <h2>
          Balance Overview{" "}
          <span className="text-secondary px-2 fntSz16">
            Preferred Name: <span className="text-dark">Nike eStore</span>
          </span>
        </h2>

        <br />
        <div className="row">
          <div className="col-md-7 d-flex align-items-end">
            <div className="pr-4">
              <div>Seller Balance</div>
              <div className="text-secondary">Total</div>
              <div>RM30.00</div>
            </div>
            <div>
              <button
                onClick={() => setActiveButton("release")}
                className={`btn ${
                  activeButton == "release"
                    ? "merchant_balance_reset_btn"
                    : "btn-outline-dark"
                } mx-1`}
              >
                Release
              </button>
              <button
                onClick={() => setActiveButton("reset")}
                className={`btn ${
                  activeButton == "reset"
                    ? "merchant_balance_reset_btn"
                    : "btn-outline-dark"
                } mx-1`}
              >
                Reset PIN
              </button>
            </div>
          </div>
          <div className="col-md-5">
            <div className="merchant_income_statement py-3">
              <h6 className="px-3">Merchant Bank Account</h6>
              <div className="marchant_income_statement_section px-3">
                <div>
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td className="text-secondary">
                          Maybank{" "}
                          <span className="pl-1 text-danger">Default Bank</span>
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          className=" float-right"
                        >
                          ******9889
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="container_body">
          <div className="card">
            <div className=" border card  my-3">
              <Tabs
                value={tabIndex}
                onChange={(e, value) => setTabIndex(value)}
                variant="scrollable"
                orientation="horizontal"
                scrollButtons={false}
                visibleScrollbar={false}
                className="px-3"
                aria-label="basic tabs example"
              >
                <Tab label="All" {...a11yProps(0)} />
                <Tab label=" Order Income" {...a11yProps(1)} />
                <Tab label="Withdrawal" {...a11yProps(2)} />
              </Tabs>
              <TabPanel value={tabIndex} index={0}>
                <AllBalance />
              </TabPanel>
              <TabPanel value={tabIndex} index={1}>
                <OrderIncomeBalance />
              </TabPanel>
              <TabPanel value={tabIndex} index={2}>
                <WithdrawalBalance />
              </TabPanel>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MerchantBalance;
