import {
  Icon,
  IconButton,
  Pagination,
  PaginationItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import SvgIcon from "../../../components/Images/SvgIcon";
import { } from "../../../redux/actions/product.action";
import moment from "moment";
import { ExportCSV } from "../../../components/export/exportXlsxFile";

import {
  startLoader,
  stopLoader,
  showToast,
  open_dialog,
  AllowAccess,
} from "../../../lib/global";
import { DELETE_CONSUMER } from "../../../lib/constant";
// import { getLocalStorage } from "../../../lib/session";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import { getAllConsumerList } from "../../../redux/actions/consumer-center.action";
import PagenationDropdown from "../../../components/DropDown/pagenationDropdown";
import ablity from "../../../helpers/abilityGuard";

const initialSearchData = { offset: 0, item: "", order: "" };

function AllConsumerAccounts(props) {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchData, setSearchData] = useState(initialSearchData);

  const [consumerData, setConsumerData] = useState([]);

  const [pageNo, setPageNo] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const limit = useRef(10);
  const [offset, setOffset] = useState(0);
  const refSearchData = useRef({});
  const { afterDelete } = props;

  console.log(props);
  const { status } = props;

  useEffect(async () => {
    const breadcrumb = [{ label: "Consumer Account", url: null }];
    dispatch(breadcrumbAction(breadcrumb));

    let filteringdata;
    if (pageLimit != limit.current) {
      console.log("limit");
      limit.current = pageLimit;
      props.reset();
      setPageNo(1);
      setOffset(0);
      filteringdata = {
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
    } else if (refSearchData.current !== props.search) {
      console.log("opopopopoppop");
      setPageNo(1);
      setOffset(0);
      filteringdata = {
        ...props.search,
        status: props.status,
        limit: pageLimit,
        offset: 0,
      };
      refSearchData.current = props.search;
    } else {
      console.log("limit23232q32323");

      filteringdata = {
        ...props.search,
        status: props.status,
        offset,
        limit: pageLimit,
      };
    }
    apiData(filteringdata);
  }, [offset, pageLimit, props.search]);

  const apiData = (filteringData) => {
    startLoader();
    const response = dispatch(getAllConsumerList(filteringData));
    response
      .then((data) => {
        if (data && data.data && data.data.status === 200) {
          props.setTotals(data.data.total || {})
          setTimeout(() => {
            setConsumerData(data && data.data && data.data.data);
            console.log(data.data.data);
            // props.searchChangeCount(data.data.total);
            // setSearchData(initialSearchData)
            stopLoader();
          }, 1000);
        } else {
          setTimeout(() => {
            stopLoader();
          }, 1000);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          console.log("----------error----", error);
          stopLoader();
        }, 1000);
      });
  };

  const handlePageChange = (event, value) => {

    setOffset(value - 1);
    setPageNo(value);
  };

  const getConsumerDataAfterDelete = (data, total) => {
    afterDelete(data);
    apiData({})

  };

  const handleDeleteConsumer = async (id) => {
    open_dialog(DELETE_CONSUMER, {
      onSubmit: getConsumerDataAfterDelete,
      id,
    });
  };

  const addConsumerAccount = () => {
    history.push("/consumer-center/add-consumer");
  };
  const ViewAndEditConsumerAccount = (consumerId) => {
    history.push(`/consumer-center/edit-consumer/${consumerId}`);
  };

  const handleSorting = (item, sortType, isDate = false) => {
    startLoader();
    try {
      if (typeof consumerData[0][item] === "number") {

        let sortedData = consumerData.sort((a, b) => {
          if (sortType == "asc") {
            return a[item] - b[item];
          } else if (sortType == "desc") {
            return b[item] - a[item];
          }
        });
        setTimeout(() => {
          setConsumerData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof consumerData[0][item] === "string" && !isDate) {
        let sortedData = consumerData.sort((a, b) => {
          let fa = a[item].toLowerCase(),
            fb = b[item].toLowerCase();
          if (sortType == "asc") {
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          } else if (sortType == "desc") {
            if (fa < fb) {
              return 1;
            }
            if (fa > fb) {
              return -1;
            }
            return 0;
          }
        });
        console.log(sortedData, "llllllllll");
        setTimeout(() => {
          setConsumerData([...sortedData]);
          stopLoader();
        }, [1000]);
      } else if (typeof consumerData[0][item] === "string" && isDate) {
        let sortedData = consumerData.sort((a, b) => {
          let da = new Date(a[item]);
          let db = new Date(b[item]);

          console.log(da, db);

          if (sortType == "asc") {
            return da - db;
          } else if (sortType == "desc") {
            return db - da;
          }
        });
        setTimeout(() => {
          setConsumerData([...sortedData]);
          stopLoader();
        }, [1000]);
      }
    } catch (error) {
      console.log({ error });
      stopLoader()
    }
  };
  const ll = [
    {
      name: 'satyam',
      age: 13
    },
    {
      name: 'amit',
      age: 15
    },

  ]

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between">
        <div>
          <span>ALL CONSUMER</span>
        </div>
        <div className="d-flex mb-3">
          {/* <button type="button" className="btn btn-dark text-nowrap mx-3">
            Export
          </button> */}
          <ExportCSV csvData={consumerData} fileName={'consumerData'} />
          {ablity({model:'Consumer', action:'create'}) == 1 && (
              <button
                type="button"
                onClick={() => addConsumerAccount()}
                className="btn btn-dark text-nowrap mx-3"
              // disabled={permmisions.product.includes('add') ? "false" : "true"}
              >
                + Add Consumer
              </button>
          )}
        </div>
      </div>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell className="th_cell" component="th">
                No.
              </TableCell>

              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Name</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("consumerName", "asc")}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("consumerName", "desc")}

                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Date Registered</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("createdAt", "asc", true)}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("createdAt", "desc", true)}

                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Last Active</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("updatedAt", "asc", true)}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("updatedAt", "desc", true)}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Email</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("email", "asc")}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("email", "desc")}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell className="th_cell" component="th" align="center">
                <div className="d-flex  ">
                  <div>Contact</div>
                  <div className="d-flex flex-column ">
                    <ExpandLessIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "0px 3px",
                      }}
                      onClick={() => handleSorting("phoneNumber", "asc")}

                    />
                    <ExpandMoreIcon
                      style={{
                        width: "15px",
                        height: "15px",
                        margin: "-5px 3px",
                      }}
                      onClick={() => handleSorting("phoneNumber", "desc")}

                    />
                  </div>
                </div>
              </TableCell>

              <TableCell
                className="table_action_head"
                component="th"
                align="center"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!consumerData?.length && (
              <h3 className="text-muted m-auto">No data found!</h3>
            )}
            {consumerData &&
              consumerData.length > 0 &&
              consumerData.map((row, iindex) => (
                <TableRow
                  key={iindex}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="center" className="td_cell">
                    {offset * pageLimit + (iindex + 1)}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.consumerName || "N/A"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.createdAt
                      ? moment(row.createdAt).format("DD/MM/YYYY")
                      : "N/A"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {moment(row.updatedAt).format("DD/MM/YYYY") || "N/A"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.email || "N/A"}
                  </TableCell>
                  <TableCell align="left" className="td_cell">
                    {row.phoneNumber || "N/A"}
                  </TableCell>

                  <TableCell className="d-flex justify-content-between table_action_item">
                    {ablity({model:'Consumer', action:'read'}) == 1 && (
                        <IconButton
                          className="pointer"
                          onClick={() => ViewAndEditConsumerAccount(row.consumerId)}
                        >
                          <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
                        </IconButton>
                    )}

                    {ablity({model:'Consumer', action:'delete'}) == 1 && (
                        <IconButton
                          className="pointer"
                          onClick={() => handleDeleteConsumer(row.consumerId)}
                        >
                          <SvgIcon src="/icons/delete_icon.svg" alt="delete icon" />
                        </IconButton>
                    )}

                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      <div className="d-flex justify-content-between align-items-center">
        <PagenationDropdown setPageQuant={setPageLimit} />

        <div className="float-right mb-1">
          <Stack spacing={2}>
            <Pagination
              count={Math.ceil((props?.totalItem || 0) / pageLimit)}
              shape="rounded"
              size="small"
              page={pageNo}
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
      <br />
    </React.Fragment>
  );
}

export default AllConsumerAccounts;

// import {
//   Icon,
//   IconButton,
//   Pagination,
//   PaginationItem,
//   Stack,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TablePagination,
//   TableRow,
// } from "@mui/material";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
// import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import SvgIcon from "../../../components/Images/SvgIcon";
// import {} from "../../../redux/actions/product.action";
// import moment from "moment";

// import {
//   startLoader,
//   stopLoader,
//   showToast,
//   open_dialog,
// } from "../../../lib/global";
// import { DELETE_CONSUMER } from "../../../lib/constant";
// // import { getLocalStorage } from "../../../lib/session";
// import { breadcrumbAction } from "../../../redux/actions/config.action";
// import { getAllConsumerList } from "../../../redux/actions/consumer-center.action";

// const initialSearchData = { offset: 0, item: "", order: "" };

// function AllConsumerAccounts(props) {
//   const [consumerData, setConsumerData] = useState([]);
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const [searchData, setSearchData] = useState(initialSearchData);

//   // useEffect(() => {
//   //     if (props.search) {
//   //         response(props.search);
//   //     }
//   // }, [props.search]);

//   console.log(props);
//   const { status } = props;

//   useEffect(async () => {
//     const breadcrumb = [{ label: "Consumer Account", url: null }];
//     dispatch(breadcrumbAction(breadcrumb));

//     const data = {
//       ...props.search,
//     };

//     startLoader();
//     const response = dispatch(getAllConsumerList(data));
//     response
//       .then((data) => {
//         if (data && data.data && data.data.status === 200) {
//           setTimeout(() => {
//             setConsumerData(data && data.data && data.data.data);
//             console.log(data.data.data);
//             props.searchChangeCount(data.data.total);
//             // setSearchData(initialSearchData)
//             stopLoader();
//           }, 1000);
//         } else {
//           setTimeout(() => {
//             stopLoader();
//           }, 1000);
//         }
//       })
//       .catch((error) => {
//         setTimeout(() => {
//           console.log("----------error----", error);
//           stopLoader();
//         }, 1000);
//       });
//   }, [props.search]);

//   useEffect(async () => {
//     const breadcrumb = [{ label: "Consumer Account", url: null }];
//     dispatch(breadcrumbAction(breadcrumb));

//     console.log(initialSearchData, searchData, "havesamedata");
//     const modifiedSearchData =
//       searchData.offset == 0
//         ? { ...searchData }
//         : { offset: searchData.offset };
//     console.log(modifiedSearchData, "vvsvssss");

//     const data = {
//       status: props.status,
//       ...props.search,
//       ...searchData,
//     };

//     startLoader();
//     const response = dispatch(getAllConsumerList(data));
//     response
//       .then((data) => {
//         if (data && data.data && data.data.status === 200) {
//           setTimeout(() => {
//             setConsumerData(data && data.data && data.data.data);
//             console.log(data.data.data);
//             props.searchChangeCount(data.data.total);
//             stopLoader();
//           }, 1000);
//         } else {
//           setTimeout(() => {
//             stopLoader();
//           }, 1000);
//         }
//       })
//       .catch((error) => {
//         setTimeout(() => {
//           console.log("----------error----", error);
//           stopLoader();
//         }, 1000);
//       });
//   }, [ searchData]);

//   const handlePageChange = (event, value) => {
//     console.log(value, "gggggggggggggggggggggggggggggggg");
//     setSearchData({ ...searchData, offset: value - 1 });

//     props.handleOffSet(value - 1 )
//   };

//   const getConsumerDataAfterDelete = (data, total) => {
//     console.log(data, total, "hhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
//     setConsumerData(data);
//     props.searchChangeCount(total);
//   };

//   const handleDeleteConsumer = async (id) => {
//     open_dialog(DELETE_CONSUMER, {
//       onSubmit: getConsumerDataAfterDelete,
//       id,
//     });
//   };

//   const addConsumerAccount = () => {
//     history.push("/consumer-center/add-consumer");
//   };
//   const ViewAndEditConsumerAccount = (consumerId) => {
//     history.push(`/consumer-center/edit-consumer/${consumerId}`);
//   };

//   return (
//     <React.Fragment>
//       <div className="d-flex justify-content-between">
//         <div>
//           <span>ALL CONSUMER</span>
//         </div>
//         <div className="d-flex mb-3">
//           <button type="button" className="btn btn-dark text-nowrap mx-3">
//             Export
//           </button>
//           <button
//             type="button"
//             onClick={() => addConsumerAccount()}
//             className="btn btn-dark text-nowrap mx-3"
//             // disabled={permmisions.product.includes('add') ? "false" : "true"}
//           >
//             + Add Consumer
//           </button>
//         </div>
//       </div>
//       <TableContainer>
//         <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
//           <TableHead>
//             <TableRow>
//               <TableCell className="th_cell" component="th">
//                 No.
//               </TableCell>

//               <TableCell className="th_cell" component="th" align="center">
//                 <div className="d-flex  ">
//                   <div>Name</div>
//                   <div className="d-flex flex-column ">
//                     <ExpandLessIcon
//                       style={{
//                         width: "15px",
//                         height: "15px",
//                         margin: "0px 3px",
//                       }}
//                       onClick={() =>
//                         setSearchData({
//                           ...searchData,
//                           item: "consumerName",
//                           order: "ASC",
//                         })
//                       }
//                     />
//                     <ExpandMoreIcon
//                       style={{
//                         width: "15px",
//                         height: "15px",
//                         margin: "-5px 3px",
//                       }}
//                       onClick={() =>
//                         setSearchData({
//                           ...searchData,
//                           item: "consumerName",
//                           order: "DESC",
//                         })
//                       }
//                     />
//                   </div>
//                 </div>
//               </TableCell>
//               <TableCell className="th_cell" component="th" align="center">
//                 <div className="d-flex  ">
//                   <div>Date Registered</div>
//                   <div className="d-flex flex-column ">
//                     <ExpandLessIcon
//                       style={{
//                         width: "15px",
//                         height: "15px",
//                         margin: "0px 3px",
//                       }}
//                       onClick={() =>
//                         setSearchData({
//                           ...searchData,
//                           item: "createdAt",
//                           order: "ASC",
//                         })
//                       }
//                     />
//                     <ExpandMoreIcon
//                       style={{
//                         width: "15px",
//                         height: "15px",
//                         margin: "-5px 3px",
//                       }}
//                       onClick={() =>
//                         setSearchData({
//                           ...searchData,
//                           item: "createdAt",
//                           order: "DESC",
//                         })
//                       }
//                     />
//                   </div>
//                 </div>
//               </TableCell>
//               <TableCell className="th_cell" component="th" align="center">
//                 <div className="d-flex  ">
//                   <div>Last Active</div>
//                   <div className="d-flex flex-column ">
//                     <ExpandLessIcon
//                       style={{
//                         width: "15px",
//                         height: "15px",
//                         margin: "0px 3px",
//                       }}
//                       onClick={() =>
//                         setSearchData({
//                           ...searchData,
//                           item: "status",
//                           order: "ASC",
//                         })
//                       }
//                     />
//                     <ExpandMoreIcon
//                       style={{
//                         width: "15px",
//                         height: "15px",
//                         margin: "-5px 3px",
//                       }}
//                       onClick={() =>
//                         setSearchData({
//                           ...searchData,
//                           item: "status",
//                           order: "DESC",
//                         })
//                       }
//                     />
//                   </div>
//                 </div>
//               </TableCell>
//               <TableCell className="th_cell" component="th" align="center">
//                 <div className="d-flex  ">
//                   <div>Email</div>
//                   <div className="d-flex flex-column ">
//                     <ExpandLessIcon
//                       style={{
//                         width: "15px",
//                         height: "15px",
//                         margin: "0px 3px",
//                       }}
//                       onClick={() =>
//                         setSearchData({
//                           ...searchData,
//                           item: "email",
//                           order: "ASC",
//                         })
//                       }
//                     />
//                     <ExpandMoreIcon
//                       style={{
//                         width: "15px",
//                         height: "15px",
//                         margin: "-5px 3px",
//                       }}
//                       onClick={() =>
//                         setSearchData({
//                           ...searchData,
//                           item: "email",
//                           order: "DESC",
//                         })
//                       }
//                     />
//                   </div>
//                 </div>
//               </TableCell>
//               <TableCell className="th_cell" component="th" align="center">
//                 <div className="d-flex  ">
//                   <div>Contact</div>
//                   <div className="d-flex flex-column ">
//                     <ExpandLessIcon
//                       style={{
//                         width: "15px",
//                         height: "15px",
//                         margin: "0px 3px",
//                       }}
//                       onClick={() =>
//                         setSearchData({
//                           ...searchData,
//                           item: "phoneNumber",
//                           order: "ASC",
//                         })
//                       }
//                     />
//                     <ExpandMoreIcon
//                       style={{
//                         width: "15px",
//                         height: "15px",
//                         margin: "-5px 3px",
//                       }}
//                       onClick={() =>
//                         setSearchData({
//                           ...searchData,
//                           item: "phoneNumber",
//                           order: "DESC",
//                         })
//                       }
//                     />
//                   </div>
//                 </div>
//               </TableCell>

//               <TableCell
//                 className="table_action_head"
//                 component="th"
//                 align="center"
//               >
//                 Action
//               </TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {!consumerData?.length && (
//               <h3 className="text-muted m-auto">No data found!</h3>
//             )}
//             {consumerData &&
//               consumerData.length > 0 &&
//               consumerData.map((row, iindex) => (
//                 <TableRow
//                   key={iindex}
//                   sx={{
//                     "&:last-child td, &:last-child th": { border: 0 },
//                   }}
//                 >
//                   <TableCell align="center" className="td_cell">
//                     {searchData.offset * 10 + (iindex + 1)}
//                   </TableCell>
//                   <TableCell align="left" className="td_cell">
//                     {row.consumerName || "N/A"}
//                   </TableCell>
//                   <TableCell align="left" className="td_cell">
//                     {row.createdAt
//                       ? moment(row.createdAt).format("DD/MM/YYYY")
//                       : "N/A"}
//                   </TableCell>
//                   <TableCell align="left" className="td_cell">
//                     {moment(row.updatedAt).format("DD/MM/YYYY") || "N/A"}
//                   </TableCell>
//                   <TableCell align="left" className="td_cell">
//                     {row.email || "N/A"}
//                   </TableCell>
//                   <TableCell align="left" className="td_cell">
//                     {row.phoneNumber || "N/A"}
//                   </TableCell>

//                   <TableCell className="d-flex justify-content-between table_action_item">
//                     <IconButton
//                       className="pointer"
//                       onClick={() => ViewAndEditConsumerAccount(row.consumerId)}
//                     >
//                       <SvgIcon src="/icons/view_icon.svg" alt="view icon" />
//                     </IconButton>
//                     <IconButton
//                       className="pointer"
//                       onClick={() => handleDeleteConsumer(row.consumerId)}
//                     >
//                       <SvgIcon src="/icons/delete_icon.svg" alt="delete icon" />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//               ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//       <br />
//       <div className="float-right mb-1">
//         <Stack spacing={0}>
//           <Pagination
//             count={Math.ceil((props?.totalItem || 0) / 10)}
//             shape="rounded"
//             size="small"
//             onChange={handlePageChange}
//           />
//         </Stack>
//       </div>
//     </React.Fragment>
//   );
// }

// export default AllConsumerAccounts;
