import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TabPanel } from "../../components/Tabs/TabPannel";
import { breadcrumbAction } from "../../redux/actions/config.action";
import Allproduct from "./Allproduct";
import { AllowAccess } from "../../lib/global";
import { getLocalStorage } from "../../lib/session";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const initialSearchData = {
  productName: "",
  categoryName: "",
  orderDate: "",
}


const ProductOverride = (props) => {
  AllowAccess(["MERCHANT"]);
  const isOverrideTp = getLocalStorage("isOverrideTp");
  if (!isOverrideTp || isOverrideTp == "" || isOverrideTp == 0 || isOverrideTp == false) {
    window.location.href = "/403";
  }

  const dispatch = useDispatch();
  const [tabIndex, setTabIndex] = useState(0);
  const [productName, setProductName] = useState('');
  const [categoryName, setCategoryName] = useState('');


  // const [allProductData, setAllProductData] = useState([]);
  const [search, setSearch] = useState(initialSearchData);
  const [total, setTotal] = useState({})
  const [reset, setReset] = useState(false);


  useEffect(() => {
    const breadcrumb = [{ label: "Products Override", url: null }];
    dispatch(breadcrumbAction(breadcrumb));
  }, [tabIndex]);

  const afterDelete = (data) => {
    setTotal(data && data.total);
    setTabIndex(0)
  };

  const searchData = () => {
    setSearch({ productName, categoryName });

  };
  const handleResetSearch = () => {
    setProductName("");
    setCategoryName("");
    setSearch({ productName: "", categoryName: "" });
  };

  return (
    <React.Fragment>
      <div className="mt-1 mx-3 px-2 py-3 rounded bg-white">
        <div className="col-12 row flex-wrap">
          <div className="min-width-200 col-md-5">
            <label>Product Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Input"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            type="button"
            onClick={() => handleResetSearch()}
            className="btn btn-outline-dark px-4 mx-3 btn-sm"
          >
            Reset
          </button>
          <button
            type="button"
            className="btn input-inside-text mx-1 btn-secondary px-3"
            onClick={() => searchData()}
          >
            Search
          </button>
        </div>
      </div>
      <br />
      <br />
      <div className="container_body container-fluid ">
        <div className="card p-3">
          <span>PRODUCT OVERRIDE LISTING</span>
          <div className=" border card  my-3">
            <Tabs
              value={tabIndex}
              onChange={(e, value) => setTabIndex(value)}
              variant="scrollable"
              orientation="horizontal"
              scrollButtons={false}
              visibleScrollbar={false}
              className="px-3"
              aria-label="basic tabs example"
            >
              <Tab label={`All(${total?.all || 0})`} {...a11yProps(0)} />
              <Tab label={`Normal(${total?.normals || 0})`}{...a11yProps(1)} />
              <Tab label={`Flashdeals(${total?.flashdeals || 0})`} {...a11yProps(2)} />
              <Tab label={`Groupdeals(${total?.groupdeals || 0})`} {...a11yProps(3)} />
              <Tab label={`Streams(${total?.livedeals || 0})`}{...a11yProps(4)} />
            </Tabs>

            <TabPanel value={tabIndex} index={0}>
              <Allproduct
                afterDelete={afterDelete}
                search={search}
                totalItem={total.all}
                status={''}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={1}>
              <Allproduct
                afterDelete={afterDelete}
                search={search}
                totalItem={total.all}
                status={"isNormal"}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={2}>
              <Allproduct
                afterDelete={afterDelete}
                search={search}
                totalItem={total.flashdeals}
                status={"isFlashDeal"}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={3}>
              <Allproduct
                afterDelete={afterDelete}
                search={search}
                totalItem={total.groupdeals}
                status={"isGroupDeal"}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={4}>
              <Allproduct
                afterDelete={afterDelete}
                search={search}
                totalItem={total.streams}
                status={"isStream"}
                setTotals={setTotal}
                reset={handleResetSearch}
              />
            </TabPanel>
          </div>
        </div>
      </div>


    </React.Fragment>
  );
};

export default ProductOverride;
