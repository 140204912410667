import {
  Box,
  Container,
  Typography
} from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';



const NotFoundView = () => {

  return (
    <React.Fragment>
    <div
      className={"h-100 w-100 align-items-center"}
      title="404"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="md">
          <h2 className="bold text-center">
            404: The page you are looking for isn’t here
          </h2>
          <Typography
            align="center"
            color="textPrimary"
            variant="subtitle2"
          >
            You either tried some shady route or you came here by mistake.
            Whichever it is, 
            <NavLink to="/">
              {' go back to home!'}
            </NavLink>
          </Typography>
          <Box textAlign="center">
            <img
              alt="Under development"
              className={"w-90"}
              src="/images/notFound.svg"
            />
          </Box>
        </Container>
      </Box>
    </div>
    </React.Fragment>
  );
};

export default NotFoundView;