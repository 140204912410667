import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Zoom from "react-img-zoom";

const ImageCarousel = (props) => {
  const { imageUrls } = props;

  return (
    <Carousel
      slide={false}
      prevLabel={null}
      nextLabel={null}
      interval={null}
      indicators={false}
    >
      {imageUrls?.map((item, idx) => (
        <Carousel.Item key={idx}>
          <Zoom img={`${item}`} zoomScale={3} width={480} height={330} />
          {/* <img
            className="d-block w-100 d-flex justify-content-center align-items-center"
            style={{ width: "500", height: "350px" }}
            src={item}
            alt={`image on slide ${idx}`}
          /> */}
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
