import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ablity from "../../../helpers/abilityGuard";
import { AllowAccess } from "../../../lib/global";

function MerchantSendBroadcastMsg() {
  AllowAccess(["SUPERADMIN","SUPPORT","OPERATOR","MARKETING & E-COMMERCE","FINANCIAL","SALES OPERATION","VIEWER","DEVELOPER"]);
  const [show, setshow] = useState(false);
  const history = useHistory();
  const { state } = useLocation()
  console.log(state, 'ppppppppppppppppp');

  return (
    <React.Fragment>
      <div className="broadcast_send_message">
        <div className="broadcast_send_message_main_container">
          <h6>CHAT CONTENT</h6>

          <table>
            <tbody>
              <tr>
                <td className="lableText">Recipient</td>
                <td>
                  <div className="input_lenght">
                    <input type="text" placeholder="All Merchant" />
                  </div>
                </td>
              </tr>
              <tr>
                <td className="lableText">Broadcast Name</td>
                <td>
                  <div className="input_lenght">
                    <input type="text" placeholder="Input" />
                    <span>0/500</span>
                  </div>
                </td>
              </tr>

              <tr>
                <td className="lableText spacial">Massage</td>
                <td>
                  <div className="input_lenght">
                    <textarea name="" id=""></textarea>
                    <span>0/500</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="lableText spacial">Broadcast Name</td>
                <td>
                  <div className="spacial_input">
                    {" "}
                    <input type="radio" name="1st" onClick={() => {
                      setshow(false);
                    }} />
                    Send Now
                  </div>
                  <div className="spacial_input" >
                    {" "}
                    <input
                      type="radio"
                      name="1st"
                      onClick={() => {
                        setshow(true);
                      }}
                    />
                    Schedule
                  </div>
                  {show ? (
                    <div className="broadcastNameSetting">
                      <input type="date" />
                      <input type="time" name="time" id="selectTime" >
                      </input>
                    </div>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <footer>
          <div
            className="btn_group_broadcast"
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              onClick={history.goBack}
              className="btn btn-light broadcastmsgFooter"
            >
              Cancel
            </button>
            {ablity({model:'Merchant', action:'create'}) == 1 && (
                <button
                  type="button"
                  onClick={history.goBack}
                  className="btn btn-light broadcastmsgFooter"
                >
                  Save as Draft
                </button>
            )}

            {ablity({model:'Merchant', action:'create'}) == 1 && (
                <button
                  type="button"
                  onClick={history.goBack}
                  className="btn btn-dark broadcastmsgFooter"
                >
                  Send
                </button>
            )}
          </div>
        </footer>
      </div>
    </React.Fragment>
  );
}

export default MerchantSendBroadcastMsg;
