const HEADER_COLOR = "#0094ea"
export const customTheme = {
    light: {
        color1: "#fc024d",
        color2: "#3a811d",
        background: "#f0f8ff",
        textColor: "#000",
        sidebarTextColor: "#969CA4",
        bgColor: "#f5f5f5",
        drawerBgColor: "#fff",
        iconColor: '',
        drawerShadow: "rgb(0 0 0 / 20%) 2px 2px 6px -1px",
        headerColor: HEADER_COLOR,
        headerBgColor: "#fff",
        card: {
            border: "1px solid #fff",
            boxShadow: "3px 3px 6px 3px #e8e6e6",
            bgColor: "#fff",
            headerBgColor: HEADER_COLOR,
        },
        graph: {
            rowColor: '#f3f3f3'
        },
        map: {
            border: "1px solid #222b37",
            boxShadow: "3px 3px 6px 3px #1a2027b8",
            bgColor: "rgb(25, 56, 94)",
            headerBgColor: "#181745"
        },
        table: {
            backgroundColor: "#fff",
            footerBgColor: "#fff"
        }
    },
    dark: {
        color1: "#fc024d",
        color2: "#3a811d",
        background: "linear-gradient(to bottom left,#216e93,#21648a,#1f5881,#172d5b,#191c51)",
        textColor: "#fff",
        sidebarTextColor: "#969CA4",
        bgColor: "#586673",
        drawerBgColor: "#181745",
        iconColor: "#fff",
        drawerShadow: "rgb(0 0 0 / 20%) 2px 2px 6px -1px",
        headerColor: HEADER_COLOR,
        headerBgColor: "#19385e",
        card: {
            border: "1px solid #222b37",
            boxShadow: "3px 3px 6px 3px #1a2027b8",
            bgColor: "#1d1b4199",
            headerBgColor: "#181745"
        },
        graph: {
            rowColor: '#2b3346'
        },
        map: {
            border: "1px solid #222b37",
            boxShadow: "3px 3px 6px 3px #1a2027b8",
            bgColor: "#1d1b4199",
            headerBgColor: "#181745"
        },
        table: {
            backgroundColor: "#182556",
            footerBgColor: "#181745"
        }
    }
}
    